/**
 * 地図画面のお知らせ一覧化時に表示するグリッド
 * @module app/notice/NoticePanelGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'app/notice/NoticeFormatter',
    'app/notice/view/NoticeTypeColumn'
], function(module, declare, IdisGrid, helper, NoticeFormatter, NoticeTypeColumn) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        // IdisGrid拡張：行自体にCSSクラスを追加する場合に指定する
        rowClassName: function(item) {
            return item && !item.pubFlg ? 'is-private' : '';
        },

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            NoticeTypeColumn,
            helper.column('content', '内容', {formatter: NoticeFormatter.format}),
            helper.timestampColumn('noticeTimestamp', '通知日時')
        ],

        // DOM構築後に呼ばれる
        postCreate: function() {
            this.inherited(arguments);
            // グリッド内のクリックが外へ波及しないようにする
            this.on('click', function(evt) {
                evt.stopPropagation();
            });
        }
    });
});
