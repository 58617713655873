/**
 * 印刷範囲指定用の矩形
 * @module app/map/print/PrintAreaRectangle
 */
define([
    'leaflet'
], function(leaflet) {
    
    leaflet.Draw.PrintFeature = leaflet.Draw.Feature.extend({
        options: {
            repeatMode: false
        },

        initialize: function (map, options) {
            this._endLabelText = leaflet.drawLocal.draw.handlers.simpleshape.tooltip.end;

            leaflet.Draw.Feature.prototype.initialize.call(this, map, options);
        },

        addHooks: function () {
            leaflet.Draw.Feature.prototype.addHooks.call(this);
            if (this._map) {
                this._mapDraggable = this._map.dragging.enabled();

                if (this._mapDraggable) {
                    this._map.dragging.disable();
                }

                //TODO refactor: move cursor to styles
                this._container.style.cursor = 'crosshair';

                this._tooltip.updateContent({ text: this._initialLabelText });

                this._map
                    .on('mousedown', this._onMouseDown, this)
                    .on('mousemove', this._onMouseMove, this);
            }
        },

        removeHooks: function () {
            leaflet.Draw.Feature.prototype.removeHooks.call(this);
            if (this._map) {
                if (this._mapDraggable) {
                    this._map.dragging.enable();
                }

            //TODO refactor: move cursor to styles
            this._container.style.cursor = '';

            this._map
                .off('mousedown', this._onMouseDown, this)
                .off('mousemove', this._onMouseMove, this);

                leaflet.DomEvent.off(document, 'mouseup', this._onMouseUp, this);

                // If the box element doesn't exist they must not have moved the mouse, 
                // so don't need to destroy/return
                if (this._shape) {
                    this._map.removeLayer(this._shape);
                    delete this._shape;
                }
            }
            this._isDrawing = false;
        },

        _getTooltipText: function () {
            return {
                    text: this._endLabelText
            };
        },

        _onMouseDown: function (e) {
            this._isDrawing = true;
            this._startLatLng = e.latlng;

            leaflet.DomEvent
                .on(document, 'mouseup', this._onMouseUp, this)
                .preventDefault(e.originalEvent);
        },

        _onMouseMove: function (e) {
            var latlng = e.latlng;

            this._tooltip.updatePosition(latlng);
            if (this._isDrawing) {
                this._tooltip.updateContent(this._getTooltipText());
                this._drawShape(latlng);
            }
        },

        _onMouseUp: function () {
            if (this._shape) {
                this._fireCreatedEvent();
            }

            this.disable();
            if (this.options.repeatMode) {
                this.enable();
            }
        },
        
        /**
         * 描画フィーチャー生成時のイベント
         */
        _fireCreatedEvent: function (layer) {
            // 作図機能のイベントとの重複を防ぐため、別イベントとして定義
            this._map.fire('print:area-created', { layer: layer, layerType: this.type });
        }
    });
    
    var PrintAreaRectangle = leaflet.Draw.PrintAreaRectangle = leaflet.Draw.PrintFeature.extend({

        statics: {
            TYPE: 'print-area-rectangle'
        },
        
        options: {
            shapeOptions: {
                clickable: false,
                color: '#0033ff',
                dashArray: null,
                fill: true,
                fillColor: null,
                fillOpacity: 0.2,
                lineCap: null,
                lineJoin: null,
                noClip: false,
                opacity: 0.5,
                smoothFactor: 1,
                stroke: true,
                weight: 5
            },
            metric: true // Whether to use the metric meaurement system or imperial
        },
        
        initialize: function (map, options) {
            // Save the type so super can fire, need to do this as cannot do this.TYPE :(
            this.type = leaflet.Draw.PrintAreaRectangle.TYPE;

            this._initialLabelText = leaflet.drawLocal.draw.handlers.rectangle.tooltip.start;

            leaflet.Draw.PrintFeature.prototype.initialize.call(this, map, options);
        },
        
        /**
         * 矩形描画
         */
        _drawShape: function (latlng) {
            if (!this._shape) {
                this._shape = new leaflet.Rectangle(
                    new leaflet.LatLngBounds(this._startLatLng, latlng), this.options.shapeOptions);
                this._map.addLayer(this._shape);
            } else {
                this._shape.setBounds(new leaflet.LatLngBounds(this._startLatLng, latlng));
            }
        },
        
        /**
         * レイヤー生成時のイベント定義
         */
        _fireCreatedEvent: function(){
            var rectangle = new leaflet.Rectangle(this._shape.getBounds(), this.options.shapeOptions);
            leaflet.Draw.PrintFeature.prototype._fireCreatedEvent.call(this, rectangle);
        },
        
        _getTooltipText: function () {
            var tooltipText = leaflet.Draw.PrintFeature.prototype._getTooltipText.call(this);
            var shape = this._shape;
            var latLngs, area, subtext;

            if (shape) {
                latLngs = this._shape.getLatLngs();
                area = leaflet.GeometryUtil.geodesicArea(latLngs);
                subtext = leaflet.GeometryUtil.readableArea(area, this.options.metric);
            }

            return {
                text: tooltipText.text,
                subtext: subtext
            };
        }
        
    });

    return PrintAreaRectangle;

});
