/**
 * チャット履歴画面用モジュール。
 * @module app/chat/ChatHistoryPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/ChatHistoryPage.html',
    'idis/view/page/_PageBase',
    'dijit/Dialog',
    'idis/control/Router',
    'idis/store/IdisRest',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'app/chat/ChatHistoryFormDialog',
    'app/view/form/UserOrganizationSelector',
    './ChatHistoryGrid'
], function(module, declare, lang, template, _PageBase, Dialog, Router, IdisRest, Loader,
	DialogChain) {
    /**
     * チャット履歴画面
     * @class ChatHistoryPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/chat/ChatHistoryPage~ChatHistoryPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--chat',

        /**
         * ストア
         */
        store: null,

        /**
         * constructor
         */
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'chatId',
                target: '/api/chat/history',
                sortParam: 'sort',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は関連ダイアログも削除する
            this.own(this.formDialog);

            this.organizationSelector.restoreRefresh();
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);

            this.initGrid();
            this.initPage();
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            this.updateGridQuery();
        },

        /**
         * チャットグループ画面を初期化する。
         */
        initPage: function() {

        },

        /**
         * 帳票出力ダイアログを表示する。
         */
         showFormDialog: function() {
            var dialog = this.formDialog.getChildren()[0];

            // 現在の検索条件を帳票出力ダイアログに渡す
            var value = this.form.get('value');
            dialog.initDialog(value);

            this.formDialog.show();
        },

        /**
         * チャット履歴を検索する。
         */
        onSubmit: function() {
            console.debug('[検索]ボタンがクリックされました。');
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * グリットを更新する。
         */
        updateGridQuery: function() {
            var filter = new this.store.Filter();
            var value = this.form.get('value');
            console.log(value);

            // グループIDによる検索
            if (value.groupId && value.groupId !== '') {
                filter = filter.eq('chatGroupId', value.groupId);
			}

            // グループ名による検索
            if (value.groupName && value.groupName !== '') {
                filter = filter.eq('chatGroupName', value.groupName);
            }

            // ユーザIDによる検索
            if (value.userId && value.userId !== '') {
                filter = filter.eq('userId', value.userId);
			}

            // ユーザ名による検索
            if (value.userName && value.userName !== '') {
                filter = filter.eq('userName', value.userName);
            }

            // 所属による検索
            if (value.organization && value.organization !== '') {
                filter = filter.eq('organization', value.organization);
            }

            // 投稿日時Fromによる検索
			if (value.sendDatetimeFrom) {
				var sendDatetimeFrom = new Date(value.sendDatetimeFrom).getTime();
				filter = filter.eq('sendDatetimeFrom', sendDatetimeFrom);
			}

            // 投稿日時Toによる検索
			if (value.sendDatetimeTo) {
				var sendDatetimeTo = new Date(value.sendDatetimeTo).getTime();
				filter = filter.eq('sendDatetimeTo', sendDatetimeTo);
			}

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);

            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.chatHistoryGrid.set('collection', collection);

            this.chatHistoryGrid.on('attachFileLinkClick', lang.hitch(this, function(evt) {
                var attachFilePath = evt.item.attachFilePath.replace('out', 'data');
                var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + attachFilePath;
                this.downloadFile(url, evt.item.attachFileName);
            }));

        },

        /**
         * 添付ファイルをダウンロードする。
         */
        downloadFile: function(url, name) {
            // Dojoのrequestor(dojo/request/xhr)のresponseType（handleAsプロパティ）で、
            // バイナリ（arraybuffer, blob）が利用できなかったためネイティブJSを利用している.
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'arraybuffer';
            xhr.onload = function() {

                var arrayBuffer = this.response;

                var blob = new Blob([arrayBuffer], {
                    type: 'application/octet-stream'
                });

                // IE10+
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, name);
                }
            };
            xhr.send();
            return false;
        }

    });
});
