define([
    'leaflet',
    './_OptionConverterMixin'
], function(leaflet, _OptionConverterMixin) {
    var TanJSON = leaflet.TanJSON = leaflet.GeoJSON.extend({
        includes: _OptionConverterMixin,
        initialize: function(tanJson, options){
            leaflet.setOptions(this, options);
            this._layers = {};
            if (tanJson) {
                this.customAddData(tanJson);
            }
        }
    });

    leaflet.tanJson = leaflet.tanJSON = function(tanjson, options){
        return new leaflet.TanJSON(tanjson, options);
    };

    return TanJSON;
});

