/**
 * <通知グループ登録画面>
 *
 * @module app/broadnotify/BroadnotifyGroupRegisterPage.js
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/text!./templates/BroadnotifyGroupRegisterPage.html', // テンプレート文字列
    'dojo/_base/lang',
    'idis/control/Router',
    'idis/store/IdisRest',
    'dstore/Memory',
    'idis/control/Locator',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    'idis/service/Requester',
    './_BroadnotifyGroupPageBase',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/form/Form',
    'dijit/form/ValidationTextBox',
    'dijit/form/CheckBox',
    'idis/view/form/AclButton',
    'idis/view/form/WordCountTextarea',
    'app/broadnotify/BroadnotifyGroupTerminalsGrid',
    'app/broadnotify/BroadnotifyAddGroupDialog'
], function(module, declare, template, lang, Router,
    IdisRest, Memory, Locator, Loader, DialogChain, Requester, _BroadnotifyGroupPageBase) {
    /**
     * 通知グループ新規登録画面
     *
     * @class BroadnotifyGroupRegisterPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _BroadnotifyGroupPageBase,
    /** @lends module:app/broadnotify/BroadnotifyGroupRegisterPage~BroadnotifyGroupRegisterPage# */ {

        // テンプレート文字列
        templateString : template,

        constructor : function() {
            this.store = new Memory({
                idProperty: 'terminalId'
            });

            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },


        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            this.initConvoAutoMailRegisterGrid();
            this.initSelectGroupDialog();
            this.own(this.selectGroupDialog);
        },

        /**
         * 登録ボタンが押された際に条件を登録する。
         */
        onSubmit: function() {

            // フォームのバリデーションを実施
            if (!this.form.validate()) {
                return false;
            }

            // 入力チェック
            if (!this.validate()) {
                return false;
            }

            // 登録確認ダイアログの表示
            this.chain.confirmAdd(lang.hitch(this, function(chain) {
                var value = this.form.get('value');
                value.terminalList = this.getGroupCdList();
                // リクエストの発行からレスポンス受信後の処理までをpromiseに格納
                var promise = Requester.post('/api/broadnotify/group/', {
                    data: value
                }).then(function(){
                    // 処理成功時は完了ダイアログを表示
                    chain.infoComplete(function() {
                        Router.moveTo('broadnotify/group');
                    });
                }, function(err) {
                    // 処理失敗時はエラーログを出力し、エラーダイアログを表示
                    console.error(err);
                    chain.infoError(err);
                });
                //ローダーの表示
                Loader.wait(promise);
            }));
        }

    });
});
