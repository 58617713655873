
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'idis/view/LazyNode',
    'app/model/LayerDirectory'
], function(module, declare, lang, IdisGrid, helper, LazyNode, LayerDirectory) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            helper.buttonColumn('detail', '詳細'),
            helper.column('inputDataFormat', '種別', {
                formatMap: {
                    '0':  '画像（jpg＋jgw）',
                    '1':  '画像（png＋pgw）',
                    '2':  'GeoTIFF',
                    '3':  'Shapefile',
                    '4':  'KML',
                    '5':  'タイル化済データ'
                }
            }),
            helper.column('crtTimestamp', '登録日時'),
            {field: 'parentLayerId', label: '格納先',
                renderCell: function(item, value) {
                    var lazyNode = new LazyNode({
                        promise: LayerDirectory.getFullName(value || 0)
                    });
                    return lazyNode.domNode;
                }
            },
            helper.column('layerName', '報告名'),
            helper.column('tstatus', '処理状況', {
                formatMap: {
                    '0':  'タイル化情報登録済',
                    '1':  'タイル化情報登録エラー',
                    '2':  'タイル化処理依頼済',
                    '3':  'タイル化処理受付エラー',
                    '4':  'タイル化中',
                    '5':  'タイル化処理エラー',
                    '6':  'タイル化完了',
                    '7':  'キャンセル'
                }
            }),
            helper.column('description', '情報説明文')
        ]
    });
});
