/**
* 洪水予報情報一覧画面用モジュール。
* @module app/floodforecast/view/FloodForecastPage
*/
define([
'module',
'dojo/_base/declare',
'dojo/_base/lang',
'dojo/date/locale',
'dojo/_base/array',
'dojox/lang/functional/object',
'idis/view/grid/IdisGrid',
'idis/view/grid/helper',
// 以下、変数として受け取らないモジュール
'dojox/lang/functional/array'
], function(module, declare, lang, locale, array, df, IdisGrid, helper) {

/**
 * 日時を表示形式に変換する
 * 'yyyy-MM-dd HH:mm:dd'を'年月日時分'に変換する
 * FIXME 外部化を検討。helperで対応?
 *
 * @param {string} 'yyyy-MM-dd HH:mm:dd'
 * @return {string} 'yyyy年MM月dd日 HH時mm分'
 */
var formatDateTime = function(value) {
	var dateLabel = locale.format(new Date(value.replace(/-/g, '/')), {
		selector: 'date',
		datePattern: 'MM月dd日'
	});
	var timeLabel = locale.format(new Date(value.replace(/-/g, '/')), {
		selector: 'time',
		timePattern: 'HH時mm分'
	});
	return dateLabel + ' ' + timeLabel;
};

//   var formatInfoKind = function(title){
//       var infoKind = {};
//       var alertLevelStr = '';
//       if(title.substring(title.length - 2) === '解除'){
//           infoKind.infokind =  title.substring(title.length - 8);
//           infoKind.className = '';
//           return infoKind;
//       } else if (title.substring(title.length - 5) === '情報解除）'){
//           alertLevelStr = title.substring((title.length - 14 ), (title.length - 8));
//       } else {
//           alertLevelStr = title.substring(title.length - 6);
//       }

//       if(alertLevelStr === '氾濫注意情報'){
//           infoKind.infokind =   '【警戒レベル2相当】<br>氾濫注意情報';
//           infoKind.className = 'is-level2';
//       } else if(alertLevelStr === '氾濫警戒情報'){
//           infoKind.infokind =   '【警戒レベル3相当】<br>氾濫警戒情報';
//           infoKind.className = 'is-level3';
//       } else if(alertLevelStr === '氾濫危険情報'){
//           infoKind.infokind =   '【警戒レベル4相当】<br>氾濫危険情報';
//           infoKind.className = 'is-level4';
//       } else if(alertLevelStr === '氾濫発生情報'){
//           infoKind.infokind =   '【警戒レベル5相当】<br>氾濫発生情報';
//           infoKind.className = 'is-level5';
//       } else {
//           infoKind.infokind =   '情報なし';
//           infoKind.className = '';
//       }

//       return infoKind;
//   };

return declare(module.id.replace(/\//g, '.'), IdisGrid, {
	// IdisGrid拡張：行自体にCSSクラスを追加する場合に指定する
	rowClassName: function(item) {
		return item && item.pubStatus === 1 ? 'is-public' : '';
	},
	/**
	 * 各列の定義
	 * @type {Object[]}
	 */
	columns: [
		helper.buttonColumn('detail', '詳細'),
		helper.column('reportDatetime', '発表日時', {
			formatter: function(reportDatetime){
				return formatDateTime(reportDatetime);
			}
		}),
	helper.column('name', '河川', {
		formatter: function(v, item){
			return !item.regions[0] ? '-' : item.regions[0].mlKindFlAreaname ?
			item.regions[0].mlKindFlAreaname : '-';
		},
		sortable: false
	}),
	helper.column('text', '発表文', {
		formatter: function(value) {
			// 100文字以上は詳細で確認してもらう。
			var text = value.length > 100 ? 
				[value.substr( 0, 100 ), '...'].join() : value;
			return text;
		},
		sortable: false
	})
	//   helper.column('floodInfoKind', '情報種別', {
	//       formatter: lang.hitch(this, function(value, item){
	//           return formatInfoKind(item.title).infokind;
	//       }),
	//       className: lang.hitch(this, function(item) {
	//           // ヘッダーの場合はclassNameを返さない
	//           if(!item){
	//             return;
	//           }
	//           return formatInfoKind(item.title).className;
	//       })
	//   }),
	//   helper.column('title', 'タイトル')
	]
	});
});
