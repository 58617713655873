/**
 * dijit/tree/modelに対し
 * コールバック引数の代わりにPromiseを返すメソッドを追加提供するためのMixin
 * @module idis/store/_PromiseModelMixin
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/Deferred'
], function(module, declare, Deferred) {
    /**
     * dijit/tree/modelに対しPromiseを返す機能を提供するためのMixin
     * @class _PromiseModelMixin
     * @extends module:dijit/tree/model
     */
    return declare(null, /** @lends module:idis/model/_PromiseModelMixin~_PromiseModelMixin# */ {
        /**
         * ルート要素を返すPromiseを返す。
         * @returns {Promise<Object>} ルート要素を返すPromise
         */
        getRootPromise: function() {
            var dfd = new Deferred();
            this.getRoot(dfd.resolve, dfd.reject);
            return dfd.promise;
        },

        /**
         * 子要素一覧を返すPromiseを返す。
         * @param {Object} item 親となる要素
         * @returns {Promise<Object[]>} 子要素一覧を返すPromise
         */
        getChildrenPromise: function(item) {
            var dfd = new Deferred();
            this.getChildren(item, dfd.resolve, dfd.reject);
            return dfd.promise;
        }
    });
});

