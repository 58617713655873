/**
 * 災害対策本部設置登録_配信確認ダイアログ
 * @module app/disasterprevention/view/DisasterPreventionConfirmDialog
 */
 define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/dom-style',
    'dojo/dom-class',
    'dojo/text!./templates/DisasterPreventionConfirmDialog.html',
    'idis/util/DateUtils',
    'idis/store/IdisRest',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    'app/provide/_ProvideDialogBase',
    './model/DistributionType',
    './model/HqSetFlg',
    './model/HqType',
    // 以下、変数から参照されないモジュール
    'app/provide/form/DistributionTypeInput',
    'app/view/form/CustomizableMunicipalitySelector',
    'dijit/form/Form',
    'idis/view/form/WordCountTextarea',
    'idis/view/form/Button',
    'idis/view/form/ReadOnlyInput'
], function(module, declare, lang, Deferred, domStyle, domClass, template,
    DateUtils, IdisRest, Requester, DialogChain, InfoDialog, Loader, _ProvideDialogBase,
    DistributionType, HqSetFlg, HqType) {
    /**
     * 災害対策本部設置登録_配信確認ダイアログ
     * @class ProvidePreventionRegisterDialog
     * @extends module:app/provide/_ProvideDialogBase~_ProvideDialogBase
     */
    return declare(module.id.replace(/\//g, '.'), _ProvideDialogBase,
        /** @lends module:app/provide/_ProvideDialogBase~_ProvideDialogBase# */ {
        // テンプレート文字列
        templateString: template,

        // 登録情報を保持用
        sendData: null,

        // 配信種別
        _distributionType: null,

        // 災害ID
        _disasterId: null,

        /**
         * 態勢情報を元に本部設置状況を返す。
         * @param {Object} dpData 態勢情報
         * @param {string} dpData.status 態勢状況
         * @param {string} dpData.hqSetFlg 本部設置状況
         * @returns {string} 本部設置状況
         */
        _getHeadquarterStatusName: function(dpData) {
            // 態勢解除または本部解散または未設置の場合は解散とする
            return (dpData.hqSetFlg === HqSetFlg.ESTABLISH) ? '設置' : '廃止';
        },

        constructor: function() {
            this.chain = DialogChain.get(this);
        },

        // ベースクラスをオーバーライド
        // 画面から渡された値で初期表示設定を行う。
        initDialog: function(obj){
            this._disasterId = obj.sendData.disasterId;
            // 取消ボタン押下→「訂正」配信の場合、前報を取得
            if (obj.sendData.allDelete!==undefined && // 取消ボタン押下時はbooleanが入る
                obj.distributionType===DistributionType.CORRECT) {
                // 前報を取得
                Requester.get('/api/disasterPrevention/revert/' + obj.sendData.disasterPrevId, {
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(lang.hitch(this, function(item) {
                    if(item){
                        // 前報を訂正として配信
                        this.setDisasterPrevInf({
                            distributionType : obj.distributionType,
                            municipalityCd : obj.municipalityCd,
                            headquarterName : item.hqName,
                            hqSetFlg : item.hqSetFlg,
                            headquarterDatetime : item.hqSetFlg === HqSetFlg.RELEASE ?
                                item.hqAbolishedTimestampMS : item.hqSetTimestampMS
                        });

                    }else{
                        this.setDisasterPrevInf(obj);
                    }
                }), lang.hitch(this, function(error) {
                    console.log(error);
                    this.chain.info('情報の取得に失敗しました。', 'エラー');
                }));
            } else {
                this.setDisasterPrevInf(obj);
            }
            // 登録情報を保持
            this.sendData = obj.sendData;
        },

        setDisasterPrevInf: function(obj){
            this.toggleErrataDescriptionArea(
                [DistributionType.CANCEL, DistributionType.CORRECT].indexOf(obj.distributionType) > -1);
            // 配信種別
            this._distributionType = obj.distributionType === DistributionType.CORRECT ?
                DistributionType.UPDATE : obj.distributionType;

            this.distributionTypeInput.set('value', this._distributionType);
            // 発行元
            this.municipalityCd.set('value', obj.municipalityCd);
            // 本部名
            this.headquarterName.innerHTML = obj.headquarterName || '対策本部';
            // 設置状況
            this.headquarterStatusNode.innerHTML = this._getHeadquarterStatusName(obj);
            // 設置日時（本部解散の場合は廃止日時）
            if(obj.headquarterDatetime){
                domStyle.set(this.headquarterDatetimeArea, 'display', '');
                this.headquarterDatetimeNode.innerHTML = DateUtils.format(new Date(obj.headquarterDatetime));
            } else {
                domStyle.set(this.headquarterDatetimeArea, 'display', 'none');
            }

            // 配信情報入力欄をセット
            this.initForm();
        },

        initForm: function(){
            this.complementaryInfo.reset();
            this.errataDescriptionInput.reset();
        },

        toggleErrataDescriptionArea: function(isShow) {
            if (isShow) {
                domStyle.set(this.errataDescriptionArea, 'display', '');
                domClass.add(this.errataDescriptionArea, 'is-required');
            } else {
                domStyle.set(this.errataDescriptionArea, 'display', 'none');
                domClass.remove(this.errataDescriptionArea, 'is-required');
            }
        },

        // テンプレートHTML内でformに対し、data-dojo-attach-event="onSubmit:onSubmit"でこのメソッドを紐付け
        // 登録ボタンをtype="submit"にしているので、ボタン押下時にフォームがsubmitされて呼ばれる
        onSubmit: function() {
            // ヴァリデーションチェック
            if(!this.form.isValid() || !this._validateCheck()){
                return false;
            }
            this.sendData.disPrevDistributionType = this._distributionType;
            var value = lang.mixin(this.form.get('value'), this.sendData);
            delete value.distributionType;
            delete value.isUnsentLalert;
            this.emit('distribution-send', { value : value });
            return false;
        },

        /**
         * ダイヤログ固有のヴァリデーションチェック
         */
        _validateCheck: function() {
            //訂正・取消理由入力必須
            if(domStyle.get(this.errataDescriptionArea, 'display')!=='none'&&
                (!this.errataDescriptionInput.validate() || !this.errataDescriptionInput.value.trim())) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '「訂正・取消理由」が入力されていません。'
                    });
                    this.infoDialog.show();
                    return false;
            }
            return true;
        }

    });
});
