/**
 * クロノロジー区分を扱うモデル。
 * @module app/model/ChronologyTypeList
 */
define([
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest'
], function(CacheStoreModel, CacheTreeRest) {
    /**
     * シングルトンを返す。
     */
    return new CacheStoreModel({
        store: new CacheTreeRest({
            target: 'dojo/app/chronology/ChronologyType.json'
        })
    });
});
