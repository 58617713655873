/**
 * 避難情報パネル
 * @module app/monitor/EvacorderPanel
 */
 define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/dom-construct',
    'dojo/json',
    'dojo/text!./templates/EvacorderPanel.html',
    'dojo/topic',
    'dojo',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/view/page/_PageBase',
    'app/model/DisasterInfo',
    'app/config',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane'
], function (module, array, declare, lang, locale, domConstruct, JSON, template, topic,
    dojo, Router, UserInfo, USER_TYPE, Requester, DialogChain, Loader, _PageBase, DisasterInfo, config) {
    /**
     * 避難情報パネル
     * @class EvacorderPanel
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/monitor/EvacorderPanel~EvacorderPanel# */ {
            // テンプレート文字列
            templateString: template,

            /**
             * 最大表示件数
             */
            MAX_COUNT: 5,

            /**
             * 市町村コード
             */
            _municipalityCd: null,

            /**
             * constructor
             */
            constructor: function () {
                this.chain = DialogChain.get(this);
                this._municipalityCd = UserInfo.getSelectedMunicipalityCd();

                // その他関係組織の場合、都道府県として抽出する
                if (this._municipalityCd === config.municInfo.otherRelationOrg) {
                    this._municipalityCd = config.municInfo.prefMunicCd;
                }
                console.debug('避難情報表示対象の市町村コード：' + this._municipalityCd);
            },

            /**
             * startup
             */
            startup: function () {
                this.inherited(arguments);
                this.initTable();

                // 市町村切替時に避難情報を更新
                this.own(topic.subscribe('idis/view/form/MunicipalitySelectForm::selected',
                    lang.hitch(this, function (payload) {
                        this._municipalityCd = payload.municipalityCd;
                        this.initTable();
                    })));

                // 災害切替時に避難情報を更新
                this.own(topic.subscribe('app/view/form/DisasterChanger::updated',
                    lang.hitch(this, function () {
                        this.initTable();
                    })));
            },

            /**
             * テーブルを初期化します
             */
            initTable: function () {
                // 避難情報概況を設定
                this.setEvacorderOverviewInfo();

                // 避難情報一覧を設定
                this.setEvacorderListInfo();
            },

            /**
             * 避難情報概況を設定します
             */
            setEvacorderOverviewInfo: function () {
                var self = this;

                var disasterId = DisasterInfo.getDisasterId();

                var promise = Requester.get('/data/evacorder/overview/' +
                    disasterId + '/' +
                    this._municipalityCd + '.json', {
                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                    handleAs: 'json',
                    preventCache: true
                }).then(function (item) {
                    console.debug('避難情報（' + disasterId + ', ' + this._municipalityCd + '）：' +
                        JSON.stringify(item));

                    // 避難情報を設定
                    self.setEvacorderOverview(self, item);

                }, function (error) {
                    console.log(error);
                    if (error.response.status === 404) {
                        var item = {
                            'disasterOccurMunicNum': 0,
                            'disasterOccurEvaqueeNum': 0,
                            'disasterOccurHouseholdNum': 0,
                            'evacOrderMunicNum': 0,
                            'evacOrderEvaqueeNum': 0,
                            'evacOrderHouseholdNum': 0,
                            'evacPrepareMunicNum': 0,
                            'evacPrepareEvaqueeNum': 0,
                            'evacPrepareHouseholdNum': 0
                        };
                        self.setEvacorderOverview(self, item);
                    } else {
                        self.chain.info('情報の取得に失敗しました。', 'エラー');
                    }
                });
                //ローダーの表示
                Loader.wait(promise);
            },

            /**
             * 避難情報を設定します
             */
            setEvacorderOverview: function (self, item) {
                self.disasterOccurMunicNum.innerHTML = item.disasterOccurMunicNum || '0';
                self.disasterOccurHouseholdNum.innerHTML = item.disasterOccurHouseholdNum || '0';
                self.disasterOccurEvaqueeNum.innerHTML = item.disasterOccurEvaqueeNum || '0';

                self.evacOrderMunicNum.innerHTML = item.evacOrderMunicNum || '0';
                self.evacOrderHouseholdNum.innerHTML = item.evacOrderHouseholdNum || '0';
                self.evacOrderEvaqueeNum.innerHTML = item.evacOrderEvaqueeNum || '0';

                self.evacPrepareMunicNum.innerHTML = item.evacPrepareMunicNum || '0';
                self.evacPrepareHouseholdNum.innerHTML = item.evacPrepareHouseholdNum || '0';
                self.evacPrepareEvaqueeNum.innerHTML = item.evacPrepareEvaqueeNum || '0';
            },

            /**
             * 避難情報一覧を設定します
             */
            setEvacorderListInfo: function () {
                var self = this;

                var disasterId = DisasterInfo.getDisasterId();

                var promise = Requester.get('/data/evacorder/list/' +
                    disasterId + '/' + this._municipalityCd + '.json', {
                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                    handleAs: 'json',
                    preventCache: true
                }).then(function (item) {
                    console.debug('避難情報（' + disasterId + ', ' + this._municipalityCd + '）：' +
                        JSON.stringify(item));

                    // 避難情報を設定
                    self.setEvacorderList(self, item);

                }, function (error) {
                    console.log(error);
                    if (error.response.status === 404) {
                        var item = { 'items': [] };
                        self.setEvacorderList(self, item);
                    } else {
                        self.chain.info('情報の取得に失敗しました。', 'エラー');
                    }
                });
                //ローダーの表示
                Loader.wait(promise);
            },

            /**
             * 避難情報を設定します
             */
            setEvacorderList: function (self, data) {
                var EVAC_ORDER = {
                    '01': '避難準備${issueReason}発令',
                    '02': '避難勧告${issueReason}発令',
                    '03': '避難指示${issueReason}発令',
                    '04': '災害発生${issueReason}発令',
                    '11': '高齢者等避難${issueReason}発令',
                    '13': '避難指示${issueReason}発令',
                    '14': '緊急安全確保${issueReason}発令',
                    '81': '高齢者等避難${issueReason}解除',
                    '83': '避難指示${issueReason}解除',
                    '84': '緊急安全確保${issueReason}解除',
                    '91': '避難準備${issueReason}解除',
                    '92': '避難勧告${issueReason}解除',
                    '93': '避難指示${issueReason}解除',
                    '94': '災害発生情報${issueReason}解除'
                };
                var ISSUE_REASON = {
                    '01': '土砂',
                    '02': '洪水',
                    '03': '地震',
                    '04': '津波',
                    '05': '高潮',
                    '06': '火災',
                    '07': '暴風',
                    '08': '火山',
                    '09': '国民保護',
                    '00': 'その他'
                };

                var evacorderPanelTbody = dojo.byId('evacorderPanelTbody');
                domConstruct.empty(evacorderPanelTbody);

                // 先頭5件のみ表示
                if (data.items.length > this.MAX_COUNT) {
                    data.items.length = this.MAX_COUNT;
                }

                array.forEach(data.items, function (item) {
                    var datetimeString = self.formatDateTime(item.evacOrderTimestamp);
                    var evacOrder = EVAC_ORDER[item.evacOrderType] || '';
                    var issueReason = ISSUE_REASON[item.issueReasonType] || '';
                    var content = item.municipalityName + 'が' + item.districtSize + '地区に';
                    content += evacOrder.replace('${issueReason}', '（' + issueReason + '）');
                    var html = '<tr>';
                    html += '<td style="padding-left:10px; width:30%;">' + datetimeString + '</td>';
                    html += '<td style="width:55%;">' + content + '</td>';
                    html += '<td style="text-align: right; padding-right:10px; width:15%; color: blue;">';
                    html += '<u><a href="?evacOrderId=' + item.evacOrderId;
                    html += '&p=evacorder/detail">詳細&gt;</a></u></td>';
                    html += '</tr>';
                    var dom = domConstruct.toDom(html);
                    domConstruct.place(dom, evacorderPanelTbody);
                });

            },

            /**
             * 'yyyy-MM-dd HH:mm' 形式に変換
             */
            formatDateTime: function (val) {
                var timestamp = new Date(val);
                var dateLabel = locale.format(timestamp, {
                    selector: 'date',
                    datePattern: 'yyyy/MM/dd'
                });
                var timeLabel = locale.format(timestamp, {
                    selector: 'time',
                    timePattern: 'HH:mm'
                });
                return dateLabel + '&nbsp;' + timeLabel;
            },

            /**
             * 避難情報一覧ページに遷移
             */
            onEvacorderLinkClick: function (evt) {
                evt.preventDefault();

                if (this._municipalityCd && this._municipalityCd !== config.municInfo.prefMunicCd) {
                    Router.moveTo('evacorder', {
                        municipalityCd: this._municipalityCd
                    });
                } else if (UserInfo.getUserType() === USER_TYPE.MUNICIPALITY) {
                    Router.moveTo('evacorder', {
                        municipalityCd: UserInfo.getMunicipalityCd()
                    });
                } else {
                    Router.moveTo('evacorder/admin');
                }
            }

        });
});
