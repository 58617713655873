/**
 * テンプレート選択ダイアログ画面用モジュール。
 * @module app/sending/SendingSelectorDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/SendingSelectorDialog.html',
    'idis/view/dialog/InfoDialog',
    'idis/store/IdisRest',
    'idis/view/page/_PageBase',
    '../config',
    'app/model/Region',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button',
    'app/sending/SendingSelectorGrid'
], function(module, declare, lang, template, InfoDialog, IdisRest, WidgetBase, config, Region) {

    /**
     * テンプレート選択ダイアログ
     * @class SendingSelectorDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), WidgetBase,
        /** @lends app/view/Header~Header# */ {

        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        // baseClass: 'parentsending-selector',
        baseClass: 'idis-Page idis-Page--sending',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        /**
         * コンストラクタ
         */
        constructor: function() {
            this.inherited(arguments);
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'sendingTemplateId',
                target: '/api/sending/template'
            });
        },

        /**
         * DOM生成
         */
        buildRendering: function() {
            this.inherited(arguments);
            // グリッドを初期化
            this.initGrid();
        },

        /**
         * 画面生成完了
         */
        startup: function() {
            this.inherited(arguments);
        },

        /**
         * 画面を再表示
         */
        refresh: function() {
            this.container.resize();
            this.item = null;
            this.grid.refresh();
        },

        initContent: function(isEmergency, municipalityCd){
            this.updateGridQuery(isEmergency, municipalityCd);
        },

        /**
         * グリッド初期化
         */
        initGrid: function() {
            // グリッドの行選択イベント
            this.grid.on('applyButtonClick', lang.hitch(this, function(evt) {
                // 選択された行データを退避
                this.item = evt.item;
                this.onSubmit();
            }));
        },

        // 選択したコードがregionCdなのかmunicipalityCdなのか確かめる。
        // regionCdの場合はtrueが帰る
        isRegion: function(cd){
            return Region.store.get(cd).then(lang.hitch(this, function(region){
                return region ? true : false;
			}));
        },

        updateGridQuery: function(isEmergency, municipalityCd){
            var filter = new this.store.Filter();

            // テンプレート配信種別
            if (isEmergency) {
                filter = filter.eq('templateSendType', '1');
            } else {
                filter = filter.eq('templateSendType', '0');
            }

            // 市町村
            this.isRegion(municipalityCd).then(lang.hitch(this, function(isRegion){
                if(isRegion){
                    filter = filter.eq('regionCd', municipalityCd);
                } else if(municipalityCd && municipalityCd !== config.municInfo.prefCd){
                    filter = filter.eq('municipalityCd', municipalityCd);
                }
            }));

            var collection = this.store.filter(filter);
            this.grid.set('collection', collection);
        },

        /**
         * テンプレート変更実行
         */
        onSubmit: function() {
            if (!this.item) {
                InfoDialog.show('入力チェック', 'テンプレートが選択されていません');
                return false;
            }
            this.emit('update', {
                data: this.item
            });
        }
    });
});
