/**
 * Xoblosエラーダイアログ用モジュール。
 * @module app/common/ErrorMessageDialogForXoblos
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/date',
    'dojo/dom-style',
    'dojo/text!./templates/ErrorMessageDialogForXoblos.html', // テンプレート文字列
    'idis/view/page/_PageBase' ,// 共通基底クラス
    'idis/util/FilesUtils',
    // 以下、変数として受け取らないモジュール
    'dijit/layout/ContentPane',
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'idis/view/form/DateInput',
    'idis/view/form/Button',
    'app/common/ErrorMessageDialogForXoblos'
], function(module, declare, date, domStyle, template, _PageBase, FilesUtils) {


        /**
         * <クラスの説明>
         * @class <ウィジェット名>
         * @extends module:idis/view/page/_PageBase~_PageBase
         */
        return declare(module.id.replace(/\//g, '.'), _PageBase,
            /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素のCSSクラス
            baseClass: 'observation-ErrorMessageDialogForXoblos',

            /**
            * データ格納用オブジェクト
            * @type {module:dstore/Store}
            */
            _uri: null,
            _filename: null,
            _download: null,

            constructor: function() {
            },

            // DOMノードを生成するためのメソッド
            buildRendering: function() {
                this.inherited(arguments);
            },

            /**
             * エラーファイルへアクセスする
             */
            onClickErrFileDownload: function() {
                var uri = this._uri;
                var fileName = this._filename;

                FilesUtils.downloadGet(uri,fileName);
            },

            /**
             * エラーダイアログ初期化処理
             */
            initErrorMessageDialogForXoblos: function(code, uri , filename) {
                var title = '';
                var message = '';
                var self = this;
                this._uri = uri;
                this._filename = filename;

                // ステータスコードが200の場合、responseのjsonの中身に応じてダイアログを出し分ける。
                switch(code) {
                    case 1:
                        title = '入力エラー';
                        message = '帳票インポート時にエラーが発生しました。';
                        domStyle.set(this.DownloadButton, 'display', '');
                        break;
                    case 2:
                        title = 'フォーマットエラー';
                        message = '帳票インポート時にエラーが発生しました。';
                        domStyle.set(this.DownloadButton, 'display', 'none');
                        break;
                    case 9:
                        title = 'システムエラー';
                        message = '帳票インポート時にエラーが発生しました。システム管理者にお問い合わせください。';
                        domStyle.set(this.DownloadButton, 'display', 'none');
                        break;
                    default :
                        console.log('ResultCodeが不正です。 ResultCode = ', code);
                        title = 'システムエラー';
                        message = '帳票インポート時にエラーが発生しました。システム管理者にお問い合わせください。';
                        domStyle.set(this.DownloadButton, 'display', 'none');
                        break;
                }
                this.getParent().set({
                    title: title
                });
                self.errMessage.innerText = message;
            }


        });

});
