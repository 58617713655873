/**
 * 表示情報のディレクトリーのみ表示する。
 * @module app/model/LayerDirectory
 */
define([
    'idis/model/UserInfo',
    'idis/store/JsonLayerModel',
    './LayerStore'
], function(UserInfo, JsonLayerModel, LayerStore) {
    /**
     * シングルトンを返す。
     */
    return new JsonLayerModel({
        rootId: 0,
        store: LayerStore,
        filter: function(item) {
            if (item.infoCategoryCd.indexOf('T') === 0) {
                // ディレクトリー（情報種別コードが'T'で始まる）は子要素がある場合だけ表示
                return item.children && item.children.length;
            } else if (UserInfo.hasAccess(item)) {
                return !item.layerUrl || item.infoCategoryCd.indexOf('D108') !== -1;
            }
        }
    });
});
