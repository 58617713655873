/**
 * 避難所選択用入力パーツ
 * 
 * @module app/view/form/CustomizableFacilitiesSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/form/TreeSelector',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'idis/view/dialog/InfoDialog',
    'app/config'
], function(module, declare, TreeSelector, CacheStoreModel, CacheTreeRest,
             UserInfo, USER_TYPE, InfoDialog, config) {
    /** 
     * 避難所選択用パーツ。
     * 
     * @class ShelterSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:app/view/tree/CustomizableFacilitiesSelector~CustomizableFacilitiesSelector# */ {
        // 選択ダイアログのタイトル
        title: '避難所選択',

        // ツリー・モデル
        model: null,

        // 選択不可フラグ
        prefUnselectable: false,
        regionUnselectable: false,
        municipalityUnselectable: false,
        facilityUnselectable: false,

        // 施設種別
        facilityTypeId: '01',

        postMixInProperties : function() {
            var prefectureFlg = this.prefectureFlg;
            var facilityFlg = this.facilityFlg;
            var facilityTypeId = this.facilityTypeId;
            var option = '&prefectureFlg=' + prefectureFlg;
            option += '&facilityFlg=' + facilityFlg;
            option += '&facilityTypeId=' + facilityTypeId;
            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                    target: '/api/facility/customizableFacilities4Tree?' + option
                })
            });
        },

        startup: function() {
            // 所属自治体を初期値とする
            switch (UserInfo.getUserType()) {
                case USER_TYPE.PREFECTURE:
                    // 県ユーザーのみクリアボタン有効
                    break;
                case USER_TYPE.REGION:
                    this.set('value', UserInfo.getRegionCd());
                    this.clearButton.setDisabled(true);
                    break;
                case USER_TYPE.MUNICIPALITY:
                    if (UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd &&
                        this.facilityUnselectable === true){
                        this.set('noAllButton', true);
                    }
                    this.clearButton.setDisabled(true);
                    this.set('value', UserInfo.getMunicipalityCd());
                    break;
                case  USER_TYPE.OTHER_ORGAN:
                    this.set('noAllButton', true);
                    this.clearButton.setDisabled(true);
                    break;
            }
        },

        _setValueAttr: function(value) {
            if(this.model.items){
                var type = '';
                for(var k = 0; k < this.model.items.length; k++) {
                    if(this.model.items[k].id === value){
                        type = this.model.items[k].type;
                    }
                }

                if(this.prefUnselectable){
                    // 県選択不可
                    if(type === 'Prefecture'){
                        InfoDialog.show('エラー', '県は選択することができません。');
                        return false;
                    }
                }
                if(this.regionUnselectable){
                    // 振興局選択不可
                    if(type === 'Region'){
                        InfoDialog.show('エラー', '振興局は選択することができません。');
                        return false;
                    }
                }
                if(this.municipalityUnselectable){
                    // 市町村選択不可
                    if(type === 'Municipality'){
                        InfoDialog.show('エラー', '市町村を選択することはできません。');
                        return false;
                    }
                }
                if(this.facilityUnselectable){
                    // 施設選択不可
                    if(type === 'Facility'){
                        InfoDialog.show('エラー', '施設を選択することはできません。');
                        return false;
                    }
                }
            }

            this.inherited(arguments);
        }
    });
});

