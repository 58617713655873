/**
 * 雨量画面の凡例UIウィジェット
 * @module app/observation/rain/RainfallLegend
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/RainfallLegend.html',
    'idis/view/page/_PageBase',
    '../view/ObservatoryLegend'
    // 以下、変数から参照されないモジュール
], function(module, declare, template, _PageBase) {
    /**
     * 雨量画面の凡例UIウィジェット。
     * @class RainfallLegend
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/observation/rain/RainfallLegend~RainfallLegend# */ {
        // テンプレート文字列
        templateString: template

    });
});