/**
 * 体制詳細ダイアログにおける体制情報取消ダイアログ
 * @module app/disasterprevention/view/DisasterPreventionDeleteDialog
 */
define([
'module', // モジュールのパスを返す
'dojo/_base/declare', // Dojoのクラス定義用モジュール
'dojo/text!./templates/DisasterPreventionDeleteDialog.html', // テンプレート文字列
'idis/view/page/_PageBase', // 共通基底クラス
// 以下、変数で受けないモジュール
'idis/view/form/Button',
'dijit/form/RadioButton',
'dijit/form/Form'
], function(module, declare, template, _PageBase) {
	/**
	 * 体制詳細ダイアログにおける体制情報取消ダイアログ
	 * @class DisasterPreventionDeleteDialog
	 * @extends module:idis/view/page/_PageBase~_PageBase
	 */
	return declare(module.id.replace(/\//g, '.'), _PageBase,
	/** @lends module:app/disasterprevention/view/DisasterPreventionDeleteDialog~DisasterPreventionDeleteDialog# */
	{
		// テンプレート文字列
		templateString: template,

		onSubmit: function() {
			var deleteTarget = this.form.get('value').deleteTarget;
			this.emit('send-delete-target', {
				allDelete: deleteTarget==='2'
			});
		}
	});
});
