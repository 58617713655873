define([
    'module',
    'dojo/_base/declare',
    'dojo/on',
    'idis/view/form/Button',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, on, Button, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        // スクロール外に出た行を自動除去しない    
        farOffRemoval: Infinity,

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            {
                label: '登録',
                field: 'register',
                sortable: false,
                renderCell: function(value) {
                    if(value.format === 'WMS' || value.format === 'OGC WMS') {
                        var gridNode = this.grid.domNode;
                        var button = new Button({
                            label: '登録',
                            onClick: function() {
                                on.emit(gridNode, 'showRegisterDialog', {item: value});
                            }
                        });
                        return button.domNode;
                    }else{
                        return null;
                    }
                }
            },
            helper.column('name', 'リソース名', {sortable: false}),
            helper.column('format', '形式', {sortable: false}),
            helper.column('created', '作成日時' , {
                formatter: function(value){
                    var date = new Date(value);
                    var convertedDate = date.getFullYear() + '/' + date.getMonth() + '/' + date.getDate();
                    return convertedDate ? convertedDate : '-';
                },
                sortable: false
            }),
            helper.column('metadataModified', '更新日時' , {
                formatter: function(value){
                    var date = new Date(value);
                    var convertedDate = date.getFullYear() + '/' + date.getMonth() + '/' + date.getDate();
                    return convertedDate ? convertedDate : '-';
                },
                sortable: false
            }),
            helper.column('description', '備考', {sortable: false}),
        ]
    });
});
