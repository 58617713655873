/**
 * <ダム諸量一覧用グリッド>
 *
 * @module app/dam/grid/DamPageGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'app/view/grid/CommonColumn',
    'app/riverBasin/grid/RiverBasinCommonColumns',
    './DamCommonColumn'
], function(module, declare, IdisGrid, helper, CommonColumn, RiverBasinCommonColumns, DamCommonColumn) {
    /**
     * ダム諸量一覧用グリッド
     *
     * @class DamPageGrid
     * @extends module:idis/view/grid/IdisGrid~IdisGrid
     */
    return declare(module.id.replace(/\//g, '.'), IdisGrid,
        /** @lends module:app/dam/grid/DamPageGrid~DamPageGrid# */
    {
        columns : [
			helper.column('regionName', '地域'),
            CommonColumn.municipalityCd,
            helper.column('damName', 'ダム名', { sortable: false }),
            helper.buttonColumn('detail', '詳細'),
            DamCommonColumn.damEffectiveStorageQuantities,
            DamCommonColumn.storageRateWaterUseCapacity,
            DamCommonColumn.damInflowQuantities,
            DamCommonColumn.damTotalReleaseQuantities,
            helper.column('limitLevel', '基準値', {
                sortable : false,
                children : [
                    CommonColumn.thresholdDissotable('designFloodLevel', 'サーチャージ水位\n[m]'),
                    CommonColumn.thresholdDissotable('normalFullWaterLevel', '常時満水位\n[m]')
                    // CommonColumn.thresholdDissotable('floodWaterLevel', '設計洪水位\n[m]'),
                    // CommonColumn.thresholdDissotable('minWaterLevel', '最低水位\n[m]')
                ]
            }),
            //             formatter: function(value, item) {
            //                 return CommonColumn.formatObservationData(value, item.waterUsageQuantitiesFlg);
            //             },
            //             className : function(item) {
            //                 // ヘッダーの場合はclassNameを返さない
            //                 if (!item) { return; }
            //                 return CommonColumn.getClassNameExcess(item.waterUsageQuantities, '0',
            //                     item.waterUsageQuantitiesFlg);
            //             }, sortable: false
            //         }),
            // helper.column('floodControlQuantities', '治水量(1000m^3)', {
            //             formatter: function(value, item) {
            //                 return CommonColumn.formatObservationData(value, item.floodControlQuantitiesFlg);
            //             },
            //             className : function(item) {
            //                 // ヘッダーの場合はclassNameを返さない
            //                 if (!item) { return; }
            //                 return CommonColumn.getClassNameExcess(item.floodControlQuantities, '0',
            //                     item.floodControlQuantitiesFlg);
            //             }, sortable: false
            //         }),
            // helper.column('storageRateEffectiveCapacity', '貯水率(有効容量) (%)', {
            //             formatter: function(value, item) {
            //                 return CommonColumn.formatObservationData(value&&value.toFixed(2),
            //                     item.storageRateEffectiveCapacityFlg);
            //             },
            //             className : function(item) {
            //                 // ヘッダーの場合はclassNameを返さない
            //                 if (!item) { return; }
            //                 return CommonColumn.getClassNameExcess(item.storageRateEffectiveCapacity, '0',
            //                     item.storageRateEffectiveCapacityFlg);
            //             }, sortable: false
            //         }),
            // helper.column('storageRateWaterUseCapacity', '貯水率(利水容量) (%)', {
            //             formatter: function(value, item) {
            //                 return CommonColumn.formatObservationData(value&&value.toFixed(2),
            //                     item.storageRateWaterUseCapacityFlg);
            //             },
            //             className : function(item) {
            //                 // ヘッダーの場合はclassNameを返さない
            //                 if (!item) { return; }
            //                 return CommonColumn.getClassNameExcess(item.storageRateWaterUseCapacity, '0',
            //                     item.storageRateWaterUseCapacityFlg);
            //             }, sortable: false
            //         }),
            RiverBasinCommonColumns.rainfall60Min
        ]
    });
});
