/**
 * 県・振興局・市町村選択用入力パーツ（区無し）
 * @module idis/view/form/PrefRegionMunicipalitySelector
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/form/TreeSelector',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest'
], function(module, declare, TreeSelector, CacheStoreModel, CacheTreeRest) {
    /**
     * 県/振興局/市町村選択用入力パーツ
     * @class FacilityTypeSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */

    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:idis/view/form/PrefRegionMunicipalitySelector~PrefRegionMunicipalitySelector# */ {
        // 選択ダイアログのタイトル
        title: '行政区',

        // ツリー・モデル
        model: null,

        constructor : function() {
            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                    target: '/api/municipalities/prefRegionMunicipality4Tree'
                })
            });
        }
    });
});
