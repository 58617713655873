/**
 * 市町村を扱うモデル。
 * @module app/model/NoPrefMunicipality
 */
define([
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest'
], function(CacheStoreModel, CacheTreeRest) {
    /**
     * シングルトンを返す。
     */
    return new CacheStoreModel({
        store: new CacheTreeRest({
            target: 'dojo/app/municipality/noPrefMunicipality.geojson'
        })
    });
});
