/**
* 掲示板詳細画面用モジュール。
* @module app/bbs/BbsDetailPage
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/promise/all',
    'dstore/Memory',
    'dstore/Trackable',
    'dojo/text!./templates/BbsDetailPage.html',
    'dojo/dom-style',
    'dojo/topic',
    './_BbsPageBase',
    '../config',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/view/dialog/DialogChain',
    'idis/store/IdisRest',
    'idis/service/Requester',
    'idis/view/Loader',
    'dojox/lang/functional/array',
    'idis/model/UserInfo',
    'app/model/DisasterInfo',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/FilteringSelect',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'dijit/form/NumberTextBox',
    'dijit/form/Textarea',
    'dijit/form/CheckBox',
    'dijit/form/RadioButton',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/TitlePane',
    'dijit/layout/TabContainer',
    'dojox/form/Uploader',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'idis/view/form/WordCountTextarea',
    'app/view/form/ShareOrgsMultiSelector4Chronology',
    './BbsReplyForm',
    './BbsReply',
    './BbsReplyGrid',
    './BbsReadGrid'
    ], function(module, declare, lang, array, all,  Memory, Trackable, template, domStyle, topic, _BbsPageBase, config,
        Locator, Router, DialogChain, IdisRest, Requester, Loader, df, UserInfo, DisasterInfo) {

        /**
        * 掲示板詳細画面
        * @class BbsDetailPage
        * @extends module:idis/view/page/_PageBase~_PageBase
        */
        return declare(module.id.replace(/\//g, '.'), _BbsPageBase,
            /** @lends module:app/bbs/BbsDetailPage~BbsDetailPage# */ {
                // テンプレート文字列
                templateString: template,

                // ルート要素に付与されるCSS
                baseClass: 'idis-Page idis-Page--bbs',

                /**
                 *  データ格納用オブジェクト
                 * @type {module:dstore/Store}
                 */
                BbsReplyStore: null,

                /**
                 * 管理対象宛先
                 */
                _targetShares: [],

                /**
                * ホワイトボードID
                */
                _bbsId: null,

                /**
                * バージョン
                */
                _version: null,

                attachFileList: [],

                constructor: function() {
                    // 掲示板IDを取得
                    this._bbsId = Locator.getQuery().bbsId;
                    // 掲示板返信コメントデータ格納用オブジェクト
                    this.BbsReplyStore = new IdisRest({
                        idProperty: 'bbsReplyId',
                        target: '/api/bbses/reply/'+ this._bbsId
                    });
                    // ダイアログ連鎖を登録
                    // 引数に与えたウィジェットのthis.ownを呼び出し、
                    // ウィジェットが破棄された際にダイアログ連鎖が破棄されるようになる
                    this.chain = DialogChain.get(this);
                    
                    // 災害IDをDisasterInfoから取得
                    this._disasterId = DisasterInfo.getDisasterId();
                    if(!this._disasterId) {
                        this._disasterId = 1;
                        console.warn('災害IDが設定させていません。');
                    }
                },

                // DOMノードを生成するためのメソッド
                buildRendering: function() {
                    this.inherited(arguments);
                    this.initDetailPage(arguments);
                    // 掲示板返信コメントの新規登録
                    this.own(topic.subscribe('app/bbs/BbsReplyForm::initReply',
                        lang.hitch(this, function(){
                            this.initBbsReplyGrid();
                            this.replyForm.clearReplyForm();
                    })));
                },

                // DOMノード生成後に呼ばれる
                postCreate: function() {
                    this.inherited(arguments);
                },

                // HTML上にウィジェットが設置されてから呼ばれる
                startup: function() {
                    this.inherited(arguments);
                    this.initBbsReplyGrid();
                    this.initBbsReadGrid();
                    // 新規登録・詳細画面で利用するTipsをセットする
                    this.setTipsPopups();
                },

                /**
                 * 詳細画面を初期化する。
                 */
                initDetailPage: function () {
                    this.readStore = Trackable.create(new Memory({
                        idProperty: 'userId'
                    }));
                    var page = this.form;
                    try {
                        var promise = Requester.get('/api/bbses/' + this._bbsId)
                        .then(lang.hitch(this,function (data) {
                            var item = data;
                            this._bbsId = item.bbsId;
                            page.set('value', item);

                            // 宛先を設定
                            var shareCds = [];
                            var bbsShares = item.bbsShares;
                            df.forIn(bbsShares, function(share) {
                                // 宛先フラグがON且つ、
                                // 対象発信グループ番号が設定されていない項目を取得する
                                if (share.destinationFlg === '1') {
                                    var shareCd = share.organizationCd && share.organizationCd !== null ?
                                        share.organizationCd :
                                        share.municipalityCd && share.municipalityCd !== null ?
                                            share.municipalityCd : share.regionCd;
                                    shareCds.push(shareCd);
                                    this._targetShares.push(share);
                                }
                            }, this);
                            this.shareMunicipalityCd._setValueAttr(shareCds);
                            // 添付ファイルを設定
                            this.attachFileList = [];
                            if(item.attachFiles) {
                                for(var i=0; i<item.attachFiles.length; i++) {
                                    this.attachFileList.push(item.attachFiles[i]);
                                    this.showPreview(item.attachFiles[i], false);
                                }
                            }
                            // 既読情報をuserIdでソートしてからメモリーストアにセットする
                            if(item.bbsDeliveredUsers){
                                item.bbsDeliveredUsers.sort(function(a, b) {
                                    if (a.userId < b.userId) {
                                        return -1;
                                    }
                                    if (a.userId > b.userId) {
                                        return 1;
                                    }
                                    return 0;
                                }).forEach(function(object) {
                                    this.readStore.add(object);
                                }, this);
                            }
                        }));
                        Loader.wait(promise);
                        this.onNotifyFlgChange();
                    } catch (e) {
                        console.error(e);
                    }
                },

                /**
                 * 掲示板返信コメントグリッドを初期化する。
                 * startup時、続報セレクタ選択時、対応状況の登録更新削除時
                 */
                initBbsReplyGrid: function() {
                    this.replyGrid.set('collection', this.BbsReplyStore);

                    this.replyGrid.on('updateReplyButtonClick', lang.hitch(this, function(evt) {
                        //[編集中]メッセージの付け替え
                        if(this.replyForm.selectedReply && this.replyForm.selectedReply.editingMsg){
                            domStyle.set(this.replyForm.selectedReply.editingMsg, 'display', 'none');
                        }
                        this.replyForm.selectedReply = evt.selectedReply;
                        domStyle.set(this.replyForm.selectedReply.editingMsg, 'display', '');
                        this.replyForm.showUpdateReplyForm(evt.item);
                    }));
                },

                /**
                 * 既読状況グリッドを初期化する。
                 * startup時
                 */
                initBbsReadGrid: function() {
                    if(!UserInfo.hasWriteAuthz("F07001")){
                        // 利用者ユーザーの時、既読状況タブを非表示にする。
                        this.tabContainer.removeChild(this.tab2);
                    } else {
                        // 管理者ユーザーの時、既読状況グリッドをセットする。
                        this.readGrid.set('collection', this.readStore);
                    }
                },

                onSubmit: function() {

                    var sendData = this.form.get('value');
        
                    // フォームのバリデーションを実施
                    if (!this.form.validate()) {
                        return false;
                    }
        
                    // フォームのバリデーションを実施（独自チェック）
                    // 入力チェック（未読通知掲載期限）
                    if (sendData.notifyFlg === '02' && sendData.pubEndTimestamp === null) {
                        this.chain.info('未読通知掲載期限を入力してください。', '入力エラー');
                    return false;
                    }
                    // 入力チェック（宛先）
                    if(sendData.shareMunicipalityCd.length < 1) {
                        this.chain.info('宛先を選択してください。', '入力エラー');
                        return false;
                    }
        
                    // 掲示板IDをセット
                    sendData.bbsId = this._bbsId;
                    // 災害IDをセット
                    sendData.disasterId = this._disasterId;
        
                    // NOTE:長崎県では、宛先の多段階管理を行う。
                    // 取得する宛先マップが2種類存在するため、配信元の場合と宛先の場合で
                    // 取得するマップとツリーを変更する。
                    var bbsShareOrgs = [];
        
                    // セレクタからフルチェックされた自治体コードを取得する
                    var shareMap = this.shareMunicipalityCd.tree._checkMap;
                    var excludeMap = {};
                    var tree = this.shareMunicipalityCd.tree;
                    var isMunicipality = false;
                    df.forIn(shareMap, function(item) {
                        if(tree.isEveryChildChecked(item)){
                            // 子が全てチェックされている項目
                            // NOTE: 長崎県では市町と組織を同じセレクタで管理する
                            // idが4桁未満のものはアイテムをまとめるダミーなので除外し、
                            // 4桁以上のもののみ処理を行う
                            if (tree.model.store.getIdentity(item).length >= 4) {
                                tree.getItemChildren(item).then(lang.hitch(this, function(items) {
                                    if(!items){
                                        return false;
                                    }
                                    // 子を全て除外リストに登録する
                                    array.forEach(items, function(item){
                                        excludeMap[tree.model.store.getIdentity(item)] = true;
                                    }, this);
                                }));
                            } else {
                                // ダミー項目も除外リストに登録する
                                excludeMap[tree.model.store.getIdentity(item)] = true;
                            }
                        }
                    }, this);

                    // 再度セレクタの値に対してチェックし、
                    // 除外リストに登録されていない項目を配列に格納していく
                    df.forIn(shareMap, function(item) {
                        var cd = tree.model.store.getIdentity(item);
                        if(!excludeMap[cd]){
                            bbsShareOrgs.push(item);
                        }
                        if (cd.substring(0, 1) !== 'D') {
                            // 市町が設定されている
                            isMunicipality = true;
                        }
                    }, this);
        
                    // 共有先情報を設定する。
                    var bbsShares = [];
        
                    // 項目ごとに処理
                    all(
                        array.map(bbsShareOrgs, function(org){
                            // 各アイテムの自治体要素を取得する
                            return this.getRegionMunicipalityInfo(this.shareMunicipalityCd.getRegionMunicipalityInfo(org).id);
                        }, this)
                    ).then(lang.hitch(this, function(municInfos){
                        // 共有先組織の数だけループする
                        array.forEach(municInfos, function(infos, index){
                            // 仮想ルートと全体を宛先から除く
                            if(infos && infos.id !== '$ROOT$' && infos.id !== '0'){
                                var share = {};
                                var shareOrg = {};
                                share.senderFlg = '0';
                                share.destinationFlg = '1';
                                // 組織情報をセット
                                shareOrg = this.setOrganizationFromTreeObject(bbsShareOrgs[index]);
                                // 組織が設定されていれば市町 + 組織名、市町のみであれば市町名
                                shareOrg.name = shareOrg.deptCd && shareOrg.deptCd !== null ?
                                    infos.name + shareOrg.name : shareOrg.name;
                                // 部・課・係コード・最下層組織コードを設定する
                                share.deptCd = shareOrg.deptCd;
                                share.sectCd = shareOrg.sectCd;
                                share.unitCd = shareOrg.unitCd;
                                share.organizationCd = shareOrg.unitCd ? shareOrg.unitCd :
                                    shareOrg.sectCd ? shareOrg.sectCd : shareOrg.deptCd;
        
                                // 市町コード(振興局はnull)
                                share.municipalityCd = infos.id.substring(0,3) === config.municInfo.prefRegCdGudge &&
                                    infos.id !== config.municInfo.prefMunicCd ? null : infos.id;
                                // 振興局コード(振興局以外はnull)
                                share.regionCd = infos.id.substring(0,3) === config.municInfo.prefRegCdGudge &&
                                    infos.id !== config.municInfo.prefMunicCd ? infos.id : null;
                                // 自治体名 + 組織名
                                share.shareName = shareOrg.name;
                                bbsShares.push(share);
                            }
                        }, this);
        
                        //  共有先情報をセットする
                        sendData.bbsShares = bbsShares;
        
                        //添付ファイルIDをセット
                        if(this.attachFileList) {
                            var attachFileIds = [];
                            for(var i=0; i<this.attachFileList.length; i++) {
                                attachFileIds.push(this.attachFileList[i].bbsAtcFileId);
                            }
                            sendData.attachFileIds = attachFileIds.join(',');
                        } else {
                            sendData.attachFileIds = '';
                        }
        
                        delete sendData.attachFile;
                        delete sendData.shareMunicipalityCd;
        
                        this.updateBbs(sendData);
                    }));
                },

                /**
                * 掲示板情報を更新する。
                */
                updateBbs: function(sendData){
                    this.chain.confirmPut(function(chain) {
                        Loader.wait(Requester.put('/api/bbses/update/' + sendData.bbsId, {
                            data: sendData
                        })).then(lang.hitch(this, function() {
                            // 成功時（PUT結果はグリッドが自動的に反映）
                            // 完了ダイアログの表示
                            chain.infoComplete(function() {
                                // 掲示板一覧ページに遷移
                                Router.moveTo('bbs');
                            }, function(err) {
                                // 失敗時
                                chain.infoError(err);
                            });
                        }));
                    });
                },

                /**
                 * 掲示板を削除する。
                 */
                deleteBbsButtonClick: function() {
                    // 削除確認ダイアログの表示
                    this.chain.confirmDel(function(chain) {
                        // リクエストの発行からレスポンス受信後の処理までをpromiseに格納
                        var promise = Requester.del('/api/bbses/delete/' + this._bbsId)
                        .then(function(){
                            // 処理成功時は完了ダイアログを表示
                            chain.infoComplete(function() {
                                Router.moveTo('bbs');
                            });
                        }, function(err) {
                            // 処理失敗時はエラーログを出力し、エラーダイアログを表示
                            console.error(err);
                            chain.infoError(err);
                        });
                        // ローダの表示
                        Loader.wait(promise);
                    });
                },

                // 未読通知掲載期限を表示・非表示
                onNotifyFlgChange: function(){
                    var notifyFlg = this.form.get('value').notifyFlg;
                    // '01'=通知しない、'02'=通知する
                    if(typeof notifyFlg === 'undefined' || notifyFlg === null) {
                        domStyle.set(this.pubEndTimestampArea, 'display', 'none');
                    } else if(notifyFlg === '01') {
                        domStyle.set(this.pubEndTimestampArea, 'display', 'none');
                    } else if(notifyFlg === '02'){
                        domStyle.set(this.pubEndTimestampArea, 'display', '');
                    } else {
                        domStyle.set(this.pubEndTimestampArea, 'display', 'none');
                    }
                },

                onBbsLinkClick:function(evt) {
                    // ブラウザーの遷移処理をキャンセル
                    evt.preventDefault();
                    Router.moveTo('bbs');
                }
            }
        );
    }
);
