define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, lang, locale, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--chatShare',

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            helper.buttonColumn('detail', '詳細'),
            { field: 'chatGroupName', label: 'グループ名', sortable: false },
            { field: 'canPost', label: '投稿可否', sortable: false,
                formatter: function (value) {
                    if (value === true) {
                        return '可';
                    } else if (value === false) {
                        return '不可';
                    }
                    return '-';
                }
            },
            { field: 'unreadNum', label: '未読件数', sortable: false }
        ]
    });
});
