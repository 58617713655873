/**
 * ライフライン種別選択用入力パーツ
 * @module app/view/form/LifelineSelector
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/when',
    'idis/view/form/TreeSelector',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'app/model/DisasterInfo',
    'idis/view/dialog/InfoDialog'
], function(module, array, declare, lang, when, TreeSelector, CacheStoreModel,
    CacheTreeRest, DisasterInfo, InfoDialog) {
    /**
     * ライフライン種別選択用パーツ。
     * @class LifelineSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:idis/view/tree/RegionSelector~RegionSelector# */ {
        // 選択ダイアログのタイトル
        title: 'ライフライン種別選択',

        // ツリー・モデル
        model: null,

        constructor : function() {
            // 災害IDをDisasterInfoから取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定されていません。');
            }
            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                	// ライフライン種別をツリーで返す
                    target: '/api/lifeline/lifelineKind4Tree'
                })
            });
        },

        startup: function () {
            this.inherited(arguments);
            this.dialog.hideOnOK = false;
        },

        // value値を設定する
        // 継承元との差分は以下
        // - parentUnselectableが指定された場合、区フォルダは選択不可。
        _setValueAttr: function(value) {
            // 偽値は数値の0のみ有効とする
            if (value || value === 0) {
                if(this.parentUnselectable){
                    // 親種別は選択不可
                    if(this.tree !== null && this.tree.selectedItem.type === 'parent'){
                        InfoDialog.show('エラー', '種別（大項目）は選択することができません。');
                        return false;
                    }
                }
                this._set('value', value);
                this.emit('change', {value: value});
                this._initTree().then(lang.hitch(this, function() {
                    var model = this.tree.model;
                    var label;
                    if (this.fullName) {
                        label = model.getFullName(value);
                    } else {
                        label = when(model.store.get(value), function(item) {
                            return model.getLabel(item);
                        });
                    }
                    when(label, lang.hitch(this, function(name) {
                        this.status.innerHTML = name;
                    }));
                }));
            } else {
                this._set('value', '');
                this.emit('change', {value: ''});
                this.status.innerHTML = this.emptyValueLabel;
            }
            // 要素の選択状態をリセットする
            this._initTree().then(lang.hitch(this, function() {
                this.tree.set('selectedItem', null);
            }));
            this.dialog.hide();
        }
    });
});
