/**
 * 初回に全データを取得し、以降はキャッシュを利用するdijit/tree/model
 * @module idis/store/CacheStoreModel
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/when',
    'dijit/tree/ObjectStoreModel',
    '../error/InvalidArgumentException',
    './_FullNameModelMixin'
], function(module, array, declare, lang, Deferred, when, ObjectStoreModel,
    InvalidArgumentException, _FullNameModelMixin) {
    /**
     * 初回に全データを取得し、以降ルート要素と親子関係はキャッシュを利用するdijit/tree/model準拠クラス。
     * @class CacheStoreModel
     * @extends module:dijit/tree/model
     * @param {Object} kwArgs
     * @param {dojo/store/api/Store} [kwArgs.store] dojo/store/api/Storeオブジェクト
     * @param {Object} [kwArgs.cacheQuery] storeに対し問い合わせる際に指定するオプション
     */
    return declare([ObjectStoreModel, _FullNameModelMixin],
        /** @lends module:idis/model/CacheStoreModel~CacheStoreModel# */ {
        /**
         * キャッシュの取得結果
         * @type {Promise}
         */
        _cachePromise: null,

        /**
         * データ取得用store
         * @type {dojo/store/api/Store}
         */
        store: null,

        /*
         * フィルター関数。
         * 1引数に要素、第2引数に葉要素かどうか、第3引数にモデルを受け、残すかどうかを返す。
         * @type {function}
         */
        filter: null,

        /**
         * キャッシュ生成時にqueryに渡すパラメーター
         * @type {Object}
         */
        cacheQuery: null,

        // 引数取り込み後に呼ばれる
        postMixInProperties: function() {
            this.inherited(arguments);
            // プロパティー・チェック
            if (!this.store) {
                var message = '::postMixInProperties: Property "store" must be specified.';
                throw new InvalidArgumentException(module.id + message);
            }
        },

        /**
         * ツリー要素一覧を受け取り、IDと子要素一覧の対応を辞書にして返す。
         * @param {Object[]} items ツリー要素一覧
         * @returns {Object} IDと子要素一覧の対応
         */
        _createChildMap: function(items) {
            var childMap = {};
            // 親IDから親子関係を構築
            for (var i = 0; i < items.length; i++) {
                var item = items[i];
                var parentId = this.store.getParentIdentity(item);
                if (parentId === null || parentId === void 0) {
                    continue; // 親要素を持たない場合
                }
                // 親IDが初出の場合は子要素配列を初期化
                if (!childMap[parentId]) {
                    childMap[parentId] = [];
                }
                // 親IDの子要素一覧に要素を追加
                childMap[parentId].push(item);
            }
            return childMap;
        },

        /**
         * 指定されたツリー要素を起点として、条件を満たすツリー要素一覧を返す。
         * ツリー要素自体が条件を満たす場合、子孫要素は全て結果に含める。
         * ツリー要素自体が条件を満たさない場合、条件を満たす子孫要素が1つ以上ある場合に限りツリー要素自身を結果に含める。
         * @param {Object[]} item ツリー要素
         * @param {function} filter フィルター関数。
         *                   第1引数に要素、第2引数に葉要素かどうか、第3引数にモデルを受け、残すかどうかを返す
         * @returns {Object[]} フィルター条件を満たす要素とその子孫要素・先祖要素一覧
         */
        _filterSiblings: function(item, filter, childMap) {
            // 子要素一覧
            var children = childMap[this.getIdentity(item)];
            // 要素自身の結果（ルート要素の場合はフィルター関数を適用せず、マッチさせない）
            var matched = (item === this.root) ? false : filter(item, !children, this);
            // 要素自身がマッチする場合、子孫要素は全て残す
            if (matched) {
                filter = function() { return true; };
            }
            // 各子要素に対し再帰的に呼び出し
            var childrenResults = array.map(children, function(child) {
                return this._filterSiblings(child, filter, childMap);
            }, this);
            // 各子要素の結果をフラットな配列に格納
            var result = [];
            array.forEach(childrenResults, function(childResult) {
                array.forEach(childResult, function(childItem) {
                    result.push(childItem);
                });
            });
            // 要素自身がルート or マッチする or 子孫要素にマッチするものがある場合、この要素は残す
            if (item === this.root || matched || result.length) {
                result.unshift(item);
            }
            return result;
        },

        /**
         * 子要素一覧を比較し、内容が同じかどうかを判定して返す。
         * @param {Object[]} childrenA 子要素一覧A
         * @param {Object[]} childrenB 子要素一覧B
         * @returns {boolean} 子要素一覧の内容が同じならtrue、それ以外の場合はfalse
         */
        _isSameChildren: function(childrenA, childrenB) {
            // 長さが異なるならその時点で偽
            if (childrenA.length !== childrenB.length) {
                return false;
            }
            // 長さ0同士なら正
            if (childrenA.length) {
                return true;
            }
            // 子要素一覧Aの出現ID一覧を取得
            var idMapA = {};
            array.forEach(childrenA, function(item) {
                idMapA[this.getIdentity(item)] = true;
            }, this);
            // 子要素Bにしか存在しない要素が見つかった時点で偽
            // （子要素一覧の長さが同じなので、子要素一覧Aにしか存在しない要素があるときは
            //  必ず子要素一覧Bにしか存在しない要素が存在する）
            return array.every(childrenB, function(item) {
                return idMapA[this.getIdentity(item)];
            }, this);
        },

        /**
         * ツリー要素の親子関係をキャッシュする。
         * @param {Object[]} items ツリー要素一覧
         * @returns {Object<string,Object[]>} 識別子と子要素一覧の対応
         */
        _updateChildrenCache: function(items) {
            // キャッシュを初期化
            var lastCache = this.childrenCache;
            this.childrenCache = this._createChildMap(items);
            // フィルター関数が指定されている場合は絞り込む
            if (this.filter) {
                var filteredItems = this._filterSiblings(this.root, this.filter, this.childrenCache);
                this.childrenCache = this._createChildMap(filteredItems);
            }
            // 子要素一覧が変わった要素に対し更新イベントを発行
            array.forEach(items, function(item) {
                var itemId = this.getIdentity(item);
                var children = this.childrenCache[itemId] || [];
                var lastChildren = lastCache[itemId] || [];
                if (!this._isSameChildren(lastChildren, children)) {
                    this.onChildrenChange(item, children);
                }
            }, this);
            // キャッシュを返す
            return this.childrenCache;
        },

        /**
         * ルート要素と親子関係の情報が未取得であれば取得してフィールドに反映する。
         * 既に取得済みであれば何もしない。
         * @returns {Promise<None>} ルート要素と親子関係の情報が反映された時点で解決するPromise
         */
        _getCache: function() {
            // 問い合わせを一度も実行していなければ実施
            if (!this._cachePromise) {
                var dfd = new Deferred();
                this._cachePromise = dfd.promise;
                // storeに対して問い合わせを実施
                var res = this.store.query(this.cacheQuery);
                if (res.then) {
                    this.own(res); // クエリー中に破棄された場合用
                }
                // 問い合わせ結果をキャッシュ
                when(res, lang.hitch(this, function(items) {
                    this.items = items;
                    // ルート要素
                    this.root = items[0];
                    // 子要素の対応
                    this._updateChildrenCache(items);
                    // _cachePromiseを解決
                    dfd.resolve();
                }), dfd.reject);
            }
            // storeへの問い合わせが完了した時点で完了するPromiseを返す
            return this._cachePromise;
        },

        /**
         * フィルターをセットする。
         * @param {function} filter
         * @returns {Promise}
         */
        setFilter: function(filter) {
            this.filter = filter;
            // 初期化前の場合は何もしない
            if (!this._cachePromise) {
                return new Deferred().resolve();
            }
            // 初期化後の場合は親子関係を更新
            return this._getCache().then(lang.hitch(this, function() {
                this._updateChildrenCache(this.items);
            }));
        },

        /**
         * 指定されたツリー要素の子要素一覧を返す。
         * @param {Object} item ツリー要素
         * @param {function} onComplete 解決時に子要素一覧を引数として呼ばれる
         * @param {function} onError 失敗時にエラー内容を引数として呼ばれる
         */
        getChildren: function(item, onComplete, onError) {
            this._getCache().then(lang.hitch(this, function() {
                // 子要素一覧が無ければ空配列を返す
                onComplete(this.childrenCache[this.getIdentity(item)] || []);
            }), onError);
        },

        /**
         * 指定されたツリー要素の子要素識別子一覧を返す。
         * @param {Object} item ツリー要素
         * @returns {number[]|string[]} 子要素識別子の配列、子要素が存在しない場合は空配列
         */
        getChildrenIds: function(item) {
            // childrenCacheは実データを入れているのでそのまま返せる
            return array.map(this.childrenCache[this.getIdentity(item)] || [], function(child) {
                return this.getIdentity(child);
            }, this);
        },

        /**
         * ルート要素を取得する。
         * @param {function} onItem 解決時にルート要素を引数として呼ばれる
         * @param {function} onError 失敗時にエラー内容を引数として呼ばれる
         */
        getRoot: function(onItem, onError) {
            this._getCache().then(lang.hitch(this, function() {
                onItem(this.root);
            }), onError);
        },

        /**
         * 指定された要素が子要素を持っている可能性があるかどうかを返す。
         * @param {Object} item ツリー要素
         * @returns {boolean} 子要素を持っている可能性がある場合はtrue、それ以外の場合はfalse
         */
        mayHaveChildren: function(item) {
            // 同期呼び出しのため、キャッシュは存在することを前提とする
            var cache = this.childrenCache[this.getIdentity(item)];
            return cache && cache.length;
        }
    });
});
