/**
 * お知らせ種別編集関連UI用モジュール。
 * @module app/notice/view/NoticeTypeItem
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/dom-style',
    'dojo/text!./templates/NoticeTypeItem.html',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/_IdisWidgetBase',
    'idis/view/Loader',
    '../NoticeTypeModel',
    // 以下、変数として受け取らないモジュール
    'dijit/form/Form',
    'idis/view/form/AclButton',
    'idis/view/form/Button'
], function(module, declare, lang, domClass, domStyle, template,
    Requester, DialogChain, _IdisWidgetBase, Loader, NoticeTypeModel) {
    /**
     * お知らせ種別編集ボタンと編集ダイアログ。
     * @class NoticeTypeItem
     * @extends module:idis/view/_IdisWidgetBase~_IdisWidgetBase
     */
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase,
        /** @lends module:idis/view/page/NoticeTypeItem~NoticeTypeItem# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素のCSSクラス
        baseClass: 'notice-NoticeTypeItem',

        constructor: function() {
            // ダイアログ連鎖を登録
            // 引数に与えたウィジェットのthis.ownを呼び出し、
            // ウィジェットが破棄された際にダイアログ連鎖が破棄されるようになる
            this.chain = DialogChain.get(this);
        },

        buildRendering: function() {
            this.inherited(arguments);
            // 表示要素の状態を反映
            domStyle.set(this.nameNode, 'color', this.item.color || '');
            this.nameNode.innerHTML = this.item.noticeTypeName;
            this.upButton.set('disabled', !this.isUpAllowed);
            this.downButton.set('disabled', !this.isDownAllowed);
        },

        /**
         * お知らせ種別を上下に移動する。
         * @param {identifier} beforeId 移動後にこの要素の直後にある要素の識別子
         */
        moveItem: function(beforeId) {
            // 確認ダイアログを表示
            this.chain.confirmPut(lang.hitch(this, function(chain) {
                // 更新リクエストを実施
                Loader.wait(Requester.post('/api/notices/types/move', {
                    data: {
                        noticeTypeId: this.item.noticeTypeId,
                        beforeId: beforeId
                    }
                })).then(function() {
                    // 更新成功時は完了ダイアログを表示
                    chain.infoComplete(function() {
                        chain.hide().then(function() {
                            // 最新情報を取得
                            Loader.wait(NoticeTypeModel.load(true));
                        });
                    });
                }, function(err) {
                    // 更新失敗時
                    chain.infoError(err);
                });
            }));
        },

        /**
         * ↑ボタンがクリックされた際に呼ばれる。
         */
        onUpButtonClick: function() {
            this.moveItem(this.beforeIdForUp);
        },

        /**
         * ↓ボタンがクリックされた際に呼ばれる。
         */
        onDownButtonClick: function() {
            this.moveItem(this.beforeIdForDown);
        },

        /**
         * 編集ボタンがクリックされた際に呼ばれる。
         */
        onEditButtonClick: function() {
            this.form.set('value', this.item);
            // 編集用UIを表示して表示用UIを隠す
            domClass.add(this.domNode, 'is-edit');
        },

        /**
         * 削除ボタンがクリックされた際に呼ばれる。
         */
        onDeleteButtonClick: function() {
            // 確認ダイアログを表示
            this.chain.confirmDel(function(chain) {
                // 削除リクエストを実施
                Loader.wait(Requester.del('/api/notices/types/' + this.item.noticeTypeId)).then(function() {
                    // 削除成功時は完了ダイアログを表示
                    chain.infoComplete(function() {
                        chain.hide().then(function() {
                            // 最新情報を取得
                            Loader.wait(NoticeTypeModel.load(true));
                        });
                    });
                }, function(err) {
                    // 削除失敗時
                    chain.infoError(err);
                });
            });
        },

        /**
         * キャンセル・ボタンがクリックされた際に呼ばれる。
         */
        onCancelButtonClick: function() {
            // 編集用UIを隠して表示用UIを表示
            domClass.remove(this.domNode, 'is-edit');
        },

        /**
         * 更新ボタンが押された際に呼ばれる。
         */
        onSubmit: function() {
            try {
                if (this.form.validate()) {
                    var value = this.form.get('value');
                    // 確認ダイアログを表示
                    this.chain.confirmPut(function(chain) {
                        // 更新リクエストを実施
                        Loader.wait(Requester.put('/api/notices/types/' + value.noticeTypeId, {
                            data: value
                        })).then(function() {
                            // 更新成功時は完了ダイアログを表示
                            chain.infoComplete(function() {
                                chain.hide().then(function() {
                                    // 最新情報を取得
                                    Loader.wait(NoticeTypeModel.load(true));
                                });
                            });
                        }, function(err) {
                            // 更新失敗時
                            chain.infoError(err);
                        });
                    });
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        }
    });
});

