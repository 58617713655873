define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/topic',
    'dojo/text!./templates/MeasurePane.html',
    'idis/view/_IdisWidgetBase',
    'idis/view/dialog/_DialogContentMixin',
    './Area',
    './Distance',
    // 以下、変数で受けないモジュール
    'dijit/form/Select',
    'idis/view/form/RadioGroup'
], function(module, declare, lang, topic, template, _WidgetBase, _DialogContentMixin, Area, Distance) {
    return declare(module.id.replace(/\//g, '.'), [_WidgetBase, _DialogContentMixin], {
        // テンプレート文字列
        templateString: template,

        /**
         * 操作対象のMapオブジェクト
         * @type {Map}
         */
        map: null,

        /**
         * 面積計算オブジェクト
         * @type {Area}
         */
        area: null,

        /**
         * 距離計算オブジェクト
         * @type {Distance}
         */
        distance: null,

        /**
         * 最終計測結果
         * @type {number}
         */
        _lastResult: null,

        /**
         * MainMapからのPub/Sub URI
         * @type {String}
         */
        DISABLE_MEASURE: '/app/view/page/MapPage::disableMeasure',

        constructor: function(kwArgs) {
            // 計算用オブジェクトを初期化
            this.area = new Area(kwArgs.map);
            this.distance = new Distance(kwArgs.map);
            // 計算範囲決定を監視
            this.area.addEventListener('finish', this.onFinish, this);
            this.distance.addEventListener('finish', this.onFinish, this);
        },

        postCreate: function() {
            this.inherited(arguments);
            // 計算種別変更を監視
            this.own(this.typeRadio.watch('value', lang.hitch(this, function() {
                this._lastResult = null;
                this.updateResult();
                this.enableChecked();
            })));
            // 計測単位変更を監視
            this.own(this.unitRadio.watch('value', lang.hitch(this, function() {
                this.updateResult();
            })));
            // MainMapで他のDialogが開かれたらSubでそれを感知して自分自身を閉じる
            this.own(
                topic.subscribe(this.DISABLE_MEASURE, lang.hitch(this, function(){
                    this.onDialogHide();
                }))
            );
        },

        destroy: function() {
            // イベントの監視を解除
            this.area.clearAllEventListeners();
            this.distance.clearAllEventListeners();
            // 計算オブジェクトを無効化
            this.area.disable();
            this.distance.disable();
            // 通常の解体処理
            this.inherited(arguments);
        },

        /**
         * チェックされている計算を有効化する。
         */
        enableChecked: function() {
            if (this.typeRadio.get('value') === '0') {
                // 距離を有効化
                this.area.disable();
                this.distance.enable();
            } else {
                // 面積を有効化
                this.distance.disable();
                this.area.enable();
            }
        },

        /**
         * 距離計算が完了したタイミングで呼ばれる。
         * @type {Event} evt 完了イベント
         */
        onFinish: function(evt) {
            // 最終結果を記録
            this._lastResult = evt.result;
            this.updateResult();
        },

        /**
         * 計測結果欄に表示する文字列を取得する。
         * @returns {string} 計測結果文字列
         */
        _getResultText: function() {
            // 計測結果が存在しない場合は空にする
            if (!this._lastResult && this._lastResult !== 0) {
                return '&nbsp;';
            }
            if (this.typeRadio.get('value') === '0') {
                // 距離の場合
                if (this.unitRadio.get('value') === '0') {
                    // メートル
                    return this._lastResult.toFixed(2) + ' m';
                } else {
                    // キロメートル
                    return (this._lastResult / 1000).toFixed(5) + ' km';
                }
            } else {
                // 面積の場合
                if (this.unitRadio.get('value') === '0') {
                    // 平方メートル
                    return this._lastResult.toFixed(2) + ' m&sup2;';
                } else {
                    // 平方キロメートル
                    return (this._lastResult / 1000 / 1000).toFixed(8) + ' km&sup2;';
                }
            }
        },

        /**
         * 計測結果を画面に反映する。
         */
        updateResult: function() {
            this.resultNode.innerHTML = this._getResultText();
        },

        // ダイアログ表示時に有効化
        onDialogShow: function() {
            this._lastResult = null;
            this.updateResult();
            this.enableChecked();
        },

        // ダイアログ非表示時に無効化
        onDialogHide: function() {
            this.area.disable();
            this.distance.disable();
        }
    });
});
