/**
 * <流域平均雨量関連観測局用グリッド>
 *
 * @module app/riverBasin/grid/RelatedObservatoryGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    './RiverBasinCommonColumns'
], function(module, declare, IdisGrid, helper, RiverBasinCommonColumns) {
    /**
     * 流域平均雨量関連観測局用グリッド
     *
     * @class RelatedObservatoryGrid
     * @extends module:idis/view/grid/IdisGrid~IdisGrid
     */
    return declare(module.id.replace(/\//g, '.'), IdisGrid,
        /** @lends module:app/riverBasin/grid/RelatedObservatoryGrid~RelatedObservatoryGrid# */ {
        columns : [
            helper.column('observatoryName', '観測局名', { sortable: false }),
            RiverBasinCommonColumns.rainfall10Min,
            RiverBasinCommonColumns.rainfall60Min,
            RiverBasinCommonColumns.rainfallHourly,
            RiverBasinCommonColumns.rainfallCumulative
        ]
    });
});