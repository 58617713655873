/**
 * dgrid/Selectorを扱いやすいように拡張。
 * @module idis/view/grid/IdisSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojox/lang/functional/object',
    'dgrid/Selector',
    'dstore/Memory'
], function(module, declare, df, Selector, Memory) {
    /**
     * dgrid/Selectorを扱いやすいように拡張。
     * @class IdisSelector
     * @extends module:dgrid/Selector~Selector
     */
    return declare(module.id.replace(/\//g, '.'), Selector,
        /** @lends module:idis/view/grid/IdisSelector~IdisSelector# */ {
        // タイトル行に全選択チェックボックスを設置
        allowSelectAll: true,

        // 選択列以外の行要素クリック時にチェックさせない
        selectionMode: 'none',

        constructor: function() {
            // allowSelectAll有効時にエラーとならないよう空collectionを初期設定
            this.collection = new Memory();
        },

        /**
         * 選択されている要素ID一覧を返す。
         * @returns {string[]} 選択されている要素ID一覧
         */
        getSelectedIdList: function() {
            // selectionはIDをキー、選択状態を値とするオブジェクト
            // 選択状態が真のものに絞り込む
            var filtered = df.filterIn(this.selection, function(value) {
                return value;
            });
            // 要素ID一覧を返す
            return df.keys(filtered);
        }
    });
});

