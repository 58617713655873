/**
 * 市町内の組織選択用入力パーツ
 * @module app/view/form/MunicRegionOrganizationSelector
 */
 define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/when',
    'idis/view/form/TreeSelector',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'idis/view/dialog/InfoDialog',
    'idis/view/tree/IdisTree',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest'
], function(module, declare, lang, when, TreeSelector, UserInfo, UserType, InfoDialog,
    IdisTree, CacheStoreModel, CacheTreeRest) {
    /**
     * 組織選択用パーツ。
     * @class OrganizationSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:app/view/tree/MunicRegionOrganizationSelector~MunicRegionOrganizationSelector# */ {
        // 選択ダイアログのタイトル
        title: '組織選択',

        // ツリー・モデル
        model: null,

        // ツリーの取得レベル（MUnit, MSect, MDeptのどちか）
        level: 'MUnit',

        // 部選択不可ならtrueをセットする
        deptUnselectable: false,
        // 課選択不可ならtrueをセットする
        sectUnselectable: false,

        // 市町村コード
        _municipalityCd: null,
        // 市町村コード
        _municipalityCdChanged: true,
        // 振興局コード
        _regionCd: null,
        // 振興局コード
        _regionCdChanged: true,
        // リフレッシュができるかどうか
        _canRefresh: false,

        constructor: function() {
            this.inherited(arguments);
            // 組織を0県受け取るようダミーの市町コードを設定
            var municipalityCd = '000000';
            var query = '?municipalityCd=' + municipalityCd + '&level=' + this.level;
            this.model =  new CacheStoreModel({
                store: new CacheTreeRest({
                    target: '/api/organizations/tree' + query
                })
            });
        },

        // 現在がリフレッシュができるかどうかを取得する
        canRefresh: function() {
            return this._canRefresh;
        },

        // リフレッシュを停止する
        stopRefresh: function() {
            this._canRefresh = false;
        },

        // リフレッシュを再開する
        restoreRefresh: function() {
            this._canRefresh = true;
            this._refresh();
        },

        // 市町村コードを設定する
        setMunicipalityCd: function(municipalityCd) {
            if (this._municipalityCd !== municipalityCd) {
                this._municipalityCd = municipalityCd;
                this._municipalityCdChanged = true;
                if (this._canRefresh) {
                    this._refresh();
                }
            }
        },

        // 振興局コードを設定する
        setRegionCd: function(regionCd) {
            if (this._regionCd !== regionCd) {
                this._regionCd = regionCd;
                this._regionCdChanged = true;
                if (this._canRefresh) {
                    this._refresh();
                }
            }
        },

        // 市町村セレクタと振興局セレクタが変更された際、市町村＋振興局内の組織を取得し直す
        _refresh: function() {
            if (!this.tree || this._municipalityCdChanged || this._regionCdChanged) {
                this._municipalityCdChanged = false;
                this._regionCdChanged = false;
                var value = this.get('value');
                // 一旦ツリーを削除し、再構築する
                if (this.tree) {
                    this.tree.destroyRecursive();
                }
                var query = '?level=' + this.level;
                if (this._municipalityCd && this._municipalityCd !== '') {
                    query += '&municipalityCd=' + this._municipalityCd;
                }
                if (this._regionCd && this._regionCd !== '') {
                    query += '&regionCd=' + this._regionCd;
                }
                // TODO: この部分で[Cannot read property 'setAttribute' of null] というエラーを吐く場合があるが、
                // 画面上の不具合はないため対応を保留中。
                this.model = new CacheStoreModel({
                    store: new CacheTreeRest({
                        target: '/api/organizations/tree' + query
                    })
                });

                this.tree = new IdisTree({
                    model: this.model
                }, this.treeNode);

                // 再配置する
                this.tree.placeAt(this.parentTreeNode);
                this.tree.startup();

                var setValue = lang.hitch(this, function(value) {
                    this.set('value', value);
                });
                // いったん現在の値をクリアし、現在選択中の値が存在する場合、それを再設定する
                setValue('');
                var onError = lang.hitch(this, function() {});
                var onComplete = lang.hitch(this, function(items) {
                    for (var i=0; i<items.length; i++) {
                        var item = items[i];
                        var ids = this.model.getChildrenIds(item);
                        if (ids.length > 0) {
                            if (ids.indexOf(value) !== -1) {
                                // 現在選択中の値が存在する場合、それを再設定する
                                setValue(value);
                            } else if (ids.length > 0) {
                                this.model.getChildren(item, onComplete, onError);
                            }
                        }
                    }
                });
                this.model.getRoot(lang.hitch(this, function(root) {
                    var ids = this.model.getChildrenIds(root);
                    if (ids.indexOf(value) !== -1) {
                        setValue(value);
                    } else if (ids.length > 0) {
                        this.model.getChildren(root, onComplete, onError);
                    }
                }), onError);
            }
            return this.tree.onLoadDeferred;
        },

        /**
         * 選択した値の設定
         *
         * 継承元との差分は以下。
         * - deptUnselectable/sectUnselectableが指定された場合、部/課は選択不可。
         */
        _setValueAttr: function(value) {
            // 偽値は数値の0のみ有効とする
            if (value || value === 0) {
                if(this.deptUnselectable){
                    // 部選択不可
                    if(value.indexOf('D') === 0){
                        InfoDialog.show('エラー', '部は選択することができません。');
                        return false;
                    }
                }
                if(this.sectUnselectable){
                    // 課選択不可
                    if(value.indexOf('S') === 0){
                        InfoDialog.show('エラー', '振興局は選択することができません。');
                        return false;
                    }
                }
                this._set('value', value);
                this.emit('change', {value: value});
                this._initTree().then(lang.hitch(this, function() {
                    var model = this.tree.model;
                    var label;
                    if (this.fullName) {
                        label = model.getFullName(value);
                    } else {
                        label = when(model.store.get(value), function(item) {
                            return model.getLabel(item);
                        });
                    }
                    when(label, lang.hitch(this, function(name) {
                        // 親の情報は不要なので省略する。
                        var lastIndex = name.lastIndexOf('/');
                        if(lastIndex !== -1){
                            var excludeParentName = name.substring(lastIndex + 1);
                            name = excludeParentName;
                        }
                        this.status.innerHTML = name;
                    }));
                }));
            } else {
                this._set('value', '');
                this.emit('change', {value: ''});
                this.status.innerHTML = this.emptyValueLabel;
            }
            // 要素の選択状態をリセットする
            this._initTree().then(lang.hitch(this, function() {
                this.tree.set('selectedItem', null);
            }));
            this.dialog.hide();
        }
    });
});
