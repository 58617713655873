/**
 * <モジュールの説明>
 * @module app/view/page/<ウィジェット名>
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/json',
    'dojo/text!./templates/DamageReportFormDialog.html', // テンプレート文字列
    'idis/view/page/_PageBase', // 共通基底クラス
    'app/model/DisasterInfo',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    '../config',
    // 'idis/util/FilesUtils',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'dijit/form/RadioButton',
    'app/view/form/CustomizableMunicipalitySelector',
    'dijit/form/Form'
], function (module, declare, lang, Deferred, JSON, template,
    _PageBase, DisasterInfo, InfoDialog, DialogChain, Loader, UserInfo, USER_TYPE, config) {
    /**
     * <クラスの説明>
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */ {
            // テンプレート文字列
            templateString: template,
            _disasterId: null,

            constructor: function () {
                // ダイアログ連鎖を登録
                this.chain = DialogChain.get(this);
                // 災害IDを取得
                this._disasterId = DisasterInfo.getDisasterId();
                if (!this._disasterId) {
                    this._disasterId = 1;
                    console.warn('災害IDが設定されていません。');
                }
            },

            startup: function () {
                this.onSelectFlgChange();
                this.initDialog();
            },

            initDialog: function () {
                switch (UserInfo.getUserType()) {
                    // 市町村・県民局ユーザの場合はデフォルトだけ設定
                    case USER_TYPE.MUNICIPALITY:
                        this.municipalityCd.set('value', UserInfo.getMunicipalityCd());
                        break;
                    case USER_TYPE.REGION:
                        this.municipalityCd.set('value', UserInfo.getMunicipalityCds()[0]);
                        break;
                    default:
                        break;
                }
            },

            // 出力帳票の種類を変更したときに「市町村」の入力可否を変更する
            // 0: 被害情報概況集計, 1: 被害情報一覧, 2: 消防庁4号様式(その2)
            onSelectFlgChange: function () {
                var selectFlg = this.form.get('value').selectFlg;
                console.debug('市町村の入力可否を変更します。');
                if (selectFlg === '0') {
                    this.municipalityCdArea.style.display = '';
                    this.activeFlgArea.style.display = '';
                } else if (selectFlg === '1') {
                    this.municipalityCdArea.style.display = 'none';
                    this.activeFlgArea.style.display = 'none';
                } else if (selectFlg === '2') {
                    this.municipalityCdArea.style.display = '';
                    this.activeFlgArea.style.display = 'none';
                } else if (selectFlg === '3') {
                    this.municipalityCdArea.style.display = '';
                    this.activeFlgArea.style.display = '';
                } else if (selectFlg === '4') {
                    this.municipalityCdArea.style.display = 'none';
                    this.activeFlgArea.style.display = '';
                } else if (selectFlg === '5') {
                    this.municipalityCdArea.style.display = 'none';
                    this.activeFlgArea.style.display = '';
                }
            },

            // 帳票出力を行う前に入力チェックを行う
            onOutputButtonClick: function () {
                if (!this._disasterId) {
                    this.chain.info('災害名が正しく選択されていません。', 'エラー');
                    return false;
                }
                var selectFlg = this.form.get('value').selectFlg;
                var repdate = this.repdatetime._date.displayedValue;
                var reptime = this.repdatetime._time.displayedValue;
                var activeFlg = this.form.get('value').activeFlg;
                // ユーザ情報
                var municipalityCd = this.form.get('value').municipalityCd;
                var regionCd = '';
                var orgMunicCd = UserInfo.getMunicipalityCd();
                var roleCd = UserInfo.getRoleCd();

                // 全帳票共通で、日時は必須項目
                if (!this.repdatetime.validate() || !repdate || !reptime) {
                    this.chain.info('日時が選択されていません。', 'エラー');
                    return false;
                }

                var reqid = 'DAMAGE_OVERVIEW_EX';
                var fileName = 'DamageOverview.xlsx';
                var paramList = [];

                if (selectFlg === '0') {
                    //一覧選択時
                    // 市町or県民局の選択は必須
                    if (!municipalityCd) {
                        this.chain.info('市町が選択されていません。', 'エラー');
                        return false;
                    }
                    // 県民局を選択していた場合
                    // if (config.municInfo.prefRegCdGudge.indexOf(municipalityCd) > -1&&
                    //     municipalityCd !== config.municInfo.prefMunicCd) {
                    //     regionCd = municipalityCd;
                    //     municipalityCd = '';
                    // }
                    // 出力する報告ステータス種別(一覧用)
                    // 0：「県へ報告（外部公開不可）」「県へ報告（外部公開可能）」の被害情報を出力可能
                    // 1：「市町内管理」「県へ報告（外部公開不可）」「県へ報告（外部公開可能）」全ての被害情報を出力可能
                    var outputReportStatusType = '0';
                    if ((UserInfo.isSysAdmin()) || (UserInfo.getUserType() === USER_TYPE.MUNICIPALITY &&
                        municipalityCd === orgMunicCd)) {
                        // システム管理ユーザ、市町ユーザで自市町選択の場合は全ての報告ステータスの被害が閲覧可のため、種別を1にする
                        outputReportStatusType = '1';
                    }

                    reqid = 'DAMAGE_LIST_EX';
                    fileName = 'DamageList.xlsx';
                    paramList = [
                        { key: 'disid', value: this._disasterId },
                        { key: 'repdate', value: repdate },
                        { key: 'reptime', value: reptime },
                        { key: 'activeFlg', value: activeFlg },
                        { key: 'orgMunicCd', value: orgMunicCd },
                        { key: 'roleCd', value: roleCd },
                        {key: 'outputReportStatusType', value: outputReportStatusType}
                    ];
                    if (municipalityCd) {
                        paramList.push({ key: 'municipalityCd', value: municipalityCd });
                    }
                    if (regionCd){
                        paramList.push({ key: 'regionCd', value: regionCd });
                    }

                } else if (selectFlg === '1') {
                    // 出力する報告ステータス種別(概況一覧用)
                    // 0：「都報告（マスコミ公開不可）」「都報告（マスコミ公開可能）」の被害情報を出力可能
                    // 1：「市町内管理」「都報告（マスコミ公開不可）」「都報告（マスコミ公開可能）」全ての被害情報を出力可能
                    var outputReportStatusTypeOverview = '0';
                    if (UserInfo.isSysAdmin()) {
                        // システム管理ユーザの場合は全ての報告ステータスの被害が閲覧可のため、種別を1にする
                        // 市町ユーザも全ての報告ステータスの被害が閲覧可能だが、概況画面表示がなく、
                        // 自自治体と他自治体で出力ロジックが異なってしまうため種別は0のまま
                        outputReportStatusTypeOverview = '1';
                    }
                    //概況選択時
                    reqid = 'DAMAGE_OVERVIEW_EX';
                    fileName = 'DamageOverview.xlsx';
                    paramList = [
                        { key: 'disid', value: this._disasterId },
                        { key: 'repdate', value: repdate },
                        { key: 'reptime', value: reptime },
                        {key: 'outputReportStatusType', value: outputReportStatusTypeOverview}
                    ];
                }

                var data4xoblosDownload = {
                    fileName: fileName,
                    reqid: reqid,
                    paramList: paramList
                };


                var promise = this.download(data4xoblosDownload, fileName).then(lang.hitch(this, function () {
                    this.infoDialog = new InfoDialog({
                        title: 'ダウンロード完了',
                        content: 'ダウンロードが完了しました。'
                    });
                    this.infoDialog.show();

                }), lang.hitch(this, function (error) {
                    console.error(error);
                    this.infoDialog = new InfoDialog({
                        title: 'エラー',
                        content: 'エラーが発生しました。管理者にお問い合わせください。'
                    });
                    this.infoDialog.show();
                }));

                Loader.wait(promise);

            },

            //帳票出力
            download: function (data, baseFileName) {
                var deferred = new Deferred();

                var xhr = new XMLHttpRequest();
                xhr.open('POST', '/api/xoblos/download', true);
                xhr.responseType = 'arraybuffer';
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.onload = function () {

                    // エラー時は処理を止める
                    if (xhr.status !== 200) {
                        deferred.reject();
                        return;
                    }

                    // バイナリデータを取得
                    var arrayBuffer = this.response;
                    var blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });
                    var fileName = baseFileName.replace(/^.*"(.*)"$/, '$1');

                    // IE10+
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, fileName);
                    } else {
                        // 擬似的にAタグを作成
                        var link = document.createElement('a');
                        link.style = 'display: none';
                        document.body.appendChild(link);

                        // AタグのURLにバイナリデータをセット
                        var url = window.URL.createObjectURL(blob);
                        link.href = url;

                        // ファイル名をセット
                        link.download = fileName;

                        // 擬似的にリンクをクリック
                        link.click();
                        // 参照を解放
                        window.URL.revokeObjectURL(url);
                        link.remove();
                    }

                    deferred.resolve();

                };
                xhr.send(JSON.stringify(data));

                return deferred.promise;
            },

            /**
             * 月や日付を2桁にゼロpaddingする
             */
            _zeroPadding: function (month) {
                return ('00' + month).slice(-2);
            },

            /**
             * キャンセルボタン押下
             */
            onCancel: function () {
                this.getParent().hide();
            }
        });
});
