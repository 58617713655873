define([
    // ここに書く
    'app/view/page/DpLinkCollectionPage',
    'app/view/page/ManualDownloadPage',
    'app/view/page/MapPage',
    'app/view/page/MonitoringPage',
    'app/view/page/PhotoUploadPage',
    'app/view/page/QuestionsPage',
    'app/view/page/WeatherLinkCollectionPage',
    // ここまで
], function () { });