/**
 * 掲示板既読ユーザー一覧画面用モジュール
 * 
 */

define([
    'module',
    'dojo/_base/declare',
    'dojo/dom-construct',
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    // 以下、変数として受け取らないモジュール
    'dojox/lang/functional/array'
], function(module, declare, domConstruct, lang, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--bbs',

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            // ユーザID
            helper.column('userId', 'ユーザID', {sortable: true}),

            // ユーザ名
            helper.column('userName', 'ユーザ名', {sortable: true}),

            // 組織
            {field: 'organization', label: '組織', sortable: false,
                renderCell: function(item){
                    var contentNode = domConstruct.create('div');
                    var org = '';
                    if(item.unitName){
                        org += '<font size="-2" color="#c6c6c6">' +
                            item.deptName + ' / ' + item.sectName + '</font></br>';
                        org += item.unitName;
                        contentNode.innerHTML = org;
                        return contentNode;
                    }
                    if(item.sectName){
                        org += '<font size="-2" color="#c6c6c6">' +
                            item.deptName + '</font></br>';
                        org += item.sectName;
                        contentNode.innerHTML = org;
                        return contentNode;
                    }
                    if(item.deptName){
                        org = item.deptName;
                        contentNode.innerHTML = org;
                        return contentNode;
                    }
                    contentNode.innerHTML = org;
                    return contentNode;
                }
            },

            // ステータス
            {field: 'read', label: 'ステータス', sortable: true,
                formatter: lang.hitch(this, function(item){
                    var type = null;
                        switch(item) {
                            case true:
                                type = '既読';
                                break;
                            case false:
                                type = '未読';
                                break;
                        }
                    return type;
                }),
                className: lang.hitch(this, function(item) {
                    // ヘッダーの場合はclassNameを返さない
                    if (!item) {
                        return;
                    }else{
                        if (item.read === false) {
                            return 'unread';
                        }
                        return '';
                    }
                }),
            }
        ]
    });
});
