/**
 * クロノロジー区分入力パーツ(複数選択可)
 * (クロノロジー情報区分をツリー形式で表示する)
 * @module idis/view/form/ChronologyTypeMultiSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/dom-style',
    'idis/view/form/TreeChecker',
    'app/model/ChronologyTypeList'
], function(module, declare, domStyle, TreeChecker, ChronologyTypeList) {
    /**
     * クロノロジー区分選択用パーツ。
     * @class ChronologyTypeMultiSelector
     * @extends module:idis/view/form/TreeChecker~TreeChecker
     */
    return declare(module.id.replace(/\//g, '.'), TreeChecker,
        /** @lends module:idis/view/tree/ChronologyTypeMultiSelector~ChronologyTypeMultiSelector# */ {
        // 選択ダイアログのタイトル
        title: '情報区分選択',

        // ツリー・モデル
        model: ChronologyTypeList,

        /**
         * 選択した値の設定
         */
        _setValueAttr: function() {
            // 継承した親クラスの処理
            this.inherited(arguments);
            // 以下、当モジュール固有の処理
            // 選択結果が20文字を超えた場合、・・・(三点リーダ)で省略する
            domStyle.set(this.status, {
                'max-width': '20em',
                'white-space': 'nowrap',
                'overflow': 'hidden',
                'text-overflow': 'ellipsis'
            });
        }
    });
});