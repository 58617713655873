/**
 * 施設情報グリッド
 * @module app/facility/FacilityGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, lang, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--facility',


        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
                helper.buttonColumn('detail', '詳細'),
                helper.column('facilityId', '施設ID'),
                helper.column('facilityTypeName', '施設種別'),
                {field: 'shelterCategory', label: '避難所区分', sortable: false,
                formatter: function(value, item) {
                    var result = '';
                    if(item.welfareEvacShFlg === '1'){
                        result += '福祉避難所';
                    }
                    if(item.edesignatedEvacShFlg === '1'){
                        result += ' 指定緊急避難場所';
                    }
                    if(item.designatedEvacShFlg === '1'){
                        result += ' 指定避難所';
                    }
                    if(item.temporaryEvacShFlg === '1'){
                        result += ' 臨時避難所';
                    }
                    if(item.civilProtectionEvacShFlg === '1'){
                        result += ' 避難施設（国民保護）';
                    }
                    if(item.otherEvacShFlg === '1'){
                        result += ' その他避難所';
                    }
                    return result;
                }
                },
                helper.column('facilityName', '施設名称'),
                {field: 'strManagementEntity', label: '自治体', sortable: false},
                helper.column('address', '住所'),
                helper.column('phoneNumber', '電話番号'),
                {field: 'availableFlg', label: '利用可否',
                formatter: lang.hitch(this, function(item){
                    var result = '否';
                    if(item === '1') {
                        result = '可';
                    }
                    return result;
                })},
                {field: 'publicFlg', label: '外部公開',
                formatter: lang.hitch(this, function(item){
                    var result = '否';
                    if(item === '1') {
                        result = '可';
                    }
                    return result;
                })}
                ]
    });
});
