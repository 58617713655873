
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            helper.buttonColumn('detail', '詳細'),
            helper.column('author', '著作者', {sortable: false}),
            helper.column('title', 'データセット名', {sortable: false}),
            helper.column('numResources', 'リソース数' , {
                formatter: function(value){
                    return value ? value + '件' : '-';
                }, 
                sortable: false
            }),
            helper.column('infoDateTime', '情報日時' , {
                formatter: function(value){
                    var date = new Date(value);
                    var convertedDate = date.getFullYear() + '/' + date.getMonth() + '/' + date.getDate();
                    return convertedDate ? convertedDate : '-';
                }, 
                sortable: false
            })
        ]
    });
});
