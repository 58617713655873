define([
    {id:'01',value:'救出・救助'},
    {id:'02',value:'応援・受援'},
    {id:'03',value:'交通'},
    {id:'04',value:'医療'},
    {id:'05',value:'ライフライン'},
    {id:'06',value:'生活情報'},
    {id:'07',value:'警察'},
    {id:'08',value:'自衛隊'},
    {id:'09',value:'消防'},
    {id:'10',value:'国'},
    {id:'11',value:'その他'}
]);
