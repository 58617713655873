define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dijit/form/Select'
], function(module, declare, lang, Select) {
    return declare(module.id.replace(/\//g, '.'), Select,{

        buildRendering: function() {
            this.inherited(arguments);
            this.set('style', {width: '7em', height:'20px'});
        },

        createSelector: function(setZoomLevel) {
            var zoomLevels = [];
            var zoom = 1;
            while (zoom < 19) {
                var aZoomOption = {
                    'id'    : ''+zoom,
                    'label' : ''+zoom,
                    'value' : zoom
                };
                if (setZoomLevel === zoom) {
                    aZoomOption.selected = true;
                }
                zoomLevels.push(aZoomOption);
                zoom++;
            }

            this.set('options', zoomLevels);
            this.startup();
        }

    });
});
