/**
 * 指示共有グループ画面用モジュール。
 * @module app/chat/ChatShareGroupPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/ChatShareGroupPage.html',
    'idis/view/page/_PageBase',
    'dijit/Dialog',
    'idis/control/Router',
    'idis/store/IdisRest',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    './_ChatGroupDialogBase',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    './ChatShareGroupGrid'
], function(module, declare, lang, template, _PageBase, Dialog, Router, IdisRest, Loader,
	DialogChain, _ChatGroupDialogBase) {
    /**
     * 指示共有グループ画面
     * @class ChatShareGroupPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _ChatGroupDialogBase,
    /** @lends module:app/chat/ChatShareGroupPage~ChatShareGroupPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--chatShare',

        /**
         * ストア
         */
        store: null,

        /**
         * constructor
         */
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'chatGroupId',
                target: '/api/chatShare/group',
                sortParam: 'sort',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
            this.initGrid();
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.chatShareGroupGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.onDetailButtonClick(evt.item);
            }));
            this.updateGridQuery();
        },

        /**
         * 詳細ボタンを押下したときの挙動
         */
        onDetailButtonClick: function(object) {
            // 指示共有画面へ遷移
            Router.moveTo('chatShare', {
                chatGroupId : object.chatGroupId
            });
        },

        /**
         * グリットを更新する。
         */
        updateGridQuery: function() {
            var filter = new this.store.Filter();

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);

            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.chatShareGroupGrid.set('collection', collection);
        }
    });
});
