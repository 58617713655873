/**
 * 地域選択用入力パーツ
 * @module idis/view/form/DistrictSelector
 */
define([
    'app/config',
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/string',
    'dijit/form/TextBox',
    'dojo/when',
    'idis/view/form/TreeSelector',
    'app/model/District',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'idis/view/dialog/InfoDialog'
], function(config, module, declare, lang, dStr, TextBox, when, TreeSelector, District,
    CacheStoreModel, CacheTreeRest,InfoDialog) {
    /**
     * 地域選択用パーツ。
     * @class DistrictSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:idis/view/tree/DistrictSelector~DistrictSelector# */ {
        // 選択ダイアログのタイトル
        title: '地区選択',

        // ツリー・モデル
        model: null,

        // ページ初期表示にmodelがnullだとエラーが出るためmodelを生成
        constructor: function() {
            this._initModel();
        },

        // DOMを構築する
        buildRendering: function () {
            this.inherited(arguments);
            // 検索用入力要素を設置
            this.filterInput = new TextBox({
                placeHolder: this.placeHolder,
                maxLength: 128
            });
            this.filterInput.placeAt(this.treeControlNode);
            this.own(this.filterInput.on('keyup', lang.hitch(this, 'onFilterInputChange')));
        },

        /**
         * 選択した値の設定
         */
         // 継承元との差分は以下。
         // cityUnselectableが指定された場合、政令指定都市(の地区コード)は選択不可。
        _setValueAttr: function(value) {
            // 偽値は数値の0のみ有効とする
            if (value || value === 0) {
                if(this.cityUnselectable){
                	//避難所一覧画面の場合、政令指定都市は選択不可
                	if(this.shelterFlg && value === config.municInfo.cityMunicCd){
                        InfoDialog.show('エラー', '政令指定都市は選択することができません。');
                        this.reset();
                        return false;
                    }
                    //政令指定都市の地区は選択不可
                    if(value === config.municInfo.cityMunicDistrictCd){
                        InfoDialog.show('エラー', '政令指定都市は選択することができません。');
                        this.reset();
                        return false;
                    }
                }
                if (this.wardUnselectable) {
                    // 区(末尾000のコード)の場合選択不可
                    if (value.substr(-3) === '000') {
                        InfoDialog.show('エラー', '区は選択することができません。');
                        this.reset();
                        return false;
                    }
                }
                    this._set('value', value);
                    this.emit('change', {value: value});
                    this._initTree().then(lang.hitch(this, function() {
                        var model = this.tree.model;
                        var label;
                        if (this.fullName) {
                            label = model.getFullName(value);
                        } else {
                            label = when(model.store.get(value), function(item) {
                                return model.getLabel(item);
                            });
                        }
                        when(label, lang.hitch(this, function(name) {
                            this.status.innerHTML = name;
                        }));
                    }));
                } else {
                    this._set('value', '');
                    this.emit('change', {value: ''});
                    this.status.innerHTML = this.emptyValueLabel;
                }
                // 要素の選択状態をリセットする
                this._initTree().then(lang.hitch(this, function() {
                    this.tree.set('selectedItem', null);
                }));
        },

        _initModel: function(){
            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                //ユーザの管理対象の地区一覧を取得する。
                target: '/api/district/managingDistrict4Tree'
                })
            });
        },

        /**
         * 検索用入力要素入力時。
         */
         onFilterInputChange: function () {
            // 入力値を取得
            var value = dStr.trim(this.filterInput.get('value'));
            if (value) {
                // ラベル名が入力値を含むものでフィルターし、ツリーを展開する
                this.model.setFilter(function (item, isLeaf, model) {
                    return isLeaf && (model.getLabel(item) || '').indexOf(value) !== -1;
                }).then(lang.hitch(this, function () {
                    this.tree.expandAll();
                }));
            } else {
                // 入力が空の場合は全要素を表示してツリーを閉じる
                this.model.setFilter().then(lang.hitch(this, function () {
                    this.tree.collapseAll();
                }));
            }
        }
    });
});
