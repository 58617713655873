/**
 * <水位実況情報情報一覧画面>
 *
 * @module app/river/grid/RiverLevelObservationPageGrid
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'app/view/grid/CommonColumn',
    './RiverLevelObservationCommonColumns'
    // 以下、変数で受けないモジュール
], function(module, declare, lang, IdisGrid, helper, CommonColumn, RiverCommonColumns
    ) {
    /**
     * 水位実況情報情報一覧画面用Grid
     *
     * @class RiverLevelObservationPageGrid
     * @extends module:idis/view/grid/IdisGrid~IdisGrid
     */
    return declare(module.id.replace(/\//g, '.'), IdisGrid,
        /** @lends module:app/river/grid/RiverLevelObservationPageGrid~RiverLevelObservationPageGrid# */ {
        columns : [
            CommonColumn.optionColumn('regionName', '地域'),
            CommonColumn.municipalityCd,
            // 観測局
            CommonColumn.observatoryName,
            helper.buttonColumn('detail', '詳細'),
            helper.column('excess', '基準値\n超過', {
                className : function(item) {
                    // ヘッダーの場合はclassNameを返さない
                    if (!item) {
                        return;
                    }
                    if (item.riverLevelFlg === '0') {
                        return {
                            1: 'is-wait',
                            2: 'is-caution',
                            3: 'is-judgment',
                            4: 'is-crisis'
                        }[item.excess];
                    } else {
                        return {
                            1: 'is-missing',
                            2: 'is-notYet',
                            3: 'is-closed'
                        }[item.riverLevelFlg];
                    }
                },
                formatter : function(value) {
                        return {
                            0: '-', // 非超過
                            1: '(待)',
                            2: '(注)',
                            3: '(避)',
                            4: '(危)'
                        }[value] || '-'; // 非有効値
                }
            }),
			RiverCommonColumns.riverLevel,
			RiverCommonColumns.difference,
            helper.column('limitLevel', '基準値', {
                sortable : false,
                children : [
                    CommonColumn.threshold('riverLevelStandby', '水防団待機\n[m]', 2),
                    CommonColumn.threshold('riverLevelCaution', '氾濫注意\n[m]', 2),
                    CommonColumn.threshold('riverLevelEvacuate', '避難判断\n[m]', 2),
                    CommonColumn.threshold('riverLevelDanger', '氾濫危険\n[m]', 2)
                ]
            }),
            CommonColumn.optionColumn('riverSysName', '水系'),
            CommonColumn.optionColumn('riverName', '河川'),
            // 管理区分
            CommonColumn.managerCd
        ],
        changeMode: function(mode) {
            RiverCommonColumns.changeColumnsLabel(mode);
            this.renderHeader();
        }
    });
});

