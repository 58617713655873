/**
 * 被害情報パネル
 * @module app/monitor/TrafficPanel
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/dom-construct',
    'dojo/dom-style',
    'dojo/json',
    'dojo/text!./templates/DamagePanel.html',
    'dojo/topic',
    'dojo',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/view/page/_PageBase',
    'app/model/DisasterInfo',
    'app/config',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane'
], function(module, array, declare, lang, locale, domConstruct, domStyle, JSON, template, topic,
    dojo, Router, UserInfo, USER_TYPE, Requester, DialogChain, Loader, _PageBase, DisasterInfo, config) {
    /**
     * 被害情報パネル
     * @class DamagePanel
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/monitor/DamagePanel~DamagePanel# */ {
        // テンプレート文字列
        templateString: template,

        /**
         * 最大表示件数
         */
        MAX_COUNT: 5,

        /**
         * 市町村コード
         */
        _municipalityCd: null,

        /**
         * constructor
         */
        constructor: function() {
            this.chain = DialogChain.get(this);
            this._municipalityCd = UserInfo.getSelectedMunicipalityCd();
            console.debug('被害情報表示対象の市町村コード：' + this._municipalityCd);
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
            this.initTable();

            // 市町村切替時に被害情報を更新
            this.own(topic.subscribe('idis/view/form/MunicipalitySelectForm::selected',
                lang.hitch(this, function(payload) {
                    this._municipalityCd = payload.municipalityCd;
                    this.initTable();
            })));

            // 災害切替時に被害情報を更新
            this.own(topic.subscribe('app/view/form/DisasterChanger::updated',
                lang.hitch(this, function() {
                    this.initTable();
            })));
        },


        /**
         * テーブルを初期化します
         */
        initTable: function() {
            // 被害情報概況を設定
            this.setDamageOverviewInfo();

            // 被害情報一覧を設定
            this.setDamageListInfo();
        },

        /**
         * 被害情報概況を設定します
         */
        setDamageOverviewInfo: function() {
            var self = this;

            var disasterId = DisasterInfo.getDisasterId();

            var promise = Requester.get('/data/damage/overview/' + disasterId +'/' + this._municipalityCd +'.json', {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(function(item) {
                console.debug('被害情報（' + disasterId + ', ' +  this._municipalityCd + '）：' +
                    JSON.stringify(item));

                // 被害情報を設定
                self.setDamageOverview(self, item);

            }, function(error) {
                if (error.response.status === 404) {
                    console.log('被害情報概況情報が見つかりません。初期値をセットします。' + (error.message ? ('('+error.message+')') : ''));
                    var item = {
                        'deadNum' : 0,
                        'seriousNum' : 0,
                        'mildNum' : 0,
                        'unknownNum' : 0,
                        'unknownSympNum' : 0,
                        'fullDestlHm' : 0,
                        'halfDestlHm' : 0,
                        'partDestlHm' : 0,
                        'fldAbvHm' : 0,
                        'fldBlwHm' : 0,
                        'lhalfDestlHm' : 0,
                        'unknownHm' : 0,
                        'elecCurr' : 0,
                        'watFailCurr' : 0,
                        'watRedCurr' : 0,
                        'telCurr' : 0,
                        'gusCurr' : 0
                    };
                    self.setDamageOverview(self, item);
                } else {
                    self.chain.info('情報の取得に失敗しました。', 'エラー');
                }
            });
            //ローダーの表示
            Loader.wait(promise);
        },

        /**
         * 被害情報を設定します
         */
        setDamageOverview: function(self, item) {
            self.deadNum.innerHTML = item.deadNum;
            self.seriousNum.innerHTML = item.seriousNum;
            self.mildNum.innerHTML = item.mildNum;
            self.unknownNum.innerHTML = item.unknownNum;
            self.unknownSympNum.innerHTML = item.unknownSympNum;

            self.fullDestlHm.innerHTML = item.fullDestlHm;
            self.halfDestlHm.innerHTML = item.halfDestlHm;
            self.partDestlHm.innerHTML = item.partDestlHm;
            self.fldAbvHm.innerHTML = item.fldAbvHm;
            self.fldBlwHm.innerHTML = item.fldBlwHm;
            self.unknownHm.innerHTML = item.unknownHm;

            self.elecCurr.innerHTML = item.elecCurr;
            self.watFailCurr.innerHTML = item.watFailCurr;
            self.watRedCurr.innerHTML = item.watRedCurr;
            self.telCurr.innerHTML = item.telCurr;
            self.gusCurr.innerHTML = item.gusCurr;
        },

        /**
         * 被害情報一覧を設定します
         */
        setDamageListInfo: function() {
            var self = this;

            var disasterId = DisasterInfo.getDisasterId();

            var promise = Requester.get('/data/damage/list/' + disasterId +'/' + this._municipalityCd +'.json', {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(function(item) {
                console.debug('被害情報（' + disasterId + ', ' +  this._municipalityCd + '）：' +
                    JSON.stringify(item));

                // 被害情報を設定
                self.setDamageList(self, item);

            }, function(error) {
                if (error.response.status === 404) {
                    console.log('被害情報一覧情報が見つかりません。初期値をセットします。' + (error.message ? ('('+error.message+')') : ''));
                    var item = {'items': []};
                    self.setDamageList(self, item);
                } else {
                    self.chain.info('情報の取得に失敗しました。', 'エラー');
                }
            });
            //ローダーの表示
            Loader.wait(promise);
        },

        /**
         * 被害情報を設定します
         */
        setDamageList: function(self, data) {
            var DAMAGE_TYPE = {
                '01': '住家被害',
                '02': 'ライフライン（電気、水道等）',
                '03': '文教施設（学校等）' ,
                '04': '病院',
                '05': '庁舎等施設',
                '06': '土砂災害',
                '07': '火災',
                '08': '河川',
                '09': '道路',
                '10': '農地・農業用施設被害',
                '14': '林野被害',
                '15': '港湾被害',
                '11': 'その他被害',
                '12': '簡易報告',
                '13': '現地画像'
            };

            var damagePanelTbody = dojo.byId('damagePanelTBody');
            domConstruct.empty(damagePanelTbody);

            // 先頭5件のみ表示
            if (data.items.length > this.MAX_COUNT) {
                data.items.length = this.MAX_COUNT;
            }

            array.forEach(data.items, function(item){
                var datetimeString = self.formatDateTime(item.reportUpdTimestamp);
                var damage = '';
                var damageTypeArr = item.damageTypeList.split(',');
                if(damageTypeArr) {
                    for(var dmCount=0; dmCount<damageTypeArr.length; dmCount++) {
                        damageTypeArr[dmCount] = DAMAGE_TYPE[damageTypeArr[dmCount]];
                    }
                    damage = damageTypeArr.join('、');
                }
                // var html = '<tr>';
                // html += '<td style="padding-left:10px; width:30%;">' + datetimeString + '</td>';
                // html += '<td style="width:55%;">' + item.reportName + ' ' + damage + ' ';
                // html += item.damageAddress + '</td>';
                // html += '<td style="text-align: right; padding-right:10px; width:15%; color: blue;">';
                // //html += '<a href="?" onClick="onDamageReportLinkClick(';
                // //html += item.municipalityCd +')"><u>詳細&gt;</u></a></td>';
                // //FIXME: 正しく動作しない。onstart後に加えたdata-dojo-attach-eventはメソッドと紐づかない？
                // // html += '<u><a href="?" ';
                // // html += 'data-dojo-attach-event="click:onDamageReportLinkClick">詳細&gt;</a></u></td>';
                // html += '<u><a href="?damageReportId=' + item.damageReportId;
                // html += '&p=report/detail">詳細&gt;</a></u></td>';
                // html += '</tr>';
                // var dom = domConstruct.toDom(html);1

                var tr = domConstruct.create('tr');
                domConstruct.place(domConstruct.create('td', {
                    innerHTML: datetimeString, style: 'padding-left:10px; width:30%;' }), tr);
                domConstruct.place(domConstruct.create('td', {
                    innerHTML: damage + ' ' + item.damageAddress,
                    style: 'width:55%;' }), tr);
                var detailTd = domConstruct.create('td', {
                    style: 'text-align: right; padding-right:10px; width:15%; color: blue;' });
                var u = domConstruct.create('u');
                var a = domConstruct.create('a',{
                    innerHTML: '詳細&gt;',
                    href: '#',
                    onclick: function() {
                        Router.moveTo('report/detail', {
                            damageReportId: item.damageReportId
                        });
                    }
                });
                domConstruct.place(a, u);
                domConstruct.place(u, detailTd);
                domConstruct.place(detailTd, tr);
                domConstruct.place(tr, damagePanelTbody);
            });

        },

        /**
         * 'yyyy-MM-dd HH:mm' 形式に変換
         */
        formatDateTime: function(val) {
            var timestamp = new Date(val);
  		  	var dateLabel = locale.format(timestamp, {
  		  		selector: 'date',
  		  		datePattern: 'yyyy/MM/dd'
  		  	});
  		  	var timeLabel = locale.format(timestamp, {
  		  		selector: 'time',
  		  		timePattern: 'HH:mm'
  		  	});
        	return dateLabel + '&nbsp;' + timeLabel;
        },

        /**
         * 被害情報一覧ページに遷移
         */
        onDamageReportLinkClick: function(evt){
            evt.preventDefault();

            if(this._municipalityCd && this._municipalityCd !== config.municInfo.prefMunicCd){
                Router.moveTo('report', {
                    // municipalityCd: this._municipalityCd
                });
            } else if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY) {
                Router.moveTo('report', {
                    // municipalityCd: UserInfo.getMunicipalityCd()
                });
            } else {
                Router.moveTo('report/admin');
            }
        }

    });
});
