define([
    'leaflet',
    './DialogRadius'
], function(leaflet, DialogRadius) {

    return leaflet.Draw.Circle.extend({

        _onMouseDown: function (e) {
            var latlng = e.latlng;
            this._tooltip.updatePosition(latlng);
            var dialogRadius = new DialogRadius();
            this.latlng = latlng;
            dialogRadius.show();
        }

    });
});
