/**
 * グリッド画面のサンプル実装用モジュール。
 * @module app/view/SampleGridRegisterPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/SampleGridDetailPage.html',
    'idis/view/page/_PageBase',
    'dijit/form/Form',
    'dijit/form/RadioButton',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'idis/view/form/RadioGroup'
], function(module, declare, template, _PageBase) {
    /**
     * グリッド画面のサンプル実装。
     * @class SampleGridPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/SampleGridRegisterPage~SampleGridRegisterPage# */ {
        // テンプレート文字列
        templateString: template,

        // テンプレートHTML内内でformに対し、data-dojo-attach-event="onSubmit:onSubmit"でこのメソッドを紐付け
        // 更新ボタンをtype="submit"にしているので、ボタン押下時にフォームがsubmitされて呼ばれる
        onSubmit: function() {
            try {
                if (this.form.validate()) {
                    // 入力チェックが成功したらsendイベントを発行
                    // 実際の更新処理はサンプル画面側でsendイベントを監視しているメソッドが実施
                    this.emit('send', {
                        // テンプレートHTML内でformに対し
                        // data-dojo-attach-point="form"を指定してthis.formとしてアクセス出来るようにしている
                        value: this.form.get('value')
                    });
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        }
    });
});

