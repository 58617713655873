/**
 * 操作可能なツリー
 * @module idis/view/tree/dndSourceForMutableTree
 */
define([
    'module',
    'dojo/_base/declare',
    'dijit/tree/dndSource'
], function(module, declare, dndSource) {
    /**
     * D&D時に移動のみを許容する。
     * @class dndSourceForMutableTree
     * @extends module:dijit/tree/dndSource~dndSource
     */
    return declare(dndSource, /** @lends module:idis/view/tree/dndSourceForMutableTree~dndSourceForMutableTree# */ {
        // コピー・モードかどうか
        copyState: function() {
            // 常にfalseを返す（移動のみを許容）
            return false;
        }
    });
});
