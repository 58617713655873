/**
 * 地図表示選択情報パネル
 * @module app/monitor/MapViewSelectPanel
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/MapViewSelectPanel.html',
    'dojo/topic',
    'dojox/lang/functional/array',
    'idis/control/Locator',
    'idis/view/dialog/DialogChain',
    'idis/view/page/_PageBase',
    'app/monitor/consts/mapViewSelectCfg',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane'
], function(module, declare, lang, template, topic, df, Locator, DialogChain, _PageBase, mapViewSelectCfg) {
    /**
     * 地図表示選択情報パネル
     * @class MapSelectPanel
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/monitor/MapViewSelectPanel~MapViewSelectPanel# */ {
        // テンプレート文字列
        templateString: template,

        /**
         * constructor
         */
        constructor: function() {
            this.chain = DialogChain.get(this);
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);

            // タブ（気象観測、地震・火山、避難・避難所、被害情報、道路情報）
            topic.subscribe('app/view/page/MonitoringPage::selected',
                lang.hitch(this, function() {
                    if (this.weatherFlg) {
                        this.weatherFlg.set('checked', true);
                    }
                    if (this.sedimentFlg) {
                        this.sedimentFlg.set('checked', false);
                    }
                    if (this.floodFlg) {
                        this.floodFlg.set('checked', false);
                    }
                    // if (this.rainFlg) {
                    //     this.rainFlg.set('checked', false);
                    // }
            }));

            // 表示情報：選択解除
            topic.subscribe('app/map/LayerPane::released',
                lang.hitch(this, function() {
                    if (this.weatherFlg) {
                        this.weatherFlg.set('checked', false);
                    }
                    if (this.sedimentFlg) {
                        this.sedimentFlg.set('checked', false);
                    }
                    if (this.floodFlg) {
                        this.floodFlg.set('checked', false);
                    }
                    // if (this.rainFlg) {
                    //     this.rainFlg.set('checked', false);
                    // }
            }));
        },

        /**
         * 気象警報
         */
        onWeatherFlgClick: function() {
            console.debug('気象警報：' + this.weatherFlg.checked);
            this.layerSelect();
        },

        /**
         * 土砂
         */
        onSedimentFlgClick: function() {
            console.debug('土砂：' + this.sedimentFlg.checked);
            this.layerSelect();
        },

        /**
         * 洪水・高潮
         */
        onFloodFlgClick: function() {
            console.debug('洪水：' + this.floodFlg.checked);
            this.layerSelect();
        },

        /**
         * 降水警報
         */
        // onRainFlgClick: function() {
        //     console.debug('降水警報：' + this.rainFlg.checked);
        //     this.layerSelect();
        // },

        /**
         * レイヤー選択
         */
        layerSelect: function() {

            var layerId = [];
            if (this.weatherFlg.checked) {
                df.forEach(mapViewSelectCfg.WEATHER_LAYER_ID, function(id) {
                    if (layerId.indexOf(id) === -1) {
                        layerId.push(id);
                    }
                }, this);
            }
            if (this.sedimentFlg.checked) {
                df.forEach(mapViewSelectCfg.SEDIMENT_LAYER_ID, function(id) {
                    if (layerId.indexOf(id) === -1) {
                        layerId.push(id);
                    }
                }, this);
            }
            if (this.floodFlg.checked) {
                df.forEach(mapViewSelectCfg.FLOOD_LAYER_ID, function(id) {
                    if (layerId.indexOf(id) === -1) {
                        layerId.push(id);
                    }
                }, this);
            }
            // if (this.rainFlg.checked) {
            //     df.forEach(mapViewSelectCfg.RAIN_LAYER_ID, function(id) {
            //         if (layerId.indexOf(id) === -1) {
            //             layerId.push(id);
            //         }
            //     }, this);
            // }
            console.debug('レイヤーID：' + layerId);

            // ツリーにチェックをつける
            var payload = {};
            var layerQuery = Locator.getLayerQuery();
            payload = {layerQuery:layerQuery, layerId:layerId};
            topic.publish(module.id + '::checked', payload);
        }

    });
});
