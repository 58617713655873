/**
 * チェック可能なツリー用モジュール。
 * @module idis/view/tree/CheckTree
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/has',
    'dojo/promise/all',
    'dojo/when',
    'dojox/lang/functional/array',
    './IdisTree',
    // 以下、変数で受けないモジュール
    'dojox/lang/functional/object'
], function (module, array, declare, lang, Deferred, has, all, when, df, IdisTree) {
    // 子要素から呼ばれた際用の定数
    var CHILD = 'CHILD';
    // 親要素から呼ばれた際用の定数
    var PARENT = 'PARNET';

    /**
     * 各要素をチェック可能なツリー
     * @class CheckTree
     * @extends module:idis/view/tree/IdisTree~IdisTree
     * @param {Object} kwArgs
     * @param {module:dijit/tree/model} kwArgs.model ツリー・モデル
     */
    return declare(module.id.replace(/\//g, '.'), IdisTree, /** @lends module:idis/view/tree/CheckTree~CheckTree# */ {
        /**
         * チェック済み要素の識別子と要素の対応
         * @type {Object<string,Object>}
         */
        _checkMap: null,

        /**
         * 半チェック状態のIDと要素の対応。
         * チェック状態の要素がこの対応付に含まれるかは保証されない。
         * @type {Object<string,Object>}
         */
        _halfCheckMap: null,

        /**
         * チェック処理（子要素の処理含めて）が完了した要素の対応
         * @type {Object<string,Object>}
         */
        _checkCompletedMap: null,

        // ラベルクリックで開閉するか
        openOnClick: false,

        constructor: function () {
            this._checkMap = {};
            this._halfCheckMap = {};
            this._checkCompletedMap = {};
        },

        /**
         * 指定されたIDの要素がチェックされているかどうかを返す。
         * @param {identifier} id ツリー要素のID
         * @returns {boolean} チェックされていればtrue、それ以外の場合はfalse
         */
        isIdChecked: function (id) {
            return !!this._checkMap[id];
        },

        /**
         * 指定された要素がチェックされているかどうかを返す。
         * @param {Object} item ツリー要素
         * @returns {boolean} チェックされていればtrue、それ以外の場合はfalse
         */
        isChecked: function (item) {
            return this.isIdChecked(this.model.getIdentity(item));
        },

        /**
         * 指定された要素の全子要素がチェック状態かどうかを返す。
         * @param {Object} item ツリー要素
         * @returns {Promise<boolean>} 指定された要素の全子要素がチェック状態かどうか
         */
        isEveryChildChecked: function (item) {
            if (this.model.getChildrenIds) {
                // モデルが子要素一覧のIDのみ返すメソッドを提供する場合はそちらで判定する
                return new Deferred().resolve(array.every(this.model.getChildrenIds(item), this.isIdChecked, this));
            } else {
                // それ以外の場合は子要素一覧を取得し各要素に対し判定する
                return this.getItemChildren(item).then(lang.hitch(this, function (items) {
                    return array.every(items, this.isChecked, this);
                }));
            }
        },

        /**
         * 指定された要素にチェック状態の子孫が存在するかどうかを返す。
         * @param {Object} item ツリー要素
         * @returns {Promise<boolean>} 指定された要素にチェック状態の子孫が存在するかどうか
         */
        isAnyDescendantChecked: function (item) {
            if (this.model.getChildrenIds) {
                // モデルが子要素一覧のIDのみ返すメソッドを提供する場合はそちらで判定する
                return new Deferred().resolve(array.some(this.model.getChildrenIds(item), this.isIdHalfChecked, this));
            } else {
                // それ以外の場合は子要素一覧を取得し各要素に対し判定する
                return this.getItemChildren(item).then(lang.hitch(this, function (items) {
                    return array.some(items, this.isHalfChecked, this);
                }));
            }
        },

        /**
         * 指定されたIDの要素が半チェック状態かどうかを返す。
         * チェック状態の場合は半チェック状態とみなす。
         * @param {identifier} id ツリー要素のID
         * @returns {boolean} チェック状態または半チェック状態ならtrue、それ以外の場合はfalse
         */
        isIdHalfChecked: function (id) {
            return this.isIdChecked(id) || !!this._halfCheckMap[id];
        },

        /**
         * 指定された要素が半チェックされているかどうかを返す。
         * チェック状態の場合は半チェック状態とみなす。
         * @param {Object} item ツリー要素
         * @returns {boolean} チェック状態または半チェック状態ならtrue、それ以外の場合はfalse
         */
        isHalfChecked: function (item) {
            return this.isIdHalfChecked(this.model.getIdentity(item));
        },

        /**
         * チェック状態の葉要素一覧を返す。
         * @returns {Object[]}
         */
        getCheckedLeafs: function () {
            // 親IDの一覧
            var parentMap = {};
            df.forIn(this._checkMap, function (item) {
                var parentId = this.model.store.getParentIdentity(item);
                if (parentId || parentId === 0) {
                    parentMap[parentId] = true;
                }
            }, this);
            return df.filter(this._checkMap, function (item) {
                // 親ID一覧に登場しないものだけ残す
                return !parentMap[this.model.store.getIdentity(item)];
            }, this);
        },

        /**
         * 指定された要素が無効状態かどうかを返す。
         * ただし、ここでの無効状態とは、ツリーのチェック状態切り替えが不可であることを意味する。
         * @param {Object} item ツリー要素
         * @returns {boolean} 無効状態ならtrue、それ以外の場合はfalse
         */
        isDisabled: function (item) {
            // チェックしないフォルダーである'T002'に限りチェック不可
            return item.infoCategoryCd === 'T002';
        },

        /**
         * 要素のアイコン用CSSクラスを取得する。
         * @param {Object} item ツリー要素
         * @returns {string} 要素のアイコン用CSSクラス
         */
        getIconClass: function (item) {
            if (this.isChecked(item)) {
                // チェックされていた場合（無効時・有効時）
                return 'dijitCheckBox dijitCheckBoxChecked' + (this.isDisabled(item) ? 'Disabled' : '');
            } else if (this.isDisabled(item)) {
                // チェックされず無効時
                return 'dijitCheckBox dijitCheckBoxDisabled';
            } else if (this.isHalfChecked(item)) {
                // いずれかの子要素がチェックされていた場合
                return 'dijitCheckBox idisCheckBoxHalfChecked';
            } else {
                // チェックされず有効時
                return 'dijitCheckBox';
            }
        },

        /**
         * ツリー要素の子要素一覧を返すPromiseを返す。
         * @param {Object} item ツリー要素
         * @returns {Promise<Object[]>} 子要素一覧を返すPromise
         */
        getItemChildren: function (item) {
            var dfd = new Deferred();
            this.model.getChildren(item, dfd.resolve, dfd.reject);
            return dfd.promise;
        },

        /**
         * 指定された要素の子要素のチェック状態を
         * 指定された要素の現在のチェック状態と同一にする。
         * @param {Object} item ツリー要素
         * @returns {Promise} 全ての子要素のチェック反映が完了したら解決するPromise
         * @private
         */
        _setChildrenChecked: function (item) {
            var checked = this.isChecked(item);
            // 子要素側から来ていなければ子要素を更新
            return this.getItemChildren(item).then(lang.hitch(this, function (children) {
                return all(array.map(children, function (child) {
                    // 元の状態に関わらず親のチェック状態に揃える
                    return this.setChecked(child, checked, PARENT);
                }, this));
            }));
        },

        /**
         * 指定された要素の親要素のチェック状態を
         * 指定された要素の兄弟要素の状態を元に更新する。
         * （全ての子要素がチェックされている場合だけチェック状態にする）
         * @param {Object} item ツリー要素
         * @returns {Promise} 親要素のチェック反映が完了したら解決するPromise
         * @private
         */
        _setParentChecked: function (item) {
            var store = this.model.store;
            var parentId = store.getParentIdentity(item);
            if (!parentId && parentId !== 0) {
                return; // 親要素が存在しない
            }
            // 親要素を取得して処理
            var checked = this.isChecked(item);
            return when(store.get(parentId)).then(lang.hitch(this, function (parent) {
                if (checked) {
                    // チェック時は兄弟要素が全てチェック状態かどうかに基づいて反映
                    return this.isEveryChildChecked(parent).then(lang.hitch(this, function (allChecked) {
                        return this.setChecked(parent, allChecked, CHILD);
                    }));
                } else {
                    // チェック解除時は解除として反映
                    return this.setChecked(parent, checked, CHILD);
                }
            }));
        },

        /**
         * ツリー要素がチェックされたときに呼ばれる。
         * チェック時に独自処理を行う場合は子クラスで継承する。
         * @param {Object} item ツリー要素
         * @param {boolean} checked チェック状態
         * @returns {None|Promise} 非同期処理を実行する場合はPromiseを返す。
         */
        onCheckChange: function (item, checked) {
            if (has('dojo-debug-messages')) {
                var id = this.model.getIdentity(item);
                console.debug(module.id + '#onCheckChange: id=' + id + ', checked=' + checked);
            }
        },

        /**
         * 指定された要素のチェック状態を更新する。
         * 子要素を持つ場合は全ての子要素のチェック状態を合わせて更新する。
         * 親要素を持つ場合、全ての兄弟要素がチェックされた場合は親要素もチェックし、
         * 1つでも兄弟要素のチェックが外れた場合は親要素のチェックも外す。
         * @param {object} item チェック状態更新対象の要素
         * @param {boolean} checked 新しいチェック状態
         * @param {string} [caller] 無限ループ回避用
         */
        setChecked: function (item, checked, caller) {
            // disabledの場合は何もせず終了
            if (this.isDisabled(item)) {
                return;
            }
            // 要素自身の状態を更新
            var id = this.model.getIdentity(item);
            var oldChecked = !!this._checkMap[id];
            // チェック中要素の対応付けを更新
            if (checked) {
                this._checkMap[id] = item;
            } else {
                delete this._checkMap[id];
            }
            // チェック時は必ずチェックされ、
            // 直接または先祖側から伝播してきた場合は必ずそのチェック状態になるが、
            // チェック解除かつ子孫要素から伝播してきた場合は各子孫要素の状態を確認する必要がある
            var halfCheckPromise = (checked || caller !== CHILD) ? checked : this.isAnyDescendantChecked(item);
            return when(halfCheckPromise).then(lang.hitch(this, function (halfCheckResult) {
                // 半チェック状態の対応付けを更新
                if (halfCheckResult) {
                    this._halfCheckMap[id] = item;
                } else {
                    delete this._halfCheckMap[id];
                }
                // 表示情報が変化した場合は表示を更新
                // （非チェック・半チェック間の変化を含む）
                this._onItemChange(item);
                // チェック状態が変化した場合はカスタム処理を実行
                // （非チェック・半チェック間の変化は含まない）
                if (checked !== oldChecked) {
                    this.onCheckChange(item, !!this._checkMap[id]);
                }
                // 親子要素にチェック状態を反映する
                var promiseList = [];
                // 子要素側から来ていなければ子要素を更新
                if (caller !== CHILD) {
                    promiseList.push(this._setChildrenChecked(item));
                }
                // 親要素側から来ていなければ親要素を更新
                if (caller !== PARENT) {
                    promiseList.push(this._setParentChecked(item));
                }
                // 親子の更新が完了したら返す
                return all(promiseList);
            })).then(lang.hitch(this, function () {
                //console.info('completed:' + id);

                // チェック処理が終わった要素について、「処理完了マップ」に追加する。
                // なお、親要素がクリックされた場合、全ての子要素のチェック処理が完了しないと、
                // 親要素のチェック処理は完了にならない。
                if (checked && id !== '$ROOT$') {
                    this._checkCompletedMap[id] = item;
                } else if (id !== '$ROOT$') {
                    delete this._checkCompletedMap[id];
                }
            }), function (err) {
                console.error(err);
            });
        },

        /**
         * とある要素のチェック処理が終了しているかどうかを確認する
         * @param {String} id ツリー要素のid
         * @returns {true|false} 処理が終了しているか、いないか
         */
        isCheckCompleted: function (id) {

            // チェック要素について確認
            if (this._checkMap[id] && this._checkCompletedMap[id]) {
                // ステータスが一致していればOK
                return true;
            }
            // 非チェック要素について確認
            if (!this._checkMap[id] && !this._checkCompletedMap[id]) {
                // ステータスが一致していればOK
                return true;
            }
            return false;
        },

        /**
         * 要素クリック時に実行される。
         * @param {object} item ツリー要素
         */
        onClick: function (item, widget, evt) {
            if (this.isIconNode(evt.target, widget)) {
                this.setChecked(item, !this.isChecked(item));
            } else {
                this._onExpandoClick({ node: widget });
            }
        }
    });
});
