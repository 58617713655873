/**
 * 全アイコンをフォルダとして表示するツリー
 * @module idis/view/tree/FolderTree
 */
define([
    'module',
    'dojo/_base/declare',
    './IdisTree'
], function(module, declare, IdisTree) {
    /**
     * 全アイコンをフォルダとして表示するツリー・ウィジェット。
     * @class FolderTree
     * @extends module:idis/view/tree/IdisTree~IdisTree
     * @param {Object} kwArgs
     * @param {module:dijit/tree/model} kwArgs.model ツリー・モデル
     */
    return declare(module.id.replace(/\//g, '.'), IdisTree, /** @lends module:idis/view/tree/FolderTree~FolderTree# */ {
        // アイコンの表示
        getIconClass: function(item, opened) {
            // 子要素が無い or 閉じた状態なら閉じたフォルダを表示する
            var node = '';
            if (!this.model.mayHaveChildren(item) && item.infoCategoryCd.indexOf('D')!==-1) {
                node = 'dijitLeaf';
            } else if (this.model.mayHaveChildren(item) && opened) {
                node = 'dijitFolderOpened';
            } else if (this.model.mayHaveChildren(item)) {
                node = 'dijitFolderClosed';
            } else if (!this.model.mayHaveChildren(item) && item.infoCategoryCd.indexOf('T')!==-1 && opened) {
                node = 'dijitFolderOpened';
            } else if (!this.model.mayHaveChildren(item) && item.infoCategoryCd.indexOf('T')!==-1) {
                node = 'dijitFolderClosed';
            }
            return node;
        }
    });
});
