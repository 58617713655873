/**
 * 緊急速報メール利用市町村選択用入力パーツ
 * @module app/view/form/MunicipalityUsingUrgentMailSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/model/UserInfo',
    'idis/view/form/TreeSelector',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest'
], function(module, declare, UserInfo, TreeSelector,
    CacheStoreModel, CacheTreeRest) {
    /**
     * 緊急速報メール利用市町村選択用入力パーツ
     * @class MunicipalityUsingUrgentMailSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:idis/view/tree/ShelterFacilitySelector~ShelterFacilitySelector# */ {
        // 選択ダイアログのタイトル
        title: '市/行政区',

        // ツリー・モデル
        model: null,

        constructor : function() {
            var param = '';
            var municipalityCds = UserInfo.getMunicipalityCds();
            if(municipalityCds) {
                var firstFlg = true;
                for (var i = 0; i < municipalityCds.length; i++) {
                    if(firstFlg) {
                        param += '?';
                        firstFlg = false;
                    } else {
                        param += '&';
                    }
                    param += 'array[]=' + municipalityCds[i];
                }
            }

            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                    target: '/api/municipalities/municipality4Tree/urgentMail' + param
                })
            });
        }
    });
});
