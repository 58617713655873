/**
 * 住所情報地図表示詳細モジュール
 * @module app/view/page/GeoplotDetailDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/on',
    'idis/service/iframe',
    'dojo/text!./templates/GeoplotDetailDialog.html',
    'idis/consts/ACL',
    'idis/model/UserInfo',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'idis/view/LazyNode',
    'app/model/LayerDirectory',
    //'idis/model/UserInfo',
    // 以下、変数として受け取らないモジュール
    'idis/view/form/AclButton',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/ValidationTextBox',
    '../view/form/OrganizationSelector',
    '../view/form/PubStatusSelector',
    '../draw/DrawSelectIconDialog'
], function(module, declare, lang, domStyle, on, iframe, template, ACL, UserInfo,InfoDialog,
     _PageBase, Loader, LazyNode, LayerDirectory) {
    /**
     * 住所情報地図表示詳細ダイアログ
     * @class GeoplotDetailDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
     return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/GeoplotDetailDialog~GeoplotDetailDialog# */ {
        // テンプレート文字列
        templateString: template,

        // icon用imageまでの相対パス
        _defaultIconPath: '/images/draw/icon/',

        _iconUrl: null,

        _geoplotId: null,

        iconSelect: function(){
          this.iconSelectDialog.show();
        },

        /**
         * データ格納領域を初期化し、データを設定する
         */
        setPage: function(item){
            console.log(Loader);
            this._geoplotId = item.geoplotId;
            this.layerName.set('value', item.layerName);
            LayerDirectory.getFullName(item.parentLayerId || 0).then(lang.hitch(this, function(data) {
                this.layerDir.innerHTML =  data;
            }));

            this.minZoom.set('value', item.minZoom);
            this.minZoom.set('disabled', 'disabled');
            this.description.set('value', item.description);
            // 組織をセットする
            this.organization.set('value', item.organization);
            // 公開範囲選択セレクターを生成
            // デフォルトの公開範囲を全体(4)にしておく
            this.pubStatus.createSelector(item.pubStatus);
            //サーバーから取得したアイコンURLを設定
            this.selectedIcon.src = item.iconUrl;
            this._iconUrl = item.iconUrl;
        },

        update: function() {
            console.log('[更新]ボタンがクリックされました。');
            // formに定義されている情報を設定する
            var sendData = this.form.get('value');
            try {
                if (this.form.validate() && this.isGeofileDetailValid(sendData)) {
                    this.emit('sendupdate', {
                        value: sendData,
                        id: this._geoplotId
                    });
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        del: function() {
            console.log('[削除]ボタンがクリックされました。');
            this.emit('senddelete', {value: this._geoplotId});
            return false;
        },

        startup: function(){
          this.inherited(arguments);
          this.setButtonStatus();
        },

        // ユーザー権限が「閲覧ユーザ」の場合は新規登録ボタンを非活性に
        setButtonStatus: function(){
            var role = UserInfo.getRoleCd();
            if (role === ACL.VIEW_USER) {
                this.updateButton.setDisabled(true);
                this.deleteButton.setDisabled(true);
            }
        },
        /**
         * 入力値をチェックする
         */
        isGeofileDetailValid: function(data) {
            if (!data.pubStatus || data.pubStatus === '') {
                InfoDialog.show('入力エラー', '公開範囲を選択してください。');
                return false;
            }
            return true;
        }
    });
});
