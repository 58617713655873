/**
 * アプリ共通のカラム定義
 * @module app/view/grid/CommonColumn
 */
define([
    'dojo/when',
    'dojo/date/locale',
    'idis/view/grid/helper',
    'idis/view/LazyNode',
    'idis/util/TypeUtils',
    'app/model/Municipality',
    'app/model/ObservatoryManager'
], function(when, locale, helper, LazyNode, TypeUtils, Municipality, ObservatoryManager) {
    return {
        municipalityCd: {
            field: 'municipalityCd',
            label: '市町',
            renderCell: function(item) {
                var itemPromise = Municipality.store.get(item.municipalityCd);
                var lazyNode = new LazyNode({
                    promise: when(itemPromise).then(function(item) {
                        if (item) {
                            return Municipality.getLabel(item);
                        } else {
                            return '山口県外';
                        }
                    })
                });
                return lazyNode.domNode;
            }
        },
        //TODO 管理者情報を取得後ObservatoryManagerを修正し利用
        // observatoryName: helper.column('observatoryName', '観測局', {
        //     formatter: function(v, item) {
        //         var suffix =
        //             (!!ObservatoryManager[item.managerCd]) ?
        //                 ObservatoryManager[item.managerCd].suffix : '';
        //         return item.observatoryName + suffix;
        //     }
        // }),
        observatoryName: helper.column('observatoryName', '観測局'),
        text: helper.column('text', '発表文', {
            formatter: function(value) {
                // 100文字以上は、省略して詳細で確認してもらう。
                var text = value.length > 100 ?
                    [value.substr( 0, 100 ), '...'].join() : value;
                return text;
            },
            sortable: false
        }),
        managerCd: helper.column('managerCd', '管理区分', {
            formatter : function(managerCd) {
                //TODO 管理者情報を取得後ObservatoryManagerを修正し利用
                console.debug(ObservatoryManager[managerCd]);
                switch (managerCd){
                    case '0000':
                        return '県';
                    case '0010':
                        return '山口県';
                    case '9999':
                        return '国';
                    default:
                        //TODO SNET観測所用にデフォルトを札幌市にするが、市外観測所もあるので修正必要
                        return '山口県';
                }
                // return (!!ObservatoryManager[managerCd]) ? ObservatoryManager[managerCd].name : 'その他';
            }
        }),

        /**
         * 基準値列用の列定義オブジェクトを返す。
         * 受け取った値を小数点以下2桁まで表示する。
         * 値が偽値の場合は'-'を表示する（0の場合も'-'を返す点に注意）。
         * @param {string} field フィールド名
         * @param {string} label タイトル行に表示する文字列
         */
        threshold: function(field, label, fixedNum) {
            return helper.column(field, label, {
                formatter: function(value) {
                    return !value ? '-' :
                        TypeUtils.isNumber(value)&&TypeUtils.isNumber(fixedNum) ?
                        value.toFixed(fixedNum) : value;
                }
            });
        },

        thresholdDissotable: function(field, label, fixedNum) {
            return helper.column(field, label, {
                formatter: function(value) {
                    fixedNum = fixedNum ? fixedNum : 2;
                    return value ? value.toFixed(fixedNum) : '-';
                },sortable : false
            });
        },

        /**
         * 基準値列用の列定義オブジェクトを返す。
         * 受け取った値を小数点以下2桁まで表示する。
         * 値が偽値の場合は'0'を表示する。
         * @param {string} field フィールド名
         * @param {string} label タイトル行に表示する文字列
         */
        thresholdDissotableCan0: function(field, label) {
            return helper.column(field, label, {
                formatter: function(value) {
                    return value ? value.toFixed(2) : '0';
                },sortable : false
            });
        },

        /**
         * 値がない場合、'-'を返す
         */
        optionColumn: function(field, label) {
            return helper.column(field, label, {
                formatter: function(value) {
                    return value || '-';
                }
            });
        },

        optionColumnNotSortable: function(field, label) {
            return helper.column(field, label, {
                formatter: function(value) {
                    return value || '-';
                } ,sortable : false
            });
        },

        /**
         * 日時カラムの表示形式を変更
         * @param {string} field フィールド名
         * @param {string} label タイトル行に表示する文字列
         */
        formatDateTime: function(field, label) {
            return helper.column(field, label, {
                formatter: function(datetime) {
                    if(!datetime){
                        return '-';
                    }
                    var reportDateTime = new Date(datetime.replace(/-/g, '/'));
                        var dateLabel = locale.format(reportDateTime, {
                            selector: 'date',
                            datePattern: 'MM月dd日'
                        });
                        var timeLabel = locale.format(reportDateTime, {
                            selector: 'time',
                            timePattern: 'HH時mm分'
                        });
                    return dateLabel + ' ' + timeLabel;
                }
            });
        },

        /**
         * 異常データの表記を返す
         * @param item      グリッドデータ
         * @param keyName   対象データのプロパティ名
         * @param fixedNum  小数以下桁数(option)
         */
        formatObservationData: function(item, keyName, fixedNum) {
            var value = item[keyName];
            var flg = item[keyName+'Flg'];
            // 正常データの場合
            if (flg==='0' && value!==null) {
                return TypeUtils.isNumber(value)&&TypeUtils.isNumber(fixedNum) ? value.toFixed(fixedNum) : value;
            }
            // 異常データの場合
            return flg === '160' ? '(未)---' : '(保守)***';
        },

        /**
         * 基準値超過or異常データのクラス名を返す
         * @param item      グリッドデータ
         * @param keyName   対象データのプロパティ名
         * @param excess    基準値超過フラグ(option)
         * @returns         観測状況
         */
        getClassNameExcess: function(item, keyName, excess) {
            var value = item[keyName];
            var flg = item[keyName+'Flg'];
            if (flg==='0' && value!==null) {
                return {
                    1: 'is-overLimitLevel-01',
                    2: 'is-overLimitLevel-02',
                    3: 'is-overLimitLevel-03',
                    4: 'is-overLimitLevel-04',
                    5: 'is-overLimitLevel-05',
                    6: 'is-overLimitLevel-06'
                }[excess] || 'is-normal';
            }
            return flg === '160' ? 'is-notYet' : 'is-missing';
        }
    };
});
