/**
 * チャットユーザの詳細ダイアログ用の基底モジュール。
 * @module app/chat/_ChatUserDialogBase
 */
define([
    'module',
    'leaflet',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/page/_PageBase',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'idis/view/form/AclButton',
    'idis/view/form/Button'
], function(module, leaflet, declare, lang, _PageBase) {

    return declare(module.id.replace(/\//g, '.'), _PageBase,
    {

        _chatGroupDestId: null,

        _chatGroupId: null,

        _userId: null,

        _municipalityName: null,

        _organizationName: null,

        _userName: null,

        _emailAddress: null,

        _sendRoleType: null,

        _autoUpdateFlg: null,

        /**
         * constructor
         */
        constructor: function() {
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * フォームをリセットする。
         */
        reset: function() {
            this.form.reset();
        },

        /**
         * 入力値の妥当性を検証する。
         */
        validate: function() {
            // ここでチェックする必要がある項目がないためtrueを返す
            return true;
        }

    });
});
