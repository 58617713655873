/**
 * 共有先選択用入力パーツ(複数選択可)
 * @module app/view/form/ShareOrganizationMultiSelector
 */
 define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/string',
    'dijit/form/TextBox',
    'dojo/dom-style',
    'idis/view/form/TreeChecker',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    './HldOrganizationMultiSelector'
], function(module, declare, lang, dStr, TextBox,
    domStyle, TreeChecker, CacheStoreModel, CacheTreeRest, HldOrganizationMultiSelector) {
    /**
     * 対応課選択用パーツ。
     * @class ShareOrganizationMultiSelector
     * @extends module:app/view/form/HldOrganizationMultiSelector~HldOrganizationMultiSelector
     */
    return declare(module.id.replace(/\//g, '.'), HldOrganizationMultiSelector,
        /** @lends module:app/view/form/ShareOrganizationMultiSelector~ShareOrganizationMultiSelector# */ {
        // 選択ダイアログのタイトル
        title: '共有先選択',

        // ツリー・モデル
        model: null,

        emptyValueLabel: '全体',

        constructor : function() {
            this.inherited(arguments);
        },

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
        }
    });
});
