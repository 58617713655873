define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function (module, declare, lang, locale, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--sending-chronology',

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            helper.column('noteContetnts', '内容', { sortable: false }),
            helper.column('noteRegisterer', '登録者', { sortable: false }),
            helper.column('noteDatetime', '日時', {
                formatter: lang.hitch(this, function (item) {
                    if (item) {
                        return locale.format(new Date(item));
                    }
                    return '';
                }),
                sortable: false
            })
        ]
    });
});
