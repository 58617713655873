/**
 * 標準時刻入力欄用モジュール。
 * @module idis/view/form/TimeInput
 */
define([
    'module',
    'dojo/_base/declare',
    'dijit/form/TimeTextBox',
    './_DateTimeInputMixin',
    './_NamedMixin'
], function(module, declare, TimeTextBox, _DateTimeInputMixin, _NamedMixin) {
    /**
     * 標準時刻入力用ウィジェット
     * @class TimeInput
     * @extends module:dijit/form/TimeTextBox~TimeTextBox
     * @extends module:idis/view/form/_DateTimeInputMixin~_DateTimeInputMixin
     * @extends module:idis/view/form/_NamedMixin~_NamedMixin
     */
    return declare(module.id.replace(/\//g, '.'), [TimeTextBox, _DateTimeInputMixin, _NamedMixin],
        /** @lends module:idis/view/form/TimeInput~TimeInput# */ {
        // 不正な入力値に対するメッセージ
        invalidMessage: '実在の時刻（HH:mm 形式）を設定してください。',

        // 入力欄をHH:mm形式にする
        constraints: {timePattern:'HH:mm'},

        // プレースホルダーに表示する文字列
        placeHolder: 'HH:mm',

        /**
         * 終了日時用の入力欄かどうか
         * @type {boolean}
         */
        isTo: false,

        // value値を取得する
        _getValueAttr: function() {
            var value = this.inherited(arguments);
            // 入力値が設定され、かつ終了日時用入力欄の場合
            if (value && this.isTo) {
                // 元の結果をコピー
                value = new Date(value.getTime());
                // 秒・ミリ秒を最大値に設定
                value.setSeconds(59);
                value.setMilliseconds(999);
            }
            return value;
        }
    });
});
