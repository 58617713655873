/**
 * 避難所選択用入力パーツ
 *
 * @module idis/view/form/ShelterSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/string',
    'dijit/form/TextBox',
    'dojo/when',
    'idis/view/form/TreeSelector',
    'idis/service/Requester',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'idis/model/UserInfo',
    'app/config',
    'app/model/DisasterInfo',
    'idis/view/dialog/InfoDialog'
], function(module, declare, lang, dStr, TextBox, when, TreeSelector, Requester, CacheStoreModel, CacheTreeRest,
    UserInfo, config, DisasterInfo, InfoDialog) {
    /**
	 * 避難所選択用パーツ。
	 *
	 * @class ShelterSelector
	 * @extends module:idis/view/form/TreeSelector~TreeSelector
	 */
    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:idis/view/tree/ShelterSelector~ShelterSelector# */ {
        // 選択ダイアログのタイトル
        title: '避難所選択',
        // ツリー・モデル
        model: null,
        constructor : function() {
            //ユーザの管理対象市町を取得
            var userMunicipalityCds = UserInfo.getMunicipalityCds();
            // UserInfo.getMunicipalityCdsが空のユーザがいるため暫定対応
            if (userMunicipalityCds[0] === '') {
                var args = [];
                args += UserInfo.getMunicipalityCd();
                userMunicipalityCds = args;
            }
            // 災害IDをDisasterInfoから取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定されていません。');
            }
            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                	// クエリにセットされているmunicipalityCdを取得し、その値に一致する施設をツリーで返す
                    target: '/api/shelters/facilities4Tree/' + userMunicipalityCds + '/' + this._disasterId
                })
            });
        },
        // DOMを構築する
        buildRendering: function () {
            this.inherited(arguments);
            // 検索用入力要素を設置
            this.filterInput = new TextBox({
                placeHolder: this.placeHolder,
                maxLength: 128
            });
            this.filterInput.placeAt(this.treeControlNode);
            this.own(this.filterInput.on('keyup', lang.hitch(this, 'onFilterInputChange')));
        },

        // value値を設定する
        // 継承元との差分は以下
        // - wardUnselectableが指定された場合、区フォルダは選択不可。
        _setValueAttr: function(value) {
            // 偽値は数値の0のみ有効とする
            if (value || value === 0) {
                if(this.cityUnselectable){
                    // 市町は選択不可
                    Requester.get('/data/municipality/municipalityList.json')
                        .then(lang.hitch(this, function(obj) {
                        var municipality = obj.municipalities.filter(function (municipality) {
                            return municipality.municipalityCd !== config.municInfo.prefMunicCd &&
                                    municipality.municipalityCd === value;
                        });
                        if(municipality.length !== 0){
                            InfoDialog.show('エラー', '市町は選択することができません。');
                            this.reset();
                            return false;
                        }
                    }));
                }
                if(this.wardUnselectable){
                    // 区は選択不可
                    console.log(config.municInfo.wardList.indexOf(value));
                    if(config.municInfo.wardList.indexOf(value) !== -1){
                        InfoDialog.show('エラー', '区は選択することができません。');
                        this.reset();
                        return false;
                    }
                }
                this._set('value', value);
                this.emit('change', {value: value});
                this._initTree().then(lang.hitch(this, function() {
                    var model = this.tree.model;
                    var label;
                    if (this.fullName) {
                        label = model.getFullName(value);
                    } else {
                        label = when(model.store.get(value), function(item) {
                            return model.getLabel(item);
                        });
                    }
                    when(label, lang.hitch(this, function(name) {
                        this.status.innerHTML = name;
                    }));
                }));
            } else {
                this._set('value', '');
                this.emit('change', {value: ''});
                this.status.innerHTML = this.emptyValueLabel;
            }
            // 要素の選択状態をリセットする
            this._initTree().then(lang.hitch(this, function() {
                this.tree.set('selectedItem', null);
            }));
        },
        /**
         * 検索用入力要素入力時。
         */
        onFilterInputChange: function () {
            // 入力値を取得
            var value = dStr.trim(this.filterInput.get('value'));
            if (value) {
                // ラベル名が入力値を含むものでフィルターし、ツリーを展開する
                this.model.setFilter(function (item, isLeaf, model) {
                    return isLeaf && (model.getLabel(item) || '').indexOf(value) !== -1;
                }).then(lang.hitch(this, function () {
                    this.tree.expandAll();
                }));
            } else {
                // 入力が空の場合は全要素を表示してツリーを閉じる
                this.model.setFilter().then(lang.hitch(this, function () {
                    this.tree.collapseAll();
                }));
            }
        }

    });
});
