/**
 * name属性を指定すべきウィジェット
 * @module idis/view/form/_NamedMixin
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/has',
    '../../error/InvalidArgumentException'
], function(module, declare, has /*, InvalidArgumentException*/) {
    /**
     * name属性を指定すべきウィジェットで継承する。
     * @class _NamedMixin
     * @param {Object} kwArgs
     * @param {string} kwArgs.name ウィジェットのname属性
     */
    return declare(module.id.replace(/\//g, '.'), null, {
        noName: false,

        // コンストラクター引数がプロパティーへ反映された後に呼ばれる
        postMixInProperties: function() {
            this.inherited(arguments);
            // name属性が設定されていなければエラーを投げる
            if (has('dojo-debug-messages') && !this.name && !this.noName) {
                var target = this.srcNodeRef ? this.srcNodeRef.outerHTML : this.declaredClass;
                console.warn('入力要素にname属性が設定されていません: ' + target + '\n' +
                             'data-dojo-props等でnameを設定するか、noNameをtrueにしてください。');
            }
        }
    });
});

