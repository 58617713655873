/**
 * テンプレート管理用グリッド
 * @module app/evacorder/EvacOrderTemplateSelectorGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid,
        /** @lends module:app/evacorder/EvacOrderTemplateSelectorGrid~ProvideTemplatePage# */ {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--evacorder',

        collection: this.store,
        columns: [
            helper.buttonColumn('apply', '適用'),
            helper.column('municipalityName', '市町', {sortable: false}),
            helper.column('issueReasonType', '発令理由', {
                formatMap: {
                    '01':'土砂',
                    '02':'洪水',
                    '03':'地震',
                    '04':'津波',
                    '05':'高潮',
                    '06':'火災',
                    '07':'暴風',
                    '08':'火山',
                    '09':'国民保護',
                    '00':'その他'
                },sortable: false
            }),
            helper.column('issueReasonDetail', '発令理由詳細', {sortable: false}),
            helper.column('evacGuidance', '避難行動指針', {sortable: false}),
            helper.column('supplementInfo', '補足情報', {sortable: false,}),
        ]
    });
});
