/**
 * 災害体制の列定義一覧。
 * @module app/river/grid/RiverLevelCommonColumn
 */
define([
    'dojo/_base/lang',
    'idis/view/grid/helper',
    'app/view/grid/CommonColumn'
], function(lang, helper, CommonColumn) {
    return {
        excess: helper.column('crisiManageRiverExcess', '基準値超過', {
            formatter : lang.hitch(this,function(v, item) {
                return {
                    1:'(待)',
                    2:'(注)',
                    3:'(避)',
                    4:'(危)',
                    5:'(観)',
                    6:'(危)',
                    7:'(氾)'
                }[item.excess] || '-';
            }),
            className : lang.hitch(this,function(item) {
                // ヘッダーの場合はclassNameを返さない
                if (!item) {
                    return;
                }
                return {
                    1:'is-wait',
                    2:'is-caution',
                    3:'is-judgment',
                    4:'is-crisis',
                    5:'is-obsStart',
                    6:'is-dangerous',
                    7:'is-floodStart'
                }[item.excess] || 'is-normal';
            }), sortable: false
        }),
        riverLevel: helper.column('riverLevel', '10分水位\n[m]', {
            formatter: function(value, item) {
                return CommonColumn.formatObservationData(item, 'riverLevel', 2);
            },
            className : function(item) {
                // ヘッダーの場合はclassNameを返さない
                if (!item) { return; }
                return CommonColumn.getClassNameExcess(item, 'riverLevel');
            }, sortable: false
        })
    };
});

