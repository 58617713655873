/**
 * 格納先選択用入力パーツ
 * @module app/view/form/DrawLayerSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/form/TreeSelector',
    'idis/view/tree/DrawTree',
    'idis/view/dialog/InfoDialog',
    'app/model/LayerStore',
    '../../model/DrawLayer'
], function(module, declare, lang,
    TreeSelector, DrawTree, InfoDialog, LayerStore, DrawLayer) {
    /**
     * 格納先選択用パーツ。
     * @class DrawLayerSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:app/view/form/DrawLayerSelector~DrawLayerSelector# */ {
        // 選択ダイアログのタイトル
        title: '読み込みファイル選択',

        // ツリー・モデル
        model: DrawLayer,

        // ツリー・ウィジェット生成用クラス
        treeClass: DrawTree,

        // ツリー全展開ボタンは危険なので非表示
        noExpandAll: true,

        /**
         * 選択した値の設定
         */
        _setValueAttr: function(layerId) {
            this.inherited(arguments);
            if (!layerId) { return; }
            // URLに登場するレイヤーを順にチェック
            LayerStore.get(layerId).then(function(item) {
                // フォルダーなら選択不可
                return item.infoCategoryCd.indexOf('T') === 0 ?
                    'フォルダは選択できません。' : null;
            }, function () {
                // 万が一、失敗なら選択不可とする
                return '選択できません。削除されている可能性があります。';
            }).then(lang.hitch(this, function(errMessage) {
                if (errMessage) {
                    InfoDialog.show('エラー', errMessage);
                    this.reset();
                }
            }));
        }
    });
});
