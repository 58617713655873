define({
    OTHER: {id:'00', name:'その他'},
    WEATHER_WARNING: {id:'01', name:'防災気象情報'},
    CIVIL_PROTECTION: {id:'02', name:'国民保護情報'},
    OBSERVATORY: {id:'03', name:'観測情報'},
    EVACUATION: {id:'24', name:'避難情報'},
    PREVENTION: {id:'25', name:'配備体制'},
    SHELTER: {id:'26', name:'避難所情報'},
    DAMAGE: {id:'27', name:'被害情報'},
    DISASTER: {id:'29', name:'災害名管理'},
    DISASTER_HLD: {id:'30', name:'要請・措置'},
    WHITE_BOARD: {id:'31', name:'組織内情報'},
    NOTIFICATION: {id:'34', name:'情報提供'},
    RESCUE: {id:'32', name:'部隊活動情報'},
    TRAFFIC: {id:'33', name:'通行規制情報'},
    SCHEDULED_REQUEST: {id:'41', name:'定時報告依頼'},
    SCHEDULED_REPORT: {id:'42', name:'定時報告'}
});
