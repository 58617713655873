/**
 * <避難所一括操作画面>
 *
 * @module app/shelter/ShelterBulkOperatingPage.js
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/array',
    'dojo/Deferred',
    'dojo/dom-style',
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/text!./templates/ShelterBulkOperatingPage.html', // テンプレート文字列
    'dojo/topic',
    'idis/store/IdisRest',
    'idis/service/Requester',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/control/Locator',
    'app/model/DisasterInfo',
    './_ShelterPageBase', // 避難所ページ基底クラス
    'app/config',
    'idis/consts/USER_TYPE',
    'app/model/Region',
    './ShelterBulkOperatingGrid',
    './consts/CrowdedStatus',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/CheckBox',
    'dijit/form/Form',
    'dijit/form/RadioButton',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'app/provide/ProvideShelterRegisterDialog',
    'app/view/form/CustomizableMunicipalitySelector',
    'app/view/form/DistrictSelector',
    './ShelterBulkOperatingGrid',
    './ShelterBulkNumberEditDialog',
    './ShelterBulkTimeEditDialog',
    './ShelterBulkSelectEditDialog'
], function(module, array, Deferred, domStyle, declare, lang, template, topic, IdisRest, Requester,
    InfoDialog, DialogChain, Loader, Router, UserInfo, Locator,
    DisasterInfo, _ShelterPageBase, config, USER_TYPE, Region, ShelterBulkOperatingGrid, CrowdedStatus) {
    /**
     * 避難所一括操作画面
     *
     * @class ShelterOpenigPage
     * @extends module:app/shelter/_ShelterPageBase~_ShelterPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _ShelterPageBase,
        /** @lends module:app/shelter/ShelterBulkOperatingPage~ShelterBulkOperatingPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--shelter',

        // Grid用Store
        store: null,

        // お知らせ用Dialog
        infoDialog: null,

        /**
         * クエリ用市町村コード
         */
        _municipalityCd: null,

        /**
         * ユーザー用市町村コード
         */
        _userMunicipalityCds: null,

        /**
         * 各開設フラグ
         */
        _floodShFlg: '0',
        _sedimentDisasterShFlg: '0',
        _stormSurgeShFlg: '0',
        _earthquakeShFlg: '0',
        _tsunamiShFlg: '0',
        _fireShFlg: '0',
        _landsideWaterShFlg: '0',
        _volcanoShFlg: '0',

        constructor: function() {
            this.store = new IdisRest({
                idProperty: 'facilityId',
                target: '/api/shelters/operating'
            });
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
            // 災害IDをDisasterInfoから取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定されていません。');
            }
            //this.OpenClose();
        },

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.shelterGrid);
            this.own(this.shelterRegisterDialog);
            this.own(this.timeEditDialog);
            this.own(this.numberEditDialog);
            this.own(this.selectEditDialog);
            this.initUserMunicipalityCds();
            this.initDistrictSelector();
            // this.initMunicipalitySelector().then(lang.hitch(this, this.updateGridQuery));
            this.own(topic.subscribe(ShelterBulkOperatingGrid.AUTO_SET_CROWDED_STATUS, lang.hitch(this, function() {
                this.chain.info(CrowdedStatus.changeMessage, '情報');
            })));
        },

        // 地区の初期値をセット
        initDistrictSelector: function() {
            var userMunicipalityCd = ( UserInfo.getMunicipalityCds()[0] === config.municInfo.prefMunicCd) ?
                                        config.municInfo.defaultMunicCd : UserInfo.getMunicipalityCds()[0] ;

            var districtCd = userMunicipalityCd.substr(2, 3) + '000';

            if(UserInfo.getUserType === USER_TYPE.MUNICIPALITY &&
                    UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd){
                this.districtCd.set('value', districtCd);
            }
            else {
                // 一覧画面で選択された市町村/地区がある場合
                if(Locator.getQuery().districtCd){
                    this.districtCd.set('value', Locator.getQuery().districtCd);
                }
                // 一覧で選択された振興局/市町村がある場合
                if(Locator.getQuery().municipalityCd){
                    this.isRegion(Locator.getQuery().municipalityCd).then(lang.hitch(this, function(isRegion){
                        if(isRegion){
                            //振興局は設定できないので未選択をセット
                            this.municipalityCd.set('clear', true);
                        } else {
                            //市町村として設定
                            this.municipalityCd.set('value', Locator.getQuery().municipalityCd);
                        }
                    }));
                }
                else{
                    // クエリパラメータ自体がない場合は、未選択をセット
                    this.districtCd.set('clear', true);
                    this.municipalityCd.set('clear', true);
                }
            }
        },

        initMunicipalitySelector: function(){
            var deferred = new Deferred();
            if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY &&
                (config.municInfo.cityDistrictMunicCds.indexOf(UserInfo.getMunicipalityCd()) === -1)) {
                // 市町ユーザの場合、自分の市町を設定
                this.municipalityCd.set('value', UserInfo.getMunicipalityCd());
                this.municipalityCd.set('noAllButton', true);
                domStyle.set(this.municipalityCd.status, 'margin-top', '8px');
                deferred.resolve();
            } else {
                // 一覧で選択された振興局/市町村がある場合
                if(Locator.getQuery().municipalityCd){
                    this.isRegion(Locator.getQuery().municipalityCd).then(lang.hitch(this, function(isRegion){
                        if(isRegion){
                            //振興局は設定できないので未選択をセット
                            this.municipalityCd.set('clear', true);
                        } else {
                            // 政令指定都市であれば設定しない
                            if (Locator.getQuery().municipalityCd !== config.municInfo.cityMunicCd) {
                                //市町村として設定
                                this.municipalityCd.set('value', Locator.getQuery().municipalityCd);
                            }
                        }
                        deferred.resolve();
                    }));
                } else {
                    // クエリパラメータ自体がない場合は、未選択をセット
                    // this.districtCd.set('clear', true);
                    this.municipalityCd.set('clear', true);
                    deferred.resolve();
                }
            }
            return deferred.promise;
        },

        initUserMunicipalityCds: function(){
            var userMunicipalityCds = UserInfo.getMunicipalityCds();
            var arr = [];
            userMunicipalityCds.forEach(function(value){
                arr.push(value);
            });
            this._userMunicipalityCds = arr.join(',');
        },

        //ラジオボタンをクリックした時の挙動
        switchOpenClose: function(){
            // var radio = document.getElementsByName('isClose');
            this.onSubmit();
        },

        /**
        * グリッドの検索条件を指定された値で更新する。
        * @param {Object} value name属性と値のマッピング
        */
        updateGridQuery: function() {
            var value = this.form.get('value');
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            filter = filter.eq('disasterId', this._disasterId);
            // 未開設(0)or開設中(1)or全て(2)を指定、検索条件に追加
            if (value.isClose) {
                filter = filter.eq('isClose', value.isClose);
            }

            // 検索条件として地区名が指定されていたらそのdistrictCdを検索条件としてセット
            if (value.districtCd) {
                filter = filter.eq('districtCd', value.districtCd);
            }

            //選択した地区に合わせて市町コードを取得。登録した避難所の市町村が一覧画面にセットされるようにする。
            //地区コードから、市町コードの5桁目までを作成
            var newMunicipalityCd = value.districtCd.substr(0, 3);
            if (newMunicipalityCd.substr(0, 1) === '1'){
                newMunicipalityCd = '100';
            }
            //5桁までが一致する管理対象市町コードを取得する
            var userMuniStr = UserInfo.getMunicipalityCds().join(',');
            var municipalityCdsChar = userMuniStr.indexOf('35'+ newMunicipalityCd);
            this._municipalityCd = userMuniStr.substr(municipalityCdsChar, 6);
            filter = filter.eq('municipalityCd', this._municipalityCd);

            //対象災害
            var shelterType = '';
            if(this.floodShSchFlg.checked){
                shelterType += '02,';
            }
            if(this.sedimentDisasterShSchFlg.checked){
                shelterType += '01,';
            }
            if(this.stormSurgeShSchFlg.checked){
                shelterType += '05,';
            }
            if(this.earthquakeShSchFlg.checked){
                shelterType += '03,';
            }
            if(this.tsunamiShSchFlg.checked){
                shelterType += '04,';
            }
            if(this.fireShSchFlg.checked){
                shelterType += '06,';
            }
            if(this.landsideWaterShSchFlg.checked){
                shelterType += '07,';
            }
            if(this.volcanoShSchFlg.checked){
                shelterType += '08,';
            }
            filter = filter.eq('shelterType', shelterType);


            //避難所区分
            var shelterCategory = '';
            if(this.eDesignatedEvacShFlg.checked){
                shelterCategory += '01,';
            }
            if(this.designatedEvacShFlg.checked){
                shelterCategory += '02,';
            }
            if(this.welfareEvacShFlg.checked){
                shelterCategory += '03,';
            }
            if(this.temporaryEvacShFlg.checked){
                shelterCategory += '04,';
            }
            if(this.otherEvacShFlg.checked){
                shelterCategory += '05,';
            }
            if(this.civilProtectionEvacShFlg.checked){
                shelterCategory += '06,';
            }
            filter = filter.eq('shelterCategory', shelterCategory);

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.shelterGrid.resetMap();
            this.shelterGrid.set('collection', collection);
        },

        isRegion: function(cd){
            return Region.store.get(cd).then(lang.hitch(this, function(region){
                return region ? true : false;
            }));
        },

        /**
        * 検索ボタンが押されたときに呼び出される。
        * テンプレートHTML内のFormウィジェットに対するdata-dojo-attach-eventでこのイベントを紐付けている。
        * また、同Formウィジェットに対しdata-dojo-attach-pointを指定し、this.formからアクセス出来るようにしている。
        */
        onSubmit: function() {
            try {
                if (this.districtCd.get('value') === '') {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : 'いずれかの市町/地区を選択してください'
                    });
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return false;
                }
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        // パンくずリストのボタンを押下したときの挙動(概況画面へ遷移)
        onShelterAdminPageLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('shelter/admin');
            this.municipalityCd.set('value', null);
        },

        // パンくずリストのボタンを押下したときの挙動(一覧画面へ遷移)
        onShelterListPageLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('shelter', {
                // 一覧画面で市町村名、市町村コードを使用するためクエリにセット
    //                municipalityCd : Locator.getQuery().districtCd
                municipalityCd : this.municipalityCd.value
            });
            this.municipalityCd.set('value', null);
        },

        // 登録ダイアログを表示する
        registerShelterButtonClick: function() {
            //開設か閉鎖か、全件取得か。
            var isClose = this.form.get('value').isClose;
            //現在チェックがついている施設IDを取得
            var changedRowList = this.shelterGrid.getSelectedIdList();

            // 混雑状況がセットされていない場合は自動算出する
            for (var i = 0; i < changedRowList.length; i++) {
                var crowdedStatus = this.shelterGrid.crowdedStatusMap[changedRowList[i]];
                if (!crowdedStatus) {
                    var evaqueeNum = this.shelterGrid.evaqueeNumMap[changedRowList[i]];
                    this.shelterGrid.setCrowdedStatus(changedRowList[i], evaqueeNum, false);
                }
            }

            // 新規開設、新規閉鎖施設名格納用List
            var newShelterList = [];
            var endShelterList = [];

            //利用不可の施設は選択リストから除外する
            for(var i = 0; i < changedRowList.length; i++){
                var availableFlg = this.shelterGrid._rowIdToObject['shelterGrid-row-' +
                changedRowList[i]].availableFlg;
                if(availableFlg === '0'){
                    changedRowList.splice(i,1);
                } else {
                    // 施設名
                    var currentFacilityName = this.shelterGrid._rowIdToObject['shelterGrid-row-' +
                        changedRowList[i]].facilityName;

                    // 現在の施設開設時間と入力中の施設開始時間を取得
                    var initialStartTime = this.shelterGrid._rowIdToObject['shelterGrid-row-' +
                        changedRowList[i]].shelterStartTime;
                    var startTime = this.shelterGrid.shelterStartTimeMap[changedRowList[i]];

                    // 現在の施設閉鎖時間と入力中の施設閉鎖時間を取得
                    var initialEndTime = this.shelterGrid._rowIdToObject['shelterGrid-row-' +
                        changedRowList[i]].shelterEndTime;
                    var endTime = this.shelterGrid.shelterEndTimeMap[changedRowList[i]];

                    // 元の開設時間が未入力の時、閉鎖時間未入力かつ開設時間に入力がある場合
                    // 未開設避難所に閉鎖時間を入力すると登録は可能だがオペミスとして処理される。外部配信はされないようにしておく
                    if(initialStartTime === null && typeof startTime !== 'undefined' && startTime !== null &&
                            (typeof endTime === 'undefined' || endTime === null)) {
                        newShelterList.push(currentFacilityName);
                    }

                    // 元の開設時間登録済み、閉鎖時間未入力の時、閉鎖時間に入力がある場合
                    if(initialStartTime !== null && initialEndTime === null &&
                            typeof endTime !== 'undefined' && endTime !== null) {
                        endShelterList.push(currentFacilityName);
                    }
                }
            }

            //入力チェック
            if(changedRowList.length === 0 || changedRowList === null){
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : '未入力',
                        content : '避難所を選択して下さい。'
                    });
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return false;
                }
            }

            var startErrList = [];
            var startErrCount = 0;
            var reportErrList = [];
            var reportErrCount = 0;
            var endErrList = [];
            var endErrCount = 0;

            //入力チェック
            array.forEach(changedRowList, function(facilityId) {
                //施設名を取得
                var facilityName = this.shelterGrid._rowIdToObject['shelterGrid-row-' + facilityId].facilityName;
                //開設日時が未記入の場合、その名前をlistに蓄積。
                var shelterStartTime = this.shelterGrid.shelterStartTimeMap[facilityId];
                //報告日時が未記入の場合、その名前をlistに蓄積。
                var reportTime = this.shelterGrid.reportTimeMap[facilityId];
                var shelterEndTime = this.shelterGrid.shelterEndTimeMap[facilityId];
                if (!shelterStartTime) {
                    //入力エラーの施設名をpushし、件数をカウント
                    startErrList.push(facilityName);
                    startErrCount = startErrCount + 1;
                    if(startErrCount > 4){
                        startErrList = ['5件以上の避難所'];
                    }
                }
                if (!reportTime) {
                    //入力エラーの施設名をpushし、件数をカウント
                    reportErrList.push(facilityName);
                    reportErrCount = reportErrCount + 1;
                    if(reportErrCount > 4){
                        reportErrList = ['5件以上の避難所'];
                    }
                }
                if (shelterStartTime && shelterEndTime && shelterStartTime > shelterEndTime) {
                    //入力エラーの施設名をpushし、件数をカウント
                    endErrList.push(facilityName);
                    endErrCount = endErrCount + 1;
                    if(endErrCount > 4){
                        endErrList = '5件以上の避難所';
                    }
                }
            }, this);

            var startTimeErrMsg = startErrList.join(',') + 'の開設日時が設定されていません。' + '\n';
            var reportTimeErrMsg = reportErrList.join(',') + 'の報告日時が設定されていません。' + '\n';
            var endTimeErrMsg = endErrList.join(',') + 'の閉鎖日時が開設日時より前に設定されています。';

            if(startErrCount > 0){
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : startTimeErrMsg
                    });
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return false;
                }
            } else if(reportErrCount > 0){
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : reportTimeErrMsg
                    });
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return false;
                }
            } else if (endErrCount > 0){
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : endTimeErrMsg
                    });
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return false;
                }
            }

            // 選択された要素の情報を送信用に加工
            var data = {
                items: array.map(changedRowList, function(facilityId) {
                    return {
                        disasterId: this._disasterId,
                        facilityId: facilityId,
                        reportTime: this.shelterGrid.reportTimeMap[facilityId],
                        shelterStartTime: this.shelterGrid.shelterStartTimeMap[facilityId],
                        shelterEndTime: this.shelterGrid.shelterEndTimeMap[facilityId],
                        evacHouseholdNum: this.shelterGrid.evacHouseholdNumMap[facilityId],
                        evaqueeNum: this.shelterGrid.evaqueeNumMap[facilityId],
                        crowdedStatus: this.shelterGrid.crowdedStatusMap[facilityId]
                    };
                },
                this)
            };

            // 配列のまま渡せないため、カンマで文字列を結合する
            var facilityIds = changedRowList.reverse().join(',');
            this._sendData = data;
            data.shelterStartName = newShelterList.join('、');
            data.shelterEndName = endShelterList.join('、');
            data.municipalityName = this.municipalityCd.domNode.firstElementChild.innerText;
            this.showShelterDialog(facilityIds, data, isClose);

        },

        sendAndRegister: function(shelterInfo){
            var message = '登録・更新を行います。\nよろしいですか？';
            if(shelterInfo){
                message = '登録・更新及びLアラート等への配信を行います。\nよろしいですか？';
            }
            this.chain.confirm(message, lang.hitch(this, function(chain) {
                if(shelterInfo){
                    this._sendData.sendPostForm = shelterInfo.lalertForm;
                    this._sendData.prefMailFlg = shelterInfo.prefMailFlg;
                    this._sendData.twitterFlg = shelterInfo.twitterFlg;
                    this._sendData.twitterMunicFlg = shelterInfo.twitterMunicFlg;
                    this._sendData.yahooFlg = shelterInfo.yahooFlg;
                }

                // 外部連携ダイアログ表示のために用意したデータは不要なため削除
                delete this._sendData.shelterStartName;
                delete this._sendData.shelterEndName;
                delete this._sendData.municipalityName;

                var promise =  Requester.post('/api/shelters/operating', {
                    data: this._sendData
                }).then(lang.hitch(this, function(result) {

                    console.log(result);
                    var dialog = this.shelterRegisterDialog.getChildren()[0];
                    chain.info('登録・配信が完了しました。', '完了', lang.hitch(this, function(){
                        if(shelterInfo){
                            dialog.closeDialog();
                        }
                        Router.moveTo('shelter', {
                            municipalityCd: this._municipalityCd
                        });
                    }));

                }), function(error) {
                    // 失敗時
                    console.error(error);
                    chain.infoError(error);
                });
                Loader.wait(promise);
            }));
        },

        //キャンセルボタンを押下したら一覧画面に戻る
        cancelButtonClick: function() {
            Router.moveTo('shelter');
        },

        /**
         * 配信情報(開設避難所情報)ダイアログを表示する。
         */
        showShelterDialog: function(facilityIds, obj, isClose) {
            Requester.get('/api/facility/list/' + facilityIds, {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(lang.hitch(this,function(items) {

                var evaqueeData = [];

                var existPubShelter = false;

                array.forEach(obj.items, function(item){
                    var facilityInfo = array.filter(items, function(facility){
                        return facility.facilityId === item.facilityId;
                    })[0];

                    if(facilityInfo.publicFlg && facilityInfo.publicFlg === '1'){
                        existPubShelter = true;
                    }else{
                        // 公開不可の場合はLアラートに載せない
                        return false;
                    }

                    var evaqueeNum = isNaN(item.evaqueeNum) ? 0 : Number(item.evaqueeNum);
                    var evacHouseholdNum = isNaN(item.evacHouseholdNum) ? 0 : Number(item.evacHouseholdNum);

                    var sort = '開設';
                    var shelterStartOrEndTime = this.formatDateFromObj(item.shelterStartTime);
                    if (item.shelterEndTime) {
                        sort = '閉鎖';
                        shelterStartOrEndTime = this.formatDateFromObj(item.shelterEndTime);
                    }

                    var evacSuitableShelter = '指定なし';
                    if(facilityInfo.edesignatedEvacShFlg === '1' && facilityInfo.designatedEvacShFlg === '1' ){
                        evacSuitableShelter = '指定緊急避難場所兼避難所';
                    } else if(facilityInfo.edesignatedEvacShFlg === '1'){
                        evacSuitableShelter = '指定緊急避難場所';
                    } else if (facilityInfo.designatedEvacShFlg === '1' ){
                        evacSuitableShelter = '指定避難所';
                    } else {
                        evacSuitableShelter = '指定なし';
                    }

                    var evacShelterTypeOfDisaster = '';
                    var evacShelterTypeOfDisasterList = [];
                    if (facilityInfo.floodShFlg === '1') {
                        evacShelterTypeOfDisasterList.push('洪水');
                    }
                    if (facilityInfo.sedimentDisasterShFlg === '1') {
                        evacShelterTypeOfDisasterList.push('崖崩れ、土石流及び地滑り');
                    }
                    if (facilityInfo.stormSurgeShFlg === '1') {
                        evacShelterTypeOfDisasterList.push('高潮');
                    }
                    if (facilityInfo.earthquakeShFlg === '1') {
                        evacShelterTypeOfDisasterList.push('地震');
                    }
                    if (facilityInfo.tsunamiShFlg === '1') {
                        evacShelterTypeOfDisasterList.push('津波');
                    }
                    if (facilityInfo.fireShFlg === '1') {
                        evacShelterTypeOfDisasterList.push('大規模な火事');
                    }
                    if (facilityInfo.landsideWaterShFlg === '1') {
                        evacShelterTypeOfDisasterList.push('内水氾濫');
                    }
                    if (facilityInfo.volcaneShFlg === '1') {
                        evacShelterTypeOfDisasterList.push('火山現象');
                    }
                    if (evacShelterTypeOfDisasterList && evacShelterTypeOfDisasterList.length !== 0) {
                        evacShelterTypeOfDisaster = evacShelterTypeOfDisasterList.join(',');
                    }

                    var evaqueeDataSub = {
                        facilityId: facilityInfo.facilityId,
                        evacShelterName: facilityInfo.facilityName,
                        evacShelterNameKana: facilityInfo.facilityNameKana,
                        evacShelterType: facilityInfo.facilityTypeName,
                        evacShelterSort: item.shelterEndTime ? '閉鎖' : '開設',
                        evacShelterLatitude: facilityInfo.latitude,
                        evacShelterLongitude: facilityInfo.longitude,
                        address: facilityInfo.address,
                        capacity: facilityInfo.capacity,
                        evaqueeNum: evaqueeNum,
                        evaqueeActNum: 0,
                        evacHouseholdNum: evacHouseholdNum,
                        evacHouseholdActNum: 0,
                        evacShelterDatetime: shelterStartOrEndTime,
                        evacTopical: null,
                        evacSuitableShelter: evacSuitableShelter,
                        evacShelterTypeOfDisaster: evacShelterTypeOfDisaster,
                        crowdedStatus: item.crowdedStatus,
                        spaceStatus: CrowdedStatus.getLabel(item.crowdedStatus)
                    };
                    evaqueeData.push(evaqueeDataSub);
                }, this);

                var args = {};

                //公開可能な避難所の操作がない場合、Lアラート連携しない
                if(!existPubShelter){
                    return this.sendAndRegister(null);
                }

                if(isClose === '1'){ //一括閉鎖の場合
                    args.shelterArgs = {
                        municipalityCd: this._municipalityCd,
                        sendCategory : '02',
                        subject: ' ',
                        evacTimestamp: new Date(),
                        evaqueeData: evaqueeData,
                        // 更新種別 '01':新規, '02':更新・訂正, '03':取消
                        distributionType: '02',
                        isPrefMailDefault: false
                    };
                } else if(isClose === '0'){ //一括開設の場合
                    args.shelterArgs = {
                        municipalityCd: this._municipalityCd,
                        sendCategory : '02',
                        subject: ' ',
                        evacTimestamp: new Date(),
                        evaqueeData: evaqueeData,
                        // 更新種別 '01':新規, '02':更新・訂正, '03':取消
                        // 一旦新規を登録しておくが、あとでinitDialog時に既存のLアラートの報があれば
                        // 更新として02に書き換えられる。
                        distributionType: '01',
                        isPrefMailDefault: true
                    };
                } else if(isClose === '2'){ //全ての場合
                    var isPrefMailDefault = true;
                	// 新規開設避難所が無ければ配信対象外
                    if(obj.shelterStartName.length > 0) {
                        isPrefMailDefault = true;
                    } else {
                        isPrefMailDefault = false;
                    }
                    args.shelterArgs = {
                        municipalityCd: this._municipalityCd,
                        sendCategory : '02',
                        subject: ' ',
                        evacTimestamp: new Date(),
                        evaqueeData: evaqueeData,
                        // 更新種別 '01':新規, '02':更新・訂正, '03':取消
                        // 一旦新規を登録しておくが、あとでinitDialog時に既存のLアラートの報があれば
                        // 更新として02に書き換えられる。
                        distributionType: '01',
                        isPrefMailDefault: isPrefMailDefault
                    };
                }

                args.evacOrderArgs = null;

                args.facilityId = items[0].facilityId;
                args.newShelterNames = obj.shelterStartName;
                args.endShelterNames = obj.shelterEndName;
                args.municipalityName = obj.municipalityName;
                args.reportTime = this.shelterGrid.reportTimeMap[items[0].facilityId];
                // 一括操作の場合、報告者を入力する項目がないため空白。
                // (DB登録済みでも、登録済みのデータと報告者が一致するとは限らない)
                args.reporterName = '';

                // ダイアログの最初の子要素が登録画面
                var dialog = this.shelterRegisterDialog.getChildren()[0];

                // ダイアログの画面を初期化する。
                Loader.wait(dialog.initDialog(args, false, false, false))
                .then(lang.hitch(this, function(){
                    // ダイアログのsendイベントを受け取れるようにする
                    dialog.on('send', lang.hitch(this, function(evt) {
                        return this.sendAndRegister(evt.value);
                    }));
                    // ダイアログを破棄できないように設定する。
                    this.shelterRegisterDialog.set('closable', false);
                    // ダイアログを表示する。
                    this.shelterRegisterDialog.show();
                }));
            }));
        }
    });
});
