define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/aspect',
    'dojo/debounce',
    'dojo/dom-class',
    'dojo/dom-geometry',
    'dojo/dom-style',
    'dojo/text!./templates/MapPage.html',
    'dojo/on',
    'dojo/json',
    'dojo/query',
    'dojo/topic',
    'dojo/when',
    'idis/consts/STORAGE_KEY',
    'idis/consts/QUERY',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/map/IdisMap',
    'idis/util/storage/LocalStorage',
    'idis/view/dialog/ConfirmDialog',
    'idis/view/dialog/IdisDialog',
    'idis/view/draw/measure/MeasurePane',
    'idis/view/page/_PageBase',
    'idis/view/draw/_DrawUtil',
    'dojox/lang/functional/object',
    '../../config',
    'app/map/AutoUpdatePane',
    'app/map/LayerPane',
    'app/map/detail/DetailMap',
    'app/map/baselayer/BaseLayerPane',
    'app/map/legend/LegendPane',
    'app/map/notice/NoticePanel',
    'app/map/print/PrintDialog',
    'app/model/LayerStore',
    'app/draw/DrawPanel',
    'app/damage/integrate/DamageReportIntegrator',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/StackContainer',
    'app/map/AddressPanel',
    'app/map/LayerSelector',
    'idis/view/form/GeocodingForm'
], function(module, array, declare, lang,
    aspect, debounce, domClass, domGeometry, domStyle, template, on, json, query, topic, when,
    STORAGE_KEY, QUERY, Locator, Router, IdisMap, LocalStorage,
    ConfirmDialog, IdisDialog, MeasurePane, _PageBase, DrawUtil, funcObj,config,
    AutoUpdatePane, LayerPane, DetailMap, BaseLayerPane, LegendPane, NoticePanel, PrintDialog, LayerStore, DrawPanel,
    DamageReportIntegrator) {
    /**
     * 左側メニュー内の各画面のキー名とコンストラクター関数の対応
     * @type {Object<string,function>}
     */
    var stackClassMap = {
        layer: LayerPane
    };

    return declare(module.id.replace(/\//g, '.'), _PageBase, {
        // 基本クラス
        baseClass: 'idis-Page idis-Page--map',

        // テンプレート文字列
        templateString: template,

        /**
         * 作図ダイアログに対する参照
         * @type {module:app/draw/DrawPanel~DrawPanel}
         * @private
         */
        _drawPanel : null,

        /**
         * 距離計測ダイアログに対する参照
         * @type {module:idis/view/dialog/IdisDialog~IdisDialog}
         * @private
         */
        _measureDialog: null,

        /**
         * 背景地図ダイアログに対する参照
         * @type {module:idis/view/dialog/IdisDialog~IdisDialog}
         * @private
         */
        _baseLayerDialog: null,

        /**
         * popupイベントを保持する
         * @private
         */
        _downloadEvts: [],

        /**
         * 被害統合機能
         */
        _damageReportIntegrator: null,

        /**
         * 被害統合モードの状態を保持する
         */
        _isIntegrateMode: false,

        // 各種Dialogの表示をコントロールするためのPub/Sub
        // すべての作図モードをOffにして、Dialogを閉じる
        DISABLE_DRAW   : '/app/draw/DrawPanel::hideAndDisableDraw',
        DISABLE_MEASURE: '/app/view/page/MapPage::disableMeasure',
        DISABLE_PRINT  : '/app/view/page/MapPage::disablePrint',
        DISABLE_DAMAGE_INTEGRATE: '/app/damage/integrate/DamageReportIntegrator::disable',
        // forIE anchorにOnclickでPublishをして、msSaveへ情報を渡す。
        DOWNLOAD_4IE   : '/app/view/page/MapPage::download4IE',

        /**
         * 地図のベースになるレイヤー
         * @type {String}
         */
        MUNICIPALITY_LAYER_URL: '/data/master/base-municipality.geojson',

        buildRendering: function() {
            this.inherited(arguments);
            this._downloadEvts = [];
        },

        /**
         * コントロール領域再表示時に前回の幅を復元するための保持値。
         * @type {number}
         */
        _lastControlWidth: 250,


        /**
         * 左側メニュー内の各画面のキー名とインスタンスの対応
         * @type {Object<string,ContentPane>}
         */
        _stackMap: null,

        /**
         * URL更新前のクエリーの内容
         * @type {Object}
         */
        _lastQuery: null,

        /**
         * 自動更新clearTimeout用のID
         * @type {number}
         */
        _autoUpdateTimeoutId: null,

        constructor: function() {
            this._stackMap = {};
            this._lastQuery = {};
        },

        startup: function() {
            this.inherited(arguments);

            // 被害統合モードをオフに設定する
            this._isIntegrateMode = false;

            // 地図と隣り合う領域の境界(splitter）を非表示にしておく
            domStyle.set(this.controlPane._splitterWidget.domNode, 'display', 'none');
            domStyle.set(this.detailPane._splitterWidget.domNode, 'display', 'none');
            this.wrapper.layout();
            // マップの初期化
            this.map = new IdisMap(this.id + '-map', {
                config: config.map,
                keyboard: false, // コメント時に+/-が使用できないため
                touchExtend: false, // IE対応
                minZoom: 5,
                maxZoom: 18,
                drawControlTooltips: false
            });
            // mapのdestroyはremoveでDojoと互換なのでownで消せる
            this.own(this.map);
            // 最後のリサイズより10ミリ秒待ってからマップへ通知
            var notifier = debounce(lang.hitch(this, 'notifyResizeToMap'), 10);
            this.own(aspect.after(this.controlPane, 'resize', notifier));
            this.own(aspect.after(this.detailPane, 'resize', notifier));

            this.putFirstLayer();

            // テロップ設定の反映
            this.own(topic.subscribe(NoticePanel.TOPIC.CHANGE_SETTING, lang.hitch(this, function(payload) {
                domClass.toggle(this.noticeControlNode, 'is-bottom', payload.region === '1');
            })));
            // 詳細表示領域の状態をURLに従って更新
            this.updateDetailPane();
            // URLの変更を監視
            this.own(Locator.on('change', lang.hitch(this, this.onLocationChanged)));
            // 中心位置表示用ボタンの状態をトグル
            var centerMarkValue = !!LocalStorage.get(STORAGE_KEY.CENTER_MARK);
            domClass.toggle(this.centerMarkToggleButton, 'is-checked', centerMarkValue);
            // LocalStorageの変更を監視
            this.own(LocalStorage.watch(STORAGE_KEY.CENTER_MARK, function(value) {
                domClass.toggle(this.centerMarkToggleButton, 'is-checked', !!value);
            }, this));
            // 被害統合のonSubmitまたはdeactivateがpubされて、被害統合機能がオフになったときに、ボタンの色を変更する。
            topic.subscribe('app/damage/integrate/DamageReportIntegrator::deactivated',
                lang.hitch(this, function() {
                    this.deactivateIntegrateDamageButton();
                }
            ));
            // 検索ボタン
            this.own(topic.subscribe('idis/view/form/GeocodingForm::geocoded', lang.hitch(this, function(payload) {
                this._latlng = payload.latlng;
                this.map.setView(payload.latlng, 13);
            })));
            // 自動更新設定を反映
            if (LocalStorage.get(STORAGE_KEY.AUTO_UPDATE_INTERVAL)) {
                this.reserveAutoUpdate();
            }
            // Web Storageの内容が変わった場合は反映
            this.own(LocalStorage.watch(STORAGE_KEY.AUTO_UPDATE_INTERVAL, this.reserveAutoUpdate, this));

            // IE対応
            // イベントを管理する人は各Mapに必要。
            // TODO pub/subの方がよいか？
            if(DrawUtil._isIE()){DrawUtil._setPopupEvtForMap(this);}

        },

        // ウィジェットを破棄する
        destroy: function() {
            // 自動更新を解除
            clearTimeout(this._autoUpdateTimeoutId);
            this.inherited(arguments);
        },

        /**
         * マップ・オブジェクトにサイズ変更を通知する。
         */
        notifyResizeToMap: function() {
            // マップにサイズ変更を通知
            this.map.invalidateSize({pan: false});
        },

        /**
         * URLが変更された際に反応する。
         */
        onLocationChanged: function() {
            // 現在のクエリー情報を取得
            var query = Locator.getQuery();
            // 詳細ペインの状態が変更された場合
            if (query[QUERY.DETAIL_LAYER_ID] !== this._lastQuery[QUERY.DETAIL_LAYER_ID]) {
                this.updateDetailPane();
            }
            // 次の更新に備えてクエリー状態を保存
            this._lastQuery = query;
        },

        /**
         * URLの状態に従い詳細領域を更新する。
         */
        updateDetailPane: function() {
            // URLから詳細表示用レイヤーIDを取得
            var layerId = Locator.getQuery()[QUERY.DETAIL_LAYER_ID];
            when(layerId && LayerStore.get(layerId), lang.hitch(this, function(item) {
                // 古いウィジェットを破棄
                array.forEach(this.detailContent.getChildren(), function(child) {
                    child.destroyRecursive();
                });
                // 表示対象が指定されているなら設置
                if (item) {
                    // 情報カテゴリー・コードに応じたウィジェットを取得
                    var widgetClass = DetailMap[item.infoCategoryCd];
                    // 詳細表示領域へ設置
                    this.detailTitle.innerHTML = item.name;
                    var widget = new widgetClass({item: item});
                    this.detailContent.addChild(widget);
                }
                // 詳細表示領域の表示を調整
                this.detailPane.resize({h: (item ? 250 : 0)});
                domStyle.set(this.detailPane._splitterWidget.domNode, 'display', item ? '' : 'none');
                // 再レイアウト
                this.wrapper.layout();
            }));
        },

        /**
         * 詳細領域を隠す。
         */
        hideDetailPane: function() {
            // URLから詳細レイヤーIDを削除する
            Locator.replaceState(QUERY.DETAIL_LAYER_ID, '');
        },

        /**
         * コントロール領域を隠す。
         */
        hideControlPane: function() {
            // 非表示化直前の幅を記憶する
            this._lastControlWidth = domGeometry.getMarginBox(this.controlPane.domNode).w;
            // コントロール領域の幅を0にする
            this.controlPane.resize({w: 0});
            domStyle.set(this.controlPane._splitterWidget.domNode, 'display', 'none');
            // 再レイアウト
            this.wrapper.layout();
        },

        /**
         * 指定されたキーワードに対応するペインを左側に表示する。
         * @param {string} key 画面のキー名
         * @param {function} classDef 画面を初期化する場合に使用するクラス
         */
        showControlPane: function(key) {
            // キーに対応するインスタンスを確認
            var pane = this._stackMap[key];
            // 既に存在する場合は選択する
            if (pane) {
                this.stackPane.selectChild(pane);
            } else {
                // キーに対応するクラスを引数無しで生成
                pane = this._stackMap[key] = new stackClassMap[key]();
                // selecChildはaddChildの内部で呼ばれる
                this.stackPane.addChild(pane);
            }
            // 前回の表示幅を復元（記録値が狭すぎる場合は破棄）
            var width = Math.max(150, this._lastControlWidth);
            // コントロール領域幅を設定
            this.controlPane.resize({w: width});
            domStyle.set(this.controlPane._splitterWidget.domNode, 'display', '');
            // 再レイアウト
            this.wrapper.layout();
        },

        /**
         * 指定されたキーワードに対応するペインを左側で表示切替する。
         */
        toggleControlPane: function(key) {
            // コントロール領域幅が1以上であれば表示状態と見なす
            var controlWidth = domGeometry.getMarginBox(this.controlPane.domNode).w;
            if(controlWidth) {
                this.hideControlPane();
            } else {
                this.showControlPane(key);
            }
        },

        /**
         * 表示情報ボタンクリック時に呼ばれる。
         * 表示情報レイヤーの表示状態を切り替える。
         */
        toggleLayerPane: function() {
            this.toggleControlPane('layer');
        },

        /**
         * 作図ダイアログを表示する
         */
        showDrawPanelDialog: function(){

            // 「広域印刷」「距離計測」を無効化
            this.switchMainMapDialogs('draw');

            if(!this._drawPanel){
                this._drawPanel = new DrawPanel({
                    map      : this.map,
                    'class'  : 'drawPanel-NonModal',
                    dispType : 'main'
                });
                // 画面が破棄された際に連れて行く
                this.own(this._drawPanel);
            }
            this._drawPanel.show();
        },

        /**
         * 凡例ダイアログを表示する。
         */
        toggleLegendDialog: function() {
            if (!this._legendDialog) {
                // 初回呼び出し時にインスタンス生成
                this._legendDialog = new IdisDialog({
                    noUnderlay: true,
                    title: '凡例',
                    content: new LegendPane()
                });
                // 画面が破棄された際に連れて行く
                this.own(this._legendDialog);
            }
            if (this._legendDialog.open) {
                this._legendDialog.hide();
            } else {
                this._legendDialog.show();
            }
        },

        /**
         * 距離計測ダイアログを表示する。
         */
        showMeasureDialog: function() {

            // 「作図」「広域印刷」を無効化
            this.switchMainMapDialogs('measure');

            // 初回にウィジェットを生成
            if (!this._measureDialog) {
                this._measureDialog = new IdisDialog({
                    noUnderlay: true,
                    title: '距離計測',
                    content: new MeasurePane({map: this.map})
                });
            }
            // 画面が破棄された際に連れて行く
            this.own(this._measureDialog);
            this._measureDialog.show();
        },

        /**
         * 印刷ダイアログを表示する
         */
        showPrintDialog: function(){

            // 「作図」「距離計測」を無効化
            this.switchMainMapDialogs('print');

            // 初回にウィジェットを生成
            if (!this._printDialog) {
                this._printDialog = new PrintDialog({
                    noUnderlay: true,
                    map: this.map,
                    layerControl: this.map.layerControl
                });
            }
            // 画面が破棄された際に連れて行く
            this.own(this._printDialog);
            this._printDialog.show();
        },

        /**
         * 背景地図ダイアログを表示する。
         */
        showBaseLayerDialog: function() {
            if (!this._baseLayerDialog) {
                // 初回呼び出し時にインスタンス生成
                this._baseLayerDialog = new IdisDialog({
                    noUnderlay: true,
                    title: '背景地図',
                    content: new BaseLayerPane({map: this.map})
                });
                // 画面が破棄された際に連れて行く
                this.own(this._baseLayerDialog);
            }
            this._baseLayerDialog.show();
        },

        /**
         * 中心表示の状態を更新する。
         */
        toggleCenterMark: function() {
            // 設定値をトグル
            LocalStorage.set(STORAGE_KEY.CENTER_MARK, LocalStorage.get(STORAGE_KEY.CENTER_MARK) ? '' : '1');
        },

        /**
         * 経緯度グリッドの表示を切り替える。
         */
        toggleLatLngLayer: function() {
            var isActive = this.map.toggleLatLngLayer();
            domClass.toggle(this.latLngGridButton, 'is-checked', isActive);
        },

        /**
         * UTMグリッドの表示を切り替える。
         */
        toggleUtmLayers: function() {
            var isActive = this.map.toggleUtmLayers();
            domClass.toggle(this.utmGridButton, 'is-checked', isActive);
        },

        /**
         * 現在の設定に従って自動更新を予約する。
         */
        reserveAutoUpdate: function() {
            // 既存の更新予約を解除
            if (this._autoUpdateTimeoutId) {
                clearTimeout(this._autoUpdateTimeoutId);
            }
            // Web Storageの値を取得
            var interval = LocalStorage.get(STORAGE_KEY.AUTO_UPDATE_INTERVAL);
            // ボタンのチェック状態を更新
            domClass.toggle(this.autoUpdateButton, 'is-checked', !!interval);
            if (!interval) {
                // 更新間隔が設定されていない場合は終了
                return;
            }
            // 更新間隔が設定されている場合は予約
            console.debug(module.id + '#reserveAutoUpdate: auto update in ' + interval + ' minutes');
            this._autoUpdateTimeoutId = setTimeout(lang.hitch(this, function() {
                // 表示情報ツリーを最新化
                console.debug(module.id + '#reserveAutoUpdate: update start');
                LayerStore.refreshAll().always(lang.hitch(this, function() {
                    // 成否に関わらず次の更新処理を予約
                    console.debug(module.id + '#reserveAutoUpdate: update end');
                    this.reserveAutoUpdate();
                }));
            }), parseFloat(interval) * 60 * 1000);
        },

        /**
         * 表示情報の自動更新設定ダイアログを表示する。
         */
        onAutoUpdateButtonClick: function() {
            if (!this._autoUpdateDialog) {
                // 初回呼び出し時にインスタンス生成
                var autoUpdatePane = new AutoUpdatePane();
                var dialog = this._autoUpdateDialog = new ConfirmDialog({
                    title: '自動更新設定',
                    content: autoUpdatePane,
                    onOK: lang.hitch(this, function() {
                        // ダイアログのOKボタンをクリックした際の動作
                        if (autoUpdatePane.form.validate()) {
                            // Web Storageの値を更新
                            var interval = autoUpdatePane.form.get('value').interval;
                            LocalStorage.set(STORAGE_KEY.AUTO_UPDATE_INTERVAL, interval);
                            dialog.hide();
                        }
                    })
                });
                // 自動更新の値がWeb Storageに記録済みの場合はフォームへ反映
                if (LocalStorage.get(STORAGE_KEY.AUTO_UPDATE_INTERVAL)) {
                    autoUpdatePane.form.set('value', {interval: LocalStorage.get(STORAGE_KEY.AUTO_UPDATE_INTERVAL)});
                }
                // 画面が破棄された際に連れて行く
                this.own(this._autoUpdateDialog);
            }
            if (LocalStorage.get(STORAGE_KEY.AUTO_UPDATE_INTERVAL)) {
                // 設定済みの場合は自動更新を解除
                LocalStorage.remove(STORAGE_KEY.AUTO_UPDATE_INTERVAL);
            } else {
                // 自動更新未設定の場合はダイアログを開く
                this._autoUpdateDialog.show();
            }
        },

        /**
         * 「作図」「広域印刷」「距離計測」機能を制御する
         * 各ボタンクリック時に発行し、選択された機能以外はダイアログを閉じ、機能をオフにする
         * @param {String} 'draw'（作図）, 'measure'（距離計測）, 'print'（広域印刷）
         */
         switchMainMapDialogs: function(id){
             var dialogSet = {
                 //key     : [closeTargetDialog, PubUrl]
                 'draw'    : [this._drawPanel,     this.DISABLE_DRAW],
                 'measure' : [this._measureDialog, this.DISABLE_MEASURE],
                 'print'   : [this._printDialog,   this.DISABLE_PRINT],
                 'damage-integrate': [this._damageReportIntegrator, this.DISABLE_DAMAGE_INTEGRATE]
             };
             // インスタンスがあれば、すべて閉じる。
             Object.keys(dialogSet).forEach(function(key){
                 if (key === id) {return;}
                 if (!!dialogSet[key][0]) {
                     topic.publish(dialogSet[key][1]);
                     if (key === 'measure') {
                         dialogSet[key][0].hide();
                     }
                 }
             });
         },

         activateIntegrateDamageButton: function(){
            domClass.add(this.damageIntegrateButton, 'is-Activated');
            this._isIntegrateMode = true;
        },

        deactivateIntegrateDamageButton: function(){
            domClass.remove(this.damageIntegrateButton, 'is-Activated');
            this._isIntegrateMode = false;
        },

         /**
         * 被害統合機能をactiveにする
         */
        activateIntegrateDamage: function() {
            // 他のダイアログを消す
            this.switchMainMapDialogs('damage-integrate');

            if (!this._damageReportIntegrator) {
                this._damageReportIntegrator = new DamageReportIntegrator(this.map);
                this.own(this._damageReportIntegrator);
            }
            this._damageReportIntegrator.activate();

            // ボタンの色を変える
            this.activateIntegrateDamageButton();
        },

        /**
         * 被害統合機能のオン・オフを制御する
         */
        toggleIntegrateDamage: function(){
            if(this._isIntegrateMode){
                // 被害統合機能をdeactivateする
                topic.publish(this.DISABLE_DAMAGE_INTEGRATE);
                this._isIntegrateMode = false;
            } else {
                this.activateIntegrateDamage();
                this._isIntegrateMode = true;
            }
        },

         /**
          * 全画面表示を解除し、監視ページに遷移する
          */
         showMapforMonitor: function(){
             Router.moveTo('monitoring');
         },

         /**
          * 地図に表示されている作図データをすべてTanJSONとして保存する。
          */
         downloadAllFeatures: function(){
             var geojson = {'type':'FeatureCollection'};
             var features = [];
             this.map.eachLayer(function(featureGroup){
                 if (featureGroup._layers && featureGroup.getLayers().length){
                     featureGroup.toTanJSON().features.forEach(function(layer){
                         features.push(layer);
                     });
                 }
             });
             geojson.features = features;
             DrawUtil._doDownload(json.stringify(geojson));
         },

         showSubMenu: function() {
             domClass.add(this.container, 'is-subMenuShown');
         },

         hideSubMenu: function() {
             domClass.remove(this.container, 'is-subMenuShown');
         },

        /**
         * 地図レイヤーの上に行政界レイヤーを敷く
         * 県の境が見にくいため、自治体ごとのpolygonを置いている.
         * layeridの'999'は仮
         * TODO: 将来的にURLで制御させるか検討要
         */
         putFirstLayer: function() {
            return this.map.layerControl.addGeneralLayer({
                'id':999,
                'name':'行政界',
                'parentId':0,
                'disasterId':21,
                'dispSeqNo':2,
                'infoCategoryCd':'D108',
                'pubStatus':'4',
                'deptCd':'D01003',
                'sectCd':'S01001',
                'minZoom':8,
                'maxZoom':18,
                'tileFlg':false,
                'jsonType':'1',
                'listType':'0',
                'layerUrl': this.MUNICIPALITY_LAYER_URL,
                'hideFlg':false,
                'userId':'U01001'
            },1);
        }
    });
});
