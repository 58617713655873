/**
 * 日付文字列やエポックをvalue値としてセットできるようにする。
 * @module idis/view/form/_DateTimeInputMixin
 */
define([
    'module',
    'dojo/_base/declare',
    '../../util/TypeUtils'
], function(module, declare, TypeUtils) {
    /**
     * 日付の文字列表現やエポックをvalue値としてセットできるようにする。
     * @class _DateTimeInputMixin
     */
    return declare(module.id.replace(/\//g, '.'), null,
        /** @lends module:idis/view/form/_DateTimeInputMixin~_DateTimeInputMixin# */ {
        // value値を設定する
        _setValueAttr: function(value) {
            // Date型以外を受け取った場合は引数としてDate型を生成する。
            // ただしエポックで初期化されるのを防ぐため、0以外の偽値はそのまま通す。
            if (value === 0 || value && !TypeUtils.isDate(value)) {
                value = new Date(value);
            }
            // 本来のメソッドを呼び出す
            this.inherited(arguments, [value]);
        }
    });
});

