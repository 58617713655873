define([
    'dojo/_base/lang',
    'idis/view/Loader',
    'idis/service/Requester',
    'idis/view/dialog/InfoDialog'
], function(lang, Loader, Requester) {
    return {
        execute: function(urlList, file, callback){
            var base = urlList.baseUrl;
            var form = new FormData();
            form.append('file', file);
            form.append('f', 'pjson');

            var promise = Requester.post(base+urlList.upload, {
                headers: {'Content-Type': false},
                data: form,
                preventCache: false
            }).then(function(res) {
                var submitPromise = Requester.post(base+urlList.execute, {
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    data: {
                        'In_ItemID'    : res.item.itemID,
                        'In_FileName'  : res.item.itemName,
                        'f'            : 'pjson',
                        'defaultValue' : {
                            'spatialReference' : {
                                'wkid' : 4326
                            }
                        }                        
                    },
                    preventCache: false
                }).then(function(data) {
                    if (data.error) {
                        return data.error;
                    } else if (callback) {
                        callback(data.results[0].value);
                    } else {
                        return data.results[0].value;
                    }
                }, function(error){
                    console.log(error);
                });
                return submitPromise;
            }, function(error) {
                console.log(error);
            });
            Loader.wait(promise);
            return promise;
        }
    };
});
