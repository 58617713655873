/**
 * <避難所情報概況画面>
 *
 * @module app/evacrecommend/EvacRecommendGrid
 */
define(['module', // モジュールのパスを返す
    'dojo',
    'dojo/_base/array',
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/date/locale',
    'idis/util/DateUtils',
    'dojo/dom',
    'dojo/dom-class',
    'dojox/lang/functional/object',
    'idis/view/grid/IdisGrid',
    'dstore/Memory',
    'dijit/form/CheckBox',
    'dgrid/Selection',
    'dgrid/Selector',
    'dgrid/Keyboard',
    'idis/view/grid/helper',
    'app/evacorder/Reason',
    './consts/EVAC_RECOMMEND_STATUS',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer'
], function (module, dojo, array, declare, lang, locale, DateUtils, dom, domClass, df,
    IdisGrid, Memory, CheckBox, Selection, Selector, Keyboard, helper, Reason, STATUS) {
    /**
        * <クラスの説明>
        *
        * @class <ウィジェット名>
        * @extends module:idis/view/page/_PageBase~_PageBase
        */
    return declare(module.id.replace(/\//g, '.'), [IdisGrid, Selection, Selector, Keyboard],
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */
        {

            selectionMode: 'multiple',
            allowSelectAll: false,
            allowTextSelection: false,
            allIdList: [],
            cellNavigation: true,   // 'dgrid-cellfocusin'の引数evtで、true=cell、false=row 情報が渡される

            columns: [
                // チェックボックス
                {
                    field: 'checkbox', label: '',
                    selector: 'checkbox'
                },
                { field: 'municipalityName', label: '市町', sortable: false },
                {
                    field: 'updTimestamp', label: '日時', sortable: false,
                    formatter: lang.hitch(this, function (item) {
                        if (item) {
                            return DateUtils.format(item);
                        }
                        return '';
                    })
                },
                {
                    field: 'issueReasonType', label: '発令理由', sortable: false,
                    formatter: lang.hitch(this, function (item) {
                        return Reason.get(item).name;
                    })
                },
                helper.column('evacOrderType', '発令内容', {
                    classMap: { '11': 'is-low', '13': 'is-high', '14': 'is-veryHigh' },
                    formatMap: { '11': '高齢者等避難', '13': '避難指示', '14': '緊急安全確保' },
                    sortable: false
                }),
                {
                    field: 'recommendReason', label: '基準超過情報', sortable: false,
                    renderCell: function (item) {
                        // 基準超過情報
                        var ul = dojo.create('ul', {
                            style: {
                                'padding-left': '20px',
                                'text-align': 'left'
                            }
                        });
                        array.forEach(item.recommendReason, function (reason) {
                            ul.appendChild(dojo.create('li', {
                                innerHTML: reason.message,
                                // 基準超過は赤字
                                style: { color: (reason.recommendFlg === '1') ? 'red' : 'black'}
                            }));
                        });
                        // HTMLとしてウィジェットに紐付くDOMノードを返す
                        return ul;
                    }
                },
                {
                    field: 'unnecessaryReason', label: '発令不要登録', sortable: false,
                    renderCell: function (item) {
                        switch (item.status) {
                            case STATUS.ACTIVE:
                                // 有効情報の場合、発令不要登録リンク作成
                                // このリンクはダミー。実際は、'dgrid-cellfocusin'イベントで処理
                                // （行未選択状態だと'dgrid-select'イベントが優先されてonClieckが発火しないため）
                                return dojo.create('a', { href: '#',
                                    // 不要理由を表示しない場合は列幅が狭いので、改行する
                                    innerHTML: this.grid.shownUnnecessaryReason ? '発令不要化' : '発令<br>不要化'
                                });
                            case STATUS.UNNECESSARY:
                                // 発令不要理由の場合、理由を表示
                                var div = dojo.create('div');
                                div.appendChild(dojo.create('span', {
                                    style: {
                                        'background-color': 'yellow'
                                    },
                                    innerHTML: DateUtils.format(item.unnecessaryUptTimestamp, { date: 'M/d', time: 'HH:mm' }) + '&emsp;発令不要登録'
                                }));
                                div.appendChild(dojo.create('br'));
                                div.appendChild(dojo.create('span', {
                                    innerHTML: '発令不要理由：' + (item.unnecessaryReason && item.unnecessaryReason.length > 0 ?
                                        item.unnecessaryReason : '---')
                                }));
                                // HTMLとしてウィジェットに紐付くDOMノードを返す
                                return div;
                            default:
                                return dojo.create('span', { innerHTML: '---' });
                        }
                    }
                },
                helper.column('status', '現況', {
                    formatMap: {
                        '': '',
                        '0': '有効',
                        '1': '発令済',
                        '2': '撤回',
                        '3': '発令不要',
                        '9': '判定対象外'
                    },
                    sortable: false
                })
            ],

            renderRow: function (item) {
                var div = this.inherited(arguments);
                // 非活性(発令済・撤回・マスタ)のデータは、列を選択不能にし、「選択不能」クラスを付与
                if ([STATUS.ISSUED, STATUS.SETTLED, STATUS.MANUAL].indexOf(item.status) > -1) {
                    domClass.add(div, 'row-inactive');
                    var inputtags = div.getElementsByTagName('input');
                    for(var j=0;j<inputtags.length;j++){
                        inputtags[j].disabled = true;
                    }
                }

                // アクティブなデータに関しては、避難区分に応じたクラスを付与
                if (item.evacOrderType === '14') {
                    domClass.add(div, 'row-active-very-high');
                } else if (item.evacOrderType === '13') {
                    domClass.add(div, 'row-active-high');
                } else if (item.evacOrderType === '11') {
                    domClass.add(div, 'row-active-low');
                }

                // 各列のオブジェクトに、避難種別・発令理由を追加
                div.evacRecommendType = {
                    status: item.status,
                    evacOrderType: item.evacOrderType,
                    issueReasonType: item.issueReasonType,
                    municipalityCd: item.municipalityCd,
                    districtCdList: item.districtList
                };
                this.allIdList.push(item.evacRecommendId);

                return div;
            },

            // SelectorのallowSelectAllオプションがエラーとなるので、
            // 仮の値としてメモリーストアをおく
            constructor: function () {
                this.collection = new Memory();
            },

            /**
             * 選択した避難レコメンドのID一覧を返す。
             * @returns {string[]} 選択した避難レコメンドのID一覧
             */
            getSelectedIdList: function () {
                // 値がtrueのものに絞り込む（一度選択したものはfalseが入る）
                var selected = df.filterIn(this.selection, function (v) { return v; });
                // 残ったキー一覧を返す
                return df.keys(selected);
            }
        });
});
