/**
 * 本部設置状況
 * @module app/disasterprevention/view/model/DisasterType
 */
define({
    WIND_WARER_DISASTER : '01',
    EARTHQUAKE_DISASTER : '02',
    TSUNAMI_DISASTER : '03',
    NATIONAL_PROTECTION : '04',
    OTHER_DISASTER : '99'
});