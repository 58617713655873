/**
 * フォルダ画面用モジュール。
 * @module app/folder/view/FolderPage
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/aspect',
    'dojo/dom-geometry',
    'dojo/text!./templates/FolderPage.html',
    'dijit/Viewport',
    'idis/store/BridgeModel',
    'idis/view/dialog/DialogChain',
    'idis/view/tree/MutableTree',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'app/model/FullLayer',
    './_CommitMixin',
    './_DeleteMixin',
    './_MoveRightMixin',
    './_NewFolderMixin',
    './_RefreshMixin',
    './_RenameMixin',
    './_UpDownMixin',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/ValidationTextBox',
    'dijit/layout/BorderContainer',
    'idis/view/dialog/ConfirmDialog',
    'idis/view/form/AclButton',
    'idis/view/form/RadioGroup'
], function(module, array, declare, lang, aspect, domGeom, template, Viewport,
    BridgeModel, DialogChain, MutableTree, _PageBase, Loader, FullLayer,
    _CommitMixin, _DeleteMixin, _MoveRightMixin, _NewFolderMixin, _RefreshMixin, _RenameMixin, _UpDownMixin) {
    /**
     * フォルダ画面用ウィジェット。
     * @class FolderPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), [
        _PageBase, _CommitMixin, _DeleteMixin, _MoveRightMixin,
        _NewFolderMixin, _RefreshMixin, _RenameMixin, _UpDownMixin
    ], /** @lends module:app/view/folder/view/FolderPage~FolderPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--folder',

        /**
         * ツリー・ウィジェットへの参照
         * @param {Tree}
         */
        tree: null,

        constructor: function() {
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },

        // DOMノードを構築する
        buildRendering: function() {
            this.inherited(arguments);
            this.resetTree();
        },

        // ウィジェットがドキュメントに設置されてから呼ばれる
        startup: function() {
            this.inherited(arguments);
            this.layoutInHalves();
            this.own(Viewport.on('resize', lang.hitch(this, 'layoutInHalves')));
        },

        /**
         * 左右のツリー領域を等分するようにレイアウトする。
         */
        layoutInHalves: function() {
            // 画面幅からsplitterとgutterの余白を除いたものを二等分
            var halfWidth = Math.floor((domGeom.position(this.domNode).w - 15) / 2);
            // splitter操作時の処理を利用
            this.borderContainer._layoutChildren(this.rightPane.id, halfWidth);
        },

        /**
         * ツリーを初期化する。
         */
        resetTree: function() {
            // 生成済みのツリーを破棄
            if (this.leftTree) {
                this.leftTree.destroyRecursive();
                this.rightTree.destroyRecursive();
            }
            // ウィジェットを生成・設置
            this.model = new BridgeModel({model: FullLayer});
            // 左側ツリー
            this.leftTree = new MutableTree({model: this.model});
            this.leftTree.placeAt(this.leftTreeNode, 'only');
            // 右側ツリー
            this.rightTree = new MutableTree({model: this.model});
            this.rightTree.placeAt(this.rightTreeNode, 'only');
            // 変更履歴の変更に反応する
            this.own(aspect.after(this.model, 'pushCommand', lang.hitch(this, 'onTreeChange')));
            // ツリー要素の選択に反応する
            this.onLeftTreeFocus(this.leftTree.get('lastFocusedItem'));
            this.onRightTreeFocus(this.rightTree.get('lastFocusedItem'));
            this.own(this.leftTree.watch('lastFocusedItem', lang.hitch(this, function(name, oldItem, item) {
                this.onLeftTreeFocus(item);
            })));
            this.own(this.rightTree.watch('lastFocusedItem', lang.hitch(this, function(name, oldItem, item) {
                this.onRightTreeFocus(item);
            })));
            this.onResetTree();
        },

        /**
         * 左側ツリーの選択要素が変わったときに呼ばれる。
         */
        onLeftTreeFocus: function(item) {
            this.inherited(arguments);
            console.debug(item);
        },

        /**
         * 右側ツリーの選択要素が変わったときに呼ばれる。
         */
        onRightTreeFocus: function(item) {
            this.inherited(arguments);
            console.debug(item);
        },

        // ツリーが再描画された際に呼ばれる
        onResetTree: function() {
            this.inherited(arguments);
            this.onTreeChange();
        },

        // ツリーの状態が変わった際に呼ばれる
        onTreeChange: function() {
            this.inherited(arguments);
            // 「変更を破棄」ボタンは変更履歴があるときだけ有効
            this.resetButton.set('disabled', !this.leftTree.model.isChanged());
        },

        onResetButtonClick: function() {
            this.chain.confirm('変更内容を破棄してもよろしいですか？', function(chain) {
                this.resetTree();
                chain.infoComplete();
            });
        }
    });
});

