/**
 * レイヤー選択用モジュール。
 * @module idis/map/LayerSelector
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/dom-attr',
    'dojo/promise/all',
    'dijit/_WidgetBase',
    'dojox/lang/functional/object',
    'idis/consts/QUERY',
    'idis/control/Locator',
    'idis/service/Requester',
    // 以下、変数で受けないモジュール
    'dojo/query'
], function(module, array, declare, lang, Deferred, domAttr, all, _WidgetBase, df, QUERY, Locator, Requester) {

    /**
     * レイヤー識別用に用いるHTML属性名
     * @type {string}
     * @private
     */
    var _LAYER_ID_ATTR = 'data-idis-layer-id';

    /**
     * レイヤー選択用ウィジェット
     * @class LayerSelector
     */
    return declare(module.id.replace(/\//g, '.'), _WidgetBase, /** @lends idis/map/LayerSelector~LayerSelector# */ {
        // ルート要素のCSSクラス
        baseClass: 'map-LayerSelector',

        /**
         * 初期レイヤー一覧
         * @param {number[]}
         */
        initialLayers: null,

        /**
         * 表示を許可されたレイヤー一覧
         * @param {number[]}
         */
        allowedLayers: null,

        /**
         * レイヤー定義一覧
         * @param {Object[]}
         */
        layers: null,

        /**
         * 識別子とレイヤー情報の対応付け
         * @type {Object<number, Object>}
         */
        _layerMap: null,
        
        _lastLayerParam: null,

        /**
         * 表示対象レイヤーIDと実レイヤーIDリストの対応付け(フォルダのID指定対応)
         * @type {allowedLayerId, [layerId]}
         */
        _allowedLayerMap: {},

        constructor: function() {
            this._layerMap = {};
            // レイヤー情報の対応付けを保持
            array.forEach(this.layers, function(layer) {
                this._layerMap[layer.id] = layer;
            }, this);
        },

        // DOM要素を構築する
        buildRendering: function() {
            // ルート要素はULタグにする
            this.domNode = this.srcNodeRef || this.ownerDocument.createElement('ul');
            this.inherited(arguments);
        },

        // DOM要素構築後に呼ばれる
        startup: function() {
            this.inherited(arguments);
            // 現在のレイヤー一覧を取得
            this._lastLayerParam = Locator.getQuery()[QUERY.LAYER_LIST];
            // 初期レイヤー一覧をURLに反映
            // postCreate時点だと親画面設置前のため
            // URL書き換えに対しRouterが誤動作するので、このタイミングで実施する。
            if (this._lastLayerParam) {
                // 許可されないレイヤーが出ていた場合は隠す
                df.forIn(Locator.getLayerQuery(), function(transparency, layerId) {
                    if (array.indexOf(this.allowedLayers, layerId) === -1) {
                        this.hideLayer(layerId);
                    }
                }, this);
            }
            if (!this._lastLayerParam) {
                // レイヤーが何も出ていない場合は初期用を表示
                array.forEach(this.initialLayers, this.showLayer, this);
                this._lastLayerParam = Locator.getQuery()[QUERY.LAYER_LIST];
            }
            // フォルダのID指定を基に、項目一覧を更新する
            this.updateLayerSelectorList();
            // URLの変更を監視
            this.own(Locator.on('change', lang.hitch(this, this.onLocationChanged)));
            // ウィジェット内の要素がクリックされたらレイヤーの表示を切り替える
            this.on('li:click', lang.hitch(this, function(evt) {
                var layerId = domAttr.get(evt.target, _LAYER_ID_ATTR) ||
                                domAttr.get(evt.target.parentNode, _LAYER_ID_ATTR);
                this.toggleLayer(layerId);
            }));
        },

        /**
         * ロケーションが変化した場合に呼ばれる。
         */
        onLocationChanged: function() {
            var layerParam = Locator.getQuery()[QUERY.LAYER_LIST];
            // レイヤー一覧が変わった場合に限りDOM要素を更新する
            if (layerParam !== this._lastLayerParam) {
                this._lastLayerParam = layerParam;
                this.updateView();
            }
        },

        /**
         * 指定されたレイヤーを表示する。
         * @param {number} id レイヤーID
         */
        showLayer: function(id) {
            var layerData = this._layerMap[id];
            // 背反なレイヤーが指定されている場合は非表示化する
            if (layerData.anti) {
                array.forEach(layerData.anti, this.hideLayer, this);
            }
            // 現状の選択レイヤー一覧を取得
            var layerQuery = Locator.getLayerQuery();
            // レイヤーIDをクエリーに追加
            layerQuery[id] = layerQuery[id] || '0';
            // URLに反映
            Locator.replaceState(QUERY.LAYER_LIST, Locator.toLayerQuery(layerQuery));
        },

        /**
         * 指定されたレイヤーを非表示化する。
         * @param {number} id レイヤーID
         */
        hideLayer: function(id) {
            // 現状の選択レイヤー一覧を取得
            var layerQuery = Locator.getLayerQuery();
            // レイヤーIDをクエリーから削除
            if (layerQuery[id]) {
                delete layerQuery[id];
            }
            // URLに反映
            Locator.replaceState(QUERY.LAYER_LIST, Locator.toLayerQuery(layerQuery));
        },

        /**
         * 指定されたレイヤー表示を切り替える。
         * @param {number} id レイヤーID
         */
        toggleLayer: function(id) {
            var layerData = this._layerMap[id];
            // 背反なレイヤーが指定されている場合は非表示化する
            if (layerData.anti) {
                array.forEach(layerData.anti, this.hideLayer, this);
            }
            // 現状の選択レイヤー一覧を取得
            var layerQuery = Locator.getLayerQuery();
            array.forEach(this._allowedLayerMap[id], function(layerId) {
                // クエリーにIDがあったら消す、なかったら追加する
                if (layerQuery[layerId]) {
                    delete layerQuery[layerId];
                } else {
                    layerQuery[layerId] = '0';
                }
            });
            // URLに反映
            Locator.replaceState(QUERY.LAYER_LIST, Locator.toLayerQuery(layerQuery));
        },

        /**
         * 項目一覧を更新する。
         */
        updateView: function() {
            var layerQuery = Locator.getLayerQuery();
            var html = [];
            array.forEach(this.layers, function(layer) {
                // 許可されたレイヤーだけ候補に掲載
                if (array.indexOf(this.allowedLayers, layer.id) === -1) {
                    return;
                }
                html.push('<li ');
                if (array.some(this._allowedLayerMap[layer.id], function(id) {return id in layerQuery;})) {
                    html.push('class="is-shown" ');
                }
                html.push(_LAYER_ID_ATTR);
                html.push('="');
                html.push(layer.id);
                html.push('"><div>');
                html.push(layer.name);
                html.push('</div></li>');
            }, this);
            this.domNode.innerHTML = html.join('');
        },

        /**
         * フォルダのID指定を基に、項目一覧を更新する
         */
        updateLayerSelectorList: function() {
            // フォルダのID指定対応
            this._generateAllowedLayerMap()
                // 項目一覧を更新する
                .then(lang.hitch(this, this.updateView));
        },

        /**
         * 指定のlayerId（フォルダのIDも可）と実レイヤーIDのマッピング
         */
        _generateAllowedLayerMap: function() {
            var deferred = new Deferred();
            all(array.map(this.allowedLayers, function(layerId) {
                return Requester.get('/data/layer/tree/' + layerId + '.json');
            })).then(lang.hitch(this, function(layerInfos) {
                var deferred2 = new Deferred();
                var promises = [];
                array.forEach(layerInfos, lang.hitch(this, function(layerInfo) {
                    // フォルダ以外ならそのままセット
                    if (['T001', 'T002'].indexOf(layerInfo.infoCategoryCd) < 0) {
                        this._allowedLayerMap[layerInfo.id] =  [layerInfo.id];
                        return;
                    }
                    // フォルダなら配下の実レイヤーIDをセット
                    this._allowedLayerMap[layerInfo.id] = [];
                    promises.push(this._setChildren(layerInfo.id, layerInfo.children));
                }));
                all(promises).then(deferred2.resolve);
                return deferred2;
            })).then(deferred.resolve);
            return deferred.promise;
        },
        _setChildren: function(parentId, childrenIdList) {
            var deferred = new Deferred();
            all(array.map(childrenIdList, function(layerId) {
                return Requester.get('/data/layer/tree/' + layerId + '.json');
            })).then(lang.hitch(this, function(layerInfos) {
                var deferred2 = new Deferred();
                var promises = [];
                array.forEach(layerInfos, lang.hitch(this, function(layerInfo) {
                    // フォルダ以外ならそのまま追加
                    if (['T001', 'T002'].indexOf(layerInfo.infoCategoryCd) < 0) {
                        this._allowedLayerMap[parentId].push(layerInfo.id);
                        return;
                    }
                    // フォルダなら配下の子供を再起的に実行する
                    promises.push(lang.hitch(this, this._setChildren(parentId, layerInfo.children)));
                }));
                all(promises).then(deferred2.resolve);
                return deferred2;
            })).then(deferred.resolve);
            return deferred.promise;
        }
    });
});

