/**
 * 観測局種別
 * @module app/observationstation/model/DataKind
 */
define({
    // 全種別
    ALL:'0',
    // 雨量
    RAINFALL:'1',
    // 水位
    RIVER_LEVEL:'2',
    // 危機管理型水位計
    CRISIS_MANAGEMENT_RIVER_LEVEL:'3',
    // 流域
    RIVER_BASIN:'4',
    // 潮位・風向風速
    TIDE_LEVEL:'5',
    // ダム
    DAM:'6',
    // 市内観測地点
    LOCAL:'7'
});
