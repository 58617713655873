/**
 * レイヤーの情報種別コードとウィジェットの対応。
 */
define([
    './DumpDetail',
    './DrawDetail',
    './DamageReportDetail',
    './LayerPropDetail',
    './PhotoUploadDetail',
    './TrafficRegulationDetail',
    './DefinedByJson'
], function(DumpDetail, DrawDetail, DamageReportDetail, LayerPropDetail,
     PhotoUploadDetail, TrafficRegulationDetail, DefinedByJson) {
    return {
        D108: DrawDetail,
        D100: DamageReportDetail,
        D101: TrafficRegulationDetail,
        T004: PhotoUploadDetail,
        T005: DefinedByJson,
        M050: LayerPropDetail
    };
});
