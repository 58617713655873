/**
 * <水位実況情報情報一覧画面>
 *
 * @module app/rivercamera/grid/RiverCameraObservationPageGrid
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'idis/util/DateUtils'
    // 以下、変数で受けないモジュール
], function (module, declare, IdisGrid, helper, DateUtils) {
    /**
     * 水位実況情報情報一覧画面用Grid
     *
     * @class RiverCameraObservationPageGrid
     * @extends module:idis/view/grid/IdisGrid~IdisGrid
     */
    return declare(module.id.replace(/\//g, '.'), IdisGrid,
        /** @lends module:app/river/RiverCameraObservationPageGrid~RiverCameraObservationPageGrid# */ {
            columns: [
                helper.buttonColumn('detail', '詳細'),
                helper.column('path', '画像', {
                    className: function (item) {
                        return item ? '' : '';
                    },
                    formatter: function (value, item) {
                        var html;
                        var date = new Date();
                        date.setMinutes(Math.floor(date.getMinutes() / 10) * 10);
                        var target = String(date.getFullYear()) + '-' + String(date.getMonth() + 1) + '-' +
                            String(date.getDate()) + '-' + String(date.getHours()) + '-' +
                            String(date.getMinutes());
                        var imagePath = DateUtils.getArroundDate(target, true, 1000, 0, target)[0].pathFormatDate;
                        if (item.linkUrl && !item.path) {
                            html = '<p style="margin:0;">画像なし</p><a href="' + item.linkUrl +
                                '" target="_brank" style="cursor:pointer;">＞詳細を閲覧する</a>';
                        } else if (item.path) {
                            html = '<img onerror="this.src=\'/images/observation/riverCamera/error.jpg\'" src="' +
                                'data/camera-10min/' + item.id + '/' + imagePath + '.jpg' +
                                '" width=100px height=80px>';
                        } else {
                            html = '<p>表示エラー</p>';
                        }
                        // 公開手続中のものであれば、その文言を出す
                        if (item.path === '公開手続中') {
                            html = '<p>' + item.path + '</p>';
                        }
                        return html;
                    }
                }),
                helper.column('rivername', '河川名'),
                helper.column('name', '観測地点'),
                helper.column('address', '住所'),
                helper.column('manager', 'カメラ管理')
            ],
            sort: [{ property: 'orderNum' }]
        });
});

