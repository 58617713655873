/**
 * 県/特別市/市町村を扱うモデル。
 * @module app/model/RegionMunicipality
 */
 define([
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest'
], function(CacheStoreModel, CacheTreeRest) {
    /**
     * シングルトンを返す。
     */
    return new CacheStoreModel({
        store: new CacheTreeRest({
            target: '/api/organizations/chronologyShareOrgs4Tree'
        })
    });
});
