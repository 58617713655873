define([
    'module',
    'dojo/_base/declare',
    'dojo/date/locale',
    'dojo/topic',
    'dojo/_base/lang',
    'idis/map/IdisMap',
    'idis/view/grid/IdisGrid',
    'dgrid/extensions/ColumnResizer',
    'dgrid/extensions/ColumnHider'
], function(module, declare, locale, topic, lang,
    IdisMap, IdisGrid, ColumnResizer, ColumnHider) {
    return declare(module.id.replace(/\//g, '.'), [IdisGrid, ColumnResizer, ColumnHider], {

        /**
         * 各列の定義
         * @type {Object[]}
         */
        // columndefs: [],

        // グリッド行がクリックされた場合に呼ばれる
        onRowClick: function(item) {
            var point = {};
            
            // 緯度も経度もある場合は地図を要素の指定する座標へ移動
            if (item.lat && item.lon) {
                point.lat = item.lat;
                point.lng = item.lon;
            } else {
                // 緯度軽度一覧が入ったモジュールから緯度軽度を得る
                // 仮
                point.lat = '40.819266';
                point.lon = '140.729713';
            }
            
            topic.publish(IdisMap.TOPIC.MOVE, {
                lat: item.lat,
                lng: item.lon
            });
        }
    });
});
