/**
 * 観測情報画面の共通凡例UIウィジェット
 * @module app/view/ObservatoryLegend
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/ObservatoryLegend.html',
    'idis/view/page/_PageBase'
    // 以下、変数から参照されないモジュール
], function(module, declare, template, _PageBase) {
    /**
     * 観測情報画面の凡例UIウィジェット。
     * @class ObservatoryLegend
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/observation/rain/ObservatoryLegend~ObservatoryLegend# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--observatoryLegend'
    });
});