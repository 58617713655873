define({
    '01': {'reportType':'市町', 'deptName':'', 'divId':'muniDamage'},
    '02': {'reportType':'健康福祉', 'deptName':'健康福祉部', 'divId':'healthDamage'},
    '03': {'reportType':'環境生活', 'deptName':'環境生活部', 'divId':'envDamage'},
    '04': {'reportType':'商工観光労働', 'deptName':'商工観光労働部', 'divId':'bizDamage'},
    '05': {'reportType':'農林水産', 'deptName':'農林水産部', 'divId':'agriDamage'},
    '06': {'reportType':'土木', 'deptName':'土木部', 'divId':'civilDamage'},
    '07': {'reportType':'企業', 'deptName':'企業局', 'divId':'compDamage'},
    '08': {'reportType':'教育', 'deptName':'教育庁', 'divId':'educDamage'},
    '09': {'reportType':'その他', 'deptName':'その他', 'divId':'otherDamage'}
});
