/**
* @module app/rescue/
* 部隊活動情報をマップに載せる。
*/
define([
    'dojo/_base/array',
    'dojo/_base/lang'
], function (array, lang) {

    function _alignByArea(byPlan) {
        var byArea = {};

        array.forEach(byPlan, function (plan) {
            array.forEach(plan.areaList, function (hist) {
                if (!byArea[hist.areaCode]) {
                    // これが作りたいデータ構造
                    byArea[hist.areaCode] = {
                        areaCode: hist.areaCode,
                        areaName: hist.areaName,
                        status: hist.status,
                        plans: []
                    };
                }

                // planの情報を積む
                byArea[hist.areaCode].plans.push({
                    reportNum: plan.reportNum,
                    assessTeam: plan.assessTeam,
                    scheduledDate: plan.scheduledDate
                });
            });
        });

        return (byArea);
    }

    function _historyListToHash(historyList) {
        var hash = {};
        array.forEach(historyList, function(history) {
            var copy = lang.mixin({}, history);

            copy.districtCd = copy.researchAreaCode;
            delete copy.researchAreaCode;
            hash[copy.districtCd] = copy;
        });

        return hash;
    }

    return {
        alignByArea: _alignByArea,
        historyListToHash: _historyListToHash
    };
});
