/**
 * 防災情報リンク集画面用のモジュール
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/text!./templates/DpLinkCollectionPage.html',
    'idis/view/page/_PageBase',
    'idis/model/UserInfo'
], function(module, array, declare, template, _PageBase, UserInfo) {

    return declare(module.id.replace(/\//g, '.'), _PageBase, {

        // テンプレート文字列
        templateString: template,

        // 基本クラス
        baseClass: 'idis-Page idis-Page--link',
        /**
         * 表示対象のリンク一覧
         */
        linkList: [{
            name: '山口県',
            children: [
                {name: '下関地方気象台', href: 'https://www.jma-net.go.jp/shimonoseki/'}
            ]
        }, {
            name: '関連リンク',
            children: [
                {name: '内閣府物資調達支援システム', href: 'https://busshi.bousai-system.go.jp/'},
                {name: '避難所等データ整備ウェブシステム', href: ''}
            ]
        }],

        // DOM要素を構築する
        buildRendering: function() {
            this.inherited(arguments);
            var html = [];
            array.forEach(this.linkList, function(linkGroup) {
                // 各リンクのグループ
                html.push('<section>');
                html.push('<h2>');
                html.push(linkGroup.name);
                html.push('</h2><div>');
                if (linkGroup.children && linkGroup.children.length && linkGroup.children[0].children) {
                    // 更に階層がある場合
                    array.forEach(linkGroup.children, function(thirdGroup) {
                        // 各リンク群
                        html.push('<div><label>');
                        html.push(thirdGroup.name);
                        html.push('</label><ul>');
                        array.forEach(thirdGroup.children, function(item) {
                            // 各リンク要素
                            html.push('<li><a href="');
                            html.push(item.href);
                            html.push('" target="_blank">');
                            html.push(item.name);
                            html.push('</a></li>');
                        });
                    });
                    if (UserInfo.getRunningMode()==='LGWAN') {
                        html.push('<li><a href="');
                        html.push('http://10.187.2.133/new_bosai/fuken/index.html?areaCurrent=P07&prefCurrent=4130');
                        html.push('" target="_blank">');
                        html.push('防災情報提供システム');
                        html.push('</a></li>');
                    }
                    html.push('</ul></div>');
                } else {
                    // フラットにリンクが並ぶ場合
                    html.push('<ul>');
                    array.forEach(linkGroup.children, function(item) {
                        // 各リンク要素
                        html.push('<li><a href="');
                        html.push(item.href);
                        html.push('" target="_blank">');
                        html.push(item.name);
                        html.push('</a></li>');
                    });
                    if (UserInfo.getRunningMode()==='LGWAN') {
                        html.push('<li><a href="');
                        html.push('http://10.187.2.133/new_bosai/fuken/index.html?areaCurrent=P07&prefCurrent=4130');
                        html.push('" target="_blank">');
                        html.push('防災情報提供システム');
                        html.push('</a></li>');
                    }
                    html.push('</ul>');
                }
                html.push('</div></section>');
            });
            this.linkContainer.innerHTML = html.join('');
        }
    });
});
