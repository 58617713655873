/**
 * SIP4D-CKAN情報管理詳細モジュール
 * @module app/sip4dCkan/Sip4dCkanDetailDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/Sip4dCkanDetailDialog.html',
    'idis/view/page/_PageBase',
    // 以下、変数として受け取らないモジュール
    'dijit/form/Form',
    'dijit/TitlePane',
    './Sip4dCkanDetailGrid'
], function(module, declare, template, _PageBase) {

    /**
     * SIP4D-CKAN情報管理詳細
     * @class Sip4dCkanDetailDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/sip4dCkan/Sip4dCkanDetailDialog~Sip4dCkanDetailDialog# */ {

        // テンプレート文字列
        templateString: template,
        
        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--sip4dCkan',

        startup: function() {
        }
    });
});
