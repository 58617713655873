/**
 * 指示共有グループ画面用モジュール。
 * @module app/chat/ChatShareStatusPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/ChatShareStatusPage.html',
    'idis/view/page/_PageBase',
    'dijit/Dialog',
    'idis/control/Router',
    'idis/control/Locator',
    'idis/model/UserInfo',
    'idis/store/IdisRest',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    './_ChatGroupDialogBase',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    './ChatShareStatusGrid'
], function(module, declare, lang, template, _PageBase, Dialog, Router, Locator, UserInfo, IdisRest,
    Loader, DialogChain, _ChatGroupDialogBase) {
    /**
     * 指示共有グループ画面
     * @class ChatShareStatusPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _ChatGroupDialogBase,
    /** @lends module:app/chat/ChatShareStatusPage~ChatShareStatusPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--chatShare',

        /**
         * ストア
         */
        store: null,

        /**
         * チャットグループID
         */
         _chatGroupId: null,

        /**
         * チャットID
         */
        _chatId: null,

        /**
         * constructor
         */
        constructor: function() {
            this._chatGroupId = Locator.getQuery().chatGroupId;
            this._chatId = Locator.getQuery().chatId;
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'chatId',
                target: '/api/chatShare/read'
            });
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
            this.initGrid();
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            this.updateGridQuery();
        },

        /**
         * チャットグループを検索する。
         */
        onSubmit: function() {
            console.debug('[検索]ボタンがクリックされました。');
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * グリットを更新する。
         */
        updateGridQuery: function() {
            var filter = new this.store.Filter();
            var value = this.form.get('value');

            filter = filter.eq('chatId', this._chatId);

            // 組織名による検索
            if (value.deptName && value.deptName !== '') {
                filter = filter.eq('deptName', value.deptName);
            }

            // 表示条件による検索
            if (value.displayType && value.displayType !== '') {
                filter = filter.eq('displayType', value.displayType);
            }

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);

            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.chatShareStatusGrid.set('collection', collection);
        },

        // パンくずリストのボタンを押下したときの挙動
        onChatShareGroupPageLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('chatShare/group');
        },

        // パンくずリストのボタンを押下したときの挙動
        onChatSharePageLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('chatShare', {
                chatGroupId: this._chatGroupId
            });
        }
    });
});
