/**
 * Lアラートお知らせ配信ダイアログ。
 * @module app/provide/ProvideUrgentMailDetailDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/ProvideUrgentMailDetailDialog.html',
    'app/provide/_ProvideDialogBase',
    'idis/util/DateUtils',
    // 以下、変数から参照されないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'dijit/form/SimpleTextarea',
    'idis/view/form/ReadOnlyInput',
    'app/view/form/MunicipalitySelector',
    'app/view/form/MunicipalityListMultiSelector'
], function(module, declare, template, _ProvideDialogBase, DateUtils) {
    /**
     * Lアラートお知らせ登録ダイアログ画面。
     * @class ProvideUrgentMailDetailDialog
     * @extends module:app/provide/_ProvideDialogBase~_ProvideDialogBase
     */
    return declare(module.id.replace(/\//g, '.'), [_ProvideDialogBase],
        /** @lends module:app/provide/_ProvideDialogBase~_ProvideDialogBase# */ {
        // テンプレート文字列
        templateString: template,

        // ベースクラスをオーバーライド
        // 画面から渡された値で初期表示設定を行う。
        initDialog: function(obj){
            // 配信種別をセットする。
            this.sendCategoryNode.innerHTML = this.getCategoryName(obj.sendCategory);
            // 市町村コードをセットする。
            this.municipalityCd._setValueAttr(obj.municipalityCd);
            // 配信対象市町をセットする
            this.municipalityListMultiSelector._setValueAttr(obj.targetMunicipalityCds);
            // 配信日時をセットする。
            var timestamp = new Date(obj.sendTimestamp);
            this.sendTimestampNode.innerHTML = DateUtils.format(timestamp);
            // タイトルを設定する。
            this.subjectInput.set('value', obj.subject);
            // 内容を設定する。
            this.bodyText.setValue(obj.bodyText);
        },

        // submit時に呼ばれる（親クラスで定義）
        onSubmit: function(){
            return false; // 何もしない
        }
    });
});

