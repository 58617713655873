define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function (module, declare, lang, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--simulation',

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            helper.column('eventId', 'イベントID', { sortable: false }),
            {
                field: 'timing', label: '発生タイミング',
                sortable: false,
                formatter: lang.hitch(this, function (value) {
                    var ss = ('00' + (value % 60)).slice(-2);
                    var mm = ('00' + parseInt((value / 60), 10) % 60).slice(-2);
                    var hh = ('00' + parseInt(value / 60 / 60, 10) % 24).slice(-2);
                    return '</span>+' + hh + ':' + mm + ':' + ss + '</span>';
                })
            },
            {
                field: 'eventType', label: 'イベント種類',
                sortable: false,
                formatter: lang.hitch(this, function (value) {
                    var str = '<span>';
                    switch (value) {
                        case '01':
                            str += 'クロノロジ';
                            break;
                        case '02':
                            str += '避難情報';
                            break;
                        case '03':
                            str += '避難所情報';
                            break;
                        case '04':
                            str += '被害情報';
                            break;
                    }
                    return str + '</span>';
                })
            },
            {
                field: 'status', label: 'ステータス',
                sortable: false,
                formatter: lang.hitch(this, function (value, item) {
                    var text = '<span>';
                    switch (item.status) {
                        case '0':
                            text += '未実行';
                            break;
                        case '1':
                            text += '実行済';
                            break;
                        default:
                            text += 'エラー発生';
                            break;
                    }
                    text += '</span>';
                    if (item.startTimestamp && item.status !== '0') {
                        text += '<br><span>' + item.startTimestamp + '</span>';
                    }
                    return text;
                })
            },
            helper.column('content', '内容', { sortable: false }),
            helper.column('updTimestamp', '最終更新日時', { sortable: false })
        ]
    });
});
