/**
 * レイヤー情報を単に出力する詳細画面パーツ。
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/query',
    'dojo/text!./templates/LayerPropDetail.html',
    'idis/view/Loader',
    'idis/view/_IdisWidgetBase',
    'app/map/detail/LayerPropList',
    // 以下、変数として受け取らないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'dijit/form/TextBox',
    'dijit/form/Form',
    'idis/view/form/Button',
    './DrawDetailGrid',
    './LayerPropGrid'
], function(module, declare, lang, query, template,
    Loader, _IdisWidgetBase, LayerPropList) {
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase, {

        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        layerPropList: null,

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
            this.layerPropList = new LayerPropList();
        },

        startup: function() {
            this.inherited(arguments);
            this.initLayerPropGrid();
        },

        /**
         * グリッドを初期化する。
         */
        initLayerPropGrid: function() {
            Loader.wait(this.layerPropList.initLayerPropStore(this.item)).then(lang.hitch(this, function() {
                this.layerPropGrid.set('collection', this.layerPropList.layerPropStore.filter().sort('name'));
            }));
        },

        resize: function(changeSize, resultSize) {
            this.borderContainer.resize(changeSize, resultSize);
        },

        onSubmit: function() {
            try {
                // 住所検索を実行
                if (this.address.get('value') || this.name.get('value')) {
                    var collection = this.layerPropList.layerPropStore.filter(lang.hitch(this, function(obj) {
                        // store内の文字列を整形する
                        var address = this.shapeStr(obj.address);
                        var name = this.shapeStr(obj.name);
                        // 検索文字列を整形する
                        var addressQuery = this.shapeStr(this.address.get('value'));
                        var nameQuery = this.shapeStr(this.name.get('value'));

                        if (address.indexOf(addressQuery) !== -1 && name.indexOf(nameQuery) !== -1) {
                            return true;
                        } else {
                            return false;
                        }
                    }));
                    this.layerPropGrid.set('collection', collection);
                } else {
                    this.layerPropGrid.set('collection', this.layerPropList.layerPropStore.filter());
                }
            } finally {
                return false;
            }
        },

        shapeStr: function(str) {
            if (!str) {
                return '';
            } else if (!isNaN(str)) {
                return str.toString();
            }
            // 全角と半角の文字コードの数値の差分を算出する。
            var diff = 'ａ'.charCodeAt(0) - 'a'.charCodeAt(0);
            // 文字列の空白を除去し、全角英数字を半角英数字に、全角のハイフンを半角のハイフンに置き換えて返す。
            return str.replace(/\s+/g, '').replace(/[ー−]/g, '-').replace(/[Ａ-Ｚａ-ｚ０-９ー]/g, function(s) {
                // 全角の文字コードから「全角半角の文字コードの差分」を差し引くことで、半角文字に変換する。
                return String.fromCharCode(s.charCodeAt(0) - diff);
            });
        },

        destroy: function(){
            this.inherited(arguments);
            this.layerPropList.requestList.forEach(lang.hitch(this, function(xhr) {
                if (xhr.status === 0) {
                    // reverseGeocodingの処理が完成していない場合は処理をキャンセル
                    xhr.abort();
                }
            }));
        }
    });
});
