/**
 * 標準ツリー用モジュール。
 * @module idis/view/tree/IdisTree
 */
define([
    'module',
    'dojo/_base/declare',
    'dijit/Tree'
], function(module, declare, Tree) {
    /**
     * 標準ツリー・ウィジェット。
     * @class IdisTree
     * @extends module:dijit/Tree~Tree
     * @param {Object} kwArgs
     * @param {module:dijit/tree/model} kwArgs.model ツリー・モデル
     */
    return declare(module.id.replace(/\//g, '.'), Tree, /** @lends module:idis/view/tree/IdisTree~IdisTree# */ {
        /**
         * ルート要素は表示しない
         * @type {boolean}
         */
        showRoot: false,

        /**
         * 子を持つ要素がクリックされたら展開する
         * @type {boolean}
         */
        openOnClick: true,

        /**
         * DOM要素がウィジェットのアイコン要素かどうかを返す。
         * @param {Node} node DOM要素
         * @param {TreeNode} widget ツリー要素ウィジェット
         * @returns {boolean} DOM要素がツリー要素ウィジェットのアイコン要素であればtrue、それ以外の場合はfalse
         */
        isIconNode: function(node, widget) {
            return node === widget.iconNode;
        }
    });
});

