/**
 * 画面に通知を出す
 * @module app/common/notifier/Notifier
 */
define([
    'module',
    'dojo/dom-class',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/Notifier.html',
    'idis/view/_IdisWidgetBase',
    './Notify'
], function (module, domClass, array, declare, lang, template, _IdisWidgetBase, Notify) {
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase, {
        // テンプレート文字列
        templateString: template,

        /**
         *
         * @param {*} obj メッセージが入ったオブジェクト
         *  message(required): メッセージ本体
         *  title: タイトル
         *  level: {high/middle/low} (default: low)
         *  timeout: ポップアップを出し続ける時間 (default: 0[消えない])
         *  page: ポップアップクリック時に遷移させたいページ(adisrouterに登録したもの)
         *  url: ポップアップクリック時に遷移させたいURL, pageとurlが両方登録されていたらpageが優先
         */
        notify: function(obj) {
            if (!obj || !obj.message) {
                return;
            }

            // notifierContainerのStyleをセット
            this.setTopStyle();

            return new Notify(lang.mixin(obj, {
                _container: this.notifierContainer
            }));
        },

        /**
         * 通知数に応じて、topの高さを変える
         */
        setTopStyle: function(){
            // 通知数を取得（既に追加されている要素数 ＋ 今回追加する分１）
            var notifyCount = this.domNode.childElementCount + 1;
            // top用のクラスを除去
            array.forEach(this.notifierContainer.classList, function(className){
                if (className.includes('is-')&&className.includes('notifies')) {
                    domClass.remove(this.notifierContainer, className);
                }
            }, this);
            // 数値数に応じたtopがセットされているクラスを追加
            domClass.add(this.notifierContainer, 'is-' + notifyCount + 'notifies');
        }
    });
});