/**
 * 権限制限ボタン用モジュール。
 * @module idis/view/form/AclButton
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/dom-style',
    '../../consts/ACL',
    '../../model/UserInfo',
    './Button'
], function(module, declare, domStyle, ACL, UserInfo, Button) {
    /**
     * 権限制限ボタン・ウィジェット。
     * @class AclButton
     * @extends module:idis/view/form/Button~Button
     * @param {object} kwArgs
     * @param {object.aclType} 確認対象の権限
     */
    return declare(module.id.replace(/\//g, '.'), Button,
        /** @lends module:idis/view/form/AclButton~AclButton# */ {
        /**
         * 初期状態では押下不可。
         * @type {boolean}
         */

         /**
         //  * ラベル
         //  */
         label: null,

         /**
         //  * 非表示フラグ
         //  */
         displayNone: false,

        /**
         * 確認対象の権限
         * @type {string}
         */
        aclType: null,

        // 設定値が自身のプロパティーに反映されてから呼ばれる
        postMixInProperties: function() {
            this.inherited(arguments);
            var message;
            if (!this.aclType) {
                // ACL未指定
                // TODO aclTypeにFunctionCdをセットする対応が完了したら、下記コメントアウト除去
                // message = 'aclTypeが設定されていません';
                console.log('aclTypeを設定してください');
            } else if (this.aclType === 'ADMIN') {
                // ADMIN_FLGで出し分けたい場合があるので、処理を分ける。
                if(!UserInfo.getAdminFlg()){
                    // 権限なし
                    message = '管理者権限がありません (' + this.aclType + ')';
                }
            } else if (!UserInfo.hasWriteAuthz(this.aclType)) {
                // 機能コードで指定され、ユーザに編集権限がなかった場合
                message = '操作権限がありません (' + this.aclType + ')';
            }
            if (message) {
                this.displayNone = true;
                console.error(this.id || module.id + '#postMixInProperties: ' + message);
            }
        },

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
            // 権限がない場合、非表示とする
            if(this.displayNone){
                domStyle.set(this.domNode, 'display', 'none');
            }
        },

        /**
         * このボタンの無効状態を設定する。
         * 無効化は一律で実施するが、有効化は権限がある場合に限り実施する。
         * @param {boolean} value 新たな設定値
         */
        _setButtonDisplayNone: function(value) {
            if (!value || !UserInfo.hasAuthz(this.aclType)) {
                domStyle.set(this.domNode, 'display', 'none');
            }
        }
    });
});
