/**
 * 職員所属課選択用入力パーツ。
 * @module app/view/form/EmployeeDivisionSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/dom-style',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'idis/view/form/TreeSelector'
], function(module, declare, domStyle, CacheStoreModel, CacheTreeRest, TreeSelector) {
    /**
     * 職員所属課選択用入力パーツ。
     * @class EmployeeDivisionSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:app/view/tree/EmployeeDivisionSelector~EmployeeDivisionSelector# */ {
        // 選択ダイアログのタイトル
        title: '部局・課選択',

        // ツリー・モデル
        model: null,

        // ユーザーに応じた組織を表示
        manageFlg: false,

        postMixInProperties: function() {
            // 設置されるたびにモデルを新規生成する
            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                    target: this.manageFlg ? '/api/convoMails/employees/manageDivisions4tree' :
                                             '/api/convoMails/employees/divisions4tree'
                })
            });
        }
    });
});