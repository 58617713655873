/**
 * 地図を画像として保存するためのLeaflet拡張
 */
define([
    'leaflet',
    './OverlayLayer',
    './VectorTileLayer',
    './TileLayer',
    'dojo/_base/array',
    'dojo/_base/lang',
    'dojo/dom',
    'dojo/dom-attr',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/dom-geometry',
    'dojo/dom-style',
    'dojo/on',
    'dojo/query',
    'idis/util/DateUtils'
], function(leaflet, OverlayLayer, VectorTileLayer, TileLayer,
    array, lang, dom, domAttr, domClass, domConst, domGeom, domStyle, on, query,
    DateUtils) {

    var MapToImage = leaflet.MapToImage = leaflet.Class.extend({
        includes: leaflet.Mixin.Events,

        options: {
          // 範囲指定か否か
          drawControls: true
        },

        initialize: function(map, options) {
            // latLngBoundsが指定されていれば指定範囲. なければ表示されている全範囲となる.
            // FIXME: 但し、指定範囲は未実装
            var pixelBounds = null;
            if (options.latLngBounds) {
                // 範囲指定あり
                var p1 = map.project(options.latLngBounds.getSouthWest(), map.getZoom());
                var p2 = map.project(options.latLngBounds.getNorthEast(), map.getZoom());
                pixelBounds = leaflet.bounds(p1, p2);
            }
            options.pixelBounds = pixelBounds;
            var drawControls = (pixelBounds ? true : false);
            options.drawControls = drawControls;

            leaflet.setOptions(this, options);

            this._map = map;

            this._setLayerList();
        },

        /**
         * 描画対象のレイヤーリストを設定する
         */
        _setLayerList: function() {
            this._list = [];
            var baseLayerItem = null;
            var layerItems = [];
            this._map.eachLayer(lang.hitch(this, function(layer){
                // 背景地図
                // FIXME: 地図から背景レイヤーを取得するメソッドとしてBaseLayerUtilsに外だししたい
                if (layer.options && layer.options.baseLayerId) {
                    var item = {};
                    item.layer = layer;
                    item.type = 'tile';
                    baseLayerItem = item;
                    return;
                }

                // 背景地図以外のタイル
                if (layer.options && layer.options.infoCategoryCd) {
                    var tileLayerInfoCategoryCds = ['O014', 'O009', 'O010', 'O006', 'O013'];
                    if (tileLayerInfoCategoryCds.indexOf(layer.options.infoCategoryCd) >=0) {
                        layerItems.push({
                            layer: layer,
                            type: 'tile'
                        });
                    }
                    return;
                }

                // 作図オブジェクトおよび通行規制の路線
                // 作図オブジェクトには必ずlayer.options.drawTypeがある
                // 矢印のヘッドもヘッド自体が1レイヤーで編集可能オブジェクトであるからeditingプロパティを持っているため対応可能
                // 路線は作図ではないためdrawTypeはないが、編集可能オブジェクトでeditingプロパティを持つ
                if (layer.options && layer.options.drawType ||
                    layer.options && layer.editing) {
                    this._list.push({
                        layer : layer,
                        opacity: layer.options.opacity,
                        type: 'geojson'
                    });
                    return;
                }

            }));
            // eachLayerは地図に追加された順番でレイヤー取得される
            // そのため、背景地図切り替えによって背景地図が最初でないケースもある
            // 背景地図は必ずどのレイヤー描画より下にくるように、リストの一番最初に追加する
            // 背景地図以外のタイルがある場合は、背景地図の次に追加する
            if (layerItems.length>0) {
                array.forEach(layerItems, function(item){
                    this._list.unshift(item);
                }, this);
            }
            this._list.unshift(baseLayerItem);
        },

        /**
         * 各レイヤーのCanvasへの投影を開始する
         */
        start: function() {

            for (var i = 0; i < this._list.length; i++) {
                var item = this._list[i];
                var layer = item.layer;
                if (!layer) {
                    item.loaded = true;
                    continue;
                }

                if (item.type === 'geojson') {
                    item.drawLayer = new VectorTileLayer(this._map, layer, {
                        opacity: item.opacity, pixelBounds: this.options.pixelBounds}
                    );
                }
                if (item.type === 'kml') {
                    item.drawLayer = new VectorTileLayer(this._map, layer, {
                        opacity: item.opacity, pixelBounds: this.options.pixelBounds}
                    );
                } else if (item.type === 'geojson_tile') {
                    item.drawLayer = new VectorTileLayer(this._map, layer, {
                        opacity: item.opacity, pixelBounds: this.options.pixelBounds}
                    );
                } else if (item.type === 'geotiff' || item.type === 'videooverlay') {
                    item.drawLayer = new OverlayLayer(this._map, layer, {
                        opacity: item.opacity, pixelBounds:this.options.pixelBounds}
                    );
                } else if (item.type === 'tile') {
                    item.drawLayer = new TileLayer(this._map, layer, {
                        opacity: layer.options.opacity,
                        grayscale: null,
                        blend: null,
                        pixelBounds:this.options.pixelBounds
                    });
                } else if (item.type === 'system') {
                    item.drawLayer = layer;
                } else {
                    item.loaded = true;
                }

                if (!item.drawLayer) {
                    continue;
                }
                item.loaded = false;
            }

            for (var k = 0; k < this._list.length; k++) {
                var item2 = this._list[k];
                if (!item2.drawLayer) {
                    continue;
                }

                if (item2.drawLayer.on) {
                    item2.drawLayer.off('loaded').on('loaded', leaflet.bind(this._onLoad, this, item2));
                }

                if (item2.drawLayer.refreshQueue) {
                    item2.drawLayer.refreshQueue();
                }

                if (item2.drawLayer.load) {
                    item2.drawLayer.load();
                } else {
                    item2.loaded = true;
                }
            }

            if (!this._drawn) {
                this._onLoad({});
            }

        },

        _onLoad: function(item) {
            item.loaded = true;
            var loaded = true;
            for (var i = 0; i < this._list.length; i++) {
                if (!this._list[i].loaded){
                    loaded = false;
                    break;
                }
            }
            if (loaded) {
                this._drawn = true;
                this._drawMapImage();
            }
        },

        _drawMapImage: function() {
            var size = this.options.pixelBounds ? this.options.pixelBounds.getSize() : this._map.getSize();

            this._mapCanvas = domConst.toDom('<canvas>');
            domAttr.set(this._mapCanvas, 'width', size.x);
            domAttr.set(this._mapCanvas, 'height', size.y);

            this._mapTexture = this._mapCanvas.getContext('2d');
            this._mapTexture.fillStyle = 'rgb(255, 255, 255)';
            this._mapTexture.fillRect(0, 0, size.x, size.y);
            for (var i = 0; i < this._list.length; i++) {
                if (this._list[i].drawLayer) {
                    if (this._list[i].type === 'system') {
                        this._list[i].drawLayer.drawPath(
                            this._mapTexture, this.options.latLngBounds, this.options.pixelBounds);
                    } else {
                        this._list[i].drawLayer.draw(this._mapTexture);
                    }
                }
            }
            this._markerPaneToCanvas();
        },

        /**
         * 付箋をCanvasに投影する
         */
        _drawDIVMarker: function(marker, origin, pixelBounds) {
            console.log('_drawDIVMarker');

            var radius = marker.style.borderRadius;
                //div.css('border-radius') || div.css('-moz-border-radius') || div.css('-webkit-border-radius');
            if (radius) {
                radius = lang.trim(radius);
                var parts = radius.split(' ');
                if (parts.length > 0) {
                    radius = parseInt(parts[0], 10);
                } else {
                    radius = 0;
                }
            } else {
                radius = 0;
            }

            var left = 0;
            var top = 0;
            if (leaflet.Browser.any3d) {
                var matches =
                    marker.style[leaflet.DomUtil.TRANSFORM].match(/([+-]*\d+)[\D]*\,[^+-\d]*([+-]*\d+)[\D]*/);
                if (matches) {
                    left = parseFloat(matches[1]) + (origin.x - pixelBounds.min.x);
                    top  = parseFloat(matches[2]) + (origin.y - pixelBounds.min.y);
                }
            } else {
                left = parseInt(marker.style.left, 10) + (origin.x - pixelBounds.min.x);
                top = parseInt(marker.style.top, 10) + (origin.y - pixelBounds.min.y);
            }

            var margin = {left: 0, top: 0};

            var opacity = marker.style.opacity;
            if (!opacity) {
                opacity = 1;
            }

            // DrawDialog.css によって、div.CustomDivIconのmargin-leftと-topは0!importantで設定されている
            /*
            if (marker.style.marginLeft) {
                margin.left = parseFloat(marker.style.marginLeft);
            }
            if (marker.style.marginTop) {
                margin.top = parseFloat(marker.style.marginTop);
            }
            */
            margin.left = 0;
            margin.top = 0;
            if (marker.style.margin) {
                var parts2 = lang.trim(marker.style.margin).split(' ');
                if (parts2.length > 0) {
                    margin.top = parseFloat(lang.trim(parts2[0]));
                }
                if (parts2.length > 3) {
                    margin.left= parseFloat(lang.trim(parts2[3]));
                }
            }


            var width = parseFloat(marker.style.width);
            var height = parseFloat(marker.style.height);
            // テクスチャーへの設定はHEX, RGB(A)どれでも可能
            var bgColor = marker.style.backgroundColor;
            var border = marker.style.border;
            var borderWidth = 4;
            var borderColor = 'rgb(51, 136, 255)';
            if (border !== '') {
                var splits = border.split(' ');
                if (splits.length === 3) {
                    borderWidth = parseFloat(splits[0]);
                    borderColor = splits[2];
                }
            }

            var cssText = marker.style.cssText;
            var fontSize = marker.style.fontSize;
            if (fontSize === '') {
                fontSize = '14px';
            }
            var fontWeight = marker.style.fontWeight;
            var fontStyle = marker.style.fontStyle;
            //var textShadow = marker.style.textShadow || div.style('-ms-text-shadow') || '';
            var textShadow = marker.style.textShadow;
            if (textShadow) {
                var matches2 = textShadow.match(/(rgb\([\d\s,]+\))/);
                if (matches2) {
                    textShadow = matches2[1];
                } else {
                    matches2 = textShadow.match(/(#[a-f|A-F|\d]+)/);
                    if (matches2) {
                        textShadow = matches2[1];
                    } else {
                        textShadow = '';
                    }
                }
            } else {
                textShadow = '';
            }
            var color = marker.style.color;
            var fontFamily = marker.style.fontFamily;
            if (!fontFamily || fontFamily === '') {
                fontFamily = '"Meiryo","メイリオ","ヒラギノ角ゴ Pro W3","sans-serif"';
            }
            // これだと改行を考慮できない
            //var text = marker.textContent;
            // 以下の構造になっていることを利用して、改行コードを入れたテキストを生成
            // .CustonDivIcon > div > div（リサイズ用）, div（テキスト）, div（テキスト）
            var rootDiv = query('>div', marker)[0];
            // 直下子要素の１つ目はリサイズ用のため、それ以外の要素を取得
            // FIXME: CSS3表現のため古いIEで使えない
            var textDivs = query('div:not(:first-child)', rootDiv);
            var text = '';
            array.forEach(textDivs, function(div, index){
                if (index === textDivs.length - 1) {
                    // 最後
                    text += div.textContent;
                } else {
                    text += div.textContent + '\n';
                }
            });

            var transformOrign = marker.style.transformOrigin;
            var lineHeight = marker.style.lineHeight;
            var transform = marker.style.transform;
                    //div.style('-moz-transform') || div.style('-o-transform') ||
                    //div.style('-ms-transform')|| div.style('-webkit-transform');
            var rotate = 0;
            if (transform) {
                var matches3 = transform.match(/rotate\((.+?)deg/);
                if (matches3) {
                    rotate = parseFloat(matches3[1]);
                } else {
                    var matches4 = cssText.match(/rotate\((.+?)deg/);
                    if (matches4) {
                        rotate = parseFloat(matches4[1]);
                    }
                }
            }

            var angle90 = marker.style.writingMode;
                    //div.style('-moz-writing-mode') || div.style('-o-writing-mode') ||
                    //div.style('-ms-writing-mode')|| div.style('-webkit-writing-mode');
            if (angle90) {
                angle90 = lang.trim(angle90);
            } else {
                var matches5 = cssText.match(/vertical-rl/);
                if (matches5) {
                    angle90 = 'vertical-rl';
                }
            }

            var textAlign = 'left';
            var textBaseline = 'top';

            if (transformOrign && transformOrign !== '') {
                transformOrign = lang.trim(transformOrign);
                var parts3 = transformOrign.split(' ');
                if (parts3.length === 1) {
                    if (parts3[0] === 'right' || parts3[0] === 'bottom') {
                        textAlign = 'right';
                        textBaseline = 'bottom';
                    } else if (parts3[0] === 'center') {
                        textAlign = 'center';
                        textBaseline = 'middle';
                    } else {
                        textAlign = 'left';
                        textBaseline = 'top';
                    }

                } else if (parts3.length >= 2) {
                    if (parts3[0] === 'right' || parts3[0] === 'bottom') {
                        textAlign = 'right';
                    } else if ( parts3[0] === 'center') {
                        textAlign = 'center';
                    } else {
                        textAlign = 'left';
                    }

                    if (parts3[1] === 'right' || parts3[1] === 'bottom') {
                        textBaseline = 'bottom';
                    } else if (parts3[1] === 'center') {
                        textBaseline = 'middle';
                    } else {
                        textBaseline = 'top';
                    }
                }
            }

            if (lineHeight && lineHeight.match(/px/)) {
                lineHeight = parseFloat(lineHeight);
            } else {
                lineHeight = 0;
            }

            var parts4 = fontFamily.split(',');
            fontFamily = '';
            for (var k = 0; k < parts4.length; k++) {
                fontFamily +=
                    (fontFamily === '' ? '': ',') + '"' + lang.trim(parts4[k]).replace(/[\'\"]/g,'') + '"';
            }

            this._mapTexture.font =
                (fontStyle !== '' ? fontStyle + ' ' : '' ) + fontWeight + ' ' + fontSize + ' ' + fontFamily + '';
            this._mapTexture.globalAlpha = opacity;

            if (angle90 === 'mode:tb-rl' || angle90 === 'vertical-rl') {
                /*
                return this._drawDIVMarkerTategaki(
                    left, top, margin, text, color, textShadow, textAlign, textBaseline, rotate, lineHeight);
                    */
                // FIMXE: 今は縦書きはないので未対応
                console.debug('_drawDIVMarkerTategaki');
            }

            // 背景描画
            this._mapTexture.fillStyle = bgColor;
            this._mapTexture.fillRect(left + margin.left, top + margin.top, width, height);
            // 枠線描画
            this._mapTexture.beginPath();
            // 線の太さ分＋する
            this._mapTexture.moveTo(left + margin.left + borderWidth, top + margin.top + borderWidth);
            this._mapTexture.lineTo(left + margin.left + borderWidth, top + margin.top + height + borderWidth);
            this._mapTexture.lineTo(left + margin.left + width + borderWidth,
                top + margin.top + height + borderWidth);
            this._mapTexture.lineTo(left + margin.left + width + borderWidth, top + margin.top + borderWidth);
            this._mapTexture.closePath();
            this._mapTexture.save();
            this._mapTexture.lineWidth = borderWidth;
            this._mapTexture.strokeStyle = borderColor;
            this._mapTexture.stroke();

            // テキスト描画
            this._mapTexture.textAlign = textAlign;
            this._mapTexture.textBaseline = textBaseline;
            this._mapTexture.fillStyle = color;

            if (lineHeight !== 0) {
                this._mapTexture.textAlign = 'top';
                top += (lineHeight/2);
            }

            this._mapTexture.save();
            this._mapTexture.translate(left + margin.left + borderWidth, top + margin.top + borderWidth);

            if (rotate !== 0) {
                this._mapTexture.rotate(rotate * Math.PI / 180);
            } else {
                this._mapTexture.rotate(0);
            }

            // Canvasでは自動改行してくれないので、１行１行の水平位置を計算して配置
            var lines = text.split('\n');
            for (var i = 0; i < lines.length; i++) {
                var line = lines[i];
                var addY = 0;

                // ２行目以降の水平位置は行数にlineHeightを考慮する
                // ここでは文字数に応じて自動改行ではなく、div要素によって行間が設定されているので、わざと6px（目分量）あける
                if (i) {
                    //addY += parseFloat(fontSize) * i + parseFloat(fontSize) * lineHeight * i;
                    addY += parseFloat(fontSize) * i + 6 * i;
                }

                this._mapTexture.fillText(line, 0, addY);
            }
            this._mapTexture.restore();
        },

        /**
         * MarkerPane（作図オブジェクト系）をCanvasに投影する
         */
        _markerPaneToCanvas: function() {
            //var size = this.options.pixelBounds ? this.options.pixelBounds.getSize() : this._map.getSize();

            var markerPane = lang.clone(query('.leaflet-marker-pane')[0]);

            var origin = this._map.getPixelOrigin();
            var pixelBounds = this.options.pixelBounds ? this.options.pixelBounds : this._map.getPixelBounds();

            // アイコン(L.icon)
            var images = query('img.leaflet-marker-icon', markerPane);
            images.forEach(lang.hitch(this, function(image) {
                // Canvas = Map Container の大きさで生成
                // この範囲において、画像を左上端として、imageに格納されたImageオブジェクトを描画する
                var left = 0;
                var top = 0;
                var matches =
                    image.style[leaflet.DomUtil.TRANSFORM].match(/([+-]*\d+)[\D]*\,[^+-\d]*([+-]*\d+)[\D]*/);
                if (matches) {
                    left = parseFloat(matches[1]) + (origin.x - pixelBounds.min.x);
                    top  = parseFloat(matches[2]) + (origin.y - pixelBounds.min.y);
                }

                // マージンの考慮
                var marginLeft = 0;
                var marginTop = 0;
                if (image.style.marginLeft !== '') {
                    marginLeft = parseFloat(image.style.marginLeft);
                }
                if (image.style.marginTop !== '') {
                    marginTop = parseFloat(image.style.marginTop);
                }

                left += marginLeft;
                top += marginTop;
                // セットの仕方：ctx.drawImage(image, dx, dy, dw, dh)
                // dw と dh が指定されると、画像は、dw を横幅、dh を縦幅としてサイズに伸縮されるので、画像の大きさ可変に対応するためにセット
                var width = (image.style.width && image.style.width !== '') ?
                        image.style.width : ((image.width && image.width !== '') ? image.width : 20);
                var height = (image.style.height && image.style.height !== '') ?
                    image.style.height : ((image.height && image.height !== '') ? image.height : 20);
                this._mapTexture.drawImage(image, left, top,
                    parseFloat(width), parseFloat(height));

            }));

            // アイコン(L.divIcon)
            var divs = query('div.leaflet-marker-icon', markerPane);
            divs.forEach(lang.hitch(this, function(div) {
                var left = 0;
                var top = 0;
                var matches =
                    div.style[leaflet.DomUtil.TRANSFORM].match(/([+-]*\d+)[\D]*\,[^+-\d]*([+-]*\d+)[\D]*/);
                if (matches) {
                    left = parseFloat(matches[1]) + (origin.x - pixelBounds.min.x);
                    top  = parseFloat(matches[2]) + (origin.y - pixelBounds.min.y);
                }
                switch (div.classList[1]) {
                    case 'damage-point':
                        // 被害アイコン
                        var images = query('img', div);
                        images.forEach(lang.hitch(this, function(image) {
                            var imageLeft = left;
                            var imageTop = top;

                            // マージンの考慮
                            var marginLeft = 0;
                            var marginTop = 0;
                            if (image.style.left !== '') {
                                marginLeft = parseFloat(image.style.left);
                            }
                            if (image.style.top !== '') {
                                marginTop = parseFloat(image.style.top);
                            }

                            imageLeft += marginLeft;
                            imageTop += marginTop;

                            // セットの仕方：ctx.drawImage(image, dx, dy, dw, dh)
                            // dw と dh が指定されると、画像は、dw を横幅、dh を縦幅としてサイズに伸縮されるので、画像の大きさ可変に対応するためにセット
                            var width = (image.style.width && image.style.width !== '') ?
                                    image.style.width : ((image.width && image.width !== '') ? image.width : 20);
                            var height = (image.style.height && image.style.height !== '') ?
                                image.style.height : ((image.height && image.height !== '') ? image.height : 20);
                            this._mapTexture.drawImage(image, imageLeft, imageTop,
                                parseFloat(width), parseFloat(height));
                        }));
                        break;
                    case 'marker-cluster':
                        // 被害アイコン郡サークル
                        var circle = div.firstChild;
                        if (circle.style.backgroundColor) {
                            this._drawClusterCircle(circle.innerText, left, top, circle.style.backgroundColor);
                        }
                        break;
                    default:
                        break;
                }
            }));

            // 付箋
            var notes = query('.CustomDivIcon', markerPane);
            for (var i = 0; i < notes.length; i++ ) {
                var note = notes[i];
                this._drawDIVMarker(note, origin, pixelBounds);
            }

            this._popupPaneToCanvas();

        },

        /**
         * PopupPaneをCanvasに描画する
         * FIXME: ポップアップの出力はいったん無効化
         */
        _popupPaneToCanvas: function() {
            console.debug('_popupPaneToCanvas');

            var popupPanes = query('.leaflet-popup-pane');
            var popupPane = null;
            if (popupPanes.length === 0) {
                return;
            } else {
                popupPane = popupPanes[0];
            }

            var origin = this._map.getPixelOrigin();
            var pixelBounds =
                (this.options.pixelBounds ? this.options.pixelBounds : this._map.getPixelBounds());

            var popups = query('.leaflet-popup', popupPane);
            // FIXME: 今はわざとなしにしている
            popups = [];
            popups.forEach(lang.hitch(this, function(popup){
                var left = 0;
                var top = 0;
                if (leaflet.Browser.any3d) {
                    var popupTrans =
                        popup.style[leaflet.DomUtil.TRANSFORM].match(/([+-]*\d+)[\D]*\,[^+-\d]*([+-]*\d+)[\D]*/);
                    if (popupTrans) {
                        left = parseFloat(popupTrans[1]) + (origin.x - pixelBounds.min.x);
                        top  = parseFloat(popupTrans[2]) + (origin.y - pixelBounds.min.y);
                    }
                } else {
                    // FIXME: ちゃんと動くか不明
                    left = domStyle.get(popup, 'left') + (origin.x - pixelBounds.min.x);
                    top = domStyle.get(popup, 'top') + (origin.y - pixelBounds.min.y);
                }
                var marginLeft = domStyle.get(popup, 'left');
                var marginTop = domStyle.get(popup, 'top');
                if (marginLeft !== '') {
                    left += marginLeft;
                }
                if (marginTop !== '') {
                    top += marginTop;
                }

                var contentWrapper = query('.leaflet-popup-content-wrapper', popup)[0];

                var width = domStyle.get(contentWrapper, 'width');
                var height = domStyle.get(contentWrapper, 'height');

                var radius = domStyle.get(contentWrapper, 'borderRadius');
                if (radius) {
                    radius = lang.trim(radius);
                    var parts = radius.split(' ');
                    if (parts.length > 0) {
                        radius = parseInt(parts[0], 10);
                    } else {
                        radius = 0;
                    }
                } else {
                    radius = 0;
                }

                // 背景描画
                this._drawRoundRect(left, top, width, height, radius, '#fff');

                // 三角形描画
                this._mapTexture.save();
                this._mapTexture.beginPath();
                // 下部先端のポインター位置
                var pointerTop = top + height + 10;
                var pointerLeft = left + Math.round(width/2);
                this._mapTexture.moveTo(pointerLeft, pointerTop);
                this._mapTexture.lineTo(pointerLeft - 10, pointerTop - 10);
                this._mapTexture.lineTo(pointerLeft + 10, pointerTop - 10);
                this._mapTexture.fillStyle = '#fff';
                this._mapTexture.fill();
                this._mapTexture.restore();

                // タイトル描画
                // ＋部分は.leaflet-popup-content のマージンを考慮
                //this._mapTexture.translate(left + 19, top + 13);

           }));

           this._scaleToCanvas();
        },

        /**
         * 角丸の四角形を描画する
         * @param radius 度
         */
        _drawRoundRect: function(x, y, width, height, radius, color) {
            this._mapTexture.save();

            this._mapTexture.fillStyle = color;

            this._mapTexture.beginPath();
            this._mapTexture.moveTo(x + radius, y);
            this._mapTexture.lineTo(x + width - radius, y);
            this._mapTexture.arc(x + width - radius, y + radius, radius, Math.PI * 1.5, 0, false);
            this._mapTexture.lineTo(x + width, y + height - radius);
            this._mapTexture.arc(x + width - radius, y + height - radius, radius, 0, Math.PI * 0.5, false);
            this._mapTexture.lineTo(x + radius, y + height);
            this._mapTexture.arc(x + radius, y + height - radius, radius, Math.PI * 0.5, Math.PI, false);
            this._mapTexture.lineTo(x, y + radius);
            this._mapTexture.arc(x + radius, y + radius, radius, Math.PI, Math.PI * 1.5, false);
            this._mapTexture.closePath();
            this._mapTexture.fill();

            this._mapTexture.restore();
        },

        /**
         * アイコン郡の円を描画する
         * @param count    アイコン数
         * @param x        x
         * @param y        y
         * @param bkColor  円背景色
         */
        _drawClusterCircle: function(count, x, y, bkColor) {
            this._mapTexture.beginPath();

            // 半径
            var radius = Math.min(Number(count) * 10, 4 * 10 );
            this._mapTexture.arc(
                x, y,
                radius,
                0, Math.PI * 2, false);
            // 円内塗りつぶし
            this._mapTexture.fillStyle = bkColor;
            this._mapTexture.fill();
            // 円内数字セット
            this._mapTexture.font = radius + 'px sans-serif';
            this._mapTexture.fillStyle = '#000000';
            this._mapTexture.textAlign = 'center';
            this._mapTexture.textBaseline = 'middle';
            this._mapTexture.fillText(count, x, y);

            this._mapTexture.restore();
        },

        /**
         * スケールバーをcanvasに投影
         */
        _scaleToCanvas: function(/* canvas */) {
            // 範囲選択の場合はスケールを表示しない
            if (this.options.drawControls) {
                this._finish();
                return;
            }

            var mapSize = this._map.getSize();
            var scaleBar = query('.leaflet-control-scale')[0];

            // height + padding(上下) + border（上下）を取得
            var size = {
                w : scaleBar.offsetWidth,
                h : scaleBar.offsetHeight
            };

            // 地図コンテナーのoffset（画面の左上からの距離）を取得
            /*
            var mapContainer = this._map.getContainer();
            var offset = {};
            var rect = mapContainer.getBoundingClientRect();
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
            offset.top = rect.top + scrollTop;
            offset.left = rect.left + scrollLeft;
            */

            var pos = {};
            //pos.left = offset.left;
            pos.left = 5;
            pos.top = mapSize.y - size.h - 5;
            size.w -= 2;
            size.h -= 2;

            this._mapTexture.fillStyle = '#fff';
            this._mapTexture.globalAlpha = 0.5;
            this._mapTexture.fillRect(pos.left, pos.top, size.w, size.h);
            if (this._mapTexture.setLineDash !== undefined) {
                this._mapTexture.setLineDash([]);
            } else if ( this._ctx.mozDash !== undefined) {
                this._mapTexture.mozDash = [];
            }

            this._mapTexture.beginPath();
            this._mapTexture.moveTo(pos.left, pos.top);
            this._mapTexture.lineTo(pos.left, pos.top + size.h);
            this._mapTexture.lineTo(pos.left + size.w, pos.top + size.h);
            this._mapTexture.lineTo(pos.left + size.w, pos.top);
            this._mapTexture.save();
            this._mapTexture.globalAlpha = 1;
            this._mapTexture.lineWidth = 2;
            this._mapTexture.strokeStyle = '#777';
            this._mapTexture.stroke();
            this._mapTexture.font =
                'normal 11px "Lucida Grande","Hiragino Kaku Gothic ProN",' +
                '"ヒラギノ角ゴ ProN W3", "Meiryo", "メイリオ", "sans-serif"';
            this._mapTexture.fillStyle = '#333';
            this._mapTexture.textAlign = 'left';
            this._mapTexture.textBaseline = 'middle';
            this._mapTexture.fillText(scaleBar.textContent, pos.left + 6, pos.top + size.h / 2);

            this._mapTexture.restore();

            this._finish();
        },


        _centerCrossToCanvas: function() {
            /*
            var size = this.options.pixelBounds ? this.options.pixelBounds.getSize() : this._map.getSize();
            if (this._gsimaps._onoffObjects[CONFIG.PARAMETERNAMES.CENTERCROSS] .obj.getVisible()) {
                    this._mapTexture.beginPath();
                    this._mapTexture.moveTo(parseInt(size.x / 2) - 16, parseInt(size.y / 2));
                    this._mapTexture.lineTo(parseInt(size.x / 2) + 16, parseInt(size.y / 2));
                    this._mapTexture.moveTo(parseInt(size.x / 2), parseInt(size.y / 2) - 16);
                    this._mapTexture.lineTo(parseInt(size.x / 2), parseInt(size.y / 2) + 16);

                    this._mapTexture.save();
                    this._mapTexture.globalAlpha = 1;
                    this._mapTexture.lineWidth = 3;
                    this._mapTexture.strokeStyle = '#222';
                    this._mapTexture.stroke();

                    this._mapTexture.restore();
            }
            */
            console.debug('_centerCrossToCanvas');
        },

        _finish: function() {
            console.debug('finish');
            this.fire('finish', { canvas : this._mapCanvas } );
        },

        /**
         * 印刷範囲の緯度経度、ポイントを文字列で返す
         * ファイル名に入れたい場合に使用する
         * 現在は未使用
         */
        _makeWorldFileText: function() {
            var size = this.options.pixelBounds ? this.options.pixelBounds.getSize() : this._map.getSize();
            var bounds = this.options.pixelBounds ? this.options.latLngBounds : this._map.getBounds();

            var project = function (latlng) { // (LatLng) -> Point
                var MAX_LATITUDE = 85.0511287798;
                var d = leaflet.LatLng.DEG_TO_RAD,
                    max = MAX_LATITUDE,
                    lat = Math.max(Math.min(max, latlng.lat), -max),
                    x = latlng.lng * d,
                    y = lat * d;

                y = Math.log(Math.tan((Math.PI / 4) + (y / 2)));

                return new leaflet.Point(x, y);
            };

            var northWest = project(
                bounds.getNorthWest()
            );
            var southEast = project(
                bounds.getSouthEast()
            );

            var lt = {
                lng : northWest.x * 6378137.0,
                lat :northWest.y * 6378137.0
            };
            var rb = {
                lng : southEast.x * 6378137.0,
                lat : southEast.y * 6378137.0
            };
            var txt = '';
            txt += ((rb.lng - lt.lng) / size.x) + '\n';
            txt += '0\n';
            txt += '0\n';
            txt += -((rb.lng - lt.lng) / size.x) + '\n';
            txt += lt.lng + '\n';
            txt += lt.lat;

            return txt;
        },

        getDownloadImageCanvas: function() {
            var size = {
                x : parseInt(this._canvas.width, 10),
                y : parseInt(this._canvas.height, 10)
            };
            /*
            var result = $('<canvas>')
                .attr({
                    'width' : size.x,
                    'height' : size.y
                })[0];
            */
            var result = domConst.toDom('<canvas>');
            domAttr.set(result, 'width', size.x);
            domAttr.set(result, 'height', size.y);

            var ctx = result.getContext('2d');

            var src = this._canvas.getContext('2d').getImageData(0, 0, size.x, size.y);
            ctx.putImageData(src, 0, 0);

            return result;
        },

        /**
         * 画像ダウンロードを実行する
         */
        download: function(canvas) {
            this._canvas = canvas;

            // ファイル名はダウンロード実行時刻
            // DateUtils.formatでは日付と時間の間にスペースが入るために置換
            this._fileName = 'img' +
                (DateUtils.format(new Date(), {date: 'yyyyMMdd', time: 'HHmmssSSS'})).replace(' ', '');

            var a = domConst.toDom('<a href="javascript:void(0);">');

            on(a, 'click', lang.hitch(this, function(){
                var canvas = this.getDownloadImageCanvas();
                if (window.navigator.msSaveBlob) {
                    window.navigator.msSaveOrOpenBlob( this._makeImage(canvas.toDataURL()), this._fileName + '.png' );
                } else {
                    var url = window.URL || window.webkitURL;
                    domAttr.set(a, {
                        download : this._fileName + '.png',
                        href : url.createObjectURL(this._makeImage(canvas.toDataURL()))
                    });
                }
            }));
            // クリックイベントを発行
            var evt = document.createEvent('MouseEvents');
            evt.initEvent('click', false, false);
            a.dispatchEvent(evt);
        },

        /**
         * 画像をBlobで返す
         * @param {String} dataUriテキスト
         */
        _makeImage: function(v) {
            var o = null;
            var base64 = v.split(',');
            if (base64.length > 1) {
                var data   = window.atob(base64[1]);
                var dataN = data.length;
                if (dataN > 0) {
                    var dataBuff = new ArrayBuffer(dataN);
                    var dataBlob = new Uint8Array(dataBuff);

                    var i = 0;
                    for (i = 0; i < dataN; i++) {
                        dataBlob[i] = data.charCodeAt(i);
                    }
                    o = new Blob([dataBlob], {type: 'image/png'});
                }
            }
            return o;
        }

    });

    /*
    leaflet.mapToImage = function(map, list, options){
        return new leaflet.MapToImage(map, list, options);
    };
    */

    return MapToImage;
});

