/**
 * 災害対応共有サイトの掲載先組織を扱うモデル。
 * @module app/model/DisInfoShareDestination
 */
define([
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest'
], function(CacheStoreModel, CacheTreeRest) {
    /**
     * シングルトンを返す。
     */
    return new CacheStoreModel({
        store: new CacheTreeRest({
            target: '/api/organizations/depts/dept4DisInfoShare'
        })
    });
});
