define([
    'module',
    'dojo/_base/declare',
    'dojo/dom-style',
    'dojo/text!./templates/IslandPanel.html',
    'dojo/topic',
    'idis/view/_IdisWidgetBase'
    // 以下、変数で受けないモジュール
], function(module, declare, domStyle, template, topic, _IdisWidgetBase) {
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase, {
        // テンプレート文字列
        templateString: template,

        // ウィジェットのルート要素に付与されるCSSクラス
        baseClass: 'map-IslandPanel',

        /**
         * 島しょ部移動先情報
         */
        ISLAND_INFO: [{
            // 壱岐・対馬
            latlng: [34.2140734536726, 129.3035888671875],
            zoom: 9
        }, {
            // 五島本島
            latlng: [32.8932109, 128.7790612],
            zoom: 9
        }],

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * 選択肢がクリックされた場合の動作
         * @param {MouseEvent} evt クリック・イベント
         */
        onClickIsland: function (evt) {
            var isLandInfo = this.ISLAND_INFO[Number(evt.target.id)];
            topic.publish(module.id + '::moveToIsland', {latlng: isLandInfo.latlng, zoom: isLandInfo.zoom});
        },

        onClosePanel: function () {
            domStyle.set(this.panelOpend, 'display', 'none');
            domStyle.set(this.panelClosed, 'display', 'block');
        },
        onOpenPanel: function () {
            domStyle.set(this.panelOpend, 'display', 'block');
            domStyle.set(this.panelClosed, 'display', 'none');
        }
    });
});
