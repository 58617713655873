/**
 * <モジュールの説明>
 * @module app/view/page/<ウィジェット名>
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/json',
    'dojo/text!./templates/ChatHistoryFormDialog.html', // テンプレート文字列
    'idis/view/page/_PageBase', // 共通基底クラス
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'dijit/form/RadioButton',
    'app/view/form/UserOrganizationSelector',
    'dijit/form/Form'
], function(module, declare, lang, Deferred, JSON, template, _PageBase,
    InfoDialog, DialogChain, Loader) {
    /**
     * <クラスの説明>
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */ {
        // テンプレート文字列
        templateString: template,

        constructor: function(){
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);

            this.organizationSelector.restoreRefresh();
        },

        startup:function(){
            this.inherited(arguments);
        },

        initDialog: function(args) {
            this.groupId.set('value', args.groupId);
            this.groupName.set('value', args.groupName);
            this.userId.set('value', args.userId);
            this.userName.set('value', args.userName);
            this.organizationSelector.set('value', args.organization);
            this.sendDatetimeFrom.set('value', args.sendDatetimeFrom);
            this.sendDatetimeTo.set('value', args.sendDatetimeTo);
        },

        onOutputExcelButtonClick : function() {
            var fileType = 'excel';
            this.onOutputButtonClick(fileType);
        },

        onOutputPdfButtonClick : function() {
            var fileType = 'pdf';
            this.onOutputButtonClick(fileType);
        },


        // 帳票出力を行う前に入力チェックを行う
        onOutputButtonClick : function(fileType) {

            if (!this.form.validate()) {
                return false;
            }

            // 選択された帳票種別によって分岐
            var groupId = this.form.get('value').groupId;
            var groupName = this.form.get('value').groupName;
            var userId = this.form.get('value').userId;
            var userName = this.form.get('value').userName;
            var organization = this.form.get('value').organization;
            if (organization) {
                organization = organization.substr(1,6);
            }

            var sendDatetimeFromDate = this.sendDatetimeFrom._date.displayedValue;
            var sendDatetimeFromTime = this.sendDatetimeFrom._time.displayedValue;
            var sendDatetimeToDate = this.sendDatetimeTo._date.displayedValue;
            var sendDatetimeToTime = this.sendDatetimeTo._time.displayedValue;

            if (!this.sendDatetimeFrom.validate() || !this.sendDatetimeTo.validate() || 
                sendDatetimeFromDate && !sendDatetimeFromTime || !sendDatetimeFromDate && sendDatetimeFromTime ||
                sendDatetimeToDate && !sendDatetimeToTime || !sendDatetimeToDate && sendDatetimeToTime) {
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '日時が選択されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
            } else {
                // YYYYMMDD形式に変換
                sendDatetimeFromDate = sendDatetimeFromDate.replace(/\//g,'');
                sendDatetimeToDate = sendDatetimeToDate.replace(/\//g,'');
                // hhmm形式に変換
                sendDatetimeFromTime = sendDatetimeFromTime.replace(':', '');
                sendDatetimeToTime = sendDatetimeToTime.replace(':', '');
                // YYYYMMDDhhmm形式に変換
                var sendDatetimeFrom = sendDatetimeFromDate + sendDatetimeFromTime;
                var sendDatetimeTo = sendDatetimeToDate + sendDatetimeToTime;

                this.downloadFile(groupId, groupName, userId, userName, organization, 
                    sendDatetimeFrom, sendDatetimeTo, fileType);
            }

        },

        // 帳票出力
        downloadFile : function(groupId, groupName, userId, userName, organization, 
            sendDatetimeFrom, sendDatetimeTo, fileType) {
            var reqid = 'CHAT_HISTORY_EX';
            var fileName = 'ChatHistory';
            var paramList = [];
            if (groupId !== '') {
                paramList.push({key: 'groupId', value: groupId});
            }
            if (groupName !== '') {
                paramList.push({key: 'groupName', value: groupName});
            }
            if (userId !== '') {
                paramList.push({key: 'userId', value: userId});
            }
            if (userName !== '') {
                paramList.push({key: 'userName', value: userName});
            }
            if (organization !== '') {
                paramList.push({key: 'organization', value: organization});
            }
            if (sendDatetimeFrom !== '') {
                paramList.push({key: 'sendDatetimeFrom', value: sendDatetimeFrom});
            }
            if (sendDatetimeTo !== '') {
                paramList.push({key: 'sendDatetimeTo', value: sendDatetimeTo});
            }

            fileName = fileType === 'excel' ? fileName + '.xlsx' :
                                              fileName + '.pdf';

            var data4xoblosDownload = {
                fileName: fileName,
                reqid: reqid,
                fileType: fileType,
                paramList: paramList
            };

            var promise = this.download(data4xoblosDownload).then(lang.hitch(this, function() {
                this.infoDialog = new InfoDialog({
                    title : 'ダウンロード完了',
                    content : 'ダウンロードが完了しました。'
                });
                this.infoDialog.show();

            }), lang.hitch(this, function(error) {
                console.log(error);
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : 'エラーが発生しました。管理者にお問い合わせください。'
                });
                this.infoDialog.show();
            }));

            Loader.wait(promise);
        },

        // 帳票のダウンロード
        download: function(data) {
            var deferred = new Deferred();

            var xhr = new XMLHttpRequest();
            xhr.open('POST', '/api/xoblos/download', true);
            xhr.responseType = 'arraybuffer';
            xhr.setRequestHeader( 'Content-Type', 'application/json' );
            xhr.onload = function() {

                // エラー時は処理を止める
                if(xhr.status !== 200){
                    deferred.reject('status error:'+ xhr.status);
                    return;
                }

                var arrayBuffer = this.response;
                var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});
                var baseFileName = '指示共有機能履歴.xlsx';
                var fileName = baseFileName.replace(/^.*"(.*)"$/, '$1');

                // IE10+
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    // 擬似的にAタグを作成
                    var link = document.createElement('a');
                    link.style = 'display: none';
                    document.body.appendChild(link);

                    // AタグのURLにバイナリデータをセット
                    var url = window.URL.createObjectURL(blob);
                    link.href = url;

                    // ファイル名をセット
                    link.download = fileName;

                    // 擬似的にリンクをクリック
                    link.click();
                    // 参照を解放
                    window.URL.revokeObjectURL(url);
                    link.remove();
                }

                deferred.resolve();

            };
            xhr.send(JSON.stringify(data));

            return deferred.promise;
        },

        /**
         * キャンセルボタン押下
         */
        onCancel:function(){
            this.getParent().hide();
        }
    });
});
