/**
 * 招集メール抑止・解除を管理するモデル
 * @module app/model/ConvoMail
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/store/Memory',
    'idis/service/Requester'
], function(module, declare, lang, Deferred, Memory, Requester) {

    // 招集メール自動配信抑止・抑止解除APIのエンドポイント
    var API_ENDPOINT = '/api/disasters/deterrence';

    // 災害自動登録抑止・抑止解除APIのエンドポイント（災害種別ごと）
    // var API_ENDPOINT_LIST = '/api/disasters/deterrencelist';

    var API_ENDPOINT_RAINYSEAZON = '/api/disasters/rainySeason';

    // モジュール定義
    var Disaster = declare(module.id.replace(/\//g, '.'), null, {

        /**
         * 抑止中か抑止解除中かのステータスを取得する
         */
        getSuppressInfo: function() {
            var deferred = new Deferred();
            // APIコール
            Requester.get(API_ENDPOINT).then(lang.hitch(this, function(data) {
                console.log('### DATA', data);

                deferred.resolve(data);

            }), function(error) {
                deferred.reject(error);
            });
            // プロミスを返却
            return deferred.promise;
        },

        /**
         * 抑止中か抑止解除中かのステータスを災害種別ごとにリストで取得する
         */
        getSuppressInfoList: function() {
            var deferred = new Deferred();
            // APIコール
            // Requester.get(API_ENDPOINT_LIST).then(lang.hitch(this, function(data) {
            Requester.get(API_ENDPOINT + '/list').then(lang.hitch(this, function(data) {
                console.log('### DATA', data);

                deferred.resolve(data);

            }), function(error) {
                deferred.reject(error);
            });
            // プロミスを返却
            return deferred.promise;
        },

        /**
         * 抑止・抑止解除のステータスを更新する
         */
        suppress: function(flg) {
            var deferred = new Deferred();
            if (flg) {
                // APIコール
                Requester.post(API_ENDPOINT, {}).then(lang.hitch(this, function(data) {
                    // 返却値は今の所ないがとりあえずセット
                    deferred.resolve(data);
                }), function(error) {
                    deferred.reject(error);

                });
            } else {
                // APIコール
                Requester.put(API_ENDPOINT, {}).then(lang.hitch(this, function(data) {
                    // 返却値は今の所ないがとりあえずセット
                    deferred.resolve(data);
                }), function(error) {
                    deferred.reject(error);

                });
            }
            // プロミスを返却
            return deferred.promise;
        },

        /**
         * 抑止・抑止解除のステータスを更新する（災害種別指定）
         */
        suppressByType: function(flg, type) {
            var deferred = new Deferred();
            if (flg) {
                // APIコール
                Requester.post(API_ENDPOINT + '/type', {data: type}).then(lang.hitch(this, function(data) {
                    // 返却値は今の所ないがとりあえずセット
                    deferred.resolve(data);
                }), function(error) {
                    deferred.reject(error);

                });
            } else {
                // APIコール
                Requester.put(API_ENDPOINT + '/type', {data: type}).then(lang.hitch(this, function(data) {
                    // 返却値は今の所ないがとりあえずセット
                    deferred.resolve(data);
                }), function(error) {
                    deferred.reject(error);

                });
            }
            // プロミスを返却
            return deferred.promise;
        },
        
        /**
         * 梅雨モードか否かのステータスを取得する
         */
        getRainySeasonInfo: function() {
            var deferred = new Deferred();
            // APIコール
            Requester.get(API_ENDPOINT_RAINYSEAZON).then(lang.hitch(this, function(data) {
                console.log('### DATA', data);

                deferred.resolve(data);

            }), function(error) {
                deferred.reject(error);
            });
            // プロミスを返却
            return deferred.promise;
        },

        /**
         * 梅雨モードのステータスを更新する
         */
        rainySeason: function(flg) {
            var deferred = new Deferred();
            if (flg) {
                // APIコール
                Requester.post(API_ENDPOINT_RAINYSEAZON, {}).then(lang.hitch(this, function(data) {
                    // 返却値は今の所ないがとりあえずセット
                    deferred.resolve(data);
                }), function(error) {
                    deferred.reject(error);

                });
            } else {
                // APIコール
                Requester.put(API_ENDPOINT_RAINYSEAZON, {}).then(lang.hitch(this, function(data) {
                    // 返却値は今の所ないがとりあえずセット
                    deferred.resolve(data);
                }), function(error) {
                    deferred.reject(error);

                });
            }
            // プロミスを返却
            return deferred.promise;
        }
    });

    // シングルトン
    return new Disaster();

});