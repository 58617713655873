/**
 * 住所情報地図表示新規登録ダイアログ
 * @module app/view/page/GeoplotRegisterDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/on',
    'idis/service/iframe',
    'dojo/text!./templates/GeoplotRegisterDialog.html',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'idis/model/UserInfo',
    // 以下、変数として受け取らないモジュール
    'idis/view/form/Button',
    'dijit/form/CheckBox',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/form/Textarea',
    'dojox/form/Uploader',
    'dijit/form/ValidationTextBox',
    'dijit/TitlePane',
    '../view/form/LayerDirectorySelector',
    '../view/form/OrganizationSelector',
    '../view/form/PubStatusSelector',
    '../draw/DrawSelectIconDialog'
], function(module, declare, lang, domStyle, on, iframe,
    template, InfoDialog, _PageBase, Loader, UserInfo) {
    /**
     * 住所情報地図表示新規登録ダイアログ
     * @class GeoplotRegisterDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/GeoplotRegisterDialog~GeoplotRegisterDialog# */ {
        // テンプレート文字列
        templateString: template,

        // icon用imageまでの相対パス
        _defaultIconPath: '/images/draw/icon/',

        _iconUrl: null,

        startup: function() {
            // 新規フォルダ記入エリアの表示/非表示を制御する
            this.own(on(this.newLayerFlg, 'change', lang.hitch(this, function(evt) {
                if(evt) {
                    this.newLayer.set('style', {display: ''});
                    this.newLayer.set('value', '');
                } else {
                    this.newLayer.set('style', {display: 'none'});
                }
            })));
            this.tmpCSVFileName.set('value', '');
            this.tmpCSVFilePath.set('value', '');
            // 公開範囲選択セレクターを生成
            // デフォルトの公開範囲を全体(4)にしておく
            this.pubStatus.createSelector('4');
        },

        iconSelect: function(){
            this.iconSelectDialog.show();
        },

        /**
         * データ格納領域を初期化し、データを設定する
         */
        setPage: function(){
            // ログインユーザの組織コードを取得する
            var orgCd = (UserInfo.getOrganization().unitCd ? 'U' + UserInfo.getOrganization().unitCd :
            UserInfo.getOrganization().sectCd ? 'S' + UserInfo.getOrganization().sectCd :
            UserInfo.getOrganization().deptCd ? 'D' + UserInfo.getOrganization().deptCd : '');

            // ログインユーザの組織情報を報告部署に設定する
            this.organization._setValueAttr(orgCd);

            this.tmpCSVFileName.set('value', '');
            this.tmpCSVFilePath.set('value', '');
            // 表示ズームレベルの初期値を設定する
            this.minZoom.set('value', '8');
            this.maxZoom.set('value', '18');
            // アイコンの初期化
            var iconUrl = this._defaultIconPath + '080.png';
            this.selectedIcon.src = iconUrl;
            this._iconUrl = iconUrl;

            var onChangeIcon = null;
            onChangeIcon = on(this.iconSelectDialog.inner.iconSelectDone, 'click',
                lang.hitch(this, function(){
                    // 初回だけデータがUndefinedになる対策
                    if (!!this.iconSelectDialog.selectedIcon) {
                        iconUrl = this._defaultIconPath + this.iconSelectDialog.selectedIcon.name;
                        this.selectedIcon.src = iconUrl;
                        this._iconUrl = iconUrl;
                    }
            }));
        },

        /**
          * 登録を実行する
          */
        regist: function() {
            // 既にアップロード済のファイルを送信対象から外す為、一時的にdisabledにする
            this.geoFile.set('disabled', true);

            // formに定義されている情報を設定する
            var sendData = this.form.get('value');
            sendData.minZoom = this.minZoom.get('value');
            sendData.maxZoom = this.maxZoom.get('value');
            sendData.pubSectCd = '';
            if (!this.newLayerFlg.checked) {
                // 新規フォルダ作成にチェックが入っていなければ、newLayerをセットしない。
                sendData.newLayer = '';
            }
            if (this._iconUrl) {
                sendData.iconUrl = this._iconUrl;
            } else {
                // 選択されていない場合デフォルトのアイコンURLをセットする
                sendData.iconUrl = this._defaultIconPath + '080.png';
            }
            try {
                if (this.form.validate() && this.isGeofileRegisterValid(sendData)) {
                    this.emit('send', {
                        value: sendData
                    });
                }
            } catch (e) {
                console.error(e);
            }

            // 一時的にdisabledにしたアップロード済のファイルを解除する
            this.geoFile.set('disabled', false);
            return false;
        },

        /**
        * 選択されたファイルをアップロードする（csvファイル）
        **/
        loadFile: function() {
            // ファイルが空の場合は処理を中断する
            if(this.geoFile._files.length === 0) {
                return;
            }
            // ファイルの拡張子が.csv以外の場合は処理を中断する
            var fileName = this.geoFile._files[0].name;
            console.debug('file change');
            console.debug(fileName);
            if(fileName.indexOf('.csv') === -1){
                InfoDialog.show('エラー',
                                '選択したファイルが適切ではありません。<br>' +
                                '.csvの拡張子のファイルを選択して下さい。');
                this.geoFile.reset();
                return;
            }

            var self = this;

            // アップロードファイル以外の要素を送信対象からはずす
            this.setForm4UploadFile();
            this.geoFile.set('disabled', false);

            // ファイルがonloadされたときにサーバーに一時保存する
            var promise = iframe.post('/api/geoplot/uploadFile', {
                form: this.form.id,
                handleAs: 'json'
            }).then(function(data) {
                console.debug(data);
                // formを戻す
                self.resetForm4UploadFile();
                // アップロードファイル情報を設定する
                self.setUploadFileInfo('1', data);
            }, function(error) {
                console.debug(error);
                //formを戻す
                self.resetForm4UploadFile();
                //uploaderをリセット
                self.geoFile.reset();
                self.chain.infoError(error);
            });
            //ローダーの表示
            Loader.wait(promise);
        },
        /**
         * ファイルアップロードの為、他のフォーム要素を一時的にdisabledにする
         */
        setForm4UploadFile: function() {
            this.newLayer.set('disabled', true);
            this.layerName.set('disabled', true);
            this.maxZoom.set('disabled', true);
            this.description.set('disabled', true);
            this.geoFile.set('disabled', true);
            this.tmpCSVFileName.set('disabled', true);
            this.tmpCSVFilePath.set('disabled', true);
        },
        /**
         * ファイルアップロードの為、一時的にdisabledした要素を解除する
         */
        resetForm4UploadFile: function() {
            this.newLayer.set('disabled', false);
            this.layerName.set('disabled', false);
            this.geoFile.set('disabled', false);
            this.maxZoom.set('disabled', false);
            this.description.set('disabled', false);
            this.tmpCSVFileName.set('disabled', false);
            this.tmpCSVFilePath.set('disabled', false);
        },
        setUploadFileInfo: function(type, data){
            this.tmpCSVFileName.set('value', data.tmpCSVFileName);
            this.tmpCSVFilePath.set('value', data.tmpCSVFilePath);
        },
        /**
         * 入力値をチェックする
         */
        isGeofileRegisterValid: function(data) {
            if (!data.parentLayerId || data.parentLayerId === '') {
                InfoDialog.show('入力エラー', '格納先を選択してください。');
                return false;
            }
            if (this.newLayerFlg.checked && (!data.newLayer || data.newLayer === '')) {
                InfoDialog.show('入力エラー', '作成するフォルダの名前を入力してください。');
                return false;
            }
            if (!data.tmpCSVFileName || data.tmpCSVFileName === '') {
                InfoDialog.show('入力エラー', '登録ファイルを選択してください。');
                return false;
            }
            if (!data.pubStatus || data.pubStatus === '') {
                InfoDialog.show('入力エラー', '公開範囲を選択してください。');
                return false;
            }
            if (!data.minZoom || data.minZoom === '' || !data.maxZoom || data.maxZoom === '') {
                InfoDialog.show('入力エラー', '表示ズームレベルを選択してください。');
                return false;
            }
            if (Number(data.minZoom) > Number(data.maxZoom)) {
                InfoDialog.show('入力エラー', '表示ズームレベルの範囲が不適切です。');
                return false;
            }
            return true;
        }
    });
});
