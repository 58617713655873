define([
    // ここに書く
    'app/broadnotify/BroadnotifyPage',
    'app/broadnotify/BroadnotifyTemplateGridPage',
    'app/broadnotify/BroadnotifyDetailPage',
    'app/broadnotify/TerminalListPage',
    'app/broadnotify/BroadnotifyGroupListPage',
    'app/broadnotify/BroadnotifyGroupDetailPage',
    'app/broadnotify/BroadnotifyGroupRegisterPage',

    // ここまで
], function () { });