/**
 * JSONファイルを参照するdstore互換ストア。
 * @module idis/store/JsonFileMemory
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dstore/RequestMemory'
], function(module, array, declare, RequestMemory) {
    /**
     * JSONファイルに対してリクエストを行い、404だった場合は空データとみなす。
     * 404の場合も通常通りエラーとする場合はこのクラスではなくdstore/RequestMemoryを使うこと。
     * @class JsonFileMemory
     * @extends module:dstore/RequestMemory~RequestMemory
     */
    return declare(RequestMemory, {
        /**
         * 要素加工関数。
         * 元データを加工する場合に指定する。
         * @type {function}
         */
        itemMapper: null,

        // リクエストを実施する
        _issueFetchRequest: function () {
            return this.inherited(arguments).otherwise(function(err) {
                if (err && err.response && err.response.status === 404) {
                    // 404を受け取った場合に限り、空データだったことにして返す
                    return '{"items":[],"total":0}';
                } else {
                    // それ以外の場合は通常通りエラーとする
                    throw err;
                }
            });
        },

        /**
         * 受け取った返り値をパースする。
         */
        parse: function() {
            // JSONオブジェクトとしてパースする
            var result = this.inherited(arguments);
            // 加工関数が指定されている場合は各要素を加工
            if (this.itemMapper) {
                result.items = array.map(result.items, this.itemMapper, this);
            }
            return result;
        }
    });
});
