define([
    'leaflet',
    './drawLocalExtendExtend'
], function(leaflet, drawLocalExtendExtend) {
    var polylineTooltipText = drawLocalExtendExtend.draw.handlers.freehandPolyline.tooltip;

    var FreeHandPolyline = leaflet.Draw.FreeHandPolyline = leaflet.Draw.Polyline.extend({
        statics: {
            TYPE: 'freehand_polyline'
        },

        initialize: function (map, options) {
            leaflet.Draw.Polyline.prototype.initialize.call(this, map, options);
            this.type = leaflet.Draw.FreeHandPolyline.TYPE;
        },

        addHooks: function () {
            leaflet.Draw.Polyline.prototype.addHooks.call(this);
            if (this._map) {
                this._map.off('click', this._onTouch, this)
                .on('touchstart', this._onMouseDown, this)
                .on('touchend', this._onMouseUp, this)
                .on('touchmove', this._onTouchMove, this);
                this._mapDraggable = this._map.dragging.enabled();

                if (this._mapDraggable) {
                    this._map.dragging.disable();
                }
            }
        },

        removeHooks: function () {
            leaflet.Draw.Polyline.prototype.removeHooks.call(this);
            if (this._map) {
                this._map.on('click', this._onTouch, this)
                .off('touchstart', this._onMouseDown, this)
                .off('touchend', this._onMouseUp, this)
                .off('touchmove', this._onTouchMove, this);
                if (this._mapDraggable) {
                    this._map.dragging.enable();
                }
            }
        },

        _onMouseDown: function (e) {
            this._isDrawing = true;
            this._startLatLng = e.latlng;
            this._startDrawing = true;
            this._startPointX = e.originalEvent.clientX;
            this._startPointY = e.originalEvent.clientY;
        },

        _onMove: function (e) {
            var newPos = e.layerPoint,
                latlng = e.latlng;

                // Save latlng
                // should this be moved to _updateGuide() ?
                this._currentLatLng = latlng;

                this._updateTooltip(latlng);

                // Update the guide line
                this._updateGuide(newPos);

                // Update the mouse marker position
                this._mouseMarker.setLatLng(latlng);
        },

        _onMouseMove: function (e) {
            var originalEvent;

            //TODO  指定の色、幅、透過率をセット
            //d16SetDrawDefaultOption(this.getObj(this.constructor.TYPE).options.shapeOptions);
            //おそらくDefaultがExtendもとのStyleOptionを持っているので、無理に拡張しなくても

            this._onMove(e);
            if (this._isDrawing) {
                originalEvent = e.originalEvent;

                this._mouseDownOrigin = leaflet.point(this._startPointX, this._startPointY);
                //			this._mouseDownOrigin = leaflet.point(originalEvent.clientX, originalEvent.clientY);
                if (this._mouseDownOrigin) {
                    // We detect clicks within a certain tolerance, otherwise let it
                    // be interpreted as a drag by the map
                    var distance = leaflet.point(e.originalEvent.clientX, e.originalEvent.clientY)
                    .distanceTo(this._mouseDownOrigin);
                    if (this._startDrawing || Math.abs(distance) > 20 * (window.devicePixelRatio || 1)) {
                        this.addVertex(e.latlng);
                        // 描き始めのフラグをオフにする
                        this._startDrawing = false;
                        // 距離計測のポイント位置を現在時点に変更
                        this._startPointX = e.originalEvent.clientX;
                        this._startPointY = e.originalEvent.clientY;
                    }
                }
                this._mouseDownOrigin = null;
            }
            //leaflet.DomEvent.preventDefault(e.originalEvent);
        },

        _onTouchMove: function (e) {
            this._onMove(e);
            if (this._isDrawing) {
                this.addVertex(e.latlng);
            }
        },

        _onMouseUp: function () {
            if (this._isDrawing) {
                this._finishShape();
            }
            this._isDrawing = false;
        },

        _getTooltipText: function () {
            var showLength = this.options.showLength, labelText, distanceStr;

            if (this._markers.length === 0) {
                labelText = {
                    text: polylineTooltipText.start
                };
            } else {
                distanceStr = showLength ? this._getMeasurementString() : '';

                labelText = {
                    text: polylineTooltipText.end,
                    subtext: distanceStr
                };
            }
            return labelText;
        }
    });

    return FreeHandPolyline;
});
