define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'idis/view/grid/IdisGrid'
], function(module, declare, lang, locale, IdisGrid) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--chatShare',

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            { field: 'user', label: 'ユーザ名', sortable: false,
                formatter: lang.hitch(this, function(item){
                    return item.userName || '-';
                })
            },
            { field: 'user', label: '所属', sortable: false,
                formatter: lang.hitch(this, function(item){
                    return item.departmentName || '-';
                })
            },
            { field: 'isUnread', label: '既読状況', sortable: false,
                formatter: lang.hitch(this, function(item){
                    if (item === true) {
                        return '未読';
                    } else if (item === false) {
                        return '既読';
                    }
                    return '-';
                }),
                className: lang.hitch(this, function (item) {
                    // ヘッダーの場合はclassNameを返さない
                    if (!item) {
                        return;
                    }
                    if (item.isUnread === true) {
                        // 未読
                        return 'column-color-yellow';
                    }
                })
            },
            { field: 'readTimestamp', label: '確認時刻', sortable: false,
                formatter: lang.hitch(this, function(item){
                    if (item) {
                        return locale.format(new Date(item));
                    }
                    return '-';
                })
            }
        ]
    });
});
