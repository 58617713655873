/**
 * 緊急通知通知グループの通知対象ユーザ追加ダイアログ用モジュール。
 * @module app/broadnotify/BroadnotifyAddGroupDialog
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/text!./templates/BroadnotifyAddGroupDialog.html', // テンプレート文字列
    'idis/view/page/_PageBase', // 共通基底クラス

    // 以下、変数から参照されないモジュール
    'idis/view/form/Button',
    'app/view/form/BroadnotifyTerminalMultiChecker'
], function(module, declare, template, _PageBase) {
    /**
     * 緊急通知グループ詳細画面・ユーザ追加ダイアログ。
     * @class BroadnotifyAddGroupDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/_PageBase~_PageBase# */
        {
            // テンプレート文字列
            templateString: template,

            store: null,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--broadnotify',
                        
            /**
             * ユーザ追加ボタンが押下された際の挙動。
             */
            onAddGroupButton: function() {
                try {
                    var formValue = this.form.get('value');
                    this.emit('register', {terminalId: formValue.dpDistributionGroupCd});
                } catch(error) {
                    // 処理失敗時はエラーログを出力
                    console.error(error);
                }
                
                return false;
            },
            
            /**
             * キャンセルボタン
             */
            onCancelButton: function() {
                // form情報のリセット
                this.form.reset();
                // ダイアログを閉じる
                this.leave();
            }
        });
});
