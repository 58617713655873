/**
 * レイヤー情報を単に出力する詳細画面パーツ。
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/dom-geometry',
    'dojo/text!./templates/DumpDetail.html',
    'dojox/lang/functional/object',
    'idis/view/_IdisWidgetBase'
    // 以下、変数で受けないモジュール
], function(module, declare, domGeom, template, df, _IdisWidgetBase) {
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase, {
        // テンプレート文字列
        templateString: template,

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
            var html = ['<table>'];
            df.forIn(this.item, function(value, key) {
                html.push('<tr><td>');
                html.push(key);
                html.push('</td><td>');
                html.push(value);
                html.push('</td></tr>');
            }, this);
            html.push('</table>');
            this.domNode.innerHTML = html.join('');
        }
    });
});

