/**
 * お知らせ関連のフォーマッター
 * @module app/notice/NoticeFormatter
 */
define([
    'module',
    'idis/util/StringUtils'
], function(module, StringUtils) {
    /**
     * 指定されたURLとタイトルからリンク用HTML文字列を生成して返す。
     * @param {string} url リンクのURL
     * @param {string} title リンクのタイトル
     * @returns {string} リンクを表すHTML文字列
     */
    function _toLinkTag(url, title) {
        return '<a href="' + url + '" target="_blank">' + StringUtils.escape(title) + '</a>';
    }

    /**
     * 指定された文字列内のURLをリンク文字列に変換して返す。
     * 単純なURLのほか、[title](URL)形式のMarkdownも受け付ける。
     * URLとして以下の形式の文字列をサポートする（xは「空白文字」、「"」、「\」を除く文字）。
     * - [scheme]://xxx
     * - ./xxx
     * - ../xxx
     * @param {string} value
     * @returns {string} 受け取った文字列のURL部分をリンクに置き換えた文字列
     */
    function _format(value) {
        var result = [];
        var lastIndex = 0;
        // '[title](URL)' または 'URL' にマッチする正規表現
        var regexp = /\[([^\]]+)\]\(((?:[a-z]+:\/|\.?\.)?\/[^\s"\\)]*)\)|((?:[a-z]+:\/|\.?\.)\/[^\s"\\]*)/g;
        // 受け取った文字列に対し先頭から繰り返しマッチングを行い、
        // マッチした範囲をリンクに、マッチしなかった範囲をエスケープ済み文字列にフォーマットする。
        var match = regexp.exec(value);
        while (match) {
            // 前回と今回の結果の隙間
            result.push(StringUtils.escape(value.slice(lastIndex, match.index)));
            // マッチしたリンクからタグ用文字列を生成
            result.push(match[3] ? _toLinkTag(match[3], match[3]) : _toLinkTag(match[2], match[1]));
            // 今回のマッチ終端位置を記録
            lastIndex = regexp.lastIndex;
            // 前回終了地点を開始地点として再実行
            match = regexp.exec(value);
        }
        // 最後のマッチ終端位置から文字列終端位置まで
        result.push(value.slice(lastIndex));
        return result.join('');
    }

    return {
        format: _format
    };
});
