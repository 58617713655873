/**
 * JsonLayerModelを効率的に扱うdijit/tree/model
 * @module idis/store/JsonLayerModel
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/when',
    'dijit/tree/ObjectStoreModel',
    '../error/InvalidArgumentException',
    './_FullNameModelMixin'
], function(module, array, declare, lang, when, ObjectStoreModel, InvalidArgumentException, _FullNameModelMixin) {
    /**
     * 初回に全データを取得し、以降ルート要素と親子関係はキャッシュを利用するdijit/tree/model準拠クラス。
     * @class JsonLayerModel
     * @extends module:dijit/tree/model
     * @param {Object} kwArgs
     * @param {Store} kwArgs.store dojo/storeオブジェクト
     * @param {number|string} [kwArgs.rootId=0] ルート要素の識別子
     * @param {function} kwArgs.filter 指定された場合、条件を満たさない要素は存在しないものとして扱う
     */
    return declare([ObjectStoreModel, _FullNameModelMixin],
        /** @lends module:idis/model/JsonLayerModel~JsonLayerModel# */ {
        /**
         * ルート要素の識別子
         * @type {number|string}
         */
        rootId: null,

        /**
         * 表示判定用関数。
         * @type {function}
         */
        filter: null,

        constructor: function(kwArgs) {
            kwArgs = kwArgs || {};
            // 引数チェック
            if (!kwArgs.store) {
                throw new InvalidArgumentException(module.id + '::constructor: Property "store" must be specified.');
            } else {
                // store要素の最新化を監視
                this.own(this.store.on('refresh', lang.hitch(this, this.onItemRefresh)));
            }
            if (kwArgs.rootId === null || kwArgs.rootId === void 0) {
                throw new InvalidArgumentException(module.id + '::constructor: Property "rootId" must be specified.');
            }
        },

        /**
         * 要素が最新化された際に呼び出され、このモデルを監視しているツリーの情報を更新する。
         * @param {Event} evt 更新イベント
         * @param {Object} evt.item 更新された要素
         */
        onItemRefresh: function(evt) {
            var item = evt.item;
            var id = this.store.getIdentity(item);
            // 要素の更新を通知
            this.onChange(item);
            // 既に子要素一覧を取得済みの要素なら子要素一覧を更新
            var cache = this.childrenCache[id];
            if (cache) {
                delete this.childrenCache[id];
                this.getChildren(item, lang.hitch(this, function(children) {
                    // 子要素一覧の更新を通知
                    this.onChildrenChange(item, children);
                }));
            }
        },

        /**
         * 子要素一覧を受け取り、filter関数がtrueを返すものに絞り込んで返す。
         * filter関数が設定されていない場合は全要素をそのまま返す。
         * @param {Object[]} children 要素の配列
         * @returns {Object[]} filter関数を満たす要素の配列
         */
        _filterChildren: function(children) {
            return this.filter ? array.filter(children, this.filter, this) : children;
        },

        /**
         * 指定されたツリー要素の子要素一覧を解決用関数に渡す。
         * @param {Object} item ツリー要素
         * @param {function} onComplete 解決時に子要素一覧を引数として呼ばれる
         * @param {function} onError 失敗時にエラー内容を引数として呼ばれる
         */
        getChildren: function(item, onComplete, onError) {
            var id = this.store.getIdentity(item);
            // キャッシュが無ければ取得（childrenCache自体はdijit/tree/ObjectStoreModelで定義）
            if (!this.childrenCache[id]) {
                // フィルター済み子要素一覧を返すPromiseをキャッシュに設定
                this.childrenCache[id] = this.store.getChildren(item).then(lang.hitch(this, this._filterChildren));
            }
            when(this.childrenCache[id]).then(lang.hitch(this, function(children) {
                // キャッシュ内容がPromiseのままなら実体の配列に置き換える
                var cache = this.childrenCache[id];
                if (cache && cache.then) {
                    this.childrenCache[id] = children;
                }
                onComplete(children);
            }), onError);
        },

        /**
         * 指定されたツリー要素の子要素識別子一覧を返す。
         * CheckTreeのチェック状態確認に使用。
         * @param {Object} item ツリー要素
         * @returns {number[]|string[]} 子要素識別子の配列、子要素が存在しない場合は空配列
         */
        getChildrenIds: function(item) {
            return item.children || [];
        },

        /**
         * ルート要素を取得する。
         * @param {function} onItem 解決時にルート要素を引数として呼ばれる
         * @param {function} onError 失敗時にエラー内容を引数として呼ばれる
         */
        getRoot: function(onItem, onError) {
            if (this.root) {
                // キャッシュ済みの場合
                onItem(this.root);
            } else {
                when(this.store.get(this.rootId), lang.hitch(this, function(item) {
                    // 最新レイヤから降順にソートする
                    // item.children.sort(function(a, b) {
                    //     return b - a;
                    // });
                    // 取得した要素をキャッシュする
                    onItem(this.root = item);
                }), onError);
            }
        },

        /**
         * 指定された要素が子要素を持っている可能性があるかどうかを返す。
         * @param {Object} item ツリー要素
         * @returns {boolean} 子要素を持っている可能性がある場合はtrue、それ以外の場合はfalse
         */
        mayHaveChildren: function(item) {
            // 子要素一覧を実体の配列としてキャッシュ済みならその内容で判断（filter済みなので正確）
            // これにより、子要素一覧を取得した結果表示する子要素が無かった場合に
            // idis/view/tree/FolderTree.jsで閉じた状態のフォルダアイコンを表示出来るようになる
            var cache = this.childrenCache[this.store.getIdentity(item)];
            if (cache && !cache.then) {
                return cache.length;
            }
            // キャッシュが無い場合は子要素IDを1つ以上持っているかで判断（除外要素を含む可能性がある）
            return item.children && item.children.length;
        }
    });
});
