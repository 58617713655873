/**
 * グリッド画面のサンプル実装用モジュール。
 * @module app/view/SampleGridPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/SampleGridPage.html',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/store/IdisRest',
    'idis/view/dialog/DialogChain',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'idis/view/form/CheckGroup',
    'idis/view/form/DateInput',
    'idis/view/form/DateTimeInput',
    'idis/view/form/TimeInput',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    '../../view/form/LayerDirectorySelector',
    '../../view/form/OrganizationSelector',
    './SampleGrid',
    './SampleGridRegisterPage',
    './SampleGridDetailPage'
], function(module, declare, lang, template, Router, UserInfo, IdisRest, DialogChain, _PageBase, Loader) {
    /**
     * グリッド画面のサンプル実装。
     * @class SampleGridPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/SampleGridPage~SampleGridPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--sample',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'reportId',
                target: '/api/sample/reports'
            });
            // ダイアログ連鎖を登録
            // 引数に与えたウィジェットのthis.ownを呼び出し、
            // ウィジェットが破棄された際にダイアログ連鎖が破棄されるようになる
            this.chain = DialogChain.get(this);
            // ユーザ情報取得サンプル
            console.log('現在のユーザのID: ' + UserInfo.getId());
        },

        /**
         * グリッドの検索条件を指定された値で更新する。
         * @param {Object} value name属性と値のマッピング
         */
        updateGridQuery: function(value) {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            // 人的被害
            if (value.human.length === 1) {
                // 1つだけ選択されている場合は絞り込み条件に加える
                filter = filter.eq('human', value.human);
            }
            // 被害種別
            if (value.type) {
                filter = filter.eq('type', value.type);
            }
            if (value.organization) {
                filter = filter.eq('organization', value.organization);
            }
            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.grid.set('collection', collection);
        },

        /**
         * 検索ボタンが押されたときに呼び出される。
         * テンプレートHTML内のFormウィジェットに対するdata-dojo-attach-eventでこのイベントを紐付けている。
         * また、同Formウィジェットに対しdata-dojo-attach-pointを指定し、this.formからアクセス出来るようにしている。
         */
        onSubmit: function() {
            try {
                if (this.form.validate()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery(this.form.get('value'));
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 新規登録ダイアログを表示する。
         * テンプレートHTMLでdata-dojo-attach-eventプロパティーを用い、
         * 新規登録ボタンのクリックイベントにこのメソッドを紐付けている。
         */
        showRegisterDialog: function() {
            // ダイアログの最初の子要素が登録画面
            var page = this.dialog.getChildren()[0];
            // 画面上のフォームをリセット
            // （ダイアログ内画面のテンプレートHTMLでdata-dojo-attach-point="form"指定済み）
            page.form.reset();
            // ダイアログを表示
            this.dialog.show();
        },

        /**
         * 詳細ダイアログを表示する。
         * @param {Object} item 参照する行のデータ
         */
        showDetailDialog: function(item) {
            // ダイアログの最初の子要素が詳細画面
            var page = this.detailDialog.getChildren()[0];
            // 画面上のフォームをリセット
            // ダイアログ内画面のテンプレートHTMLでdata-dojo-attach-point="form"指定済み
            page.form.reset();
            // 行データの内容をフォームに反映
            page.form.set('value', item);
            // 詳細ダイアログを表示
            this.detailDialog.show();
        },

        /**
         * 地図を含む画面を表示する。
         */
        showMapPage: function() {
            Router.moveTo('sample/map');
        },

        /**
         * 連鎖ダイアログのサンプル
         */
        showDialogChain: function() {
            // 確認ダイアログを表示 - (1)
            this.chain.confirm(function(chain) {
                // (1)でOK: 確認ダイアログを表示 - (1-1)
                chain.confirm('本当によろしいですか？（キャンセルでエラー）', function(chain) {
                    // (1-2)でOK: 完了ダイアログを表示
                    chain.infoComplete();
                }, function(chain) {
                    // (1-2)でキャンセル: エラー・ダイアログを表示
                    chain.infoError(new Error());
                });
            }, function(chain) {
                // (1)でキャンセル
                // 情報ダイアログを表示 - (1-2)
                // info, infoComplete, infoErrorで第二引数に関数が指定された場合、OK後勝手に閉じない
                chain.info('キャンセルが押されました。', function() {
                    // (1-2)でOK
                    chain.info('情報ダイアログでOKが押されました');
                });
            });
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // 全件表示用のcollectionをグリッドにセットする
            // （テンプレートHTML内のdata-dojo-attach-point="grid"でグリッドをthis.gridに紐付け済み）
            this.grid.set('collection', this.store.filter());
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.grid.on('detailButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                this.showDetailDialog(evt.item);
            }));
        },

        /**
         * 登録画面のフォームが投稿された際の動作を設定する。
         */
        initRegisterPage: function() {
            // 登録ダイアログの最初の子要素が登録画面
            var dialog = this.dialog;
            var page = dialog.getChildren()[0];
            // 登録画面のsendイベントを受け取る
            page.on('send', lang.hitch(this, function(evt) {
                // ダイアログ連鎖は確認ダイアログ用に以下のメソッドを提供する。
                // いずれもOKボタンが押されるとonOKが、キャンセル時はonCancelが呼ばれる。
                // onCancelが登録されない状態でキャンセルされると自動的に閉じる。
                // confirm([message], [title], onOK, [onCancel]): 汎用的な確認ダイアログ
                // confirmAdd([target], onOK, [onCancel]): 追加用の確認文言が設定されたダイアログ
                // confirmPut([target], onOK, [onCancel]): 更新用の確認文言が設定されたダイアログ
                // confirmDel([target], onOK, [onCancel]): 削除用の確認文言が設定されたダイアログ
                // 追加確認ダイアログを表示
                this.chain.confirmAdd(function(chain) {
                    // グリッドが参照するstoreにaddメソッドでデータを登録することで
                    // store生成時にtargetで指定してあったURLのPOSTが呼ばれる
                    var promise = this.store.add(evt.value);
                    // Loader.waitでpromiseの結果待ちが可能
                    Loader.wait(promise).then(function() {
                        // 成功時（POSTの返り値がグリッドの先頭に自動的に追加される）
                        // ダイアログ連鎖は情報ダイアログ用に以下のメソッドを提供する。
                        // いずれもOKボタンが押されるとonOKが呼ばれる。
                        // onOKが登録されない状態でOKされると自動的に閉じる。
                        // info([message], [title], [onOK]): 汎用的な情報ダイアログ
                        // infoComplete([onOK]): 完了時用の文言が設定されたダイアログ
                        // infoError([err], [onOK]): エラー時用のダイアログ（エラー情報を表示する予定）
                        // 確認ダイアログを閉じる
                        chain.infoComplete();
                        // 登録フォーム・ダイアログを閉じる
                        dialog.hide();
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
        },

        /**
         * 詳細画面のフォームが投稿された際の動作を設定する。
         */
        initDetailPage: function() {
            // 詳細ダイアログの最初の子要素が詳細画面
            var dialog = this.detailDialog;
            var page = dialog.getChildren()[0];
            // 詳細画面のsendイベントを受け取る
            page.on('send', lang.hitch(this, function(evt) {
                // グリッドが参照するstoreにputメソッドでデータを登録することで
                // store生成時にtargetで指定してあったURLの末尾にidを足したURLのPUTが呼ばれる
                this.store.put(evt.value).then(function() {
                    // 成功時（PUTの返り値がグリッドの該当行に自動的に反映される）
                    dialog.hide();
                }, function() {
                    // TODO 失敗時
                    console.error('PUT failed');
                });
            }));
        },

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は関連ダイアログも削除する
            // （ダイアログは初期化時にbody直下へ移動するため、明示的に指定しないと消えずに残ってしまう）
            this.own(this.dialog);
            this.own(this.detailDialog);
            // グリッドを初期化する
            this.initGrid();
            // 登録画面内のフォームがsubmitされた際の動作を設定
            this.initRegisterPage();
            // 詳細画面内のフォームがsubmitされた際の動作を設定
            this.initDetailPage();
        }
    });
});

