/**
 * 災害変更ダイアログ画面用モジュール。
 * @module app/view/form/DisasterChangerDialog
 */
define([
	'module',
	'dojo/_base/declare',
	'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/topic',
    'dojo/text!./templates/DisasterChangerDialog.html',
    'app/model/DisasterInfo',
    'idis/view/dialog/InfoDialog',
	'idis/store/IdisRest',
    'idis/view/page/_PageBase',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button',
	'app/view/form/DisasterChangerGrid'
], function(module, declare, lang, domStyle, topic, template, DisasterInfo, InfoDialog, IdisRest, WidgetBase) {

    /**
     * 災害変更ダイアログ
     * @class DisasterChangerDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), WidgetBase,
        /** @lends app/view/Header~Header# */ {

        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'disaster-changer',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        event: 'app/disaster/view/DisasterAdminPage::registered',

        refreshed: 'app/disaster/view/DisasterAdminPage::refreshed',

        /**
         * コンストラクタ
         */
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'disasterId',
                target: '/api/disasters',
                sortParam: 'sort',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });
            // 災害名登録がPubされた際にグリッドを最新の情報に更新する
            topic.subscribe(this.event, lang.hitch(this, function(disasterId) {
                console.log(disasterId);
                this.refresh();
            }));

            // 災害名登録がPubされた際にグリッドを最新の情報に更新する
            topic.subscribe(this.refreshed, lang.hitch(this, function(disasterId) {
                console.log(disasterId);
                this.refresh();
            }));
        },

        /**
         * DOM生成
         */
        buildRendering: function() {
            this.inherited(arguments);
            // グリッドを初期化
            this.initGrid();
        },

        /**
         * 画面生成完了
         */
        startup: function() {
            this.inherited(arguments);
        },

        /**
         * 画面を再表示
         */
        refresh: function() {
            this.borderContainer.resize();
            this.item = null;
            // 最新の災害情報を取得
            DisasterInfo.load().then(lang.hitch(this, function() {
                // 最新の災害IDを退避
                this.current = DisasterInfo.getDisasterId();
                // グリッドを再表示
                this.grid.refresh();
            }));
        },

        /**
         * グリッド初期化
         */
        initGrid: function() {
            this.grid.set('collection', this.store);

            // グリッドの行選択イベント
            this.grid.on('dgrid-select', lang.hitch(this, function(evt) {
                // 選択された行データを退避
                this.item = evt.rows[0].data;
            }));
            // グリッドの再表示完了イベント
            this.grid.on('dgrid-refresh-complete', lang.hitch(this, function(evt) {
                // 最新の災害情報の行を選択状態にする
                evt.grid.collection.forEach(lang.hitch(this, function(item, idx) {
                    if (item.disasterId === parseInt(this.current, 10)) {
                        this.grid.select(this.grid._rows[idx]);
                    }

                }));

            }));
            // 初期表示では「対応中の災害のみ表示」
            this.openOnlyFlg = true;
            this.updateGridQuery(this.form.get('value'));
        },

        /**
         * 災害変更実行
         */
        onSubmit: function() {
            if (!this.item) {
                InfoDialog.show('入力チェック', '災害が選択されていません');
                return false;
            }
            this.emit('update', {
                data: this.item
            });
        },
        // 詳細検索
        onOpenFilterClick: function () {
            // 検索リンク
            domStyle.set(this.openFilterLink, 'display', 'none');
            domStyle.set(this.closeFilterLink, 'display', '');
            domStyle.set(this.searchArea, 'display', '');
            domStyle.set(this.buttonArea, 'display', '');
            this.borderContainer.resize();
        },

        onCloseFilterClick: function () {
            domStyle.set(this.openFilterLink, 'display', '');
            domStyle.set(this.closeFilterLink, 'display', 'none');
            domStyle.set(this.searchArea, 'display', 'none');
            domStyle.set(this.buttonArea, 'display', 'none');
            this.borderContainer.resize();
        },

        // 対応中のみ・完了済含むのラジオボタン値が変更された時の処理
        onChangeOpenOnlyFlg: function (evt) {
            // フラグ値を制御
            if(evt.target.value) {
                if(evt.target.value === '1') {
                    this.openOnlyFlg = true;
                }else{
                    this.openOnlyFlg = false;
                }
                this.updateGridQuery(this.form.get('value'));
            }
        },

        onSearch: function() {
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery(this.form.get('value'));
                }
            } catch (e) {
                console.error(e);
            } finally {
                return false;
            }
        },
        /**
         * グリッドの検索条件を指定された値で更新する。
         * @param {Object} value name属性と値のマッピング
         */
         updateGridQuery: function(value) {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();

            if (value.disasterName) {
                filter = filter.eq('disasterName', value.disasterName);
            }

            // 災害種別
            if(value.disasterType){
                if (value.disasterType ==='01'){
                    filter = filter.eq('waterFlg', value.disasterType);

                } else if (value.disasterType ==='02'){
                    filter = filter.eq('earthquakeFlg', value.disasterType);

                } else if (value.disasterType ==='05'){
                    filter = filter.eq('volcanoFlg', value.disasterType);

                } else if (value.disasterType ==='04'){
                    filter = filter.eq('protectionFlg', value.disasterType);

                } else if (value.disasterType ==='99'){
                    filter = filter.eq('otherFlg', value.disasterType);
                }
            }
            
            if (value.disasterStartDate) {
                filter = filter.eq('disasterStartDate', value.disasterStartDate);
            }

            if (value.disasterEndDate) {
                filter = filter.eq('disasterEndDate', value.disasterEndDate);
            }

            filter = filter.eq('openOnlyFlg', this.openOnlyFlg);

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.grid.set('collection', collection);
        }
    });
});
