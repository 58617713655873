/**
 * ライフライン（停電）表示用ダイアログモジュール。
 * @module app/user/LifelineDetailDialog
 */
define([
	'module',
    'dojo/_base/array',
	'dojo/_base/declare',
    'dojo/dom-construct',
    'dojo/dom-style',
	'dojo/text!./templates/LifelineDetailDialog.html',
    'dojo',
	'./_LifelineDialogBase',
	// 以下、変数で受けないモジュール
], function (module, array, declare, domConstruct, domStyle, template, dojo, _LifelineDialogBase) {

	return declare(module.id.replace(/\//g, '.'), _LifelineDialogBase,
		{
			// テンプレート文字列
			templateString: template,

			/**
			 * buildRendering
			 */
			buildRendering: function () {
				this.inherited(arguments);
			},

			/**
			 * フォームを初期化する。
			 */
			initForm: function (object) {
				this._title = object.title;
				this._contentDescription = object.contentDescription;
				this._reportTimestamp = object.reportTimestamp;
				this._notification = object.notification;
				this._restoreTimestamp = object.restoreTimestamp;
				this._url = object.url;
				this._householdNum = object.householdNum;
				this._districtCondition = object.districtCondition;
				this._areas = object.areas;
				this._publishingOrganizationName = object.publishingOrganizationName;
			},


			/**
			 * ダイアログを初期化する。
			 */
			initDialog: function (object) {

				var lifelineAreasTBody = dojo.byId('lifelineAreasTBody');
				domConstruct.empty(lifelineAreasTBody);

				this.initForm(object);
				this.title.innerHTML = this._title;
				this.contentDescription.innerHTML = this._contentDescription;
				this.reportTimestamp.innerHTML = this._reportTimestamp;
				this.notification.innerHTML = this._notification;
				this.restoreTimestamp.innerHTML = this._restoreTimestamp;
				this.url.innerHTML = this._url;
				this.householdNum.innerHTML = this._householdNum;
				if (this._areas && this._areas.length > 0) {
					array.forEach(this._areas, function(item){
						var tr = domConstruct.create('tr');
						domConstruct.place(domConstruct.create('td', {
							innerHTML: item.name}), tr);
						domConstruct.place(domConstruct.create('td', {
							innerHTML: item.num}), tr);
						domConstruct.place(tr, lifelineAreasTBody);
					});
					domStyle.set(this.area, 'display', 'none');
					domStyle.set(this.areaList, 'display', '');
				} else {
					this.area.innerHTML = 'なし';
					domStyle.set(this.area, 'display', '');
					domStyle.set(this.areaList, 'display', 'none');
				}
				this.publishingOrganizationName.innerHTML = this._publishingOrganizationName;
		},

		});
});
