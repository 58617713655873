/**
 * メールテンプレートの新規登録・詳細ダイアログ用の基底モジュール。
 * @module app/broadnotify/_BroadnotifyTemplateDialogBase
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/control/Router',
    'dojo/dom-style',
    'idis/model/UserInfo',
    'idis/view/page/_PageBase',
    'idis/view/dialog/InfoDialog',
    'idis/store/withEmptyOption',
    'app/model/NotifyType',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/form/SimpleTextarea',
    'idis/view/form/Button',
    'idis/view/form/AclButton'
], function(module, declare, lang, Router, domStyle, UserInfo, _PageBase, InfoDialog, withEmptyOption, NotifyType) {

    return declare(module.id.replace(/\//g, '.'), _PageBase,
    {
        /**
         * constructor
         */
        constructor: function() {
        },
        
        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
            // テンプレート種別選択リストを初期化
            this.notifyTypeSelector.set('store', withEmptyOption(NotifyType));
        },

        /**
         * 入力値の妥当性を検証する。
         */
        validate: function() {
            var formData = this.form.get('value');

            // テンプレート種別 Select
            if (formData.notifyType === '') {
                InfoDialog.show('入力チェック', '通知種別が選択されていません');
                return false;
            }
            // 件名 TextBox
            if (formData.subject === '') {
                InfoDialog.show('入力チェック', 'タイトルが入力されていません');
                return false;
            }
            // 本文 SimpleTextarea
            if (formData.body === '') {
                InfoDialog.show('入力チェック', '本文が入力されていません');
                return false;
            }
            if((!formData.notifyGroupSendId || formData.notifyGroupSendId.length === 0) &&
                (!formData.notifyUserSendId || formData.notifyUserSendId.length === 0)){
                InfoDialog.show('入力チェック', '通知先が選択されていません');
                return false;
            }
            return true;
        }
    });
});