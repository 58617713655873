/**
 * 入力文字数をチェックする入力欄用ウィジェット。
 */
define([
    'module',
    'dojo/_base/declare',
    'dijit/form/ValidationTextBox'
], function(module, declare, ValidationTextBox) {
    return declare(module.id.replace(/\//g, '.'), [ValidationTextBox], {
        /**
         * 指定された制限に従って入力チェック用正規表現文字列を返す。
         * @param {object} constraints 制限
         * @param {number} constraints.min 最小文字数
         * @param {number} constraints.max 最大文字数
         */
        pattern: function(constraints) {
            // （改行を含む）任意のmin以上max以下の文字列にマッチする正規表現文字列
            return ['[\\s\\S]{', constraints.min || 0, ',', constraints.max || '', '}'].join('');
        },

        /**
         * プロパティーの設定が終わった段階で呼ばれるメソッド。
         * 不正な入力値に対し"(min文字以上)(max文字以内)で入力してください。"というメッセージを出すようにする。
         * @override
         */
        postMixInProperties: function() {
            this.inherited(arguments);
            if (this.invalidMessage === '$_unset_$') {
                var message = this.constraints.min ? [this.constraints.min + '文字以上'] : [];
                if (this.constraints.max) {
                    message.push(this.constraints.max + '文字以内');
                }
                if (message.length) {
                    message.push('で入力してください。');
                    this.invalidMessage = message.join('');
                }
            }
        },

        /**
         * DOMノード準備完了後に呼ばれるメソッド。
         * @override
         */
        buildRendering: function() {
            // プロパティーチェック（ウィジェットにIDが付与されるのを待つためこのタイミングで実施）
            if (!this.constraints || !this.constraints.min && !this.constraints.max) {
                console.warn(this.id + ': no constraints specified');
            }
            this.inherited(arguments);
        }
    });
});
