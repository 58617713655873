/**
 * 表示情報のディレクトリーのみ表示する。
 * @module app/model/LayerDirectory
 */
define([
    'idis/store/JsonLayerModel',
    './LayerStore'
], function(JsonLayerModel, LayerStore) {
    /**
     * シングルトンを返す。
     */
    return new JsonLayerModel({
        rootId: 0,
        store: LayerStore,
        filter: function(item) {
            // ディレクトリー（情報種別コードが'T'で始まる）のみ表示する
            return item.infoCategoryCd && item.infoCategoryCd.indexOf('T') === 0;
        }
    });
});

