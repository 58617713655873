/**
 * <ライフライン概況画面>
 *
 * @module app/lifeline/lifelineAdminGrid.js
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/dom',
    'dijit/registry',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'idis/view/form/Button',
    'idis/util/DateUtils',
    'app/config',
    'dojo/on',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer'
], function(module, declare, lang, locale, dom, registry, IdisGrid, helper, Button, DateUtils, config, on) {
    /**
     * <クラスの説明>
     *
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'),IdisGrid,
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */
        {
            columns: [
            {
                label: 'ライフライン種別', field: 'lifelineKind', sortable: false,
                children: [
                    {field: 'category', label: '大項目', sortable: false},
                    {field: 'infoKind', label: '小項目', className: 'admin', sortable: false},
                ]
            },
            {field: 'text', label: '本文', sortable: false},
            {field: 'updateTime', label: '最終更新日時', sortable: true,
                formatter: lang.hitch(this, function(item){
                    return item ? DateUtils.format(DateUtils.parse(item)) : '-';
                })
            },
            {field: 'reportName', label: '報告者名', sortable: false},
            helper.buttonColumn('list', '一覧')
            ]
        }
    );
});
