/**
 * メールテンプレート詳細画面用モジュール。
 * @module app/broadnotify/BroadnotifyTemplateDetailDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/BroadnotifyTemplateDetailDialog.html',
    './_BroadnotifyTemplateDialogBase',
    'idis/service/Requester',
    'idis/view/Loader'
    // 以下、変数から参照されないモジュール
], function(module, declare, lang, domStyle, template, _BroadnotifyTemplateDialogBase, Requester, Loader) {
    /**
     * メールテンプレート詳細画面
     * @class BroadnotifyTemplateDetailDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _BroadnotifyTemplateDialogBase,
        /** @lends module:app/broadnotify/BroadnotifyTemplateDetailDialog~BroadnotifyTemplateDetailDialog# */ {

        /**
         * メールテンプレートID
         */
        _notifyTemplateId: null,
        
        // テンプレート文字列
        templateString: template,
        
        /**
         * constructor
         */
        constructor: function() {
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * フォームを初期化する
         */
        initForm: function(item) {
            
            this._broadnotifyTemplateId = item.broadnotifyTemplateId;
            this.notifyGroupSendIdSelector._refresh();
            
            // コールバック関数内で使用するために参照をコピー
            var self = this;

            // メール配信IDをキーに、配信情報を取得
            Loader.wait(Requester.get('/api/broadnotify/templates/' + this._broadnotifyTemplateId)).then(function(data) {
                // 取得した値をフォームにセット
                self.broadnotifyAdmin.innerHTML = data.admin;
                self.notifyTypeSelector.set('value', data.notifyType);
                self.subject.set('value', data.subject);
                self.body.set('value', data.body);
                self.remarks.set('value', data.remarks);
                var notifyGroupList = data.notifyGroupSendId;
                var notifyUserList = data.notifyUserSendId;
                if(notifyGroupList !== null) {
                    self.notifyGroupSendIdSelector.set('value', notifyGroupList);
                } else {
                    self.notifyGroupSendIdSelector.set('value', []);
                }
                if(notifyUserList !== null) {
                    self.notifyUserSendIdChecker.set('value', notifyUserList);
                } else {
                    self.notifyUserSendIdChecker.set('value', []);
                }

            });
            
        },

        /**
         * メールテンプレートを更新する。
         */
        onSubmit: function() {
            try {
                console.debug('[更新]ボタンがクリックされました');

                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                // 固有のバリデーションを実施
                if(!this.validate()) {
                    return false;
                }
                // 入力値を取得
                var formValues = this.form.get('value');
                formValues.admin = this.broadnotifyAdmin.innerHTML;

                // 保持しておいた主キーを設定する
                formValues.broadnotifyTemplateId = this._broadnotifyTemplateId;

                // updateイベントを発行し、親画面に入力値を渡す
                this.emit('update', {value: formValues});
                
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * メールテンプレートを更新する。
         */
        onDelete: function() {
            try {
                console.debug('[削除]ボタンがクリックされました');

                // deleteイベントを発行し、親画面の処理へ
                // ※イベント名がフォームのonSubmitと異なるイベント名の場合、
                //   emitのパラメータで送っても取得できないため、IDはダイアログの
                //   呼び出し元で保持しているものを使う。
                this.emit('delete');
                
            } catch (e) {
                console.error(e);
            }
        },

        
        /**
         * ダイアログを初期化する。
         */
        initDialog: function(item) {

            this.form.reset();
            this.initForm(item);
        }

    });
});
