/**
 * 配信の緊急度
 * @module app/provide/model/ProvideUrgencyType
 */
define([
    'module',
    'dojo/store/Memory'
    // 以下、変数で受けないモジュール
], function(module, Memory) {
    // シングルトンを返す
    return new Memory({
        idProperty: 'code',
        data: [
            {code: '01', label: '通常'},
            {code: '02', label: '緊急'}
        ]
    });
});

