define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        // IdisGrid拡張：行自体にCSSクラスを追加する場合に指定する
        rowClassName: function(item) {
            return item && item.pubStatus === 1 ? 'is-public' : '';
        },

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            // ボタン列はbuttonColumnメソッドで生成、グリッドのdetailButtonClickイベントが発行されるようになる
            helper.buttonColumn('detail', '詳細'),
            // 値をそのまま出力する例
            helper.column('reportId', 'ID'),
            // 連番整数をクラス名やフォーマット文字列にマッピングする例
            helper.column('human', '人的被害', {
                classMap: ['', 'is-exist'],
                formatMap: ['無し', '有り']
            }),
            // 文字列定数をフォーマット文字列にマッピングする例
            helper.column('type', '被害種別', {
                formatMap: {
                    ROAD: '道路',
                    LIFELINE: 'ライフライン',
                    RIVER: '河川'
                }
            }),
            // 連番整数をフォーマット文字列にマッピングする例
            helper.column('status', '対応状況', {formatMap: ['未対応', '対応中', '対応済']}),
            helper.column('pubStatus', '公開状況', {formatMap: ['一時保存', '振興局', '全体']}),
            // タイムスタンプ変換例
            helper.timestampColumn('regTimestamp', '登録日時')
            // formatterを関数として指定する例（あくまでサンプルとして。実際の日時はそのまま表示すれば良い）
            // helper.column('regTimestamp', '登録日時', {formatter: function(value) { return value + ' に登録'; }})
        ]
    });
});
