/**
* 関連リンクを扱うモデル。
* @module app/model/RelatedLink
*/
define([], function() {
    return [
        {'name':'MCA無線', 'url':''},
        {'name':'防災行政無線', 'url':''},
        {'name':'防災計画・マニュアル', 'url':''},
        {'name':'電話帳使い方', 'url':''}
    ];
});
