define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/topic',
    'dojox/lang/functional/array',
    'idis/consts/QUERY',
    'idis/control/Locator',
    'idis/view/tree/CheckTree',
    './consts/ExclusiveLayer.json',
    './Layers',
    '../model/Layer',
    '../model/LayerStore',
    './LayerPaneTreeNode'
    // 以下、変数で受けないモジュール
], function (module, array, declare, lang, topic, df,
    QUERY, Locator, CheckTree,
    ExclusiveLayer, Layers, Layer, LayerStore, LayerPaneTreeNode) {
    return declare(module.id.replace(/\//g, '.'), CheckTree, {
        model: Layer,

        constructor: function() {
            this._layerMap = {};
            // レイヤー情報の対応付けを保持
            array.forEach(Layers, function(layer) {
                this._layerMap[layer.id] = layer;
            }, this);
        },

        _pageLoad: false,
        // 各プロパティーが設定されてから呼ばれる
        postMixInProperties: function () {
            this.inherited(arguments);
            // 無限ループを避けるため、初期化後はURL変更を監視しない
            // （このパーツ以外からレイヤー一覧が更新されないものとして割り切る）
            df.forEach(Locator.getLayerQuery(), function (opacity, layerId) {
                // URLに登場するレイヤーを順にチェック
                LayerStore.get(layerId).then(lang.hitch(this, function (item) {
                    this.setChecked(item, true);
                }));
            }, this);
            this._pageLoad = true;
        },

        startup: function () {
            this.inherited(arguments);

            // 表示情報：選択解除
            topic.subscribe('app/map/LayerPane::released',
                lang.hitch(this, function (payload) {
                    this.selectLayer(payload);
                }));

            // タブ（気象観測、地震・火山、避難・避難所、被害情報、道路情報）
            topic.subscribe('app/view/page/MonitoringPage::selected',
                lang.hitch(this, function (payload) {
                    this.selectLayer(payload);
                }));

            // 気象観測タブ：地図表示選択
            topic.subscribe('app/monitor/MapViewSelectPanel::checked',
                lang.hitch(this, function (payload) {
                    this.selectLayer(payload);
                }));

        },

        /**
         * レイヤーを選択する。
         */
        selectLayer: function (payload) {
            // 今までついていたチェックを外す
            df.forEach(payload.layerQuery, function (opacity, layerId) {
                LayerStore.get(layerId).then(lang.hitch(this, function (item) {
                    this.setChecked(item, false);
                }));
            }, this);
            // チェックを付ける
            df.forEach(payload.layerId, function (layerId) {
                LayerStore.get(layerId || '0').then(lang.hitch(this, function (item) {
                    this.setChecked(item, true);
                }));
            }, this);
        },

        // ツリー要素にボタンを置けるよう、独自クラスを利用
        _createTreeNode: function (kwArgs) {
            return new LayerPaneTreeNode(kwArgs);
        },

        /**
         * レイヤーのチェック状態をURLに反映する。
         * @param {identifier} id レイヤーの識別子
         * @param {boolean} checked レイヤーのチェック状態
         */
        _updateLocation: function (id, checked) {
            if (!this._pageLoad) {
                return;
            }
            // 表示中のレイヤーIDと透過度の組み合わせ
            var layerQuery = Locator.getLayerQuery();
            // 今回のチェック内容を反映
            if (checked) {
                layerQuery[id] = layerQuery[id] || '0';
                // 排他制御
                df.forEach(ExclusiveLayer.exclusiveList, function (exclusive) {
                    // 排他制御対象かチェック
                    if (df.some(exclusive.layerIds, function (layerId) { return layerId === id; })) {
                        // 他のレイヤを外す
                        df.forEach(exclusive.layerIds, function (layerId) {
                            if (layerId !== id) {
                                delete layerQuery[layerId];
                                LayerStore.get(layerId).then(lang.hitch(this, function (item) {
                                    this.setChecked(item, false);
                                }));
                            }
                        }, this);
                    }
                }, this);
            } else {
                // クエリー文字列から除去
                delete layerQuery[id];
            }
            // 対象のレイヤーは表示状態を常に合わせる
            var layerData = this._layerMap[id];
            if (layerData && layerData.com) {
                df.forEach(layerData.com, function(layerId){
                    if (checked) {
                        layerQuery[layerId] = layerQuery[layerId] || '0';
                    } else if (layerQuery[layerId]) {
                        delete layerQuery[layerId];
                    }
                }, this);
            }
            // URLに反映
            Locator.replaceState(QUERY.LAYER_LIST, Locator.toLayerQuery(layerQuery));
        },

        // ツリー・ノードがチェックされたときに呼ばれる
        onCheckChange: function (item, checked) {
            // ディレクトリーの場合は何もしない
            if (item.infoCategoryCd.indexOf('T') === 0) {
                return;
            }
            // URLの状態を更新（ディレクトリー以外は全て表示情報という前提）
            this._updateLocation(item.id, checked);
        }
    });
});
