/**
 * ユーザーに関わらず全市町村選択用入力パーツ避難情報画面では変更後にtopicを発行する。
 * @module app/view/form/AllFromCityMunicipalitySelector
 */
define([
    'module',
    'dojo/topic',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/when',
    'idis/model/UserInfo',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'app/view/form/MunicipalityListSelector',
    'idis/view/dialog/InfoDialog',
    'app/config'
], function (module, topic, declare, lang, when, UserInfo, CacheStoreModel, CacheTreeRest, MunicipalityListSelector,
    InfoDialog, config) {
    /**
     * 市町村選択用パーツ（避難情報）
     * @class AllFromCityMunicipalitySelector
     * @extends app/view/form/MunicipalityListSelector~MunicipalityListSelector
     */
    return declare(module.id.replace(/\//g, '.'), MunicipalityListSelector,
        /** @lends module:app/evacorder/AllFromCityMunicipalitySelector~AllFromCityMunicipalitySelector*/ {

            // 選択ダイアログのタイトル
            title: '市町',

            // ツリー・モデル
            model: null,

            startup: function () {
                this.inherited(arguments);
                this.dialog.hideOnOK = false;
            },

            postMixInProperties: function () {
                this.model = new CacheStoreModel({
                    store: new CacheTreeRest({
                        target: '/api/municipalities/allRegionMunicipalityFromCity4Tree'
                    })
                });
            },

            /**
             * 選択した値の設定
             */
            // 継承元との差分は以下。
            // (1) prefUnselectable/regionUnselectableが指定された場合、県/振興局は選択不可。
            // (2) 親の名前を表示させない。ex. [長崎県/新宿区/内藤町] → [内藤町]
            _setValueAttr: function (value) {
                // 偽値は数値の0のみ有効とする
                if (value || value === 0) {
                    if (this.prefUnselectable) {
                        //県選択不可
                        if (value === config.municInfo.prefMunicCd) {
                            InfoDialog.show('エラー', '都は選択することができません。');
                            return false;
                        }
                    }
                    if (this.regionUnselectable) {
                        //県民局選択不可
                        if (value !== config.municInfo.prefMunicCd &&
                            config.municInfo.prefRegCdGudge.indexOf(value) > -1) {
                            InfoDialog.show('エラー', '区部単位では選択することができません。');
                            return false;
                        }
                    }
                    if (this.cityUnselectable) {
                        //政令指定都市選択不可
                        if (value === config.municInfo.cityMunicCd) {
                            InfoDialog.show('エラー', '政令指定都市は選択することができません。');
                            return false;
                        }
                    }
                    this._set('value', value);
                    this.emit('change', { value: value });
                    this._initTree().then(lang.hitch(this, function () {
                        var model = this.tree.model;
                        var label;
                        if (this.fullName) {
                            label = model.getFullName(value);
                        } else {
                            label = when(model.store.get(value), function (item) {
                                return model.getLabel(item);
                            });
                        }
                        when(label, lang.hitch(this, function (name) {
                            // 親の情報は不要なので省略する。
                            var lastIndex = name.lastIndexOf('/');
                            if (lastIndex !== -1) {
                                var excludeParentName = name.substring(lastIndex + 1);
                                name = excludeParentName;
                            }
                            this.status.innerHTML = name;
                        }));
                    }));
                } else {
                    this._set('value', '');
                    this.emit('change', { value: '' });
                    this.status.innerHTML = this.emptyValueLabel;
                }
                // 要素の選択状態をリセットする
                this._initTree().then(lang.hitch(this, function () {
                    this.tree.set('selectedItem', null);
                }));
                this.dialog.hide();
            }

        });
});
