/**
 * 地域選択用入力パーツ
 * @module idis/view/form/CustomizableDistrictSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/form/TreeSelector',
    'app/model/District',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'idis/view/tree/IdisTree'
], function(module, declare, TreeSelector, District, CacheStoreModel, CacheTreeRest, IdisTree) {
    /**
     * 地域選択用パーツ。
     * @class CustomizableDistrictSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:idis/view/tree/DistrictSelector~DistrictSelector# */ {
        // 選択ダイアログのタイトル
        title: '地区選択',

        // ツリー・モデル
        model: null,

        // ページ初期表示にmodelがnullだとエラーが出るためmodelを生成
        constructor: function() {
            this._initModel();
        },

        _initModel: function(){
            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                target: '/api/district/CustomizeManagingDistrict4Tree'
                })
            });
        },
        
        // 市町セレクタが変更された際、市町内の地区を取得し直す
        onMunicipalityChange : function(municipalityCd) {
            if(!municipalityCd){
                return false;
            }

            // 一旦ツリーを削除し、再構築する
            if(this.tree){
                this.tree.destroyRecursive();
            }

            var store = new CacheTreeRest({
                target: '/api/district/municDistrict4Tree/' + municipalityCd
            });

            this.model = new CacheStoreModel({
                store: store
            });

            this.tree = new IdisTree({
                model : this.model
            }, this.treeNode);

            // 再配置する
            this.tree.placeAt(this.parentTreeNode);
            this.tree.startup();

            return this.tree.onLoadDeferred;
        }
    });
});
