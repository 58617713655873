/**
 * GeojsonのプロパティとLayerPropGridのカラム名の対応関係を定義
 * 各プロパティー情報を以下2要素の配列で表現する。
 * - Geojson上のプロパティー名
 * - LayerPropGridのカラム名
 * @module app/map/detail/consts/LayerPropColumnMap.js
 */
define({
    // GeojsonのプロパティとLayerPropGridのカラム名の対応マップ
    PROP_TO_COLOMN_MAP: {
        'name': 'name',
        'd_name': 'name',
        'id': 'name',
        'title': 'name',
        'number': 'name',
        'road_name': 'name',
        'reportName': 'name',
        'facilityname': 'name',
        'address': 'address',
        'Address': 'address',
        '住所': 'address',
        'damageAddress': 'address',
        'comment': 'comment',
        'note': 'comment',
        'remarks': 'comment',
        'description': 'comment'
    }
});
