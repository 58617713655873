/**
 * 親要素を含めたラベルを取得する機能を持つモデル用Mixin
 * @module idis/store/_FullNameModelMixin
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/when',
    '../error/InvalidArgumentException',
    './_PromiseModelMixin'
], function(module, declare, lang, when, InvalidArgumentException, _PromiseModelMixin) {
    /**
     * 初回に全データを取得し、以降ルート要素と親子関係はキャッシュを利用するdijit/tree/model準拠クラス。
     * @class _FullNameModelMixin
     * @extends module:dijit/tree/model
     * @param {Object} kwArgs
     * @param {Store} kwArgs.store dojo/storeオブジェクト
     */
    return declare(_PromiseModelMixin, /** @lends module:idis/model/_FullNameModelMixin~_FullNameModelMixin# */ {
        // プロパティー設定後に呼ばれる
        postMixInProperties: function() {
            this.inherited(arguments);
            // プロパティーの検証
            if (!this.store || !this.store.getParentIdentity) {
                var message = '::postMixInProperties: store は getParentIdentity メソッドを持つ必要があります。';
                throw new InvalidArgumentException(module.id + message);
            }
        },

        /**
         * 指定された要素の親要素を含めた名前を取得する。
         * @param {string|number} id 要素の識別子
         * @param {string} [separator=' / '] 要素の名前間の区切り文字
         * @returns {Promise<string>} 親要素を含めた名前
         */
        getFullName: function(id, separator) {
            if (arguments.length < 2) {
                separator = ' / ';
            }
            // 指定された要素を取得
            return when(this.store.get(id)).then(lang.hitch(this, function(item) {
                if (!item) {
                    throw new Error(module.id + '#getFullName: 識別子に対応する要素が見つかりませんでした: id=' + id);
                }
                // 要素名と親のIDを取得
                var name = this.getLabel(item);
                var parentId = this.store.getParentIdentity(item);
                // 親が存在しないなら自分の名前だけを返す
                if (!parentId) {
                    return name;
                }
                return when(this.getRootPromise()).then(lang.hitch(this, function(root) {
                    // 親IDがルート要素と一致した場合は自分の名前だけを返す
                    if (parentId === this.store.getIdentity(root)) {
                        return name;
                    }
                    // 親の名前と自分の名前を結合して返す
                    return when(this.getFullName(parentId, separator)).then(function(familyName) {
                        return familyName + separator + name;
                    });
                }));
            }));
        }
    });
});

