define({
    toTanJSONFromOne: function(drawType){
        var tempJSON = this.toGeoJSON();
        var options = this.options;
        if (drawType !== void 0 && drawType.toUpperCase().indexOf('CIRCLE') !== -1) {
            options.drawType = 'CircleMarker';
            options.radius = this.getRadius();
        } else if (drawType === 'marker') {
            options.drawType = 'Icon';
        } else if (drawType === 'DivIcon') {
            // for ResizeHandle
            this.options.width = this.options.icon.options.width;
            this.options.height = this.options.icon.options.height;
        } else if (drawType === 'arrow') {
            // MultiLineStringとしてGeoJsonを変換する
            tempJSON.geometry.type = 'MultiLineString';
            if(options.arrowHead){
                // arrowHeadをgeoJson化する
                var arrowHeadCoordinates = null;
                var arrowBothHeadCoordinates = null;
                // MultiLineStringとしてGeoJsonを変換する
                var arrowLineCoordinates = tempJSON.geometry.coordinates;

                if(options.bothArrow === true) {
                    for(var i = 0; i <= options.arrowHead.length -1; i++) {
                        var arrowHeadJson = options.arrowHead[i].toGeoJSON();
                        if(i === 0) {
                            arrowHeadCoordinates = arrowHeadJson.features[0].geometry.coordinates;
                        } else {
                            arrowBothHeadCoordinates = arrowHeadJson.features[0].geometry.coordinates;
                        }

                        // arrowline/arrowhead で固定
                        tempJSON.geometry.coordinates = [
                            arrowLineCoordinates,
                            arrowHeadCoordinates,
                            arrowBothHeadCoordinates
                        ];
                    }
                } else {
                    var arrowHeadJson = options.arrowHead.toGeoJSON();
                    arrowHeadCoordinates = arrowHeadJson.features[0].geometry.coordinates;

                    // arrowline/arrowhead で固定
                    tempJSON.geometry.coordinates = [
                        arrowLineCoordinates,
                        arrowHeadCoordinates
                    ];
                }
            }
        }
        tempJSON.properties = this.optionConverter(options, 'export');
        return tempJSON;
    }
});
