/**
 * 部選択用入力パーツ
 * @module app/view/form/DeptSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/string',
    'dojo/when',
    'dijit/form/TextBox',
    'idis/view/form/TreeSelector',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'idis/view/dialog/InfoDialog',
    'app/config'
], function(module, declare, lang, dStr, when, TextBox, TreeSelector, CacheStoreModel, CacheTreeRest,
    InfoDialog, config) {
    /**
     * 部選択用パーツ。
     * @class SectSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:app/view/form/DeptSelector~DeptSelector# */ {
        // 選択ダイアログのタイトル
        title: '部',

        // ツリー・モデル
        model: null,

        // ページ初期表示にmodelがnullだとエラーが出るためmodelを生成
        constructor: function() {
            this._initModel();
        },

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
            // 検索用入力要素を設置
            this.filterInput = new TextBox({
                placeHolder: '局名で絞り込み'
            });
            this.filterInput.placeAt(this.treeControlNode);
            this.own(this.filterInput.on('keyup', lang.hitch(this, 'onFilterInputChange')));
        },

        _initModel: function(){
            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                target: '/api/organization/depts/dept4Tree'
                })
            });
        },

        // ダイアログを表示する度にmodelを呼び出すことで施設種別テーブルの変更点をツリーに反映
        showDialog: function() {
            if(this.treeParentNode && this.tree){
                // this.treeNode = this.tree.domNode;
                this.tree.destroyRecursive();
                this.treeParentNode.appendChild(this.treeNode);
                delete this.tree;
                delete this.model;
                this._initModel();
            } else {
                // 2回目の表示以降、treeを削除する際にtreeNodeが削除されてしまうので
                // 初期表示の段階で、ツリー表示時のtreeNode.parentNodeを記憶しておく
                this.treeParentNode = this.treeNode.parentNode;
            }
            return this.inherited(arguments);
        },

        /**
         * 検索用入力要素入力時。
         */
        onFilterInputChange: function() {
            // 入力値を取得
            var value = dStr.trim(this.filterInput.get('value'));
            if (value) {
                // ラベル名が入力値を含むものでフィルターし、ツリーを展開する
                this.model.setFilter(function(item, isLeaf, model) {
                    return isLeaf && (model.getLabel(item) || '').indexOf(value) !== -1;
                }).then(lang.hitch(this, function() {
                    this.tree.expandAll();
                }));
            } else {
                // 入力が空の場合は全要素を表示してツリーを閉じる
                this.model.setFilter().then(lang.hitch(this, function() {
                    this.tree.collapseAll();
                }));
            }
        },
        /**
         * 選択した値の設定
         */
        _setValueAttr: function (value) {
            // 偽値は数値の0のみ有効とする
            if (value || value === 0) {
                if (this.cityUnselectable) {
                    //市選択不可
                    if (value === config.municInfo.cityMunicCd) {
                        InfoDialog.show('エラー', '市は選択することができません。');
                        return false;
                    }
                }
                this._set('value', value);
                this.emit('change', { value: value });
                this._initTree().then(lang.hitch(this, function () {
                    var model = this.tree.model;
                    var label;
                    if (this.fullName) {
                        label = model.getFullName(value);
                    } else {
                        label = when(model.store.get(value), function (item) {
                            return model.getLabel(item);
                        });
                    }
                    when(label, lang.hitch(this, function (name) {
                        // // 親の情報は不要なので省略する。
                        // var lastIndex = name.lastIndexOf('/');
                        // if (lastIndex !== -1) {
                        //     var excludeParentName = name.substring(lastIndex + 1);
                        //     name = excludeParentName;
                        // }
                        this.status.innerHTML = name;
                    }));
                }));
            } else {
                this._set('value', '');
                this.emit('change', { value: '' });
                this.status.innerHTML = this.emptyValueLabel;
            }
            // 要素の選択状態をリセットする
            this._initTree().then(lang.hitch(this, function () {
                this.tree.set('selectedItem', null);
            }));
            this.dialog.hide();
        }
    });
});
