/**
* Spectee詳細画面用モジュール。
* @module app/spectee/SpecteeAdminDetailPage
*/
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/SpecteeAdminDetailPage.html',
    'dojo/topic',
    'dojo/dom-style',
    'dojo/dom-construct',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'leaflet',
    'app/model/DisasterInfo',
    'idis/consts/ACL',
    'idis/view/dialog/DialogChain',
    './_SpecteeAdminPageBase',
    '../config',
    // 以下、変数として受け取らないモジュール
    'dijit/form/Form',
    'dijit/form/CheckBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    '../view/form/DisasterSelector',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    './SpecteeAdminDetailGrid',
    './SpecteeFavoriteDialog',
    './NoneAdressIconPanel'
], function(module, array, declare, lang, template, topic, domStyle, domConstruct, _PageBase, Loader, Locator,
    Router, Requester, UserInfo, UserType, leaflet, DisasterInfo, ACL, DialogChain, _SpecteeAdminPageBase, config) {
    /**
    * Spectee情報詳細画面。
    * @class SpecteeAdminDetailPage
    * @extends module:idis/view/page/_PageBase~_PageBase
    */
    return declare(module.id.replace(/\//g, '.'), _SpecteeAdminPageBase,
        /** @lends module:app/spectee/SpecteeAdminDetailPage~SpecteeAdminDetailPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--spectee',

        /**
        * データ格納用オブジェクト
        * @type {module:dstore/Store}
        */
        store: null,
        /**
         * 災害ID
         */
        _disasterId: null,
        /**
         * 位置情報
         * @type {Object}
         */
        _latlng: null,
        /**
         * 事案ID
         */
        caseId: null,
        /**
         * 最新報フラグ
         */
        _lastReportFlg: true,
        /**
         * 最新報情報
         */
        _lastReportInfo: true,
        /**
         * page種別(詳細画面)
         * @type {String}
         */
        PAGE_TYPE: 'DETAIL_PAGE',
        /**
         * 使用するgrid名
         * @type {String}
         */
        GRID_NAME: null,
        /**
         * デフォルトの緯度経度
         */
        INIT_LATLNG: {lat: config.map.latitude, lng: config.map.longitude},
        /**
         * 位置情報確定レベル(緯度経度・住所なし)
         * @type {String}
         */
        LATLNG_COMFIRM_LEVEL_NONE: '0',
        /**
         * specteeお気に入り情報の登録・解除権限
         * @type {String}
         */
        SPECTEE_UPDATE_AUTH: '01',
        /**
         * 地図を動的に描画・操作するために発行するトピック
         */
        DRAW_ICON: 'app/spectee/SpecteeAdminDetailGrid' + '::drawIcon',

        /**
         * constructor
         */
        constructor: function() {
            this.chain = DialogChain.get(this);

            // 災害IDをDisasterInfoから取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                console.warn('災害IDが設定させていません。');
            }
            // 市町コード取得
            this._municipalityCd = UserInfo.getMunicipalityCd();
            // 振興局コード取得
            this._regionCd = UserInfo.getRegionCd();
            console.debug('現在のログインユーザID：' + UserInfo.getId());
            console.debug('現在の災害ID：' + DisasterInfo.getDisasterId());

            // ユーザ情報を取得し、ロールによりgrid名を決める
            var specteeAuth;
            if(UserInfo && UserInfo._userInfo && UserInfo._userInfo.authzs) {
                specteeAuth = UserInfo._userInfo.authzs.F06008;
                // 市町ユーザの場合は、ユーザ情報に紐づく市町コードを取得しておく
                if (UserInfo.getUserType() === UserType.MUNICIPALITY) {
                    this._municipalityCd = UserInfo.getMunicipalityCd();
                }
            }
            this.GRID_NAME = 'grid';

            this.caseId = Locator.getQuery().caseId;
            this.favoriteFlg = Locator.getQuery().favoriteFlg;
        },

        /**
         * buildRendering
         * DOMノードを生成するためのメソッド
         */
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は関連ダイアログも削除する
            this.own(this.favoriteDialog);
        },

        /**
         * postCreate
         */
        postCreate: function() {
            console.debug('postCreate');
            this.inherited(arguments);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);

            // 使用するgridを出し分ける
            this.setGridStyle();
            // 地図を初期化する
            this.initMap(this.INIT_LATLNG.lat, this.INIT_LATLNG.lng, 14);
            this.own(topic.subscribe('idis/view/form/GeocodingForm::geocoded', lang.hitch(this, function(payload) {
                this._latlng = payload.latlng;
                this.map.setView(payload.latlng, 11);
            })));
            // グリッドが描画された際に地図にも位置情報を描画する
            var drawIconEvent = topic.subscribe(this.DRAW_ICON, lang.hitch(this, function (arg) {
                this.addMarker(arg);
            }));
            this._events.push(drawIconEvent);
            this.own(drawIconEvent);

            // グリッドを初期化する
            this.initGrid(this.PAGE_TYPE);

            this.form.resize();
            // 初期表示用にSpecteeAPIにリクエストする
            var query = this.buildQueryForOneCase();
            this.fetchSpecteeInfo(query);
            // お気に入り登録有無によって表示内容を調整
            this.changeFavorite();
            

            // 定期処理を開始する
            this.startTimerForOneCase();

            // 周辺被害情報表示
            this.showMarkersFlg = false;
            if (this._municipalityCd) {
                this.fetchDamageReports();
                this.showDamageReportMarkers();
            }
        },

        /**
         * Specteeへの定期取得処理を開始する。
         */
        startTimerForOneCase: function(){
        //関数fetchSpecteeInfo()を60000ミリ秒間隔で呼び出す
            this.specteeTimer =
            setInterval(lang.hitch(this, function() {
                // 最新報フラグをtrueにする
                this._lastReportFlg = true;
                var query = this.buildQueryForOneCase();
                this.fetchSpecteeInfo(query);
            }), 60000);
        },

        /**
         * Specteeより、特定の事案IDの全報情報を取得する。
         */
        fetchSpecteeInfo: function(query){
            Requester.get('/api/spectee/', {
                query: query
            }).then(lang.hitch(this, function(data) {
                console.log('SpecteeよりSNS情報を取得');
                // APIから返却されるレスポンスコードを元に処理する
                if(data.status === '200') {
                    this.lastFetchTime = data.fetchTime;
                    this.updateGrid(data.data);
                    this._lastReportInfo = data.data[0];
                    this.initPage(this._lastReportInfo);
                }else {
                    this.chain.info('SpecteeよりSNS情報を取得できませんでした。一分後に情報を自動取得します。', 'エラー');
                }
            }), lang.hitch(this, function(error) {
                this.chain.infoError(error);
            }));
        },

        /**
         * お気に入り情報を取得する。
         */
        fetchFavoriteInfo: function () {
            // 災害名が選択されていない場合は処理しない
            if (!this._disasterId) {
                this.chain.info('災害名を選択してください', 'エラー');
                return;
            }
            Requester.get('/api/spectee/favoriteInfo/', {
                query: { 
                    disasterId: this._disasterId,
                    caseId: this.caseId
                }
            }).then(lang.hitch(this, function (data) {
                console.log('お気に入り情報を取得');
                var favoriteMemo = data.memo;
                // お気に入りメモ登録がある時、詳細画面に表示
                if(favoriteMemo){
                    this.favoriteMemo.innerHTML = favoriteMemo;
                    domStyle.set(this.favoriteMemoArea, 'display', '');
                }
            }), lang.hitch(this, function (error) {
                this.chain.infoError(error);
            }));
        },

        /**
         * 特定事案のSpectee情報取得用のqueryを組み立てる。
         */
        buildQueryForOneCase : function() {
            var query = {
                disasterId : this._disasterId,
                caseId : this.caseId,
                lastFetchTime : this.lastFetchTime
            };
            return query;
        },

        /**
         * メモ入力ダイアログを表示する。
         */
        openFavoriteDialog: function () {
            this.favoriteDialog.show();
            // ダイアログ中のページウィジェットを取得
            var page = this.favoriteDialog.getChildren()[0];
            page.on('register', lang.hitch(this, function (form) {
                this.registerFavorite(form);
            }));
            // ダイアログの画面を初期表示する
            page.initDialog(this._lastReportInfo);
        },

        /**
             * お気に入り情報を登録する。
             */
        registerFavorite: function (form) {
            // 災害名が選択されていない場合は処理しない
            if (!this._disasterId) {
                this.chain.info('災害名を選択してください', 'エラー');
                return;
            }
            var msg = '登録します。よろしいですか？';
            this.chain.confirm(msg, lang.hitch(this, function (chain) {
                // 市町コード取得
                this._municipalityCd = UserInfo.getMunicipalityCd();
                // 振興局コード取得
                this._regionCd = UserInfo.getRegionCd();
                // お気に入り登録フォーム作成
                var value = this._toSendValue(form, this._disasterId, this._municipalityCd, this._regionCd);
                Loader.wait(
                    // 取消処理を実行して取消後の返り値を保持する。
                    Requester.post('/api/spectee/favoriteCase/', {
                        data: value
                    })).then(lang.hitch(this, function () {
                        chain.infoComplete();
                        this.favoriteFlg = 'true';
                        this.changeFavorite();
                        this.favoriteDialog.hide();

                    }), function (err) {
                        // 登録失敗
                        chain.infoError(err);
                    });
            }));
        },

        /**
         * お気に入り情報を登録解除する。
         */
        removeFavorite: function (value) {
            // 災害名が選択されていない場合は処理しない
            if (!this._disasterId) {
                this.chain.info('災害名を選択してください', 'エラー');
                return;
            }
            var message = 'お気に入り登録を解除します。<br>' + 'よろしいですか？';
            this.chain.confirm(message, lang.hitch(this, function (chain) {
                Loader.wait(
                    // 取消処理を実行して取消後の返り値を保持する。
                    Requester.del('/api/spectee/favoriteCase/' + this.caseId + '/' + this._disasterId, {
                    })).then(lang.hitch(this, function () {
                        chain.infoComplete();
                        this.favoriteFlg = 'false';
                        this.changeFavorite();
                    }), function (err) {
                        // 削除失敗
                        chain.infoError(err);
                    });
            }));
        },

        /**
         * お気に入り登録の有無によって表示内容を変更する。
         */
        changeFavorite: function () {
            // お気に入り登録済みの場合、
            if (this.favoriteFlg === 'true'){
                // お気に入り登録情報を取得
                this.fetchFavoriteInfo();
                // 登録済みマークの表示
                domStyle.set(this.favorite, 'display', '');
                domStyle.set(this.nonFavorite, 'display', 'none');
            } else if (this.favoriteFlg === 'false'){
                // 未登録マークの表示
                domStyle.set(this.favorite, 'display', 'none');
                domStyle.set(this.nonFavorite, 'display', '');
                // 保存済みメモの非表示
                domStyle.set(this.favoriteMemoArea, 'display', 'none');
            };
        },

        /**
         * Spectee管理画面に遷移する。
         */
        onSpecteeAdminPageLinkClick: function(evt) {
            if(this.marker) {
                this.removeMark();
            }
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('spectee');
        },

        /**
         * 詳細画面に最新報の情報を表示する
         */
        initPage: function(item) {
            // 地図を初期化
            if (item.locationInfo[0].geocode.latitude && item.locationInfo[0].geocode.longitude) {
                var latlng = [item.locationInfo[0].geocode.latitude, item.locationInfo[0].geocode.longitude];
                // this.initMap(item.locationInfo[0].geocode.latitude, item.locationInfo[0].geocode.longitude);
                this.map.setView(latlng, 14);
            }
            if(item) {
                this.reportNo.innerHTML = '第'+ item.reportNo +'報';
                if (item.locationInfo){
                    var locationInfo = item.locationInfo[0];
                    this.address.innerHTML = locationInfo.city + ' ' + locationInfo.town;                
                    this.prefecture.innerHTML = locationInfo.prefecture;
                }
                this.title.innerHTML = item.title;
                // SNSコンテンツの作成
                var snsInfo = item.snsInfo[0];
                if (snsInfo){
                    // Twitterの場合、Twitter用のコンテンツを作成する
                    if (snsInfo.sns_type === this.SNSTYPE_TWITTER) {
                        this.initTwitterContent(snsInfo);
                    // Twitter以外の場合、埋め込み用コンテンツを入れる。
                    } else {
                        this.initSnsContent(snsInfo);
                    }
                // SNSコンテンツ情報をもっていない場合、noteの情報を表示する。
                } else {
                    this.body.innerHTML = item.note;
                }
            }
        },

        /**
         * Twitterコンテンツを作成する
         */
        initTwitterContent: function(snsInfo) {
            var type = null;
            var url = null;
            var mediaUrl = 'media_url';
            var mediaType = 'media_type';
            // Spectee側で組み立てられた埋め込み部分のうち不要な部分を削る
            snsInfo.embed = this.trimEmbed(snsInfo);
            // 不要部分を削った埋め込み部分をパーツ毎に取り出す
            var dom = domConstruct.toDom(snsInfo.embed);
            var childNodes = dom.childNodes;

            // メディアタイプを判定
            if (snsInfo.media && snsInfo.media[0]) {
                url = snsInfo.media[0][mediaUrl];
                if (snsInfo.media[0][mediaType] === 'photo') {
                    type = 'photo';
                } else if (snsInfo.media && snsInfo.media[0] && snsInfo.media[0][mediaType] === 'video') {
                    type = 'video';
                }
            }
            // SNS毎のイメージを作成
            this.buildSnsImage(snsInfo);
            // メディア部分を作成
            this.buildMediaForTwitter(url,type);
            

            var userName = dom.textContent;
            var userName = userName.split('— ');
            var userName = userName[1].split(')');
            var userName = '―&nbsp;'+userName[0]+')';
            var idUserName = document.getElementById('userName');
            // id=userNameの要素を削除する。
            if (idUserName){
                domConstruct.destroy('userName');
            }
            this.userName.innerHTML = '<span id = "userName">'+ userName +"</span>";
            array.forEach(childNodes, lang.hitch(this, function(childNode) {
                // pタグの場合、bodyとなる
                if (childNode && childNode.tagName === 'P'){
                    // 自動更新時のため、一度body要素を削除
                    if (document.getElementById('body')){
                        domConstruct.destroy('body');
                    }
                    var body = childNode;
                    // 削除したidを再度付与
                    body.id = 'body';
                    // bodyをHTMLに挿入
                    domConstruct.place(body, this.body);
                // aタグの場合、timestampとなる
                } else if (childNode && childNode.tagName === 'A'){
                    // 自動更新時のため、一度body要素を削除
                    if (document.getElementById('timestamp')){
                        domConstruct.destroy('timestamp');
                    }
                    var timestamp = childNode;
                    // 削除したidを再度付与
                    timestamp.id = 'timestamp';
                    // timestampをHTMLに挿入
                    domConstruct.place(timestamp, this.timestamp);
                }
            }));
        },

        /**
         * SNSコンテンツ（Twitter以外）を作成する
         */
        initSnsContent: function(snsInfo) {
            // Spectee側で組み立てられた埋め込み部分のうち不要な部分を削る
            snsInfo.embed = this.trimEmbed(snsInfo);
            // 不要部分を削った埋め込み部分をパーツ毎に取り出す
            var body = domConstruct.toDom(snsInfo.embed);

            if (document.getElementById('body')){
                domConstruct.destroy('body');
            }
            body.id = 'body';
            domConstruct.place(body, this.body);
        },

        /**
         * SNSイメージ画像を挿入する。
         */
        buildSnsImage: function (snsInfo) {
            var image = '';
            var snsType = snsInfo.sns_type;
            if (snsType === this.SNSTYPE_TWITTER) {
                image = '<img src="/images/spectee/logo_twitter.png" hspace="10" vspace="10" style="width:30px; height:auto;"></img>';
            } else if (snsType === this.SNSTYPE_FACEBOOK){
                image = '<img src="/images/spectee/logo_facebook.png" hspace="10" vspace="10" style="width:30px; height:auto;"></img>';
            } else if (snsType === this.SNSTYPE_YOUTUBE){
                image = '<img src="/images/spectee/logo_youtube.png" hspace="10" vspace="10" style="width:30px; height:auto;"></img>';
            } else if (snsType === this.SNSTYPE_TIKTOK){
                image = '<img src="/images/spectee/logo_tiktok.png" hspace="10" vspace="10" style="width:30px; height:auto;"></img>';
            }
            this.image.innerHTML = image;
        },

        /**
         * Twitter用のメディアコンテンツを組み立てる。
         */
        buildMediaForTwitter: function (url, type) {
            // Twitterの場合は画像、動画を抜き出し横幅を固定
            // 画像、動画を読み込んだ後、ポップアップをリサイズする
            if (url) {
                var media = '';
                if (type === 'photo') {
                    // 画像の場合
                    var image = new Image();
                    image.src = url;
                    // 画像サイズを取得し終えた時点でポップアップを更新する
                    image.onload = lang.hitch(this, function () {
                        if (this.marker) {
                            this.marker._popup.update();
                        }
                    });
                    media += '<img src="' + url + '" style="height:100px; float:right; padding-right:10px;"></img>';
                } else if (type === 'video') {
                    // 動画の場合
                    var element = document.createElement('video');
                    element.src = url;
                    media += '<video controls preload="metadata" style="height:100px; float:right; padding-right:10px;"><source src="' +
                        url + '"></video>';
                }
                this.media.innerHTML = media;
            }
        },

        /**
         * Spectee情報のマーカーを生成する
         */
        makeSpecteeMarker: function(item){
            var lat = item.locationInfo[0].geocode.latitude;
            var lng = item.locationInfo[0].geocode.longitude;

            // 位置情報が無い時はマーカーを生成しない
            if(!lat || !lng){
                return false;
            }

            // 事象別アイコン
            var html = [];
            var leftPosition = -12;
            var specteeEvent = 'other';
            if(this._lastReportFlg===true && !this.isEmpty(item.eventIds)){
                if(item.eventIds.indexOf('200100002')!==-1 || item.eventIds.indexOf('200100003')!==-1 || item.eventIds.indexOf('200100007')!==-1 || item.eventIds.indexOf('200100009')!==-1){
                    specteeEvent = 'railway';
                } else if (item.eventIds.indexOf('100100')!==-1){
                    specteeEvent = 'weather';
                } else if (item.eventIds.indexOf('100200')!==-1){
                    specteeEvent = 'fire';
                } else if (item.eventIds.indexOf('100300')!==-1){
                    specteeEvent = 'accident';
                } else if (item.eventIds.indexOf('100400')!==-1){
                    specteeEvent = 'incident';
                } else if (item.eventIds.indexOf('100500')!==-1){
                    specteeEvent = 'lifeline';
                } else if (item.eventIds.indexOf('100600')!==-1){
                    specteeEvent = 'other';
                }
            }
            if(this._lastReportFlg===false && !this.isEmpty(item.eventIds)){
                if(item.eventIds.indexOf('200100')!==-1){
                    specteeEvent = 'railway_old';
                } else if (item.eventIds.indexOf('100100')!==-1){
                    specteeEvent = 'weather_old';
                } else if (item.eventIds.indexOf('100200')!==-1){
                    specteeEvent = 'fire_old';
                } else if (item.eventIds.indexOf('100300')!==-1){
                    specteeEvent = 'accident_old';
                } else if (item.eventIds.indexOf('100400')!==-1){
                    specteeEvent = 'incident_old';
                } else if (item.eventIds.indexOf('100500')!==-1){
                    specteeEvent = 'lifeline_old';
                } else if (item.eventIds.indexOf('100600')!==-1){
                    specteeEvent = 'other_old';
                }
            }
            // 最新報フラグをfalseにする
            this._lastReportFlg = false;

            var specteeEventIcon = '/images/spectee/event_' + specteeEvent + '.png';
            html += '<img src="' + specteeEventIcon + '" height="30px" width="30px"';
            html += ' style="position:absolute;top:-15px;left:' + leftPosition + 'px;">';


            var divIcon = leaflet.divIcon({
                html: html,
                className: 'damage-point',
                iconSize: [0, 0],
                iconAnchor: [3, 3]
            });

            var marker = leaflet.marker([lat, lng],{
                icon: divIcon
            });

            return marker;
        }
    });
});