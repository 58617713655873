/**
 * 地震情報パネル
 * @module app/monitor/EarthquakePanel
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/dom-construct',
    'dojo/json',
    'dojo/text!./templates/EarthquakePanel.html',
    'dojo/topic',
    'dojo',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/view/page/_PageBase',
    'app/config',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane'
], function(module, array, declare, lang, locale, domConstruct, JSON, template, topic,
    dojo, Router, UserInfo, Requester, DialogChain, Loader, _PageBase, config) {
    /**
     * 地震情報パネル
     * @class EarthquakePanel
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/monitor/EarthquakePanel~EarthquakePanel# */ {
        // テンプレート文字列
        templateString: template,

        /**
         * 最大表示件数
         */
        MAX_COUNT: 5,

        /**
         * 市町村コード
         */
        _municipalityCd: null,

        /**
         * constructor
         */
        constructor: function() {
            this.chain = DialogChain.get(this);
            this._municipalityCd = UserInfo.getSelectedMunicipalityCd();
            console.debug('地震情報表示対象の市町村コード：' + this._municipalityCd);
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
            this.initTable();

            // 市町村切替時に気象情報を更新
            this.own(topic.subscribe('idis/view/form/MunicipalitySelectForm::selected',
                lang.hitch(this, function(payload) {
                    this._municipalityCd = payload.municipalityCd;
                    this.initTable();
            })));
        },

        /**
         * テーブルを初期化します
         */
        initTable: function() {
            // 地震情報を設定
            this.setEarthquakeListInfo();
        },

        /**
         * 地震情報を設定します
         */
        setEarthquakeListInfo: function() {
            var self = this;

            var promise = Requester.get('/data/earthquake/earthquake.json', {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(function(item) {
                console.debug('地震情報（' + this._municipalityCd + '）：' +
                    JSON.stringify(item));

                // 地震情報を設定
                self.setEarthquakeList(self, item);

            }, function(error) {
                if (error.response.status === 404) {
                    console.log('地震情報が見つかりません。初期値をセットします。' + (error.message ? ('('+error.message+')') : ''));
                    var item = {items:[]};
                    self.setEarthquakeList(self, item);
                } else {
                    self.chain.info('情報の取得に失敗しました。', 'エラー');
                }
            });
            //ローダーの表示
            Loader.wait(promise);
        },

        /**
         * 地震情報を設定します
         */
        setEarthquakeList: function(self, data) {
            var earthquakePanelListTbody = dojo.byId('earthquakePanelListTbody');
            domConstruct.empty(earthquakePanelListTbody);

            var html = '';
            if (data.items.length === 0) {
                html += '<tr>';
                html += '<td colspan="3" style="text-align: left;">現在、地震情報はありません。</td>';
                html += '</tr>';
            } else {

                // 発表時刻降順
                var items = data.items.sort(function(a, b) {
                    return new Date(b.reportDatetime.replace(/-/g, '/')).getTime() - new Date(a.reportDatetime.replace(/-/g, '/')).getTime();
                });

                var earthquakeList = {'items': []};
                var currentDateTime = new Date().getTime();

                array.forEach(items, function(item){
                    var reportDatetime = new Date(item.reportDatetime.replace(/-/g, '/')).getTime();
                    // 24時間以内
                    if (reportDatetime + (24*60*60*1000) < currentDateTime) {
                        return;
                    }
                    // 対象自治体のみ、震度の大きい順でソートする
                    var filtered = item.regions.filter(function(element) {
                        return element.placeCode.slice(0, 2) === config.municInfo.prePrefCd;
                    }).sort(self.sortByIntensity).reverse();
                    if (filtered.length > 0) {
                        var maxIntensity = filtered[0].intensity;
                        var lastStr = maxIntensity.slice(-1);
                        if (lastStr === '+') {
                            maxIntensity = maxIntensity.slice(0, -1) + '強';
                        } else if (lastStr === '-') {
                            maxIntensity = maxIntensity.slice(0, -1) + '弱';
                        }
                        earthquakeList.items.push({
                            'reportDatetime' :	reportDatetime,
                            'infokind' :	item.infokind,
                            'maxIntensity' : maxIntensity
                        });
                    }
                });

                if (earthquakeList.items.length === 0) {
                    html += '<tr>';
                    html += '<td colspan="3" style="text-align: left;">現在、地震情報はありません。</td>';
                    html += '</tr>';
                } else {
                    // 先頭5件のみ表示
                    if (earthquakeList.items.length > this.MAX_COUNT) {
                        earthquakeList.items.length = this.MAX_COUNT;
                    }

                    array.forEach(earthquakeList.items, function(item){
                        var datetimeString = self.formatDateTime(item.reportDatetime);
                        html += '<tr>';
                        html += '<td>' + datetimeString + '</td>';
                        html += '<td>' + item.infokind + '</td>';
                        html += '<td>' + item.maxIntensity + '</td>';
                        html += '</tr>';
                    });
                }
            }

            var dom = domConstruct.toDom(html);
            domConstruct.place(dom, earthquakePanelListTbody);
        },

        /**
         * 震度順を並び替えるcomparator
         * 本来5+ > 5- なのだが, '+' < '-' なので単なるsortではおかしくなってしまう
         */
        sortByIntensity: function(a, b) {
            // 完全に一致
            if (a.intensity === b.intensity) {
                return 0;
            }

            // どちらかに2文字目が存在しなかったら普通の数字順を戻す
            if (!a.intensity[1] || !b.intensity[1]) {
                return a.intensity[0] - b.intensity[0];
            }

            // 1文字目が異なる比較だったらやはり普通の数字順を戻す
            if (a.intensity[0] !== b.intensity[0]) {
                return a.intensity[0] - b.intensity[0];
            }

            // 2文字目(+とか-)が存在し, かつ1文字目が同じ比較だったら 通常文字列比較の"逆"を戻す
            // 文字コード順では '-' > '+' だが, 逆にする('+'が'-'よりも大きいと判断させる)ため
            return b.intensity > a.intensity ? 1 : -1;
        },

        /**
         * 'yyyy-MM-dd HH:mm' 形式に変換
         */
        formatDateTime: function(val) {
            var timestamp = new Date(val);
            var dateLabel = locale.format(timestamp, {
                selector: 'date',
                datePattern: 'yyyy/MM/dd'
            });
            var timeLabel = locale.format(timestamp, {
                selector: 'time',
                timePattern: 'HH:mm'
            });
            return dateLabel + '&nbsp;' + timeLabel;
        },

        /**
         * 地震情報一覧ページに遷移
         */
        onEarthQuakeLinkClick: function(evt){
            evt.preventDefault();
            Router.moveTo('earthquake');
        }

    });
});
