define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, lang, locale, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--chatShare',

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            helper.buttonColumn('detail', '詳細'),
            { field: 'dpDistributionHistId', label: '投稿種別', sortable: false,
                formatter: lang.hitch(this, function(item, value) {
                    if (item) {
                        return '緊急連絡';
                    } else if (!value.chatId) {
                        return '気象情報';
                    } else {
                        return '通常';
                    }
                    return '-';
                })
            },
            { field: 'user', label: '投稿者', sortable: false,
                formatter: lang.hitch(this, function(item){
                    if (item && item.userName) {
                        return item.userName;
                    }
                    return '-';
                })
            },
            { field: 'officialPositionName', label: '役職', sortable: false,
                formatter: lang.hitch(this, function(v, item){
                    if (item.user && item.user.officialPositionName) {
                        return item.user.officialPositionName;
                    }
                    return '-';
                })
            },
            { field: 'body', label: '本文', sortable: false,
                formatter: lang.hitch(this, function(item){
                    if (item) {
                        return item;
                    }
                    return '-';
                })
            },
            { field: 'sendTimestamp', label: '投稿日時', sortable: false,
                formatter: lang.hitch(this, function(item){
                    if (item) {
                        return locale.format(new Date(item));
                    }
                    return '';
                })
            },
            helper.buttonColumn('list', '既読状況一覧', '一覧')
        ]
    });
});
