/**
 * 組織選択用入力パーツ
 * @module idis/view/form/OrganizationMultiSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/dom-style',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'idis/view/form/TreeChecker'
], function(module, declare, domStyle, CacheStoreModel, CacheTreeRest, TreeChecker) {
    /**
     * 組織選択用パーツ。
     * @class OrganizationSelector
     * @extends module:idis/view/form/TreeChecker~TreeChecker
     */
    return declare(module.id.replace(/\//g, '.'), TreeChecker,
        /** @lends module:idis/view/tree/OrganizationSelector~OrganizationSelector# */ {
        // 選択ダイアログのタイトル
        title: '組織選択',

        // ツリー・モデル
        model: null,

        // ツリーの取得レベル（MUnit, MSect, MDeptのどちか）
        level: 'MDept',

        // リフレッシュができるかどうか
        _canRefresh: false,

        // ページ初期表示にmodelがnullだとエラーが出るためmodelを生成
        constructor: function() {
            this._initModel();
        },

        _initModel: function(){
            this.inherited(arguments);
            var query = '?level=' + this.level;
            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                    target: '/api/organizations/selectLevel' + query
                })
            });
        },

        /**
        * 選択した値の設定
        */
        _setValueAttr: function() {
        // TreeChecker本来の処理
            this.inherited(arguments);
            // 文字列省略をオーバーライド
            domStyle.set(this.status, {
                'max-width': '20em',
                'white-space': 'nowrap',
                'overflow': 'hidden',
                'text-overflow': 'ellipsis'
            });
        }
    });
});

