/**
 * 更新種別を表示する入力要素
 * @module app/provide/form/DistributionTypeInput
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/dom-class',
    'idis/view/form/ReadOnlyInput',
    '../model/DistributionType'
], function(module, declare, domClass, ReadOnlyInput, DistributionType) {
    return declare(module.id.replace(/\//g, '.'), ReadOnlyInput, {
        // 値と表示文字列の紐付け
        store: DistributionType,

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
            domClass.add(this.domNode, 'provide-DistributionTypeInput');
        },

        // 値をセットする
        _setValueAttr: function(value) {
            this.inherited(arguments);
            // 取消の場合はスタイルを変更
            domClass.toggle(this.domNode, 'is-cancel', value === '03');
        }
    });
});

