/**
 * レイヤー選択用モジュール。
 * @module app/map/LayerSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/map/LayerSelector',
    './Layers'
    // 以下、変数で受けないモジュール
], function(module, declare, LayerSelector, Layers) {
    /**
     * ヘッダーのメニュー用オブジェクト
     * @class LayerSelector
     */
    return declare(module.id.replace(/\//g, '.'), LayerSelector, /** @lends app/map/LayerSelector~LayerSelector# */ {
        layers: Layers
    });
});
