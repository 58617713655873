/**
 * 警報コードを扱うモデル。
 * @module app/weatherinfo/model/kindCode
 */
define([
    'dstore/Memory'
], function(Memory) {
    /**
     * シングルトンを返す。
     */
    var WARNINGS = new Memory({
        data: [
            {
                id: 'RAIN_SPECIAL_WARNING',
                code: '33',
                className: 'SPECIAL_WARNING'
            },
            {
                id: 'STORM_SPECIAL_WARNING',
                code: '35',
                className: 'SPECIAL_WARNING'
            },
            {
                id: 'STORM_SNOW_SPECIAL_WARNING',
                code: '32',
                className: 'SPECIAL_WARNING'
            },
            {
                id: 'SNOW_SPECIAL_WARNING',
                code: '36',
                className: 'SPECIAL_WARNING'
            },
            {
                id: 'WAVE_SPECIAL_WARNING',
                code: '37',
                className: 'SPECIAL_WARNING'
            },
            {
                id: 'STORM_SURGE_SPECIAL_WARNING',
                code: '38',
                className: 'SPECIAL_WARNING'
            },
            {
                id: 'SEDIMENT_DISASTER_ALERT',
                code: '99',
                className: 'SEDIMENT'
            },
            {
                id: 'HEAVY_RAIN_WARNING',
                code: '03',
                className: 'WARNING'
            },
            {
                id: 'FLOOD_WARNING',
                code: '04',
                className: 'WARNING'
            },
            {
                id: 'STORM_WARNING',
                code: '05',
                className: 'WARNING'
            },
            {
                id: 'STORM_SNOW_WARNING',
                code: '02',
                className: 'WARNING'
            },
            {
                id: 'HEAVY_SNOW_WARNING',
                code: '06',
                className: 'WARNING'
            },
            {
                id: 'WAVE_WARNING',
                code: '07',
                className: 'WARNING'
            },
            {
                id: 'STORM_SURGE_WARNING',
                code: '08',
                className: 'WARNING'
            },
            {
                id: 'HEAVY_RAIN_ADVISORY',
                code: '10',
                className: 'ADVISORY'
            },
            {
                id: 'FLOOD_ADVISORY',
                code: '18',
                className: 'ADVISORY'
            },
            {
                id: 'STRONG_WIND_ADVISORY',
                code: '15',
                className: 'ADVISORY'
            },
            {
                id: 'WIND_AND_SNOW_ADVISORY',
                code: '13',
                className: 'ADVISORY'
            },
            {
                id: 'HEAVY_SNOW_ADVISORY',
                code: '12',
                className: 'ADVISORY'
            },
            {
                id: 'WAVE_ADVISORY',
                code: '16',
                className: 'ADVISORY'
            },
            {
                id: 'STORM_SURGE_ADVISORY',
                code: '19',
                className: 'ADVISORY'
            },
            {
                id: 'LIGHTNING_ADVISORY',
                code: '14',
                className: 'ADVISORY'
            },
            {
                id: 'SNOW_MELTING_ADVISORY',
                code: '17',
                className: 'ADVISORY'
            },
            {
                id: 'HEAVY_FOG_ADVISORY',
                code: '20',
                className: 'ADVISORY'
            },
            {
                id: 'DRYING_ADVISORY',
                code: '21',
                className: 'ADVISORY'
            },
            {
                id: 'AVALANCHE_ADVISORY',
                code: '22',
                className: 'ADVISORY'
            },
            {
                id: 'LOW_TEMPERATURE_ADVISORY',
                code: '23',
                className: 'ADVISORY'
            },
            {
                id: 'FROST_ADVISORY',
                code: '24',
                className: 'ADVISORY'
            },
            {
                id: 'ICING_ADVISORY',
                code: '25',
                className: 'ADVISORY'
            },
            {
                id: 'SNOW_ARRIVAL_ADVISORY',
                code: '26',
                className: 'ADVISORY'
            }
        ]
    });

    WARNINGS.getCode = function(id){
        return WARNINGS.getSync(id).code;
    };

    WARNINGS.getClass = function(id){
        return WARNINGS.getSync(id).className;
    };

    WARNINGS.getSpecialCodes = function(){
        var codes = [];
        WARNINGS.forEach(function(item){
            if(item.className==='SPECIAL_WARNING'){
                codes.push(item.code);
            }
        });
        return codes;
    };

    return WARNINGS;
});