/**
 * 雨量画面の凡例UIウィジェット
 * @module app/beginner/MapDetail
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/MapDetail.html',
    'idis/view/page/_PageBase',
    'dijit/_WidgetBase',
    'dijit/_TemplatedMixin',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'app/common/consts/FunctionCd',
    'dojo/dom-style',
    // 以下、変数から参照されないモジュール
], function(module, declare, template, _PageBase, _WidgetBase, _TemplatedMixin, Router, UserInfo, UserType, FunctionCd, domStyle,) {

    /**
     * 地図画面のUIウィジェット。
     * @class MapDetail
     * @extends module:idis/view/page/_PageBase~_PageBase
     */

    /**
     * メニュー用オブジェクト
     * @class Menu
     */

    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/beginner/MapDetail~MapDetail# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--beginner/mapdetail',

        /**
         * マニュアルファイルPATH
         */
        FAIL_PATH: '/data/manual/',

        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * DOM構築後に呼ばれる
         */
        postCreate: function() {
            this.inherited(arguments);
            this.initMapLink();
        },

        initMapLink: function(){
            // 権限毎にリンクを出し分ける
            console.debug(UserInfo.getUserType());

            domStyle.set(this.mapLink1,'display', 'none');
            domStyle.set(this.mapLink2,'display', 'none');
            domStyle.set(this.mapLink3,'display', 'none');
            domStyle.set(this.mapLink4,'display', 'none');
            domStyle.set(this.mapLink5,'display', 'none');

            switch (UserInfo.getUserType()) {

                // 市町ユーザーの場合
                case UserType.MUNICIPALITY:
                    if(UserInfo.hasWriteAuthz(FunctionCd.SHELTER)){
                        domStyle.set(this.mapLink1,'display', '');
                        domStyle.set(this.mapLink2,'display', '');
                        domStyle.set(this.mapLink3,'display', '');
                    }
                    if(UserInfo.hasWriteAuthz(FunctionCd.EVACORDER)){
                        domStyle.set(this.mapLink3,'display', '');
                        domStyle.set(this.mapLink4,'display', '');
                    }
                    if(UserInfo.hasWriteAuthz(FunctionCd.DAMAGE)){
                        domStyle.set(this.mapLink3,'display', '');
                        domStyle.set(this.mapLink5,'display', '');
                    }
                    break;
                // 振興局・県・その他機関ユーザーの場合
                case UserType.REGION:
                case UserType.PREFECTURE:
                case UserType.OTHER_ORGAN:
                    if(UserInfo.hasWriteAuthz(FunctionCd.SHELTER)){
                        domStyle.set(this.mapLink1,'display', '');
                        domStyle.set(this.mapLink3,'display', '');
                    }
                    if(UserInfo.hasWriteAuthz(FunctionCd.EVACORDER)){
                        domStyle.set(this.mapLink3,'display', '');
                    }
                    if(UserInfo.hasAuthz(FunctionCd.DAMAGE)){
                        domStyle.set(this.mapLink3,'display', '');
                        domStyle.set(this.mapLink5,'display', '');
                    }
                    break;
                // 開発用
                default:
                    domStyle.set(this.mapLink1, 'display', '');
                    domStyle.set(this.mapLink2, 'display', '');
                    domStyle.set(this.mapLink3, 'display', '');
                    domStyle.set(this.mapLink4, 'display', '');
                    domStyle.set(this.mapLink5, 'display', '');
                    break;
            }

        },

        /**
         * 「マニュアルを確認する」がクリックされた時の挙動。
         */
        onMapManualDlLink: function() {
            var failname = 'map.pdf';
            // pdfを別タブで開く
            window.open(this.FAIL_PATH + failname, '地図マニュアル');
        },

        /**
         * 「地図画面を表示する」がクリックされた時の挙動。
         */
        onMapShowLink: function() {
            Router.moveTo('monitoring');
        },


        onMapLink1Click: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            console.debug('[地図情報：地図上で開設中避難所を確認したい。]がクリックされました');
            Router.moveTo('monitoring', {
                monitorTab: 'evacShelterTab',
            });

        },

        onMapLink2Click: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            console.debug('[地図情報：地図上ですべての避難所を確認したい。]がクリックされました');
            Router.moveTo('monitoring', {
                layerType: 'allShelter',
            });
        },

        onMapLink3Click: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            console.debug('[地図情報：ハザードマップを確認したい。]がクリックされました');
            Router.moveTo('monitoring', {
                layerType : 'hazard'
            });
        },

        onMapLink4Click: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            console.debug('[地図情報：地図上で現在発令中の避難情報を確認したい。]がクリックされました');
            Router.moveTo('monitoring', {
                monitorTab: 'evacShelterTab',
            });
        },

        onMapLink5Click: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            console.debug('[地図情報：地図上で被害情報を確認したい。]がクリックされました');
            Router.moveTo('monitoring', {
                monitorTab: 'damageTab',
            });
        },

    });
});
