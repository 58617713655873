/**
 * 親管理番号設定ダイアログ用グリッド
 * @module app/damage/ParentDamageSelectorGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/date/locale',
    'dgrid/Selector',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, locale, Selector, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), [IdisGrid, Selector],
        /** @lends module:app/damage/ParentDamageSelectorGrid~ProvideTemplatePage# */ {

        /**
         * 各列の定義
         * @type {Object[]}
         */
        baseClass: 'timeline-disasterSelector',

        collection: this.store,
        columns: [
            helper.column('disasterId','災害No'),
            // 新潟ソースの被害情報ではlayerNameをタイトル項目として扱っている
            helper.column('disasterName','災害名称'),
            helper.column('disasterType','災害種別'),
            helper.column('headquarterStatus','体制状況'),
            {field: 'zeroHour', label: '基準時刻'}
        ]
    });
});
