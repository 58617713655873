/**
 * ライフライン（停電）情報パネル
 * @module app/monitor/LifelinePanel
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/dom-construct',
    'dojo/json',
    'dojo/text!./templates/LifelinePanel.html',
    'dojo/topic',
    'dojo',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/view/page/_PageBase',
    'app/model/DisasterInfo',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
	'./LifelineDetailDialog'
], function(module, array, declare, lang, locale, domConstruct, JSON, template, topic,
    dojo, UserInfo, Requester, DialogChain, Loader, _PageBase, DisasterInfo) {
    /**
     * ライフライン（停電）情報パネル
     * @class LifelinePanel
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/monitor/LifelinePanel~LifelinePanel# */ {
        // テンプレート文字列
        templateString: template,

        /**
         * 最大表示件数
         */
        MAX_COUNT: 5,

        /**
         * 市町村コード
         */
        _municipalityCd: null,

        /**
         * constructor
         */
        constructor: function() {
            this.chain = DialogChain.get(this);
            this._municipalityCd = UserInfo.getSelectedMunicipalityCd();
            console.debug('ライフライン（停電）情報表示対象の市町村コード：' + this._municipalityCd);
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は関連ダイアログも削除する
            this.own(this.innerLifelineDetailDialog);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
            this.initTable();

            // 市町村切替時にライフライン（停電）情報を更新
            this.own(topic.subscribe('idis/view/form/MunicipalitySelectForm::selected',
                lang.hitch(this, function(payload) {
                    this._municipalityCd = payload.municipalityCd;
                    this.initTable();
            })));

            // 災害切替時にライフライン（停電）情報を更新
            this.own(topic.subscribe('app/view/form/DisasterChanger::updated',
                lang.hitch(this, function() {
                    this.initTable();
            })));
        },


        /**
         * テーブルを初期化します
         */
        initTable: function() {
            // ライフライン（停電）情報概況を設定
            this.setLifelineOverviewInfo();

            // ライフライン（停電）情報一覧を設定
            this.setLifelineListInfo();
        },

        /**
         * ライフライン（停電）情報概況を設定します
         */
        setLifelineOverviewInfo: function() {
            var self = this;

            var disasterId = DisasterInfo.getDisasterId();

            var promise = Requester.get('/data/lifeline/overview/' + disasterId +'/' + this._municipalityCd +'.json', {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(function(item) {
                console.debug('ライフライン（停電）情報（' + disasterId + ', ' +  this._municipalityCd + '）：' +
                    JSON.stringify(item));

                // ライフライン（停電）情報を設定
                self.setLifelineOverview(self, item);

            }, function(error) {
                if (error.response.status === 404) {
                    console.log('ライフライン（停電）情報概況情報が見つかりません。初期値をセットします。' + (error.message ? ('('+error.message+')') : ''));
                    var item = {
                        'powerFailureMunicNum' : 0,
                        'powerFailureHouseNum' : 0
                    };
                    self.setLifelineOverview(self, item);
                } else {
                    self.chain.info('情報の取得に失敗しました。', 'エラー');
                }
            });
            //ローダーの表示
            Loader.wait(promise);
        },

        /**
         * ライフライン（停電）情報を設定します
         */
        setLifelineOverview: function(self, item) {
            self.powerFailureMunicNum.innerHTML = item.powerFailureMunicNum;
            self.powerFailureHouseNum.innerHTML = item.powerFailureHouseNum;
        },

        /**
         * ライフライン（停電）情報一覧を設定します
         */
        setLifelineListInfo: function() {
            var self = this;

            var disasterId = DisasterInfo.getDisasterId();

            var promise = Requester.get('/data/lifeline/list/' + disasterId +'/' + this._municipalityCd +'.json', {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(function(item) {
                console.debug('ライフライン（停電）情報（' + disasterId + ', ' +  this._municipalityCd + '）：' +
                    JSON.stringify(item));

                // ライフライン（停電）情報を設定
                self.setLifelineList(self, item);

            }, function(error) {
                if (error.response.status === 404) {
                    console.log('ライフライン（停電）情報一覧情報が見つかりません。初期値をセットします。' + (error.message ? ('('+error.message+')') : ''));
                    var item = {'items': []};
                    self.setLifelineList(self, item);
                } else {
                    self.chain.info('情報の取得に失敗しました。', 'エラー');
                }
            });
            //ローダーの表示
            Loader.wait(promise);
        },

        /**
         * ライフライン（停電）情報を設定します
         */
        setLifelineList: function(self, data) {
            var self = this;

            var lifelinePanelTBody = dojo.byId('lifelinePanelTBody');
            domConstruct.empty(lifelinePanelTBody);

            // 先頭5件のみ表示
            if (data.items.length > this.MAX_COUNT) {
                data.items.length = this.MAX_COUNT;
            }

            array.forEach(data.items, function(item){
                var reportTimestampString = self.formatDateTime(item.reportTimestamp);
                var restoreTimestampString = self.formatDateTime(item.restoreTimestamp);

                var tr = domConstruct.create('tr');
                domConstruct.place(domConstruct.create('td', {
                    innerHTML: reportTimestampString, style: 'padding-left:10px; width:30%;' }), tr);
                domConstruct.place(domConstruct.create('td', {
                    innerHTML: item.municipalityName + ' ' + item.sort + '<br>' + item.householdNum,
                    style: 'width:55%;' }), tr);
                var object = {
                    'title' : item.title,
                    'contentDescription' : item.contentDescription,
                    'reportTimestamp' : reportTimestampString,
                    'notification' : item.notification,
                    'restoreTimestamp' : restoreTimestampString,
                    'url' : item.url,
                    'householdNum' : item.householdNum,
                    'areas' : item.areas,
                    'publishingOrganizationName' : item.publishingOrganizationName
                };
                var detailTd = domConstruct.create('td', {
                    style: 'text-align: right; padding-right:10px; width:15%; color: blue;' });
                var u = domConstruct.create('u');
                var a = domConstruct.create('a',{
                    innerHTML: '詳細&gt;',
                    href: '#',
                    onclick: function() {
                        self.showDetailDialog(object);
                    }
                });
                domConstruct.place(a, u);
                domConstruct.place(u, detailTd);
                domConstruct.place(detailTd, tr);
                domConstruct.place(tr, lifelinePanelTBody);
            });

        },

        /**
         * 'yyyy-MM-dd HH:mm' 形式に変換
         */
        formatDateTime: function(val) {
            var timestamp = new Date(val);
            var dateLabel = locale.format(timestamp, {
                selector: 'date',
                datePattern: 'yyyy/MM/dd'
            });
            var timeLabel = locale.format(timestamp, {
                selector: 'time',
                timePattern: 'HH:mm'
            });
            return dateLabel + '&nbsp;' + timeLabel;
        },

        showDetailDialog: function (object) {
            console.debug('[詳細]リンクがクリックされました。');
            this.innerLifelineDetailDialog.initDialog(object);
            this.lifelineDetailDialog.show();
        }

    });
});
