/**
 * 災害対策本部設置詳細_配信確認ダイアログ
 * @module app/provide/ProvidePreventionDetailDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/ProvidePreventionDetailDialog.html',
    'idis/util/DateUtils',
    './_ProvideDialogBase',
    // 以下、変数から参照されないモジュール
    'dijit/form/Form',
    'idis/view/form/WordCountTextarea',
    'idis/view/form/Button',
    'idis/view/form/ReadOnlyInput',
    'app/view/form/MunicipalitySelector'
], function(module, declare, template, DateUtils, _ProvideDialogBase) {
    /**
     *  災害対策本部設置詳細_配信確認ダイアログ
     * @class ProvidePreventionDetailDialog
     * @extends module:app/provide/_ProvideDialogBase~_ProvideDialogBase
     */
    return declare(module.id.replace(/\//g, '.'), _ProvideDialogBase,
        /** @lends module:app/provide/_ProvideDialogBase~_ProvideDialogBase# */ {
        // テンプレート文字列
        templateString: template,

        // ベースクラスをオーバーライド
        // 画面から渡された値で初期表示設定を行う。
        initDialog: function(obj){
            // // 配信種別をセットする。
            this.sendCategoryNode.innerHTML = this.getCategoryName(obj.sendCategory);
            // 市町村コードをセットする。
            this.municipalityCd.set('value', obj.areaCd);
            // 配信日時をセットする。
            var sendTimestamp = new Date(obj.sendTimestamp);
            this.sendTimestampNode.innerHTML = DateUtils.format(sendTimestamp);
            // 補足情報
            this.complementaryInfo.set('value', obj.complementaryInfo);
            // 対策本部名を設定
            this.headquarterNameInput.set('value', obj.headquarterName);
            // 設置状況をセットする。
            this.headquarterStatusNode.innerHTML = obj.headquarterStatus;
            // 設置解散日時をセットする。
            var headquarterTimestamp = new Date(obj.headquarterDatetime);
            this.headquarterDatetimeNode.innerHTML = DateUtils.format(headquarterTimestamp);
        }
    });
});

