/**
 * フォルダ管理画面のツリー再構築処理を実装するMixin。
 * @module app/folder/view/_RefreshMixin
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/service/Requester',
    'idis/view/Loader',
    'app/model/LayerStore'
    // 以下、変数として受け取らないモジュール
], function(module, declare, lang, Requester, Loader, LayerStore) {
    /**
     * フォルダ管理画面のツリー再構築操作を実装するMixin。
     * @class _RefreshMixin
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(null, /** @lends module:app/view/folder/view/_RefreshMixin~_RefreshMixin# */ {
        /**
         * 「表示情報ツリーを再構築」ボタンがクリックされた際に呼ばれる。
         * @param {MouseEvent} evt クリック・イベント
         */
        onRefreshButtonClick: function(/* evt */) {
            // 確認ダイアログを表示
            this.chain.confirm([
                'データベースの内容を元に表示情報ツリーを再構築しますか？',
                '（完了までに時間がかかる場合があります）'
            ].join('<br>'), function(chain) {
                // OKボタンクリック時
                // ローディング表示＆リクエスト
                Loader.wait(Requester.post('/api/layers/refresh').then(function() {
                    // 成功時はLayerStoreを全て更新（ローディング表示はこれも待つ）
                    return LayerStore.refreshAll();
                }).then(lang.hitch(this, function() {
                    // リクエストとLayerStore更新の両方に成功時
                    // この画面のツリーを再描画
                    this.resetTree();
                    chain.infoComplete();
                }), function(err) {
                    // いずれかに失敗時
                    chain.infoError(err);
                }));
            });
        }
    });
});

