/**
 * 住所情報地図表示一覧モジュール
 * @module app/view/page/GeoplotPage
 */
define([
    'module',
    'app/model/LayerStore',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/GeoplotPage.html',
    'dstore/Memory',
    'idis/consts/ACL',
    'idis/model/UserInfo',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/view/page/_PageBase',
    'idis/store/IdisRest',
    'idis/service/Requester',
    // 以下、変数として受け取らないモジュール
    'idis/view/grid/IdisGrid',
    'idis/view/form/Button',
    'idis/view/form/AclButton',
    'idis/view/form/DateTimeInput',
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    './GeoplotDetailDialog',
    './GeoplotRegisterDialog',
    './GeoplotGrid',
    '../view/form/DisasterSelector'
], function(module, LayerStore, declare, lang, template, Memory, ACL, UserInfo,
    DialogChain, Loader, _PageBase, IdisRest, Requester) {

    /**
     * 住所情報地図表示一覧画面
     * @class GeoplotPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/view/page/GeoplotPage~GeoplotPage# */ {

        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--geoplot',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'geoplotId',
                target: '/api/geoplot'
            });

            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },

        startup: function(){
          this.inherited(arguments);
          this.setButtonStatus();
        },

        // ユーザー権限が「閲覧ユーザ」の場合は新規登録ボタンを非活性に
        setButtonStatus: function(){
            var role = UserInfo.getRoleCd();
            if (role === ACL.VIEW_USER) {
                this.uploadButton.setDisabled(true);
            }
        },

        /**
        * グリッドの検索条件を指定された値で更新する
        * @param {Object} value name属性と値のマッピング
        */
        updateGridQuery: function() {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.grid.set('collection', collection);
        },

        /**
        * 新規登録ダイアログを表示する
        * テンプレートHTMLでdata-dojo-attach-eventプロパティーを用い、
        * 新規登録ボタンのクリックイベントにこのメソッドを紐付けている
        */
        showRegisterDialog: function() {
            // ダイアログの最初の子要素が登録画面
            var page = this.registerDialog.getChildren()[0];
            // 画面上のフォームをリセット
            // （ダイアログ内画面のテンプレートHTMLでdata-dojo-attach-point="form"指定済み）
            page.form.reset();
            // innerHTMLに値を設定
            this.innerRegisterDialog.setPage();
            // ダイアログを表示
            this.registerDialog.show();
        },

        /**
        * 詳細ダイアログを表示する
        * @param {Object} item 参照する行のデータ
        */
        showDetailDialog: function(item) {
            // ダイアログの最初の子要素が詳細画面
            var page = this.detailDialog.getChildren()[0];
            // 画面上のフォームをリセット
            page.form.reset();
            // innerHTMLに値を設定
            this.innerDetailDialog.setPage(item);
            // 詳細ダイアログを表示
            this.detailDialog.show();
        },

        /**
        * アップロードしたファイルをcsv形式でダウンロードする。
        * @param {Object} item ダウンロードする行のデータ
        */
        downloadFile: function(item){
            var url = '/api/geoplot/geoplot.csv?layerId=' + item.layerId;
            // 確認ダイアログを表示
            this.chain.confirm('CSVを出力します。<br>' +
                'よろしいですか？', function(chain) {
                // 確認ダイアログでOKを押した場合
                chain.hide();
                // URLを遷移する
                window.location.href=url;

                // ボタンにセットしたイベントが外れないよう、再度グリッドを初期化
                this.initGrid();
                this.initRegisterPage();
                this.initDetailPage();
            });
        },

        /**
        * グリッドを初期化する
        */
        initGrid: function() {
            // 全件検索のcollectionをグリッドにセットする
            // （テンプレートHTML内のdata-dojo-attach-point="grid"でグリッドをthis.gridに紐付け済み）
            this.grid.set('collection', this.store.filter());
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.grid.on('detailButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                this.showDetailDialog(evt.item);
            }));

            // グリッドの出力ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.grid.on('outputButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                this.downloadFile(evt.item);
            }));
        },

        /**
        * 登録画面のフォームが投稿された際の動作を設定する
        */
        initRegisterPage: function() {
            // 登録ダイアログの最初の子要素が登録画面
            var dialog = this.registerDialog;
            var page = dialog.getChildren()[0];
            // 登録画面のsendイベントを受け取る
            page.on('send', lang.hitch(this, function(evt) {
                this.chain.confirmAdd(function(chain) {
                    var promise = this.store.add(evt.value);
                    Loader.wait(promise).then(function(data) {
                        console.log(data);
                        // 確認ダイアログを閉じる
                        chain.infoComplete();
                        // 登録フォーム・ダイアログを閉じる
                        dialog.hide();
                        //ツリーを更新する
                        LayerStore.refresh(data.parentLayerId);
                        LayerStore.refresh(data.grandPrentLayerId);
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
        },


        /**
        * 詳細画面のフォームが投稿された際の動作を設定する
        */
        initDetailPage: function() {
            // 詳細ダイアログの最初の子要素が詳細画面
            var dialog = this.detailDialog;
            var page = dialog.getChildren()[0];
            // 詳細画面のsendイベントを受け取る
            page.on('sendupdate', lang.hitch(this, function(evt) {
                this.chain.confirmPut(function(chain) {
                    Loader.wait(Requester.put('/api/geoplot/' + evt.id, {
                        data: evt.value
                    })).then(lang.hitch(this, function() {
                        this.updateGridQuery();
                        chain.infoComplete();
                        dialog.hide();
                    }, function(err) {
                        chain.infoError(err);
                    }));
                });
            }));
            page.on('senddelete', lang.hitch(this, function(evt) {
                this.chain.confirmDel(function(chain) {
                    Loader.wait(Requester.del('/api/geoplot/' + evt.value))
                    .then(lang.hitch(this, function() {
                        this.updateGridQuery();
                        dialog.hide();
                        chain.infoComplete();
                    }), function(err) {
                        chain.infoError(err);
                    });
                });
            }));
        },

        /**
        * DOMノードを生成する
        */
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は関連ダイアログも削除する
            // （ダイアログは初期化時にbody直下へ移動するため、明示的に指定しないと消えずに残ってしまう）
            this.own(this.registerDialog);
            // グリッドを初期化する
            this.initGrid();
            // 登録画面内のフォームがsubmitされた際の動作を設定
            this.initRegisterPage();
            // 詳細画面内のフォームがsubmitされた際の動作を設定
            this.initDetailPage();
        }
    });
});
