/**
 * 災害情報詳細/更新画面用モジュール。
 * @module app/timeline/TimelineInfoChange
 */
define([
    'module',
    'dojo/json',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/topic',
    'dojo/text!./templates/TimelineInfoChangeDialog.html',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    // 以下、変数から参照されないモジュール
    'dijit/form/Form',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'dijit/form/Textarea',
    'dijit/form/CheckBox',
    'dijit/form/DateTextBox',
    'dijit/form/Select',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/CheckGroup',
    'idis/view/form/RadioGroup',
    'idis/view/form/DateTimeInput'
], function(module, json, declare, lang, array, topic, template, InfoDialog, _PageBase) {
    /**
     * 災害情報登録画面。
     * @class TimelineInfoChange
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/timeline/TimelineInfoChange~TimelineInfoChange# */ {
        // テンプレート文字列
        templateString: template,

        onSubmit: function() {
            topic.publish(module.id + '::update',{
                data: this.changeForm.get('value')
            });
        }
    });
});
