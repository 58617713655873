/**
 * コードを扱うモデル。
 * @module app/weatherinfo/model/kindCodeName
 */
 define([
    'dstore/Memory'
], function(Memory) {
    /**
     * シングルトンを返す。
     */
    var WARNINGS = new Memory({
        data: [
            {
                id: '33',
                name: '大雨',
                className: 'SPECIAL_WARNING'
            },
            {
                id: '35',
                name: '暴風',
                className: 'SPECIAL_WARNING'
            },
            {
                id: '32',
                name: '暴風雪',
                className: 'SPECIAL_WARNING'
            },
            {
                id: '36',
                name: '大雪',
                className: 'SPECIAL_WARNING'
            },
            {
                id: '37',
                name: '波浪',
                className: 'SPECIAL_WARNING'
            },
            {
                id: '38',
                name: '高潮',
                className: 'SPECIAL_WARNING'
            },
            {
                id: '03',
                name: '大雨',
                className: 'WARNING'
            },
            {
                id: '04',
                name: '洪水',
                className: 'WARNING'
            },
            {
                id: '05',
                name: '暴風',
                className: 'WARNING'
            },
            {
                id: '02',
                name: '暴風雪',
                className: 'WARNING'
            },
            {
                id: '06',
                name: '大雪',
                className: 'WARNING'
            },
            {
                id: '07',
                name: '波浪',
                className: 'WARNING'
            },
            {
                id: '08',
                name: '高潮',
                className: 'WARNING'
            },
            {
                id: '10',
                name: '大雨',
                className: 'ADVISORY'
            },
            {
                id: '18',
                name: '洪水',
                className: 'ADVISORY'
            },
            {
                id: '15',
                name: '強風',
                className: 'ADVISORY'
            },
            {
                id: '13',
                name: '風雪',
                className: 'ADVISORY'
            },
            {
                id: '12',
                name: '大雪',
                className: 'ADVISORY'
            },
            {
                id: '16',
                name: '波浪',
                className: 'ADVISORY'
            },
            {
                id: '19',
                name: '高潮',
                className: 'ADVISORY'
            },
            {
                id: '14',
                name: '雷',
                className: 'ADVISORY'
            },
            {
                id: '17',
                name: '融雪',
                className: 'ADVISORY'
            },
            {
                id: '20',
                name: '濃霧',
                className: 'ADVISORY'
            },
            {
                id: '21',
                name: '乾燥',
                className: 'ADVISORY'
            },
            {
                id: '22',
                name: 'なだれ',
                className: 'ADVISORY'
            },
            {
                id: '23',
                name: '低温',
                className: 'ADVISORY'
            },
            {
                id: '24',
                name: '霜',
                className: 'ADVISORY'
            },
            {
                id: '25',
                name: '着氷',
                className: 'ADVISORY'
            },
            {
                id: '26',
                name: '着雪',
                className: 'ADVISORY'
            },
            {
                id: 'L1',
                name: '土砂警解除',
                className: 'SEDIMENT-LIFT'
            },
            {
                id: 'L3',
                name: '土砂警発表',
                className: 'SEDIMENT'
            }
        ]
    });

    WARNINGS.getClass = function (id) {
        return WARNINGS.getSync(id).className;
    };

    WARNINGS.getName = function (id) {
        return WARNINGS.getSync(id).name;
    };

    return WARNINGS;
});