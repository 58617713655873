/**
 * 災害体制の列定義一覧。
 * @module app/dam/grid/DamCommonColumn
 */
define([
    'idis/view/grid/helper',
    'app/view/grid/CommonColumn'
], function(helper, CommonColumn) {
    return {
        damQuantitiesLevel: helper.column('damQuantitiesLevel', '貯水位[m]', {
            formatter: function(value, item) {
                return CommonColumn.formatObservationData(item, 'damQuantitiesLevel', 2);
            },
            className : function(item) {
                // ヘッダーの場合はclassNameを返さない
                if (!item) { return; }
                // 0:通常   1:注意   2:警報
                var excess = 0;
                if(item.normalFullWaterLevel){
                    excess = item.normalFullWaterLevel<=item.damQuantitiesLevel ? 1 : excess;
                }
                if(item.floodWaterLevel){
                    excess = item.floodWaterLevel<=item.damQuantitiesLevel ? 2 : excess;
                }
                return CommonColumn.getClassNameExcess(item, 'damQuantitiesLevel', excess);
            }, sortable: false
        }),
        damInflowQuantities: helper.column('damInflowQuantities', '流入量[m^3/s]',{
            formatter: function(value, item) {
                return CommonColumn.formatObservationData(item, 'damInflowQuantities', 3);
            },
            className : function(item) {
                // ヘッダーの場合はclassNameを返さない
                if (!item) { return; }
                // 洪水調整中か
                var excess = item.ventFlg==='1' ? 2 : 0;
                return CommonColumn.getClassNameExcess(item, 'damInflowQuantities', excess);
            }, sortable: false
        }),
        damTotalReleaseQuantities: helper.column('damTotalReleaseQuantities', '全放流量[m^3/s]',{
            formatter: function(value, item) {
                return CommonColumn.formatObservationData(item, 'damTotalReleaseQuantities', 3);
            },
            className : function(item) {
                // ヘッダーの場合はclassNameを返さない
                if (!item) { return; }
                return CommonColumn.getClassNameExcess(item, 'damTotalReleaseQuantities');
            }, sortable: false
        }),
        damEffectiveStorageQuantities: helper.column('damEffectiveStorageQuantities', '貯水量[1000m^3]', {
                    formatter: function(value, item) {
                        return CommonColumn.formatObservationData(item, 'damEffectiveStorageQuantities');
                    },
                    className : function(item) {
                        // ヘッダーの場合はclassNameを返さない
                        if (!item) { return; }
                        return CommonColumn.getClassNameExcess(item, 'damEffectiveStorageQuantities');
                    }, sortable: false
        }),
        storageRateWaterUseCapacity: helper.column('storageRateWaterUseCapacity', '利水貯水率 [%]', {
            formatter: function(value, item) {
                return CommonColumn.formatObservationData(item, 'storageRateWaterUseCapacity', 1);
            },
            className : function(item) {
                // ヘッダーの場合はclassNameを返さない
                if (!item) { return; }
                var excess = item.storageRateWaterUseCapacity<50 ? 1 : 0;
                return CommonColumn.getClassNameExcess(item, 'storageRateWaterUseCapacity', excess);
            }, sortable: false
        })
    };
});

