define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, lang, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            helper.buttonColumn('detail', '詳細'),
            {field: 'photoPath', label: '写真',
                formatter: function(value, item){
                    return '<img style="height:80px;" src=' + item.photoPath + '>' ;
                }
            },
            helper.column('organizationName', '報告部署'),
            helper.column('pubStatus', '公開範囲', {
                formatMap: {
                    '0':  '一時保存',
                    '1':  '公開（地域振興局）',
                    '2':  '公開（事業課）',
                    '3':  '公開（道土整備部）',
                    '4':  '公開（全体）'
                }
            }),
            helper.column('photoTimestamp', '撮影日時'),
            helper.column('subject', '報告名'),
            {field: 'comment', label: 'コメント',
                formatter: function(value, item){
                    return item.comment;
                }
            }
        ]
    });
});
