/**
 * お知らせ一覧画面用モジュール
 * 
 */

define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/date/locale',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'dojo/dom-construct',
    'dojo/on',
    './BbsTitleContent',
    './BbsNotifyContent',
    // 以下、変数として受け取らないモジュール
    'dojox/lang/functional/array'
], function(module, declare, lang, array, locale, IdisGrid, helper, domConstruct, on, BbsTitleContent, BbsNotifyContent) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--bbs',

        // IdisGrid拡張：行自体にCSSクラスを追加する場合に指定する
        rowClassName: function(item) {
            var currentTime = new Date();
            var pubEndTimestamp = new Date(item.pubEndTimestamp);
            return item && (item.read === false) && currentTime <= pubEndTimestamp ? 'red-row' : '';
        },


        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            // 編集ダイアログボタン
            helper.buttonColumn('detail', '詳細', '詳細'),

            {field: 'title', label: 'タイトル・本文',
                id: 'title',
                renderCell: function(item) {

                    // タイトル文字列
                    var text = item.title;
                    // 内容文字列
                    var content = item.contents;
                    if(content){
                        if(content.length > 50){
                            content = content.substring(0, 50) + '...';
                        }
                    }
                    var bbsContent = new BbsTitleContent({
                        title: text,
                        contents: content
                    });
                    return bbsContent.domNode;
                }
            },

            // 添付ファイル
            {field: 'attachFiles', label: '添付ファイル', sortable: false,
                renderCell: function(items) {
                    var attachNode = domConstruct.create('span');
                    var gridNode = this.grid.domNode;
                    if(items.attachFiles && items.attachFiles.length !== 0) {
                        items.attachFiles.forEach(function(item) {
                            var path = item.attachFilePath.replace('out', 'data');
                            var name = item.attachFileName;
                            var node = null;
                            var userAgent = window.navigator.userAgent.toLowerCase();
                            if(userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)){
                                // IEの場合、download属性が効かないため、右クリック保存などaタグ機能を無効化.
                                // ダウンロード関数により元ファイル名でのダウンロードを可能にする.
                                node = domConstruct.create('a', {
                                    innerHTML: name,
                                    href: '#'
                                });
                                //クリックでファイル取得処理に入る
                                node.onclick = function() {
                                    on.emit(gridNode, 'attachFileLinkClick', {item: item});
                                };
                            } else {
                                // 報告書リンクを作成
                                node = domConstruct.create('a', {
                                    innerHTML: name,
                                    href: path,
                                    download: name
                                });
                            }
                            domConstruct.place(node, attachNode);
                            domConstruct.place('<br>', attachNode);
                        });
                        return attachNode;
                    } else {
                        var node = null;
                        // 報告書リンクを作成
                        node = domConstruct.create('a', {
                            innerHTML: '―',
                        });
                        domConstruct.place(node, attachNode);
                        return attachNode;
                    }
                }
            },
            //カテゴリ
            {field: 'category', label: 'カテゴリ', sortable: true,
                formatter: lang.hitch(this, function(item){
                    var type = null;
                        switch(item) {
                            case '01':
                                type = 'メンテナンス';
                                break;
                            case '02':
                                type = 'お知らせ';
                                break;
                            case '03':
                                type = '要請';
                                break;
                            case '04':
                                type = 'その他';
                                break;
                        }
                    return type;
                })
            },

            //宛先
            {field: 'bbsShares', label: '宛先', sortable: false,
                formatter: lang.hitch(this, function (value, item) {
                    var destinationList = [];
                    array.forEach(item.bbsShares, function (share) {
                        if (share.destinationFlg === '1') {
                            destinationList.push(share.shareName);
                        }
                    }, this);

                    if (!destinationList || destinationList.length === 32) {
                        return '全体';
                    } else if (destinationList.length > 2) {
                        return destinationList[0] + '<br>他' + (destinationList.length - 1) + '件';
                    }
                    return destinationList.join('<br>');
                })
            },

            // 発表日時
            {field: 'pubStartTimestamp', label: '発表日時', sortable: true,
                formatter: lang.hitch(this, function(item){
                    if(item) {
                        return locale.format(new Date(item));
                    }
                    return '';
                })
            },

            {field: 'notifyFlg', label: '通知要否・未読通知掲載期限', sortable: false,
                id: 'notifyFlg',
                renderCell: function(item) {

                    // 通知要否文字列
                    if (item.notifyFlg === '02'){
                        var notifyFlg = '〇';
                    } else {
                        var notifyFlg = '―';
                    }
                    //掲載期限文字列
                    if(item.notifyFlg === '02'){
                        var pubEndTimestamp = '期限：' + locale.format(new Date(item.pubEndTimestamp));
                    } else {
                        pubEndTimestamp = '';
                    }
                    var bbsContent = new BbsNotifyContent({
                        notify: notifyFlg,
                        timestamp: pubEndTimestamp
                    });
                    return bbsContent.domNode;
                }
            }
        ]
    });
});
