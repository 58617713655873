/**
 * 未読情報パネル
 * @module app/monitor/UnreadInfoPanel
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/dom-construct',
    'dojo/dom-style',
    'dojo/json',
    'dojo/promise/all',
    'dojo/text!./templates/UnreadInfoPanel.html',
    'dojo/topic',
    'dojo',
    'idis/consts/USER_TYPE',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/view/dialog/ConfirmDialog',
    'idis/view/dialog/DialogChain',
    'idis/view/page/_PageBase',
    'app/common/consts/FunctionCd',
    'app/chronology/consts/ChronologyType',
    'app/model/DisasterInfo',
    '../config',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
	'./LifelineDetailDialog'
], function(module, array, declare, lang, Deferred, domConstruct, domStyle, JSON, all, template, topic,
    dojo, USER_TYPE, Router, UserInfo, Requester, ConfirmDialog, DialogChain, _PageBase,
    FunctionCd, ChronologyType, DisasterInfo, config) {
    /**
     * 未読情報パネル
     * @class UnreadInfoPanel
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/monitor/UnreadInfoPanelPanel~UnreadInfoPanel# */ {
        // テンプレート文字列
        templateString: template,

        timer: null,

        /**
         * 市町村コード
         */
        _municipalityCd: null,

        /**
         * constructor
         */
        constructor: function() {
            this.chain = DialogChain.get(this);
            this._municipalityCd = UserInfo.getSelectedMunicipalityCd();
            console.debug('未読情報表示対象の市町村コード：' + this._municipalityCd);
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);

            // 市町村切替時に未読情報を更新
            this.own(topic.subscribe('idis/view/form/MunicipalitySelectForm::selected',
                lang.hitch(this, function(payload) {
                    this._municipalityCd = payload.municipalityCd;
                    this.setUnreadInfo();
            })));

            // 災害切替時に未読情報を更新
            this.own(topic.subscribe('app/view/form/DisasterChanger::updated',
                lang.hitch(this, function() {
                    this.setUnreadInfo();
            })));

            // 未読情報を更新
            // 監視ページのstartupから呼び出される
            this.own(topic.subscribe('app/view/page/MonitoringPage::initUnreadInfoPanel',
                lang.hitch(this, function() {
                    this.setUnreadInfo();
            })));

            // 画面自動更新用のタイマーを設定(1分毎)
            this.timer = setInterval(lang.hitch(this, function() {
                this.setUnreadInfo();
            }), 1 * 60 * 1000);
        },

        /**
         * widgetが居なくなる時に呼ばれる
         * 定期処理を止める
         */
        destroy: function() {
            this.inherited(arguments);
            clearInterval(this.timer);
        },

        setUnreadInfo: function () {
            var self = this;

            var bbsCount = 0;
            var chronologyCount = 0;
            var scheduledReportCount = 0;

            all({
                bbs: this.getBbsUnreadInfo(),
                chronology: this.getChronologyUnreadInfo(),
                scheduledReport: this.getScheduledReportUnreadInfo()
            }).then(lang.hitch(this, function (result) {
                if (result.bbs) {
                    bbsCount = result.bbs.unreadCount;
                }
                if (result.chronology) {
                    chronologyCount = result.chronology.unreadCount;
                }
                if (result.scheduledReport) {
                    scheduledReportCount = result.scheduledReport.unreadCount;
                }
                self.setUnreadInfoList(bbsCount, chronologyCount, scheduledReportCount);
            }));

        },

        /**
         * 掲示板の未読件数取得
         */
        getBbsUnreadInfo: function() {
            var dfd = new Deferred();

            // F07002：掲示板（掲示板利用）
            if (!UserInfo.hasAuthz('F07002')) {
                dfd.resolve({"unreadCount":0});
                return dfd.promise;
            }

            var now = new Date();
            var registerTimestampTo = new Date(now.getFullYear(), now.getMonth(), now.getDate(),
                now.getHours(), now.getMinutes());
            var pubTimestampFrom = new Date(now.getFullYear(), now.getMonth(), now.getDate(),
                now.getHours(), now.getMinutes());

            // 未読情報のみを取得する
            var url = '/api/bbses?disasterId=' + DisasterInfo.getDisasterId() +
                        '&notifyFlg2=02' + // 宛先ユーザへの通知：有
                        '&notifyFlg=1' + // 自分宛かつ要通知
                        '&unreadFlg=1' +
                        '&registerTimestampTo=' + registerTimestampTo.getTime() +
                        '&pubTimestampFrom=' + pubTimestampFrom.getTime();

            Requester.get(url).then(lang.hitch(this, function (data) {
                console.log('掲示板の未読件数：' + JSON.stringify(data.items.length));
                dfd.resolve({"unreadCount":data.items.length});
            }), function (error) {
                if (error.response.status === 401) {
                    // 自動ログアウトのメッセージをダイアログで表示
                    new ConfirmDialog({
                        title: '自動ログアウト',
                        content: '一定時間が経過した為、自動的にログアウトされました。<br>ログインしなおしてください。',
                        showCancel: false,
                        onOK: function() {
                            if (document.logout) {
                                // ログアウトフォームをサブミット
                                document.logout.submit();
                            }
                        }
                    }).show();
                } else {
                    console.error('掲示板の未読件数取得でエラー発生', error);
                    dfd.resolve({"unreadCount":0});
                }
            });
            return dfd.promise;
        },

        /**
         * クロノロジの未読件数取得
         */
        getChronologyUnreadInfo: function() {
            var dfd = new Deferred();

            // F05001：クロノロジー管理
            if (!UserInfo.hasAuthz('F05001')) {
                dfd.resolve({"unreadCount":0});
                return dfd.promise;
            }

            // 未読情報のみを取得する
            var url = '/api/chronologies?disasterId=' + DisasterInfo.getDisasterId() +
                        '&resultLimitFlg=0' + 
                        '&chronologyTypes=' + ChronologyType.DISASTER_HLD.id + // 「要請」のみ
                        '&unreadFlg=1' +
                        '&count=0'; // 全件数のみ取得できれば良いので内容は取らない

            // 取得対象を、ユーザの市町・振興局のものに限定。関係機関は県扱い。
            if (UserInfo.getUserType() === USER_TYPE.PREFECTURE) {
                url += '&userMunicipalityCd=' + UserInfo.getMunicipalityCd();
                url += '&userOrganizationCd=' + UserInfo.getLowestOrganizationCd();

            } else if (UserInfo.getUserType() === USER_TYPE.REGION) {
                url += '&userMunicipalityCd=' + config.municInfo.prefMunicCd;
                url += '&userOrganizationCd=' + UserInfo.getLowestOrganizationCd();
                url += '&regionCd=' + UserInfo.getRegionCd();

            } else if (UserInfo.getUserType() === USER_TYPE.MUNICIPALITY) {
                url += '&userMunicipalityCd=' + UserInfo.getMunicipalityCd();
                url += '&userOrganizationCd=' + UserInfo.getLowestOrganizationCd();
                url += '&municipalityCd=' + UserInfo.getMunicipalityCd();
                url += '&organizationCd=' + UserInfo.getLowestOrganizationCd();

            } else if (UserInfo.getUserType() === USER_TYPE.OTHER_ORGAN) {
                url += '&userMunicipalityCd=' + config.municInfo.prefMunicCd;
                url += '&userOrganizationCd=' + UserInfo.getLowestOrganizationCd();
                url += '&municipalityCd=' + config.municInfo.prefMunicCd;
                url += '&organizationCd=' + UserInfo.getLowestOrganizationCd();
            }

            Requester.get(url).then(lang.hitch(this, function (data) {
                console.log('クロノロジの未読件数：' + JSON.stringify(data.total));
                dfd.resolve({"unreadCount": data.total});
            }), function (error) {
                if (error.response.status === 401) {
                    // 自動ログアウトのメッセージをダイアログで表示
                    new ConfirmDialog({
                        title: '自動ログアウト',
                        content: '一定時間が経過した為、自動的にログアウトされました。<br>ログインしなおしてください。',
                        showCancel: false,
                        onOK: function() {
                            if (document.logout) {
                                // ログアウトフォームをサブミット
                                document.logout.submit();
                            }
                        }
                    }).show();
                } else {
                    console.error('クロノロジの未読件数取得でエラー発生', error);
                    dfd.resolve({"unreadCount":0});
                }
            });
            return dfd.promise;
        },

        /**
         * 定時報告依頼の未読件数取得
         */
        getScheduledReportUnreadInfo: function() {
            var dfd = new Deferred();

            // F05009：定時報告実施
            if (!UserInfo.hasAuthz('F05009')) {
                dfd.resolve({"unreadCount":0});
                return dfd.promise;
            }

            var now = new Date();
            var aggrTimestampTo = new Date(now.getFullYear(), now.getMonth(), now.getDate(),
                now.getHours(), now.getMinutes());

            // 未読情報のみを取得する
            var url = '/api/scheduledReports?disasterId=' + DisasterInfo.getDisasterId() +
                        '&allReportFlg=1' +         // 全件表示フラグ（被害がなくても表示）
                        '&requestingReportFlg=1' +  // 未回答分を表示
                        '&aggrDateTimeTo=' + aggrTimestampTo.getTime() +
                        '&unreadFlg=1' +
                        '&reportType=01&municipalityCd=' + UserInfo.getMunicipalityCd();

            Requester.get(url).then(lang.hitch(this, function (data) {
                console.log('定時報告依頼の未読件数：' + JSON.stringify(data.items.length));
                dfd.resolve({"unreadCount":data.items.length});
            }), function (error) {
                if (error.response.status === 401) {
                    // 自動ログアウトのメッセージをダイアログで表示
                    new ConfirmDialog({
                        title: '自動ログアウト',
                        content: '一定時間が経過した為、自動的にログアウトされました。<br>ログインしなおしてください。',
                        showCancel: false,
                        onOK: function() {
                            if (document.logout) {
                                // ログアウトフォームをサブミット
                                document.logout.submit();
                            }
                        }
                    }).show();
                } else {
                    console.error('定時報告依頼の未読件数取得でエラー発生', error);
                    dfd.resolve({"unreadCount":0});
                }
            });
            return dfd.promise;
        },

        /**
         * 未読情報を表示します
         */
        setUnreadInfoList: function(bbsCount, chronologyCount, scheduledReportCount) {

            var unreadInfoList = dojo.byId('unreadInfoList');
            domConstruct.empty(unreadInfoList);

            var openFlg = false;

            if (bbsCount > 0) {
                openFlg = true;
                var li = domConstruct.create('li');
                var u = domConstruct.create('u');
                var a = domConstruct.create('a',{
                    innerHTML: bbsCount + '件',
                    href: '#',
                    onclick: function() {
                        Router.moveTo('bbs');
                    }
                });
                var s1 = domConstruct.create('span', { innerHTML: '掲示板に未読情報が'});
                var s2 = domConstruct.create('span', { innerHTML: 'あります。'});
                domConstruct.place(a, u);
                domConstruct.place(s1, li);
                domConstruct.place(u, li);
                domConstruct.place(s2, li);
                domConstruct.place(li, unreadInfoList);
            }
            if (chronologyCount > 0) {
                openFlg = true;
                var li = domConstruct.create('li');
                var u = domConstruct.create('u');
                var a = domConstruct.create('a',{
                    innerHTML: chronologyCount + '件',
                    href: '#',
                    onclick: function() {
                        Router.moveTo('chronology');
                    }
                });
                var s1 = domConstruct.create('span', { innerHTML: 'クロノロジの要請が'});
                var s2 = domConstruct.create('span', { innerHTML: 'あります。'});
                domConstruct.place(a, u);
                domConstruct.place(s1, li);
                domConstruct.place(u, li);
                domConstruct.place(s2, li);
                domConstruct.place(li, unreadInfoList);
            }
            if (scheduledReportCount > 0) {
                openFlg = true;
                var li = domConstruct.create('li');
                var u = domConstruct.create('u');
                var a = domConstruct.create('a',{
                    innerHTML: '定時報告依頼',
                    href: '#',
                    onclick: function() {
                        Router.moveTo('report/sche');
                    }
                });
                var s = domConstruct.create('span', { innerHTML: 'があります。'});
                domConstruct.place(a, u);
                domConstruct.place(u, li);
                domConstruct.place(s, li);
                domConstruct.place(li, unreadInfoList);
            }

            // パネル自体の表示/非表示を設定（タブコンテナの高さを変更するためMonitoringPageにpublishする）
            topic.publish(module.id + '::showHideUnreadInfoPanel', openFlg);

        },

        onUnreadInfoShowClick: function() {
            domStyle.set(this.unreadInfo, 'display', '');
            domStyle.set(this.unreadInfoShowMarker, 'display', 'none');
            domStyle.set(this.unreadInfoHideMarker, 'display', '');
        },
        onUnreadInfoHideClick: function() {
            domStyle.set(this.unreadInfo, 'display', 'none');
            domStyle.set(this.unreadInfoShowMarker, 'display', '');
            domStyle.set(this.unreadInfoHideMarker, 'display', 'none');
        }

    });
});
