/**
 * 事前定義規制区間の選択ダイアログ。
 * @module app/traffic/view/RegSectionPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/RegSectionPage.html',
    'idis/view/page/_PageBase',
    // 以下、変数から参照されないモジュール
    'dijit/form/Form',
    'dijit/form/TextBox',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput'
], function(module, declare, template, _PageBase) {
    /**
     * 事前定義規制区間の選択ダイアログ。
     * @class RegSectionPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/RegSectionPage~RegSectionPage# */
        {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--traffic',

            // resizeメソッドを定義すると外側のresize時に合わせて呼ばれる
            resize: function(changeSize, resultSize) {
                // リサイズ時は中のBorderContainerに追随させる
                // （ウィジェット自体とBorderContainerで大きさが同一なので、サイズはそのまま渡す）
                this.regSectionBorderContainer.resize(changeSize, resultSize);
            }

        });
});
