define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/array',
    'dojo/_base/lang',
    'dojo/text!./templates/DialogContent.html',
    'dstore/Memory',
    'idis/service/Requester',
    'idis/view/_IdisWidgetBase',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/IdisDialog',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    // 以下、変数で受けないモジュール
    'dijit/form/Button',
    'dijit/layout/LayoutContainer',
    'dijit/layout/ContentPane',
    'app/damage/integrate/DamageListGrid'
], function(module, declare, array, lang, template, Memory,
    Requester, _IdisWidgetBase, DialogChain, IdisDialog, InfoDialog, Loader) {

    var thisName = module.id.substring(module.id.lastIndexOf('/') + 1);
    var content = declare(thisName + 'Content', _IdisWidgetBase, {
        templateString: template,
        baseClass: thisName + '-Container',
        widgetsInTemplate: true
    });

    return declare(module.id.replace(/\//g, '.'), IdisDialog, {

        noUnderlay: true,
        title: '被害統合',
        hasData: false,
        store: null,

        constructor: function(options) {
            lang.mixin(this, options);
            this.inherited(arguments);

            this.inner = new content();
            this.own(this.inner);

            this.chain = DialogChain.get(this);
            this._newStore();
        },

        /**
        * widget生成後, 画面表示前
        */
        postCreate: function () {
            this.inherited(arguments);
            this.set('content', this.inner);
            this.inner.confirmButton.on('click', lang.hitch(this, this.onConfirm));
            this.inner.cancelButton.on('click', lang.hitch(this, this.onCancel));
        },

        setData: function(data) {
            this.inner.grid.reset();

            array.forEach(data, lang.hitch(this, function(item) {
                this.store.addSync(item);
            }));

            this.inner.grid.set('collection', this.store);
            this.hasData = true;
        },

        onConfirm: function() {
            this.inherited(arguments);

            if (!this.inner.grid.parentId) {
                InfoDialog.show('入力チェック', '親となる被害報告を指定してください');
                return;
            }

            if (this.inner.grid.children.length === 0) {
                InfoDialog.show('入力チェック', '子となる被害報告を1つ以上指定してください');
                return;
            }

            this.chain.confirmAdd(function(chain) {
                var promise = Requester.post('/api/damageReports/integrate', {
                    data: {
                        parentId: this.inner.grid.parentId,
                        children: this.inner.grid.children
                    }
                });

                //ローダーの表示
                Loader.wait(promise).then(function() {
                    chain.infoComplete(function() {
                        chain.hide();
                        this.close();
                        location.reload();
                    });
                }, function(error) {
                    chain.infoError(error);
                });
            });

        },

        onCancel: function() {
            this.inherited(arguments);
            this.close();
        },

        onShow: function() {
            this.inherited(arguments);
            this.inner.grid.resize();
            this.inner.container.resize();
        },

        onHide: function() {
            this.inherited(arguments);
            this._newStore();
            this.inner.grid.set('collection', this.store);
            this.hasData = false;
        },

        close: function() {
            this.inherited(arguments);
            this.hide();
        },

        _newStore: function () {
            if (this.store) {
                delete this.store;
            }

            this.store = new Memory({
                'idProperty': 'damageReportId',
                'data': []
            });
        }
    });
});
