/**
 * <危機管理型水位計情報一覧>
 *
 * @module app/river/grid/CrisisManageRiverLevelObsPageGrid
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'app/view/grid/CommonColumn',
    './RiverLevelCommonColumn'
    // 以下、変数で受けないモジュール
], function(module, declare, IdisGrid, helper, CommonColumn, RiverLevelCommonColumn) {
    /**
     * 危機管理型水位計情報一覧画面用Grid
     *
     * @class CrisisManageRiverLevelObsPageGrid
     * @extends module:idis/view/grid/IdisGrid~IdisGrid
     */
    return declare(module.id.replace(/\//g, '.'), IdisGrid,
        /** @lends module:app/river/CrisisManageRiverLevelObsPageGrid~CrisisManageRiverLevelObsPageGrid# */ {
        minRowsPerPage: -1,
        columns : [
            helper.buttonColumn('detail', '詳細'),
            // 観測局
            CommonColumn.observatoryName,
            // 管理区分

            CommonColumn.optionColumn('riverSysName', '水系'),
            CommonColumn.optionColumn('riverName', '河川'),
            RiverLevelCommonColumn.excess,
            RiverLevelCommonColumn.riverLevel,
            helper.column('limitLevel', '基準値', {
                sortable : false,
                children : [
                    CommonColumn.thresholdDissotable('obsStartRiverLevel', '観測開始水位\n[m]'),
                    CommonColumn.thresholdDissotable('dangerousRiverLevel', '危険水位\n[m]'),
                    CommonColumn.thresholdDissotableCan0('floodStartRiverLevel', '氾濫開始水位\n[m]')
                ]
            })
        ]
    });
});
