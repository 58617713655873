/**
 * 通知先先グループを扱うモデル。
 * @module app/model/AddressGroup
 */
define([
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest'
], function(CacheStoreModel, CacheTreeRest) {
    /**
     * シングルトンを返す。
     */
    return new CacheStoreModel({
        store: new CacheTreeRest({
            target: '/api/broadnotify/broadnotifyGroup4Tree'
        })
    });
});
