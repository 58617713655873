/**
 * クロノロジ関連のストア
 * @module app/chronology/ChronologyStore
 */
define([
    'module'
], function(module) {

    var PROP = false;

    /**
     * クロノロジの設定を取得する
     */
    function _setProp(prop) {
        PROP = prop;
        return;
    }

    function _getProp() {
        return PROP;
    }


    return {
        setProp: _setProp,
        getProp: _getProp,
        module: module
    };
});
