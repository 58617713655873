/**
 * 現地写真登録一覧モジュール
 * @module app/view/page/PhotoUploadPage
 */
define([
    'module',
    'app/model/LayerStore',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/PhotoUploadPage.html',
    'dstore/Memory',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/view/page/_PageBase',
    'idis/service/Requester',
    'idis/store/IdisRest',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/grid/IdisGrid',
    'idis/view/form/Button',
    'idis/view/form/AclButton',
    'idis/view/form/DateTimeInput',
    '../../view/form/OrganizationSelector',
    './PhotoUploadRegisterPage',
    './PhotoUploadDetailPage',
    './PhotoUploadGrid'
], function(module, LayerStore, array, declare, lang, template, Memory, DialogChain,
    Loader, _PageBase, Requester, IdisRest) {

    /**
     * 現地写真登録一覧
     * @class PhotoUploadPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
                   /** @lends module:app/view/page/PhotoUploadPage~PhotoUploadPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--photo',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'photoId',
                target: '/api/photo'
            });

            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },

        /**
         * グリッドの検索条件を指定された値で更新する
         * @param {Object} value name属性と値のマッピング
         */
        updateGridQuery: function(value) {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();

            // 組織
            if (value.organization) {
                filter = filter.eq('organization', value.organization);
            }
            // 撮影日時（FROM）
            if (value.photoTimestampFrom) {
                var photoTimestampFrom = new Date(value.photoTimestampFrom).getTime();
                filter = filter.eq('photoTimestampFrom', photoTimestampFrom);
            }
            // 撮影日時（TO）
            if (value.photoTimestampTo) {
                var photoTimestampTo = new Date(value.photoTimestampTo).getTime();
                filter = filter.eq('photoTimestampTo', photoTimestampTo);
            }

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.grid.set('collection', collection);
        },

        /**
         * 検索を実行する
         */
        onSubmit: function() {
            try {
                if (this.form.validate()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery(this.form.get('value'));
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 新規登録ダイアログを表示する
         * テンプレートHTMLでdata-dojo-attach-eventプロパティーを用い、
         * 新規登録ボタンのクリックイベントにこのメソッドを紐付けている。
         */
        showRegisterDialog: function() {
            // ダイアログの最初の子要素が登録画面
            var page = this.registerDialog.getChildren()[0];
            // 画面上のフォームをリセット
            // （ダイアログ内画面のテンプレートHTMLでdata-dojo-attach-point="form"指定済み）
            page.form.reset();
            // innerHTMLに値を設定
            this.innerDialog4Regist.setPage();
            // ダイアログを表示
            this.registerDialog.show();
        },

        /**
         * 詳細ダイアログを表示する
         * @param {Object} item 参照する行のデータ
         */
        showDetailDialog: function(item) {
            // ダイアログの最初の子要素が詳細画面
            var page = this.detailDialog.getChildren()[0];
            // 画面上のフォームをリセット
            // ダイアログ内画面のテンプレートHTMLでdata-dojo-attach-point="form"指定済み
            page.form.reset();
            // 行データの内容をフォームに反映
            page.form.set('value', item);
            // innerHTMLに値を設定
            this.innerDialog4Detail.setPage(item);
            // ダイアログを表示
            this.detailDialog.show();
        },

        /**
         * グリッドを初期化する
         */
        initGrid: function() {
            // 全件表示用のcollectionをグリッドにセットする
            // （テンプレートHTML内のdata-dojo-attach-point="grid"でグリッドをthis.gridに紐付け済み）
            this.grid.set('collection', this.store.filter());
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.grid.on('detailButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                this.showDetailDialog(evt.item);
            }));
        },

        /**
         * 登録画面のフォームが投稿された際の動作を設定する
         */
        initRegisterPage: function() {
            var self = this;
            // 登録ダイアログの最初の子要素が登録画面
            var dialog = this.registerDialog;
            var page = dialog.getChildren()[0];
            // 登録画面のsendイベントを受け取る
            page.on('send', lang.hitch(this, function(evt) {
              this.chain.confirmAdd(function(chain) {
                var promise = Requester.post('/api/photo', {data: evt.value})
                    .then(function(data) {
                        console.log(data);
                        // 確認ダイアログを閉じる
                        chain.infoComplete();
                        // 再検索を行う
                        self.onSubmit();
                        // 登録ダイアログを閉じる
                        dialog.hide();
                        //ツリーを更新する
                        array.forEach(data, lang.hitch(function(object){
                            LayerStore.refresh(object.parentLayerId);
                            LayerStore.refresh(object.grandPrentLayerId);
                        }));
                    }, function(error) {
                        console.log(error);
                        chain.infoError(error);
                    });
                //ローダーの表示
                Loader.wait(promise);
                });
            }));
        },

        /**
         * 詳細画面のフォームが投稿された際の動作を設定する
         */
        initDetailPage: function() {
            // 詳細ダイアログの最初の子要素が詳細画面
            var dialog = this.detailDialog;
            var page = dialog.getChildren()[0];
            // 詳細画面のsendupdateイベントを受け取る
            page.on('sendupdate', lang.hitch(this, function(evt) {
              this.chain.confirmPut(function(chain) {
                var promise = this.store.put(evt.value);
                Loader.wait(promise).then(function(data) {
                    chain.infoComplete();
                    LayerStore.refresh(data.parentLayerId);
                    dialog.hide();
                }, function(err) {
                    // 失敗時
                    chain.infoError(err);
                    //console.error('PUT failed');
                });
              });
            }));
            // 詳細画面のsenddeleteイベントを受け取る
            page.on('senddelete', lang.hitch(this, function(evt) {
              this.chain.confirmDel(function(chain) {
                var promise = this.store.remove(evt.value);
                Loader.wait(promise).then(function(data) {
                    chain.infoComplete();
                    LayerStore.refresh(data.parentLayerId);
                    LayerStore.refresh(data.grandPrentLayerId);
                    dialog.hide();
                }, function(err) {
                    // 失敗時
                    chain.infoError(err);
                    //console.error('REMOVE failed');
                });
              });
            }));
        },

        /**
          * DOMノードを生成する
          */
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は関連ダイアログも削除する
            // （ダイアログは初期化時にbody直下へ移動するため、明示的に指定しないと消えずに残ってしまう）
            this.own(this.registerDialog);
            this.own(this.detailDialog);
            // グリッドを初期化する
            this.initGrid();
            // 登録画面内のフォームがsubmitされた際の動作を設定
            this.initRegisterPage();
            // 詳細画面内のフォームがsubmitされた際の動作を設定
            this.initDetailPage();
        }
    });
});
