/**
 * カラーを選択するためのウィジェット. カラーパレット
*/
define([
    'module',
	'dojo/_base/declare',
	'dojo/on',
	'dojo/_base/lang',
	'dojo/query',
    'dojo/text!./templates/ColorSelector.html',
	'dijit/form/DropDownButton',
	'dijit/TooltipDialog',
	'dijit/ColorPalette',
    'dijit/popup',
    'dijit/_WidgetBase',
    'dijit/_TemplatedMixin',
    'dijit/_WidgetsInTemplateMixin'
], function(module, declare, on, lang, query, template, DropDownButton, TooltipDialog,
    ColorPalette, popup,  _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin){

	/**
	 * カラーを選択するためのウィジェット
	 * @class ColorSelector
	 * @param {Object} arguments
	 */
	return declare(module.id.replace(/\//g, '.'), [_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {

		/**
		 * このウィジェットのトップレベルに付与されるCSSクラス
		 * @type {String}
		 */
		baseClass: 'dimsColorSelector',

		/**
		 * テンプレート文字列
		 * @type {String}
		 */
		templateString: template,

		/**
		 * デフォルトカラー
		 */
		_defaultColor: '#000000',

		/**
		 * 選択されているカラー
		 */
		_selectedColor: '#000000',

		/**
		 * @override
		 */
		constructor: function(options){
			this.inherited(arguments);

			if(typeof options.color !== 'undefined'){
				// TODO 妥当なカラーかチェック
				this._defaultColor = options.color;
			}

		},

		/**
		 * @override
		 */
		postCreate: function(){
			var self = this;
			self.inherited(arguments);

			// 初期値をセット
			self.setColor();

			// パレット選択時のイベント
			on(self.palette, 'change', self._setColor(this.palette));
		},

		/**
		 * カラーをボタンに設定し、パレットを閉じる
		 * @private
		 * @param {Object} dijit.ColorPalette
		 */
		_setColor: function(palette){

			var self = this;
			var dialog = self.dialog;
			var id = self.id;

			return function(){

				var color = palette.value; // 16進数
				self._selectedColor = color;
				query('#' + id + ' .dimsColorSwatch').style('backgroundColor', color);
				popup.close(dialog);

			};
		},

		/**
		 * 選択されているカラーを返す
		 * @public
		 */
		getColor: function(){
			return this._selectedColor;
		},

		/**
		 * 指定されたカラーを設定する
		 * @public
		 * @param {String} 16進数カラー
		 */
		setColor: function(color){
			var id = this.id;

			if(typeof color !== 'undefined'){

				// TODO カラーの妥当性チェック

				this.palette.set('value', color);

				query('#' + id + ' .dimsColorSwatch').style('backgroundColor', color);
				this._selectedColor = color;

			}else{
				this.palette.set('value', this._defaultColor);
				query('#' + id + ' .dimsColorSwatch').style('backgroundColor', this._defaultColor);
				this._selectedColor = this._defaultColor;
			}

		}

	});

});
