define({
    district:[
        {id: '220100', value:'青森市'},
        {id: '220200', value:'弘前市'},
        {id: '220300', value:'八戸市'},
        {id: '220400', value:'黒石市'},
        {id: '220500', value:'五所川原市'},
        {id: '220600', value:'十和田市'},
        {id: '220700', value:'三沢市'},
        {id: '220800', value:'むつ市'},
        {id: '220900', value:'つがる市'},
        {id: '221000', value:'平川市'},
        {id: '230100', value:'平内町'},
        {id: '230300', value:'今別町'},
        {id: '230400', value:'蓬田村'},
        {id: '230700', value:'外ヶ浜町'},
        {id: '232100', value:'鰺ヶ沢町'},
        {id: '232300', value:'深浦町'},
        {id: '234300', value:'西目屋村'},
        {id: '236100', value:'藤崎町'},
        {id: '236200', value:'大鰐町'},
        {id: '236700', value:'田舎館村'},
        {id: '238100', value:'板柳町'},
        {id: '238400', value:'鶴田町'},
        {id: '238700', value:'中泊町'},
        {id: '240100', value:'野辺地町'},
        {id: '240200', value:'七戸町'},
        {id: '240500', value:'六戸町'},
        {id: '240600', value:'横浜町'},
        {id: '240800', value:'東北町'},
        {id: '241100', value:'六ヶ所村'},
        {id: '241200', value:'おいらせ町'},
        {id: '242300', value:'大間町'},
        {id: '242400', value:'東通村'},
        {id: '242500', value:'風間浦村'},
        {id: '242600', value:'佐井村'},
        {id: '244100', value:'三戸町'},
        {id: '244200', value:'五戸町'},
        {id: '244300', value:'田子町'},
        {id: '244500', value:'南部町'},
        {id: '244600', value:'階上町'},
        {id: '245000', value:'新郷村'}
    ],

    region: [
        {id: '20010', value: '津軽'},
        {id: '20011', value: '東青津軽'},
        {id: '20012', value: '北五津軽'},
        {id: '20013', value: '西津軽'},
        {id: '20014', value: '中南津軽'},
        {id: '20020', value: '下北'},
        {id: '20030', value: '三八上北'},
        {id: '20031', value: '三八'},
        {id: '20032', value: '上北'},
        {id: '20100', value: '津軽下北'},
        {id: '20200', value: '下北三八上北'}
    ],

    earthquakeRegion: [
        {id: '200', value: '青森県津軽北部'},
        {id: '201', value: '青森県津軽南部'},
        {id: '202', value: '青森県三八上北'},
        {id: '203', value: '青森県下北'}
    ]
});
