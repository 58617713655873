/**
 * 気象警報・注意報を扱うモデル。
 * @module app/model/WeatherWarn
 */
define([
        'idis/store/CacheStoreModel',
        'idis/store/CacheTreeRest'
], function(CacheStoreModel, CacheTreeRest) {
    /**
     * シングルトンを返す。
     */
    return new CacheStoreModel({
        store: new CacheTreeRest({
            target: '/api/convoMails/conditions/warns4Tree'
        })
    });
});


