/**
 * 共有先選択用入力パーツ(複数選択可)
 * @module app/view/form/HldOrganizationMultiSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/Deferred',
    'dojo/string',
    'dojo/promise/all',
    'dojo/when',
    'dojox/lang/functional/array',
    'idis/error/InvalidArgumentException',
    'dijit/form/TextBox',
    'dojo/dom-style',
    '../../config',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'idis/view/form/TreeChecker',
    'idis/view/tree/CheckTree',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest'
], function(module, declare, lang, array, Deferred, dStr, all, when, df, InvalidArgumentException, TextBox,
     domStyle, config, UserInfo, UserType, TreeChecker, CheckTree, CacheStoreModel, CacheTreeRest) {
    /**
     * 対応課選択用パーツ。
     * @class HldOrganizationMultiSelector
     * @extends module:idis/view/form/TreeChecker~TreeChecker
     */
    return declare(module.id.replace(/\//g, '.'), TreeChecker,
        /** @lends module:idis/view/tree/HldOrganizationMultiSelector~HldOrganizationMultiSelector# */ {
        // 選択ダイアログのタイトル
        title: '対応課選択',

        // ツリー・モデル
        model: null,

        placeHolder: '組織名で絞り込み',

        constructor : function() {
            // 市町ユーザでない場合、組織を0件受け取るようダミーの市町コードを設定
            // var municipalityCd = '000000';
            // if(UserInfo.getUserType() === UserType.MUNICIPALITY){
            //     municipalityCd = UserInfo.getMunicipalityCd();
            // }

            // 政令指定都市内の区であるかどうか
            // var isParentMunicOrgRequired = (config.municInfo.wardList.indexOf(municipalityCd) !== -1);

            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                    // target: '/api/organizations/' + municipalityCd +
                    //             '?isParentMunicOrgRequired=' + isParentMunicOrgRequired
                    target: '/api/organizations/'
                })
            });
        },

        // 市町セレクタが変更された際、市町内の組織を取得し直す
        onMunicipalityChange : function(municipalityCd) {
            if(!municipalityCd){
                return false;
            }

            // 一旦ツリーを削除し、再構築する
            if(this.tree){
                this.tree.destroyRecursive();
            }

            // 政令指定都市内の区であるかどうか
            var isParentMunicOrgRequired = (config.municInfo.wardList.indexOf(municipalityCd) !== -1);

            var store = new CacheTreeRest({
                target: '/api/organizations/' + municipalityCd + '?isParentMunicOrgRequired=' + isParentMunicOrgRequired
            });
            // TODO: この部分で[Cannot read property 'setAttribute' of null] というエラーを吐く場合があるが、
            // 画面上の不具合はないため対応を保留中。
            this.model = new CacheStoreModel({
                store: store
            });

            this.tree = new CheckTree({
                model : this.model
            }, this.treeNode);

            // 再配置する
            this.tree.placeAt(this.parentTreeNode);
            this.tree.startup();

            return this.tree.onLoadDeferred;
        },

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
            // 検索用入力要素を設置
            this.filterInput = new TextBox({
                placeHolder: this.placeHolder,
                maxLength: 128
            });
            this.filterInput.placeAt(this.treeControlNode);
            this.own(this.filterInput.on('keyup', lang.hitch(this, 'onFilterInputChange')));
        },

        /**
         * 選択した値の設定
         */
        _setValueAttr: function(value) {
            // 継承した親クラスの処理
            //this.inherited(arguments);
            if (!lang.isArray(value)) {
                var message = '#_setValueAttr: 配列を指定してください: value=' + value;
                throw new InvalidArgumentException(module.id + message);
            }
            // value値を設定
            this._set('value', value);
            // 選択状態を更新
            this._initTree().then(lang.hitch(this, function() {
                // 指定されなかったvalue値に対応する要素のチェックを解除
                return all(df.map(this.tree._checkMap, function(item, id) {
                    if (array.indexOf(value, id) === -1) {
                        return this.tree.setChecked(item, false);
                    }
                }, this));
            })).then(lang.hitch(this, function() {
                return all(array.map(value, function(v) {
                    // 各値を識別子とする要素を取得
                    return when(this.tree.model.store.get(v), lang.hitch(this, function(item) {
                        // チェックされたものをツリーに反映
                        return this.tree.setChecked(item, true);
                    }));
                }, this));
            })).then(lang.hitch(this, function() {
                // チェックされたものを選択表示へ反映
                // 親がチェックされている場合、子は表示しない
                // 全選択の時、ROOTが入る影響ですべてのラベル表示がされなくなるため、ROOTの要素を消す
                delete this.tree._checkMap['$ROOT$'];
                var excludeMap = {};
                df.forIn(this.tree._checkMap, function(item) {
                    if(this.tree.isEveryChildChecked(item)){
                        this.tree.getItemChildren(item).then(lang.hitch(this, function(items) {
                            if(!items){
                                return false;
                            }
                            array.forEach(items, function(item){
                                excludeMap[this.tree.model.store.getIdentity(item)] = true;
                            }, this);
                        }));
                    }
                }, this);
                var ret = df.filter(this.tree._checkMap, function(item) {
                    return !excludeMap[this.tree.model.store.getIdentity(item)];
                }, this);
                this.status.innerHTML = array.map(ret, function(item) {
                    return this.model.getLabel(item);
                }, this).join(', ') || this.emptyValueLabel;
            }));
            // 以下、当モジュール固有の処理
            // 選択結果が20文字を超えた場合、・・・(三点リーダ)で省略する
            domStyle.set(this.status, {
                'max-width': '20em',
                'white-space': 'nowrap',
                'overflow': 'hidden',
                'text-overflow': 'ellipsis'
            });
        },

        /**
         * 検索用入力要素入力時。
         */
        onFilterInputChange: function() {
            // 入力値を取得
            var value = dStr.trim(this.filterInput.get('value'));
            if (value) {
                // ラベル名が入力値を含むものでフィルターし、ツリーを展開する
                this.model.setFilter(function(item, isLeaf, model) {
                    return isLeaf && (model.getLabel(item) || '').indexOf(value) !== -1;
                }).then(lang.hitch(this, function() {
                    this.tree.expandAll();
                }));
            } else {
                // 入力が空の場合は全要素を表示してツリーを閉じる
                this.model.setFilter().then(lang.hitch(this, function() {
                    this.tree.collapseAll();
                }));
            }
        }
    });
});
