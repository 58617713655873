/**
 * 避難所一括画面・日時設定ダイアログ用モジュール。
 * @module app/shelter/ShelterBulkTimeEditDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/ShelterBulkTimeEditDialog.html',
    'dojo/topic',
    'idis/view/page/_PageBase', // 共通基底クラス
    './ShelterBulkOperatingGrid',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput'
], function(module, declare, lang, template, topic, _PageBase, ShelterBulkOperatingGrid) {
    /**
     * 避難所一括画面・日時設定ダイアログ
     * @class ShelterBulkTimeEditDialog
     * @extends module:app/facility/_FacilityTypeBase~_FacilityTypeBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/shelter/ShelterBulkTimeEditDialog~ShelterBulkTimeEditDialog# */ {
        // テンプレート文字列
        templateString: template,

        /**
         * 以下の属性を持つ編集対象情報。
         * grid: 対象グリッド
         * column: 対象列定義
         * idList: 対象行のID一覧
         * value: 対象の初期値
         * @type {Object}
         */
        _target: null,

        // DOM構築後に呼ばれる
        postCreate: function() {
            this.inherited(arguments);
            this.own(topic.subscribe(ShelterBulkOperatingGrid.TIME_EDIT_EVENT, lang.hitch(this, function(evt) {
                this._target = evt;
                this.setPage();
                this.getParent().show();
            })));
        },

        /**
         * ダイアログ内容を初期化する。
         */
        setPage: function() {
            // 編集対象によってダイアログの表示文言を設定
            this.titleNode.innerHTML = this._target.column.label + 'の入力';
            this.labelNode.innerHTML = this._target.column.label + '：';
            // フィールドをリセット
            this.form.reset();
            if (this._target.value) {
                this.form.set('value', {timeInput: this._target.value});
            }
        },

        // 入力結果をグリッドに反映
        onSubmit: function() {
            // 入力チェック
            if (!this.form.validate()) {
                return false;
            }
            // グリッド内容を書き換える
            this._target.grid.updateEditValues({
                field: this._target.column.field,
                value: this.form.get('value').timeInput,
                idList: this._target.idList
            });
            this.getParent().hide();
        }
    });
});
