/**
 * 体制・報告 詳細/更新画面用モジュール。
 * @module app/disasterprevention/view/DisasterPreventionDetailPage
 */
define([
    'module',
    'app/disaster/model/DisasterType',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/DisasterPreventionStatusDetailPage.html',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'idis/view/dialog/InfoDialog',
    './_DisasterPreventionStatusDialogPageBase',
    'app/config',
    // 以下、変数から参照されないモジュール
    'dijit/form/ValidationTextBox',
    'dijit/form/NumberTextBox',
    'app/view/form/MunicipalitySelector',
    'app/view/form/CustomizableMunicipalitySelector'
], function(module, DisasterType, declare, lang, domStyle, template,
    UserInfo, USER_TYPE, InfoDialog,
    _PageBase, config) {
    /**
     * 災害情報登録画面。
     * @class DisasterPreventionDetailPage
     * @extends module:idis/view/page/_DisasterPreventionStatusDialogPageBase~_DisasterPreventionStatusDialogPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/disasterprevention/view/DisasterPreventionDetailPage~DisasterPreventionDetailPage */ {
        // テンプレート文字列
        templateString: template,

        _prevStatusId: null,

        /**
         * 体制の機能コード
         */
        functionCd : 'F05002',

        buildRendering: function() {
            this.inherited(arguments);
        },

        // DOM要素構築後に呼ばれる
        postCreate: function() {
            this._postCreate();
            // 更新ボタン
            this.own(this.updateButton.on('click', lang.hitch(this, function() {
                this.onSubmit();
            })));
            // 削除ボタン
            this.own(this.deleteButton.on('click', lang.hitch(this, function() {
                this.onDelete();
            })));
        },

        startup: function() {
            this.setTipsPopups();
        },

        /**
         * TipsPopupをセットする。
         */
        setTipsPopups: function() {
            this.setHqTypeTips('detailHqTypeLabel');
            this.setLevelTips('detailLevelLabel');
        },

        /**
         * フォームを初期化する。
         */
        initForm: function(obj) {
            this._disasterPrevId = obj.disasterPrevId;

            // 詳細画面では選択した自治体を設定し、変更不可
            this.municipalitySelector.set('value', obj.areaCd);
            this.municipalitySelector.set('noAllButton',true);

            // 更新ボタン・取消ボタンの権限を反映
            this.initButtton(obj.areaCd);

            this.form.set('value', obj);
            this._prevStatusId = obj.prevStatusId;
            // 災害種別
            this.disasterTypeSelect.set('value', obj.disasterType);
            // 本部種別に応じて本部設置情報の表示を切替える
            this._setHqNameNode(obj.hqType);
        },

        // 更新ボタンの押下時によばれる
        onSubmit: function() {
            try {
                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }
                // 登録・更新共通のバリデーションを実施
                if(!this.validate()) {
                    return false;
                }

                var value = this.form.get('value');
                // 非表示項目は入力値を削除
                if (domStyle.get(this.hqNameNode, 'display')==='none'){
                    delete value.hqName;
                } else {
                    value.hqName = this.hqName.get('value');
                }
                value.prevStatusId = this._prevStatusId;
                this.emit('put-send', {value: value});
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        // 削除ボタンの押下時によばれる
        onDelete: function() {
            var value = {};
            value.prevStatusId = this._prevStatusId;
            value.version = this.version.get('value');
            this.emit('delete-send', {value: value});
        },

        /**
         * ダイアログを初期化する。（更新時）
         */
        initDialog: function(parent, obj) {
            this._parent = parent;
            this.form.reset();
            this.initForm(obj);
        },

        /**
         * 更新ボタン・取消ボタンの権限を反映
         * @param 選択中の市町コード
         */
        initButtton: function(selectedAreaCd) {
            // システム管理者以外は、マスタ管理機能においては代理登録権限を持たない
            if (UserInfo.isSysAdmin()) {
                return;
            }
            // 所属自治体以外を選択の場合、操作ボタン非表示
            var userAreaCode = UserInfo.getUserType() === USER_TYPE.REGION ?
                UserInfo.getRegionCd() : UserInfo.getMunicipalityCd();
            if (selectedAreaCd !== userAreaCode) {
                domStyle.set(this.updateButton.domNode, 'display', 'none');
                domStyle.set(this.deleteButton.domNode, 'display', 'none');
            }
        }
    });
});
