/**
 * Lアラート画面用モジュール。
 * @module app/view/page/LalertPage
 */
 define([
     'module',
     'dojo/_base/declare',
     'dojo/_base/lang',
     'dojo/text!./templates/LalertPage.html',
     'idis/store/IdisRest',
     'idis/control/Locator',
     'idis/control/Router',
     'idis/view/page/_PageBase',
     // 以下、変数として受け取らないモジュール
     'dijit/layout/BorderContainer',
     'idis/view/form/DateTimeInput',
     'dijit/form/Form',
     'dijit/Dialog',
     'app/view/form/LayerDirectorySelector',
     'app/view/form/OrganizationSelector',
     'app/lalert/LalertGrid'
 ], function(module, declare, lang, template, IdisRest, Locator, Router, _PageBase) {
     /**
      * グリッド画面
      * @class LalertPage
      * @extends module:idis/view/page/_PageBase~_PageBase
      */
     return declare(module.id.replace(/\//g, '.'), _PageBase,
         /** @lends module:idis/view/page/LalertPage~LalertPage# */ {

         // テンプレート文字列
         templateString: template,

         // ルート要素に付与されるCSS
         //baseClass: 'idis-Page idis-Page--sample',
         baseClass: 'idis-Page idis-Page--lalert',

         // データストア
         store: null,

         // コンストラクタ
         constructor: function() {
             this.inherited(arguments);
             // データ格納用オブジェクト
             this.store = new IdisRest({
                 target: '/api/lalert/files'
             });
         },

         /**
          * フォームサブミット時に呼び出される。
          * @returns {boolean} ブラウザーのサブミット処理を停止する場合はfalse
          */
         onSubmit: function() {
            try {
               var value = this.form.get('value');
               console.log(value);
            } catch (e) {
               console.error(e);
            } finally {
               return false;
            }
         },

         /**
          * 詳細画面を表示する。
          */
         showDetailPage: function(lalertFilesId) {
            Locator.pushState('?' + Router.PAGE_KEY + '=' + 'lalert/detail' + '&lalertFilesId=' + lalertFilesId, true);
         },

         /**
          * グリッドを初期化する。
          * @function module:app/view/SampleGridPage~SampleGridPage#~startup
          */
          initGrid: function() {
            var collection = this.store.filter();
            this.grid.set('collection', collection);
          },

          // DOMノードを生成するためのメソッド
          buildRendering: function() {
              this.inherited(arguments);
              this.initGrid();
          },

         startup: function() {
             this.inherited(arguments);
             this.grid.on('lalertFilesIdButtonClick', lang.hitch(this, function (event) {
                 this.showDetailPage(event.item.lalertFilesId);
              }));
         }
     });
});
