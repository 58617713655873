/**
 * 職員招集の共通モジュール
 * @module app/convocation/_ConvocationPageBase
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'idis/view/page/_PageBase', // 共通基底クラス
    'idis/model/UserInfo',
    'idis/consts/ACL',
], function(module, declare, _PageBase, UserInfo, ACL) {
    /**
     * 職員招集の共通モジュール
     * @class _ConvocationPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/convocation/_ConvocationPageBase~_ConvocationPageBase# */
        {
            /**
             * ユーザーに職員招集の管理者権限があるかを返す
             */
            isAdminUser: function() {
                return UserInfo.getRoleCd() === ACL.ADMIN_USER;
            }
        }
    );
});
