/**
 * <ダム諸量推移表示用グリッド>
 *
 * @module app/dam/grid/DamHistoryGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'app/riverBasin/grid/RiverBasinCommonColumns',
    './DamCommonColumn'
], function (module, declare, IdisGrid, helper, RiverBasinCommonColumns, DamCommonColumn) {
    /**
     * ダム諸量推移表示用グリッド
     *
     * @class DamHistoryGrid
     * @extends module:idis/view/grid/IdisGrid~IdisGrid
     */
    return declare(module.id.replace(/\//g, '.'), IdisGrid,
        /** @lends module:app/dam/grid/DamHistoryGrid~DamHistoryGrid# */
        {
            columns: [
                helper.column('days', '月日', { sortable: false }),
                helper.column('time', '時刻', { sortable: false }),
                DamCommonColumn.damQuantitiesLevel,
                DamCommonColumn.damInflowQuantities,
                DamCommonColumn.damTotalReleaseQuantities,
                DamCommonColumn.damEffectiveStorageQuantities,
                DamCommonColumn.storageRateWaterUseCapacity,
                RiverBasinCommonColumns.rainfall60Min
            ]
        });
});