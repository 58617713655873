/**
 * 振興局を扱うモデル。
 * @module app/disasterprevention/view/model/PrefPromotionBureau
 */
define([
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest'
], function(CacheStoreModel, CacheTreeRest) {
    /**
     * シングルトンを返す。
     */
    return new CacheStoreModel({
        store: new CacheTreeRest({
            target: 'dojo/app/disasterprevention/view/model/prefPromotionBureau.json'
        })
    });
});
