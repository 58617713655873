define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/dom-construct',
    'dojo/on',
    'idis/view/grid/IdisGrid'
], function(module, declare, lang, locale, domConstruct, on, IdisGrid) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--chat',

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            { field: 'chatGroupId', label: 'グループID', sortable: true },
            { field: 'chatGroupName', label: 'グループ名', sortable: true },
            { field: 'organizationName', label: '所属', sortable: true },
            { field: 'userId', label: 'ユーザID', sortable: true },
            { field: 'userName', label: 'ユーザ名', sortable: true },
            { field: 'comment', label: 'メッセージ', sortable: true },
            // 添付ファイル
            {field: 'attachFileName', label: 'ファイル', sortable: false,
                renderCell: function(item) {
                    var attachNode = domConstruct.create('span');
                    var gridNode = this.grid.domNode;
                    if (item.attachFileName) {
                        var path = item.attachFilePath.replace('out', 'data');
                        var name = item.attachFileName;
                        var node = null;
                        var userAgent = window.navigator.userAgent.toLowerCase();
                        if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {
                            // IEの場合、download属性が効かないため、右クリック保存などaタグ機能を無効化.
                            // ダウンロード関数により元ファイル名でのダウンロードを可能にする.
                            node = domConstruct.create('a', {
                                innerHTML: name,
                                href: '#'
                            });
                            // クリックでファイル取得処理に入る
                            node.onclick = function() {
                                on.emit(gridNode, 'attachFileLinkClick', {item: item});
                            };
                        } else {
                            // IE以外の場合、download属性でファイルダウンロード
                            node = domConstruct.create('a', {
                                innerHTML: name,
                                href: path,
                                download: name
                            });
                        }
                        domConstruct.place(node, attachNode);
                        domConstruct.place('<br>', attachNode);
                        return attachNode;
                    }
                }
            },
            { field: 'sendTimestamp', label: '投稿日時', sortable: true,
                formatter: lang.hitch(this, function(item){
                    if (item) {
                        return locale.format(new Date(item));
                    }
                    return '';
                })
            }
        ]
    });
});
