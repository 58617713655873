/**
 * 対象都道府県を扱うモデル。
 * @module app/model/Weather
 */
define([
        'idis/store/CacheStoreModel',
        'idis/store/CacheTreeRest'
], function(CacheStoreModel, CacheTreeRest) {
    /**
     * シングルトンを返す。
     */
    return new CacheStoreModel({
        store: new CacheTreeRest({
            target: '/api/convoMails/conditions/prefs4Tree'
        })
    });
});


