/**
 * ライフライン（停電）表示ダイアログ用の基底モジュール。
 * @module app/user/_LifelineDialogBase
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/page/_PageBase',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'idis/view/form/AclButton',
    'idis/view/form/Button'
], function (module, declare, _PageBase) {

    return declare(module.id.replace(/\//g, '.'), _PageBase,
        {
            /**
             * 表題
             */
            _title: null,

            /**
             * 見出し文
             */
            _contentDescription: null,

            /**
             * 更新日時
             */
            _reportTimestamp: null,

            /**
             * お知らせ（詳細情報）
             */
            _notification: null,

            /**
             * 復旧見込日時
             */
            _restoreTimestamp: null,

            /**
             * 関連するホームページ
             */
            _url: null,

            /**
             * 停電発生件数
             */
            _householdNum: null,

            /**
             * 地区別状況
             */
            _areas: null,

            /**
             * 発表組織
             */
            _publishingOrganizationName: null,

            /**
             * constructor
             */
            constructor: function () {
            },

            /**
             * buildRendering
             */
            buildRendering: function () {
                this.inherited(arguments);
            }

        });
});
