/**
 * 配信情報Lアラート内容確認ダイアログ（体制）
 * @module app/sendingHistory/Dialog/DisasterPreventionDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/DisasterPreventionDialog.html',
    'idis/view/dialog/DialogChain',
    'dojo/date/locale',
    'dojo/dom-style',
    'idis/view/page/_PageBase',
    // 以下、変数から参照されないモジュール
    'app/provide/form/DistributionTypeInput',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button'
], function (module, declare, template, DialogChain, locale, domStyle, _PageBase) {
    /**
     * 配信情報Lアラート内容確認ダイアログ（体制）
     * @class DisasterPreventionDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/sending/DisasterPreventionDialog~DisasterPreventionDialog# */ {
            // テンプレート文字列
            templateString: template,

            constructor: function () {
                this.chain = DialogChain.get(this);
            },

            initDialog: function (obj) {
                // 更新種別
                this.distributionType.set('value', obj.distributionType);

                // 発行元
                this.municipalityName.innerHTML = obj.municipalityName;

                //災害識別情報
                this.disasterTypeName.innerHTML = obj.disasterTypeName;

                // 本部名称
                this.hqType.innerHTML = obj.hqType;

                // 設置状況
                this.hqSetFlg.innerHTML = obj.hqSetFlg;

                // 設置・解散日時
                this.hqTimestamp.innerHTML = locale.format(new Date(obj.hqTimestamp));
                // 本部名称
                this.hqName.innerHTML = obj.hqName;

                // 補足情報
                this.comment.innerHTML = obj.comment;

            },

            onOKButton: function () {
                this.getParent().hide();
            }
        });
});
