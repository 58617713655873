/**
 * 選択形式のウィジェットを実現するためのMixin
 * @module idis/view/form/_OptionGroupMixin
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-construct',
    'dijit/_WidgetBase',
    '../../error/InvalidArgumentException',
    '../../util/ArrayUtils',
    './_NamedMixin'
], function(module, array, declare, lang, domConst, _WidgetBase, InvalidArgumentException, ArrayUtils, _NamedMixin) {
    /**
     * ラジオやチェックボックスをまとめるウィジェットの基底クラス。
     * @class _OptionGroupMixin
     * @extends module:dijit/_WidgetBase~_WidgetBase
     * @extends module:idis/view/form/_NamedMixin~_NamedMixin
     * @param {Object} kwArgs
     * @param {string} kwArgs.name この入力値のname属性
     * @param {Object[]|string[]} [kwArgs.options] 選択肢の設定値
     */
    return declare(module.id.replace(/\//g, '.'), [_WidgetBase, _NamedMixin], {
        /**
         * このウィジェットのCSSクラス
         * @type {string}
         */
        baseClass: 'idis-OptionGroup',

        /**
         * ウィジェットのname属性。
         * @type {string}
         */
        name: null,

        /**
         * ウィジェットの最新の選択値。
         * 単一選択形式なら文字列、複数選択形式なら文字列の配列になる。
         * 単一選択形式の空値はnull、複数選択形式の空値は空配列で表す。
         * @type {string|string[]}
         */
        value: null,

        /**
         * 複数選択可能かどうか（dijit/form/Formから参照される）
         * @type {boolean}
         */
        multiple: true,

        /**
         * 各オプションを生成するためのコンストラクター関数
         * @type {function}
         * @protected
         */
        _optionWidgetClass: null,

        constructor: function() {
            if (this.multiple) {
                this.value = [];
            }
        },

        // DOM要素を生成する。
        buildRendering: function() {
			if (!this.domNode) {
				this.domNode = this.srcNodeRef || this.ownerDocument.createElement('ul');
			}
            this.inherited(arguments);
            if (!this.containerNode) {
                this.containerNode = this.domNode;
            }
            if (this.containerNode && this.options) {
                this._createOptionGroup(this.options);
            }
            this._updateValue();
        },

        /**
         * 文字列からオプション用オブジェクトを生成して返す。
         * @param {string} label 表示文字列
         * @param {number} index オプションの位置
         * @returns {object} オプション用オブジェクト
         */
        _toOptionObject: function(label, index) {
            // このオプションのvalue
            var value = index + '';
            // チェック状態のvalueまたはその一覧
            var checkedValue = this.get('value');
            return {
                label: label,
                value: value,
                checked: lang.isArray(checkedValue) ? ArrayUtils.contains(checkedValue, value) : checkedValue === value
            };
        },

        /**
         * 選択肢一覧を生成して設置する。
         * @protected
         */
        _createOptionGroup: function(options) {
            // 選択肢を生成して変数に格納
            this._optionWidgets = array.map(options, function(item, index) {
                if (lang.isString(item)) {
                    // 文字列の場合は表示文字列として扱い、値は連番にする
                    item = this._toOptionObject(item, index);
                }
                var option = this.createOption(item);
                option.set('name', this.name);
                option.on('change', lang.hitch(this, this._updateValue));
                return option;
            }, this);
        },

        /**
         * 指定された値に対応するオプション要素を返す。
         * 見つからなかった場合はnullを返す。
         * @param {string} value オプションの値
         * @returns {dijit/form/CheckBox} オプション要素
         */
        getOption: function(value) {
            for (var i = 0; i < this._optionWidgets.length; i++) {
                if (this._optionWidgets[i]._get('value') === value) {
                    return this._optionWidgets[i];
                }
            }
            return null;
        },

        /**
         * 指定された位置にあるオプション要素を返す。
         * @param {number} index オプションの位置
         * @returns {dijit/form/CheckBox} オプション要素
         */
        getOptionAt: function(index) {
            return this._optionWidgets[index];
        },

        /**
         * このウィジェットの値を返す。
         * @returns {*} 選択値
         */
        _getValueAttr: function() {
            return this.value;
        },

        /**
         * 全ての選択肢の指定された属性を指定された値に更新する。
         * @param {string} attrName 属性名
         * @param {*} value 新たな値
         * @private
         */
        _setAllOptions: function(attrName, value) {
            if (attrName !== 'checked') {
                this._set(attrName, value);
            }
            array.forEach(this._optionWidgets, function(option) {
                option.set(attrName, value);
            });
        },

        /**
         * このウィジェットのvalue値を設定する。
         * @param {string|string[]} チェック対象のvalue値またはその配列
         */
        _setValueAttr: function(value) {
            // 確実に配列化
            value = lang.isArray(value) ? value : [value];
            // チェック状態を更新
            array.forEach(this._optionWidgets, function(option) {
                option.set('checked', ArrayUtils.contains(value, option._get('value')));
            });
            // チェック状態を元にvalue値を計算
            this._updateValue();
        },

        /**
         * このウィジェットの無効状態をセットする。
         * @param {boolean} value セットする値
         */
        _setDisabledAttr: function(value) {
            this._setAllOptions('disabled', value);
        },

        /**
         * このウィジェットのreadOnly状態をセットする。
         * @param {boolean} value セットする値
         */
        _setReadOnlyAttr: function(value) {
            this._setAllOptions('readOnly', value);
        },

        /**
         * 選択肢をリセットしてvalue値を更新する。
         */
        reset: function() {
            array.forEach(this._optionWidgets, function(option) {
                option.reset();
            });
            this._updateValue();
        },

        /**
         * 現在のチェック状態を元にこのウィジェットのvalueプロパティーを更新する。
         */
        _updateValue: function() {
            var value = [], found = false;
            array.some(this._optionWidgets, function(option) {
                if (option.get('checked')) {
                    found = true;
                    if (this.multiple) {
                        // 複数選択形式
                        value.push(option._get('value'));
                    } else {
                        // 単数選択形式
                        value = option._get('value');
                        return true; // break
                    }
                }
            }, this);
            this._set('value', (this.multiple || found) ? value : null);
        },

        /**
         * 選択肢要素を作って設置する。
         * @param {object} item
         * @param {string} item.label 選択肢の表示文字列
         * @param {string} item.value 選択肢の値
         * @param {string} [item.checked] 選択肢が初期状態でチェックされているかどうか
         * @returns {*} 生成された選択肢要素
         */
        createOption: function(item) {
            var li = domConst.create('li');
            var label = domConst.create('label', {innerHTML: item.label}, li);
            var option = new this._optionWidgetClass({value: item.value, checked: item.checked});
            option.placeAt(label, 'first');
            domConst.place(li, this.containerNode);
            return option;
        }
    });
});
