/**
 * Lアラート画面用モジュール。詳細画面
 * @module app/view/page/LalertDetailPage
 */
 define([
     'module',
     'dojo/_base/declare',
     'dojo/dom-style',
     'dojo/text!./templates/LalertDetailPage.html',
     'idis/view/page/_PageBase',
     'idis/store/IdisRest',
     'idis/control/Locator',
     'idis/control/Router',
     'idis/service/Requester',
     'dojo/_base/lang',
     'dojo/store/Memory',
     './data/FormValues',
     // 以下、変数として受け取らないモジュール
     'dijit/layout/BorderContainer',
     'idis/view/form/DateTimeInput',
     'dijit/form/Form',
     'dijit/form/Select',
     'dijit/Dialog',
     'app/view/form/LayerDirectorySelector',
     'app/view/form/OrganizationSelector',
     'app/lalert/LalertDetailGrid'
 ], function(module, declare, domStyle, template, _PageBase, IdisRest, Locator,
     Router, Requester, lang, Memory, FormValues) {
     /**
      * グリッド画面
      * @class LalertPage
      * @extends module:idis/view/page/_PageBase~_PageBase
      */
     return declare(module.id.replace(/\//g, '.'), _PageBase,
         /** @lends module:idis/view/page/LalertDetailPage~LalertDetailPage# */ {

         // テンプレート文字列
         templateString: template,
         store: null,

         // ルート要素に付与されるCSS
         baseClass: 'idis-Page idis-Page--sample',

         // コンストラクタ
         constructor: function() {
             this.inherited(arguments);
             // データ格納用オブジェクト
             this.store = new IdisRest({
                target: '/api/lalert/files/' + Locator.getQuery().lalertFilesId + '/lalerts',
                sortParam: 'sort',
                rangeStartParam: 'offset',
                rangeCountParam: 'limit'
             });
         },

         /**
          * フォームサブミット時に呼び出される。
          * @returns {boolean} ブラウザーのサブミット処理を停止する場合はfalse
          */
         onSubmit: function() {
            try {
              if (this.form.isValid()) {
                  // 入力値が正常ならグリッドの検索条件を更新
                  this.updateGridQuery(this.form.get('value'));
              }
            } catch (e) {
               console.error(e);
            }
            return false;
         },

         /**
          * グリッドを初期化する。
          * @function module:app/view/SampleGridPage~SampleGridPage#~startup
          */
          initGrid: function() {
            var collection = this.store.filter();
            this.grid.set('collection', collection);
          },

          // DOMノードを生成するためのメソッド
          buildRendering: function() {
              this.inherited(arguments);
              this.initGrid();
          },

         /**
          * グリッドを初期化する。
          * @function module:app/view/SampleGridPage~SampleGridPage#~startup
          */
         startup: function() {
            this.inherited(arguments);

             // 本文の情報を入れる
            Requester.get('/api/lalert/files/' + Locator.getQuery().lalertFilesId)
                .then(lang.hitch(this, function(data) {
                    this.article.innerText = data.text;

                    // 検索する地方も警報もない場合
                    if (!FormValues[data.infokind]) {
                        this.searchButton.set('disabled', true);
                        domStyle.set('tiiki-select', 'display', 'none');
                        domStyle.set('keiho-select', 'display', 'none');
                        return;
                    }

                    // 地区検索用のformを作る
                    if (!FormValues[data.infokind].tiiki) {
                        domStyle.set('tiiki-select', 'display', 'none');
                    } else {
                        this.tiikiForm.set('labelAttr', 'value');
                        this.tiikiForm.set('sortByLabel', false);
                        this.tiikiForm.set('store', new Memory({data: FormValues[data.infokind].tiiki}));
                    }

                    // 警報種別用のformを作る
                    if (!FormValues[data.infokind].keiho) {
                        domStyle.set('keiho-select', 'display', 'none');
                    } else {
                        this.keihoForm.set('labelAttr', 'value');
                        this.keihoForm.set('sortByLabel', false);
                        this.keihoForm.set('store', new Memory({data: FormValues[data.infokind].keiho}));
                    }

                }), function(error) {
                    console.log(error);
            });
         },

         /**
          * グリッドの検索条件を指定された値で更新する。
          * @param {Object} value name属性と値のマッピング
          */
         updateGridQuery: function(value) {
             // 入力値を元にグリッド用フィルターを作成
             var filter = new this.store.Filter();
             // 警報で検索
             if (value.keiho1 !== '') {
                 // 1つだけ選択されている場合は絞り込み条件に加える
                 filter = filter.eq('kindCode', value.keiho1);
             }
             // 地域で検索
             if (value.tiiki1 !== '') {
                 filter = filter.eq('areasCode', value.tiiki1);
             }
             // filterに対応するcollectionを取得
             var collection = this.store.filter(filter);
             // collectionをグリッドにセットする（サーバーにリクエストされる）
             this.grid.set('collection', collection);
         },

         /**
         *一覧画面に遷移する。
         */
         leave: function() {
             //一覧画面に移動
             Router.moveTo('lalert');
         }

    });
});
