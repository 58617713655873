/**
 * フォルダ管理画面の削除処理を実装するMixin。
 * @module app/folder/view/_DeleteMixin
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang'
    // 以下、変数として受け取らないモジュール
], function(module, declare, lang) {
    /**
     * フォルダ管理画面の名前変更処理を実装するMixin。
     * @class _DeleteMixin
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(null, /** @lends module:app/view/folder/view/_DeleteMixin~_DeleteMixin# */ {
        /**
         * ツリーの選択要素が変わったときに呼ばれる。
         */
        onLeftTreeFocus: function(item) {
            this.inherited(arguments);
            // 名前変更ボタンはツリー要素選択中しか使えない
            if (this.deleteButton) {
                this.deleteButton.set('disabled', !item);
            }
        },

        /**
         * 削除ボタンがクリックされた際に呼ばれる。
         * @param {MouseEvent} evt クリック・イベント
         */
        onDeleteButtonClick: function(/* evt */) {
            var item = this.leftTree.get('lastFocusedItem');
            // 確認ダイアログを表示
            this.chain.confirm('「' + item.name + '」とその中身を削除します。<br>よろしいですか？', function(chain) {
                // 確認ダイアログでOKを押した場合
                // 要素を削除
                this.leftTree.model.deleteItem(item).then(lang.hitch(this, function() {
                    this.leftTree.set('lastFocusedItem', null);
                    chain.info('削除を完了しました。<br>（確定ボタンを押すまで実際のデータには反映されません）');
                }), lang.hitch(this, function(err) {
                    this.chain.infoError(err);
                }));
            });
        }
    });
});

