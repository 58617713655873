/**
 * 宛先グループ内宛先選択用入力パーツ
 * @module idis/view/form/AddressSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/string',
    'dijit/form/TextBox',
    'idis/view/form/TreeChecker',
    'idis/view/tree/CheckTree',
    'app/model/BroadnotifyGroup',
    'idis/view/tree/IdisTree',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest'
], function(module, declare, lang, dStr, TextBox, TreeChecker, CheckTree, BroadnotifyGroup,
    IdisTree, CacheStoreModel, CacheTreeRest) {
    /**
     * 宛先選択用パーツ。
     * @class AddressSelector
     * @extends module:idis/view/form/TreeChecker~TreeChecker
     */
    return declare(module.id.replace(/\//g, '.'), TreeChecker,
        /** @lends module:idis/view/tree/AddressSelector~AddressSelector# */ {
        // 選択ダイアログのタイトル
        title: '通知グループ選択',

        // ツリー・モデル
        model: BroadnotifyGroup,

        // DOMを構築する
        buildRendering: function () {
            this.inherited(arguments);
            // 検索用入力要素を設置
            this.filterInput = new TextBox({
                placeHolder: this.placeHolder,
                maxLength: 128
            });
            this.filterInput.placeAt(this.treeControlNode);
            this.own(this.filterInput.on('keyup', lang.hitch(this, 'onFilterInputChange')));
        },
        _refresh: function() {
                // 一旦ツリーを削除し、再構築する
                if (this.tree) {
                    this.tree.destroyRecursive();
                }

                // TODO: この部分で[Cannot read property 'setAttribute' of null] というエラーを吐く場合があるが、
                // 画面上の不具合はないため対応を保留中。
                this.model = new CacheStoreModel({
                    store: new CacheTreeRest({
                        target: '/api/broadnotify/broadnotifyGroup4Tree'
                    })
                });
    
                this.tree = new CheckTree({
                    model: this.model
                }, this.treeNode);
    
                // 再配置する
                this.tree.placeAt(this.parentTreeNode);
                this.tree.startup();
            return this.tree.onLoadDeferred;
        },

        /**
         * 検索用入力要素入力時。
         */
         onFilterInputChange: function () {
            // 入力値を取得
            var value = dStr.trim(this.filterInput.get('value'));
            if (value) {
                // ラベル名が入力値を含むものでフィルターし、ツリーを展開する
                this.model.setFilter(function (item, isLeaf, model) {
                    return isLeaf && (model.getLabel(item) || '').indexOf(value) !== -1;
                }).then(lang.hitch(this, function () {
                    this.tree.expandAll();
                }));
            } else {
                // 入力が空の場合は全要素を表示してツリーを閉じる
                this.model.setFilter().then(lang.hitch(this, function () {
                    this.tree.collapseAll();
                }));
            }
        }

    });
});

