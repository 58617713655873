/**
 * グリッドなどでPromiseの結果を表示するためのモジュール。
 * @module idis/view/LazyNode
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/when',
    'dijit/_WidgetBase'
], function(module, declare, lang, when, _WidgetBase) {
    /**
     * Promise結果表示用要素。
     * @class LazyNode
     * @extends module:dijit/_WidgetBase~_WidgetBase
     * @param {Object} kwArgs
     * @param {string} kwArgs.tagName ルート要素として用いるタグ名
     * @param {string|Node|Promise<string|Node>} kwArgs.promise HTMLとして表示可能な値またはそれを返すPromise
     */
    return declare(module.id.replace(/\//g, '.'), _WidgetBase,
       /** @lends module:idis/view/LazyNode~LazyNode# */ {
       /**
        * ルート要素として使うタグ名
        * @type {string}
        */
       tagName: 'div',

       // DOMノードを構築する
       buildRendering: function() {
           // ルート要素は指定したタグ名で作成
           if (!this.domNode) {
               this.domNode = this.ownerDocument.createElement(this.tagName);
           }
           this.inherited(arguments);
           // Promise解決時に内容を中身として表示
           when(this.promise).then(lang.hitch(this, 'onResolve'), lang.hitch(this, 'onReject'));
       },

       /**
        * 成功結果を加工する。
        * @param {Node|string} result 結果
        */
       onResolve: function(result) {
           if (!this._destroyed && this.domNode) {
               this.domNode.innerHTML = result;
           }
       },

       /**
        * 失敗時に結果を適用する。
        * @param {Error} err エラー
        */
       onReject: function(/* err */) {
           if (!this._destroyed && this.domNode) {
               this.domNode.innerHTML = 'Error';
           }
       }
   });
});
