/**
 * 標準日付入力欄用モジュール。
 * @module idis/view/form/DateInput
 */
define([
    'module',
    'dojo/_base/declare',
    'dijit/form/DateTextBox',
    './_DateTimeInputMixin',
    './_NamedMixin'
], function(module, declare, DateTextBox, _DateTimeInputMixin, _NamedMixin) {
    /**
     * 標準日付入力用ウィジェット
     * @class DateInput
     * @extends module:dijit/form/DateTextBox~DateTextBox
     * @extends module:idis/view/form/_DateTimeInputMixin~_DateTimeInputMixin
     * @extends module:idis/view/form/_NamedMixin~_NamedMixin
     */
    return declare(module.id.replace(/\//g, '.'), [DateTextBox, _DateTimeInputMixin, _NamedMixin],
        /** @lends module:idis/view/form/DateInput~DateInput# */ {
        // 不正な入力値に対するメッセージ
        invalidMessage: '実在の日付（YYYY/MM/DD 形式）を設定してください。',

        // 入力欄をYYYY/MM/DD形式にする
        constraints: {datePattern:'y/MM/dd'},

        /**
         * 終了日時用の入力欄かどうか
         * @type {boolean}
         */
        isTo: false,

        // プレースホルダーに表示する文字列
        placeHolder: 'YYYY/MM/DD',

        // value値を取得する
        _getValueAttr: function() {
            var value = this.inherited(arguments);
            // 入力値が設定され、かつ終了日時用入力欄の場合
            if (value && this.isTo) {
                // 元の結果をコピー
                value = new Date(value.getTime());
                // 時・分・秒・ミリ秒を最大値に設定
                value.setHours(23);
                value.setMinutes(59);
                value.setSeconds(59);
                value.setMilliseconds(999);
            }
            return value;
        }
    });
});
