define([
   './kmlFormat',
   'leaflet-plugins/layer/vector/KML',
   'dojo/_base/lang'
], function(format, kml, lang){
   return {
      // tanjsonからkmlへ
      geojsonToKML: function(tanjson) {
         var styleId = 0;
         var styleDataInfo = '';
         var folderDataInfo = '';
         var res = [];
         var kmlFormats = format.getKmlDefFormat();

         tanjson.features.forEach(lang.hitch(this, function(feature){
            res = this.generateKmlPart(feature, kmlFormats, styleId);
            if (!!res){
               // 定義部分を保管
               styleDataInfo += res[0];
               folderDataInfo += res[1];
               // kmlのstyleIDなるものがあるらしい
               styleId++;
            }
         }));

         return this.replaceContent(
            kmlFormats.kmlBase,
            [styleDataInfo + folderDataInfo]
         );
      },

      // 1作図データをKMLに変換する
      generateKmlPart: function(feature, kmlFormats, styleId){
         // 変換先フォーマット
         var kmlBase = [];
         // 変換リスト
         var styleContainer = [];
         var folderContainer = [];
         // feature
         var featureType = feature.properties._markerType;
         var options = feature.properties;
         // optionsがあればkml用に変換する
         var kmlOptions = this.toKmlOptions(options);
         // 変換対象
         var color       = kmlOptions.color;
         var fillColor   = kmlOptions.fillColor;
         var fillOpacity = kmlOptions.fillOpacity;
         var weight      = kmlOptions.weight;
         var iconUrl     = kmlOptions.iconUrl;
         var iconScale   = kmlOptions.iconScale;
         var title       = kmlOptions.title === undefined ? '' : kmlOptions.title;
         var description = kmlOptions.description === undefined ? '' : kmlOptions.description;
         var latlng      = this.toStringLatlngs(feature.geometry.coordinates, featureType);
         title = description !== '' && title === '' ? '無題' : title;

         switch(featureType) {
            case 'marker':
            case 'Icon':
               kmlBase = kmlFormats.marker;
               styleContainer.push(styleId, iconScale, iconUrl);
               folderContainer.push(title, description, styleId, latlng);
               break;
            case 'polyline':
            case 'freehand_polyline':
               kmlBase = kmlFormats.line;
               styleContainer.push(styleId, color, weight);
               folderContainer.push(title, description, styleId, latlng);
               break;
            case 'arrow':
               kmlBase = kmlFormats.arrow;
               styleContainer.push(styleId, color, weight);
               folderContainer.push(title, description, styleId, latlng[0], latlng[1]);
               break;
            case 'rectangle':
            case 'polygon':
            case 'freehand_polygon':
               kmlBase = kmlFormats.polygon;
               styleContainer.push(styleId, color, weight, fillColor, fillOpacity);
               folderContainer.push(title, description, styleId, latlng);
               break;
            // 処理対象外
            // TODO 円は変換できないことはないはず → ただし、Polygon・Circle変換を行う必要有り
            case 'circle':
            case 'CircleMarker':
            case 'DivIcon':
               console.log('sorry... out of Service');
               break;
            default :
               console.log('type is undefined');
         }

         // 変換対象でないものをフォーマットがあるかで判断。
         if (kmlBase.length !== 0) {
            // 定義部分を生成して返却
            return [
               this.replaceContent(kmlBase[0], styleContainer),
               this.replaceContent(kmlBase[1], folderContainer)
            ];
         }
      },

      replaceContent: function(def, value){
         return lang.replace(
               def,
               value,
               /\%\[([^\]]+)\]\%/g
         );
      },

      toKmlOptions: function(options){
         var kmlOptions = {};
         if (!!options._color  && !!options._opacity){
            kmlOptions.color = this.replaceColor(options._color, options._opacity);
         }
         if (!!options._fillColor && !!options._fillOpacity) {
            kmlOptions.fillColor = this.replaceColor(options._fillColor, options._fillOpacity);
         }
            kmlOptions.fillOpacity = '1';
         if (!!options._weight) {
            kmlOptions.weight = options._weight;
         }
         if (!!options._iconUrl) {
            kmlOptions.iconUrl = options._iconUrl;
            kmlOptions.iconScale = this.getIconScale(options._iconUrl, options._iconSize);
         }
         if (!!options._weight) {
            kmlOptions.weight = options._weight;
         }
         if (!!options.name) {
            kmlOptions.title = this.replaceString(options.name);
         }
         if (!!options.description) {
            kmlOptions.description = this.replaceString(options.description);
         }
         return kmlOptions;
      },

      toStringLatlngs: function(latlngs, featureType){
         var strLatLng = '';
         if (featureType === 'arrow') {
            var arrowllList = [];
            latlngs.forEach (function(latlng) {
               var tmpStrLl = '';
               latlng.forEach (function(ll, idx) {
                  tmpStrLl += (idx !== 0 ? ' ' : '') + ll[0] + ',' + ll[1];
               });
               arrowllList.push(tmpStrLl);
            });
            strLatLng = arrowllList;
         } else {
            if (featureType === 'Icon' ||
               // these following two types of layer is not handled yet
               featureType === 'CircleMarker' ||
               featureType === 'DivIcon' ){
               strLatLng = latlngs[0] + ',' + latlngs[1];
            } else {
               if (featureType !== 'freehand_polyline' &&
                  featureType !== 'polyline') {
                  latlngs = latlngs[0];
               }
               latlngs.forEach (function(ll, idx) {
                  strLatLng += (idx !== 0 ? ' ' : '') + ll[0] + ',' + ll[1];
               });
            }
         }
         return strLatLng;
      },

      escapeDwQuotes: function(word) {
         if(typeof word === 'string') {
            word = word.replace(/\n/g,'');
         }
         return word;
      },

      replaceString: function(content) {
         var result = '';
         if(!!content && content.length !== 0) {
            result +=
             '<![CDATA[' +
                 this.escapeDwQuotes(content) +
             ']]>';
         }
         return result;
      },

      getIconScale: function(iconUrl, iconSize) {
         var image = new Image();
         image.src = iconUrl;
         return iconSize[0] / image.naturalWidth;
      },

      replaceColor: function(color, argFillOpacity) {
         if (color === undefined || color === null) {return;}
         var fillOpacity = '7f';
         if(argFillOpacity !== null) {
            fillOpacity = ('00' + Math.floor((argFillOpacity * 255)).toString(16)).slice(-2);
         }
         var str = '';
         if(color.indexOf('rgb(') === -1) {
            //color.substr(4, 2) + color.substr(2, 2) + color.substr(0, 2);
            str = fillOpacity + color.replace('#','').substr(4, 2) +
                           color.replace('#','').substr(2, 2) +
                           color.replace('#','').substr(0, 2);
         } else {
            // 背景色の余計な文字列削除
            color = color.replace('rgb(','');
            color = color.replace(')','');
            // 文字列分割
            color = color.split(',');
            // 10進数を16進数に変換して連結(前0を付加)
            str = fillOpacity +
             ('00' + parseInt(color[2], 16).toString(16)).slice(-2)   +
             ('00' + parseInt(color[1], 16).toString(16)).slice(-2)   +
             ('00' + parseInt(color[0], 16).toString(16)).slice(-2);
         }
         return str;
      }
   };
});
