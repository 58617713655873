define([
    'leaflet',
    './CustomIcon',
    './CustomDivIcon',
    './_DrawUtil',
    './_SetStyleMixin'
], function(leaflet, CustomIcon, CustomDivIcon, Util, SetStyleMixin) {
    return {
        customAddData: function(tanJson){
            var features = leaflet.Util.isArray(tanJson) ? tanJson : tanJson.features,
                feature;

                if (features) {
                    for (var i = 0; i < features.length; i++) {
                        // Only add this if geometry or geometries are set and not null
                        feature = features[i];
                        if(feature !== null) {
                            if (feature.geometries || feature.geometry || feature.features || feature.coordinates) {
                                this.customAddData(features[i]);
                            }
                        }
                    }
                    return this;
                }

                var layer = this.addLayerByTanJSON(tanJson, tanJson.properties);
                layer.feature = leaflet.GeoJSON.asFeature(tanJson);

                layer.defaultOptions = layer.options;
                this.resetStyle(layer);

                return this.addLayer(layer);
        },

        addLayerByTanJSON: function(tanJson, options){
            var geometry = tanJson.type === 'Feature' ? tanJson.geometry : tanJson;
            var coords = tanJson ? tanJson.geometry.coordinates : null;
            var layers = [];
            var pointToLayer = options && options.pointToLayer;
            var coordsToLatLng = options && options.coordsToLatLng || this.coordsToLatLng;
            var vectorOptions = this.optionConverter(options, 'import');
            var latlng, latlngs, ret = null;

            if (!coords && !geometry) {return null;}

            switch (geometry.type) {
                case 'Point':
                    latlng = leaflet.GeoJSON.coordsToLatLng(coords);
                    if (pointToLayer) {
                        ret = leaflet.GeoJSON.pointToLayer(tanJson, latlng);
                    } else {
                        if (vectorOptions.drawType === 'CircleMarker') {
                            ret = new leaflet.circle(latlng, vectorOptions.radius, vectorOptions);

                        } else if (vectorOptions.drawType === 'DivIcon') {
                            ret = new leaflet.Marker(latlng, {
                                icon : new CustomDivIcon(vectorOptions)
                            });
                            ret = SetStyleMixin._setUpperOptions(ret);

                        } else { // vectorOptions.drawType === 'Icon'
                            ret = new leaflet.Marker(latlng, {
                                icon: new CustomIcon(vectorOptions)
                            });
                            ret = SetStyleMixin._setUpperOptions(ret);
                        }
                    }

                    if (vectorOptions.drawType !== 'DivIcon' && this._isNeededPopup(vectorOptions)){
                        Util._setPopup(ret, 'import');
                    }
                    return ret;
                case 'MultiPoint':
                    for (var i = 0; i < coords.length; i++) {
                        latlng = leaflet.GeoJSON.coordsToLatLng(coords[i]);
                        layers.push(pointToLayer ? pointToLayer(tanJson, latlng) : new leaflet.Marker(latlng));
                    }
                    return new leaflet.FeatureGroup(layers);
                case 'LineString':
                    latlngs = leaflet.GeoJSON.coordsToLatLngs(coords, 0, coordsToLatLng);
                    ret = new leaflet.Polyline(latlngs, vectorOptions);
                    if (this._isNeededPopup(vectorOptions)) {
                        Util._setPopup(ret, 'import');
                    }
                    return ret;
                case 'MultiLineString':
                    latlngs = leaflet.GeoJSON.coordsToLatLngs(coords, 1, coordsToLatLng);
                    ret = new leaflet.Polyline(latlngs, vectorOptions);
                    /*if(vectorOptions.drawType==='arrow'){
                        ret = ret.getLayers()[0];
                    }*/
                    if (this._isNeededPopup(vectorOptions)) {
                        Util._setPopup(ret, 'import');
                    }
                    return ret;
                case 'Polygon':
                case 'MultiPolygon':
                    if (coords.length === 2 && !coords[1].length) {
                        throw new Error('Invalid GeoJSON object.');
                    }
                    latlngs = leaflet.GeoJSON.coordsToLatLngs(coords, geometry.type === 'Polygon' ? 1 : 2, coordsToLatLng);
                    ret = new leaflet.Polygon(latlngs, vectorOptions);
                    if (this._isNeededPopup(vectorOptions)) {
                        Util._setPopup(ret, 'import');
                    }
                    return ret;
                case 'GeometryCollection':
                    for (i = 0; i < geometry.geometries.length; i++) {
                        layers.push(this.addLayersByTanJSON({
                            geometry: geometry.geometries[i],
                            type: 'Feature',
                            properties: tanJson.properties
                        }, vectorOptions));
                    }
                    return new leaflet.FeatureGroup(layers);
                default:
                    throw new Error('Invalid GeoJSON object.');
            }
        },

        _isNeededPopup: function(options){
            return options.title || options.description || options.fileList && options.fileList.length;
        }
    };
});
