define([
    'dojo/_base/lang',
    'leaflet',
    '../drawLocalExtendExtend',
    './Distance',
    './measureObj'
], function(lang, leaflet, drawLocalExtendExtend, Distance, measureObj) {
    // ツールチップ・テキスト
    var tooltipText = drawLocalExtendExtend.measure.handlers.area.tooltip;

    var Measure = lang.getObject('Measure', true, leaflet);

    Measure.Area = Distance.extend({
        statics: {
            TYPE: 'measure-area'
        },

        includes: leaflet.Mixin.Events,

        Poly: leaflet.Polygon,

        options: {
            showArea: false,
            shapeOptions: {
                stroke: true,
                color: '#f06eaa',
                weight: 5,
                opacity: 0.5,
                fill: true,
                fillColor: null, //same as color by default
                fillOpacity: 0.2,
                clickable: true
            }
        },

        initialize: function (map, options) {
            Distance.prototype.initialize.call(this, map, options);

            // Save the type so super can fire, need to do this as cannot do this.TYPE :(
            this.type = leaflet.Measure.Area.TYPE;
            this.setObj(this);
        },


        setObj: function (obj) {
            measureObj[this.constructor.TYPE] = obj;
        },

        getObj: function (type) {
            return measureObj[type];
        },


        _finishShape: function (e) {
            if (!this._shapeIsValid()) {
                return;
            }

            var firstLatlng = this._positions[0];
            this._poly.addLatLng(firstLatlng);
            Distance.prototype._finishShape.call(this, e);
        },

        _vertexChanged: function (latlng, added) {
            var latLngs;

            // Check to see if we should show the area
            latLngs = this._poly.getLatLngs();

            this._area = leaflet.GeometryUtil.geodesicArea(latLngs);

            Distance.prototype._vertexChanged.call(this, latlng, added);
        },

        _shapeIsValid: function () {
            return this._positions.length >= 3;
        },

        _getTooltipText: function () {
            var text, subtext;

            if (this._positions.length === 0) {
                text = tooltipText.start;
            } else if (this._positions.length < 3) {
                text = tooltipText.cont;
            } else if (this._afterDrawing) {
                text = tooltipText.finished;
                subtext = this._getMeasurementString();
            } else {
                text = tooltipText.end;
                subtext = this._getMeasurementString();
            }

            return {
                text: text,
                subtext: subtext
            };
        },

        /**
         * 計測結果を数値として返す。
         * @returns {number} 計測結果
         */
        _getMeasurementResult: function() {
            return this._area;
        },

        _getMeasurementString: function () {
            var area = this._area;

            if (!area) {
                return null;
            }

            return leaflet.GeometryUtil.readableArea(area, true);
        }

    });

    return Measure.Area;
});

