/**
 * ダム詳細情報のグラフ
 * @module app/dam/chart/DamHistoryChart
 */
define([
    'module',
    'app/view/chart/ChartConfig',
    'app/view/chart/ChartCommon',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojox/charting/action2d/Tooltip',
    'dojox/charting/action2d/Magnify',
    'dojox/charting/Chart',
    'dojox/charting/plot2d/Areas',
    'dojox/charting/plot2d/Lines',
    'dojox/charting/themes/Bahamation',
    'dojox/charting/widget/SelectableLegend',
    //
    'dojox/charting/axis2d/Default'
], function(module, ChartConfig, ChartCommon,
    array, declare, lang,
    Magnify, Tooltip, Chart, Areas, Lines, Theme, Legend
) {
    /**
     * ダム詳細情報のグラフ
     * @class DamHistoryChart
     */
    return declare(module.id.replace(/\//g, '.'), null,
        /** @lends module:app/dam/chart/DamHistoryChart~DamHistoryChart# */
    {
        title: 'ダム諸量',

        // 水位
        maxYAxis: 200, // Y軸最大値
        minYAxis: 200, // Y軸最小値

        // 諸量
        maxRightAxis: 5, // Y軸最大値

        legend: {},

        /**
         * グラフのノードを生成する
         * @param {*} node グラフを入れたいhtml内のdivに対するdojo-data-attach-pointで示したオブジェクト
         * @param {*} LegendNode 凡例を入れたいhtml内のdivに対するdojo-data-attach-pointで示したオブジェクト
         * @param {*} options.mode 検索条件の時間モード
         * @param {*} options.data 詳細GridのCollectionのデータ
         */
        create: function(node, LegendNode, options) {
            if(options){
                // 引数を変数にセット
                lang.mixin(this, options);
                // Gridとは逆順に並べる必要がある
                this.data.reverse();

                // 再作成の場合、表を破棄
                ChartCommon.destroyChart(this.chart);

                // タイトルをセット
                this.title = {
                    '10' : '10分',
                    '30' : '30分',
                    '60' : '時間'
                }[this.mode] + 'ダム諸量';

                // 縦軸の最大最小値をセット
                this.setMaxMinAxis();
            }

            this.chart = new Chart(node, {
                title: this.title,
                titlePos: 'top',
                titleGap: 10
            });

            // グラフのデザインテーマ, 他のグラフ群と合わせる
            this.chart.setTheme(Theme);

            // グラフの背景に描画される線の種類を決定。
            ChartCommon.addGridPlot(this.chart);

            // 水位軸
            this.chart.addAxis('y', {
                min: this.minYAxis-1,
                max: this.maxYAxis+10,
                vertical: true,
                fixLower: 'minor',
                fixUpper: 'minor'
            });

            // 諸量軸
            this.chart.addAxis('right', {
                min: 0,
                max: this.maxRightAxis+0.5,
                vertical: true,
                leftBottom: false,
                fixLower: 'minor',
                fixUpper: 'minor'
            });

            // 時間軸
            ChartCommon.addDataTimestampXAxis(this.chart, this.data);

            // 基準水位
            this.chart.addPlot('Level', {
                type: Lines,
                markers: false,
                labels: true,
                vAxis: 'y'
            });

            // エリア作成のためまたは、設計洪水位データがある場合に追加
            // if(!this.data||this.data.designFloodLevel){
            //     this.chart.addSeries('設計洪水位',
            //         ChartCommon.getChartData(this.data, 'designFloodLevel'), {
            //         plot: 'Level',
            //         stroke: {
            //             color: '#2B26BF'
            //         }
            //     });
            // }
            if (!this.data || this.data[0].floodWaterLevel) {
                this.chart.addSeries('サーチャージ水位',
                    ChartCommon.getChartData(this.data, 'floodWaterLevel'), {
                    plot: 'Level',
                    stroke: {
                        color: '#fc0502'
                    }
                });
            }

            this.chart.addSeries('貯水位',
                ChartCommon.getChartData(this.data, 'damQuantitiesLevel'), {
                plot: 'Level',
                stroke: {
                    color: '#4b14fa'
                }
            });

            if (!this.data || this.data[0].normalFullWaterLevel) {
                this.chart.addSeries('常時洪水位',
                    ChartCommon.getChartData(this.data, 'normalFullWaterLevel'), {
                    plot: 'Level',
                    stroke: {
                        color: '#eaf60f'
                    }
                });
            }

            // エリア作成のためまたは、第一期制限水位データがある場合に追加
            if(!this.data || this.data[0].firstLimitWaterLevel){
                this.chart.addSeries('制限水位',
                    ChartCommon.getChartData(this.data, 'firstLimitWaterLevel'), {
                    plot: 'Level',
                    stroke: {
                        color: '#fd14fc'
                    }
                });
            }

            // エリア作成のためまたは、第二期制限水位データがある場合に追加
            // if(!this.data||this.data.secondLimitWaterLevel){
            //     this.chart.addSeries('第二期制限水位',
            //         ChartCommon.getChartData(this.data, 'secondLimitWaterLevel'), {
            //         plot: 'Level',
            //         stroke: {
            //             color: '#04BF55'
            //         }
            //     });
            // }

            // エリア作成のためまたは、第三期制限水位データがある場合に追加
            // if(!this.data||this.data.thirdLimitWaterLevel){
            //     this.chart.addSeries('第三期制限水位',
            //         ChartCommon.getChartData(this.data, 'thirdLimitWaterLevel'), {
            //         plot: 'Level',
            //         stroke: {
            //             color: '#308C50'
            //         }
            //     });
            // }

            // this.chart.addSeries('最低水位',
            //     ChartCommon.getChartData(this.data, 'minWaterLevel'), {
            //         plot: 'Level',
            //         stroke: {
            //             color: '#000000'
            //         }
            // });

            // 諸量
            this.chart.addPlot('Quantities', {
                type: Lines,
                markers: true,
                labels: true,
                vAxis: 'right'
            });

            this.chart.addSeries('流入量',
                ChartCommon.getChartData(this.data, 'damInflowQuantities'), {
                    plot: 'Quantities',
                    marker: ChartConfig.MARKER_SHAPE,
                    stroke: {
                        color: '#0dfd0e'
                    },
                fill: '#0dfd0e'
            });
            this.damInflowQuantitiesTooltip = new Tooltip(this.chart, 'Quantities');
            this.damInflowQuantitiesMagnify = new Magnify(this.chart, 'Quantities');

            this.chart.addSeries('全放流量',
                ChartCommon.getChartData(this.data, 'damTotalReleaseQuantities'), {
                plot: 'Quantities',
                marker: ChartConfig.MARKER_SHAPE,
                stroke: {
                    color: '#a9cbf9'
                },
                fill: '#a9cbf9'
            });
            this.damTotalReleaseQuantitiesTooltip = new Tooltip(this.chart, 'Quantities');
            this.damTotalReleaseQuantitiesMagnify = new Magnify(this.chart, 'Quantities');

            this.chart.render();

            // プロットの凡例を表示
            // 初回起動時のみ生成
            if (!this.legend.id) {
                this.legend = new Legend({chart: this.chart}, LegendNode);
            }
            this.legend.set('chart', this.chart);
            this.legend.refresh();
			ChartCommon.setAxisTitle(this.chart, 'y', '諸水位[m]');
			ChartCommon.setAxisTitle(this.chart, 'right', '諸量[m^3/s]');
        },

        /**
         * 縦軸の最大最小値をセット
         */
        setMaxMinAxis: function(){
            // 初期化
            this.maxYAxis = 200;
            this.minYAxis = 200;
            this.maxRightAxis = 5;

            // 水位
            // Y軸最大値をセット検
            // this.maxYAxis = ChartCommon.getMaxData(this.data, 'designFloodLevel', this.maxYAxis);        // 設計洪水位
            this.maxYAxis = ChartCommon.getMaxData(this.data, 'floodWaterLevel', this.maxYAxis);         // サーチャージ水位
            this.maxYAxis = ChartCommon.getMaxData(this.data, 'normalFullWaterLevel', this.maxYAxis);    // 常時洪水位
            this.maxYAxis = ChartCommon.getMaxData(this.data, 'damQuantitiesLevel', this.maxYAxis);    // 貯水位
            // Y軸最小値をセット
            // this.minYAxis = ChartCommon.getMinData(this.data, 'minWaterLevel', this.minYAxis);    // 最低水位
            this.minYAxis = ChartCommon.getMinData(this.data, 'damQuantitiesLevel', this.minYAxis);    // 貯水位

            // 諸量
            // 右軸最大値をセット
            this.maxRightAxis = ChartCommon.getMaxData(this.data,'damInflowQuantities', this.maxRightAxis); // 流入量
            this.maxRightAxis = ChartCommon.getMaxData(this.data,
                    'damTotalReleaseQuantities', this.maxRightAxis);   // 全放流量
        }
    });
});