/**
 * <避難所情報概況画面>
 *
 * @module app/shelter/ShelterOpeningGrid.js
 */
define(['module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'idis/util/DateUtils',
    'dojo/dom',
    'dijit/registry',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer'
], function(module, declare, lang, DateUtils, dom, registry, IdisGrid, helper) {
    /**
     * <クラスの説明>
     *
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'),IdisGrid,
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */
        {
        columns: [
            {field: 'terminalName', label: 'ユーザ名・組織名', sortable: false},
            helper.buttonColumn('delete', '削除')
        ]
    });
});
