/**
 * 避難所一括画面・セレクタ選択ダイアログ用モジュール。
 * @module app/shelter/ShelterBulkSelectEditDialog
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/ShelterBulkSelectEditDialog.html',
    'dojo/topic',
    'idis/view/page/_PageBase', // 共通基底クラス
    './ShelterBulkOperatingGrid',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/Select',
    'idis/view/form/AclButton',
    'idis/view/form/Button'
], function(module, array, declare, lang, template, topic, _PageBase, ShelterBulkOperatingGrid) {
    /**
     * 避難所一括画面・セレクタ選択ダイアログ
     * @class ShelterBulkSelectEditDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/shelter/ShelterBulkSelectEditDialog~ShelterBulkSelectEditDialog# */ {
        // テンプレート文字列
        templateString: template,

        /**
         * 以下の属性を持つ編集対象情報。
         * grid: 対象グリッド
         * column: 対象列定義
         * idList: 対象行のID一覧
         * value: 対象の初期値
         * @type {Object}
         */
        _target: null,

        // DOM構築後に呼ばれる
        postCreate: function() {
            this.inherited(arguments);
            this.own(topic.subscribe(ShelterBulkOperatingGrid.SELECT_EDIT_EVENT, lang.hitch(this, function(evt) {
                this._target = evt;
                this.setPage();
                this.getParent().show();
            })));
        },

        /**
         * ダイアログ内容を初期化する。
         */
        setPage: function() {
            // 編集対象によってダイアログの表示文言を設定
            this.titleNode.innerHTML = this._target.column.label + 'の選択';
            this.labelNode.innerHTML = this._target.column.label + '：';
            // 選択肢をセット
            var options = array.filter(this._target.column._editEventPayload.options, function(opt) {
                return !!opt.value;
            });
            // 開設済＆混雑状況設定ありでない場合、未入力OKとする
            if (!(this._target.items.shelterStartTime && this._target.items.crowdedStatus)) {
                options.unshift({'value':'',  'label':'&nbsp;'});
            }
            this.selector.set('options', options);
                // フィールドをリセット
            this.form.reset();
            if (this._target.value) {
                this.selector.set('value', this._target.value);
            }
        },

        // 入力結果をグリッドに反映
        onSubmit: function() {
            // グリッド内容を書き換える
            this._target.grid.updateEditValues({
                field: this._target.column.field,
                value: this.selector.get('value'),
                idList: this._target.idList
            });
            this.getParent().hide();
        }
    });
});

