/**
 * チャットユーザ画面用モジュール。
 * @module app/chat/ChatUserPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/ChatUserPage.html',
    'idis/view/page/_PageBase',
    'dojo/dom-style',
    'dijit/Dialog',
    'idis/consts/ACL',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/store/IdisRest',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    'app/config',
    './_ChatUserDialogBase',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'app/view/form/CustomizableMunicipalitySelector',
    'app/view/form/UserOrganizationSelector',
    './ChatUserGrid',
    './ChatUserDetailDialog'
], function(module, declare, lang, template, _PageBase, domStyle, Dialog, ACL, Locator, Router,
	UserInfo, Requester, IdisRest, Loader, DialogChain, config, _ChatUserDialogBase) {
    /**
     * チャットユーザ画面
     * @class ChatUserPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _ChatUserDialogBase,
    /** @lends module:app/chat/ChatUserPage~ChatUserPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--chat',

        /**
         * ストア
         */
        store: null,

        // チャットグループID
        _chatGroupId: null,

        /**
         * constructor
         */
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'chatGroupDestId',
                target: '/api/chat/user',
                sortParam: 'sort',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は関連ダイアログも削除する
            this.own(this.innerChatUserDetailDialog);

            this.organizationSelector.restoreRefresh();
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);

            // チャットグループ画面から渡されたチャットグループIDをセットする。
            this._chatGroupId = Locator.getQuery().chatGroupId ? Locator.getQuery().chatGroupId : '';

            this.initGrid();
            this.initPage();

            this.setButtonStatus();
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.chatUserGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.showUpdateDialog(evt.item);
            }));

            this.updateGridQuery();
        },

        /**
         * チャット管理画面を初期化する。
         */
        initPage: function() {

            this.setChatGroupName();
            this.setMunicipality();

        	// 詳細ダイアログを初期化
        	this.initDetailDialog();
        },

        /**
         * 新規登録ダイアログを初期化する。
         */
        initRegisterDialog: function() {
            var dialog = this.chatUserRegisterDialog;
            var page = dialog.getChildren()[0];

            // 登録画面のregisterイベントを受け取る
            page.on('register', lang.hitch(this, function(evt) {
                console.debug('registerイベント');
                // 追加確認ダイアログを表示
                this.chain.confirmAdd(function(chain) {
                    // OKが押された場合
                    // 追加処理と共にローディング表示
                    Loader.wait(this.store.add(evt.value)).then(lang.hitch(this, function() {
                        // 成功時（POST結果はグリッドが自動的に反映）
                        // 登録ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                        // 一覧を取得しなおす
                        this.updateGridQuery();
                    }), function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
        },

        /**
         * 詳細ダイアログを初期化する。
         */
        initDetailDialog: function() {
            var dialog = this.chatUserDetailDialog;
            var page = dialog.getChildren()[0];

            // 詳細画面のremoveイベントを受け取る
            page.on('remove', lang.hitch(this, function(evt) {
                console.debug('removeイベント');
                this.chain.confirmDel(function(chain) {
                    var promise = this.store.remove(evt.value.chatGroupDestId + '?version=' + evt.value.version);
                    Loader.wait(promise).then(lang.hitch(this, function() {
                        // 登録フォーム・ダイアログを閉じる
                        dialog.hide();
                        chain.infoComplete();
                        // 一覧を取得しなおす
                        this.updateGridQuery();
                    }), function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));

            // 詳細画面のupdateイベントを受け取る
            page.on('update', lang.hitch(this, function(evt) {
                console.debug('updateイベント');
                // 追加確認ダイアログを表示
                this.chain.confirmPut(function(chain) {
                    var form = evt.value;
                    // 追加処理と共にローディング表示
                    Loader.wait(this.store.put(form)).then(lang.hitch(this, function() {
                        // 成功時（POST結果はグリッドが自動的に反映）
                        // 登録ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                        // 一覧を取得しなおす
                        this.updateGridQuery();
                    }), function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
        },

        // 市町村セレクタの値が変更するときの動作
        onMunicipalityCdChanged: function(municipalityCd) {
	        this.organizationSelector.restoreRefresh();
            this.organizationSelector.setMunicipalityCd(municipalityCd.value);
        },

        // ログインユーザの市/行政区をセットする
        setMunicipality: function () {
			var role = UserInfo.getRoleCd();
            var munic = UserInfo.getMunicipalityCd();
                // システム管理者・全体管理者・市本部管理者以外は市区選択セレクタを非表示
            if (!(role === ACL.ADMIN_USER || role === ACL.SYSMGR_USER || role === ACL.SHMGR_USER)) {
                this.municipalityCd.set('value', munic );
			    domStyle.set(this.municipalityCd.selectButton.domNode, 'display', 'none');
                // 所属管理者・所属本部ユーザ（人事室および総務局以外）・一般職員は自組織のみ閲覧可能
                if (role === ACL.XXMGR_USER ||
                    (role === ACL.XXHONBU_USER &&
                        (UserInfo.getOrganization().deptCd !== config.dept.humanResourceDeptCd ||
                            UserInfo.getOrganization().deptCd !== config.dept.soumuDeptCd )) ||
                            role === ACL.IPPAN_USER) {
                    // this.organizationSelector.restoreRefresh();
                    // 所属組織をセレクタにセット
                    this.organizationSelector.set('value', 'D' + UserInfo.getOrganization().deptCd);
                    domStyle.set(this.organizationSelector.selectButton.domNode, 'display', 'none');
                }
			}
	    },

        /**
         * チャットグループ名を表示する。
         */
         setChatGroupName: function(){
            var promise = Requester.get('/api/chat/group/' + this._chatGroupId, {
                preventCache: false
            }).then(lang.hitch(this,function(obj) {
                this.chatGroupName.innerHTML = obj.chatGroupName;
            }), lang.hitch(this, function(err){
                console.error(err);
            }));

            Loader.wait(promise);
        },

        /**
         * 利用者を検索する。
         */
        onSubmit: function() {
            console.debug('[検索]ボタンがクリックされました。');
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * チャットユーザ登録画面を表示する。
         */
        showRegisterPage: function() {
            console.debug('[新規登録]ボタンがクリックされました。');

            // チャットユーザ登録画面へ遷移
            Router.moveTo('chatUser/register', {
                chatGroupId : this._chatGroupId
            });

        },

        /**
         * チャットユーザ一括編集画面を表示する。
         */
        showBulkPage: function() {
            console.debug('[一括編集]ボタンがクリックされました。');

            // チャットユーザ登録画面へ遷移
            Router.moveTo('chatUser/bulk', {
                chatGroupId : this._chatGroupId
            });

        },

        /**
         * チャットグループ画面へ遷移する。
         */
        onReturnLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // チャットグループ画面へ遷移
            Router.moveTo('chatGroup');
        },

        /**
         * 利用者詳細ダイアログを表示する。
         */
        showUpdateDialog: function(object) {
            console.debug('[詳細]ボタンがクリックされました。');
            this.innerChatUserDetailDialog.initDialog(object);
            this.chatUserDetailDialog.show();
        },

        /**
         * グリットを更新する。
         */
        updateGridQuery: function() {
            var filter = new this.store.Filter();
            var value = this.form.get('value');
            console.log(value);

            // グループIDによる検索
            filter = filter.eq('chatGroupId', this._chatGroupId);

            // 所属による検索
            if (value.municipalityCd && value.municipalityCd !== '') {
                filter = filter.eq('municipalityCd', value.municipalityCd);
            }

            // 組織による検索
            if (value.organization && value.organization !== '') {
                filter = filter.eq('organization', value.organization);
            }

            // ユーザIDによる検索
            if (value.userId && value.userId !== '') {
                filter = filter.eq('userId', value.userId);
            }

            // ユーザ名による検索
            if (value.userName && value.userName !== '') {
                filter = filter.eq('userName', value.userName);
            }

            // 投稿可否による検索
            if (value.sendRoleType && value.sendRoleType !== '') {
                filter = filter.eq('sendRoleType', value.sendRoleType);
            }

            // 自動異動による検索
            if (value.autoUpdateFlg && value.autoUpdateFlg !== '') {
                filter = filter.eq('autoUpdateFlg', value.autoUpdateFlg);
            }

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);

            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.chatUserGrid.set('collection', collection);
        },
        setButtonStatus: function(){
            // 訓練モードの場合、ユーザ追加・ユーザ追加（一括）ボタンを非表示とする
            if(UserInfo.isTrainingFlg()){
                domStyle.set(this.showRegisterPageButton.domNode, 'display', 'none');
                domStyle.set(this.showBulkPageButton.domNode, 'display', 'none');
            }
        }
    });
});
