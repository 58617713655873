/**
 * 検索欄等のdijit/Selectでstoreをセットする際、
 * 元のstoreに影響を与えずに空選択肢を追加するためのモジュール。
 * @module idis/store/withEmptyOption
 */
define([
    'dojo/_base/lang',
    'dojo/store/util/QueryResults',
    'dojo/when'
], function(lang, QueryResults, when) {
    /**
     * 指定されたstoreのquery結果の先頭に空要素を加えたものをquery結果として返すオブジェクトを返す。
     * 検索欄等でdijit/Selectにstoreを紐付ける際、空要素を必要とする場合に用いる。
     * @function emptyAttached
     * @param {dojo/store/api/Store} 選択肢を格納したstore
     * @example
     * selectWidget.set('store', withEmptyOption(store));
     */
    return function(store) {
        // 空要素を準備
        var emptyItem = {};
        emptyItem[store.idProperty] = '';

        return lang.delegate(store, {
            /**
             * 空要素が指定された場合は空要素の文字列、
             * それ以外の場合はstoreのgetLabelの結果を返す。
             *
             * @param {Object} item 要素
             * @param {string} 表示文字列
             */
            getLabel: function(item) {
                // select要素やstoreにlabelAttrが指定されている場合はこれが呼ばれる前に解決するため、
                // storeが必ずしもgetLabelメソッドを実装している必要は無い
                return item === emptyItem ? '　' : store.getLabel.apply(store, arguments);
            },

            /**
             * 紐付けられたstoreのクエリー結果先頭に空要素を加えたものをquery結果として返す。
             * @param {Object} query 検索内容
             * @param {Object} opitons オプション
             */
            query: function() {
                // 元のstoreに対し指定された条件でquery
                var orgResult = store.query.apply(store, arguments);
                return when(orgResult, function(results) {
                    // 空要素を先頭に追加して返す
                    return QueryResults([emptyItem].concat(results));
                });
            }
        });
    };
});
