/**
 * ユーザーに権限のある県・県民局・市町村選択用入力パーツ
 * @module idis/view/form/ManagementRegionMunicipalitySelector
 */
 define([
    'module',
    'dojo/topic',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/when',
    'dojo/string',
    'dijit/form/TextBox',
    'idis/consts/USER_TYPE',
    'idis/view/form/TreeSelector',
    'idis/model/UserInfo',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'idis/view/dialog/InfoDialog',
    'app/config'
], function (module, topic, declare, lang, when, dStr, TextBox,
    USER_TYPE, TreeSelector, UserInfo, CacheStoreModel,
    CacheTreeRest, InfoDialog, config) {
    /**
     * 県/県民局/市町村選択用入力パーツ
     * @class FacilityTypeSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */

    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:idis/view/form/ManagementRegionMunicipalitySelector~TreeSelector# */ {
            // 選択ダイアログのタイトル
            title: '県/市町',

            // ツリー・モデル
            model: null,

            constructor: function () {
                this.model = new CacheStoreModel({
                    store: new CacheTreeRest({
                        target: '/api/municipalities/manageRegionMunicipality4Tree'
                    })
                });
            },

            startup: function () {
                if (this.defaultUnselected) {
                    return;
                }
                // 所属自治体を初期値とする
                switch (UserInfo.getUserType()) {
                    case USER_TYPE.PREFECTURE:
                        this.set('value', config.municInfo.prefCd);
                        break;
                    case USER_TYPE.REGION:
                        this.set('value', UserInfo.getRegionCd());
                        break;
                    case USER_TYPE.MUNICIPALITY:
                        if (UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd) {
                            this.set('noAllButton', true);
                        }
                        this.set('value', UserInfo.getMunicipalityCd());
                        break;
                    case  USER_TYPE.OTHER_ORGAN:
                        this.set('value', config.municInfo.prefCd);
                        break;
                }
            },

            // DOMを構築する
            buildRendering: function () {
                this.inherited(arguments);
                // 検索用入力要素を設置
                this.filterInput = new TextBox({
                    placeHolder: '市町'
                });
                this.filterInput.placeAt(this.treeControlNode);
                this.own(this.filterInput.on('keyup', lang.hitch(this, 'onFilterInputChange')));
            },

            /**
             * 検索用入力要素入力時。
             */
            onFilterInputChange: function () {
                // 入力値を取得
                var value = dStr.trim(this.filterInput.get('value'));
                if (value) {
                    // ラベル名が入力値を含むものでフィルターし、ツリーを展開する
                    this.model.setFilter(function (item, isLeaf, model) {
                        return isLeaf && (model.getLabel(item) || '').indexOf(value) !== -1;
                    }).then(lang.hitch(this, function () {
                        this.tree.expandAll();
                    }));
                } else {
                    // 入力が空の場合は全要素を表示してツリーを閉じる
                    this.model.setFilter().then(lang.hitch(this, function () {
                        this.tree.collapseAll();
                    }));
                }
            },
            /**
             * ツリーから市町が選択されてonOKをクリックした場合、変更されて入れば変更後の市町コードを渡すトピックを発行する。
             * 避難情報新規登録画面では、このトピックを受けて画面をリフレッシュする。
             * TreeSelectorの処理をオーバーライド
             */
            applySelectedItem: function () {
                // 要素が選択されていない場合は何もしない
                var item = this.tree.selectedItem;
                if (item) {
                    var value = this.tree.model.store.getIdentity(item);
                    // 市町が変更されていた場合、トピックを発行する。
                    if (this.value !== value) {
                        topic.publish(module.id + '::selected', value);
                    }
                    // 要素が選択されている場合、選択されている要素をウィジェットのvalueとして設定
                    this.set('value', value);
                }
            },

            /**
             * 選択した値の設定
             */
            // 継承元との差分は以下。
            // (1) prefUnselectable/regionUnselectableが指定された場合、県/県民局は選択不可。
            // (2) 親の名前を表示させない。ex. [岡山県/岡山市/岡山市中区] → [岡山市中区]
            _setValueAttr: function (value) {
                // 偽値は数値の0のみ有効とする
                if (value || value === 0) {
                    if (this.prefUnselectable) {
                        //県選択不可
                        if (value === config.municInfo.prefMunicCd || value === config.municInfo.prefCd) {
                            InfoDialog.show('エラー', '県は選択することができません。');
                            return false;
                        }
                    }
                    if (this.regionUnselectable) {
                        //県民局選択不可
                        if (value !== config.municInfo.prefMunicCd &&
                            value !== config.municInfo.prefCd &&
                            config.municInfo.prefRegCdGudge.indexOf(value) > -1) {
                            InfoDialog.show('エラー', '区部単位では選択することができません。');
                            this.reset();
                            return false;
                        }
                    }
                    if (this.cityUnselectable) {
                        //政令指定都市選択不可
                        if (value === config.municInfo.cityMunicCd) {
                            InfoDialog.show('エラー', '政令指定都市は選択することができません。');
                            this.reset();
                            return false;
                        }
                    }
                    this._set('value', value);
                    this.emit('change', { value: value });
                    this._initTree().then(lang.hitch(this, function () {
                        var model = this.tree.model;
                        var label;
                        if (this.fullName) {
                            label = model.getFullName(value);
                        } else {
                            label = when(model.store.get(value), function (item) {
                                return model.getLabel(item);
                            });
                        }
                        when(label, lang.hitch(this, function (name) {
                            // 親の情報は不要なので省略する。
                            var lastIndex = name.lastIndexOf('/');
                            if (lastIndex !== -1) {
                                var excludeParentName = name.substring(lastIndex + 1);
                                name = excludeParentName;
                            }
                            this.status.innerHTML = name;
                        }));
                    }));
                } else {
                    this._set('value', '');
                    this.emit('change', { value: '' });
                    this.status.innerHTML = this.emptyValueLabel;
                }
                // 要素の選択状態をリセットする
                this._initTree().then(lang.hitch(this, function () {
                    this.tree.set('selectedItem', null);
                }));
                this.dialog.hide();
            },

            reset: function () {
                this.inherited(arguments);
                topic.publish(module.id + '::selected');
            }

        });
});
