define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, lang, locale, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--chat',

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            helper.buttonColumn('detail', '詳細'),
            { field: 'userId', label: 'ユーザID', sortable: true },
            { field: 'municipalityName', label: '所属', sortable: true },
            { field: 'organizationName', label: '組織', sortable: true },
            { field: 'userName', label: 'ユーザ名', sortable: true },
            { field: 'emailAddress', label: 'メールアドレス', sortable: true },
            { field: 'sendRoleType', label: '投稿可否', sortable: true,
                formatter: lang.hitch(this, function(item){
                    switch (item) {
                        case '0':
                            return '不可';
                        case '1':
                            return '可';
                        default:
                            return '';
                    }
                })
            },
            { field: 'autoUpdateFlg', label: '自動異動', sortable: true,
                formatter: lang.hitch(this, function(item){
                    switch (item) {
                        case '0':
                            return '対象外';
                        case '1':
                            return '対象';
                        default:
                            return '';
                    }
                })
            }
        ]
    });
});
