/**
 * チャート描画共通処理
 * @module app/view/chart/ChartCommon
 */
define([
    'dojo/_base/array',
    'dojox/charting/plot2d/Grid',
    'dojox/gfx/matrix',
    'idis/util/DateUtils'
], function(array, Grid, Matrix, DateUtils) {
    return {
        /**
         * 縦軸上のラベルをセット
         * @param {*} chart    表ウィジェット
         * @param {*} axisname 左'y' 右'right'
         * @param {*} title    ラベル表記
         */
        setAxisTitle: function(chart, axisname, title) {
			console.debug('setAxisTitle');
			var axis = chart.axes[axisname];
			var x = axisname==='y' ? 55 :chart.dim.width - 50;
			var y = 30;
			var rotate = 0;
			var m = Matrix;
			var elem = axis.group.createText({
                x: x,
                y: y,
                text: title,
                align: 'middle'});
			elem.setFill('black');
			elem.setTransform(m.rotategAt(rotate, x,y));
        },

        /**
         * 背景グリッドをセット
         */
        addGridPlot: function(chart) {
            chart.addPlot('grid', {
                type: Grid,
                hMajorLines: true,
                hMinorLines: false,
                vMajorLines: true,
                vMinorLines: false,
                majorHLine: { color: 'silver', width: 0.5 },
                majorVLine: { color: 'silver', width: 0.5 }
            });
        },

        /**
         * 横軸のラベルをセット
         */
        addDataTimestampXAxis: function(chart, data){
            chart.addAxis('x', {
                majorTickStep: 1,
                natural: true,
                labels: this._getDataTimestampLabelData(data)
            });
        },

        /**
         * x軸ラベルを返す(観測時間)
         * @param   観測データ
         * @returns x軸用時間ラベル
         */
        _getDataTimestampLabelData: function(data) {
            if(!data){
                return [];
            }
            var labelData = [];
            array.forEach(data, function(item, i){
                labelData.push({
                    value: i + 1,
                    text: DateUtils.formatTime(item.dataTimestamp, 'HH:mm')
                });
            });
            return labelData;
        },

        /**
         * グラフデータを返す
         * @param {*} data 観測データ
         * @param {*} key データ名
         */
        getChartData: function(data, key) {
            if(!data){
                return [0,0];
            }
            var chatData = [];
            array.forEach(data, function(item, i){
                // 観測値データならフラグチェック
                var data = (!item[key+'Flg'] || item[key+'Flg']==='0') && isFinite(item[key]) ?
                    item[key] : null;
                chatData.push({
                    x: i + 1,
                    y: data
                });
            });
            return chatData;
        },

        /**
         * 観測値リストの最大値を返す
         * @param {*} data 観測データ
         * @param {*} key データ名
         * @param {*} max デフォルト最大値
         * @return {*} データ名の最大値
         */
        getMaxData: function(data, key, max) {
            array.forEach(data, function(item){
                var target = (!item[key+'Flg'] || item[key+'Flg']==='0') && isFinite(item[key]) ?
                    item[key] : null;
                if (target !== null && !isNaN(target)) {
                    if (max <= target){
                        max = target;
                    }
                }
            });
            return max;
        },

        /**
         * 観測値リストの最小値を返す
         * @param {*} data 観測データ
         * @param {*} key データ名
         * @param {*} min デフォルト最小値
         * @return {*} データ名の最小値
         */
        getMinData: function(data, key, min) {
            array.forEach(data, function(item){
                var target = (!item[key+'Flg'] || item[key+'Flg']==='0') && isFinite(item[key]) ?
                    item[key] : null;
                if (target !== null && !isNaN(target)) {
                    if (target <= min){
                        min = target;
                    }
                }
            });
            return min;
        },

        /**
         * 再作成の場合、表を破棄
         */
        destroyChart: function(chart){
        	// 初回起動時にchartTitleを参照してエラーとならないように入れ子構造にしている
            if(chart&&chart.chartTitle.innerHTML !== ''){
                chart.destroy();
            }
            return;
        }
    };
});

