/**
 * 配信の一般情報カテゴリーのグループ
 * @module app/provide/model/GeneralInfoCategoryGroup
 */
define([
    'module',
    'dojo/store/Memory'
    // 以下、変数で受けないモジュール
], function(module, Memory) {
    // シングルトンを返す
    return new Memory({
        idProperty: 'code',
        data: [
            {code: '1', label: '交通'},
            {code: '2', label: 'ライフライン'},
            {code: '3', label: '生活情報'},
            {code: '4', label: '広報'},
            {code: '5', label: '観光・文化'},
            {code: '6', label: 'その他'}
        ]
    });
});

