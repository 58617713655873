/**
 * お知らせ管理画面用モジュール。
 * @module app/bbs/BbsAdminPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'idis/control/Router',
    'dojo/text!./templates/BbsAdminPage.html',
    'idis/view/page/_PageBase',
    'idis/consts/ACL',
    'idis/model/UserInfo',
    'app/model/DisasterInfo',
    'idis/store/IdisRest',
    'idis/view/dialog/DialogChain',
    'dojo/dom-construct',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'idis/view/form/Button',
    'idis/view/form/AclButton',
    'idis/view/form/DateTimeInput',
    'app/view/form/MunicipalitySelector',
    './BbsAdminGrid',
    './BbsRegisterPage',
    './BbsDetailPage',
    '../common/view/StatementDialog',
    '../view/form/OrganizationSelector'
], function(module, declare, lang, domStyle, Router, template, _PageBase,
    ACL, UserInfo, DisasterInfo, IdisRest, DialogChain, domConstruct) {

    /**
    * 検索条件格納用オブジェクト
    * @type {Object}
    */
    var bbsFilterStore = {};

    /**
     * 掲示板一覧画面
     *  BbsAdminPage
     *  module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/bbs/BbsAdminPage~BbsAdminPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--bbs',

        /**
        * データ格納用オブジェクト
        * @type {module:dstore/Store}
        */
        store: null,

        /**
         * 災害ID
         * @private
         */
        _disasterId: null,

        /**
         * @type {Object}
         */
        timer: null,

        /**
         * constructor
         */
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'bbsId',
                target: '/api/bbses',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);

            // 災害IDをDisasterInfoから取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定させていません。');
            }

            console.debug('現在のログインユーザID：' + UserInfo.getId());
            console.debug('現在の災害ID：' + DisasterInfo.getDisasterId());
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
            // グリッドを初期化する
            this.initGrid();
            this.setButtonStatus();
            this.initUnreadPublishArea();
        },

        // ユーザー権限が「閲覧ユーザ」の場合は新規登録ボタンを非活性にする
        setButtonStatus: function(){
            var role = UserInfo.getRoleCd();
            if (role === ACL.VIEW_USER) {
                this.registerButton.setDisabled(true);
            }
        },
        // ユーザー権限が「閲覧ユーザ」の場合は未読通知掲載中情報チェックボックスを非表示にする
        initUnreadPublishArea: function(){
            var role = UserInfo.getRoleCd();
            if (role === ACL.VIEW_USER) {
                this.showUnreadPublishArea.setDisabled(true);
            }
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.bbsAdminGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.onDetailButtonClick(evt.item);
            }));
            this.setFilterData();
            this.updateGridQuery();
        },

        /**
         * 掲示板を検索する。
         */
        onSubmit: function() {
            console.debug('[検索]ボタンがクリックされました。');
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                } else {
                    console.debug('エラーあり');
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * グリットを更新する。
         */
        updateGridQuery: function() {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            var value = this.form.get('value');

            // 災害ID
            filter = filter.eq('disasterId', this._disasterId);

            // 発信日時FROM
            if (value.registerTimestampFrom) {
                var registerDateTimeFrom = new Date(value.registerTimestampFrom).getTime();
                filter = filter.eq('registerTimestampFrom', registerDateTimeFrom);
            }
            // 発信日時TO
            if (value.registerTimestampTo) {
                var registerDateTimeTo = new Date(value.registerTimestampTo).getTime();
                filter = filter.eq('registerTimestampTo', registerDateTimeTo);
            }
            // カテゴリ
            if (value.category) {
                filter = filter.eq('category', value.category);
            }
            // タイトル
            if (value.title) {
                filter = filter.eq('title', value.title);
            }

            // トップページ掲載
            if (this.notifyFlg1.checked) {
                filter = filter.eq('notifyFlg1', this.notifyFlg1.value);
            }
            if (this.notifyFlg2.checked) {
                filter = filter.eq('notifyFlg2', this.notifyFlg2.value);
            }

            // 掲載対象災害
            if (value.pubDisasterFlg) {
                filter = filter.eq('pubDisasterFlg', value.pubDisasterFlg);
            }
            // 掲載期間FROM
            if (value.pubTimestampFrom) {
                var pubTimestampFrom = new Date(value.pubTimestampFrom).getTime();
                filter = filter.eq('pubTimestampFrom', pubTimestampFrom);
            }
            // 掲載期間TO
            if (value.pubTimestampTo) {
                var pubTimestampTo = new Date(value.pubTimestampTo).getTime();
                filter = filter.eq('pubTimestampTo', pubTimestampTo);
            }
            // 未読情報通知を掲載中の情報を表示する
            if (value.showUnreadPublishFlg.length) {
                var showUnreadPublishFlg = '1';
                filter = filter.eq('showUnreadPublishFlg', showUnreadPublishFlg);
            }

            var collection = this.store.filter(filter);
            this.bbsAdminGrid.set('collection', collection);
            //検索条件の保管
            this.setFilterStore();

            this.bbsAdminGrid.on('attachFileLinkClick', lang.hitch(this, function(evt) {
                var attachFilePath = evt.item.attachFilePath.replace('out', 'data');
                var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + attachFilePath;
                this.downloadFile(url, evt.item.attachFileName);
            }));

            //定期更新処理
            this.timer = setTimeout(lang.hitch(this, function() {
                this.updateGridQuery();
            }),600*1000);
        },

        /**
         * 検索条件を保管する
         */
        setFilterStore: function() {
            //初期化する
            bbsFilterStore = {};
            var value = this.form.get('value');

            // 発信日時FROM
            if (value.registerTimestampFrom) {
                bbsFilterStore.registerTimestampFrom = value.registerTimestampFrom;
            }
            // 発信日時To
            if (value.registerTimestampTo) {
                bbsFilterStore.registerTimestampTo = value.registerTimestampTo;
            }
            // カテゴリ
            if (value.category) {
                bbsFilterStore.category = value.category;
            }
            // タイトル
            if (value.title) {
                bbsFilterStore.title = value.title;
            }
            // トップページ掲載
            if (value.notifyFlg1) {
                bbsFilterStore.notifyFlg1 = value.notifyFlg1;
            }
            if (value.notifyFlg2) {
                bbsFilterStore.notifyFlg2 = value.notifyFlg2;
            }
            // 掲載対象災害
            if (value.pubDisasterFlg) {
                bbsFilterStore.pubDisasterFlg = value.pubDisasterFlg;
            }
            // 掲載期間FROM
            if (value.pubTimestampFrom) {
                bbsFilterStore.pubTimestampFrom = value.pubTimestampFrom;
            }
            // 掲載期間To
            if (value.pubTimestampTo) {
                bbsFilterStore.pubTimestampTo = value.pubTimestampTo;
            }
            // 未読情報通知を掲載中の情報を表示する
            if (value.showUnreadPublishFlg.length) {
                bbsFilterStore.showUnreadPublishFlg = value.showUnreadPublishFlg;
            }
        },

        /**
         * 保管した検索条件をformにセットする
         */
        setFilterData: function() {
            // 発信日時FROM
            if(bbsFilterStore.registerTimestampFrom) {
                this.registerTimestampFrom.set('value', bbsFilterStore.registerTimestampFrom);
            }
            // 発信日時To
            if(bbsFilterStore.registerTimestampTo) {
                this.registerTimestampTo.set('value', bbsFilterStore.registerTimestampTo);
            }
            // カテゴリ
            if(bbsFilterStore.category) {
                this.category.set('value', bbsFilterStore.category);
            }
            // タイトル
            if(bbsFilterStore.title) {
                this.title.set('value', bbsFilterStore.title);
            }
            // トップページ掲載
            if(bbsFilterStore.notifyFlg1) {
                this.notifyFlg1.set('value', bbsFilterStore.notifyFlg1);
            }
            if(bbsFilterStore.notifyFlg2) {
                this.notifyFlg2.set('value', bbsFilterStore.notifyFlg2);
            }
            // 掲載対象災害
            if(bbsFilterStore.pubDisasterFlg) {
                this.pubDisasterFlg.set('value', bbsFilterStore.pubDisasterFlg);
            }
            // 掲載期間FROM
            if(bbsFilterStore.pubTimestampFrom) {
                this.pubTimestampFrom.set('value', bbsFilterStore.pubTimestampFrom);
            }
            // 掲載期間To
            if(bbsFilterStore.pubTimestampTo) {
                this.pubTimestampTo.set('value', bbsFilterStore.pubTimestampTo);
            }
            // 未読情報通知を掲載中の情報を表示する
            if(bbsFilterStore.showUnreadPublishFlg) {
                this.showUnreadPublishFlg.set('value', bbsFilterStore.showUnreadPublishFlg);
            }
        },

        onRegisterButtonClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // ホワイトボード登録画面へ遷移
            Router.moveTo('bbs/register', {
                type : ''
            });
        },

        onDetailButtonClick: function(object) {
            // ホワイトボード詳細画面へ遷移
            Router.moveTo('bbs/detail', {
                bbsId: object.bbsId
            });
        },

        /**
         * 一覧画面から添付ファイルのダウンロードを行う。
         */
        onFileDownload: function(item) {
            item.attachFile.forEach(function(element){
                var uri = element.attachFilePath.replace('out/', 'data/');
                var fileName = element.attachFileName;
                var download = null;
                var userAgent = window.navigator.userAgent.toLowerCase();
                if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {
                    var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + uri;
                    // IEの場合、download属性が効かないため、右クリック保存などaタグ機能を無効化.
                    // ダウンロード関数により元ファイル名でのダウンロードを可能にする.
                    download = domConstruct.create('a', {
                        href: '#'
                    });
                    // クリックでファイル取得処理に入る
                    download.onclick = function() {
                        this.downloadFile(url, fileName);
                    };
                    download.onclick();
                } else {
                    // FF, Chromeの場合、download属性でファイルダウンロード
                    download = domConstruct.create('a', {
                        href: uri,
                        download: fileName
                    });
                    download.click();
                }
            });
        },

        /**
         * 添付ファイルをダウンロードする。
         */
        downloadFile: function(url, name) {
            // Dojoのrequestor(dojo/request/xhr)のresponseType（handleAsプロパティ）で、
            // バイナリ（arraybuffer, blob）が利用できなかったためネイティブJSを利用している.
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'arraybuffer';
            xhr.onload = function() {
                var arrayBuffer = this.response;
                var blob = new Blob([arrayBuffer], {
                    type: 'application/octet-stream'
                });
                // IE10+
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, name);
                }
            };
            xhr.send();
            return false;
        },

        /**
         * 詳細入力の表示
         **/
        onShowQueryButtonClick: function(){
            this.showQuery();
        },

        onHideQueryButtonClick: function(){
            this.hideQuery();
        },

        hideQuery: function(){
            domStyle.set(this.hideQueryLabel, 'display', 'none');
            domStyle.set(this.showQueryLabel, 'display', '');
            domStyle.set(this.queryPane, 'display', 'none');
            this.searchContentPane.resize({h:55});
            this.borderContainer.layout();
        },

        showQuery: function(){
            domStyle.set(this.hideQueryLabel, 'display', '');
            domStyle.set(this.showQueryLabel, 'display', 'none');
            domStyle.set(this.queryPane, 'display', '');
            this.searchContentPane.resize({h:160});
            this.borderContainer.layout();
        }

    });
});
