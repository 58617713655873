/**
 * 発令不要登録ダイアログ
 * @module app/evacrecommend/UnnecessaryStatusRegisterDialog
 */
define([
    'module',
    'dojo',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/json',
    'dojo/text!./templates/UnnecessaryStatusRegisterDialog.html',
    'dojo/topic',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/view/page/_PageBase',
    'idis/util/DateUtils',
    'app/evacorder/EvacOrderType',
    'app/evacorder/Reason',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/WordCountTextarea'
], function(module, dojo, declare, lang, array, json, template, topic,
        Requester, DialogChain, Loader, _PageBase, DateUtils,
        EvacOrderType, Reason) {
    /**
     * 被害状況ファイルアップロードダイアログ
     * @class DamageReportFileUploadDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
                   /** @lends module:app/evacrecommend/UnnecessaryStatusRegisterDialog# */ {
        // テンプレート文字列
        templateString: template,

        // 基本クラス
        baseClass: 'idis-Page idis-Page--evacrecommend',

        // ID
        _evacRecommendId: null,

        constructor: function() {
            this.inherited(arguments);
            this.chain = DialogChain.get(this);
        },

        /**
         * ダイアログを初期化
         * @param {*} item 選択した行の発令判断支援情報
         */
        initDialog: function(item) {
            this._evacRecommendId = item.evacRecommendId;
            // 選択した発令判断支援情報をダイアログ上に表示
            this.setEvacRecommendInfo(item);
            
        },

        /**
         * 選択した行の発令判断支援情報を表示
         * @param {*} item 発令判断支援情報一覧の選択行データ一式
         */
        setEvacRecommendInfo: function(item) {
            // 更新日時
            this.updTimestamp.innerHTML = DateUtils.format(item.updTimestamp);
            // 市町名
            this.municipalityName.innerHTML = item.municipalityName;
            // 発令理由
            this.issueReasonType.innerHTML = Reason.get(item.issueReasonType).name;
            // 発令内容
            this.evacOrderType.innerHTML = EvacOrderType.get(item.evacOrderType).name;
			// 超過情報リスト
            var ul = dojo.create('ul', { style: { 'padding-left': '20px', 'text-align': 'left' } });
            array.forEach(item.recommendReason, function (reason) {
                ul.appendChild(dojo.create('li', {
                    innerHTML: reason.message,
                    // 基準超過は赤字
                    style: { color: (reason.recommendFlg === '1') ? 'red' : 'black'}
                }));
            });
            this.reasonList.appendChild(ul);
        },

        /**
         * 発令不要登録ボタン押下時の処理
         */
        onClickRegisterButton: function() {
            // フォームのバリデーションを実施
            if (!this.form.validate()) { return false; }
            // 登録データを取得
            var sendDataJsonStr = json.stringify({
                evacRecommendId: this._evacRecommendId,
                unnecessaryReason: this.unnecessaryReason.get('value')
            });
            // 確認ダイアログを表示
            var message = '発令不要登録を行います。\nよろしいですか？';
            this.chain.confirm(message, lang.hitch(this, function(chain) {
                // 登録処理開始
                Loader.wait(Requester.put('/api/evacrecommend/unnecessary', {
                    data: sendDataJsonStr,
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(lang.hitch(this, function() {
                    // 成功メッセージ表示
                    chain.info('登録が完了しました。', '完了', lang.hitch(this, function(){
                        // 確認ダイアログを閉じる
                        chain.hide();
                        // 親のダイアログを閉じる。
                        this.getParent().hide();
                        location.reload();
                    }));

                }), function(error) {
                    // 失敗時
                    console.error(error);
                    // 発令判断支援情報が見つからない場合は、情報再取得のためリロードする
                    chain.infoError(error, (error.response.data && error.response.data.code === 'E0001') ?
                        location.reload() : null);
                }));
            }));
        }

    });
});
