/**
 * 避難状況画面の地区選択ツリー用モデル
 * @module app/evacorder/EvacOrderStoreModel
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojox/lang/functional/object',
    'idis/store/CacheStoreModel',
    'idis/store/CachelessJsonRest',
    'idis/store/CacheTreeRest'
], function(module, array, declare, lang, df, CacheStoreModel, CachelessJsonRest, CacheTreeRest) {
    /**
     * 避難状況詳細の地区選択用store。
     * 先に災害と市町村の組み合わせに対応するJSONファイルを探し、
     * ない場合に限りAPIに対しリクエストを行う。
     * @class _LatestEvacOrderStore
     * @param {Object} kwArgs
     * @param {string} kwArgs.disasterId 災害ID
     * @param {string} kwArgs.municipalityCd 市町村コード
     * @private
     */
    var _LatestEvacOrderStore = declare(CacheTreeRest, {
        _getCache: function() {
            if (!this._cachePromise) {
                // キャッシュが無ければリクエスト
                // JSON取得用storeの準備
                var jsonPath = [
                    '/data/evacorder/cache/latest_',
                    this.targetQuery.disasterId,
                    '_',
                    this.targetQuery.municipalityCd,
                    '.json'
                ].join('');
                var jsonStore = new CachelessJsonRest({
                    target: jsonPath,
                    idProperty: this.idProperty
                });
                this.own(jsonStore);
                // API取得用のstoreの準備
                var apiStore = new CachelessJsonRest({
                    target: this.target,
                    idProperty: this.idProperty
                });
                this.own(apiStore);
                // リクエスト結果からキャッシュを生成
                // queryの返り値（dojo.Deferred）がotherwiseメソッドを持たないので、thenの第1引数をnullにして代用
                this._cachePromise = jsonStore.query()
                    .then(null, lang.hitch(apiStore, 'query', this.targetQuery))
                    .then(lang.hitch(this, '_createCache'));
            }
            return this._cachePromise;
        }
    });

    /**
     * 避難状況詳細の地区選択用ツリー
     * 避難状況詳細画面では、ラベルに避難情報の現況を表示しない。
     * @param {Object} kwArgs
     * @param {string} kwArgs.disasterId 災害ID
     * @param {string} kwArgs.municipalityCd 市町村コード
     * @class EvacOrderStoreModel
     * @extends module:idis/store/CacheStoreModel~CacheStoreModel
     */
    return declare(CacheStoreModel, /** @lends module:app/evacorder/EvacOrderStoreModel~EvacOrderStoreModel# */ {
        constructor: function(kwArgs) {
            // 地区一覧用storeを生成
            this.store = new _LatestEvacOrderStore({
                target: '/api/evacorders/latestSituation',
                targetQuery: {
                    disasterId: kwArgs.disasterId,
                    municipalityCd: kwArgs.municipalityCd
                }
            });
        },

        // ツリーのラベル表示を返す
        getLabel: function(item) {
            // 詳細画面のラベルには現況を表示しない。
            return item.districtName;
        }
    });
});
