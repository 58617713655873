/**
 * 気象エリア選択用入力パーツ(複数選択可)
 * @module app/view/form/KisyoAreaMultiSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/dom-style',
    'idis/view/form/TreeChecker',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest'
], function(module, declare, domStyle, TreeChecker, CacheStoreModel, CacheTreeRest) {
    /**
     * 気象エリア用パーツ。
     * @class KisyoAreaMultiSelector
     * @extends module:idis/view/form/TreeChecker~TreeChecker
     */
    return declare(module.id.replace(/\//g, '.'), TreeChecker,
        /** @lends module:idis/view/form/KisyoAreaMultiSelector~KisyoAreaMultiSelector# */ {
        // 選択ダイアログのタイトル
        title: '気象エリア',

        // ツリー・モデル
        model: null,

        constructor : function() {
            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                    target: '/api/KisyoAreas/KisyoAreas4Tree'
                })
            });
        }
    });
});

