/**
 * お知らせ種別を管理するモデル。
 * @module app/notice/NoticeTypeModel
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Evented',
    'idis/service/Requester'
], function(module, declare, lang, Evented, Requester) {
    return new declare(Evented, {
        // リクエスト先API
        target: '/api/notices/types',

        _load: function() {
            var promise = Requester.get(this.target);
            promise.then(lang.hitch(this, function(res) {
                this.emit('load', res);
            }));
            return promise;
        },

        load: function(force) {
            if (force || !this._promise) {
                this._promise = this._load();
            }
            return this._promise;
        }
    })();
});
