/**
 * チャットグループ画面用モジュール。
 * @module app/chat/ChatGroupPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/ChatGroupPage.html',
    'idis/model/UserInfo',
    'idis/view/page/_PageBase',
    'dijit/Dialog',
    'idis/control/Router',
    'idis/store/IdisRest',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    './_ChatGroupDialogBase',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    './ChatGroupGrid',
    './ChatGroupDetailDialog',
    './ChatGroupRegisterDialog'
], function(module, declare, lang, domStyle, template, UserInfo, _PageBase, Dialog, Router, IdisRest, Loader,
	DialogChain, _ChatGroupDialogBase) {
    /**
     * チャットグループ画面
     * @class ChatGroupPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _ChatGroupDialogBase,
    /** @lends module:app/chat/ChatGroupPage~ChatGroupPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--chat',

        /**
         * ストア
         */
        store: null,

        /**
         * constructor
         */
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'chatGroupId',
                target: '/api/chat/group',
                sortParam: 'sort',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は関連ダイアログも削除する
            this.own(this.innerChatGroupRegisterDialog);
            this.own(this.innerChatGroupDetailDialog);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);

            this.initGrid();
            this.initPage();

            this.setButtonStatus();
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.chatGroupGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.showUpdateDialog(evt.item);
            }));

            // グリッドのユーザ管理ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'list'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.chatGroupGrid.on('userAdminButtonClick', lang.hitch(this, function(evt) {
                this.onUserAdminButtonClick(evt.item);
            }));

            this.updateGridQuery();
        },

        /**
         * チャットグループ画面を初期化する。
         */
        initPage: function() {

        	// 新規登録ダイアログを初期化
        	this.initRegisterDialog();

        	// 詳細ダイアログを初期化
        	this.initDetailDialog();
        },

        /**
         * 新規登録ダイアログを初期化する。
         */
        initRegisterDialog: function() {
            var dialog = this.chatGroupRegisterDialog;
            var page = dialog.getChildren()[0];

            // 登録画面のregisterイベントを受け取る
            page.on('register', lang.hitch(this, function(evt) {
                console.debug('registerイベント');
                // 追加確認ダイアログを表示
                this.chain.confirmAdd(function(chain) {
                    // OKが押された場合
                    // 追加処理と共にローディング表示
                    Loader.wait(this.store.add(evt.value)).then(lang.hitch(this, function() {
                        // 成功時（POST結果はグリッドが自動的に反映）
                        // 登録ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                        // 一覧を取得しなおす
                        this.updateGridQuery();
                    }), function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
        },

        /**
         * 詳細ダイアログを初期化する。
         */
        initDetailDialog: function() {
            var dialog = this.chatGroupDetailDialog;
            var page = dialog.getChildren()[0];

            // 詳細画面のremoveイベントを受け取る
            page.on('remove', lang.hitch(this, function(evt) {
                console.debug('removeイベント');
                this.chain.confirmDel(function(chain) {
                    var promise = this.store.remove(evt.value.chatGroupId + '?version=' + evt.value.version);
                    Loader.wait(promise).then(lang.hitch(this, function() {
                        // 登録フォーム・ダイアログを閉じる
                        dialog.hide();
                        chain.infoComplete();
                        // 一覧を取得しなおす
                        this.updateGridQuery();
                    }), function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));

            // 詳細画面のupdateイベントを受け取る
            page.on('update', lang.hitch(this, function(evt) {
                console.debug('updateイベント');
                // 追加確認ダイアログを表示
                this.chain.confirmPut(function(chain) {
                    var form = evt.value;
                    // 追加処理と共にローディング表示
                    Loader.wait(this.store.put(form)).then(lang.hitch(this, function() {
                        // 成功時（POST結果はグリッドが自動的に反映）
                        // 登録ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                        // 一覧を取得しなおす
                        this.updateGridQuery();
                    }), function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
        },

        /**
         * チャットグループを検索する。
         */
        onSubmit: function() {
            console.debug('[検索]ボタンがクリックされました。');
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * チャットグループ新規登録ダイアログを表示する。
         */
        showRegisterDialog: function() {
            console.debug('[新規登録]ボタンがクリックされました。');
            this.innerChatGroupRegisterDialog.initDialog();
            this.chatGroupRegisterDialog.show();
        },

        /**
         * チャットグループ詳細ダイアログを表示する。
         */
        showUpdateDialog: function(object) {
            console.debug('[詳細]ボタンがクリックされました。');
            this.innerChatGroupDetailDialog.initDialog(object);
            this.chatGroupDetailDialog.show();
        },

        /**
         * ユーザ管理ボタンを押下したときの挙動
         */
         onUserAdminButtonClick: function(object) {
            console.debug('[ユーザ管理]ボタンがクリックされました。');
            console.debug(object);
            // チャットユーザ画面へ遷移
            Router.moveTo('chatUser', {
                chatGroupId : object.chatGroupId
            });
        },

        /**
         * グリットを更新する。
         */
        updateGridQuery: function() {
            var filter = new this.store.Filter();
            var value = this.form.get('value');
            console.log(value);

            // グループIDによる検索
            if (value.groupId && value.groupId !== '') {
                filter = filter.eq('chatGroupId', value.groupId);
			}

            // グループ名による検索
            if (value.groupName && value.groupName !== '') {
                filter = filter.eq('chatGroupName', value.groupName);
            }

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);

            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.chatGroupGrid.set('collection', collection);
        },

        setButtonStatus: function(){
            // 訓練モードの場合、新規登録ボタンを非表示とする
            if(UserInfo.isTrainingFlg()){
                domStyle.set(this.showRegisterDialogButton.domNode, 'display', 'none');
            }
        }
    });
});
