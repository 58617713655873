/**
 * 操作可能なツリー
 * @module idis/view/tree/MutableTree
 */
define([
    'module',
    'dojo/_base/declare',
    'dijit/registry',
    './IdisTree',
    './dndSourceForMutableTree'
], function(module, declare, registry, IdisTree, dndSourceForMutableTree) {
    /**
     * 全アイコンをフォルダとして表示するツリー・ウィジェット。
     * @class MutableTree
     * @extends module:idis/view/tree/IdisTree~IdisTree
     * @param {Object} kwArgs
     * @param {module:dijit/tree/model} kwArgs.model ツリー・モデル
     */
    return declare(module.id.replace(/\//g, '.'), IdisTree,
        /** @lends module:idis/view/tree/MutableTree~MutableTree# */ {
        /**
         * ツリー要素
         * @type {dijit/tree/Tree~TreeNode}
         */
        lastFocusedItem: null,

        // D&D用
        dndController: dndSourceForMutableTree,

        // D&Dで並び替えを可能にする
        betweenThreshold: 5,

        /**
         * 指定したDOM要素に対しD&D可能かどうかを返す。
         * フォルダでない要素の中には要素を追加出来ないようにする。
         * @param {Node} target 移動先DOMノード
         * @param {module:dijit/tree/dndSource~dndSource} source
         * @param {string} position 移動位置（"over", "before", "after"のいずれか）
         * @returns {boolean} 移動可能かどうか
         */
        checkItemAcceptance: function(target, source, position) {
            if (position !== 'over') {
                // 前後への追加は常に許容
                return true;
            }
            // ツリー要素ウィジェットを取得
            var treeNode = registry.getEnclosingWidget(target);
            var item = treeNode && treeNode.get('item');
            // 中に追加するにはフォルダであることを必須とする
            // （itemが取れない場合は継承元に揃えてtrueとする）
            return !item || item.infoCategoryCd && item.infoCategoryCd.indexOf('T') === 0;
        },

        // アイコンの表示
        getIconClass: function(item, opened) {
            // フォルダかどうかは情報種別コードで判定。
            // フォルダの場合は子要素がありかつ開いている場合だけ開いたフォルダ、それ以外は閉じたフォルダ
            // フォルダでない場合は一律で葉要素用アイコン
            return (!item || item.infoCategoryCd && item.infoCategoryCd.indexOf('T') === 0) ?
                (this.model.mayHaveChildren(item) && opened ? 'dijitFolderOpened' : 'dijitFolderClosed') : 'dijitLeaf';
        },

        // this.get('lastFocusedItem') 経由で呼ばれる
        _getLastFocusedItemAttr: function() {
            return this.lastFocusedItem;
        },

        // 要素を選択した際に呼ばれる
        focusNode: function(widget) {
            // 最後に選択した要素を記憶する
            this.set('lastFocusedItem', widget.item);
            this.inherited(arguments);
        }
    });
});

