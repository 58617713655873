define([
    'app/lalert/data/AreasCodes'
], function(AreasCodes) {
    return {
        '気象警報・注意報': {
            tiiki: [{id: '', value: 'すべて'}]
            .concat(AreasCodes.region)
            .concat(AreasCodes.district),

            keiho:[
                {id: '', value: 'すべて'},
                {id: '10', value: '注意報'},
                {id: '02', value: '警報'},
                {id: '32', value: '特別警報'}
            ]
        },

        '噴火警報・予報': {
            tiiki:[{id: '', value: 'すべて'}]
            .concat(AreasCodes.region)
            .concat(AreasCodes.district)
        },

        '竜巻注意情報': {
            tiiki:[{id: '', value: 'すべて'}]
            .concat(AreasCodes.region)
            .concat(AreasCodes.district)
        },

        '記録的短時間大雨情報': {
            tiiki:[{id: '', value: 'すべて'}]
            .concat(AreasCodes.region)
            .concat(AreasCodes.district)
        },

        '土砂災害警戒情報': {
            tiiki:[{id: '', value: 'すべて'}]
            .concat(AreasCodes.region)
            .concat(AreasCodes.district)
        },

        '地震情報': {
            tiiki:[{id: '', value: 'すべて'}]
            .concat(AreasCodes.district)
        },

        '震度速報': {
            tiiki: [{id: '', value: 'すべて'}]
            .concat(AreasCodes.earthquakeRegion)
        }

    };
});
