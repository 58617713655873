/**
 * 職員招集用ツリー選択入力要素を定義する。
 * @module app/view/form/ConvoTreeSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/dom-style',
    'dojo/text!./templates/ConvoTreeSelector.html',
    'dojo/when',
    'idis/view/_IdisWidgetBase',
    'idis/view/tree/IdisTree',
    'idis/view/form/_NamedMixin',
    // 以下、変数で受けないモジュール
    'idis/view/dialog/InfoDialog',
    'idis/view/form/Button'
], function(module, declare, lang, domClass, domStyle, template, when, _IdisWidgetBase, IdisTree, _NamedMixin) {
    /**
     * 職員招集用ツリー選択入力要素。
     * @class ConvoTreeSelector
     * @extends module:idis/view/_IdisWidgetBase~_IdisWidgetBase
     * @param {Object} kwArgs
     * @param {module:dijit/tree/model} [kwArgs.title] 選択ダイアログのタイトル
     * @param {module:dijit/tree/model} kwArgs.model ツリー・モデル
     * @param {module:dijit/Tree} kwArgs.treeClass ツリー生成用クラス
     */
    return declare(module.id.replace(/\//g, '.'), [_IdisWidgetBase, _NamedMixin],
        /** @lends module:app/view/form/ConvoTreeSelector~ConvoTreeSelector# */ {
        // テンプレート文字列
        templateString: template,

        /**
         * ウィジェットのダイアログのタイトル
         * @type {string}
         */
        title: '選択',

        /**
         * 選択値として親を含めた名前を表示するか
         * @type {boolean}
         */
        fullName: true,

        /**
         * 無効状態かどうか
         * @type {boolean}
         */
        disabled: false,

        /**
         * 編集不可状態かどうか
         * @type {boolean}
         */
        readOnly: false,

        /**
         * このプロパティーが定義されているとき、
         * {@link module:dijit/form/Form~Form}のget('value')による取得対象となる。
         * @type {string}
         */
        value: '',

        /**
         * ツリー・モデル
         * @type {dijit/tree/model}
         */
        model: null,

        /**
         * クリア・ボタンを表示するかどうか
         * @type {boolean}
         */
        clear: false,

        /**
         * ツリー・ウィジェット
         * @type {module:dijit/Tree~Tree}
         */
        tree: null,

        /**
         * ツリー生成用クラス
         * @type {function}
         */
        treeClass: IdisTree,

        /**
         * ツリー開閉用ボタンを表示するか
         * @type {boolean}
         */
        noTreeControl: false,

        /**
         * ツリー全展開用ボタンを表示するか（noTreeControlがtrueなら参照されない）
         * @type {boolean}
         */
        noExpandAll: false,

        /**
         * 選択、クリアボタンを表示するか
         * @type {boolean}
         */
        noAllButton: false,

        /**
         * 未選択時の文字列
         * @type {string}
         */
        emptyValueLabel: '未選択',

        // DOMノードを生成する
        buildRendering: function() {
            this.inherited(arguments);
            // 共通CSSクラスを追加
            domClass.add(this.domNode, 'idis-TreeSelector');

            if (this.noAllButton) {
              domStyle.set(this.selectButton.domNode, 'display', 'none');
              domStyle.set(this.clearButton.domNode, 'display', 'none');
            }
            // clearがfalseならクリア・ボタンは表示しない
            if (!this.clear) {
                domStyle.set(this.clearButton.domNode, 'display', 'none');
            }
            if (this.noTreeControl) {
                // ツリー開閉用ボタン領域を非表示にする
                domStyle.set(this.treeControlNode, 'display', 'none');
            } else if (this.noExpandAll) {
                // ツリー全展開ボタンのみを非表示にする
                domStyle.set(this.expandAllButton.domNode, 'display', 'none');
            }
            // 破棄する際はダイアログも連れて行く
            this.own(this.dialog);
        },

        /**
         * 入力フォーム上に設置されるボタンの押下可不可を設定する。
         * このウィジェットが無効状態か編集不可状態なら押下不可、それ以外なら押下可にする。
         */
        _updateFormButtonState: function() {
            var disabled = this.get('disabled') || this.get('readOnly');
            this.selectButton.set('disabled', disabled);
            this.clearButton.set('disabled', disabled);
            var display = this.get('noAllButton') ? 'none' : '';
            domStyle.set(this.selectButton.domNode, 'display', display);
            domStyle.set(this.clearButton.domNode, 'display', display);
        },

        // ウィジェットの無効化状態を設定する
        _setDisabledAttr: function(disabled) {
            this._set('disabled', disabled);
            this._updateFormButtonState();
        },

        // ウィジェットの編集不可状態を設定する
        _setReadOnlyAttr: function(readOnly) {
            this._set('readOnly', readOnly);
            this._updateFormButtonState();
        },

        // ウィジェットの非表示状態を設定する
        _setNoAllButtonAttr: function(display) {
          this._set('noAllButton', display);
          this._updateFormButtonState();
        },

        // value値を返す
        _getValueAttr: function() {
            return this.value;
        },

        // value値を設定する
        _setValueAttr: function(value) {
            // 偽値は数値の0のみ有効とする
            if (value || value === 0) {
                this._set('value', value);
                this.emit('change', {value: value});
                this._initTree().then(lang.hitch(this, function() {
                    var model = this.tree.model;
                    var label;
                    if (this.fullName) {
                        label = model.getFullName(value);
                    } else {
                        label = when(model.store.get(value), function(item) {
                            return model.getLabel(item);
                        });
                    }
                }));
            } else {
                this._set('value', '');
                this.emit('change', {value: ''});
            }
            // 要素の選択状態をリセットする
            this._initTree().then(lang.hitch(this, function() {
                this.tree.set('selectedItem', null);
            }));
        },

        /**
         * ウィジェットの値をリセットする。
         */
        reset: function() {
            this.set('value', '');
        },

        /**
         * ツリーを初期化する。
         */
        _initTree: function() {
            if (!this.tree) {
                this.tree = new this.treeClass({
                    model: this.model
                }, this.treeNode);
                this.tree.startup();
            }
            return this.tree.onLoadDeferred;
        },

        /**
         * 選択用ダイアログを表示する。
         */
        showDialog: function() {
            this._initTree();
            this.dialog.show();
        },

        /**
         * ツリー選択されている要素をこのウィジェットのvalue値として設定する。
         * 要素が選択されていない場合は何もしない。
         */
        applySelectedItem: function() {
            var item = this.tree.selectedItem;
            if (item) {
                this.set('value', this.tree.model.store.getIdentity(item));
            }
        },

        /**
         * ツリーがロード済みであれば全て展開する。
         */
        expandAll: function() {
            // ツリーがロード済みの場合のみ実施
            if (this._initTree().isResolved()) {
                // ツリーを展開
                this.tree.expandAll(); // .then(lang.hitch(this.dialog, 'reposition'));
            }
        },

        /**
         * ツリーがロード済みであれば全て折りたたむ。
         */
        collapseAll: function() {
            // ツリーがロード済みの場合のみ実施
            if (this._initTree().isResolved()) {
                // ツリーを閉じる
                this.tree.collapseAll(); // .then(lang.hitch(this.dialog, 'reposition'));
            }
        }
    });
});
