/**
 * ツリー選択入力要素を定義する。
 * @module idis/view/form/TreeSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/BbsNotifyContent.html',
    'idis/view/_IdisWidgetBase',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button'
], function(module, declare, lang, template, _IdisWidgetBase) {
    /**
     * ツリー選択入力要素。
     * @class TreeSelector
     * @extends module:idis/view/_IdisWidgetBase~_IdisWidgetBase
     * @param {Object} kwArgs
     * @param {module:dijit/tree/model} [kwArgs.title] 選択ダイアログのタイトル
     * @param {module:dijit/tree/model} kwArgs.model ツリー・モデル
     * @param {module:dijit/Tree} kwArgs.treeClass ツリー生成用クラス
     */
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase,
        /** @lends module:idis/view/form/TreeSelector~TreeSelector# */ {
        // テンプレート文字列
        templateString: template,

        /**
         * 未選択時の文字列
         * @type {string}
         */
        notify: '',
        timestamp:'',

        constructor: function(kwArgs) {
            lang.mixin(this, kwArgs);
        },

        // DOMノードを生成する
        buildRendering: function() {
            this.inherited(arguments);
            this.notify.innerHTML = this.notify;
            this.timestamp.innerHTML = this.timestamp;
        }
    });
});
