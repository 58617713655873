/**
 * 現地写真新規登録モジュール
 * @module app/view/page/PhotoUploadRegisterPage
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/json',
    'dojo/on',
    'dojo/text!./templates/PhotoUploadRegisterPage.html',
    'dstore/Memory',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    'idis/view/grid/IdisGrid',
    'idis/model/UserInfo',
    'exif-js/exif',
    // 以下、変数として受け取らないモジュール
    'dijit/form/Form',
    'dijit/form/CheckBox',
    'dijit/form/Select',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button',
    '../../view/form/LayerDirectorySelector',
    '../../view/form/OrganizationSelector',
    '../../view/form/PubStatusSelector',
    '../../view/form/BusinessDivisionSectionSelector',
    './PhotoUploadRegisterGrid'
], function(module, array, declare, lang, domStyle, json, on, template, Memory,
    InfoDialog, _PageBase, IdisGrid, UserInfo, exif) {

    /**
     * 現地写真新規登録
     * @class PhotoUploadRegisterPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
                   /** @lends module:app/view/page/PhotoUploadRegisterPage~PhotoUploadRegisterPage# */ {
        // テンプレート文字列
        templateString: template,

        store:null,

        constructor: function(){
            this.store = new Memory();
        },

        /**
         * グリッドを初期化する。
         * @function module:app/view/PhotoUploadRegisterPage~PhotoUploadRegisterPage#~startup
         */
        startup: function() {
            this.inherited(arguments);
            // グリッドを初期化する
            this.initGrid();
            // 写真選択時のデータの追加を制御する
            this.own(on(this.photo, 'change', lang.hitch(this, function(evt){
                this.addPhoto(evt);
            })));
            // 新規フォルダ記入エリアの表示/非表示を制御する
            this.own(on(this.newLayerFlg, 'change', lang.hitch(this, function(evt) {
                if(evt) {
                    this.newLayer.set('style', {display: ''});
                    this.newLayer.set('value', '');
                } else {
                    this.newLayer.set('style', {display: 'none'});
                }
            })));
            // 公開範囲選択時に公開事業課エリアの表示/非表示を制御する
            this.own(on(this.pubStatus, 'change', lang.hitch(this, function(evt) {
                if(evt === '2') {
                    domStyle.set(this.pubSectCdArea, 'display', '');
                } else {
                    domStyle.set(this.pubSectCdArea, 'display', 'none');
                    this.pubSectCd.set('value', '');
                }
            })));
            // 検索処理ではない為、検索結果が0件の場合のメッセージは非表示とする
            this.grid2.noDataMessage = '';
            this.grid2.startup();
        },

        // resizeメソッドを定義すると外側のresize時に合わせて呼ばれる
        resize: function(changeSize, resultSize) {
            // リサイズ時は中のBorderContainerに追随させる
            // （ウィジェット自体とBorderContainerで大きさが同一なので、サイズはそのまま渡す）
            this.registerDialogBorderContainer.resize(changeSize, resultSize);
        },

        /**
         * グリッドを初期化する
         */
        initGrid: function() {
            // 全件表示用のcollectionをグリッドにセットする
            // （テンプレートHTML内のdata-dojo-attach-point="grid"でグリッドをthis.gridに紐付け済み）
            this.grid2.set('collection', this.store.filter());
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'delete'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.grid2.on('deleteButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                this.deletePhoto(evt.item);
            }));
        },

        /**
         * データ格納領域を初期化し、データを設定する
         */
        setPage: function(){
            // storeを初期化する
            var id = [];
            this.store.fetch().forEach(function(object) {
                id.push(object.id);
            });
            for ( var i = 0; i < id.length; ++i ) {
                this.store.remove(id[i]);
            }
            this.grid2.refresh();

            // 現地写真選択領域を初期化する
            this.photo.value='';

            // ログインユーザの組織コードを取得する
            var orgCd = (UserInfo.getOrganization().unitCd ? 'U' + UserInfo.getOrganization().unitCd :
            UserInfo.getOrganization().sectCd ? 'S' + UserInfo.getOrganization().sectCd :
            UserInfo.getOrganization().deptCd ? 'D' + UserInfo.getOrganization().deptCd : '');

            // ログインユーザの組織情報を報告部署に設定する
            this.organization._setValueAttr(orgCd);

            // 格納先を設定する
            var defalutLayerId = 12;
            var deptCd = UserInfo.getOrganization().deptCd ? UserInfo.getOrganization().deptCd : '';
            switch(deptCd) {
                case 'D06':
                    defalutLayerId = 6;
                    break;
                case 'D07':
                    defalutLayerId = 7;
                    break;
                case 'D08':
                    defalutLayerId = 8;
                    break;
                case 'D09':
                    defalutLayerId = 9;
                    break;
                case 'D10':
                    defalutLayerId = 10;
                    break;
                case 'D11':
                    defalutLayerId = 11;
                    break;
            }
            this.parentLayerId._setValueAttr(defalutLayerId);

            // 公開範囲セレクトボックスを作成する
            this.pubStatus.createSelector();
            // 公開事業課セレクトボックスを作成する
            this.pubSectCd.createSelector();
        },

        /**
         * 写真格納フォルダを表示する
         */
        selectPhoto: function() {
            this.photo.click();
        },

        /**
         * 選択された写真を追加する
         */
        addPhoto:function(evt){
            var fileList = evt.target.files;
            var fileReader = new FileReader();

            this.own(on(fileReader, 'load', lang.hitch(this, function(e) {
                // 登録対象の写真の件数を取得する
                var maxId = null;
                array.forEach(this.store.data, lang.hitch(this, function(object){
                    if (maxId === null || (object !== null && object.id > maxId)) {
                        maxId = object.id;
                    }
                }));
                maxId = maxId + 1;
                //EXIFデータを取得する
                var dataUri = e.target.result ;
                this.getExifData(maxId, dataUri, fileList[0], this);
                }
            )));
          fileReader.readAsDataURL(fileList[0]);
        },

        /**
         * EXIFデータを取得する
         */
        getExifData: function(id, dataUri, fileList, self) {
            var fileName = fileList.name ;
            var dateTimeOriginal;
            var photoLatitude;
            var photoLongitude;

            exif.getData(fileList, function(){

                var gpsLatitude = exif.getTag(fileList, 'GPSLatitude');
                var gpsLongitude = exif.getTag(fileList, 'GPSLongitude');
                dateTimeOriginal = exif.getTag(fileList, 'DateTimeOriginal');

                if(gpsLatitude === void 0 || gpsLongitude === void 0 || dateTimeOriginal === void 0){
                        console.log('GPS data is unavailable.');
                        InfoDialog.show('エラー', '位置情報付写真ではありません。');
                } else {
                    var f = function(number){
                        return number[0].numerator + number[1].numerator /
                        (60 * number[1].denominator) + number[2].numerator / (3600 * number[2].denominator);
                    };

                    // 緯度、経度を設定する
                    photoLatitude = f(gpsLatitude);
                    photoLongitude = f(gpsLongitude);

                    self.store.put({id: id, photo: dataUri, photoFileName: fileName,
                        photoTimestamp: dateTimeOriginal, comment: '', delBtn: id,
                        photoLatitude: photoLatitude, photoLongitude: photoLongitude});
                    self.grid2.refresh();
                    self.grid2.on('dgrid-refresh-complete',function(){
                        self.grid2.resize();
                    });
                }
            });
        },

        /**
         * 選択された写真を削除する
         */
        deletePhoto:function(object){
            this.store.remove(object.id);
            this.grid2.refresh();
        },

        /**
         * 登録を実行する
         */
        onSubmit: function() {
            // 写真毎の詳細情報を格納するリスト
            var PhotoChildPostForm = [];

            // 写真毎の詳細情報を格納する配列
            var tmpPhotoData = {
                'photoFileName'   : '',
                'photoTimestamp'  : '',
                'photoLatitude'   : '',
                'photoLongitude'  : '',
                'comment'         : '',
                'fileBase64'      : ''
            };

            // formに定義されている情報を設定する
            var sendData = this.form.get('value');

            // 写真毎の詳細情報を取得する
            for( var i = 0; i < this.store.data.length; i++ ){
                var tmpStoreData = this.store.data[i];

                tmpPhotoData = {
                    'photoFileName'   : tmpStoreData.photoFileName,
                    'photoTimestamp'  : tmpStoreData.photoTimestamp.substr(0,10).replace(/:/g, '-') +
                                    'T' + tmpStoreData.photoTimestamp.substr(11,8) + '+0900',
                    'photoLatitude'   : tmpStoreData.photoLatitude,
                    'photoLongitude'  : tmpStoreData.photoLongitude,
                    'comment'         : tmpStoreData.comment,
                    'fileBase64'      : tmpStoreData.photo.split(',')[1]
                };
                PhotoChildPostForm[i] = tmpPhotoData;
            }

            sendData.PhotoChildPostForm = PhotoChildPostForm;
            console.log(sendData);

            try {
                if (this.form.validate() && this.isPhotoRegisterValid(sendData)) {
                    // 入力チェックが成功したらsendイベントを発行
                    // 実際の登録処理はサンプル画面側でsendイベントを監視しているメソッドが実施
                    this.emit('send', {
                        value: sendData
                    });
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 入力値をチェックする
         */
        isPhotoRegisterValid: function(data) {
            if (!data.parentLayerId || data.parentLayerId === '') {
                InfoDialog.show('入力エラー', '格納先を選択してください。');
                return false;
            }
            // 現地写真直下のフォルダのレイヤーID
            var array = [2,6,7,8,9,10,11,12];
            var flg = true;
            for ( var i = 0; i < array.length; ++i ) {
                if(data.parentLayerId === array[i]){
                    if(data.parentLayerId === 2 && (!data.newLayer || data.newLayer === '')){
                        var message = '現地写真直下に写真を登録することはできません。<br><br>' +
                                        '現地写真直下に新規フォルダを作成する、または、<br>' +
                                        '現地写真直下のフォルダを選択してください。';
                        InfoDialog.show('入力エラー', message);
                        return false;
                    }
                    flg = false;
                }
            }
            /*
            if (flg) {
                InfoDialog.show('入力エラー', '現地写真直下のフォルダを選択してください。');
                return false;
            }
            */
            if (data.PhotoChildPostForm.length === 0) {
                InfoDialog.show('入力エラー', '写真を選択してください。');
                return false;
            }
            // 報告名
            if(data.subject.length > 40){
                InfoDialog.show('入力エラー', '報告名は40字以下で入力してください。');
                return false;
            }
            // コメント
            var commentflg = true;
            data.PhotoChildPostForm.forEach(function(object) {
                if (object.comment.length > 150) {
                    commentflg = false;
                }
            });
            if (!commentflg) {
                InfoDialog.show('入力エラー', 'コメントは150字以下で入力してください。');
                return false;
            }

            return true;
        }
    });
});
