define([
    'module',
    'dojo/_base/declare',
    'dojo/date/locale',
    'dojo/dom-construct',
    'dojo/dom-style',
    'dojo/topic',
    'dojo/on',
    'idis/map/IdisMap',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'app/view/form/PubStatusSelector',
    'app/view/form/ZoomLevelSelector',
    'idis/model/UserInfo',
    'idis/consts/ACL',
    'idis/view/form/Button'
], function(module, declare, locale, domCon, domStyle, topic, on, IdisMap, IdisGrid, helper,
    PubStatusSelector, ZoomLevelSelector, UserInfo, ACL, Button) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'map-DetailGrid',

        /**
         * 各列の定義
         * @type {Object[]}
         */
         // 添付ファイルを管理したいと言い出した場合の想定。
         // 作図はテーブルを持っていないのと、添付ファイルは作図情報としてgeoJsonに持たせるようにしている。
         // よって管理するのであれば、作図データの保存時にdescriptionに入れる。
         // もしdescriptionも使いたいとかいわれたら、テーブル作成＋サーバーサイドを全部改修したほうが良い。
        columns: [
            helper.column('layerName', '報告名'),
            {field: 'updPubStatus', label:'公開範囲',
            renderCell: function(item){
                // 本当はもっといいアクセスの仕方があるはず。
                var pubUpdateArea = domCon.create('div', {'class':'dgirdUpdPubStatus'});
                var gridNode = this.grid.domNode;
                var selector = new PubStatusSelector();
                selector.createSelector(item.pubStatus);
                var button = new Button({
                    label: '更新',
                    onClick: function() {
                        on.emit(gridNode, 'changeStatusSubmit', {item: item});
                    }
                });
                var role = UserInfo.getRoleCd();
                if(role === ACL.VIEW_USER) {
                    var status = domCon.create('div');
                    status.textContent = selector.domNode.textContent;
                    domCon.place(status, pubUpdateArea);
                } else {
                    domCon.place(selector.domNode, pubUpdateArea);
                    domCon.place(button.domNode, pubUpdateArea);
                }
                return pubUpdateArea;
            }},
            {field: 'updZoomLevel', label:'表示最小ズームレベル',
            renderCell: function(item){
                // 本当はもっといいアクセスの仕方があるはず。
                var zoomUpdateArea = domCon.create('div', {'class':'dgirdUpdZoomLevel'});
                var gridNode = this.grid.domNode;
                if(!item.minZoom){item.minZoom = '8';}
                var selector = new ZoomLevelSelector();
                selector.createSelector(item.minZoom);
                var button = new Button({
                    label: '更新',
                    onClick: function() {
                        on.emit(gridNode, 'changeMinZoomSubmit', {item: item});
                    }
                });
                var role = UserInfo.getRoleCd();
                if(role === ACL.VIEW_USER) {
                    var status = domCon.create('div');
                    status.textContent = selector.domNode.textContent;
                    domCon.place(status, zoomUpdateArea);
                } else {
                    domCon.place(selector.domNode, zoomUpdateArea);
                    domCon.place(button.domNode, zoomUpdateArea);
                }
                return zoomUpdateArea;
            }},
            {field: 'organizationName', label: '報告部署', sortable: false},
            helper.column('crtUserId', '登録者'),
            helper.column('crtTimestamp', '登録日時'),
            helper.column('updUserId', '最終更新者'),
            helper.column('updTimestamp', '最終更新日時'),
            {field: 'delete', label:'削除',
            renderCell: function(item){
                var gridNode = this.grid.domNode;
                var button = new Button({
                    label: '削除',
                    onClick: function() {
                        on.emit(gridNode, 'deleteDrawLayer', {item: item});
                    }
                });
                var content = null;
                var role = UserInfo.getRoleCd();
                var org = UserInfo.getOrganization();
                var comment = domCon.create('div');
                comment.textContent = '削除権限がありません';
                if (role === ACL.VIEW_USER) {
                    content = comment;
                } else if (role === ACL.DEPT_USER) {
                     // 部局ユーザの場合、自部局が報告部署である場合のみ削除可能
                    if (!item.sectCd && org.deptCd && org.deptCd === item.deptCd) {
                        // ユーザの部コードと同一ならtrue
                        content = button.domNode;
                    } else if (!item.unitCd && org.deptCd && org.deptCd === item.deptCd &&
                        org.sectCd && org.sectCd === item.sectCd) {
                        // ユーザの課コードと同一ならtrue
                        content = button.domNode;
                    } else if (org.deptCd && org.deptCd === item.deptCd &&
                        org.sectCd && org.sectCd === item.sectCd &&
                        org.unitCd && org.unitCd === item.unitCd) {
                        // ユーザの係コードと同一ならtrue
                        content = button.domNode;
                    } else {
                        content = comment;
                    }
                } else {
                    content = button.domNode;
                }
                return content;
            }}
        ],

        // グリッド行がクリックされた場合に呼ばれる
        onRowClick: function(item) {
            // 地図を要素の指定する座標へ移動
            /*
            topic.publish(IdisMap.TOPIC.MOVE, {
                lat: item.damageLatitude,
                lng: item.damageLongitude
            });
            */
            // ポップアップを表示
            topic.publish(IdisMap.TOPIC.OPEN_POPUP, {id: item.layerId});
        }
    });

});
