define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'idis/view/form/Button',
    'dojo/on'
], function(module, declare, lang, locale, IdisGrid, helper, Button, on) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--chat',

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            helper.buttonColumn('detail', '詳細'),
            { field: 'chatGroupId', label: 'グループID', sortable: true },
            { field: 'chatGroupName', label: 'グループ名', sortable: true },
            { field: 'description', label: '目的', sortable: true },
            { field: 'chatGroupUseType', label: '使用区分', sortable: true,
                formatter: lang.hitch(this, function(item){
                    switch (item) {
                        case '1':
                            return '災害時';
                        case '2':
                            return '常時';
                        default:
                            return '';
                    }
                })
            },
            { field: 'updTimestamp', label: '更新日', sortable: true,
                formatter: lang.hitch(this, function(item){
                    if (item) {
                        return locale.format(new Date(item));
                    }
                    return '';
                })
            },
            {
                label: 'ユーザ管理',
                field: 'userAdmin',
                sortable: false,
                renderCell: function(item) {
                    var gridNode = this.grid.domNode;
                    var button = new Button({
                        label: 'ユーザ管理',
                        onClick: function() {
                            on.emit(gridNode, 'userAdminButtonClick', {item: item});
                        }
                    });
                    return button.domNode;
                }
            }
        ]
    });
});
