/**
 * 情報配信テンプレート一覧グリッド。
 * @module app/provide/ProvideTemplateGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'    
], function(module, declare, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid,
        /** @lends module:idis/view/page/ProvideTemplatePage~ProvideTemplatePage# */ {

        /**
         * 各列の定義
         * @type {Object[]}
         */
        collection: this.store,
        columns: [
            helper.buttonColumn('tmpDetail', '詳細'),
            helper.column('sendCategory', '種別',
                {formatMap: {'01': '体制情報','02': '勧告情報','03':'避難所情報','04':'お知らせ情報','99':'その他'}}),
            helper.column('urgencyType', '緊急度',
                {formatMap: {'01': '通常','02': '緊急'}}),
            helper.column('subject', 'タイトル'),
            helper.column('crtTimestamp', '作成日時')
        ]        
    });
});

