/**
 * 災害シミュレーション詳細ダイアログ
 * @module app/simulation/SimulationEventDetailDialog
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/SimulationEventDetailDialog.html', // テンプレート文字列
    './_SimulationEventPageBase', // 共通基底クラス
    'idis/view/Loader',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/control/Locator',
    'idis/control/Router',
    'dojo/_base/array',
    'idis/util/FilesUtils',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button',
    'idis/view/form/TimeInput',
    'dijit/form/RadioButton',
    'dijit/form/Form'
], function(module, declare, lang, domStyle, template, _SimulationEventPageBase, Loader, Requester,
    DialogChain, Locator, Router, array) {
    /**
     *  * 災害シミュレーション詳細ダイアログ
     * @class SimulationEventDetailDialog
     * @extends module:app/simulation/_SimulationEventPageBase~_SimulationEventPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _SimulationEventPageBase,
        /** @lends module:app/simulation/SimulationEventDetailDialog~SimulationEventDetailDialog# */ {

            // テンプレート文字列
        templateString: template,

        baseClass: 'idis-Page idis-Page--simulation',

        _eventId: null,
        _eventType: null,

        constructor: function(){
            this.inherited(arguments);
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },

        initDetailPage: function(item) {
            this._eventId = item.eventId;
            this._eventType = item.eventType;
            var eventTypeName = {
                '01': 'クロノロジ',
                '02': '避難情報',
                '03': '避難所情報',
                '04': '被害情報'
            }[this._eventType];
            this.eventType.innerHTML = eventTypeName;
            var mm,hh;
            if(typeof(item.timing) === 'number'){
                // ss = ('00' + (item.timing % 60)).slice(-2);
                mm = ('00' + parseInt((item.timing / 60), 10) % 60).slice(-2);
                hh = ('00' + parseInt(item.timing / 60 / 60, 10) % 24).slice(-2);
            } else {
                // ss = item.timing.getSeconds();
                mm = item.timing.getMinutes();
                hh = item.timing.getHours();
            }
            var time = new Date('','','',hh,mm);
            time.setHours(hh);
            time.setMinutes(mm);
            // time.setSeconds(ss);
            item.timing = time;
            this.form.set('value', item);

            this.getEventInfo();
        },

        /**
         * イベントごとの詳細情報を取得
         */
        getEventInfo: function() {
            var promise = Requester.get('/data/simulation/' + this._eventId + '.json')
            .then(lang.hitch(this, function(item) {
                var form = null;
                var conversion = null;
                switch (this._eventType) {
                    case '01':
                        form = item.chronologyPostForm;
                        conversion = item.chronologyConversionForm;
                        this.setChroValue(form, conversion);
                        break;
                    case '02':
                        form = item.evacOrderPostForm;
                        conversion = item.evacOrderConversionForm;
                        this.setEvacValue(form, conversion);
                        break;
                    case '03':
                        form = item.shelterPostForm;
                        conversion = item.shelterConversionForm;
                        this.setShelValue(form, conversion);
                        break;
                    case '04':
                        form = item.damageReportPostForm;
                        conversion = item.damageReportConversionForm;
                        this.setDmgValue(form, conversion);
                        break;
                }
            }), lang.hitch(this, function(error) {
                this.chain.infoError(error);
            }));
            // ローダーの表示
            Loader.wait(promise);
        },

        /**
         * クロノロジ情報を詳細ダイアログに設定
         */
        setChroValue: function(form, conversion) {
            domStyle.set(this.chroInfo, 'display', '');
            domStyle.set(this.evacInfo, 'display', 'none');
            domStyle.set(this.shelInfo, 'display', 'none');
            domStyle.set(this.dmgInfo, 'display', 'none');
            this.chronologyType.innerHTML = conversion.chronologyTypeName;
            var str = '';
            array.forEach(form.chronologyShares, function(chronologyShare){
                if(chronologyShare.senderFlg === '0'){
                    str += chronologyShare.shareName + ' ';
                }
            });
            this.urgencyType.innerHTML = conversion.urgencyTypeName;
            this.shareName.innerHTML = str;
            this.chroContent.innerHTML = form.content;
            this.hldStatus.innerHTML = conversion.hldStatusName;
        },

        /**
         * 避難情報を詳細ダイアログに設定
         */
        setEvacValue: function(form, conversion) {
            domStyle.set(this.chroInfo, 'display', 'none');
            domStyle.set(this.evacInfo, 'display', '');
            domStyle.set(this.shelInfo, 'display', 'none');
            domStyle.set(this.dmgInfo, 'display', 'none');
            var date = new Date(form.evacOrderTimestamp);
            this.evacOrderTimestamp.innerHTML =
                date.getFullYear() + '/' +
                date.getMonth() + '/' +
                date.getDate() + ' ' +
                date.getHours() + ':' +
                date.getMinutes();
            var districtNames = '';
            array.forEach(form.districtList, function(item){
                districtNames += item.districtFreeName + ' ';
            });
            if(districtNames.length > 50){
                districtNames = districtNames.substr(0, 50) + '…';
            }
            this.evacOrderDistrictCds.innerHTML = districtNames;
            this.evacMunicipalityName.innerHTML = conversion.municipalityName;
            this.issueReasonType.innerHTML = conversion.issueReasonTypeName;
            this.evacOrderType.innerHTML = conversion.evacOrderTypeName;
        },

        /**
         * 避難所情報を詳細ダイアログに設定
         */
        setShelValue: function(form, conversion) {
            domStyle.set(this.chroInfo, 'display', 'none');
            domStyle.set(this.evacInfo, 'display', 'none');
            domStyle.set(this.shelInfo, 'display', '');
            domStyle.set(this.dmgInfo, 'display', 'none');
            this.facilityName.innerHTML = conversion.facilityName;
            this.status.innerHTML = conversion.statusName;
        },

        /**
         * 被害情報を詳細ダイアログに設定
         */
        setDmgValue: function(form, conversion) {
            domStyle.set(this.chroInfo, 'display', 'none');
            domStyle.set(this.evacInfo, 'display', 'none');
            domStyle.set(this.shelInfo, 'display', 'none');
            domStyle.set(this.dmgInfo, 'display', '');
            this.dmgMunicipalityName.innerHTML = conversion.municipalityName;
            this.damageTypeList.innerHTML = conversion.damageTypeNameList;
            var date = new Date(form.reportTimestamp);
            this.reportTimestamp.innerHTML =
                date.getFullYear() + '/' +
                date.getMonth() + '/' +
                date.getDate() + ' ' +
                date.getHours() + ':' +
                date.getMinutes();
            this.dmgUrgencyType.innerHTML = conversion.urgencyTypeName;
        },

        /**
         * イベント更新ボタン押下
         */
        onUpdButtonClick : function() {
            // 入力チェック
            if (!this._validation()) { return false; }
            // 登録処理を行うPage.js側にformの情報を渡す
            this.emit('update', {
                eventId: this._eventId,
                timing: this._generateTimingToSec(),
                content: this.content.value});
        },

        onChangeButtonClick: function() {
            // 該当するイベント種別の登録画面へ遷移する
            var eventUrl = this.EVENT_URL_BASE[this._eventType] + 'detail';
            Router.moveTo(eventUrl, {
                simulateFlg: true,
                scenarioId: Locator.getQuery().scenarioId,
                eventId: this._eventId});
            this.getParent().hide();
        }
    });
});
