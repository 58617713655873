/**
 * フォルダ管理画面の並び替え処理を実装するMixin。
 * @module app/folder/view/_UpDownMixin
 */
define([
    'module',
    'dojo/_base/declare'
    // 以下、変数として受け取らないモジュール
], function(module, declare) {
    /**
     * フォルダ管理画面の並び替え処理を実装するMixin。
     * @class _UpDownMixin
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(null, /** @lends module:app/view/folder/view/_UpDownMixin~_UpDownMixin# */ {
        /**
         * 選択中の要素に従ってボタンの状態を最新化する。
         * @param {Object} [item] 選択中の要素
         */
        _updateUpDownButtons: function(item) {
            // 選択中要素が指定されなければ自分で取得
            item = item || this.leftTree.get('lastFocusedItem');
            // 並び替えボタンはツリー要素選択中しか使えない
            var siblings = item && this.model.getSiblings(item);
            var noSiblings = !siblings || siblings.length < 2;
            var index = !noSiblings && this.model.getItemIndex(siblings, item);
            if (this.upButton) {
                // 兄弟要素が存在しない or 一番上ならdisabled
                this.upButton.set('disabled', noSiblings || index < 1);
            }
            if (this.downButton) {
                // 兄弟要素が存在しない or 一番下ならdisabled
                this.downButton.set('disabled', noSiblings || index > siblings.length - 2);
            }
        },

        /**
         * ツリーの選択要素が変わったときに呼ばれる。
         */
        onLeftTreeFocus: function(item) {
            this.inherited(arguments);
            this._updateUpDownButtons(item);
        },

        /**
         * ツリーの状態が変わったときに呼ばれる。
         */
        onTreeChange: function() {
            this.inherited(arguments);
            this._updateUpDownButtons();
        },

        /**
         * 選択中のツリー要素の順番を上下に移動する。
         * @param {number} delta 下方向にいくつ動かすか（上方向なら負の値を指定）
         * @private
         */
        _moveUpDown: function(delta) {
            // 順番を変更
            var item = this.leftTree.get('lastFocusedItem');
            var parent = this.model.getParent(item);
            var siblings = this.model.getSiblings(item);
            this.model.moveItem(item, parent, parent, this.model.getItemIndex(siblings, item) + delta);
        },

        /**
         * 「↑」ボタンがクリックされた際に呼ばれる。
         * @param {MouseEvent} evt クリック・イベント
         */
        onUpButtonClick: function(/* evt */) {
            // 上へ移動
            this._moveUpDown(-1);
        },

        /**
         * 「↓」ボタンがクリックされた際に呼ばれる。
         * @param {MouseEvent} evt クリック・イベント
         */
        onDownButtonClick: function(/* evt */) {
            // 下へ移動
            this._moveUpDown(1);
        }
    });
});

