/**
 * 災害変更ダイアログ用グリッド
 * @module app/view/form/DisasterChangerGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dgrid/Selector',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, lang, locale, Selector, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), [IdisGrid, Selector],
        /** @lends module:idis/view/page/ProvideTemplatePage~ProvideTemplatePage# */ {

        /**
         * 各列の定義
         * @type {Object[]}
         */
        collection: this.store,
        columns: [
            helper.column('disasterName','災害名称'),
            helper.column('disasterType', '災害種別', {
                formatMap: {
                    '01': '風水害',
                    '02': '地震・津波',
                    '05': '火山',
                    '04': '国民保護',
                    '99': 'その他'
                }
            }),
            helper.column('startTimestamp', '発生期間（開始）', {
                formatter: function(item) {
                    if(item) {
                        return locale.format(new Date(item));
                    }
                    return '';
                }
            })
        ]
    });
});
