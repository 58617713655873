/**
 * 観測地域モデル
 * @module app/model/ObservationRegion
 */
define([
    'dojo/store/Memory'
    // 以下、変数で受けないモジュール
], function(Memory) {
    // シングルトンを返す
    return new Memory({
        idProperty: 'value',
        data: [
            {
                'value': '350001',
                'label': '山口県全域',
                'selected': true,
                'regionFlg': ''
            },
            {
                'value': '',
                'label': '------地域選択------',
                'disabled': 'true',
                'regionFlg': '1'
            },
            {
                'value': '350002',
                'label': '岩国圏域',
                'regionFlg': '1'
            },
            {
                'value': '350003',
                'label': '柳井圏域',
                'regionFlg': '1'
            },
            {
                'value': '350004',
                'label': '周南圏域',
                'regionFlg': '1'
            },
            {
                'value': '350005',
                'label': '山口・防府圏域',
                'regionFlg': '1'
            },
            {
                'value': '350006',
                'label': '宇部・小野田圏域',
                'regionFlg': '1'
            },
            {
                'value':'350007',
                'label':'下関圏域',
                'regionFlg': '1'
            },
            {
                'value':'350008',
                'label':'長門圏域',
                'regionFlg': '1'
            },
            {
                'value':'350009',
                'label':'萩圏域',
                'regionFlg': '1'
            }
        ]
    });
});
