/**
 * 招集メール抑止・解除を管理するモデル
 * @module app/model/ConvoMail
 */
 define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/store/Memory',
    'idis/service/Requester'
], function(module, declare, lang, Deferred, Memory, Requester) {

    // 招集メール自動配信抑止・抑止解除APIのエンドポイント
    var API_ENDPOINT = '/api/convoMails/mails/distributionType';

    // モジュール定義
    var ConvoMail = declare(module.id.replace(/\//g, '.'), null, {

        // 定数宣言
        // WARNING: '01',           // 警報・注意報
        EARTHQUAKE: '01',        // 地震
        TSUNAMI: '02',           // 津波
        // VOLCANO: '03',           // 火山
        CIVIL_PROTECTION: '04',  // 国民保護事案
        OTHER: '99',             // その他

        /**
         * 抑止中か抑止解除中かのステータスを取得する
         */
        getSuppressInfo: function() {
            var deferred = new Deferred();
            // APIコール
            Requester.get(API_ENDPOINT).then(lang.hitch(this, function(data) {
                var ret = {};

                data.forEach(lang.hitch(this, function(item) {
                    this._setSuppressFlag(ret, item);
                }));
                deferred.resolve(ret);


            }), function(error) {
                deferred.reject(error);
            });
            // プロミスを返却
            return deferred.promise;
        },

        _isSuppressed: function(flag) {
            return flag === '1' ? true : false;
        },

        _setSuppressFlag: function(ret, item) {
            switch(item.distributionTypeId) {
                // case this.WARNING:
                //     ret[this.WARNING] = this._isSuppressed(item.deterrenceFlg);
                //     break;
                case this.EARTHQUAKE:
                    ret[this.EARTHQUAKE] = this._isSuppressed(item.deterrenceFlg);
                    break;
                case this.TSUNAMI:
                    ret[this.TSUNAMI] = this._isSuppressed(item.deterrenceFlg);
                    break;
                // case this.VOLCANO:
                //     ret[this.VOLCANO] = this._isSuppressed(item.deterrenceFlg);
                //     break;
                case this.CIVIL_PROTECTION:
                    ret[this.CIVIL_PROTECTION] = this._isSuppressed(item.deterrenceFlg);
                    break;
                case this.OTHER:
                    ret[this.OTHER] = this._isSuppressed(item.deterrenceFlg);
                    break;
            }
        },

        /**
         * 抑止・抑止解除のステータスを更新する
         */
        suppress: function(type, flg) {
            var deferred = new Deferred();
            // APIコール
            Requester.put(API_ENDPOINT + '/' + type, {
                data: {
                    deterrenceFlg: flg ? '1' : '0'
                }
            }).then(lang.hitch(this, function(data) {
                // 返却値は今の所ないがとりあえずセット
                deferred.resolve(data);
            }), function(error) {
                deferred.reject(error);

            });
            // プロミスを返却
            return deferred.promise;
        }
    });

    // シングルトン
    return new ConvoMail();

});
