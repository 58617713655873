define([
    'module',
    'dojo/_base/declare',
    'dojo/date/locale',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, locale, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            helper.buttonColumn('detail', '選択'),
            helper.column('roadName', '路線名', {
                formatter: function(value, item) {
                    if (!item.roadName) {
                        return '';
                    }
                    return item.roadName;
                }
            }),
            helper.column('regStartPointName', '規制区間', {
                formatter: function(value, item) {
                    return (item.regStartPointName || '') + '&nbsp;〜&nbsp;' + (item.regEndPointName || '');
                }
            }),
            helper.column('regLength', '延長', {
                formatter: function(value, item) {
                    if (item.regLength && item.regLength !== '') {
                        return item.regLength + ' km';
                    } else {
                        return '';
                    }
                }
            })

        ]
    });
});
