/**
 * 複数のラジオボタンを管理するためのモジュール
 * @module idis/view/form/RadioGroup
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dijit/form/RadioButton',
    './_OptionGroupMixin'
], function(module, declare, lang, RadioButton, _OptionGroupMixin) {
    /**
     * 複数のラジオボタンを生成して選択出来るようにする。
     * @class RadioGroup
     */
    return declare(module.id.replace(/\//g, '.'), _OptionGroupMixin, {
        /**
         * 複数選択可能かどうか（dijit/form/Formから参照）
         * @type {boolean}
         */
        multiple: false,

        /**
         * ここでfalseが指定され、かつoptionsが文字列の配列で渡された場合、
         * 先頭の選択肢をvalue値を持たない（=選択時サーバーに送られない）選択肢として生成する
         * @type {boolean}
         */
        noEmpty: true,

        /**
         * 各オプションを生成するためのウィジェット・クラス
         * @type {function}
         * @override
         */
        _optionWidgetClass: RadioButton,

        /**
         * @override
         */
        _createOptionGroup: function() {
            if (this.noEmpty || !this.options || !lang.isString(this.options[0])) {
                // noEmptyがtrue or 選択肢をオブジェクト形式で明確に指定
                this.inherited(arguments);
            } else {
                // 先頭を取り出し空の選択肢要素として設置
                var emptyOption = this.createOption({label: this.options[0], checked: true});
                emptyOption.set('name', this.name);
                // 残りの設定を選択肢要素にする
                this.inherited(arguments, [this.options.slice(1)]);
                // 空の選択肢を選択肢一覧変数の先頭に追加する
                if (emptyOption) {
                    this._optionWidgets.unshift(emptyOption);
                }
            }
        }
    });
});
