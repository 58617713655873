/**
 * 初回に全データを取得し、以降はキャッシュを利用するdojo/store形式のStore。
 * @module idis/store/CacheRest
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/store/Memory',
    'dijit/Destroyable',
    '../error/InvalidArgumentException',
    './CachelessJsonRest'
], function(module, declare, lang, Memory, Destroyable, InvalidArgumentException, CachelessJsonRest) {
    /**
     * 最初に全要素を取得してキャッシュし、以降はその内容を返す。
     * 問い合わせ先APIは識別子を持つオブジェクトの配列をitemプロパティーに格納したJSONを返すこと。
     * 識別子として扱うプロパティー名はオプション指定可能。
     *
     * @class CacheRest
     * @extends module:idis/store/CachelessJsonRest
     * @param {Object} kwArgs
     * @param {string} kwArgs.target 一覧要素を返すAPIのURL
     * @param {Object} [kwArgs.targetQuery] APIへのGETリクエストに含めるクエリー・パラメーター
     * @example <caption>APIの返却値の例</caption>
     * {
     *     items: [
     *         {id: '001', name: '要素1'},
     *         {id: '002', name: '要素2'},
     *         ...
     *     ]
     * }
     */
    return declare([CachelessJsonRest, Destroyable], /** @lends module:idis/store/CacheRest~CacheRest# */ {
        /**
         * キャッシュ用ストアの準備が完了したら解決するPromise
         * @type {Promise}
         */
        _cachePromise: null,

        /**
         * リクエスト先URL
         * @type {string}
         */
        target: null,

        /**
         * リクエストに含めるパラメーター
         * @type {Object}
         */
        targetQuery: null,

        constructor: function(kwArgs) {
            kwArgs = kwArgs || {};
            // 引数チェック
            if (!kwArgs.target) {
                throw new InvalidArgumentException(module.id + '::constructor: Property "target" must be specified.');
            }
        },

        /**
         * 受け取ったデータからキャッシュを生成する。
         */
        _createCache: function(orgData) {
            // データを格納したMemoryをキャッシュとして返す
            return new Memory({
                idProperty: this.idProperty,
                data: orgData.items || orgData
            });
        },

        /**
         * キャッシュを取得する。
         * @returns {Promise} キャッシュ内容
         */
        _getCache: function() {
            if (!this._cachePromise) {
                // キャッシュが無ければリクエスト
                var store = new CachelessJsonRest({
                    target: this.target,
                    idProperty: this.idProperty
                });
                this.own(store);
                // リクエスト結果からキャッシュを生成
                this._cachePromise = store.query(this.targetQuery).then(lang.hitch(this, '_createCache'));
            }
            return this._cachePromise;
        },

        // 識別子に対応する要素の取得
        get: function() {
            var getArgs = arguments;
            return this._getCache().then(function(cache) {
                return cache.get.apply(cache, getArgs);
            });
        },

        // 問い合わせ用メソッド
        query: function() {
            var queryArgs = arguments;
            return this._getCache().then(function(cache) {
                return cache.query.apply(cache, queryArgs);
            });
        }
    });
});

