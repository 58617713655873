/**
 * 避難所情報一覧グリッド
 *
 * @module app/shelter/ShelterListGrid
 */
define(['module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'app/common/consts/DeliveredStatusFormatMap',
    './consts/CrowdedStatus',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer'
], function(module, declare, lang, IdisGrid, helper, deliveredStatus, CrowdedStatus) {
    /**
     * 避難所情報一覧グリッド
     *
     * @class 避難所情報一覧グリッド
     * @extends module:idis/view/grid/IdisGrid~IdisGrid
     */
    return declare(module.id.replace(/\//g, '.'),IdisGrid,
        /** @lends module:app/shelter/ShelterListGrid~ShelterListGrid# */
        {
        columns: [
            helper.buttonColumn('detail', '詳細'),
            {field: 'municipalityName', label: '市町', sortable: true},
            {field: 'districtName', label: '地区', sortable: true},
            {field: 'facilityName', label: '避難所名', sortable: true},
            helper.column('seqNum', '報告連番', {formatter: function(value) { return '第' + value + '報'; }}),
            {field: '_item', label: 'ステータス',
                className : function(item) {
                    // ヘッダーの場合はclassNameを返さない
                    if (!item) {
                        return;
                    }
                    if (item.status === '1') {
                        if (item.crowdedStatus === '1') {
                            return 'open-capacity-under';
                        } else if (item.crowdedStatus === '2') {
                            return 'open-capacity-crowded';
                        } else if (item.crowdedStatus === '3') {
                            return 'open-capacity-over';
                        } else if (item.crowdedStatus === '9') {
                            return 'open-capacity-unknown';
                        }
                    } else if (item.status === '9') {
                        return 'close';
                    }
                },
                formatter: function(item) {
                    if (item.status === '0') {
                        return '開設準備中';
                    } else if (item.status === '1') {
                        if (item.crowdedStatus) {
                            return '開設済み<br/>（' + CrowdedStatus.getLabel(item.crowdedStatus) + '）';
                        } else {
                            return '開設済み';
                        }
                    } else if (item.status === '9') {
                        return '閉鎖';
                    } else {
                        return '-';
                    }
                }
            },
            {field: 'shelterStartTime', label: '開設日時', sortable: true,
                formatter: lang.hitch(this, function(value, item){
                    if (!item || item.status === '0') { //開設準備中の場合
                        return '-';
                    }else{
                        return value;
                    }
                })
            },
            {field: 'shelterEndTime', label: '閉鎖日時', sortable: true,
                formatter: lang.hitch(this, function(item){
                    if (!item) {
                        return '-';
                    }else{
                        return item;
                    }
                })
            },
            {
                field: '_item', label: '要請有無',
                className : function(item) {
                    // ヘッダーの場合はclassNameを返さない
                    if (!item) {
                        return;
                    }
                    if (item.requestFlg === '1') {
                        return 'requestFlg-active';
                    }
                },
                formatter: function(item) {
                    if (item.requestFlg === '1') {
                        var requestContent = item.requestContent;
                        if (requestContent.length > 10) {
                            requestContent = requestContent.substring(0,10) + '...';
                        }
                        return '有' + '<br />' + '（' + requestContent + ')';
                    } else {
                        return '無';
                    }
                }
            },
            {field: 'evaqueeNum', label: '避難者数', sortable: true,
                formatter: lang.hitch(this,function(item) {
                    if (!item) {
                        return '-';
                    }
                    return item;
                })
            },
            {field: 'capacity', label: '最大収容人数', sortable: true,
                formatter: lang.hitch(this,function(item) {
                    if (item === null || !item) {
                        return '-';
                    }
                    return item;
                })
            },
            {field: '_item', label: '避難所区分', sortable: true,
                formatter:lang.hitch(this,function(item) {
                    var shelterCategory = '';
                    if (item.edesignatedEvacShFlg === '1'){
                        shelterCategory += '指定緊急 ';
                    }
                    if (item.designatedEvacShFlg === '1'){
                        shelterCategory += '指定 ';
                    }
                    if (item.welfareEvacShFlg === '1'){
                        shelterCategory += '福祉 ';
                    }
                    if (item.temporaryEvacShFlg === '1'){
                        shelterCategory += '臨時';
                    }
                    if (item.civilProtectionEvacShFlg === '1'){
                        shelterCategory += '国民保護';
                    }
                    if (item.otherEvacShFlg === '1'){
                        shelterCategory += 'その他';
                    }
                    return shelterCategory;
                })
            },
            {
                label: 'ライフライン確認状況',field: 'lifeLine', sortable: false,
                children: [
                helper.column('elecDmgFlg', '電気', {
                    formatter:lang.hitch(this,function(item) {
                        if (item === '1'){
                            return '〇';
                        } else if (item === '2'){
                            return '✕ ';
                        } else {
                            return '未';
                        }
                    })
                }),
                helper.column('gasDmgFlg', 'ガス', {
                    formatter:lang.hitch(this,function(item) {
                        if (item === '1'){
                            return '〇';
                        } else if (item === '2'){
                            return '✕ ';
                        } else {
                            return '未';
                        }
                    })
                }),
                helper.column('waterDmgFlg', '水道', {
                    formatter:lang.hitch(this,function(item) {
                        if (item === '1'){
                            return '〇';
                        } else if (item === '2'){
                            return '✕ ';
                        } else {
                            return '未';
                        }
                    })
                }),
                helper.column('phoneDmgFlg', '電話', {
                    formatter:lang.hitch(this,function(item) {
                        if (item === '1'){
                            return '〇';
                        } else if (item === '2'){
                            return '✕ ';
                        } else {
                            return '未';
                        }
                    })
                }),
                helper.column('toiletDmgFlg', 'トイレ', {
                    formatter:lang.hitch(this,function(item) {
                        if (item === '1'){
                            return '〇';
                        } else if (item === '2'){
                            return '✕ ';
                        } else {
                            return '未';
                        }
                    })
				})
				]
			},
            {field: 'updTimestamp', label: '最終更新日時', sortable: true}
        ]
    });
});
