/**
 * 格納先選択用入力パーツ
 * @module idis/view/form/LayerDirectorySelector
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/form/TreeSelector',
    'idis/view/tree/FolderTree',
    '../../model/LayerDirectory'
], function(module, declare, TreeSelector, FolderTree, LayerDirectory) {
    /**
     * 格納先選択用パーツ。
     * @class LayerDirectorySelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:idis/view/tree/LayerDirectorySelector~LayerDirectorySelector# */ {
        // 選択ダイアログのタイトル
        title: '格納先選択',

        // ツリー・モデル
        model: LayerDirectory,

        // ツリー・ウィジェット生成用クラス
        treeClass: FolderTree,

        // ツリー全展開ボタンは危険なので非表示
        noExpandAll: true
    });
});

