/**
 * 災害選択用入力パーツ
 * @module idis/view/form/DisasterSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/topic',
    'dojo/_base/lang',
    'app/model/DisasterInfo',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'idis/view/form/TreeSelector',
    'idis/view/Loader'
], function(module, declare, topic, lang, 
    DisasterInfo, CacheStoreModel, CacheTreeRest, TreeSelector, Loader) {
    /**
     * 組織選択用パーツ。
     * @class DisasterSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:app/view/tree/DisasterSelector~DisasterSelector# */ {
        // 選択ダイアログのタイトル
        title: '災害選択',

        // ツリー・モデル
        model: null,

        // ツリー開閉用ボタン表示有無
        noTreeControl: true,

        constructor: function() {
            // 設置されるたびにモデルを新規生成する
            this._initModel();
            // 災害名登録がPubされた際にツリーを最新の情報に更新する
            topic.subscribe('app/disaster/view/DisasterAdminPage::registered',
                lang.hitch(this, function(payload) {
                    // ツリーの更新
                    var promise = this.updateTree();
                    // 災害名登録時に付番された災害IDをDisasterInfoにpubする
                    Loader.wait(promise).then(lang.hitch(this, function() {
                        topic.publish(module.id + '::registered', payload);
                    }));   
            }));    
        },
        
        _initModel: function(){
            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                target: '/api/disasters/disasters4Tree'
                })
            });
            return this.model;
        },

        applySelectedItem: function() {
            this.inherited(arguments);
            var disasterId = this.get('value');
            DisasterInfo.setDisasterId(disasterId);
        },
        
        // ダイアログを表示する度にmodelを呼び出すことで施設種別テーブルの変更点をツリーに反映
        showDialog: function() {
            this.updateTree();
            return this.inherited(arguments);
        },
        
        updateTree: function() {
            if(this.tree){
                this.treeParentNode = this.tree.domNode.parentNode; 
                this.tree.destroyRecursive();
                this.treeParentNode.appendChild(this.treeNode);
                delete this.tree;
                delete this.model;
                this.model = this._initModel();
                return this.model;
            }
        }
    });
});