/**
 * 配信設定一覧グリッド(招集メール配信ダイアログ用)。
 * @module app/convocation/view/mail/ConvoSendAutoMailGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid,
        /** @lends module:idis/view/page/ConvoSendAutoMailGrid~ConvoSendAutoMailGrid# */ {

        /**
         * 各列の定義
         * @type {Object[]}
         */
        collection: this.store,
        columns: [
            helper.buttonColumn('insert', '挿入'),
            helper.column('settingName', '配信設定名')
        ]
    });
});
