/**
 * UI系定数定義。
 * 恐らくほとんど使われていない。
 */
define({
    draw: {
        toolbar: {
            buttons: {
                freehandPolyline: 'Draw a polyline by freehand',
                freehandPolygon: 'Draw a polygon by freehand'
            }
        },
        handlers: {
            freehandPolyline: {
                tooltip: {
                    start: 'Click to start drawing line.',
                    end: 'Release mouse to finish drawing'
                }
            },
            freehandPolygon: {
                error: '<strong>Error:</strong> shape edges cannot cross!',
                tooltip: {
                    start: 'Click to start drawing shape.',
                    cont: 'Continue drawing shape.',
                    end: 'Release mouse to finish drawing.'
                }
            }
        }
    },
    edit: {
        toolbar: {
            buttons: {
                removeAll: 'Delete All layers.',
                removeAllDisabled: 'No layers to delete.',
                measure: 'Measure layers.',
                measureDisabled: 'No layers to measure.'
            }
        },
        handlers: {
            measure: {
                tooltip: {
                    text: 'Click on a feasure to measure'
                }
            }
        }
    },
    measure: {
        toolbar: {
            actions: {
                finish: {
                    title: 'Finish to measure',
                    text: 'Finish'
                }
            },
            buttons: {
                measure: 'Measure layers.',
                measureDisabled: 'No layers to measure.',
                distance: 'Measure distance',
                area: 'Measure area'
            }
        },
        handlers: {
            measure: {
                tooltip: {
                    text: 'Click on a feasure to measure',
                    subtext: {
                        polyline: '距離： {distance}',
                        polygon: '面積： {area}',
                        circle: '半径： {radius}    面積： {area}'
                    }
                }
            },
            distance: {
                tooltip: {
                    start: 'Click to start measuring distance.',
                    cont: 'Click to continue measuring distance.',
                    end: 'Click last point to finish measuring distance.',
                    finished: 'Click to restart measuring distance.'
                }
            },
            area: {
                tooltip: {
                    start: 'Click to start measuring area.',
                    cont: 'Click to continue measuring area.',
                    end: 'Click last point to finish measuring area.',
                    finished: 'Click to restart measuring area.'
                }
            }
        }
    },
    history: {
        toolbar: {
            buttons: {
                undo: 'Undo',
                redo: 'Redo'
            }
        }
    }
});

