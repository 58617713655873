/**
 * メールテンプレート種別情報を扱うモデル。
 * @module app/model/NotifyType
 */
define([
  'idis/store/CacheStoreModel',
  'idis/store/CacheRest'
], function(CacheStoreModel, CacheRest) {
  /**
   * シングルトンを返す。
   */
    return new CacheRest({
        idProperty: 'itemNo',
        target: '/api/broadnotify/notifyType'
    });
});

