define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'idis/util/DateUtils',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'app/common/consts/DeliveredStatusFormatMap',
    'app/view/grid/CommonColumn',
    './EvacOrderColumn'
], function (module, declare, lang, domClass, DateUtils, IdisGrid, helper,
        deliveredStatus, CommonColumn, EvacOrderColumn) {
            
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            // ボタン
            helper.buttonColumn('detail', '詳細'),
            helper.column('deliveredStatus', '配信状況', {
                formatMap: deliveredStatus,
                className: function(item) {
                    // ヘッダーor未配信でない場合はclassNameを返さない
                    if (!item || item.deliveredStatus!=='1') {
                        return;
                    }
                    return 'is-undelivered';
                }
            }),
            CommonColumn.municipalityCd,
            EvacOrderColumn.alertLevel,
            EvacOrderColumn.evacOrderType,
            EvacOrderColumn.evacOrderTimestamp,
            EvacOrderColumn.issueReasonType,
            helper.column('evacNum', '対象', {
                children: [
                    helper.column('evacHouseholdNum', '世帯数', { sortable: false }),
                    helper.column('evaqueeNum', '人数', { sortable: false })
                ],
                sortable: false
            }),
            // 訂正・取消はそれぞれのフラグからサーバー側で判断して返しているため、
            // dstoreのGridでは単純にリクエストできないためソート対象から外す。
            helper.column('correctCancelStatus', '訂正・取消', { sortable: false }),
            // 更新日時
            EvacOrderColumn.updTimestamp,
            // 公開日時
            EvacOrderColumn.releaseTimestamp
        ],

        // DOMを構築する
        buildRendering: function () {
            this.inherited(arguments);
            domClass.add(this.domNode, 'evacorder-EvacOrderGrid');
        }
    });
});
