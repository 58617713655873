/**
 * 気象情報リンク集画面用のモジュール
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/text!./templates/WeatherLinkCollectionPage.html',
    'idis/view/page/_PageBase'
], function(module, array, declare, template, _PageBase) {

    return declare(module.id.replace(/\//g, '.'), _PageBase, {

        // 基本クラス
        baseClass: 'idis-Page idis-Page--link',

        // テンプレート文字列
        templateString: template,

        /**
         * 表示対象のリンク一覧
         */
        linkList: [{
            name: '気象情報',
            children: [
                {name: '気象警報・注意報(札幌市)', 
                href: 'https://www.jma.go.jp/bosai/warning/#area_type=class20s&area_code=0110000&lang=ja'},
                //{name: '土砂災害警戒情報(石狩・空知・後志地方)', href: 'http://www.jma.go.jp/jp/dosha/306_index.html'},
                {name: '気象情報(石狩・空知・後志地方)', 
                href: 'https://www.jma.go.jp/bosai/information' + 
                '/#area_type=offices&area_code=016000&format=table&japan_page=0'},
                //{name: '早期注意情報(石狩・空知・後志地方)', href: 'https://www.jma.go.jp/jma/kishou/keika/306.html'},
                {name: '指定河川洪水予報(北海道)', 
                href: 'https://www.jma.go.jp/bosai/flood/#area_type=class20s&area_code=0110000'},
                {name: '台風情報', href: 'http://www.jma.go.jp/jp/typh/'},
                {name: '竜巻注意報(石狩・空知・後志地方)', href: 'http://www.jma.go.jp/jp/tatsumaki/306.html'},
                {name: 'レーダー・ナウキャスト(降水・雷・竜巻)', href: 'http://www.jma.go.jp/jp/radnowc/index.html?areaCode=203'}
            ]
        }, {
            name: '天気予報',
            children: [
                {name: '天気図', href: 'https://www.jma.go.jp/jp/g3/'},
                {name: '季節予報', href: 'https://www.jma.go.jp/jp/longfcst/'},
                {name: '降水予報(石狩・空知・後志地方)', href: 'https://www.jma.go.jp/jp/yoho/306.html'},
                {name: '降水予報(現在地)', href: 'https://www.jma.go.jp/jp/kaikotan/'}
            ]
        }, {
            name: '気象観測',
            children: [
                {name: '気象衛星画像(日本域)', href: 'http://www.jma.go.jp/jp/gms/smallc.html'},
                {name: '道内アメダス(石狩・後志地方)', href: 'https://www.jma.go.jp/jp/amedas_h/map12.html'},
                {name: '広域アメダス(北海道地方(南西部))', href: 'http://www.jma.go.jp/jp/amedas/203.html?elementCode=2'},
                {name: 'アメダス一覧(表形式)', href: 'https://www.jma.go.jp/jp/amedas_h/index.html'}
            ]
        }],

        // DOMノードを構築する
        buildRendering: function() {
            this.inherited(arguments);
            var html = [];
            array.forEach(this.linkList, function(linkGroup) {
                // 各リンクのグループ
                html.push('<section><h2>');
                html.push(linkGroup.name);
                html.push('</h2><div><ul>');
                array.forEach(linkGroup.children, function(item) {
                    // 各リンク要素
                    html.push('<li><a href="');
                    html.push(item.href);
                    html.push('" target="_blank">');
                    html.push(item.name);
                    html.push('</a></li>');
                });
                html.push('</ul></div></section>');
            }, this);
            this.linkContainer.innerHTML = html.join('');
        }
    });
});
