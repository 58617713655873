/**
 * dojo/store/JsonRestのGET系メソッドでブラウザーがキャッシュしないようにする。
 * @module idis/store/CachelessJsonRest
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/xhr',
    'dojo/store/JsonRest',
    'dojo/store/util/QueryResults'
], function(module, declare, lang, xhr, JsonRest, QueryResults) {
    /**
     * dojo/store/JsonRestのGET系メソッドでブラウザーがキャッシュしないようにしたもの。
     *
     * @class CachelessJsonRest
     * @extends module:dojo/store/JsonRest
     */
    return declare(JsonRest, /** @lends module:idis/store/CachelessJsonRest~CachelessJsonRest# */ {
        // dojo/store/JsonRest#getでブラウザー・キャッシュしないようにする
        get: function(id, options) {
            // インデント以外は出来るだけ変更しない
            /* jshint quotmark: false */
            options = options || {};
            var headers = lang.mixin({ Accept: this.accepts }, this.headers, options.headers || options);
            return xhr("GET", {
                url: this._getTarget(id),
                handleAs: "json",
                preventCache: true, // ここだけ変更
                headers: headers
            });
        },

        // dojo/store/JsonRest#queryでブラウザー・キャッシュしないようにする
        query: function(query, options){
            // インデント以外は出来るだけ変更しない
            /* jshint quotmark: false, eqeqeq: false */
            options = options || {};

            var headers = lang.mixin({ Accept: this.accepts }, this.headers, options.headers);

            var hasQuestionMark = this.target.indexOf("?") > -1;
            if(query && typeof query == "object"){
                query = xhr.objectToQuery(query);
                query = query ? (hasQuestionMark ? "&" : "?") + query: "";
            }
            if(options.start >= 0 || options.count >= 0){
                headers["X-Range"] = "items=" + (options.start || '0') + '-' +
                    (("count" in options && options.count != Infinity) ?
                     (options.count + (options.start || 0) - 1) : '');
                     if(this.rangeParam){
                         query += (query || hasQuestionMark ? "&" : "?") + this.rangeParam + "=" + headers["X-Range"];
                         hasQuestionMark = true;
                     }else{
                         headers.Range = headers["X-Range"];
                     }
            }
            if(options && options.sort){
                var sortParam = this.sortParam;
                query += (query || hasQuestionMark ? "&" : "?") + (sortParam ? sortParam + '=' : "sort(");
                /* jshint ignore:start */
                for(var i = 0; i<options.sort.length; i++){
                    var sort = options.sort[i];
                    query += (i > 0 ? "," : "") + (sort.descending ? this.descendingPrefix : this.ascendingPrefix) + encodeURIComponent(sort.attribute);
                }
                /* jshint ignore:end */
                if(!sortParam){
                    query += ")";
                }
            }
            var results = xhr("GET", {
                url: this.target + (query || ""),
                handleAs: "json",
                preventCache: true, // ここだけ変更
                headers: headers
            });
            results.total = results.then(function(){
                var range = results.ioArgs.xhr.getResponseHeader("Content-Range");
                if (!range){
                    // At least Chrome drops the Content-Range header from cached replies.
                    range = results.ioArgs.xhr.getResponseHeader("X-Content-Range");
                }
                return range && (range = range.match(/\/(.*)/)) && +range[1];
            });
            return QueryResults(results);
        }
    });
});

