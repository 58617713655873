define([
    'leaflet'
], function(leaflet) {
    return {
        _initIcon: function () {
            var options = this.options,
                map = this._map,
                animation = (map.options.zoomAnimation && map.options.markerZoomAnimation),
                classToAdd = animation ? 'leaflet-zoom-animated' : 'leaflet-zoom-hide';

            // for resizeHandle
            if (this.options.icon.options.drawType === 'DivIcon' && this.options.icon.options.height) {
                    this.options.height = this.options.icon.options.height;
                    this.options.width  = this.options.icon.options.width;
            }

            var icon = options.icon.createIcon(this._icon),
                addIcon = false;

            // if we're not reusing the icon, remove the old one and init new one
            if (icon !== this._icon) {
                if (this._icon) {
                    this._removeIcon();
                }
                addIcon = true;

                if (options.title) {
                    icon.title = options.title;
                }

                if (options.alt) {
                    icon.alt = options.alt;
                }
            }

            leaflet.DomUtil.addClass(icon, classToAdd);

            if (options.keyboard) {
                icon.tabIndex = '0';
            }

            this._icon = icon;

            this._initInteraction();

            if (options.riseOnHover) {
                leaflet.DomEvent
                    .on(icon, 'mouseover', this._bringToFront, this)
                    .on(icon, 'mouseout', this._resetZIndex, this);
            }

            var newShadow = options.icon.createShadow(this._shadow),
                addShadow = false;

            if (newShadow !== this._shadow) {
                this._removeShadow();
                addShadow = true;
            }

            if (newShadow) {
                leaflet.DomUtil.addClass(newShadow, classToAdd);
            }
            this._shadow = newShadow;


            if (options.opacity < 1) {
                this._updateOpacity();
            }


            var panes = this._map._panes;

            if (addIcon) {
                panes.markerPane.appendChild(this._icon);
            }

            if (newShadow && addShadow) {
                panes.shadowPane.appendChild(this._shadow);
            }
        }
    };
});
