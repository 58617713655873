/**
 * 災害シミュレーション新規登録ダイアログ
 * @module app/simulation/SimulationEventRegisterDialog
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/text!./templates/SimulationEventRegisterDialog.html', // テンプレート文字列
    './_SimulationEventPageBase', // 共通基底クラス
    'idis/view/dialog/DialogChain',
    'idis/control/Router',
    'idis/util/FilesUtils',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'dijit/form/RadioButton',
    'dijit/form/Form',
    'idis/view/form/WordCountTextarea'
], function(module, declare, template, _SimulationEventPageBase, DialogChain, Router) {
    /**
     * 災害シミュレーション新規登録ダイアログ
     * @class SimulationEventRegisterDialog
     * @extends module:app/simulation/_SimulationEventPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _SimulationEventPageBase,
        /** @lends module:app/simulation/SimulationEventRegisterDialog~SimulationEventRegisterDialog# */ {

            // テンプレート文字列
            templateString: template,

            baseClass: 'idis-Page idis-Page--simulation',

            _eventId: null,
            _scenarioId: null,

        constructor: function(){
            this.inherited(arguments);
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },

        initPage: function(scenarioId){
            this._scenarioId = scenarioId;
        },

        onDetailButtonClick : function() {
            // 入力チェック
            if (!this._validation()) { return false; }
            var eventType = this.eventType.get('value');
            // 該当するイベント種別の登録画面へ遷移する
            var eventUrl = this.EVENT_URL_BASE[eventType] + 'register';
            this.getParent().hide();
            // 各種イベント登録画面へ遷移
            Router.moveTo(eventUrl, {
                simulateFlg: true,
                scenarioId: this._scenarioId,
                eventType: eventType,
                content: this.content.get('value'),
                timing: this._generateTimingToSec()});

        }
    });
});
