define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, lang, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--broadnotify',

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            // ボタン
            helper.buttonColumn('detail', '詳細'),
            { field: 'organizationName', label: '所属', sortable: true },
            { field: 'terminalName', label: 'ユーザ名', sortable: true }
        ]
    });
});
