/**
 * 危険度分布情報パネル
 * @module app/monitor/DangerPanel
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/dom-construct',
    'dojo/dom-style',
    'dojo/json',
    'dojo/text!./templates/DangerPanel.html',
    'dojo/topic',
    'dojo',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/view/page/_PageBase',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane'
], function(module, array, declare, lang, locale, domConstruct, domStyle, JSON, template, topic,
    dojo, Router, UserInfo, Requester, DialogChain, Loader, _PageBase) {
    /**
     * 危険度分布情報パネル
     * @class DamagePanel
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/monitor/DangerPanel~DangerPanel# */ {
        // テンプレート文字列
        templateString: template,

        /**
         * 最大表示件数
         */
        MAX_COUNT: 5,

        /**
         * 市町村コード
         */
        _municipalityCd: null,

        /**
         * constructor
         */
        constructor: function() {
            this.chain = DialogChain.get(this);
            this._municipalityCd = UserInfo.getSelectedMunicipalityCd();
            console.debug('危険度分布情報表示対象の市町村コード：' + this._municipalityCd);
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
            this.initTable();

            // 市町村切替時に被害情報を更新
            this.own(topic.subscribe('idis/view/form/MunicipalitySelectForm::selected',
                lang.hitch(this, function(payload) {
                    this._municipalityCd = payload.municipalityCd;
                    this.initTable();
            })));

            // 災害切替時に被害情報を更新
            this.own(topic.subscribe('app/view/form/DisasterChanger::updated',
                lang.hitch(this, function() {
                    this.initTable();
            })));
        },


        /**
         * テーブルを初期化します
         */
        initTable: function() {
            // 危険度分布情報を設定
            this.setDangerInfo();
        },

        /**
         * 危険度分布情報を設定します
         */
        setDangerInfo: function() {
            var self = this;

            var promise = Requester.get('/data/danger/summary/' + this._municipalityCd + '.json', {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(function(item) {
                console.debug('危険度分布情報（' + self._municipalityCd + '）：' +
                    JSON.stringify(item));

                // 大雨警報（土砂災害）を設定
                self.setRainSedimentAlerm(self, item);

                // 大雨警報（浸水害）を設定
                // self.rainFloodAlerm(self, item);

                // 洪水警報を設定
                // self.floodAlerm(self, item);

            }, function(error) {
                if (error.response.status === 404) {
                    console.log('危険度分布情報が見つかりません。初期値をセットします。' + (error.message ? ('('+error.message+')') : ''));
                    var item = {
                        'rainSedimentAlermFlg' : false,
                        'rainFloodAlermFlg' : false,
                        'floodAlermFlg' : false
                    };
                    self.setRainSedimentAlerm(self, item);
                    // self.rainFloodAlerm(self, item);
                    // self.floodAlerm(self, item);
                } else {
                    self.chain.info('情報の取得に失敗しました。', 'エラー');
                }
            });
            //ローダーの表示
            Loader.wait(promise);
        },

        /**
         * 大雨警報（土砂災害）を設定します
         */
        setRainSedimentAlerm: function(self, item) {
            if(self.rainSedimentAlerm) {
                // 画面遷移していない場合のみ注入
                var html = ['<span>'];
                if(!item.rainSedimentAlermFlg) {
                    domStyle.set(self.rainSedimentAlerm, 'backgroundColor', 'white');
                    domStyle.set(self.rainSedimentAlerm, 'color', 'black');
                    html.push('なし');
                } else {
                    var backgroundColor = 'white';
                    var color = 'black';
                    // if(item.specialWarningFlg) {
                    //     backgroundColor = '#C800FF';
                    //     color = 'white';
                    // } else if(item.warningFlg) {
                    //     backgroundColor = '#FF2800';
                    //     color = 'white';
                    // } else if(item.advisoryFlg) {
                    //     backgroundColor = '#FAF500';
                    // }
                    domStyle.set(self.rainSedimentAlerm, 'backgroundColor', backgroundColor);
                    domStyle.set(self.rainSedimentAlerm, 'color', color);
                    // if(item.specialWarningFlg) {
                    //     html.push('特別警報発表');
                    // } else if(item.warningFlg) {
                    //     html.push('警報発表');
                    // } else {
                        html.push('注意報発表');
                    // }
                }
                html.push('</span>');
                self.rainSedimentAlerm.innerHTML = html.join('');
            }
        },

        onRainSedimentAlarmLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // 土砂災害危険度情報一覧画面へ遷移
            Router.moveTo('sedimentriskobservation');
        },

        onRainFloodAlarmLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // 気象情報一覧画面へ遷移
            Router.moveTo('weatherinfo');
        },

        onFloodAlarmLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // 気象情報一覧画面へ遷移
            Router.moveTo('weatherinfo');
        }

    });
});
