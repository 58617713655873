define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/dom-class',
    'dojox/lang/functional/object',
    'idis/view/form/RadioGroup',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/IdisSelector'
], function(module, declare, lang, locale, domClass, df, RadioGroup, IdisGrid, IdisSelector) {
    return declare(module.id.replace(/\//g, '.'), [IdisGrid, IdisSelector], {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--chat',

        radioButtons: {},

        // 登録済みユーザであるかを保持する
        registered: {},

        rowClassName: function(item) {
            return item && (item.registeredFlg) ? 'gray-row' : '';
        },

        // 未登録のユーザのみを登録可能としている
        renderRow: function(item) {
            var div = this.inherited(arguments);
            // デフォルトではチェックボックスを非活性に
            this.registered[item.userId] = true; // 登録済みユーザとしてセット
			var inputtags = div.getElementsByTagName('input');
			for (var j = 0; j < inputtags.length; j++) {
		        inputtags[j].disabled = true;
		    }
            // 未登録のユーザである場合、チェックボックスを活性に
            if (!item.registeredFlg) {
                this.registered[item.userId] = false; // 未登録ユーザに変更する
                for (var i = 0; i < inputtags.length; i++) {
                    inputtags[i].disabled = false;
                }
            }

            // rowClassNameが指定されている場合
            if (this.rowClassName) {
                var className = this.rowClassName;
                // 関数の場合は行データを与えて実行する
                if (lang.isFunction(className)) {
                    className = className(item);
                }
                // 偽値でなければCSSクラスとして追加
                if (className) {
                    domClass.add(div, className);
                }
            }
            return div;
        },

        constructor: function () {
            /**
             * 各列の定義
             * @type {Object[]}
             */
            this.columns = [
                {
                    field: 'checkbox',
                    label: '選択',
                    selector: 'checkbox'
                },
                { field: 'userId', label: 'ユーザID', sortable: true },
                { field: 'municipalityName', label: '所属', sortable: true },
                { field: 'organizationName', label: '組織', sortable: true },
                { field: 'userName', label: 'ユーザ名', sortable: true },
                // 検索時にM_USERのメールアドレスでソートするため、M_USERの項目名に合わせる
                { field: 'officialMailAddress', label: 'メールアドレス', sortable: true },
                {
                    field: 'sendRoleType', label: '投稿可否', sortable: false,
                    renderCell: lang.hitch(this, function (item) {
                        this.radioButtons[item.userId] = new RadioGroup({
                            name:'sendRoleType_' + item.userId, 
                            options:[{label:'不可', value:'0'}, {label:'可', value:'1'}]
                        });
                        this.radioButtons[item.userId].set('value', item.sendRoleType);
                        // HTMLとしてウィジェットに紐付くDOMノードを返す
                        return this.radioButtons[item.userId].domNode;
                    })
                }
            ];

            this.inherited(arguments);
        },

        reset: function () {
            this.inherited(arguments);

            this.radioButtons = {};
        },

        /**
         * 選択したユーザのID一覧を返す。
         * @returns {string[]} 選択したユーザのID一覧
         */
        getSelectedIdList: function() {
            var self = this;
            // 値がtrueのものに絞り込む（一度選択したものはfalseが入る）
            var selected = df.filterIn(this.selection, function(v, userId) {
                // チェックボックスが選択されている and 未登録ユーザであるものを返す
                // ヘッダ行のチェックボックスを選択した場合、チェックボックスが非活性でも値がtrueとなるため
                return v && !self.registered[userId];
            });
            // 残ったキー一覧を返す
            return df.keys(selected);
        }

    });
});
