/**
 * locationに含まれるアプリ固有のパラメーター種別を定数として定義
 * @module app/consts/APP_QUERY
 */
define({
    // 観測情報で利用する時間モード
    MODE: 'mode',
    // 観測情報（潮位）で利用するデータモード
    DATA_MODE: 'dataMode',
    // 観測所Id
    OBSERVATORY_ID: 'observatoryId',
    // 観測日時
    DATETIME: 'datetime',
    // 地域CD
    REGION_CD: 'regionCd',
    // 市町村CD
    MUNICIPALITY_CD: 'municipalityCd'
});
