define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, lang, locale, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--sending',

        /**
         * 各列の定義
         * @type {Object[]}
         */

        columns: [
            // ボタン
            helper.buttonColumn('detail', '詳細'),
            helper.column('infoType', '情報種別'),
            helper.column('subject', '件名', {sortable: false}),
            helper.column('bodyText', '本文', {sortable: false}),
            {label: '配信状況',field: 'sendingStatus', sortable: false,
                children: [
                    helper.column('emergencyMailStatus', '緊急速報メール', {
                        formatter: function(value) {return value ? value : '-';}, sortable: false}),
                    helper.column('lalertStatus', 'Lアラート', {
                        formatter: function(value) {return value ? value : '-';}, sortable: false}),
                    helper.column('twitterStatus', 'SNS\n(Twitter)', {
                        formatter: function(value) {return value ? value : '-';}, sortable: false}),
                    helper.column('yahooStatus', '防災アプリ\n(Yahoo)', {
                        formatter: function(value) {return value ? value : '-';}, sortable: false})
                ]
            },
            helper.column('sendOrganizationName', '配信課', {sortable: false}),
            {field: 'sendTimestamp', label: '配信日時', sortable: true,
                formatter: lang.hitch(this, function(item){
                    if(item) {
                        return locale.format(new Date(item));
                    }
                    return '';
                })
            },
            helper.column('senderName', '登録者', {sortable: false}),
            {field: 'crtTimestamp', label: '登録日時', sortable: true,
                formatter: lang.hitch(this, function(item){
                    if(item) {
                        return locale.format(new Date(item));
                    }
                    return '';
                })
            }
        ]
    }
    );
});
