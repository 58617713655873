/**
 * 画面の基底となるモジュール。
 * @module idis/view/page/_PageBase
 */
define([
    'module',
    'dojo/_base/declare',
    '../_IdisWidgetBase'
], function(module, declare, _IdisWidgetBase) {
    /**
     * 画面ウィジェットの基底クラス。
     * @class _PageBase
     * @extends module:idis/view/_IdisWidgetBase~_IdisWidgetBase
     */
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase,
        /** @lends module:idis/view/page/_PageBase~_PageBase# */ {
        // ウィジェットのルート要素に付与されるCSSクラス名
        baseClass: 'idis-Page',

        /**
         * 現在の画面を抜けるときに呼び出すメソッド。
         * 親ウィジェットがdijit/Dialogなどhideメソッドを持つウィジェットの場合はそのhideメソッドを呼び出す。
         * それ以外の場合は履歴を戻る。
         */
        leave: function() {
            var parent = this.getParent();
            if (parent && parent.hide) {
                parent.hide();
            } else {
                history.back();
            }
        }
    });
});
