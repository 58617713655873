/**
 * 避難情報関連のUtility
 * @module app/evacorder/EvacOrderUtil
 */
define([
    'module',
    './EvacOrderType',
    './EvacOrderConfig'
], function(module, EvacOrderType, EvacOrderConfig) {

    function _isReleased(evacOrderTypeCd){
        return EvacOrderConfig.RELEASE_CD_LIST.indexOf(evacOrderTypeCd) > -1;
    }

    /**
     * 発令理由コード・発令内容コードを引数に、を警戒レベル情報返す。
     * @param {string} value
     * @returns {int} 受け取った文字列から警戒レベルオブジェクトを返す
     */
    function _getAlertLevel(issueReasonType, evacOrderType) {
        if(EvacOrderConfig.ALERT_LEVEL_ISSUE.indexOf(issueReasonType) === -1){
            // 警戒レベル付与対象の種別一覧に【含まれない】場合は、警戒レベルを付与しない
            return null;
        }
        if(_isReleased(evacOrderType)){
            // 解除対象の地区には、警戒レベルを付与しない
            return null;
        }
        return EvacOrderConfig.ALERT_LEVEL_MAP_CD[evacOrderType];
    }

    return {
        getAlertLevel: _getAlertLevel,
        isReleased: _isReleased
    };
});
