define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'dijit/form/TextBox'
], function(module, declare, lang, IdisGrid, helper, TextBox) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            {field: 'photo', label: '写真',
                formatter: function(value, item){
                    return '<img style="height:80px;" src=' + item.photo + '>' ;
                }
            },
            helper.column('photoFileName', 'ファイル名'),
            helper.column('photoTimestamp', '撮影日時'),
            {field: 'comment', label: 'コメント',editable: true,
                renderCell: lang.hitch(this, function(item,value,node){
                    var textbox = new TextBox({
                        value: value,
                        onChange: function() {
                            item.comment = this.displayedValue;
                        }
                    });
                    textbox.placeAt(node);
                })
            },
            helper.buttonColumn('delete', '削除')
        ]
    });
});
