/**
 * 地域選択用入力パーツ
 * @module app/view/form/RegionSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/form/TreeSelector',
    'app/model/Region'
], function(module, declare, TreeSelector, Region) {
    /**
     * 地域選択用パーツ。
     * @class DistrictSelector
     * @extends module:app/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:app/view/tree/RegionSelector~RegionSelector# */ {
        // 選択ダイアログのタイトル
        title: '地域選択',

        // ツリー・モデル
        model: Region
    });
});
