define([
    'leaflet',
    './Area',
    './Distance',
    './measureObj'
], function(leaflet, Area, Distance, measureObj) {
    var MeasureToolbar = leaflet.MeasureToolbar = leaflet.Toolbar.extend({
        statics: {
            TYPE: 'measure'
        },

        options: {
            measure: {},
            featureGroup: null /* REQUIRED! TODO: perhaps if not set then all layers on the map are selectable? */
        },

        initialize: function (options) {
            if (options.measure) {
                options.measure = leaflet.extend({}, this.options.measure, options.measure);
            }

            this._toolbarClass = 'leaflet-draw-measure';
            leaflet.Toolbar.prototype.initialize.call(this, options);

            this._selectedFeatureCount = 0;
        },

        getModeHandlers: function (map) {
            var featureGroup = this.options.featureGroup;
            return [
                {
                    enabled: this.options.measure,
                    handler: new leaflet.MeasureToolbar.Measure(map, {
                        featureGroup: featureGroup
                    }),
                    title: leaflet.drawLocalExtend.measure.toolbar.buttons.measure
                },
                {
                    enabled: this.options.measure,
                    handler: new Distance(map, {}),
                    title: leaflet.drawLocalExtend.measure.toolbar.buttons.distance
                },
                {
                    enabled: this.options.measure,
                    handler: new Area(map, {
                    }),
                    title: leaflet.drawLocalExtend.measure.toolbar.buttons.area
                }


            ];
        },

        getActions: function () {
            return [
                {
                    title: leaflet.drawLocalExtend.measure.toolbar.actions.finish.title,
                    text: leaflet.drawLocalExtend.measure.toolbar.actions.finish.text,
                    callback: this.disable,
                    context: this
                }
            ];
        },

        addToolbar: function (map) {
            var container = leaflet.Toolbar.prototype.addToolbar.call(this, map);

            this._checkDisabled();

            this.options.featureGroup.on('layeradd layerremove', this._checkDisabled, this);

            return container;
        },

        removeToolbar: function () {
            this.options.featureGroup.off('layeradd layerremove', this._checkDisabled, this);

            leaflet.Toolbar.prototype.removeToolbar.call(this);
        },

        disable: function () {
            if (!this.enabled()) { return; }

            this._activeMode.handler.revertLayers();

            leaflet.Toolbar.prototype.disable.call(this);
        },

        _checkDisabled: function () {
            var featureGroup = this.options.featureGroup,
                hasLayers = featureGroup.getLayers().length !== 0,
                    button;

                    if (this.options.measure) {
                        button = this._modes[leaflet.MeasureToolbar.Measure.TYPE].button;

                        if (hasLayers) {
                            leaflet.DomUtil.removeClass(button, 'leaflet-disabled');
                        } else {
                            leaflet.DomUtil.addClass(button, 'leaflet-disabled');
                        }

                        button.setAttribute(
                            'title',
                            hasLayers ?
                                leaflet.drawLocalExtend.measure.toolbar.buttons.measure
                                    : leaflet.drawLocalExtend.measure.toolbar.buttons.measureDisabled
                        );
                    }

        }
    });

    MeasureToolbar.Measure = leaflet.Handler.extend({
        statics: {
            TYPE: 'measure'
        },

        includes: leaflet.Mixin.Events,

        initialize: function (map, options) {
            leaflet.Handler.prototype.initialize.call(this, map);

            leaflet.Util.setOptions(this, options);

            // Store the selectable layer group for ease of access
            this._targetLayers = this.options.featureGroup;

            if (!(this._targetLayers instanceof leaflet.FeatureGroup)) {
                throw new Error('options.featureGroup must be a leaflet.FeatureGroup');
            }

            // Save the type so super can fire, need to do this as cannot do this.TYPE :(
            this.type = leaflet.MeasureToolbar.Measure.TYPE;
            this.setObj(this);
        },

        setObj: function (obj) {
            measureObj[this.constructor.TYPE] = obj;
        },

        getObj: function (type) {
            return measureObj[type];
        },

        enable: function () {
            if (this.getObj(this.constructor.TYPE)._enabled ||
                !this.getObj(this.constructor.TYPE)._hasAvailableLayers()) {
                    return;
                }
                this.getObj(this.constructor.TYPE).fire('enabled', {
                    handler: this.getObj(this.constructor.TYPE).type});

                    this.getObj(this.constructor.TYPE)._map.fire('draw:measurestart', {
                        handler: this.getObj(this.constructor.TYPE).type });

                        leaflet.Handler.prototype.enable.call(this.getObj(this.constructor.TYPE));

        },

        disable: function () {
            if (!this.getObj(this.constructor.TYPE)._enabled) { return; }

            leaflet.Handler.prototype.disable.call(this.getObj(this.constructor.TYPE));

            this.getObj(this.constructor.TYPE)._map.fire('draw:measurestop', {
                handler: this.getObj(this.constructor.TYPE).type });

                this.getObj(this.constructor.TYPE).fire('disabled', {
                    handler: this.getObj(this.constructor.TYPE).type});
        },

        addHooks: function () {
            var map = this._map;

            if (map) {
                map.getContainer().focus();

                this._targetLayers.eachLayer(this._enableLayerClickable, this);

                this._tooltip = new leaflet.Tooltip(this._map);
                this._tooltip.updateContent({
                    text: leaflet.drawLocalExtend.measure.handlers.measure.tooltip.text });
                    // カーソル側のコメントを非表示に
                    this._tooltip.dispose();

                    this._map.on('mousemove', this._onMouseMove, this);
            }
        },

        removeHooks: function () {
            if (this._map) {
                this._targetLayers.eachLayer(this._disableLayerClickable, this);

                this._tooltip.dispose();
                this._tooltip = null;

                this._map.off('mousemove', this._onMouseMove, this);
            }
        },

        revertLayers: function () {
            // Iterate of the deleted layers and add them back into the featureGroup
            //this._deletedLayers.eachLayer(function (layer) {
            //	this._deletableLayers.addLayer(layer);
            //}, this);
        },

        save: function () {
            //this._map.fire('draw:deleted', { layers: this._deletedLayers });
        },

        _enableLayerClickable: function (e) {
            var layer = e.layer || e.target || e;

            layer.on('click', this._measureLayer, this);
        },

        _disableLayerClickable: function (e) {
            var layer = e.layer || e.target || e;

            layer.off('click', this._measureLayer, this);

        },

        _measureLayer: function (e) {
            var layer = e.layer || e.target || e;

            // ポイント、付箋の場合は、計測しない
            if( layer instanceof leaflet.Marker) {
                this._tooltip.updateContent({
                    text: leaflet.drawLocalExtend.measure.handlers.measure.tooltip.text, subtext: '' });
                    return;
            }

            var subtext = '';
            var result = [];
            var area;
            var resultArea;
            if (layer._mRadius) {
                var radius = layer._mRadius;
                area = radius * radius * Math.PI;
                var resultRadius = leaflet.GeometryUtil.readableDistance(radius, true);
                resultArea = leaflet.GeometryUtil.readableArea(area, true);
                result.push(leaflet.drawLocalExtend.measure.handlers.measure.tooltip.subtext.circle
                            .replace('{radius}', resultRadius[0])
                            .replace('{area}', resultArea[0]));
                            result.push(leaflet.drawLocalExtend.measure.handlers.measure.tooltip.subtext.circle
                                        .replace('{radius}', resultRadius[1])
                                        .replace('{area}', resultArea[1]));
            } else {
                var latlngs = layer.getLatLngs();
                if (layer.options.fill === true) {
                    area = leaflet.GeometryUtil.geodesicArea(latlngs);
                    resultArea = leaflet.GeometryUtil.readableArea(area, true);
                    result.push(leaflet.drawLocalExtend.measure.handlers.measure.tooltip.subtext.polygon
                                .replace('{area}', resultArea[0]));
                                result.push(leaflet.drawLocalExtend.measure.handlers.measure.tooltip.subtext.polygon
                                            .replace('{area}', resultArea[1]));
                } else {
                    var distance = 0;
                    var startPos = latlngs[0];
                    for (var i = 1; i < latlngs.length; i++) {
                        var endPos = latlngs[i];
                        distance += startPos.distanceTo(endPos);
                        startPos = endPos;
                    }
                    var resultDistance = leaflet.GeometryUtil.readableDistance(distance, true);
                    result.push(leaflet.drawLocalExtend.measure.handlers.measure.tooltip.subtext.polyline
                                .replace('{distance}', resultDistance[0]));
                                result.push(leaflet.drawLocalExtend.measure.handlers.measure.tooltip.subtext.polyline
                                            .replace('{distance}', resultDistance[1]));
                }
            }
            //d14SetMeasureResult(result);
            this._tooltip.updateContent({
                text: leaflet.drawLocalExtend.measure.handlers.measure.tooltip.text, subtext: subtext });
        },

        _onMouseMove: function (e) {
            this._tooltip.updatePosition(e.latlng);
        },

        _hasAvailableLayers: function () {
            return this._targetLayers.getLayers().length !== 0;
        }
    });
});

