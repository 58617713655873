define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/DrawSelectIconDialog.html',
    'idis/view/_IdisWidgetBase',
    'dijit/Dialog',
    'dojo/_base/lang',
    'dojo/on',
    'dojo/request/xhr',
    'dojo/query',
    'dojo/dom-construct',
    'dojo/dom-class',
    'idis/view/draw/iconData.json',
    //
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/TooltipDialog',
    'dijit/form/DropDownButton',
    'idis/view/form/Button'
], function(module, declare, template, _IdisWidgetBase, Dialog, lang, on, xhr, query, domCon, domClass, iconData) {

    var content = declare('_DrawSelectIconDialogContent', _IdisWidgetBase, {
        templateString : template,
        baseClass: 'drawDialog-Container',
        widgetsInTemplate : true
    });

    var container = declare(module.id.replace(/\//g, '.'), [Dialog], {

        constructor : function(options){
            lang.mixin(this, options);
            this.title = 'アイコンの選択';
            this.inherited(arguments);
            this.inner = new content();
        },

        postCreate : function(){
            this.inherited(arguments);
            this.set('content', this.inner);
            this._setIcons();
            on(this.inner.iconSelectDone, 'click', lang.hitch(this, function(){
                this.hide();
            }));
            on(this.inner.iconSelectCancel, 'click', lang.hitch(this, function(){
                this.hide();
            }));
        },

        // Override
        show : function(){
            this.inherited(arguments);
        },

        // アイコンデータを読み込み、表示する
        _setIcons: function(){
            var icons = iconData.items;
            icons.forEach(lang.hitch(this, function(iconObj){
                var url = '../../../images/draw/icon/' + iconObj.url;
                var iconImage = '<img src=\"'+url+'\" class=\"iconData\" name=\"'+iconObj.url+'\">';
                this.inner.iconShowArea.appendChild(domCon.toDom(iconImage));
            }));

            this.own(
                //アイコンの一つ一つにイベントを付与して、クリックされたらCSSをつける
                on(this.inner.iconShowArea, 'click', lang.hitch(this, function(e){
                    if(e.target !== this.selectedIcon){
                        if(!!this.selectedIcon){
                            domClass.toggle(this.selectedIcon, 'iconSelected', false);
                        }
                        domClass.toggle(e.target, 'iconSelected', true);
                        this.selectedIcon = e.target;
                    }else{
                        domClass.toggle(e.target, 'iconSelected', false);
                    }
                }))
            );
        }
    });
    return container;
});
