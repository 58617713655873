/**
 * 体制の登録ダイアログ用モジュール。
 * @module app/disasterprevention/view/DisasterPreventionRegisterPage
 */
define([
    'module',
    'app/config',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/DisasterPreventionStatusRegisterPage.html',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    './_DisasterPreventionStatusDialogPageBase',
    // 以下、変数から参照されないモジュール
    'dijit/form/ValidationTextBox',
    'dijit/form/NumberTextBox',
    'app/view/form/CustomizableMunicipalitySelector'
], function(module, config, declare, lang, domStyle, template, UserInfo, USER_TYPE,
    _PageBase) {
    /**
     * 体制情報登録画面。
     * @class DisasterPreventionRegisterPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/disasterprevention/view/DisasterPreventionRegisterPage~DisasterPreventionRegisterPage */ {
        // テンプレート文字列
        templateString: template,

        //市町村名
        muniName : null,

        buildRendering: function() {
            this.inherited(arguments);
        },

        // DOM要素構築後に呼ばれる
        postCreate: function() {
            this._postCreate();
        },

        startup: function() {
            this.setTipsPopups();
        },

        /**
         * TipsPopupをセットする。
         */
        setTipsPopups: function() {
            this.setHqTypeTips('registerHqTypeLabel');
            this.setLevelTips('registerLevelLabel');
        },

        onSubmit: function() {
            try {
                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }
                // 固有のバリデーションを実施
                if(!this.validate()) {
                    return false;
                }

                var value = this.form.get('value');
                // 非表示項目は入力値を削除
                if (domStyle.get(this.hqNameNode, 'display')==='none'){
                    delete value.hqName;
                } else {
                    value.hqName = this.hqName.get('value');
                }
                this.emit('post-send', {value: value});
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * ダイアログを初期化する。（新規登録時）
         */
        initDialog: function(parent) {
            // フォーム初期化
            this.form.reset();
            // 本部体制選択ノード
            // 初期表示時は非表示にする
            domStyle.set(this.hqNameNode, 'display', 'none');
            // 自治体
            this.initMunicipalitySelector(parent.municipalitySelector.get('value'));
            // 本部種別に応じて本部設置情報の表示を切替える
            this._setHqNameNode(this.hqTypeSelect.get('value'));

            this._parent = parent;
        },

        /**
         * 自治体セレクタを初期化
         * @param 一覧で選択中の市町コード
         */
        initMunicipalitySelector: function(selectedAreaCd) {
            // システム管理者以外は、マスタ管理機能においては代理登録権限を持たない
            if (UserInfo.isSysAdmin()) {
                // システム管理者は、一覧で選択中の市町をデフォルトセット
                this.municipalitySelector.set('value', selectedAreaCd);
                return;
            }
            // 所属自治体をセット
            this.municipalitySelector.set('value', UserInfo.getUserType() === USER_TYPE.REGION ?
                UserInfo.getRegionCd() : UserInfo.getMunicipalityCd());
            // 選択ボタン不可
            this.municipalitySelector.set('noAllButton', true);
        }
    });
});

