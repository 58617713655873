/**
 * <モジュールの説明>
 * @module app/employgathering/EmployGatheringFormDialog
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/json',
    'dojo/text!./templates/EmployGatheringFormDialog.html', // テンプレート文字列
    './_EmployGatheringStatusPageBase',
    'idis/model/UserInfo',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    '../config',
    // 以下、変数で受けないモジュール
    'app/view/form/PrefRegionMunicipalitySelector',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'dijit/form/RadioButton',
    'dijit/form/Form'
], function(module, declare, lang, Deferred, JSON, template, _PageBase, UserInfo,
        InfoDialog, DialogChain, Loader, config) {
    /**
     * <クラスの説明>
     * @class EmployGatheringFormDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends app/employgathering/EmployGatheringFormDialog~EmployGatheringFormDialog */ {
        // テンプレート文字列
        templateString: template,


        /**
         * 防災体制ID
         */
        _disasterPreventionId: null,

        /**
         * ダウンロードファイル名
         */
        _fileMappin: [{
                'formType' : 'PREF',
                'reqid' : 'EMP_GATHERING_PREF_LIST_EX',
                'fileName' : 'EmpGatheringPrefList.xlsx',
                'fileNameJp' : '参集状況一覧（県・振興局）.xlsx'
            },{
                'formType' : 'MUNI',
                'reqid' : 'EMP_GATHERING_LIST_EX',
                'fileName' : 'EmpGatheringList.xlsx',
                'fileNameJp' : '参集状況一覧.xlsx'
        }],

        initDialog: function(item){
            var muniCd = item.municipalityCd;
            if (muniCd === config.municInfo.prefMunicCd) {
                // 県の市町村コードは県コードに変換
                muniCd = config.municInfo.prefCd;
            }
            this._disasterPreventionId = item.disasterPrevId;
            this.municipalitySelector.set('value', muniCd);
            this.municipalitySelector.set('noAllButton', true);
        },

        constructor: function(){
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },

        // 帳票出力を行う
        onOutputButtonClick : function() {
            // 出力帳票タイプ
            var formType = 'MUNI';  // 市町村
            if(this.municipalitySelector.get('value').slice(0, 3)===config.municInfo.prefRegCdGudge){
                // 県・振興局
                formType = 'PREF';
            }

            this.getParent().hide();
            // ダウンロード
            this.downloadExcel(formType);
        },

        //帳票出力
        downloadExcel : function(formType) {
            // 連携ファイル名とリクエストIDを取得
            var fileInf = this._fileMappin.filter(function(item){
                if(item.formType===formType) { return true; }
            })[0];
            // パラメータをセット
            var municipalityCd = this.municipalitySelector.get('value');
            var regionCd = null;
            if (municipalityCd === config.municInfo.prefCd) {
                // 県コードは県の市町村コードに変換
                municipalityCd = config.municInfo.prefMunicCd;
            } else if (municipalityCd.slice(0, 3)===config.municInfo.prefRegCdGudge) {
                // 振興局
                regionCd = municipalityCd;
                municipalityCd = null;
            }
            // 市町村はそのまま
            var data4xoblosDownload = {
                fileName: fileInf.fileName,
                reqid: fileInf.reqid,
                paramList: [
                    {key: 'disasterPrevId', value: this._disasterPreventionId},
                    {key: 'municipalityCd', value: municipalityCd},
                    {key: 'regionCd', value: regionCd}
                ]
            };

            var promise = this.download(data4xoblosDownload).then(lang.hitch(this, function() {
                    this.infoDialog = new InfoDialog({
                        title : 'ダウンロード完了',
                        content : 'ダウンロードが完了しました。'
                    });
                    this.infoDialog.show();

                }), lang.hitch(this, function(error) {
                    console.error(error);
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : 'エラーが発生しました。管理者にお問い合わせください。'
                    });
                    this.infoDialog.show();
            }));

            Loader.wait(promise);
        },

        // 帳票のダウンロード
        download: function(data) {
            var deferred = new Deferred();

            var xhr = new XMLHttpRequest();
            xhr.open('POST', '/api/xoblos/download', true);
            xhr.responseType = 'arraybuffer';
            xhr.setRequestHeader( 'Content-Type', 'application/json' );
            var self = this;
            xhr.onload = function() {

                // エラー時は処理を止める
                if(xhr.status !== 200){
                    deferred.reject('status error:'+ xhr.status);
                    return;
                }
                // ファイル名をレスポンスヘッダーから取り出す
                var contentDisposition = this.getResponseHeader('content-disposition');
                var inputFileName = contentDisposition.replace(/^.*"(.*)"$/, '$1');

                var arrayBuffer = this.response;
                var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});
                // ファイル名を取得
                var baseFileName = self._fileMappin.filter(function(item){
                    if(item.fileName===inputFileName) { return true; }
                })[0].fileNameJp;
                var fileName = baseFileName.replace(/^.*"(.*)"$/, '$1');

                // IE10+
                if(window.navigator.msSaveOrOpenBlob){
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    // 擬似的にAタグを作成
                    var link = document.createElement('a');
                    link.style = 'display: none';
                    document.body.appendChild(link);

                    // AタグのURLにバイナリデータをセット
                    var url = window.URL.createObjectURL(blob);
                    link.href = url;

                    // ファイル名をセット
                    link.download = fileName;

                    // 擬似的にリンクをクリック
                    link.click();
                    // 参照を解放
                    window.URL.revokeObjectURL(url);
                    link.remove();
                }

                deferred.resolve();

            };
            xhr.send(JSON.stringify(data));

            return deferred.promise;
        },

        /**
         * 押された帳票出力ボタンのIDをセットする
         */
        setSelectId : function(id) {
            this.form.set('value', {selectId:id});
        },

        /**
         * 月や日付を2桁にゼロpaddingする
         */
        _zeroPadding: function(month) {
            return ('00' + month).slice(-2);
        }
    });
});
