/**
 * レイヤー情報を単に出力する詳細画面パーツ。
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/text!./templates/DefinedByJson.html',
    'dstore/Memory',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/view/_IdisWidgetBase',
    // 以下、変数として受け取らないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    './DefinedByJsonGrid'
], function(module, declare, lang, locale, template, Memory,
    Router, Requester, _IdisWidgetBase) {
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase, {

        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            var layerId = this.item.id;
            // データ格納用オブジェクト
            Requester.get('/data/layer/data/'+layerId+'/data.json').then(lang.hitch(this, function(data) {
                var columns = {};
                // 各カラムの定義を取得して適用
                if (data.lists && data.lists.header) {
                    columns = this.defineColumns(data.lists.header);
                } else if (data.lists && data.lists[0] && data.lists[0].header) {
                    columns = this.defineColumns(data.lists[0].header);
                }
                this.grid.set('columns', columns);

                // データの中身を入れる
                this.store = new Memory({idProperty: 'id'});
                if (data.lists && data.lists.data) {
                    this.store.setData({items: data.lists.data});
                } else if (data.lists && data.lists[0] && data.lists[0].data) {
                    this.store.setData({items: data.lists[0].data});
                }
                this.grid.set('collection', this.store.filter());
            }));
        },

        defineColumns: function(list) {
            var columns = {};
            list.forEach(lang.hitch(this, function(item) {
                var column = {
                    label: item.name,
                    field: item.field,
                    width: item.width.replace(/^(\d+)\s?px/, '$1')
                };

                // カラムが緯度軽度だったら非表示にする
                if (item.field === 'lat' || item.field === 'lon') {
                    column.hidden = true;
                }

                columns[item.field] = column;
            }));

            return columns;
        },

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
        },

        startup: function() {
            this.inherited(arguments);
            // グリッドを初期化する
            this.initGrid();
        },

        // resizeメソッドを定義すると外側のresize時に合わせて呼ばれる
        resize: function(changeSize, resultSize) {
            // リサイズ時は中のBorderContainerに追随させる
            // （ウィジェット自体とBorderContainerで大きさが同一なので、サイズはそのまま渡す）
            this.borderContainer.resize(changeSize, resultSize);
        }
    });
});
