/**
 * 情報文表示ダイアログ
 * @module app/view/page/StatementDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/StatementDialog.html',
    'idis/view/page/_PageBase'
], function(module, declare, lang, template, _PageBase) {
    /**
     * 情報文表示ダイアログ
     * @class StatementDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/view/page/StatementDialog~StatementDialog# */ {
        // テンプレート文字列
        templateString: template,
        
        setMessage: function(str) {
            var message = this.escape(str);
            this.message.innerHTML = message;
        },
        
        escape: function(str) {
            var escaped;
            escaped = str.replace(/\r?\n/g, '<br>');
            return escaped;
        },
        
        onCancel: function() {
            this.leave();
        }
    });
});