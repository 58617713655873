/**
 * 災害体制の列定義一覧。
 * @module app/disasterprevention/view/DPColumn
 */
define([
    'app/model/Municipality',
    'idis/view/grid/helper',
    'idis/view/LazyNode',
    'dojo/when'
], function(Municipality, helper, LazyNode,  when) {
    var gatheringClmSetting = {
        formatter: function(data){
            return data!==null ? data.toLocaleString() : '-';
        }
    };
	var datetimeFormatter = function(value){
		return value ? value.replace(/-/g, '/') : '-';
	};
    return {
        regionName: {
            field: 'regionName',
            label: '振興局'
        },
        areaName: {
            field: 'areaName',
            label: '自治体',
            formatter: function(v, item) {
                return item.regionName || item.municipalityName;
            }
        },
        status: helper.column('statusName', '体制状況', {
            className: function(item) {
                // ヘッダーの場合はclassNameを返さない
                if (!item) {
                    return;
                }
                if(item.hqSetFlg !== '1'){
                    // 本部設置以外の場合、体制状況に関わらずセルは無色
                    return 'is-other';
                }
                return {
                    '01': 'is-emergency',
                    '02': 'is-alarm',
                    '03': 'is-caution',
                    '99': 'is-other'
                }[item.hqType] || '';
            },
            formatter: function(value) {
                return value ? value : '-';
            }
        }),
        hqName: helper.column('hqName', '本部情報', {
            className: function(item) {
                // ヘッダーの場合はclassNameを返さない
                if (!item) {
                    return;
                }
                if(item.hqSetFlg !== '1'){
                    // 本部設置以外の場合、本部情報に関わらずセルは無色
                    return 'is-other';
                }
                return {
                    '01': 'is-emergency',
                    '02': 'is-alarm',
                    '03': 'is-caution',
                    '99': 'is-other'
                }[item.hqType] || '';
            },
            formatter: function(value) {
                return value ? value : '-';
            }
        }),
        Headquarters: helper.column('headquarter', '本部設置情報', {
            children: [
                // helper.column('hqSetTimestamp', '設置/切替日時', { formatter: datetimeFormatter }),
                helper.column('hqSetTimestamp', '設置/切替日時', {
                    formatter: function (value, item) {
                        if (item && item.hqAbolishedTimestamp) {
                            return '-';
                        }else{
                            return value ? value.replace(/-/g, '/') : '-';
                        }
                    }
                }),
                helper.column('hqAbolishedTimestamp', '廃止日時', { formatter: datetimeFormatter })
            ],
            sortable: false
        }),
        deploymentPrefecture: helper.column('gathering', '配置人員', {
            children: [
                helper.column('disasterStandby', '災害待機', gatheringClmSetting),
                helper.column('floodDefenseStandby', '水防待機', gatheringClmSetting)
            ],
            sortable: false
        }),
        deploymentEmployee: helper.column('gathering', '配置人員', {
            children: [
                helper.column('disasterStandby', '災害待機', gatheringClmSetting),
                helper.column('floodDefenseStandby', '水防待機', gatheringClmSetting)
            ],
            sortable: false
        }),
        employeeList: helper.column('employeeList', '参集者', {
            sortable: false,
            formatter: function(data) {
                if (data && data.length > 100) {
                    return data.substring(0, 100) + '...';
                } else {
                    return data;
                }
            }
        }),
        reportUser: helper.column('reportUserName', '報告者'),
        reportTimestamp: helper.column('reportTimestamp', '報告日時', { formatter: datetimeFormatter }),
        lastReportTimestamp: helper.column('reportTimestamp', '最終報告日時', { formatter: datetimeFormatter })
    };
});
