define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/promise/all',
    'dojo/text!./templates/LegendPane.html',
    'dojo/when',
    'dojox/lang/functional/object',
    'idis/control/Locator',
    'idis/view/_IdisWidgetBase'
], function(module, array, declare, lang, all, template, when, df,
    Locator, _IdisWidgetBase) {
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase, {
        // テンプレート文字列
        templateString: template,

        // ウィジェットのルート要素に付与されるCSSクラス
        baseClass: 'map-LegendPane',

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * 指定された凡例一覧をDOMノードとして設置する。
         * @param {Object[]} legendList 凡例定義一覧
         */
        _placeLegendList: function(legendList) {
            // 各凡例をDOMノード化する
            var html = [];
            array.forEach(legendList, function(group) {
                // 各種別コードに対応する凡例一覧
                html.push('<li class="map-LegendPane-group"><div class="map-LegendPane-groupLabel">');
                html.push(group.name || group.path);
                html.push('</div><ul>');
                // 各種別コードに対応する個別の凡例
                array.map(group.list, function(item) {
                    if (item.image || item.link) {
                        // 凡例の色やアイコン
                        html.push('<li class="map-LegendPane-item">');
                        if (item.image) {
                            // 画像の場合
                            html.push('<div class="map-LegendPane-imageContainer">');
                            html.push('<img src="');
                            html.push(item.image);
                            html.push('">');
                            html.push('</div>');
                        } else if (item.link) {
                            // リンクの場合
                            html.push('<a href="');
                            html.push(item.link);
                            html.push(' "target="_blank"');
                            html.push(' ">');
                            html.push(item.name);
                            html.push('</a>');
                        }
                        html.push('</li>');
                    } else {
                        // 凡例の色やアイコン
                        html.push('<li class="map-LegendPane-item"><div class="map-LegendPane-iconContainer">');
                        if (item.src) {
                            // 画像の場合
                            html.push('<img src="');
                            html.push(item.src);
                            html.push('">');
                        } else if (item.area) {
                            // 領域の場合
                            html.push('<div class="map-LegendPane-icon--area" style="background:');
                            html.push(item.area);
                            // 線も同時に設定されている場合
                            if (item.line) {
                                html.push(';border:solid 2px ');
                                html.push(item.line);
                            }
                            html.push('"></div>');
                        } else {
                            // 線の場合
                            html.push('<div class="map-LegendPane-icon--line" style="background:');
                            html.push(item.line);
                            html.push('"></div>');
                        }
                        html.push('</div><div class="map-LegendPane-label">');
                        // 凡例名
                        html.push(item.name);
                        html.push('</div></li>');
                    }
                });
                html.push('</ul></li>');
            });
            var emptyMessage = [
                '<li>現在地図上に表示されている情報に対応する凡例はありません。',
                '（チェック中の表示情報に凡例が存在する場合はここに表示されます）</li>'
            ].join('<br>');
            this.legendListContainer.innerHTML = html.join('') || emptyMessage;
        }
    });
});
