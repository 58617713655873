/**
 * フォーム関連のユーティリティー。
 * @module idis/util/FormUtils
 */
define([
    'module',
    'dojox/lang/functional/object',
    './TypeUtils'
], function(module, df, TypeUtils) {
    /**
     * 指定されたオブジェクトの各値をリクエスト用に加工する。
     * @function serialize
     * @param {Object} valueObj フォームの項目名と値の組み合わせ
     * @returns {Object} 加工後の組み合わせ
     */
    function _serialize(valueObj) {
        // 各入力値を確認
        return df.mapIn(valueObj, function(value) {
            // 日付はepocを取る
            if (TypeUtils.isDate(value)) {
                return value.getTime();
            } else {
                return value;
            }
        });
    }

    return {
        serialize: _serialize
    };
});

