define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/aspect',
    'dojo/dom-class',
    'dojo/json',
    'dojo/text!./templates/LayerPane.html',
    'dojo/topic',
    'idis/consts/QUERY',
    'idis/control/Locator',
    'idis/view/page/_PageBase',
    // 以下、変数で受けないモジュール
    'dijit/form/HorizontalSlider',
    './LayerPaneTree'
], function(module, declare, lang, aspect, domClass, JSON, template, topic, QUERY, Locator, _PageBase) {
    return declare(module.id.replace(/\//g, '.'), _PageBase, {
        // テンプレート文字列
        templateString: template,

        /**
         * 最後に選択されたツリー要素
         * @type {Object}
         */
        _lastFocusItem: null,

        // リサイズ時は中のBorderContainerに追随させる
        resize: function(changeSize, resultSize) {
            this.borderContainer.resize(changeSize, resultSize);
        },

        /**
         * 透過率バーが更新された際に呼ばれる。
         * @param {number} value 0以上100以下の整数値
         */
        onOpacityChange: function(value) {
            // 値を文字列化する
            value = value + '';
            this.opacityLabel.innerHTML = value;
            var item = this._lastFocusItem;
            if (!item) {
                console.warn(module.id + '#onOpacityChange: ツリー要素が選択されていません');
                return;
            }
            var layerId = item.id;
            var layerQuery = Locator.getLayerQuery();
            if (layerQuery[layerId] && layerQuery[layerId] !== value) {
                // 表示中で透過度が変わっているならURLを更新
                layerQuery[layerId] = value;
                Locator.replaceState(QUERY.LAYER_LIST, Locator.toLayerQuery(layerQuery));
            }
        },

        // DOMノード生成時に呼ばれる
        buildRendering: function() {
            this.inherited(arguments);
            // 独自クラスを追加
            domClass.add(this.domNode, 'idis-Page--layerPane');
        },

        startup: function() {
            this.inherited(arguments);

            this.own(topic.subscribe('app/view/page/MonitoringPage::resetLayer', lang.hitch(this, function(layerQuery) {
                this.onCheckReleaseByQuery(layerQuery);
            })));
        },

        /**
         * 要素がチェックまたは解除されるか、選択要素が切り替わった際に呼ばれる。
         * 指定された要素の状態に従い透過率スライダーの表示を更新する。
         * @param {Object} item ツリー要素
         * @param {identifier} item.id ツリー要素の識別子
         */
        updateOpacitySlider: function(item) {
            // 選択された要素の透過度（文字列のため、'0'の場合も偽値にならない）
            var opacity = Locator.getLayerQuery()[item.id];
            // 透過率はチェック時のみ変更可能
            this.opacitySlider.set('disabled', !opacity);

            // TileLayer（3: 画像タイル）は透過率の初期値を20%に設定済のため、スライダーも連動させる
            if (opacity && item.jsonType ==='3'){
                opacity =  '20';
            }

            // 現在の透過率を反映
            this.opacitySlider.set('value', opacity || '0');
        },

        // DOMノード生成後に呼ばれる
        postCreate: function() {
            this.inherited(arguments);
            // ツリーの選択状態更新メソッドが呼ばれる直前に介入
            this.own(aspect.before(this.tree, 'focusNode', lang.hitch(this, function(node) {
                if (this._lastFocusItem !== node.item) {
                    console.debug(module.id + ': レイヤー選択: ' + JSON.stringify(node.item));
                    // 最後の選択要素を記憶する
                    this._lastFocusItem = node.item;
                }
                // 透過率の反映
                this.updateOpacitySlider(node.item);
                // 説明欄を更新
                this.descriptionArea.innerHTML = node.item.description || '&nbsp;';
                // 説明欄の変化に合わせてリサイズ
                this.resize();
                // 元の引数をそのまま返す
                return arguments;
            })));
        },

        /**
         * レイヤー選択の全解除
         */
        onCheckRelease: function() {
            var payload = {};
            var layerQuery = Locator.getLayerQuery();
            var id = [];
            payload = {layerQuery:layerQuery, layerId:id};
            topic.publish(module.id + '::released', payload);
        },

        /**
         * レイヤー選択の解除
         */
        onCheckReleaseByQuery: function(query) {
            var payload = {};
            var id = [];
            var layers = query.l.split(',');
            var key, value;
            var layerQuery = {};
            layers.forEach(function(item){
                key = item.split('-')[0];
                value = item.split('-')[1];
                layerQuery[key] = value;
            });
            payload = {layerQuery:layerQuery, layerId:id};
            topic.publish(module.id + '::released', payload);
        }
    });
});
