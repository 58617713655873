/**
 * フォルダ管理画面の新規フォルダ作成処理を実装するMixin。
 * @module app/folder/view/_NewFolderMixin
 */
define([
    'module',
    'dojo/_base/declare'
    // 以下、変数として受け取らないモジュール
], function(module, declare) {
    /**
     * フォルダ管理画面の新規フォルダ作成を実装するMixin。
     * @class _NewFolderMixin
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(null, /** @lends module:app/view/folder/view/_NewFolderMixin~_NewFolderMixin# */ {
        /**
         * 新規フォルダ作成の起点となる要素。
         * @type {Object}
         */
        _newFolderTarget: null,

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は新規フォルダ作成ダイアログも削除する
            if (this.newFolderDialog) {
                this.own(this.newFolderDialog);
            }
        },

        /**
         * ツリーの選択要素が変わったときに呼ばれる。
         */
        onLeftTreeFocus: function(item) {
            this.inherited(arguments);
            // 新規フォルダ作成ボタンはツリー要素選択中しか使えない
            if (this.newFolderButton) {
                this.newFolderButton.set('disabled', !item);
            }
        },

        /**
         * 新規フォルダ作成ボタンが押された際に呼ばれる。
         * @param {MouseEvent} evt クリック・イベント
         */
        onNewFolderButtonClick: function(/* evt */) {
            var target = this._newFolderTarget = this.leftTree.get('lastFocusedItem');
            // 新規フォルダ作成フォームを初期化
            this.newFolderForm.reset();
            // 「子要素として追加」は選択要素がフォルダの場合に限る
            var isTargetFolder = target.infoCategoryCd && target.infoCategoryCd.indexOf('T') === 0;
            this.newFolderPlace.set('disabled', !isTargetFolder);
            // 新規フォルダ作成ダイアログを表示
            this.newFolderDialog.show();
        },

        /**
         * 新規フォルダ作成ダイアログのOKボタンが押された際に呼ばれる。
         */
        onNewFolderOK: function() {
            // 新規フォルダ作成フォームをサブミット
            this.newFolderForm.submit();
        },

        /**
         * 新規フォルダ作成フォームがサブミットされた際に呼ばれる。
         */
        onNewFolderFormSubmit: function() {
            // エラー時もフォーム投稿されないようにtry-catchする
            try {
                if (!this.newFolderForm.validate()) {
                    // 入力チェック失敗時は何もしない
                    return;
                }
                // 確認ダイアログを表示
                this.chain.confirm('変更します。よろしいですか？', function(chain) {
                    // 確認ダイアログでOKを押した場合
                    var value = this.newFolderForm.get('value');
                    var target = this._newFolderTarget;
                    // 兄弟要素 or 子要素（選択対象がフォルダでないなら子要素には出来ない）
                    var isTargetFolder = target.infoCategoryCd && target.infoCategoryCd.indexOf('T') === 0;
                    var parentId = (!isTargetFolder || value.place === '0') ? target.parentId : target.id;
                    // disasterIdを持っている場合は追加
                    var disasterId = target.disasterId;
                    // 新規フォルダを作成
                    this.leftTree.model.createFolder(value.name, parentId, disasterId);
                    // 新規フォルダ作成ダイアログを隠す
                    this.newFolderDialog.hide();
                    chain.info('フォルダ作成を完了しました。<br>（確定ボタンを押すまで実際のデータには反映されません）');
                });
            } catch (e) {
                console.error(e);
            }
            return false;
        }
    });
});

