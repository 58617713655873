/**
 * 地図電子ファイル詳細モジュール
 * @module app/view/page/GeoFileDetailDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/on',
    'dojo/text!./templates/GeoFileDetailDialog.html',
    'idis/consts/QUERY',
    'idis/control/Router',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    // 以下、変数として受け取らないモジュール
    'idis/view/form/Button',
    'idis/view/form/AclButton',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/TitlePane',
    '../view/form/LayerDirectorySelector'
], function(module, declare, lang, domStyle, on, template, QUERY, Router, InfoDialog, _PageBase) {

    /**
     * 地図電子ファイル詳細
     * @class GeoFileDetailDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/GeoFileDetailDialog~GeoFileDetailDialog# */ {

    // テンプレート文字列
    templateString: template,

    startup: function() {
    },

    /**
     * 更新を実行する
     */
    onSubmit: function() {
        // formに定義されている情報を設定する
        var sendData = this.form.get('value');
        sendData.minZoom = this.minZoom.get('value');
        sendData.maxZoom = this.maxZoom.get('value');
        sendData.pubStatus = '4';
        sendData.pubSectCd = '';
        console.debug(sendData);
        try {
            if (this.form.validate() && this.isGeoFileDetailValid(sendData)){
                this.emit('sendupdate', {
                    // テンプレートHTML内でformに対し
                    // data-dojo-attach-point="form"を指定してthis.formとしてアクセス出来るようにしている
                    value: sendData
                });
            }
        } catch (e) {
            console.error(e);
        }
        return false;
    },

    /**
     * 削除を実行する
     */
    onDelete: function() {
        // formから地図電子ファイルIDを取得する
        var id = this.form.get('value').geoFileId;
        this.emit('senddelete', {
            // テンプレートHTML内でformに対し
            // data-dojo-attach-point="form"を指定してthis.formとしてアクセス出来るようにしている
            value:id
        });
        return false;
    },

    /**
     * 確認を実行する
     */
    onPreview: function() {
        var query = {};
        query[QUERY.LAYER_LIST] = this.layerId.innerHTML + '-0';
        Router.moveTo('map', query);
    },

    /**
     * データ格納領域を初期化し、データを設定する
     */
    setPage: function(item) {
        this.reset();
        this.parentLayerId._setValueAttr(item.parentLayerId);
        this.layerName.innerHTML = item.layerName;
        this.coordinate.innerHTML = item.coordinate;
        this.geodetic.innerHTML = item.geodetic;
        this.layerId.innerHTML = item.layerId;
        this.minZoom.set('value', item.minZoom);
        this.maxZoom.set('value', item.maxZoom);
        this.descriptionD.set('value', item.description);

        // 表示ズームレベル、情報説明文を編集不可にする
        this.minZoom.set('disabled', true);
        this.maxZoom.set('disabled', true);
        this.descriptionD.set('readOnly', true);

        // 処理状況のコード値を名称をマッピングする
        // タイル化完了の場合のみ、表示ズームレベル、情報説明文を編集可能に変更する
        if(item.tstatus === '0'){
            this.tstatus.innerHTML = 'タイル化情報登録済';
        } else if (item.tstatus === '1') {
            this.tstatus.innerHTML = 'タイル化情報登録エラー';
        } else if (item.tstatus === '2') {
            this.tstatus.innerHTML = 'タイル化処理依頼済';
        } else if (item.tstatus === '3') {
            this.tstatus.innerHTML = 'タイル化処理受付エラー';
        } else if (item.tstatus === '4') {
            this.tstatus.innerHTML = 'タイル化中';
        } else if (item.tstatus === '5') {
            this.tstatus.innerHTML = 'タイル化処理エラー';
        } else if (item.tstatus === '6') {
            this.tstatus.innerHTML = 'タイル化完了';
            this.minZoom.set('disabled', false);
            this.maxZoom.set('disabled', false);
            this.descriptionD.set('readOnly', false);
        } else if (item.tstatus === '7') {
            this.tstatus.innerHTML = 'キャンセル';
        }

        // タイル化完了以外のステータスの場合、確認ボタンを非表示にする
        if (item.tstatus === '6') {
            this.confirm.set('style', {display: ''});
        } else {
            this.confirm.set('style', {display: 'none'});
        }

        // 入力データ形式のコード値を名称をマッピング
        if(item.inputDataFormat === '0'){
            this.inputDataFormatName.innerHTML = '画像（jpg＋jgw）';
        } else if (item.inputDataFormat === '1') {
            this.inputDataFormatName.innerHTML = '画像（png＋pgw）';
        } else if (item.inputDataFormat === '2') {
            this.inputDataFormatName.innerHTML = 'GeoTIFF';
        } else if (item.inputDataFormat === '3') {
            this.inputDataFormatName.innerHTML = 'Shapefile';
        } else if (item.inputDataFormat === '4') {
            this.inputDataFormatName.innerHTML = 'KML';
        } else if (item.inputDataFormat === '5') {
            this.inputDataFormatName.innerHTML = 'タイル化済データ';
        }

        // 入力データ形式がShapefile、KMLの場合のみ、出力形式を表示
        if((item.inputDataFormat !== '3') && (item.inputDataFormat !== '4')){
            domStyle.set(this.outputDataFormatArea, 'display', 'none');
        }
        if((item.inputDataFormat === '3') || (item.inputDataFormat === '4')){
            domStyle.set(this.outputDataFormatArea, 'display', '');
            if(item.outputDataFormat === '1'){
                this.outputDataFormatName.innerHTML = '画像タイル形式';
            } else if (item.outputDataFormat === '2'){
                this.outputDataFormatName.innerHTML = 'ベクトルタイル形式';
            }
        }

        // EPSGコードをもとに元データの測地系、座標系を設定
        var epsgArray = this.epsgFormatter(item.epsgCd);
        if(epsgArray === ''){
            this.coordinate.innerHTML = '';
            this.geodetic.innerHTML = '';
        } else {
            this.coordinate.innerHTML = epsgArray[0];
            this.geodetic.innerHTML = epsgArray[1];
        }

        if(item.overlayZoom === 0){
            this.maxNativeZoom.innerHTML = '';
        } else {
            this.maxNativeZoom.innerHTML = item.overlayZoom;
        }

        // 格納先を設定する
        this.parentLayerId._setValueAttr(item.parentLayerId);
    },

    /**
     * 入力値をチェックする
     */
    isGeoFileDetailValid: function(data) {
        if (Number(data.minZoom) > Number(data.maxZoom)) {
            InfoDialog.show('入力エラー', '表示ズームレベルの範囲が不適切です。');
            return false;
        }
        return true;
    },

    /**
     * 元データの測地系、座標系を設定する
     * @param coordinate 座標系
     */
    epsgFormatter: function(epsgCd){
        var epsgObj = {
            '4612' : ['世界測地系JGD2000', '緯度経度'],
            '2443' : ['世界測地系JGD2000', '平面直角座標系 第1系'],
            '2445' : ['世界測地系JGD2000', '平面直角座標系 第3系'],
            '2446' : ['世界測地系JGD2000', '平面直角座標系 第4系'],
            '2447' : ['世界測地系JGD2000', '平面直角座標系 第5系'],
            '2448' : ['世界測地系JGD2000', '平面直角座標系 第7系'],
            '2450' : ['世界測地系JGD2000', '平面直角座標系 第8系'],
            '2451' : ['世界測地系JGD2000', '平面直角座標系 第9系'],
            '2452' : ['世界測地系JGD2000', '平面直角座標系 第10系'],
            '2453' : ['世界測地系JGD2000', '平面直角座標系 第11系'],
            '2454' : ['世界測地系JGD2000', '平面直角座標系 第13系'],
            '2456' : ['世界測地系JGD2000', '平面直角座標系 第14系'],
            '2458' : ['世界測地系JGD2000', '平面直角座標系 第16系'],
            '2459' : ['世界測地系JGD2000', '平面直角座標系 第17系'],
            '2460' : ['世界測地系JGD2000', '平面直角座標系 第18系'],
            '2461' : ['世界測地系JGD2000', '平面直角座標系 第19系'],
            '3098' : ['世界測地系JGD2000', 'UTM座標系 52N'],
            '3099' : ['世界測地系JGD2000', 'UTM座標系 53N'],
            '3100' : ['世界測地系JGD2000', 'UTM座標系 54N'],
            '3101' : ['世界測地系JGD2000', 'UTM座標系 55N'],
            '102150' : ['世界測地系JGD2000', 'UTM座標系 56N'],
            '104020' : ['世界測地系JGD2011', '緯度経度'],
            '102610' : ['世界測地系JGD2011', '平面直角座標系 第1系'],
            '102611' : ['世界測地系JGD2011', '平面直角座標系 第2系'],
            '102612' : ['世界測地系JGD2011', '平面直角座標系 第3系'],
            '102613' : ['世界測地系JGD2011', '平面直角座標系 第4系'],
            '102614' : ['世界測地系JGD2011', '平面直角座標系 第5系'],
            '102615' : ['世界測地系JGD2011', '平面直角座標系 第6系'],
            '102616' : ['世界測地系JGD2011', '平面直角座標系 第7系'],
            '102617' : ['世界測地系JGD2011', '平面直角座標系 第8系'],
            '102618' : ['世界測地系JGD2011', '平面直角座標系 第9系'],
            '102619' : ['世界測地系JGD2011', '平面直角座標系 第10系'],
            '102620' : ['世界測地系JGD2011', '平面直角座標系 第11系'],
            '102621' : ['世界測地系JGD2011', '平面直角座標系 第12系'],
            '102622' : ['世界測地系JGD2011', '平面直角座標系 第13系'],
            '102623' : ['世界測地系JGD2011', '平面直角座標系 第14系'],
            '102624' : ['世界測地系JGD2011', '平面直角座標系 第15系'],
            '102625' : ['世界測地系JGD2011', '平面直角座標系 第16系'],
            '102626' : ['世界測地系JGD2011', '平面直角座標系 第17系'],
            '102627' : ['世界測地系JGD2011', '平面直角座標系 第18系'],
            '102628' : ['世界測地系JGD2011', '平面直角座標系 第19系'],
            '102593' : ['世界測地系JGD2011', 'UTM座標系 51N'],
            '102594' : ['世界測地系JGD2011', 'UTM座標系 52N'],
            '102595' : ['世界測地系JGD2011', 'UTM座標系 53N'],
            '102596' : ['世界測地系JGD2011', 'UTM座標系 54N'],
            '102597' : ['世界測地系JGD2011', 'UTM座標系 55N'],
            '102598' : ['世界測地系JGD2011', 'UTM座標系 56N'],
            '4301' : ['日本測地系TOKYO', '緯度経度'],
            '30161' : ['日本測地系TOKYO', '平面直角座標系 第1系'],
            '30162' : ['日本測地系TOKYO', '平面直角座標系 第2系'],
            '30163' : ['日本測地系TOKYO', '平面直角座標系 第3系'],
            '30164' : ['日本測地系TOKYO', '平面直角座標系 第4系'],
            '30165' : ['日本測地系TOKYO', '平面直角座標系 第5系'],
            '30166' : ['日本測地系TOKYO', '平面直角座標系 第6系'],
            '30167' : ['日本測地系TOKYO', '平面直角座標系 第7系'],
            '30168' : ['日本測地系TOKYO', '平面直角座標系 第8系'],
            '30169' : ['日本測地系TOKYO', '平面直角座標系 第9系'],
            '30170' : ['日本測地系TOKYO', '平面直角座標系 第10系'],
            '30171' : ['日本測地系TOKYO', '平面直角座標系 第11系'],
            '30172' : ['日本測地系TOKYO', '平面直角座標系 第12系'],
            '30173' : ['日本測地系TOKYO', '平面直角座標系 第13系'],
            '30174' : ['日本測地系TOKYO', '平面直角座標系 第14系'],
            '30175' : ['日本測地系TOKYO', '平面直角座標系 第15系'],
            '30176' : ['日本測地系TOKYO', '平面直角座標系 第16系'],
            '30177' : ['日本測地系TOKYO', '平面直角座標系 第17系'],
            '30178' : ['日本測地系TOKYO', '平面直角座標系 第18系'],
            '30179' : ['日本測地系TOKYO', '平面直角座標系 第19系'],
            '3092' : ['日本測地系TOKYO', 'UTM座標系 51N'],
            '3093' : ['日本測地系TOKYO', 'UTM座標系 52N'],
            '3094' : ['日本測地系TOKYO', 'UTM座標系 53N'],
            '3095' : ['日本測地系TOKYO', 'UTM座標系 54N'],
            '3096' : ['日本測地系TOKYO', 'UTM座標系 55N'],
            '102156' : ['日本測地系TOKYO', 'UTM座標系 56N'],
            '4326' : ['WGS84', '緯度経度'],
            '3857' : ['WGS84', 'Webメルカトル投影 ']
        };

        if (epsgCd === null || epsgCd === '') {
            return '';
        } else {
            if (epsgObj[epsgCd]) {
                return epsgObj[epsgCd];
            } else {
                return '';
            }
        }
    },

    /**
     * データ格納領域を初期化する
     */
    reset: function() {
        this.layerName.innerHTML = null;
        this.inputDataFormatName.innerHTML = null;
        this.outputDataFormatName.innerHTML = null;
        this.coordinate.innerHTML = null;
        this.geodetic.innerHTML = null;
        this.maxNativeZoom.innerHTML = null;
        this.tstatus.innerHTML = null;
     }
   });
 });
