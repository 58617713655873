define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, lang, locale, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--sending',

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            helper.column('areaName', '発令地区', {
                sortable: false,
                formatter: function(value, item){
                    if(!item.areaNameKana){
                        return item.areaName;
                    }
                    return '<font size="-2" color="#c6c6c6">' +
                    item.areaNameKana + '</font></br>' +
                    '<font size="">' + item.areaName + '</font>';
                }
            }),
            helper.column('reason', '発令理由', {
                sortable: false,
                formatter: function(value, item){
                    if(!item.alertLevel){
                        return item.reason;
                    }
                    // 警戒レベルがある場合は、振り仮名をつける
                    return '<font size="-2" color="#c6c6c6">' +
                    item.alertLevel + '</font></br>' +
                    '<font size="">' + item.reason + '</font>';
                }
            }),
            helper.column('evacuateSort', '発令内容', {
                sortable: false,
                formatter: function(value, item){
                    // 警戒レベルがある場合は、振り仮名をつける
                    return item.evacuateSort + '</br>' +
                    '<font size="">' + item.evacuateIssue + '</font>';
                }
            }),
            helper.column('typeOfDisaster', '災害種別', {
                sortable: false,
                formatter: function(value){
                    return value.join('<br>');
                }
            }),
            helper.column('target', '対象', {
                children: [
                    helper.column('evacHouseholdNum', '世帯数', {
                        sortable: false,
                        formatter: function(item){
                            if(!item || item === 0){
                                return '-';
                            }
                            return item;
                        }
                    }),
                    helper.column('evaqueeNum', '人数', {
                        sortable: false,
                        formatter: function(item){
                            if(!item || item === 0){
                                return '-';
                            }
                            return item;
                        }
                    })
                ],
                sortable: false
            }),
            helper.column('evacOrderTimestamp', '発令・解除日時', {
                sortable: false,
                formatter: function(item) {
                    if (item) {
                        return locale.format(new Date(item));
                    }
                    return item;
                }
            })
        ]
    });
});
