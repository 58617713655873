/**
 *　クロノロジ関連のUtility
 * @module app/chronology/ChronologyUtil
 */
define([
    'module'
], function() {

    var CHRONOLOGY_SETTING_MAP = {
        /**
         * code : クロノロジ種別
         * NOTE: 自治体限定フラグ、組織限定フラグのDB定義は以下だが、実装は逆で、30,34,31のいずれも自所属限定で閲覧可。
         * municipalityAuthorizeFlg : 自治体参照権限チェックフラグ('0'の場合は、他自治体は参照不可。'1'の場合、他自治体も参照可)
         * organizationAuthorizeFlg : 組織参照権限チェックフラグ('0'の場合、他組織は参照不可。'1'の場合、他組織も参照可)
         * isShareNameOrganization  : 組織単位に共有を行う
         * isMunicipalityUnit       : 市町村単位に共有を行う
         */
        demand:  {code: '30',
                    municipalityAuthorizeFlg: '1',
                    organizationAuthorizeFlg: '1',
                    isShareNameOrganization: true,
                    isMunicipalityUnit: false},
        notify:  {code: '34',
                    municipalityAuthorizeFlg: '1',
                    organizationAuthorizeFlg: '1',
                    isShareNameOrganization: true,
                    isMunicipalityUnit: false},
        internal: {code: '31',
                    municipalityAuthorizeFlg: '1',
                    organizationAuthorizeFlg: '1',
                    isShareNameOrganization: true,
                    isMunicipalityUnit: false}
    };

    function _getSettingFromCode(chronologyType){
        var setting = null;
        Object.keys(CHRONOLOGY_SETTING_MAP).forEach(function(key){
            if(CHRONOLOGY_SETTING_MAP[key].code === chronologyType){
                setting = CHRONOLOGY_SETTING_MAP[key];
            }
        });

        return setting;
    }

    return {
        getSettingFromCode: _getSettingFromCode
    };
});
