/**
 * 中身をスクロールさせる機能を持つウィジェット。
 * @module app/map/notice/MarqueePanel
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/fx',
    'dojo/_base/lang',
    'dojo/aspect',
    'dojo/dom-geometry',
    'dojo/dom-style',
    'dojo/fx/easing',
    'dijit/layout/_LayoutWidget',
    'idis/view/_IdisWidgetBase'
    // 以下、変数で受けないモジュール
], function(module, declare, fx, lang, aspect, domGeom, domStyle, easing, _LayoutWidget, _IdisWidgetBase) {
    /**
     * @class MarqueePanel
     */
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase, {
        // テンプレート文字列
        templateString: [
            '<div><div class="map-MarqueePanel-container"',
            'data-dojo-attach-point="containerNode"></div></div>'
        ].join(' '),

        // ウィジェットのルート要素に付与されるCSSクラス
        baseClass: 'map-MarqueePanel',

        /**
         * コンテナー領域の幅（スクロール速度の算出に利用）
         * @type {number}
         */
        _containerWidth: 0,

        /**
         * 最後のアニメーション動作。
         * @type {Animation}
         */
        _lastAnimation: null,

        // ウィジェットが接地された後に呼ばれる
        startup: function() {
            this.inherited(arguments);
            // スクロール領域の大きさを記録
            this.saveContainerSize();
            // スクロール処理を実行
            this.scrollToLeft();
        },

        // ウィジェットが破棄される際に呼ばれる
        destroy: function() {
            // 実行中のアニメーションがあれば破棄
            this.removeAnimation();
            this.inherited(arguments);
        },

        /**
         * スクロール領域の大きさ（文字の大きさに依存）を記録する。
         */
        saveContainerSize: function() {
            this._containerSize = domGeom.position(this.containerNode);
        },

        /**
         * アニメーションを一時停止する。
         */
        playAnimation: function() {
            if (this._lastAnimation) {
                this._lastAnimation.play(0, true);
            }
        },

        /**
         * アニメーションを一時停止する。
         */
        pauseAnimation: function() {
            if (this._lastAnimation) {
                this._lastAnimation.pause();
            }
        },

        /**
         * 実行中のアニメーションがある場合は破棄する。
         */
        removeAnimation: function() {
            // 実行中のアニメーションがあれば破棄
            if (this._lastAnimation) {
                this._lastAnimation.destroy();
                this._lastAnimation = null;
            }
        },

        /**
         * ウィジェットのサイズを変更する。
         */
        resize: function(changeSize) {
            // 高さはスクロール対象に合わせる
            if (changeSize && !changeSize.h && changeSize.h !== 0) {
                changeSize = lang.mixin(null, changeSize, {h: this._containerSize.h});
            }
            // _LayoutWidgetのロジックをそのまま利用
            _LayoutWidget.prototype.resize.apply(this, arguments);
        },

        /**
         * _LayoutWidgetのresizeから呼ばれる
         */
        layout: function() {
            // do nothing
        },

        /**
         * コンテンツ内容を設定する
         * @param {string} content HTML文字列
         */
        setContent: function(content) {
            // 実行中のアニメーションがあれば破棄
            this.removeAnimation();
            // 内容を書き換える
            this.containerNode.innerHTML = content;
            // スクロール領域の大きさを記録
            this.saveContainerSize();
            // 最初からスクロール開始
            this.scrollToLeft();
        },

        /**
         * スクロール領域幅とスクロール要素幅の合計が隠れるまでの移動距離を取得する。
         * アニメーション時間を移動距離に比例させることで、各幅によらず等速移動させる。
         */
        getDuration: function() {
            // スクロール部左端から枠左端まで（pxはparseFloatで切り捨てられる）
            var scrollLeft = parseFloat(this.containerNode.style.left);
            // スクロール部の幅
            var scrollWidth = domGeom.position(this.containerNode).w;
            return (scrollLeft + scrollWidth) * 7;
        },

        /**
         * 左へスクロールするアニメーションを実行。
         */
        scrollToLeft: function() {
            // 実行中のアニメーションがあれば破棄
            this.removeAnimation();
            // スクロール要素をスクロール開始位置へ移動
            this._domSize = domGeom.position(this.domNode);
            domStyle.set(this.containerNode, 'left', this._domSize.w + 'px');
            // アニメーションを再帰的に実行
            this._lastAnimation = fx.anim(this.containerNode, {
                left: - this._containerSize.w
            }, this.getDuration(), easing.linear, lang.hitch(this, 'scrollToLeft'));
            this.own(aspect.after(this._lastAnimation, 'onPlay', lang.hitch(this, function() {
                this._lastAnimation.duration = this.getDuration();
            }), true));
        }
    });
});
