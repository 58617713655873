/**
 * 緊急通知端末を扱うモデル。
 * @module app/model/BroadnotifyTerminal
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/store/Memory',
    'idis/service/Requester'
], function(module, declare, lang, Deferred, Memory, Request) {
    var BroadnotifyTerminal = declare(module.id.replace(/\//g, '.'), [], {

        API_ENDPOIT: '/api/broadnotify/terminal/terminal4Tree',

        get: function(id) {
            var deferred = new Deferred();
            Request.get(this.API_ENDPOIT).then(lang.hitch(this, function(data) {
                var memory = new Memory({
                    idProperty: 'id',
                    data: data
                });
                deferred.resolve(memory.get(id));
            }));
            return deferred.promise;
        }

    });

    return new BroadnotifyTerminal();
});
