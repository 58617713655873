/**
 * 共通バーチャート用モジュール。
 * @module idis/view/draw/StackedBars
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojox/charting/plot2d/StackedBars',
    'dojox/charting/plot2d/commonStacked'
], function(module, declare, lang, StackedBars, commonStacked){
    /**
     * 共通バーチャート・ウィジェット。
     * 既存チャートのラベルの表示場所がずれることを対応
     * https://stackoverrun.com/ja/q/11192842 に参考
     * @class StackedBars
     * @extends module:dijit/form/StackedBars~StackedBars
     */
    return declare(module.id.replace(/\//g, '.'), StackedBars,
        /** @lends module:idis/view/draw/StackedBars~StackedBars# */ {
        getValue: function (value, index, seriesIndex, indexed) {
            var y, x;
            if (indexed) {
                x = index;
                y = commonStacked.getIndexValue(this.series, seriesIndex, x, lang.hitch(this, 'isNullValue'));
            } else {
                x = value.x - 1;
                y = commonStacked.getValue(this.series, seriesIndex, value.x);
                y = [y[0] ? y[0].y : null, y[1] ? y[1] : null];
            }
            // in py we return the previous stack value as we need it to position labels on columns
            return { x: x, y: y[0], py: y[1] };
        }
    });
});
