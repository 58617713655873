/**
 * 観測地域を選択するためのウィジェット
 */
define([
    'module',
    'dojo/_base/declare',
    'dijit/form/Select',
    'app/model/ObservationRegion'
], function(module, declare, Select, ObservationRegion) {
    return declare(module.id.replace(/\//g, '.'), Select,{

        store: ObservationRegion,

        buildRendering: function() {
            this.inherited(arguments);
            this.set('style', {width: '10em', height:'20px'});

        },

        /**
         * 地域のみのリストとする
         */
        _setRegionOnly: function(){
            this.set('query', function(option){
                if(option.regionFlg!=='0'){
                    return option;
                }
            });
        },

        /**
         * 流域のみのリストとする
         */
        _setRiverBasinOnly: function(){
            this.set('query', function(option){
                if(option.regionFlg!=='1'){
                    return option;
                }
            });
        }
        
    });
});
