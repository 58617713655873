define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/json',
    'dijit/form/Select',
    'dstore/Memory',
    'idis/service/Requester'
], function(module, array, declare, lang, json, Select, Memory, Requester) {
    /**
    * 公開事業課選択用パーツ。
    * @class busDivSectSelector
    */
    return declare(module.id.replace(/\//g, '.'), Select,{

        /*
        * 公開事業課を扱うモデル
        */
        model:null,

        /*
        * コンストラクタ
        */
        constructor: function(){
            this.model = new Memory({data: [{id:0, value: null, label:'&nbsp;'}]});
            var self = this;
            Requester.get('/api/sect/getPubSectCdList').then(function(data) {
                array.forEach(data, lang.hitch(function(object){
                    self.model.data.push(object);
                }));
                console.log(self.model.data);
            }, function(error) {
                console.log(error);
            });
        },

        /*
        * DOM構築処理
        */
        buildRendering: function() {
            this.inherited(arguments);
            // 幅を調整
            this.set('style', {width: '10em'});
        },

        /*
        * SelectBoxの選択肢を変更する
        */
        changeOptions: function(busDivSect) {
            var option = this.model.data;

            // 変更後の選択肢をoptionsに設定
            this.set('options', option);
            if(busDivSect) {
                for(var i in this.options) {
                    if(this.options[i].value === busDivSect) {
                        // 事業課が与えられている場合は選択状態にする
                        this.set('value', this.options[i]);
                        break;
                    }
                }
            } else {
                // 空白を初期値として設定す
                this.set('value', this.options[0]);
            }
            this.startup();
        },

        /*
        * SelectBoxの選択肢の種類を設定する
        */
        createSelector: function(busDivSect) {
            // セレクトの選択肢を変更する
            this.changeOptions(busDivSect);
        }
    });
});
