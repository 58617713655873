/**
 * フォルダ管理画面の名前変更処理を実装するMixin。
 * @module app/folder/view/_RenameMixin
 */
define([
    'module',
    'dojo/_base/declare'
    // 以下、変数として受け取らないモジュール
], function(module, declare) {
    /**
     * フォルダ管理画面の名前変更処理を実装するMixin。
     * @class _RenameMixin
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(null, /** @lends module:app/view/folder/view/_RenameMixin~_RenameMixin# */ {
        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は名前変更ダイアログも削除する
            if (this.renameDialog) {
                this.own(this.renameDialog);
            }
        },

        /**
         * ツリーの選択要素が変わったときに呼ばれる。
         */
        onLeftTreeFocus: function(item) {
            this.inherited(arguments);
            // 名前変更ボタンはツリー要素選択中しか使えない
            if (this.renameButton) {
                this.renameButton.set('disabled', !item);
            }
        },

        /**
         * 名前変更ボタンが押された際に呼ばれる。
         * @param {MouseEvent} evt クリック・イベント
         */
        onRenameButtonClick: function(/* evt */) {
            this._renameTarget = this.leftTree.get('lastFocusedItem');
            // 名前変更フォームを初期化
            this.renameForm.set('value', this._renameTarget);
            // 名前変更ダイアログを表示
            this.renameDialog.show();
        },

        /**
         * 名前変更ダイアログのOKボタンが押された際に呼ばれる。
         */
        onRenameOK: function() {
            // 名前変更フォームをサブミット
            this.renameForm.submit();
        },

        /**
         * 名前変更フォームがサブミットされた際に呼ばれる。
         */
        onRenameFormSubmit: function() {
            // エラー時もフォーム投稿されないようにtry-catchする
            try {
                if (!this.renameForm.validate()) {
                    // 入力チェック失敗時は何もしない
                    return;
                }
                // 確認ダイアログを表示
                this.chain.confirm('変更します。よろしいですか？', function(chain) {
                    // 確認ダイアログでOKを押した場合
                    var name = this.renameForm.get('value').name;
                    // 名前を変更
                    this.leftTree.model.renameItem(this._renameTarget, name);
                    // 名前変更ダイアログを隠す
                    this.renameDialog.hide();
                    chain.info('名前変更を完了しました。<br>（確定ボタンを押すまで実際のデータには反映されません）');
                });
            } catch (e) {
                console.error(e);
            }
            return false;
        }
    });
});

