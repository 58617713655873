/**
 * 文字列に関するユーティリティー関数群
 * @module idis/util/StringUtils
 */
define(function() {
    var escapeRegexp = /[&<>"'`]/g;
    var escapeMap = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        '\'': '&#x27;',
        '`': '&#x60;'
    };

    return {
        /**
         * HTML表示用にエスケープする。
         * @param {string} エスケープ対象文字列
         * @returns {string} エスケープ済み文字列
         */
        escape: function(str) {
            if (!str) {
                return str;
            }
            return str.replace(escapeRegexp, function(c) {
                return escapeMap[c];
            });
        },

        /**
         * 指定された長さを超えた文字列の表示を省略する関数を返す関数。
         * @param {number} num 文字列を切る長さ
         * @param {string} [delimiter='…'] 区切った場合に足す文字列
         * @returns {function} 指定された文字列を決められた長さで切る関数
         * @example
         * var max3 = StringUtils.abbrevWith(3);
         * max3('いろは'); // 'いろは'
         * max3('いろはに'); // 'いろ…'
         */
        abbrevWith: function(num, delimiter) {
            delimiter = delimiter || '…';
            return function(str) {
                return (str.length > num) ? (str.slice(0, num-1) + delimiter) : str;
            };
        }
    };
});
