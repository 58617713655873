/**
* 体制状況・報告概況画面用モジュール。
* @module app/disasterpreventionstatus/view/DisasterPreventionStatusListPage
*/
define([
	'module',
	'dojo/_base/declare',
	'dojo/_base/lang',
	'dojo/json',
	'dojo/text!./templates/DisasterPreventionStatusListPage.html',
	'idis/model/UserInfo',
	'idis/control/Router',
	'idis/store/IdisRest',
	'idis/view/dialog/DialogChain',
	'idis/view/page/_PageBase',
	'app/config',
	'idis/view/Loader',
	'idis/consts/USER_TYPE',
	'idis/control/Locator',
	// 以下、変数として受け取らないモジュール
	'dijit/layout/BorderContainer',
	'dijit/Dialog',
	'dijit/form/Form',
	'dijit/form/TextBox',
	'idis/view/form/DateInput',
	'idis/view/form/DateTimeInput',
	'idis/view/form/AclButton',
	'idis/view/form/Button',
	'app/view/form/DisasterSelector',
	'app/view/form/LayerDirectorySelector',
	'app/view/form/OrganizationSelector',
	'app/view/form/UserSelector',
	'app/provide/ProvidePreventionRegisterDialog',
	'app/view/form/CustomizableMunicipalitySelector',
	'./DisasterPreventionStatusDetailPage',
	'./DisasterPreventionStatusRegisterPage',
	'./DisasterPreventionStatusListGrid'
], function(module, declare, lang, json, template, UserInfo, Router, IdisRest, DialogChain,
	_PageBase, config, Loader, USER_TYPE, Locator) {

	/**
	 * 検索条件保管store
	 */
	var disasterPreventionStatusListFilterStore = {};

	return declare(module.id.replace(/\//g, '.'), _PageBase,
		/** @lends module:app/disasterpreventionstatus/view/DisasterPreventionStatusListPage
		 * ~DisasterPreventionStatusListPage# */ {
		// テンプレート文字列
		templateString: template,

		// ルート要素に付与されるCSS
		baseClass: 'idis-Page idis-Page--disasterpreventionstatus',

		/**
		 * データ格納用オブジェクト
		 * @type {module:dstore/Store}
		 */
		store: null,

		chain: null,

		/**
		 * 自治体コード(デフォルト：道庁所在市町村)
		 */
		areaCd: config.municInfo.cityMunicCd,

		constructor: function() {
			// データ格納用オブジェクト
			this.store = new IdisRest({
				idProperty: 'prevStatusId',
				target: '/api/disasterPrevention/status'
			});
			this.chain = DialogChain.get(this);
		},

		postCreate: function() {
			this.own(this.municipalitySelector.on('change', lang.hitch(this, function() {
				this.updateGridQuery();
			})));
			this.own(this.disasterTypeSelect.on('change', lang.hitch(this, function() {
				this.updateGridQuery();
			})));
		},

		/**
		 * startup
		 */
		startup: function() {
			this.inherited(arguments);
			this.initForm();
			this.initGrid();
			this.initRegisterPage();
			this.initDetailPage();
		},

		/**
		 * グリッドを初期化する。
		 */
		initGrid: function() {
			// 検索条件を初期設定する
			// グリッドを更新する
			this.updateGridQuery();

			// グリッドの詳細ボタンクリック時の動作を設定する
			// helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
			this.grid.on('detailButtonClick', lang.hitch(this, function(evt) {
				// helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
				this.showDetailDialog(evt.item);
			}));
		},

		/**
		 * 登録ダイアログを表示する。
		 */
		showRegisterDialog: function() {
			this.innerDialog.initDialog(this);
			this.dialog.show();
		},

		/**
		 * 登録画面のフォームが投稿された際の動作を設定する。
		 */
		initRegisterPage: function() {
			// 登録ダイアログの最初の子要素が登録画面
			var page = this.dialog.getChildren()[0];
			page.initDialog(this);
			// 登録画面のsendイベントを受け取る
			page.on('post-send', lang.hitch(this, function(evt) {
				var sendData = evt.value;

				// 外部配信対象外の場合、共通の登録確認画面
				var self = this;
				this.chain.confirmAdd(function(chain) {
					// OKが押された場合
					// 追加処理と共にローディング表示
					Loader.wait(this.store.add(sendData)).then(function() {
						chain.infoComplete(function() {
							self.updateGridQuery();
							self.dialog.hide();
							chain.hide();
						});
					}, function(err) {
						// 失敗時
						if(err.response.data && typeof(err.response.data) === 'string'){
							err.response.data = json.parse(err.response.data);
						}
						chain.infoError(err);
					});
				});
			}));
		},

		/**
		 * 詳細ダイアログを表示する。
		 * @param {Object} item 参照する行のデータ
		 */
		showDetailDialog: function(item) {
			this.innerDetailDialog.initDialog(this, item);
			this.detailDialog.show();
		},

		/**
		 * 詳細ダイアログのフォームが投稿された際の動作を設定する。
		 */
		initDetailPage: function() {
			// 詳細ダイアログの最初の子要素が詳細画面
			var dialog = this.detailDialog;
			var page = dialog.getChildren()[0];
			// 詳細画面のsendイベントを受け取る
			page.on('put-send', lang.hitch(this, function(evt) {
				var sendData = evt.value;

				// 共通の更新確認画面
				this.chain.confirmPut(function(chain) {
					// OKが押された場合
					// 追加処理と共にローディング表示
					Loader.wait(this.store.put(sendData)).then(lang.hitch(this, function() {
						this.initGrid();
						chain.infoComplete(lang.hitch(this, function() {
							dialog.hide();
							chain.hide();
						}));
					}), function(err) {
						// 失敗時
						if(err.response.data && typeof(err.response.data) === 'string'){
							err.response.data = json.parse(err.response.data);
						}
						chain.infoError(err);
					});
				});
			}));

			// 詳細ダイアログの取消処理を受ける
			page.on('delete-send', lang.hitch(this, function(evt) {
				var sendData = evt.value;

				// 外部配信対象外の場合、共通の確認ダイアログを開く
				this.chain.confirmDel(lang.hitch(this, function(chain) {
					// OKなら取消リクエストを実施
					Loader.wait(this.store.remove(sendData.prevStatusId + '?version=' +
						sendData.version)).then(lang.hitch(this, function() {
						chain.infoComplete(lang.hitch(this, function() {
							// 取消成功時
							this.initGrid();
							// 各ダイアログを閉じる
							this.detailDialog.hide();
							chain.hide();
						}));
					}), function(err) {
						// 失敗時
						if(err.response.data && typeof(err.response.data) === 'string'){
							err.response.data = json.parse(err.response.data);
						}
						chain.infoError(err);
					});
				}));
			}));
		},

		/**
		 * グリッドの検索条件を指定された値で更新する。
		 * @param {Object} value name属性と値のマッピング
		 */
		updateGridQuery: function() {
			var value = this.form.get('value');
			// 入力値を元にグリッド用フィルターを作成
			var filter = new this.store.Filter();
			// 市町村 or 振興局
			if (value.areaCd) {
				filter = filter.eq('areaCd', value.areaCd);
			}
			// 災害種別
			if (value.disasterType) {
				filter = filter.eq('disasterType', value.disasterType);
			}
			// filterに対応するcollectionを取得
			var collection = this.store.filter(filter);
			// collectionをグリッドにセットする（サーバーにリクエストされる）
			this.grid.set('collection', collection);
			// 検索条件を保管する。
			this.setFilterToStore();
		},

		/**
		 * 検索ボタンが押されたときに呼び出される。
		 * テンプレートHTML内のFormウィジェットに対するdata-dojo-attach-eventでこのイベントを紐付けている。
		 * また、同Formウィジェットに対しdata-dojo-attach-pointを指定し、this.formからアクセス出来るようにしている。
		 */
		onSubmit: function() {
			try {
				if (this.form.isValid()) {
					// 入力値が正常ならグリッドの検索条件を更新
					this.updateGridQuery();
				}
			} catch (e) {
				console.error(e);
			} finally {
				return false;
			}
		},

		onBack: function(evt) {
			evt.preventDefault();
			var param = {};
			if (Locator.getQuery().areaCd) {
				param.areaCd = Locator.getQuery().areaCd;
			}
			if (Locator.getQuery().municipalityCd) {
				param.municipalityCd = Locator.getQuery().municipalityCd;
			}
			Router.moveTo(Locator.getQuery().from, param);
		},

		/**
		 * 検索条件が保管されている場合、storeから取得して検索条件を設定する。
		 * 検索条件が保管されていない項目は初期条件を設定する。
		 */
		initForm: function() {
			// 戻るためのリンクの表示文字
			if (Locator.getQuery().from === 'disasterprevention') {
				this.backLink.innerHTML = '体制状況報告概況';
			} else if (Locator.getQuery().from === 'disasterprevention/status') {
				this.backLink.innerHTML = '体制状況報告一覧';
			} else if (Locator.getQuery().from === 'disasterprevention/pref') {
				this.backLink.innerHTML = '体制状況報告概況（振興局）';
			} else if (Locator.getQuery().from === 'disasterprevention/status/pref') {
				this.backLink.innerHTML = '体制状況報告一覧（振興局）';
			}
			// 値にユーザの所属自治体を設定する(道・市町村 or 振興局)
			this.municipalitySelector.set('value',
				UserInfo.getMunicipalityCd() || UserInfo.getRegionCd());
			if (UserInfo.getUserType() === USER_TYPE.MUNICIPALITY) {
				//　市町村ユーザーの場合(政令指定都市を除く)編集不可
				if (UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd) {
					this.municipalitySelector.set('noAllButton', true);
				} else {
					//　政令指定都市ユーザーの場合市内を選択
					this.municipalitySelector.set('prefFlg', '0');
				}
			}
		},

		/**
		 * 検索条件をstoreに保管する。
		 */
		setFilterToStore: function() {
			// 変数の初期化
			disasterPreventionStatusListFilterStore = {};
			// 現在の検索条件
			var value = this.form.get('value');

			// 市町村 or 振興局
			if (value.areaCd) {
				disasterPreventionStatusListFilterStore.areaCd = value.areaCd;
			}
			// 報告日時
			if (value.disasterType) {
				disasterPreventionStatusListFilterStore.disasterType = value.disasterType;
			}
		}
	});
});
