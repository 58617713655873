/**
 * 災害情報取得用モジュール
 * @module app/model/RescueInfo
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/cookie',
    'dojo/topic',
    'idis/service/Requester'
], function(module, declare, lang, cookie, topic, Requester) {
    /**
     * 災害情報を管理するオブジェクト
     */
    return new declare(null, {

        /**
         * 災害情報に対するリクエスト結果
         * @type {Promise<Object>}
         */
        _promise: null,

        /**
         * 災害情報
         * @type {Object}
         */
        _rescueId: null,

        load: function() {
            if (!this._promise) {
                this._promise = Requester.get('/api/rescues/rescue4Cookie').then(lang.hitch(this, function() {
                    this._rescueId = cookie('DISASTER_ID');
                }));
            }
            return this._promise;
        },

        constructor: function() {
            this.inherited(arguments);
            // 災害名登録後に呼ばれる
            topic.subscribe('app/rescue/view/RescueAdminPage::registered',
                lang.hitch(this, function(payload) {
                    this.setRescueId(payload);
            }));
        },

        /**
         * 災害情報をCookieから取得する。
         */
        getRescueId: function() {
            this._rescueId = cookie('DISASTER_ID');
            return this._rescueId;
        },

        /**
         * 災害情報をCookieにセットする
         * @returns {string} 識別子
         */
        setRescueId: function(id) {
            this._rescueId = id;
            cookie('DISASTER_ID', id);
        }
    })();
});
