/**
 * よくある質問集画面用のモジュール
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/text!./templates/QuestionsPage.html',
    'idis/view/page/_PageBase'
], function (module, array, declare, template, _PageBase) {

    return declare(module.id.replace(/\//g, '.'), _PageBase, {

        // 基本クラス
        baseClass: 'idis-Page idis-Page--link',

        // テンプレート文字列
        templateString: template,

        /**
         * 質問、回答の一覧
         */
        questionList: [
            {
                name: '問1：操作中にエラーが発生しましたが、どうすればいいでしょうか。',
                children: [{
                    name: 'ヘルプデスクにお問合せください。'}]
            }, {
                name: '問2：災害名の変更ができません。変更ボタンがないようですが、どうしたら良いですか。',
                children: [{
                    name: '画面左上の「防災情報システム」のリンクをクリックしトップページに遷移すると変更ボタンが表示されます。'}]
            }, {
                name: '問3：過去の災害情報を確認することはできますか。',
                children: [{
                    name: 'トップページ上部で過去の災害名を選択することで確認することができます。'}]
            }, {
                name: '問4：別の災害について確認・入力を行いたい。',
                children: [{
                    name: 'トップページ上部で災害名を選択することで確認・入力することができます。'}]
            }, {
                name: '問5：入力したと報告のあった災害情報がシステム上に表示されません。',
                children: [{
                    name: 'システムヘッダーの災害名が正しく選択されているか確認してください。'}]
            }, {
                name: '問6：地図情報を表示しようとしたが、最新の情報が表示されません。 ',
                children: [{
                    name: 'PC をお使いの場合は、「Shift」キー、「F5」キーを同時に押して、キャッシュをクリアしてください。\nモバイル端末をお使いの場合は、ブラウザから閲覧履歴の削除をしてください。\n解消しない場合には、ヘルプデスクまでお問合せください。'}]
            }, {
                name: '問7：背景地図を変更することはできますか。',
                children: [{
                    name: '背景地図ボタンクリックにより以下の地図から選択が可能です。\n道路地図、標準地図、淡色地図、写真地図、衛星画像地図、OpenStreetMap'}]
            }, {
                name: '問8：災害モードが「宣言」で赤くなる時はどんな時ですか。',
                children: [{
                    name: '災害モード宣言が入力されている場合、赤く表示されます。'}]
            }, {
                name: '問9：配備体制で、体制状況が赤くなるのはどんな時ですか。',
                children: [{
                    name: '本部種別が「災害対策本部」で、対策本部設置状況が「設置」の場合に赤く表示されます。\n一覧画面の詳細ボタンの押下により、設定内容を確認できます。'}]
            }, {
                name: '問10：「自動承認モード」と画面に表示されているが、通常時と何が違うのでしょうか。どうやって解除するのでしょうか。',
                children: [{
                    name: '自動承認モード適用中に操作された避難所開設情報は、すべて本部確認完了として登録・更新されます。自動承認モードの開始および解除操作は、各区本部のユーザにのみ可能です。'}]
            }, {
                name: '問11：被害の種類で、対象の被害が選択できません。',
                children: [{
                    name: '利用者の所属により、登録できる被害が設定されています。利用者の所属が間違っている場合は、ヘルプデスクにお問合せください。'}]
            }, {
                name: '問12：利用者管理画面で、自分以外のユーザの情報を検索・閲覧できません。',
                children: [{
                    name: '一般職員ユーザの場合は、セキュリティ面やプライバシーを考慮し、自分の情報のみを閲覧・編集できるよう制御しています。'}]
            }, {
                name: '問13：帳票入力で読み込む帳票ファイルの項目列を削除したり、追加しても良いでしょうか。',
                children: [{
                    name: '帳票ファイルの項目列の削除や追加はせず、帳票出力したテンプレート形式のまま使用してください。'}]
            }, {
                name: '問14：帳票入力で読み込む帳票ファイルの入力内容を知りたいです。',
                children: [{
                    name: '帳票出力したエクセルファイルの2シート目の「入力方法」シートを参照ください。'}]
            }, {
                name: '問15：帳票入力時に「入力エラー」という画面が出て、エクセルファイルがダウンロードされました。このファイルはどういった物でしょうか。',
                children: [{
                    name: 'そのファイルには、帳票入力時にエラーの原因となった対象データが茶色く塗りつぶされて表示されます。帳票の「入力方法」シートを参考に、エラーの原因となった対象データの修正をしてください。'}]
            }, {
                name: '問16：スマートフォンアプリで登録した避難所情報・被害情報がwebで確認できません。',
                children: [{
                    name: '参照している災害名が異なっている可能性があります。スマートフォンアプリで選択した災害名と同じ災害名を選び、各機能から確認を行ってください。'}]
            }, {
                name: '問17：避難所状況で登録ボタンが表示されません。',
                children: [{
                    name: 'ユーザーの権限によって登録の可否が設定されています。'}]
            }, {
                name: '問18：個人所有のスマートフォンを利用しても良いでしょうか。',
                children: [{
                    name: '個人所有のスマートフォンを利用しても問題ありません。'}]
            },
        ],

        // DOMノードを構築する
        buildRendering: function () {
            this.inherited(arguments);
            var html = [];

            /**
             * 説明資料ファイルPATH
             */
            var FAIL_PATH = "/data/questions/";

            array.forEach(this.questionList, function (questionName) {
                html.push('<section><h2>');
                html.push(questionName.name);
                html.push('</h2>');
                array.forEach(questionName.children, function (item) {
                    html.push('<div><ul><li>');
                    var item = item.name;
                    while (item.includes('\n')) {
                        html.push(item.match(/^.+\n/));
                        html.push('<br>');
                        var lineNum = item.search(/\n.*/);
                        item = item.substr(lineNum + 1);
                    }
                    html.push(item);

                    if (item.includes('自分のユーザで何ができるのかを知りたい。')) {
                        var authzFilePath = FAIL_PATH + "userAuthList.xlsx";
                        html.push('<a href="');
                        html.push(authzFilePath);
                        html.push('" download="ユーザ種別・権限一覧.xlsx">ユーザ種別・権限一覧.xlsx</a>');
                        console.log(authzFilePath);
                    }

                    html.push('</li></ul></div></section>');
                });

            }, this);
            this.linkContainer.innerHTML = html.join('');
        }
    });
});
