/**
 * 招集グループ選択用パーツ。
 * @module app/view/form/ConvoGroupSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/dom-style',
    'dojo/_base/lang',
    'dojo/string',
    'dijit/form/TextBox',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'idis/view/form/TreeChecker'
], function(module, declare, domStyle, lang, dStr, TextBox,  CacheStoreModel, CacheTreeRest, TreeChecker) {
    /**
     * 招集グループ用パーツ。
     * @class UsersSelector
     * @extends module:idis/view/form/TreeChecker~TreeChecker
     */
    return declare(module.id.replace(/\//g, '.'), TreeChecker,
        /** @lends module:idis/view/tree/ConvoGroupSelector~ConvoGroupSelector# */ {
        // 選択ダイアログのタイトル
        title: '参集グループ選択',
        // ツリー・モデル
        model: null,
        // グループ種別
        groupType: null,

        // ページ初期表示にmodelがnullだとエラーが出るためmodelを生成
        constructor: function() {
            this._initModel();
        },
        // DOMを構築する
        buildRendering: function () {
            this.inherited(arguments);
            // 検索用入力要素を設置
            this.filterInput = new TextBox({
                placeHolder: this.placeHolder,
                maxLength: 128
            });
            this.filterInput.placeAt(this.treeControlNode);
            this.own(this.filterInput.on('keyup', lang.hitch(this, 'onFilterInputChange')));
        },
        
        _initModel: function(){
            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                target: '/api/convoMails/mails/convoMailsGroup4Tree'
                })
            });
        },
        
        // ダイアログを表示する度にmodelを呼び出すことで施設種別テーブルの変更点をツリーに反映
        showDialog: function() {
            if(this.treeParentNode && this.tree){
                // this.treeNode = this.tree.domNode;
                this.tree.destroyRecursive();
                this.treeParentNode.appendChild(this.treeNode);
                delete this.tree;
                delete this.model;
                this._initModel();
            } else {
                // 2回目の表示以降、treeを削除する際にtreeNodeが削除されてしまうので
                // 初期表示の段階で、ツリー表示時のtreeNode.parentNodeを記憶しておく
                this.treeParentNode = this.treeNode.parentNode;
            }
            return this.inherited(arguments);
        },

        /**
         * 選択した値の設定
         */
        _setValueAttr: function() {
            // TreeChecker本来の処理
            this.inherited(arguments);
            // 文字列省略をオーバーライド
            domStyle.set(this.status, {
                'max-width': '20em',
                'white-space': 'nowrap',
                'overflow': 'hidden',
                'text-overflow': 'ellipsis'
            });
        },
        /**
         * 検索用入力要素入力時。
         */
         onFilterInputChange: function () {
            // 入力値を取得
            var value = dStr.trim(this.filterInput.get('value'));
            if (value) {
                // ラベル名が入力値を含むものでフィルターし、ツリーを展開する
                this.model.setFilter(lang.hitch(this, function(item, isLeaf, model) {
                    return isLeaf && (model.getLabel(item) || '').indexOf(value) !== -1
                            // グループ種別が渡されていればその条件も追加
                            && (!this.groupType || this.groupType === item.groupType); 
                })).then(lang.hitch(this, function () {
                    this.tree.expandAll();
                }));
            } else {
                // 入力が空の場合
                if (this.groupType) {
                    // グループ種別が渡されていれば、グループ種別のみでフィルターをかける
                    this.model.setFilter(lang.hitch(this, function(item, isLeaf, model) {
                        return isLeaf && this.groupType === item.groupType; 
                    })).then(lang.hitch(this, function () {
                        this.tree.expandAll();
                    }));
                } else {
                    // グループ種別も設定されていなければ、フィルターを解除する
                    this.model.setFilter().then(lang.hitch(this, function () {
                        this.tree.collapseAll();
                    }));
                }
            }
        }
    });
});

