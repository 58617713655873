define({
    '01': '住家被害',
    '02': 'ライフライン（電気、水道等）',
    '03': '文教施設（学校等）' ,
    '04': '病院',
    '05': '庁舎等施設',
    '06': '土砂災害',
    '07': '火災',
    '08': '河川',
    '09': '道路',
    '10': '農地・農業用施設被害',
    '14': '林野被害',
    '15': '港湾被害',
    '11': 'その他被害',
    '12': '簡易報告',
    '13': '現地画像'
});
