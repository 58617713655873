/**
 * クリック可能なボタンを持つTreeNode拡張
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dijit/Tree',
    'idis/consts/QUERY',
    'idis/control/Locator',
    'idis/view/form/Button',
    './detail/DetailMap'
    // 以下、変数で受けないモジュール
], function(module, declare, lang, domClass, Tree, QUERY, Locator, Button, DetailMap) {
    /**
     * 現在の詳細ペインの表示状態に対する詳細ボタン用ラベルを返す。
     * @function _labelFromShowStatus
     * @param {boolean} showStatus 現在表示されているかどうか
     * @returns {string} 詳細ボタン用ラベル文字列
     * @private
     */
    function _labelFromShowStatus(showStatus) {
        return showStatus ? '閉じる' : '詳細';
    }

    /**
     * クリック可能なボタンを持つTreeNode拡張
     * @class LayerPaneTreeNode
     */
    return declare(module.id.replace(/\//g, '.'), Tree._TreeNode, {
        /**
         * 現在詳細ペインに表示されているかどうか
         * @type {boolean}
         * @private
         */
        _lastShowStatus: false,

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
            // 情報種別コードに応じて追加CSSクラスを付与（現地写真の表示制御等に利用）
            domClass.add(this.domNode, 'map-TreeNode-' + this.item.infoCategoryCd);
            // 情報種別コードに対応するウィジェットがあれば詳細ボタンを設置
            if (DetailMap[this.item.infoCategoryCd]) {
                this._lastShowStatus = this.isDetailShown();
                this.detailButton = new Button({
                    // 現在の表示状態によってラベルを設定
                    label: _labelFromShowStatus(this._lastShowStatus),
                    onClick: lang.hitch(this, this.onDetailButtonClick)
                });
                this.detailButton.placeAt(this.contentNode);
            }
        },

        // DOM構築後に呼ばれる
        postCreate: function() {
            // URL変更を監視
            if (this.detailButton) {
                this.own(Locator.on('change', lang.hitch(this, function() {
                    // URLの表示指定を確認
                    var showStatus = this.isDetailShown();
                    if (showStatus !== this._lastShowStatus) {
                        // 表示指定が変わっていればラベルを更新
                        this.detailButton.set('label', _labelFromShowStatus(showStatus));
                        // 前回の表示指定として保存
                        this._lastShowStatus = showStatus;
                    }
                })));
            }
        },

        /**
         * URLの状態から現在詳細ペインが表示されているかを返す。
         * @returns {boolean} 現在詳細ボタンが表示されているか
         */
        isDetailShown: function() {
            return Locator.getQuery()[QUERY.DETAIL_LAYER_ID] === this.item.id + '';
        },

        /**
         * 詳細ボタンがクリックされた際に呼ばれる。
         * @param {MouseEvent} evt クリック・イベント
         */
        onDetailButtonClick: function(evt) {
            evt.stopPropagation();
            // 現在表示されていれば隠し、そうでなければ表示するようURLを更新
            Locator.replaceState(QUERY.DETAIL_LAYER_ID, this.isDetailShown() ? '' : this.item.id);
        }
    });
});

