/**
 * ダム諸量情報の一覧ページ
 * @module app/dam/DamPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/DamPage.html',
    'dojo/topic',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/store/IdisRest',
    'idis/view/page/_PageBase',
    'app/observation/view/form/DateTimeSelect',
    'app/observationstation/model/DataKind',
    //
    'dijit/form/Select',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button',
    'app/dam/grid/DamPageGrid',
    'app/view/page/StatisticsDataDialog',
    'app/view/form/RegionMuni4ObservatorySelector',
    './DamLegend'
], function(module, declare, lang, template, topic,
    Router, Requester, IdisRest, _PageBase,
    DateTimeSelect, DataKind) {

    var _apiUrlPrefix = '/api/dam';

    /**
     * ダム諸量情報一覧画面
     * @class DamPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    	/** @lends module:aapp/dam/DamPage~DamPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--dam',

        // グリッド用ストア
        store: null,

        /**
         * 時間モード
         * {string} '10': 10分 '60': 正時
         */
        mode: '10',

        /**
         * 前回のデータ取得日時
         * {string} 'yyyy-MM-dd HH:mm'
         */
        lastRequestDateTime: '',

        constructor: function() {
            this.inherited(arguments);
            this.store = new IdisRest({
                idProperty: 'damQuantitiesId',
                target: _apiUrlPrefix + '/'
            });
        },

        buildRendering: function() {
            this.inherited(arguments);

            this.setLatestDatetime();
            this.initGrid();
        },

        /**
         * DOM構築後に呼ばれる
         */
        postCreate: function() {
            this.inherited(arguments);

            // 日時の変更を監視
            this.own(topic.subscribe(DateTimeSelect.TOPIC.CHANGE_DATE_TIME, lang.hitch(this, this.onChangeDateTime)));

            // 最新ボタンのクリックが押されたら
            this.own(topic.subscribe(DateTimeSelect.TOPIC.CLICK_LATEST, lang.hitch(this, this.onClickLatest)));
        },

        /**
         * gridの初期化
         *
         * 詳細ボタンクリック時の動作を規定している
         */
        initGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.grid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.onDetailButtonClick(evt.item);
            }));
        },

        /**
         * 時刻が変更された時の動作
         * @param {*} datetime
         */
        onChangeDateTime: function(datetime) {
            // 1回の操作で日付と時間が同時に変更される場合があり, このとき同じ日時で2回続けて変更イベントが発行される
            // 同じ日時で連続でデータ取得のリクエストが飛ぶことを避けるため, 前回の日時を保持し、異なる場合のみデータを取得するようにする
            if (this.lastRequestDateTime === (datetime.date + ' ' + datetime.time)) {
                return;
            }
            // 地域が選択されている場合があるためフィルターを設定し、グリッドにセット
            var filter = this.updateFilter(this.areaSelector.value, 0);

            // IEでDate生成時にエラーとなるのを回避
            var dateStr = datetime.date.replace(/-/g, '/') + ' ' + datetime.time;
            var date = new Date(dateStr).getTime();

            // 観測日時をフィルターにセット
            filter = filter.eq('date', date);

            var collection = this.store.filter(filter);
            this.grid.set('collection',collection);

            this.lastRequestDateTime = datetime.date + ' ' + datetime.time;
        },

        /**
         * ロード時に最新時刻を取得して時刻選択パーツにセット
         * @param {*} value
         */
        setLatestDatetime: function() {
            // 日付セレクトボックスを生成・設置する
            // 初期表示は、時間モード10分で最新の時間データ
            Requester.get(_apiUrlPrefix + '/latestDataTime?timeMode=' + this.mode)
            .then(lang.hitch(this, function(date) {
                this.dateTimeSelect = new DateTimeSelect({
                    to: date
                });
                this.dateTimeSelect.placeAt(this.dateTimeSelectNode, 'only');
                this.borderContainer.resize();

            }), lang.hitch(this, function() {
                this.dateTimeSelect = new DateTimeSelect();
                this.dateTimeSelect.placeAt(this.dateTimeSelectNode, 'only');
            }));
        },

        /**
         * 最新ボタンが押された時の動作
         */
        onClickLatest: function() {
            Requester.get(_apiUrlPrefix + '/latestDataTime?timeMode=' + this.mode)
            .then(lang.hitch(this, function(date) {
                this.dateTimeSelect.rebuild(date);

            }), lang.hitch(this, function() {
                this.dateTimeSelect.rebuild(new Date());
            }));
        },

        /**
         * 時間モードが変更された際に呼ばれる
         */
        onChangeModeSelect: function(value) {
            this.mode = value;
            // 観測時間選択の表示を切り替える
            this.dateTimeSelect.changeMode(value);
        },

        /**
         * 地域セレクトボックスが変更された際に呼ばれる
         */
        onChangeRegionSelect: function(evt) {
            var filter = this.updateFilter(evt.value,1);
            this.grid.set('collection', this.store.filter(filter));
        },

        /**
         * ストアのフィルターを生成する
         * @param {string} 地域コード または 流域コード. 指定されない場合は全選択.
         * @param {string} 時間変更フラグ. 1の場合は時間が変更されていないため、最後に選択された時刻でRequest.
         * @returns {dstore.Filter}
         */
        updateFilter: function(value,flg) {
            var filter = new this.store.Filter();

            // 地域/市町村セレクタ
            switch (this.areaSelector.getAreaType()) {
                case 'REGION':          //地域を選択
                    filter = filter.eq('regionCd', value);
                    break;
                case 'MUNICIPALITY':    // 市町村を選択
                    filter = filter.eq('municipalityCd', value);
                    break;
            }
            if(flg === 1){
                // 観測日時をフィルターにセット
                var date = new Date(this.lastRequestDateTime).getTime();
                filter = filter.eq('date', date);
            }
            return filter;
        },

        /**
         * グリッドの詳細ボタンを押した時の動作
         * @param {*} グリッド1行分の観測データ
         */
        onDetailButtonClick: function(object) {
            // 現在の表示時刻を引き継いで
            var date = new Date(this.lastRequestDateTime).getTime();

            // 詳細画面へ遷移
            Router.moveTo('observation/dam/detail', {
                observatoryId : object.damId,
                date : date,
                timeMode : this.mode
            });
        },

        /**
         * 観測情報ダウンロードボタンを押した時の動作
         */
        onWorksheetDownloadButtonClick: function() {
            console.debug('[統計情報ダウンロード]ボタンがクリックされました。');
            // ダイアログの最初の子要素が登録画面
            var dialog = this.statisticsDataDialog.getChildren()[0];
            dialog.setInitKind(DataKind.DAM);
            this.statisticsDataDialog.set({title: '統計情報'});
            this.statisticsDataDialog.show();
        }
    });
});
