/**
* 気象情報一覧画面用モジュール。
* @module app/weatherinfo/view/WeatherInfoTimeLineGrid
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/dom-class',
    'dgrid/ColumnSet',
    'idis/view/grid/MultiRowIdisGrid',
    // 以下、変数として受け取らないモジュール
    'dojox/lang/functional/array'
], function(module, declare, domClass, ColumnSet, MultiRowIdisGrid) {
    return declare(module.id.replace(/\//g, '.'), [MultiRowIdisGrid, ColumnSet], {
        // DOM構築時に呼ばれる
        buildRendering: function() {
            this.inherited(arguments);
            domClass.add(this.domNode, 'weatherinfo-WeatherInfoTimeLinePageGrid');
        }
    });
});
