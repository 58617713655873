/**
 * 組織選択用入力パーツ
 * @module app/view/form/EarthQuakeSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/form/TreeSelector',
    'app/model/EarthQuake'
], function(module, declare, TreeSelector, EarthQuake) {
    /**
     * 組織選択用パーツ。
     * @class EarthQuakeSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:app/view/form/EarthQuakeSelector~EarthQuakeSelector# */ {
        // 選択ダイアログのタイトル
        title: '地震情報',

        // ツリー・モデル
        model: EarthQuake
    });
});
