/**
 * 被災対応状況の追加ダイアログ。
 * @module app/traffic/view/TrafficActionDetailPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/TrafficActionDetailPage.html',
    'idis/view/page/_PageBase',
    // 以下、変数から参照されないモジュール
    'dijit/form/Form',
    'dijit/form/TextBox',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput'
], function(module, declare, template, _PageBase) {
    /**
     * 被災対応状況の追加ダイアログ。
     * @class TrafficActionDetailPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/TrafficActionDetailPage~TrafficActionDetailPage# */
        {
            // テンプレート文字列
            templateString: template,

            /**
             * 対応状況を新規登録する。
             */
            registerTrafficAction: function() {
                try {
                    if (this.form.validate()) {
                        // 入力チェックが成功したらaddイベントを発行
                        // 実際の登録処理はサンプル画面側でaddイベントを監視しているメソッドが実施
                        this.emit('register', {
                            // テンプレートHTML内でformに対し
                            // data-dojo-attach-point="form"を指定してthis.formとしてアクセス出来るようにしている
                            value: this.form.get('value')
                        });
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;
            },

            /**
             * 対応状況を更新する。
             */
            updateTrafficAction: function() {
                try {
                    if (this.form.validate()) {
                        // 入力チェックが成功したらupdateイベントを発行
                        // 実際の登録処理はサンプル画面側でupdateイベントを監視しているメソッドが実施
                        this.emit('update', {
                            // テンプレートHTML内でformに対し
                            // data-dojo-attach-point="form"を指定してthis.formとしてアクセス出来るようにしている
                            value: this.form.get('value')
                        });
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;

            },

            /**
             * 対応状況を削除する。
             */
            deleteTrafficAction: function() {
                try {
                    if (this.form.validate()) {
                        // 入力チェックが成功したらdeleteイベントを発行
                        // 実際の登録処理はサンプル画面側でdeleteイベントを監視しているメソッドが実施
                        this.emit('delete', {
                            // テンプレートHTML内でformに対し
                            // data-dojo-attach-point="form"を指定してthis.formとしてアクセス出来るようにしている
                            value: this.form.get('value')
                        });
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;
            }

        });
});
