/**
 * お知らせ表示用パーツ。
 * スタイル情報は css/app/map/NoticePanel.css で定義。
 * @module app/map/notice/NoticePanel
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/fx',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/dom-class',
    'dojo/dom-geometry',
    'dojo/dom-style',
    'dojo/string',
    'dojo/text!./templates/Notice.html',
    'dojo/text!./templates/NoticePanel.html',
    'dojo/topic',
    'dstore/Memory',
    'idis/store/IdisRest',
    'idis/view/_IdisWidgetBase',
    'app/notice/NoticeFormatter',
    'app/notice/NoticeTypeModel',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/dialog/ConfirmDialog',
    'idis/view/form/Button',
    './MarqueePanel',
    './NoticePanelGrid'
], function(module, array, declare, fx, lang, locale, domClass, domGeom, domStyle,
    string, noticeTemplate, template, topic,
    Memory, IdisRest, _IdisWidgetBase, NoticeFormatter, NoticeTypeModel) {
    /**
     * 2つのお知らせ一覧の内容が同じ内容であるかどうかを返す。
     * @param {Object{}} nl1 お知らせ一覧1
     * @param {Object{}} nl2 お知らせ一覧2
     * @returns {boolean} 2つのお知らせ一覧が同一の内容かどうか
     */
    function _isSameNoticeList(nl1, nl2) {
        return nl1 && nl2 && nl1.length === nl2.length && array.every(nl1, function(n, i) {
            return n.noticeId === nl2[i].noticeId;
        });
    }

    /**
     * トピック一覧
     */
    var TOPIC = {
        CHANGE_SETTING: module.id + '::changeSetting'
    };

    /**
     * @class NoticePanel
     */
    var NoticePanel = declare(module.id.replace(/\//g, '.'), _IdisWidgetBase, {
        // テンプレート文字列
        templateString: template,

        // ウィジェットのルート要素に付与されるCSSクラス
        baseClass: 'map-NoticePanel',

        /**
         * お知らせ一覧を管理するオブジェクト
         * @type {dstore/Collection}
         */
        collection: null,

        /**
         * 展開時のサイズ（開閉アニメーション用）
         * @type {Object}
         */
        _openDomSize: null,

        /**
         * 最後の開閉アニメーション動作。
         * @type {Animation}
         * @private
         */
        _lastAnimation: null,

        /**
         * 公開するお知らせがあるか
         * @type {boolean}
         * @private
         */
        _hasNotice: false,

        /**
         * お知らせ種別の識別子と内容
         * @type {Object<identifier,Object>}
         * @private
         */
        _noticeTypeMap: null,

        constructor: function() {
            // データ格納用オブジェクト
            this.collection = new IdisRest({
                idProperty: 'noticeId',
                target: '/api/notices'
            }).filter({pubFlg: 1});
        },

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.settingDialog);
            this.grid.set('collection', this.collection);
            // 種別一覧を取得
            NoticeTypeModel.load().then(lang.hitch(this, function(res) {
                // 取得した種別一覧を保持
                this._noticeTypeMap = {};
                array.forEach(res.items, function(item) {
                    this._noticeTypeMap[item.noticeTypeId] = item;
                }, this);
                // お知らせ一覧を取得
                this.loadNotice();
            }));
        },

        // DOM構築後に呼ばれる
        postCreate: function() {
            this.inherited(arguments);
            // クリックしたとき
            this.on('click', lang.hitch(this, function() {
                if (this.stackContainer.selectedChildWidget === this.gridPane) {
                    // グリッド選択時はお知らせ取得状況に応じてグリッド以外を選択
                    if (this._hasNotice) {
                        this.selectMarqueePane();
                    } else {
                        this.selectMessagePane();
                    }
                } else {
                    // グリッド以外選択時はグリッドを選択
                    this.selectGridPane();
                }
            }));
            // カーソルが置かれたときはスクロールを一時停止
            this.marqueePane.on('mouseover', lang.hitch(this, function() {
                this.marqueePane.pauseAnimation();
            }));
            // カーソルが外れたときはスクロールを再開
            this.on('mouseleave', lang.hitch(this, function() {
                this.marqueePane.playAnimation();
            }));
            // 設定更新時
            this.own(topic.subscribe(TOPIC.CHANGE_SETTING, lang.hitch(this, function(payload) {
                // フォント・サイズを更新
                domStyle.set(this.marqueePane.containerNode, 'font-size', payload.fontSize + 'px');
                this.marqueePane.saveContainerSize();
                // マーキーを開始
                this.selectMarqueePane();
            })));
        },

        startup: function() {
            this.inherited(arguments);
            if (!this._hasNotice) {
                this.messagePane.resize(domGeom.position(this.messageNode));
            }
        },

        // ウィジェットを破棄する
        destroy: function() {
            // 直前のアニメーションを破棄
            if (this._lastAnimation) {
                this._lastAnimation.destroy();
            }
            this.inherited(arguments);
        },

        /**
         * お知らせ一覧を読み込む。
         */
        loadNotice: function() {
            if (this._destroyed || !this.domNode) {
                // ウィジェットが破棄済みの場合は何もしない
                return;
            }
            this.collection.fetchRange({
                start: 0,
                end: 20
            }).then(lang.hitch(this, this.onFetchSuccess), lang.hitch(this, function(err) {
                // 失敗時はエラー出力
                console.error(err);
                this.setMessage('お知らせ情報の取得に失敗しました。');
            })).always(lang.hitch(this, function() {
                // 前回の結果が取得されてから一定時間待って再実行
                setTimeout(lang.hitch(this, 'loadNotice'), 60 * 1000);
            }));
        },

        /**
         * お知らせ内容表示領域の幅を取得する。
         * @returns {number} お知らせ内容表示領域の幅
         */
        getContainerWidth: function() {
            // 開閉ボタン以外はStackContainer内に入っているのでその幅を使う
            return domGeom.position(this.stackContainer.domNode).w;
        },

        /**
         * メッセージを設定する。
         */
        setMessage: function(message) {
            // メッセージを更新
            this.messageNode.innerHTML = message;
            // 表示中ならメッセージに合わせてリサイズ
            if (this.stackContainer.selectedChildWidget === this.messagePane) {
                this.messagePane.resize(domGeom.position(this.messageNode));
            }
        },

        /**
         * お知らせ内容としてメッセージを表示する。
         */
        selectMessagePane: function() {
            this.marqueePane.removeAnimation();
            // メッセージを選択してリサイズ
            this.stackContainer.selectChild(this.messagePane);
            this.messagePane.resize(domGeom.position(this.messageNode));
        },

        /**
         * お知らせ内容としてマーキーを選択する。
         */
        selectMarqueePane: function() {
            // マーキーを選択してリサイズ
            this.stackContainer.selectChild(this.marqueePane);
            this.marqueePane.resize({w: this.getContainerWidth()});
            if (!this.isClosed()) {
                // スクロールを開始する
                this.marqueePane.scrollToLeft();
            }
        },

        /**
         * お知らせ内容としてグリッドを表示する。
         */
        selectGridPane: function() {
            // スクロールを停止
            this.marqueePane.removeAnimation();
            // グリッドを選択してリサイズ
            this.stackContainer.selectChild(this.gridPane);
            this.gridPane.resize({w: this.getContainerWidth(), h: 300});
        },

        /**
         * このウィジェットが閉じているかどうかを返す。
         * @returns {boolean} このウィジェットが閉じているか
         */
        isClosed: function() {
            return domClass.contains(this.domNode, 'is-closed');
        },

        /**
         * 展開・格納ボタンがクリックされた際に呼ばれる。
         * @param {MouseEvent} evt クリック・イベント
         */
        onButtonClick: function(evt) {
            // 外へ波及させない（パネルの展開切り替えに拾わせない）
            evt.stopPropagation();
            // 直前のアニメーションを破棄
            if (this._lastAnimation) {
                this._lastAnimation.destroy();
            }
            // 初期状態で閉じているかどうか
            var isInitClosed = this.isClosed();
            if (!isInitClosed) {
                // 展開時のサイズを記憶
                this._openDomSize = domGeom.position(this.domNode);
            }
            // 開閉のCSSをトグル
            domClass.toggle(this.domNode, 'is-closed');
            // 開閉に合わせて更新フラグを解除
            domClass.remove(this.domNode, 'is-updated');
            // サイズ変更をアニメーションさせる（展開時のサイズが動的に決まるためCSSでなくJSで実施）
            fx.anim(this.domNode, {
                width: (isInitClosed ? this._openDomSize.w : 24),
                height: (isInitClosed ? this._openDomSize.h : 24)
            }, 200, null, lang.hitch(this, function(node) {
                // アニメーション終了時の動作
                if (isInitClosed) {
                    // 開いたときはサイズをCSS任せにする（ウィンドウサイズ変更に追随するため）
                    domStyle.set(node, {width: '', height: ''});
                    if (this.stackContainer.selectedChildWidget === this.gridPane) {
                        // グリッドを再選択
                        this.selectGridPane();
                    } else {
                        // グリッド以外選択時はお知らせ取得状況に応じて再選択
                        if (this._hasNotice) {
                            this.selectMarqueePane();
                        } else {
                            this.selectMessagePane();
                        }
                    }
                } else {
                    // スクロールを停止
                    this.marqueePane.removeAnimation();
                }
            }));
        },

        /**
         * 設定ボタンがクリックされた際に呼ばれる。
         * @param {MouseEvent} evt クリック・イベント
         */
        onSettingButtonClick: function(/* evt */) {
            this.settingDialog.show();
        },

        /**
         * 設定ダイアログでOKボタンがクリックされた際に呼ばれる。
         * @param {MouseEvent} evt クリック・イベント
         */
        onSettingOK: function(/* evt */) {
            topic.publish(TOPIC.CHANGE_SETTING, this.form.get('value'));
        },

        /**
         * お知らせ情報に対応するHTML文字列を生成する。
         * @param {Object} notice お知らせ情報
         * @param {string} notice.content お知らせ内容
         * @param {number} notice.noticeTimestamp お知らせ日時のUnix秒
         * @returns {string} お知らせに対応するHTML文字列
         */
        _formatNotice: function(notice) {
            // タイムスタンプは分単位
            var timestamp = locale.format(new Date(notice.noticeTimestamp), {
                datePattern: 'y/MM/dd',
                timePattern: 'HH:mm'
            });
            // お知らせ情報からHTML文字列を生成
            var noticeType = this._noticeTypeMap[notice.noticeTypeId] || {};
            var params = lang.mixin(null, notice, {
                // 内容はエスケープ
                content: NoticeFormatter.format(notice.content),
                // 種別コードから文字列表現と色を設定
                noticeTypeName: noticeType.noticeTypeName || '?',
                color: noticeType.color || '',
                // タイムスタンプは上で加工したものを利用
                timestamp: timestamp
            });
            return string.substitute(noticeTemplate, params);
        },

        /**
         * お知らせ一覧取得完了後に呼ばれる。
         * @param {dstore/QueryResults}
         */
        onFetchSuccess: function(noticeList) {
            if (this._destroyed || !this.domNode) {
                // ウィジェットが破棄済みの場合は何もしない
                return;
            }
            if (_isSameNoticeList(this._lastNoticeList, noticeList)) {
                // 結果が前回と変わっていなければ何もしない
                console.debug(module.id + '#onFetchSuccess: no updates');
                return;
            }
            // 結果が変わった場合
            this._lastNoticeList = noticeList;
            // グリッドを更新
            this.grid.set('collection', this.collection);
            this._hasNotice = !!noticeList.length;
            if (this._hasNotice) {
                // 各お知らせをHTML化して結合
                var htmlList = array.map(noticeList, this._formatNotice, this);
                htmlList.unshift('<div class="map-NoticePanel-noticeList">');
                htmlList.push('</div>');
                // マーキーに設定して選択
                this.marqueePane.setContent(htmlList.join(''));
                // 閉じていた場合は更新フラグを立てる
                if (this.isClosed()) {
                    domClass.add(this.domNode, 'is-updated');
                }
                // グリッド表示中でなければマーキーに切り替える
                if (this.stackContainer.selectedChildWidget !== this.gridPane) {
                    this.selectMarqueePane();
                }
            } else {
                this.setMessage('お知らせはありません。');
            }
        }
    });

    NoticePanel.TOPIC = TOPIC;

    return NoticePanel;
});
