/**
 * チャットユーザ更新用ダイアログモジュール。
 * @module app/chat/ChatUserDetailDialog
 */
define([
    'module',
    'leaflet',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/ChatUserDetailDialog.html',
    './_ChatUserDialogBase',
    'idis/model/UserInfo',
    'idis/service/Requester',
    // 以下、変数で受けないモジュール
    'idis/view/form/RadioGroup',
    'idis/view/form/WordCountTextarea'
], function(module, leaflet, declare, lang, domStyle, template, _ChatUserDialogBase, UserInfo, Requester) {
    
    return declare(module.id.replace(/\//g, '.'), _ChatUserDialogBase,
    {
        // テンプレート文字列
        templateString: template,

        constructor: function() {
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        // バージョン
        _version: null,

        /**
         * フォームを初期化する。
         */
        initForm: function (object) {
            this._chatGroupDestId = object.chatGroupDestId;
            this._chatGroupId = object.chatGroupId;
            this._userId = object.userId;
            this._municipalityName = object.municipalityName;
            this._organizationName = object.organizationName;
            this._userName = object.userName;
            this._emailAddress = object.emailAddress;
            this._sendRoleType = object.sendRoleType;
            this._autoUpdateFlg = object.autoUpdateFlg;
            
            var self = this;
            var page = this.form;
            Requester.get('/api/chat/user/' + this._chatGroupDestId)
            .then(function(data) {
                var item = data;
                page.set('value', item);
                self._version = item.version;
            });
            // 訓練モードの場合、削除・更新ボタンを非表示とする
            if(UserInfo.isTrainingFlg()){
                domStyle.set(this.deleteButton.domNode, 'display', 'none');
                domStyle.set(this.submitButton.domNode, 'display', 'none');
            }
        },

        /**
         * チャットグループを更新する。
         */
        onSubmit: function() {
            try {
                console.debug('[更新]ボタンがクリックされました。');

                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                // 固有のバリデーションを実施
                if (!this.validate()) {
                    return false;
                }

                var value = this.form.get('value');
                value.chatGroupDestId = this._chatGroupDestId;
                value.version = this._version;
                this.emit('update', { value: value });
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        onDelete:function(){
            console.debug('[削除]ボタンがクリックされました。');
            var value = this.form.get('value');
            value.chatGroupDestId = this._chatGroupDestId;
            value.version = this._version;
            this.emit('remove', {value: value});
        },

        /**
         * ダイアログを初期化する。（更新時）
         */
        initDialog: function(object) {
            this.reset();

            this.initForm(object);
            this.userId.innerHTML = this._userId ? this._userId : '';
            this.municipalityName.innerHTML = this._municipalityName ? this._municipalityName : '';
            this.organizationName.innerHTML = this._organizationName ? this._organizationName : '';
            this.userName.innerHTML = this._userName ? this._userName : '';
            this.emailAddress.innerHTML = this._emailAddress ? this._emailAddress : '';
            var autoUpdateFlg = '';
            switch (this._autoUpdateFlg) {
                case '0':
                    autoUpdateFlg = '対象外';
                    break;
                case '1':
                    autoUpdateFlg = '対象';
                    break;
                default:
                    break;
            }
            this.autoUpdateFlg.innerHTML = autoUpdateFlg;

        }

    });
});
