/**
 * 施設種別登録ダイアログ用モジュール。
 * @module app/facility/FacilityTypeRegisterDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/on',
    'dojo/text!./templates/FacilityTypeRegisterDialog.html',
    'app/facility/_FacilityTypeBase',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/ValidationTextBox',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/consts/ACL',
    'idis/view/form/AclButton',
    'dijit/layout/BorderContainer',
    'app/sample/view/SampleGrid',
    'dijit/form/RadioButton',
    'dijit/form/Select',
    'dijit/form/Form',
    'idis/view/form/DateTimeInput',
    'dijit/layout/AccordionContainer',
    'dijit/TitlePane',
    'dijit/form/Textarea',
    'app/view/form/ShelterSelector',
    'dijit/TitlePane',
    '../draw/DrawSelectIconDialog'
], function(module, declare, lang, domStyle, on, template, _FacilityTypeBase) {

    /**
     * 施設種別登録ダイアログ
     * @class FacilityTypeRegisterDialog
     * @extends module:app/facility/_FacilityTypeBase~_FacilityTypeBase
     */
    return declare(module.id.replace(/\//g, '.'), _FacilityTypeBase,
                   /** @lends module:app/facility/FacilityTypeRegisterDialog~FacilityTypeRegisterDialog# */ {
        // テンプレート文字列
        templateString: template,

        setPage: function(){
            this.form.reset();
            // デフォルトのアイコンをセット
            this.selectedIcon.src = this._defaultIconPath + '080.png';
            this._iconUrl= null;
            this.onChangeIcon();
        },

        onSubmit: function() {

            if(!this.form.validate()) {
                return false;
            }
            var value = this.form.get('value');
            delete value.selectedIcon;

            if (this._iconUrl) {
                value.iconUrl = this._iconUrl;
            } else {
                // 選択されていない場合デフォルトのアイコンURLをセットする
                value.iconUrl = '080.png';
            }
            delete value.facilityTypeIcon;

            this.emit('sendregister',{value : value});
            return false;
        }
    });
});
