define([
    // ここに書く

    'app/beginner/BeginnerLoginDialog',
    'app/beginner/BeginnerAdminPage',
    'app/beginner/ShelterDetail',
    'app/beginner/ReportDetail',
    'app/beginner/MapDetail',

    // ここまで
], function () { });