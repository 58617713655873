/**
 * Lアラート一覧画面用モジュール。
 * @module app/view/page/LalertPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, lang, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        columns: [
            helper.buttonColumn('lalertFilesId', '詳細'),
            lang.mixin(helper.column('reportDatetime', '発令時間'), {sortable: false}),
            lang.mixin(helper.column('infokind', '種別'), {sortable: false}),
            lang.mixin(helper.column('dbStatus', 'データ取り込み', {
                classMap: ['', 'is-failure'],
                formatMap: ['取り込み成功', '取り込み失敗']
            }),
            {sortable: false})
        ]
    });
});
