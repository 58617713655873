/**
 * ユーザーに紐付く市町村選択用入力パーツ
 * @module idis/view/form/RegionMuniNoWardSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/consts/USER_TYPE',
    'idis/model/UserInfo',
    'idis/view/form/TreeSelector',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    '../../config'
], function(module, declare, USER_TYPE, UserInfo, TreeSelector, CacheStoreModel, CacheTreeRest, config) {
    /**
     * 市町村選択用入力パーツ
     * @class FacilityTypeSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */

    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:idis/view/form/RegionMuniNoWardSelector~RegionMuniNoWardSelector# */ {
        // 選択ダイアログのタイトル
        title: '都道府県/地域/市町村',

        // ツリー・モデル
        model: null,

        constructor : function() {
            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                    target: '/api/municipalities/observationArea4Tree'
                })
            });
        },

        startup : function(){
            switch (UserInfo.getUserType()) {
                case USER_TYPE.MUNICIPALITY:
                    this.set('value', UserInfo.getMunicipalityCd());
                    break;
                case USER_TYPE.REGION:
                    this.set('value', UserInfo.getRegionCd());
                    break;
                default:
                    this.set('value', config.municInfo.prefCd);
                    break;
            }
        },

        // 選択中のエリアの種別を返す
        getAreaType: function(){
            if(this.value.length===0 ||
                    this.value===config.municInfo.prefCd) {
                return 'PREFECTURE';
            } else if (this.value.slice(0,3)===config.municInfo.prefRegCdGudge) {
                return 'REGION';
            } else {
                return 'MUNICIPALITY';
            }
        }
    });
});
