/**
 * 複数行グリッド用モジュール。
 * @module idis/view/grid/IdisGrid
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dijit/registry',
    'dgrid/OnDemandGrid',
    'dgrid/extensions/DijitRegistry'
], function(module, array, declare, lang, domClass, domConst, registry, OnDemandGrid, DijitRegistry) {
    /**
     * 1つのデータに対し複数行表示を行う場合に用いるグリッド。
     * @class IdisGrid
     * @extends module:dgrid/OnDemandGrid~OnDemandGrid
     * @extends module:dgrid/extensions/DijitRegistry~DijitRegistry
     */
    return declare(module.id.replace(/\//g, '.'), [OnDemandGrid, DijitRegistry],
                   /** @lends module:idis/view/grid/IdisGrid~IdisGrid# */ {
       /**
        * 条件に一致するデータが存在しない場合に表示されるメッセージ
        * @type {string}
        */
       noDataMessage: '条件に一致するデータが存在しません',

       /**
        * データ取得中に表示されるメッセージ
        * @type {string}
        */
       loadingMessage: 'データを取得中…',

       /**
        * フッター領域を表示する
        * @type {boolean}
        */
       showFooter: true,

       /**
        * 行データに付与するCSSクラス
        * 関数の場合は各行データに対する実行結果をCSSクラスとして追加する
        * @type {string|function}
        */
       rowClassName: null,

       // DOMノードを構築する
       buildRendering: function() {
           this.inherited(arguments);
           // 独自CSSクラスを追加
           domClass.add(this.domNode, 'idis-IdisGrid');
           // 総件数表示用のフッター領域
           this.totalNode = domConst.create('div', {
               className: 'idis-IdisGrid-totalRow',
               role: 'row',
               innerHTML: '&nbsp;'
           }, this.footerNode);
       },

       // DOMノードの準備が完了した際に呼ばれる
       postCreate: function() {
           this.inherited(arguments);
           // エラー発生時の処理
           this.on('dgrid-error', lang.hitch(this, this._onDgridError));
           // クリックへの反応
           this.on('.dgrid-row:click', lang.hitch(this, function(evt) {
               var row = this.row(evt);
               this.onRowClick(row.data, row);
           }));
       },

       /**
        * 全件数をグリッドのフッターに描画する。
        * 件数が0以外の偽値の場合は空欄にする。
        *
        * @type {number} total 表示する全件数
        */
       _updateTotalNode: function(total) {
           if (this.totalNode) {
               this.totalNode.innerHTML = (total || total === 0) ? ('全 ' + total + ' 件') : '&nbsp;';
           }
       },

       // クエリー結果を描画する
       renderQueryResults: function(results) {
           // 全件数が解決したらフッターを更新
           results.totalLength.then(lang.hitch(this, this._updateTotalNode));
           // 本来の処理を実施
           return this.inherited(arguments);
       },

       // コレクションに対する変更を監視する
       _observeCollection: function(collection) {
           // 元の処理
           var parentHandle = this.inherited(arguments);
           // 追加・削除・変更時に全件数を更新する
           var handle = collection.on('add, delete, update', lang.hitch(this, function () {
               this._updateTotalNode(this.get('total'));
           }));
           // 元のハンドルと今回のハンドルを除去する関数を持ったオブジェクトを返す
           return {
               remove: function() {
                   handle.remove();
                   parentHandle.remove();
               }
           };
       },

       /**
        * dgridのエラーが発生した際の処理。
        */
       _onDgridError: function(evt) {
           var message = 'エラーが発生しました: ';
           // エラー内容を格納
           var err = evt && evt.error;
           if (err && err.message) {
               message += err.message;
           }
           // 通信エラーを明示
           var res = err && err.response;
           if (res) {
               message = '通信' + message;
           }
           // エラー用のDOMノードを生成
           var errorNode = this.errorNode = domConst.create('div', {
               className: 'idis-grid-error',
               innerHTML: message
           });
           // グリッド上へ設置
           // TODO 成功後に破棄
           var parentNode = this.contentNode;
           parentNode.insertBefore(errorNode, this._getFirstRowSibling && this._getFirstRowSibling(parentNode));
       },

       /**
        * グリッドの行がクリックされた場合に呼ばれる。
        * @param {Object} item クリックされた行のデータ
        * @param {Object} row クリックされた行の情報
        */
       onRowClick: function(/* item, row */) {
           // do nothing
       },

       /**
        * 行データを描画するためのメソッド。
        * rowClassNameプロパティーが指定されている場合は行データ全体にCSSクラスを付与する。
        * なお、ヘッダー列はrenderHeaderを使うので、この関数には入って来ない。
        * @function module:idis/view/grid/IdisGrid~IdisGrid#~renderRow
        * @protected
        */
       renderRow: function(item) {
           var div = this.inherited(arguments);
           // rowClassNameが指定されている場合
           if (this.rowClassName) {
               var className = this.rowClassName;
               // 関数の場合は行データを与えて実行する
               if (lang.isFunction(className)) {
                   className = className(item);
               }
               // 偽値でなければCSSクラスとして追加
               if (className) {
                   domClass.add(div, className);
               }
           }
           return div;
       },

       /**
        * 行を削除する。
        * @param {Element} rowElement 1行分のDOMノード
        */
       removeRow: function(rowElement) {
           // セル内にあるウィジェットを削除する
           array.forEach(registry.findWidgets(rowElement), function(widget) {
               widget.destroyRecursive(false);
           });
           this.inherited(arguments);
       }
    });
});
