/**
 * 配信情報Lアラート内容確認ダイアログ（避難情報）
 * @module app/sending/LalertEvacOrderDialog
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/LalertEvacOrderDialog.html',
    'idis/view/dialog/DialogChain',
    'dojo/date/locale',
    'dojo/dom-style',
    'idis/service/Requester',
    'idis/view/page/_PageBase',
    // 以下、変数から参照されないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button',
    'idis/view/form/WordCountTextarea',
    'app/sending/EvacOrderGrid'
], function(module, array, declare, lang, template, DialogChain, locale, domStyle, Requester, _PageBase) {
    /**
     * 配信情報Lアラート内容確認ダイアログ（避難情報）
     * @class LalertEvacOrderDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/sending/LalertEvacOrderDialog~LalertEvacOrderDialog# */ {
        // テンプレート文字列
        templateString: template,

        baseClass: 'idis-Page idis-Page--sending',

        // グリッドに描画したデータ
        _lastRowList: null,

        constructor: function() {
            this.chain = DialogChain.get(this);
        },

        initDialog: function(obj){
            // 配信情報種別
            var _sendCategory = null;
            switch (obj.sendCategory) {
                case '01': _sendCategory = '避難情報'; break;
                case '02': _sendCategory = '開設避難所'; break;
                case '03': _sendCategory = '災害対策本部設置状況'; break;
                case '04': _sendCategory = 'お知らせ'; break;
                case '05': _sendCategory = '緊急速報メール情報'; break;
            }
            this.sendCategory.innerHTML = _sendCategory;

            // 更新種別
            this.distributionType.innerHTML = {
                    '01': '新規',
                    '02': '更新・訂正',
                    '03': '取消'
                }[obj.distributionType] || '-';

            // 発行元
            this.municipalityName.innerHTML = obj.municipalityName;

            // 補足情報
            this.complementaryInfo.innerHTML = obj.complementaryInfo;

            // 災害名
            Requester.get('/api/disasters/' + obj.disasterId, {
            }).then(lang.hitch(this, function(disasterObj) {
                this.disasterName.innerHTML = disasterObj.disasterName;
            }), lang.hitch(this, function() {
                // error
                this.disasterName.innerHTML = '';
            }));

            // 発令日時
            this.sendTimestamp.innerHTML = locale.format(new Date(obj.sendTimestamp));

            // 避難世帯数
            this.abstEvacHouseholdNum.innerHTML = obj.abstEvacHouseholdNum;

            // 避難者数
            this.abstEvaqueeNum.innerHTML = obj.abstEvaqueeNum;

            // 発令理由
            this.evacuateReason.innerHTML = obj.evacuateReason;

            // 前回グリッドに描画したデータを消去する
            if(this._lastRowList){
                array.forEach(this._lastRowList, function(rowNode){
                    this.evacOrderGrid.removeRow(rowNode , false);
                }, this);
            }

            // グリッドにデータを描画し、そのデータを保管しておく
            this._lastRowList = this.evacOrderGrid.renderArray(obj.evacOrderAreaList);

            this.container.resize();
        },

        onOKButton: function() {
            this.getParent().hide();
        }
    });
});
