/**
 * 配列関連のユーティリティー。
 * @module idis/util/ArrayUtils
 */
define([
    'module',
    'dojo/_base/array'
], function(module, array) {
    /**
     * 指定された値が配列にあるかどうかを返す。
     * @function contains
     * @param {Array<*>} arr 確認対象の配列
     * @param {*} value 探す値
     * @returns {boolean} 指定された値が配列にあればtrue、それ以外の場合はfalse
     * @example
     * ArrayUtils.contains(['a', 'b', 'c'], 'b'); // true
     */
    function _contains(arr, value) {
        return array.indexOf(arr, value) !== -1;
    }

    /**
     * 指定された要素を配列から取り除く。
     * @function _remove
     * @param {Array<*>} arr 除去対象の配列
     * @param {*} value 取り除く値
     * @example
     * var items = ['a', 'b', 'c'];
     * ArrayUtils.remove('b');
     * console.log(items); // ['a', 'c']
     */
    function _remove(arr, value) {
        if (array.indexOf(arr, value) !== -1) {
            arr.splice(array.indexOf(arr, value), 1);
        }
    }

    /**
     * 指定された配列をshallow copyする。
     * @param {Array<*>} arr
     * @returns {Array<*>} shallow copyされた配列
     */
    function _shallowCopy(arr) {
        return array.map(arr, function(item) { return item; });
    }

    /**
     * 指定された配列の中身をシャッフルする。
     * @param {Array<*>} arr シャッフル対象の配列
     * @example
     * var items = ['a', 'b', 'c'];
     * ArrayUtils.shuffle(items);
     * console.log(items); // ['b', 'a', 'c']
     */
    function _shuffle(arr) {
        var i, n, tmp;
        for (i = arr.length; i > 1 ; --i) {
            // 注目範囲からランダムに1つ指定
            n = Math.floor(Math.random() * i);
            // 指定した位置と注目範囲の末尾を入れ替える
            tmp = arr[i-1];
            arr[i-1] = arr[n];
            arr[n] = tmp;
            // 注目範囲を1つ手前に狭める
        }
    }

    return {
        contains: _contains,
        remove: _remove,
        shallowCopy: _shallowCopy,
        shuffle: _shuffle
    };
});
