define([
    'module',
    'dojo/_base/array',
    'dojo/_base/lang',
    'dojo/on',
    'dojox/lang/functional/object',
    '../../error/InvalidArgumentException',
    '../form/Button',
    '../../util/ArrayUtils',
    '../../util/DateUtils'
], function(module, array, lang, on, df, InvalidArgumentException, Button, ArrayUtils, DateUtils) {
    /**
     * フィールド名として許可されるパターン
     * @type {RegExp}
     * @private
     */
    var _FIELD_NAME_REGEXP = /^_?[a-zA-Z][a-zA-Z0-9]*$/;

    /**
     * 指定された値が配列またはオブジェクトかどうかを返す。
     * @param {*} value 判定対象
     * @returns {boolean} 引数が配列かオブジェクトならtrue、それ以外の場合はfalse
     */
    function _isDict(value) {
        return lang.isArray(value) || lang.isObject(value);
    }

    // helper.columnのオプションとして許容されるプロパティー
    var allowedColumnOptions = [
        'children',
        'classMap',
        'className',
        'formatMap',
        'formatter',
        'sortable',
        'editor',
        'autoSave',
        'editOn',
        'editorArgs'
    ];

    return {
        /**
         * 値をクラス名に変換する関数を返す。
         * @param {string} field フィールド名
         * @param {string[]|Object} dict 各値に対応する文字列のマッピング
         * @returns {function} 値に対応するクラス名を返す関数
         * @example
         * // 定数が0から連番で決まる場合
         * helper.classNameTo('human', ['', 'is-exist']);
         */
        classNameTo: function(field, dict) {
            // 引数チェック
            if (!_FIELD_NAME_REGEXP.test(field)) {
                throw new InvalidArgumentException(module.id + '#classNameTo: 不正なフィールド名です: field="' + field + '"');
            }
            if (!_isDict(dict)) {
                throw new InvalidArgumentException(module.id + '#classNameTo: オブジェクトか配列を渡してください: dict="' + dict + '"');
            }
            // クラス名生成関数を返却
            return function(item) {
                return item && dict[item[field]];
            };
        },

        /**
         * 値を対応する文字列に変換するフォーマッターを返す。
         * @param {string[]|Object} dict 各値に対応する文字列のマッピング
         * @param {string} [unknown=?] マッピングに存在しない値が来た場合に表示する文字列
         * @returns {function} 値に対応する文字列を返すフォーマッター
         * @example
         * // 定数が0から連番で決まる場合
         * helper.formatTo(['無し', '有り']);
         * // 定数が文字列コードで表される場合
         * helper.formatTo({'ID01': '道路', 'ID02': '河川'});
         */
        formatTo: function(dict, unknown) {
            // 引数チェック
            if (!_isDict(dict)) {
                throw new InvalidArgumentException(module.id + '#formatTo: オブジェクトか配列を渡してください: dict="' + dict + '"');
            }
            if (arguments.length > 1 && !lang.isString(unknown)) {
                throw new InvalidArgumentException(module.id + '#formatTo: 文字列を渡してください: unknown="' + unknown + '"');
            }
            // デフォルト値の適用
            if (arguments.length < 2) {
                unknown = '?';
            }
            // フォーマッターを返却
            return function(value) {
                var result = dict[value];
                return lang.isString(result) ? result : unknown;
            };
        },

        /**
         * グリッド用の列定義オブジェクトを返す。
         * @param {string} field フィールド名
         * @param {string} label タイトル行に表示する文字列
         * @param {Object} options
         * @param {string[]|Object<string,string>} [options.classMap] 値とクラス名の対応、classNameとの同時指定不可
         * @param {function} [options.className] 値に対応するクラス名を返す関数、classMapとの同時指定不可
         * @param {string[]|Object<string,string>} [options.formatMap] 値と表示文字列の対応、formatterとの同時指定不可
         * @param {function} [options.formatter] 値に対応する文字列を返す関数、formatMapとの同時指定不可
         * @returns {Object} dgridグリッドの列定義
         */
        column: function(field, label, options) {
            // 引数チェック
            var message;
            if (!_FIELD_NAME_REGEXP.test(field)) {
                message = '不正なフィールド名です: field="' + field + '"';
            } else if (!label || !lang.isString(label)) {
                message = '長さ1以上の文字列を渡してください: label="' + label + '"';
            } else if (options) {
                if (!lang.isObject(options)) {
                    message = 'オブジェクトを渡してください: options="' + options + '"';
                } else if (options.className && options.classMap) {
                    message = 'classNameとclassMapは同時に指定できません: field="' + field + '"';
                } else if (options.formatter && options.formatMap) {
                    message = 'formatterとformatMapは同時に指定できません: field="' + field + '"';
                } else if (options.classMap && !_isDict(options.classMap)) {
                    message = 'オブジェクトか配列を渡してください: options.classMap="' + options.classMap + '"';
                } else if (options.formatMap && !_isDict(options.formatMap)) {
                    message = 'オブジェクトか配列を渡してください: options.formatMap="' + options.formatMap + '"';
                } else if (options.formatter && !lang.isFunction(options.formatter)) {
                    message = '関数を渡してください: options.formatter="' + options.formatter + '"';
                } else {
                    var unknown = array.filter(df.keys(options), function(key) {
                        return !ArrayUtils.contains(allowedColumnOptions, key);
                    });
                    if (unknown.length) {
                        message = 'optionsに未知のプロパティーが含まれています: [\'' + unknown.join('\', \'') + '\']';
                    }
                }
            }
            if (message) {
                throw new InvalidArgumentException(module.id + '#column: ' + message);
            }
            options = options || {};
            return lang.mixin(null, options, {
                field: field,
                label: label,
                className: options.className || options.classMap && this.classNameTo(field, options.classMap),
                formatter: options.formatter || options.formatMap && this.formatTo(options.formatMap)
            });
        },

        /**
         * タイムスタンプ表示列の定義を生成する。
         * @param {string} field フィールド名
         * @param {string} label タイトル行に表示する文字列
         * @returns {Object} タイムスタンプ表示列の定義
         */
        timestampColumn: function(field, label) {
            return this.column(field, label, {formatter: DateUtils.format});
        },

        /**
         * ボタン利用列の定義を生成する。
         * 各ボタンをクリックすると、フィールド名に応じてグリッドのイベントが呼ばれる。
         * @param {string} field フィールド名
         * @param {string} label タイトル行に表示する文字列
         * @param {string} buttonLabel ボタンに表示される文字列
         * @returns {Object} ボタン利用列の定義
         * @example
         * // 列定義
         * column: [
         *     helper.buttonColumn('detail', '詳細', '詳細'),
         *     {field: 'human', …},
         *     …
         * ],
         * // 上記に対応するイベント・リスナーの登録（イベントは「フィールド名＋ButtonClick」という名前になる）
         * this.grid.on('detailButtonClick', function(evt) {
         *     // 対応する処理
         *     console.log(evt.item);
         * });
         */
        buttonColumn: function(field, label, buttonLabel) {
            // 引数チェック
            var message;
            if (!/^[a-zA-Z][a-zA-Z0-9]*$/.test(field)) {
                message = '不正なフィールド名です: field="' + field + '"';
            } else if (!label || !lang.isString(label)) {
                message = '長さ1以上の文字列を渡してください: label="' + label + '"';
            }
            if (message) {
                throw new InvalidArgumentException(module.id + '#buttonColumn: ' + message);
            }
            
            if(!buttonLabel) {
            	buttonLabel = label;
            }
            
            // カラムを返す
            return {
                field: field,
                label: label,
                sortable: false,
                renderCell: function(item) {
                    var gridNode = this.grid.domNode;
                    var button = new Button({
                        label: buttonLabel,
                        onClick: function() {
                            on.emit(gridNode, field + 'ButtonClick', {item: item});
                        }
                    });
                    // HTMLとしてウィジェットに紐付くDOMノードを返す
                    return button.domNode;
                }
            };
        }
    };
});
