/**
 * Session Storageを管理する。
 * @module idis/util/storage/SessionStorage
 */
define([
    'module',
    'dojo/_base/declare',
    './StorageManager'
], function(module, declare, StorageManager) {
    return new declare(StorageManager, {
        storage: sessionStorage
    })();
});
