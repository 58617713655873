/**
 * ダム諸量情報の詳細ページ
 * @module app/dam/DamDetailPage
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/DamDetailPage.html',
    'dojo/topic',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/store/IdisRest',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'app/observation/view/form/DateTimeSelect',
    'app/observationstation/model/DataKind',
    'app/dam/chart/DamHistoryChart',
    'app/riverBasin/chart/RiverBasinRainfallHistoryChart',
    'app/util/DateFormatter',
    //
    'dijit/form/Select',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'app/dam/grid/DamHistoryGrid',
    './DamLegend'
], function(
    module, array, declare, lang, domStyle, template, topic,
    Locator, Router, Requester, IdisRest, _PageBase, Loader,
    DateTimeSelect, DataKind, DamChart, RiverBasinChart, DateFormatter) {

    var _apiUrlPrefix = '/api/dam';

    /**
     * ダム諸量情報一覧画面
     * @class DamDetailPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    	/** @lends module:app/dam/DamDetailPage~DamDetailPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--dam',

        // 雨量水位グリッド用ストア
        historyStore: null,

        // 関連雨量観測局グリッド用ストア
        observatoryStore: null,

        /**
         * 時間モード
         * {string} '10': 10分 '60': 正時
         */
        mode: '10',

        // 変更フラグ
        changeFlg: 0,

        /**
         * 前回のデータ取得日時
         * {string} 'yyyy-MM-dd HH:mm'
         */
        lastRequestDateTime: '',

        constructor: function() {
            this.inherited(arguments);

            this.historyStore = new IdisRest({
                idProperty: 'damQuantitiesId',
                target: _apiUrlPrefix + '/detail'
            });
        },

        buildRendering: function() {
            this.inherited(arguments);

            this.setLatestDatetime();
        },

        /**
         * DOM構築後に呼ばれる
         */
        postCreate: function() {
            this.inherited(arguments);

            // 日時の変更を監視
            this.own(topic.subscribe(DateTimeSelect.TOPIC.CHANGE_DATE_TIME, lang.hitch(this, this.onChangeDateTime)));

            // 最新ボタンのクリックが押されたら
            this.own(topic.subscribe(DateTimeSelect.TOPIC.CLICK_LATEST, lang.hitch(this, this.onClickLatest)));

            // 観測局をセット
            this.initSelectBox();

            // グラフエリアを作る
            this.damChart = new DamChart();
            this.riverBasinChart = new RiverBasinChart();
            this.chartContainer.on('show', lang.hitch(this, function() {
                this.damChart.create(this.damChartNode, this.damLegendNode);
                this.riverBasinChart.create(this.riverBasinChartNode, this.riverBasinLegendNode);
            }));
        },

        /**
         * マスタデータ表記を初期化する。
         */
        initText: function(data) {
            if(data.totalLength !== 0){
                this.designFloodLevel.innerHTML = data[0].designFloodLevel || '-';
                this.normalFullWaterLevel.innerHTML = data[0].normalFullWaterLevel || '-';
                this.floodWaterLevel.innerHTML = data[0].floodWaterLevel || '-';
                this.minWaterLevel.innerHTML = data[0].minWaterLevel || '-';
                // 期間制限水位は値がある場合のみ表示
                domStyle.set(this.firstLimitWaterLevelTableRow, 'display', 'none');
                domStyle.set(this.secondLimitWaterLevelTableRow, 'display', 'none');
                domStyle.set(this.thirdLimitWaterLevelTableRow, 'display', 'none');
                var limitLevelLabel = [];
                if(data[0].firstLimitWaterLevel){
                    limitLevelLabel.push('(');
                    limitLevelLabel.push(DateFormatter.jpDate(data[0].firstLimitWaterLevelStart, false));
                    limitLevelLabel.push('～');
                    limitLevelLabel.push(DateFormatter.jpDate(data[0].firstLimitWaterLevelEnd, false));
                    limitLevelLabel.push(')');
                    this.firstLimitWaterLevel.innerHTML = data[0].firstLimitWaterLevel;
                    this.firstLimitWaterLevelPeriod.innerHTML = limitLevelLabel.join('');
                    domStyle.set(this.firstLimitWaterLevelTableRow, 'display', '');
                    if(data[0].secondLimitWaterLevel){
                        limitLevelLabel = [];
                        limitLevelLabel.push('(');
                        limitLevelLabel.push(DateFormatter.jpDate(data[0].secondLimitWaterLevelStart, false));
                        limitLevelLabel.push('～');
                        limitLevelLabel.push(DateFormatter.jpDate(data[0].secondLimitWaterLevelEnd, false));
                        limitLevelLabel.push(')');
                        this.secondLimitWaterLevel.innerHTML = data[0].secondLimitWaterLevel;
                        this.secondLimitWaterLevelPeriod.innerHTML = limitLevelLabel.join('');
                        domStyle.set(this.secondLimitWaterLevelTableRow, 'display', '');
                        if(data[0].thirdLimitWaterLevel){
                            limitLevelLabel = [];
                            limitLevelLabel.push('(');
                            limitLevelLabel.push(DateFormatter.jpDate(data[0].thirdLimitWaterLevelStart, false));
                            limitLevelLabel.push('～');
                            limitLevelLabel.push(DateFormatter.jpDate(data[0].thirdLimitWaterLevelEnd, false));
                            limitLevelLabel.push(')');
                            this.thirdLimitWaterLevel.innerHTML = data[0].thirdLimitWaterLevel;
                            this.thirdLimitWaterLevelPeriod.innerHTML = limitLevelLabel.join('');
                            domStyle.set(this.thirdLimitWaterLevelTableRow, 'display', '');
                        }
                    }
                }
                this.riverName.innerHTML = data[0].riverName;
                this.address.innerHTML = data[0].address;
                this.observatoryName.innerHTML = data[0].damName;
                this.riverSysName.innerHTML = data[0].riverSysName;

                // レイアウト崩れ対策のためリサイズを実施
                this.borderContainer.resize();
            }
        },

        /**
         * ロード時に最新時刻を取得して時刻選択パーツにセット
         * @param {*} value
         */
        setLatestDatetime: function() {
            // 日付セレクトボックスを生成・設置する
            // 初期表示は、時間モード10分で一覧で指定したデータか最新の時間データ
            var dateQuery = Number(Locator.getQuery().date);
            Requester.get(_apiUrlPrefix + '/latestDataTime?timeMode=' + this.mode)
            .then(lang.hitch(this, function(date) {
                this.dateTimeSelect = new DateTimeSelect({
                    to: dateQuery || date
                });
                this.dateTimeSelect.placeAt(this.dateTimeSelectNode, 'only');

            }), lang.hitch(this, function() {
                this.dateTimeSelect = new DateTimeSelect({
                    to: dateQuery
                });
                this.dateTimeSelect.placeAt(this.dateTimeSelectNode, 'only');
            }));
        },

        /**
         * 時刻が変更された時の動作
         * @param {*} datetime
         */
        onChangeDateTime: function(datetime) {
            // 1回の操作で日付と時間が同時に変更される場合があり, このとき同じ日時で2回続けて変更イベントが発行される
            // 同じ日時で連続でデータ取得のリクエストが飛ぶことを避けるため, 前回の日時を保持し、異なる場合のみデータを取得するようにする
            if (this.lastRequestDateTime === (datetime.date + ' ' + datetime.time)) {
                return;
            }

            // IEでDate生成時にエラーとなるのを回避
            var dateStr = datetime.date.replace(/-/g, '/') + ' ' + datetime.time;
            var date = new Date(dateStr).getTime();

            // ダム諸量推移グリッドの更新
            var historyFilter = new this.historyStore.Filter();
            historyFilter = historyFilter.eq('date', date);
            this.updateHistoryGrid(historyFilter);

            this.lastRequestDateTime = datetime.date + ' ' + datetime.time;
        },

        /**
         * 最新ボタンが押された時の動作
         */
        onClickLatest: function() {
            Requester.get(_apiUrlPrefix + '/latestDataTime?timeMode=' + this.mode)
            .then(lang.hitch(this, function(date) {
                this.dateTimeSelect.rebuild(date);

            }), lang.hitch(this, function() {
                this.dateTimeSelect.rebuild(new Date());
            }));
        },

        /**
         * 時間モードが変更された際に呼ばれる
         */
        onChangeModeSelect: function(value) {
			// 正時の場合、1時間モードとする
			this.mode = value === 'hourly' ? '60' : value;
            // 観測時間選択の表示を切り替える
            this.dateTimeSelect.changeMode(value);

            var timeModeStr = null;
            if(value === '10'){
                timeModeStr = '[10分モード]';
            }
            if(value === '60'){
                timeModeStr = '[正時モード]';
            }

            // 新しい[時間モード]で雨量推移グリッドの更新
            var historyFilter = new this.historyStore.Filter();

            // 観測日時をフィルターにセット
            var date = new Date(this.lastRequestDateTime).getTime();
            historyFilter = historyFilter.eq('date', date);

            // サーバーにリクエストする
            this.updateHistoryGrid(historyFilter);
        },

        /**
         * 雨量推移グリッド更新
         * @param {*} filter storeに対するfilterオブジェクト
         */
        updateHistoryGrid: function(filter) {
            filter = filter.eq('damId',
                this.observationStationsSelectBox.get('value') || Locator.getQuery().observatoryId);
            filter = filter.eq('timeMode', this.mode);
            var collection = this.historyStore.filter(filter);
            this.historyGrid.set('collection', collection);

            // グラフを再作成
            collection.fetch().then(lang.hitch(this, function(data) {
                if(data.totalLength !== 0){
                    this.initText(data);
                    this.initChart(data);
                }
            }));
        },

		onDamLinkClick: function(evt) {
			// ブラウザーの遷移処理をキャンセル
			evt.preventDefault();
			Router.moveTo('observation/dam');
		},

        /**
         * グラフを作成する
         * @param data 検索結果の観測データ
         */
        initChart: function(data){
            // ダムグラフ
            this.damChart.create(this.damChartNode, this.damLegendNode,
                {mode:this.mode, data:data});
            // 流域平均雨量グラフ
            this.riverBasinChart.create(this.riverBasinChartNode, this.riverBasinLegendNode,
                {mode:this.mode, data:data});
        },

		/**
		 * 時間モードの初期化を行う
		 */
		setTimeModeSelect: function(){
			var obsInfos = this.observationStationsSelectBox.options;
			var observatoryId = this.observationStationsSelectBox.get('value');
			array.forEach(obsInfos, function(item){
				if(item.value===observatoryId){
					var timeMode;
					// 時間観測局の場合、正時のみ
					if (item.isHourObs) {
						domStyle.set(this.timeModeSelect.domNode, 'display', 'none');
						domStyle.set(this.hourMode, 'display', '');
						timeMode = 'hourly';
					} else {
						domStyle.set(this.timeModeSelect.domNode, 'display', '');
						domStyle.set(this.hourMode, 'display', 'none');
						timeMode = this.timeModeSelect.get('value');
					}
					// 時間モードによって、グリッド更新
					this.onChangeModeSelect(timeMode);
				}
			}, this);
		},

        // セレクトボックスの初期化を行う
		initSelectBox: function(){
			// 観測局セレクトボックスの初期化
			var promise =
				Requester.get('/api/observationStation/selectBox/?dataKind='+ DataKind.DAM);
			Loader.wait(promise).then(lang.hitch(this, function(data) {
				this.observationStationsSelectBox.options = data;
                this.observationStationsSelectBox.set('value',Locator.getQuery().observatoryId);
				// 時間モードの初期化
				this.mode = Locator.getQuery().timeMode || this.mode;
				if(this.mode === '60'){
					this.timeModeSelect.set('value', this.mode);
				}
				// 時間モードをセット
				this.setTimeModeSelect();
            }));
		},

		/**
		 * [観測局]を変更し、表の更新を行う。
		 */
		onObservationStationChange: function(){
			if(this.changeFlg !== 0){
                // // 新しい[時間モード]で雨量推移グリッドの更新
                // var historyFilter = new this.historyStore.Filter();
                // // 観測日時をフィルターにセット
                // var date = new Date(this.lastRequestDateTime).getTime();
                // historyFilter = historyFilter.eq('date', date);
                // // グリッドを更新
                // this.updateHistoryGrid(historyFilter);
				// 時間モードをセット
				this.setTimeModeSelect();
			}
			this.changeFlg++;
		},

		// 一つ前の観測局を取得する
		onPreviousButtonClick: function() {
			var value = this.observationStationsSelectBox.get('value');
			var options = this.observationStationsSelectBox.options;
			// 最初の観測局の場合はなにもしない
			if(value !== options[0].value){
				array.forEach(this.observationStationsSelectBox.options, lang.hitch(this, function(option, i){
					if(option.value===value){
						this.observationStationsSelectBox.set('value', options[i - 1].value);
					}
				}));
			}
		},

		// 一つ後の観測局を取得する
		onNextButtonClick: function() {
            var value = this.observationStationsSelectBox.get('value');
			var options = this.observationStationsSelectBox.options;
			// 最後の観測局の場合はなにもしない
			if(value !== options[options.length - 1].value){
				array.forEach(this.observationStationsSelectBox.options, lang.hitch(this, function(option, i){
					if(option.value===value){
						this.observationStationsSelectBox.set('value', options[i + 1].value);
					}
				}));
			}
		}
    });
});
