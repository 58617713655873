/**
 * 水位共通カラム一覧
 * @module app/river/grid/RiverLevelObservationCommonColumns
 */
define([
    'app/view/grid/CommonColumn',
    'dojo/dom-construct',
    'dojo/dom-style',
    'idis/view/grid/helper'
    // 以下、変数で受けないモジュール
], function(CommonColumn, domConstruct, domStyle, helper) {
    var timeModeLabel = {
        '10': '10分',
        '30': '30分',
        '60': '時間'
    };
    var DIFFERENCE_IMG_PATH = {
        UP : '/images/observation/obs_wlev_up.png',
        DOWN : '/images/observation/obs_wlev_down.png'
    };
    var riverLevelFormatter = function(value, item) {
        return CommonColumn.formatObservationData(item, 'riverLevel', 2);
    };
    var riverLevelClassName = function(item) {
        // ヘッダーの場合はclassNameを返さない
        if (!item) { return; }
        return CommonColumn.getClassNameExcess(item, 'riverLevel');
    };
    var differenceCell = function(item) {
        var domNode = domConstruct.create('div');
        if(item.difference===null || Number(item.difference)===0){
            domNode.innerHTML = item.difference===null ? '-' : item.difference;
        } else {
            var image = new Image();
            image.src = item.difference>0 ?
                DIFFERENCE_IMG_PATH.UP : DIFFERENCE_IMG_PATH.DOWN;
            domConstruct.place(image, domNode);
            var differenceLabelNode = domConstruct.create('span');
            domStyle.set(differenceLabelNode, 'margin-left', item.difference>0?'1.3em':'1em');
            differenceLabelNode.innerHTML = item.difference;
            domConstruct.place(differenceLabelNode, domNode);
        }
        return domNode;
    };
    return {
        riverLevel: helper.column('riverLevel', '水位\n[m]', {
            formatter: riverLevelFormatter,
            className : riverLevelClassName
        }),
        riverLevelDetail: helper.column('riverLevel', '水位\n[m]', {
            formatter: riverLevelFormatter,
            className : riverLevelClassName,
            sortable : false
        }),
        difference: {
            field: 'difference',
            label: '差分\n[cm]',
            renderCell: differenceCell
        },
        differenceDetail: {
            field: 'difference',
            label: '差分\n[cm]',
            renderCell: differenceCell,
            sortable : false
        },
        changeColumnsLabel: function(mode){
            this.riverLevel.label = timeModeLabel[mode] + '水位\n[m]';
            this.difference.label = timeModeLabel[mode] + '差分\n[cm]';
        }
    };
});
