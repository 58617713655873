define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/DialogRadius.html',
    'idis/view/_IdisWidgetBase',
    'dijit/Dialog',
    'dojo/_base/lang',
    'dojo/topic',
    'dojo/on',
    'idis/view/dialog/DialogChain',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/TextBox',
    'idis/view/form/RadioGroup'
], function(module, declare, template, _IdisWidgetBase, Dialog, lang, topic, on, DialogChain) {

    var content = declare('_DrawEditDialogContent', _IdisWidgetBase, {
        templateString : template,
        baseClass: 'drawDialog-Container',
        widgetsInTemplate : true
    });

    var container = declare(module.id.replace(/\//g, '.'), [Dialog], {
        // Pub/SubのURL
        RESET_MODE_ID  : '/app/draw/DrawPanel::resetMode',
        RM_EDIT_EVT    : '/app/draw/DrawPanel::removeEditEvent',
        RADIUS_DIALOG  : '/app/draw/DrawPanel::drawRadius',

        //イベント破棄用
        _events: null,

        /**
         * コンストラクター
         */
        constructor : function(options){
            lang.mixin(this, options);

            this.title = '円の描画（半径指定）';
            this.inherited(arguments);
            this.inner = new content();
            this.chain = DialogChain.get(this);
            this._events = [];
        },

        /**
         * ウィジェットのプロパティ挿入後実行される
         */
        postMixInProperties : function(){
            this.inherited(arguments);
        },

        postCreate : function(){
            this.inherited(arguments);
            this.set('content', this.inner);
        },

        // Override
        show : function(mode, layerObj){
            this.inherited(arguments);
            this._init();
            // 既存イベントの破棄
            this._removeEvents();
            // 作図オブジェクトに対して編集中のイベントを定義
            this._setEvents(mode, layerObj);
        },

        // 編集中の作図オブジェクトに対するイベント定義
        _setEvents: function(mode, layerObj){
            // 「決定」ボタン
            var onClickOk = on(this.inner.ok, 'click', lang.hitch(this, function(){
                var radius = this.inner.hankei.value;
                var unit = this.inner.unitSwitch.value;

                if(this._inputCheck(radius)) {
                    var pubURL = this.RADIUS_DIALOG;
                    topic.publish(pubURL, {
                        'radius': radius,
                        'unit': unit,
                        'edit': false
                    });
                    this.hide();
                }
            }));

            // 「キャンセル」ボタン
            var onClickCancel = on(this.inner.cancel, 'click', lang.hitch(this, function(){
                if (mode === 'new') {
                    // removeはDrawPanelのFeatureGroupから取り除く
                    topic.publish(this.RESET_MODE_ID, layerObj);
                }
                this.hide();
            }));

            // ダイアログの「×」ボタン
            var onClickClose = on(this.closeButtonNode, 'click', lang.hitch(this, function(){
                if (mode === 'new') {
                    // removeはDrawPanelのFeatureGroupから取り除く
                    topic.publish(this.RESET_MODE_ID, layerObj);
                }
            }));

            // イベントを保持
            this._events.push(
                onClickOk, onClickCancel, onClickClose
            );

            // ダイアログがクローズされたら当該作図オブジェクトに対するイベントを破棄
            on(this, 'hide', lang.hitch(this, function() {
                this._removeEvents();
            }));

            // DrawPanelから呼び出されるイベント削除
            var removeEvents = topic.subscribe(this.RM_EDIT_EVT, lang.hitch(this, function() {
                this._removeEvents();
                removeEvents.remove();
            }));
        },

        /**
         * このウィジェットに対する全イベントを削除
         */
        _removeEvents: function(){
            for (var i = 0; i < this._events.length; i++) {
                if (this._events[i] !== null) {
                    this._events[i].remove();
                }
            }
            this._events = [];
        },

        _init: function() {
            // 開かれたときにCheckを入れておく
            this.inner.unitSwitch.getChildren()[0].checked = true;
            this.inner.unitSwitch.getChildren()[1].checked = false;
        },

        /**
         * 半径の入力チェック
         */
        _inputCheck: function(radius) {
            var regexp = /^([1-9]\d*|0)$/;

            if(radius === '') {
                this.chain.info('半径を入力してください。', 'エラー');
                return false;
            }

            if(!regexp.test(radius)) {
                this.chain.info('数値以外が入力されています。', 'エラー');
                return false;
            }

            if(Number(radius) === 0) {
                this.chain.info('1以上を入力してください。', 'エラー');
                return false;
            }

            return true;
        }
    });

    return container;

});
