/**
 * 現地写真新規詳細モジュール
 * @module app/view/page/PhotoUploadDetailPage
 */
 define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/on',
    'dojo/text!./templates/PhotoUploadDetailPage.html',
    'idis/view/page/_PageBase',
    'idis/view/dialog/InfoDialog',
    // 以下、変数として受け取らないモジュール
    'idis/view/form/Button',
    'idis/view/form/AclButton',
    'dijit/form/Textarea',
    'dijit/form/Select',
    '../../view/form/LayerDirectorySelector',
    '../../view/form/PubStatusSelector'
], function(module, declare, lang, domStyle, on, template, _PageBase, InfoDialog) {

    /**
     * 現地写真詳細
     * @class PhotoUploadDetailPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/PhotoUploadDetailPage~PhotoUploadDetailPage# */ {
        // テンプレート文字列
        templateString: template,

        startup: function() {
            // 公開範囲選択時に公開事業課エリアの表示/非表示を制御する
            this.own(on(this.pubStatus, 'change', lang.hitch(this, function(evt) {
                if(evt === '2') {
                    domStyle.set(this.pubSectCdArea, 'display', '');
                } else {
                    domStyle.set(this.pubSectCdArea, 'display', 'none');
                }
            })));
        },

        /**
         * 更新を実行する
         */
        onSubmit: function() {
            // formに定義されている情報を設定する
            var sendData = this.form.get('value');
            console.log(sendData);
            try {
                if (this.form.validate() && this.isPhotoDetailValid(sendData)) {
                    // 入力チェックが成功したらsendupdateイベントを発行
                    // 実際の登録処理はサンプル画面側でsendイベントを監視しているメソッドが実施
                    this.emit('sendupdate', {
                        value: sendData
                    });
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 削除を実行する
         */
        onDelete: function() {
            // formから現地写真IDを取得する
            var id = this.form.get('value').photoId;
            this.emit('senddelete', {
                // テンプレートHTML内でformに対し
                // data-dojo-attach-point="form"を指定してthis.formとしてアクセス出来るようにしている
                value: id
            });

            return false;
        },

        /**
         * 入力値をチェックする
         */
        isPhotoDetailValid: function(data) {
            if(data.comment.length > 150){
                InfoDialog.show('入力エラー', 'コメントは150字以下で入力してください。');
                return false;
            }
            return true;
        },

        /**
         * 画像を拡大表示する
         */
        onClick: function(){
            window.open(this.photoDispPath.innerText);
        },

        /**
         * データ格納領域を初期化し、データを設定する
         */
        setPage: function(object) {
            this.resetPage();
            this.organizationName.innerHTML = object.organizationName;
            this.photoTimestamp.innerHTML = object.photoTimestamp;
            this.photoLatitiude.innerHTML = object.photoLatitiude;
            this.photoLongitude.innerHTML = object.photoLongitude;
            if(object.subject === null){
                this.subject.innerHTML = '';
            } else {
                this.subject.innerHTML = object.subject;
            }
            this.photoPath.innerHTML = '<img style="height:80px;" src=' + object.photoPath + '>';
            this.photoDispPath.innerHTML = object.photoPath;

            // メール登録の場合、コメントに<br>が含まれる可能性がある為、置換する
            var replaceString = '<br />';
            var regExp = new RegExp(replaceString, 'g') ;
            var comment = object.comment.replace(regExp , '');
            this.comment.set('value', comment);

            // 格納先を設定する
            this.parentLayerId._setValueAttr(object.parentLayerId);
            // 公開範囲セレクトボックスを作成する
            this.pubStatus.createSelector(object.pubStatus);
            // 公開事業課セレクトボックスを作成する
            this.pubSectCd.createSelector(object.pubSectCd);
        },

        /**
         * データ格納領域を初期化する
         */
        resetPage: function() {
            this.organizationName.innerHTML = null;
            this.photoTimestamp.innerHTML = null;
            this.photoLatitiude.innerHTML = null;
            this.photoLongitude.innerHTML = null;
            this.comment.innerHTML = null;
            this.photoPath.innerHTML = null;
        }
    });
});
