/**
 * <流域平均雨量推移用グリッド>
 *
 * @module app/riverBasin/grid/RainfallHistoryGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    './RiverBasinCommonColumns'
], function(module, declare, IdisGrid, helper, RiverBasinCommonColumns) {
    /**
     * 流域平均雨量推移用グリッド
     *
     * @class RainfallHistoryGrid
     * @extends module:idis/view/grid/IdisGrid~IdisGrid
     */
    return declare(module.id.replace(/\//g, '.'), IdisGrid,
        /** @lends module:app/riverBasin/grid/RainfallHistoryGrid~RainfallHistoryGrid# */ {
        columns : [
			helper.column('days', '月日', {sortable: false }),
			helper.column('time', '時刻', {sortable: false }),
            RiverBasinCommonColumns.rainfall10Min,
            RiverBasinCommonColumns.rainfall60Min,
            RiverBasinCommonColumns.rainfallHourly,
            RiverBasinCommonColumns.rainfallCumulative
        ]
    });
});