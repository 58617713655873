define([], function(){
	return {
        getKmlDefFormat: function(type){
            //KML出力定義
            var kmlBaseDefault = '' +
                '<?xml version="1.0" encoding="UTF-8"?>' +
                '<kml xmlns="http://www.opengis.net/kml/2.2">' +
                '      <Document>%[0]%</Document>'+
                '</kml>';

            // ラインのスタイル出力定義
            var lineDefaultStyle = '\n' +
                '   <Style id="style%[0]%">\n' +
            	'      <LineStyle>\n' +
                '         <color>%[1]%</color>\n' +
                '         <width>%[2]%</width>\n' +
                '      </LineStyle>\n' +
            	'   </Style>\n';

            // ラインのフォルダ出力定義
            var lineDefaultFolder = '\n' +
            	'   <Folder>\n' +
                '      <Placemark>\n' +
                '         <name>%[0]%</name>\n' +
                '         <description>%[1]%</description>\n' +
                '         <styleUrl>#style%[2]%</styleUrl>\n' +
                '         <LineString>\n' +
                '            <coordinates>%[3]%</coordinates>\n' +
                '         </LineString>\n' +
                '      </Placemark>\n' +
            	'   </Folder>\n';

			// 矢印のフォルダ出力定義
            var arrowDefaultFolder = '\n' +
            	'   <Folder>\n' +
                '      <Placemark>\n' +
                '         <name>%[0]%</name>\n' +
                '         <description>%[1]%</description>\n' +
                '         <styleUrl>#style%[2]%</styleUrl>\n' +
                '         <LineString>\n' +
                '            <coordinates>%[3]%</coordinates>\n' +
                '         </LineString>\n' +
				'      </Placemark>\n' +
				'      <Placemark>\n' +
                '         <name>%[0]%</name>\n' +
                '         <description>%[1]%</description>\n' +
				'         <styleUrl>#style%[2]%</styleUrl>\n' +
				'         <LineString>\n' +
                '            <coordinates>%[4]%</coordinates>\n' +
                '         </LineString>\n' +
				'      </Placemark>\n' +
            	'   </Folder>\n';

            // ポリゴンのスタイル出力定義
            var polygonDefaultStyle = '\n' +
            	'   <Style id=\"style%[0]%\">\n' +
                '      <LineStyle>\n' +
                '         <color>%[1]%</color>\n' +
                '         <width>%[2]%</width>\n' +
                '      </LineStyle>\n' +
                '      <PolyStyle>\n' +
                '         <color>%[3]%</color>\n' +
                '         <fill>%[4]%</fill>\n' +
                '      </PolyStyle>\n' +
            	'   </Style>\n';

            // ポリゴンのフォルダ出力定義
            var polygonDefaultFolder = '\n' +
            	'   <Folder>\n' +
                '      <Placemark>\n' +
                '         <name>%[0]%</name>\n' +
                '         <description>%[1]%</description>\n' +
                '         <styleUrl>#style%[2]%</styleUrl>\n' +
                '         <Polygon>\n' +
                '            <outerBoundaryIs>\n' +
                '               <LinearRing>\n' +
                '                  <coordinates>%[3]%</coordinates>\n' +
                '               </LinearRing>\n' +
                '            </outerBoundaryIs>\n' +
                '         </Polygon>\n' +
                '      </Placemark>\n' +
        	    '   </Folder>\n';

            // ポイントのスタイル出力定義
            var markerDefaultStyle = '\n' +
                '   <Style id=\"style%[0]%\">\n' +
                '      <IconStyle>\n' +
                '         <scale>%[1]%</scale>\n' +
                '         <heading>0.0</heading>\n' +
                '         <Icon>\n' +
                '            <href>%[2]%</href>\n' +
                '            <refreshInterval>0.0</refreshInterval>\n' +
                '            <viewRefreshTime>0.0</viewRefreshTime>\n' +
                '            <viewBoundScale>0.0</viewBoundScale>\n' +
                '         </Icon>\n' +
                '      </IconStyle>\n' +
                '      <LabelStyle>\n' +
                '         <color>ffaa0000</color>\n' +
                '         <scale>1.0</scale>\n' +
                '      </LabelStyle>\n' +
                '   </Style>\n';

            // ポイントのフォルダ出力定義
            var markerDefaultFolder = '\n' +
                '   <Folder>\n' +
                '      <Placemark>\n' +
                '         <name>%[0]%</name>\n' +
                '         <description>%[1]%</description>\n' +
                '         <styleUrl>#style%[2]%</styleUrl>\n' +
                '         <Point>\n' +
                '            <coordinates>%[3]%</coordinates>\n' +
                '         </Point>\n' +
                '      </Placemark>\n' +
                '   </Folder>\n';

            var kmlFormatObj = {
                kmlBase : kmlBaseDefault,
                line    : [lineDefaultStyle, lineDefaultFolder],
				arrow   : [lineDefaultStyle, arrowDefaultFolder],
                polygon : [polygonDefaultStyle, polygonDefaultFolder],
                marker  : [markerDefaultStyle, markerDefaultFolder]
            };

            return type === undefined ?
                    kmlFormatObj :
                    kmlFormatObj[type];
        }
    };
});
