/**
* 災害シミュレーション共通モジュール。
* @module app/simulation/_SimulationEventPageBase
*/
define([
    'module',
    'dojo/_base/declare',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase'
    // 以下、変数として受け取らないモジュール
], function (module, declare, InfoDialog, _PageBase) {
    /**
    * 災害シミュレーション共通モジュール。
    * @class _SimulationEventPageBase
    * @extends module:idis/view/page/_PageBase~_PageBase
    */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/simulation/_SimulationEventPageBase~_SimulationEventPageBase# */ {
        // イベントURLベース
        EVENT_URL_BASE : {
            '01': 'chronology/',
            '02': 'evacorder/',
            '03': 'shelter/',
            '04': 'report/'
        },

        /**
         * 入力チェック
         * @returns チェック結果
         */
        _validation: function() {
            var msg = [];
            if(!this._eventType && !this.eventType.get('value')) {   // 登録の場合のみチェック
                msg.push('イベント種別が選択されていません。');
            }
            if(!this.timing.get('value')) {
                msg.push('発生タイミングが選択されていません。');
            }
            if(!this.content.get('value')) {
                msg.push('内容が入力されていません。');
            }
            if (msg.length > 0) {
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : msg.join('<br>')
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
                return false;
            }
            return true;
        },

        /**
         * 発生タイミングを秒数に変換し、返却
         * @returns 発生タイミング
         */
        _generateTimingToSec: function () {
            // 発生タイミングを取得
            var timing = this.timing.get('value');
            // timingをHH:ssで取得
            var formatTiming = timing.toString().split(' ')[4].split(':');
            return parseInt(formatTiming[0],10) * 60 * 60 +
                parseInt(formatTiming[1], 10) * 60;
        }
    });
});