/**
 * <ライフライン一覧画面>
 *
 * @module app/lifeline/lifelineAdminGrid.js
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/dom',
    'dijit/registry',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'idis/view/form/Button',
    'idis/util/DateUtils',
    'app/config',
    'dojo/on',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer'
], function(module, declare, lang, locale, dom, registry, IdisGrid, helper, Button, DateUtils, config, on) {
    return declare(module.id.replace(/\//g, '.'),IdisGrid, {

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            helper.buttonColumn('detail', '詳細'),
            {field: 'admNum', label: '整理番号', sortable: true},
            helper.column('seqNum', '連番', {formatter: function(value) { return '第' + value + '報'; }}),
            {field: 'municipalityName', label: '区', sortable: true},
            {field: 'infoKind', label: 'ライフライン種別', sortable: true},
            {field: 'title', label: '件名', sortable: true},
            helper.column('text', '本文', {
                formatter: function(value) {
                    // 100文字以上は詳細で確認してもらう。
                    var text = value.length > 100 ?
                        [value.substr( 0, 100 ), '...'].join() : value;
                    return text;
                },
                sortable: true
            }),
            {field: 'reportTimeStamp', label: '報告日時', sortable: true,
                formatter: lang.hitch(this, function(item){
                    return item ? DateUtils.format(DateUtils.parse(item)) : '-';
                })
            },
            {field: 'updateTimeStamp', label: '更新日時', sortable: true,
                formatter: lang.hitch(this, function(item){
                    return item ? DateUtils.format(DateUtils.parse(item)) : '-';
                })
            },
            {field: 'reportName', label: '報告者名', sortable: true},
        ]
    });
});
