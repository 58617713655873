define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/json',
    'dijit/form/Select',
    'idis/consts/USER_TYPE',
    'idis/model/UserInfo',
    '../../model/PubStatus'
], function(module, declare, lang, json, Select, USER_TYPE, UserInfo, PubStatus) {
    /**
    * 公開範囲選択用パーツ。
    * @class PubStatusSelector
    */
    return declare(module.id.replace(/\//g, '.'), Select,{


        /*
        * セレクトの選択肢の種類
        * ALL:すべて
        * PREFECTURE:(県・振興局ユーザー)一時保存、組織内共有、県庁内管理、全体公開
        * OTHER:（その他ユーザー）一時保存、組織内共有、県報告、全体公開
        */
        type: 'ALL',

        /*
        *ログインユーザ組織情報格納先
        */
        organization: '',

        /*
        * 選択必須
        */
        required: false,

        // DOM構築処理
        buildRendering: function() {
            this.inherited(arguments);

            // 幅を調整
            this.set('style', {width: '10em'});

            // ログインユーザの組織情報を取得
            this.organization = UserInfo.getOrganization();
        },

        // セレクトの選択肢を変更する
        changeOptions: function(pubStatus) {
            var options = PubStatus.concat();

            switch (this.type) {
                case 'PREFECTURE':
                    options[3].label = '県庁内管理';
                    options.splice(4, 1);
                    break;
                case 'OTHER':
                    options.splice(3, 1);
                    break;
            }

            if(this.required){
                // 空白消す
                options.splice(0, 1);
            }

            // 変更後の選択肢をoptionsに設定
            this.set('options', options);
            if(pubStatus) {
                // 初期値を設定する
                this.set('value', pubStatus);
            }
            this.startup();
        },

        /**
         * 選択肢の種類を設定する
         * @param {*} pubStatus 初期選択値
         */
        createSelector: function(pubStatus) {
            //初期化
            this.type = 'ALL';
            // ユーザータイプによって、選択肢タイプをセット
            switch(UserInfo.getUserType()){
                case USER_TYPE.PREFECTURE:
                case USER_TYPE.REGION:
                    this.type = 'PREFECTURE';
                    break;
                case USER_TYPE.OTHER_ORGAN:
                    this.type = 'OTHER';
                    break;
            }

            // セレクトの選択肢を変更する
            this.changeOptions(pubStatus);
        },

        // 組織タイプを設定する
        setOrganizationType: function() {

            //各組織コードを取得する
            var deptCd = this.organization.deptCd;
            var sectCd = this.organization.sectCd;

            //組織タイプ（1：振興局、2：事業課、それ以外）
            var organizationType = '';

            if(deptCd === this.landDevelopment) {
                for(var i in this.section) {
                    if(sectCd === this.section[i]){
                        //県土整備部下の課ユーザである場合
                        organizationType = '2';
                        break;
                    }
                }
            } else {
                for(var j in this.branchOffice) {
                    if(deptCd === this.branchOffice[j]) {
                        //地域振興局ユーザである場合
                        organizationType = '1';
                        break;
                    }
                }
            }

            //組織タイプを返す
            return organizationType;
        }

    });
});
