define([
    'module',
    'dojo',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/text!./templates/TimestampsPanel.html',
    'idis/control/Locator',
    'idis/view/_IdisWidgetBase',
    'dojo/topic',
    'app/consts/LAYER_ID'
], function(module, dojo, declare, lang, domClass, template, Locator, _IdisWidgetBase, topic, LAYER_ID) {
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase, {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSSクラス
        baseClass: 'map-TimestampsPanel',

        constructor: function(){
            // レイヤー選択された際に時刻表示を追加する
            this.own(topic.subscribe('app/map/TimestampsPanel::show',
                lang.hitch(this, function(payload) {
                    this.addTimestampView(payload);
                })
            ));
            // レイヤー解除された際に時間を非表示にする
            this.own(topic.subscribe('app/map/TimestampsPanel::disappear',
                lang.hitch(this, function(){
                    this.removeTimestampView(Locator.getQuery());
                })
            ));
        },

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
        },

        // DOM構築後に呼ばれる
        postCreate: function() {
            this.inherited(arguments);
        },

        /**
         * 選択されたレイヤの時刻表示を追加。
         */
        addTimestampView: function(payload) {
            var layers2add = [
                // {name: 'highres-nowcast', timeRowDom: this.nowcast, timeInputDom: this.nowcastTimestamp},
                // {name: 'srf-current', timeRowDom: this.srfCurrent, timeInputDom: this.srfCurrentTimestamp},
                // {name: 'srf-forecast', timeRowDom: this.srfForecast, timeInputDom: this.srfForecastTimestamp},
                {name: 'sediment-risk', timeRowDom: this.sedimentRisk, timeInputDom: this.sedimentRiskTimestamp},
                {name: 'inund-risk', timeRowDom: this.inundRisk, timeInputDom: this.inundRiskTimestamp},
                {name: 'flood-risk-line', timeRowDom: this.floodRisk, timeInputDom: this.floodRiskTimestamp},
                {name: 'weather-warning', timeRowDom: this.weatherWarning, timeInputDom: this.weatherWarningTimestamp},
                {name: 'rainfall', timeRowDom: this.rainfall, timeInputDom: this.rainfallTimestamp},
                {name: 'river', timeRowDom: this.river, timeInputDom: this.riverTimestamp}
            ];
            var formattedTime = payload.timestamp.replace(
                /(\d{4})-(\d{2})-(\d{2})-(\d{2})-(\d{2})/,'$1/$2/$3 $4:$5');

            layers2add.forEach(function(layer){
                if (layer.name === payload.layerName) {
                    // 時刻表示
                    domClass.remove(layer.timeRowDom, 'nonDisp');
                    layer.timeInputDom.innerText = formattedTime;
                    // 閉じるボタンも表示
                    domClass.remove(this.closeButtonArea, 'nonDisp');
                }
            }, this);

            // 閉じるボタンで時刻表示を閉じた後の再表示で、選択されているレイヤーの情報を再表示する
            var layers2restore = [
                // {code: LAYER_ID.HIGHRES_NOWCAST, timeRowDom: this.nowcast},
                // {code: LAYER_ID.SRF_CURRENT, timeRowDom: this.srfCurrent},
                // {code: LAYER_ID.SRF_FORECAST, timeRowDom: this.srfForecast},
                {code: LAYER_ID.INUND_RISK, timeRowDom: this.inundRisk},
                {code: LAYER_ID.SEDIMENT_RISK, timeRowDom: this.sedimentRisk},
                {code: LAYER_ID.FLOOD_RISK, timeRowDom: this.floodRisk},
                {code: LAYER_ID.WEATHER_WARNING, timeRowDom: this.weatherWarning},
                {code: LAYER_ID.RAINFALL_10MIN, timeRowDom: this.rainfall},
                {code: LAYER_ID.RAINFALL_1HOUR, timeRowDom: this.rainfall},
                {code: LAYER_ID.RAINFALL_CUMULATIVE, timeRowDom: this.rainfall},
                {code: LAYER_ID.RIVER, timeRowDom: this.river}
            ];
            var list = null;
            var query = Locator.getQuery();
            if (query.l) {
                list = query.l;
            } else {
                list = [];
            }

            // 選択されている項目の行は表示する
            layers2restore.forEach(function(layer){
                if (list && list.indexOf(layer.code) !== -1) {
                    domClass.remove(layer.timeRowDom, 'nonDisp');
                }
            });
        },

        /**
         * 選択解除されたレイヤの時刻を非表示にする
         */
        removeTimestampView: function(query) {
            var layers2remove = [
                // {code: LAYER_ID.HIGHRES_NOWCAST, timeRowDom: this.nowcast},
                // {code: LAYER_ID.SRF_CURRENT, timeRowDom: this.srfCurrent},
                // {code: LAYER_ID.SRF_FORECAST, timeRowDom: this.srfForecast},
                {code: LAYER_ID.INUND_RISK, timeRowDom: this.inundRisk},
                {code: LAYER_ID.SEDIMENT_RISK, timeRowDom: this.sedimentRisk},
                {code: LAYER_ID.FLOOD_RISK, timeRowDom: this.floodRisk},
                {code: LAYER_ID.WEATHER_WARNING, timeRowDom: this.weatherWarning},
                {code: LAYER_ID.RAINFALL_10MIN, timeRowDom: this.rainfall},
                {code: LAYER_ID.RAINFALL_1HOUR, timeRowDom: this.rainfall},
                {code: LAYER_ID.RAINFALL_CUMULATIVE, timeRowDom: this.rainfall},
                {code: LAYER_ID.RIVER, timeRowDom: this.river}
            ];
            var layers2rainfall= [
                LAYER_ID.RAINFALL_10MIN,
                LAYER_ID.RAINFALL_1HOUR,
                LAYER_ID.RAINFALL_CUMULATIVE
            ];
            var list = null;
            var rowExist = false;

            if (query.l) {
                list = query.l;
            } else {
                list = [];
            }

            // 選択されていない項目の行は非表示にする
            layers2remove.forEach(function(layer){
                if (list && list.indexOf(layer.code) === -1) {
                    // 雨量はすべてチェック外れたときのみ非表示にする
                    if (layers2rainfall.indexOf(layer.code) !== -1) {
                        if (list.indexOf(LAYER_ID.RAINFALL_10MIN) === -1 &&
                            list.indexOf(LAYER_ID.RAINFALL_1HOUR) === -1 &&
                            list.indexOf(LAYER_ID.RAINFALL_CUMULATIVE) === -1) {
                            domClass.add(layer.timeRowDom, 'nonDisp');
                        } else {
                            rowExist = true;
                        }
                    } else {
                        domClass.add(layer.timeRowDom, 'nonDisp');
                    }
                } else if (list) {
                    rowExist = true;
                }
            });

            // すべて非表示の場合は閉じるボタンも非表示
            if (!rowExist) {
                domClass.add(this.closeButtonArea, 'nonDisp');
            }

        },

        /**
         * 時刻表示を閉じる
         */
        onDismiss: function (e) {
            this.removeTimestampView([]);
            e.stopPropagation();
        }

    });
});
