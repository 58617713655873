/**
 * クロノロジ新規登録画面用モジュール。
 * @module app/chronology/ChronologyRegisterPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/json',
    'dojo/dom-style',
    'dojo/text!./templates/ChronologyRegisterPage.html',
    'dojo/promise/all',
    'idis/view/page/_PageBase',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    '../config',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/view/Loader',
    'idis/view/dialog/InfoDialog',
    './_ChronologyPageBase',
    './ChronologyUtil',
    'app/model/DisasterInfo',
    'dojox/lang/functional/array',
    './consts/ChronologyType',
    // 以下、変数で受けないモジュール
    'idis/view/form/AclButton',
    'dijit/form/ValidationTextBox',
    'dijit/form/CheckBox',
    'app/view/form/ShareOrgsMultiSelector4Chronology',
    'app/view/form/ManageRegionMunicipalitySelector',
    'app/view/form/CustomizableMunicipalitySelector',
    'app/view/form/ShareOrganizationMultiSelector'
], function(module, declare, lang, array, json, domStyle, template, all, _PageBase,
	UserInfo, USER_TYPE, config, Locator, Router, Requester, Loader, InfoDialog,
    _ChronologyPageBase, ChronologyUtil, DisasterInfo, df, ChronologyType) {
    /**
     * クロノロジ新規登録画面
     * @class ChronologyRegisterPage
     * @extends module:app/chronology/_ChronologyPageBase~_ChronologyPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _ChronologyPageBase,
    /** @lends module:app/chronology/ChronologyRegisterPage~ChronologyRegisterPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--chronology',

        /**
         * constructor
         */
        constructor: function() {
            // 災害シミュレーション関連のパラメータ取得
            this._scenarioId = Locator.getQuery().scenarioId;
            this._simulateFlg = Locator.getQuery().simulateFlg === 'true';
            this._eventType = Locator.getQuery().eventType;
            this._content = Locator.getQuery().content;
            this._timing = Locator.getQuery().timing;
        },

        buildRendering: function(){
            this.inherited(arguments);
            this.own(this.linkInsertDialog);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);

            this._senderMunicCd = (UserInfo.getUserType() === USER_TYPE.MUNICIPALITY) ?
                    UserInfo.getMunicipalityCds()[0] : config.municInfo.prefMunicCd;

            // 宛先ラベルにhintをつける
            this.setShareTips();

            // クロノロジ新規登録画面を初期化
            this.initRegisterPage();
        },

        /**
         * postCreate
         */
        postCreate: function() {
            this.inherited(arguments);
        },

        /**
         * クロノロジ新規登録画面を初期化する
         */
        initRegisterPage: function() {
            // プレビューを初期化
            this.clearPreview();
            //諸変数の初期化
            this.attachFileList = [];
            this.pointLat = '';
            this.pointLng = '';
            this.marker = '';
            this.chronologyType.set('value', ChronologyType.WHITE_BOARD.id);

            var userMunicCd = UserInfo.getMunicipalityCd();
            var userRegionCd = UserInfo.getRegionCd();
            var userOrganizationCd = UserInfo.getLowestOrganizationCd();

            // デフォルトの値を県とする
            this.shareMunicipalityCd.set('value', [config.municInfo.prefMunicCd]);

            // ユーザ種別に応じて、地図のセット・配信元の確定
            var promise;
            if(userMunicCd){
                // 市町村・県・関係機関ユーザの場合
                // 市町村の位置に地図をセット＋市町名を取得
                promise = Requester.get('/api/municipalities/' + userMunicCd, {
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(lang.hitch(this, function(data) {
                    //ズーム値の設定
                    var zoom = 13;
                    if (userMunicCd === config.municInfo.prefMunicCd){
                        zoom = 11;
                    }
                    // 地図を初期化
                    this.initMap(data.latitude, data.longitude, zoom);
                    this._senderName = data.municipalityName;
                    this.senderName.set('value', [userOrganizationCd]);
                    this.senderName.set('noAllButton', true);
                    this.shareOrganInnerLabel.set('value', [userOrganizationCd]);
                }), lang.hitch(this, function(error) {
                    this.chain.infoError(error);
                }));
                //ローダーの表示
                Loader.wait(promise);
            }else if(userRegionCd){
                // 振興局ユーザの場合
                // 地域名を取得
                promise = Requester.get('/api/regions/' + userRegionCd, {
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(lang.hitch(this, function(data) {
                    // 地図を初期化
                    this.initMap(data.latitude, data.longitude, 9);
                    this._senderName = data.regionName;
                    this.senderName.set('value', [userOrganizationCd]);
                    this.senderName.set('noAllButton', true);
                    this.shareOrganInnerLabel.set('value', [userOrganizationCd]);
                }), lang.hitch(this, function(error) {
                    this.chain.infoError(error);
                }));
                //ローダーの表示
                Loader.wait(promise);
            }else{
                this.initMap(config.map.latitude, config.map.longitude);
            }
        },

        // 仮登録する
        registerChronologyTemp: function(){
            this.registerChronology(false);
        },

        // 本登録する
        registerChronologyActual: function(){
            this.registerChronology(true);
        },

        /**
         * クロノロジを登録する。
         */
        registerChronology: function(isActual) {

            var sendData = this.form.get('value');

            // 入力チェック
            if(sendData.shareMunicipalityCd.length < 1 && sendData.chronologyType !== ChronologyType.WHITE_BOARD.id) {
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '宛先が選択されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog=null;
                return false;
            }

            // 内容のサイズチェック
            // 内容のみリンク追加での制御があるため登録時にもチェックする
            var propLength = this.content.params.maxLength;
            if (this.content.get('value').length > propLength) {
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '内容は' + propLength + '字以内で入力してください。'
                    });
                }
                this.infoDialog.show();
                this.infoDialog=null;
                return false;
            }

            // フォームのバリデーションを行う（共通部品）
            if(!this.form.validate()) {
                return false;
            }

            sendData.actualFlg = isActual ? '1' : '0';

            var chronologyShareOrgs = [];

            // 宛先を取得するのは「要請」「情報提供」の場合のみ。
            var isMunicipality = false;
            var isDummy = false; // 自治体指定か否か
            var isCrossOrg = false; // 組織またぎか否か
            var isToMunicOrg = false; // 市町の防災担当組織宛てか否か
            if(sendData.chronologyType === ChronologyType.DISASTER_HLD.id ||
                sendData.chronologyType === ChronologyType.NOTIFICATION.id){
                // セレクタからフルチェックされた自治体コードを取得する
                var shareMap = this.shareMunicipalityCd.tree._checkMap;
                var excludeMap = {};
                var tree = this.shareMunicipalityCd.tree;
                df.forIn(shareMap, function(item) {
                    if(tree.isEveryChildChecked(item)){
                        // 配信元が県・振興局ユーザの場合、宛先が市町自治体か否か
                        // （上記の宛先が市町の場合、最下層の防災担当組織1件を宛先組織とするため、ツリーの上位組織ではなく最下層組織を取得）
                        if(UserInfo.getUserType() === USER_TYPE.PREFECTURE || UserInfo.getUserType() === USER_TYPE.REGION){
                            isToMunicOrg = /^42[234]*/.test(item.municipalityCd);
                        }
                        // NOTE: 長崎県では市町と組織を同じセレクタで管理する
                        // idが4桁未満のものはアイテムをまとめるダミーなので除外し、
                        // 4桁以上のもののみ処理を行う
                        // 振興局・自治体コードも除外（振興局・自治体もそれぞれの組織コードで扱う）
                        // if (tree.model.store.getIdentity(item).length >= 4 &&) {
                        if (tree.model.store.getIdentity(item).length >= 4 &&
                            !/^42[0-9]{4}/.test(tree.model.store.getIdentity(item))) {
                            // セレクタにて子要素が全てチェックされている場合、子要素を除外リストに追加
                            tree.getItemChildren(item).then(lang.hitch(this, function(items) {
                                if(!items){
                                    return false;
                                }
                                if(isToMunicOrg){
                                    if(items.length !== 0){
                                        // 県・振興局ユーザが市町の防災担当組織宛てとする場合は、子を登録する
                                        // 子を登録して親を登録対象外とする場合
                                        // 対象に子が存在する場合、親は登録対象外とする
                                        excludeMap[tree.model.store.getIdentity(item)] = true;
                                        return false;
                                    }
                                } else {
                                    array.forEach(items, function(item){
                                        // 親を登録して子を登録対象外とする場合
                                        // 対象に紐づく子はすべて登録対象外とする
                                        excludeMap[tree.model.store.getIdentity(item)] = true;
                                    }, this);
                                }
                            }));
                        }
                    }
                }, this);

                // 最下層のチェックを取得する
                // 合わせて要請時の宛先に組織が設定されているかチェック
                df.forIn(shareMap, function(item) {
                    var cd = tree.model.store.getIdentity(item);
                    // 除外リストに設定されていなければ登録
                    // 親もこのタイミングで登録される
                    // NOTE: 長崎県では市町と組織を同じセレクタで管理する
                    // idが4桁未満のものはアイテムをまとめるダミーなので除外し、
                    // 4桁以上のもののみ処理を行う
                    // 振興局・自治体コードも除外（振興局・自治体もそれぞれの組織コードで扱う）
                    // if(cd.length >= 4 && !excludeMap[cd] && cd !== '$ROOT$'){
                    if(cd.length >= 4 && !excludeMap[cd] && cd !== '$ROOT$' && !/^42[0-9]{4}/.test(cd)){
                        chronologyShareOrgs.push(item);
                        if (cd.substring(0, 1) !== 'D' && cd.substring(0, 1) !== 'S' && cd.substring(0, 1) !== 'U') {
                            // 部/課/係でない情報が登録されている
                            isMunicipality = true;
                        }
                    } else if (cd.length < 4){
                        isDummy = true;
                    }
                    // 組織またぎか否か
                    if(UserInfo.getUserType() === USER_TYPE.PREFECTURE || UserInfo.getUserType() === USER_TYPE.REGION){
                        // 配信元が県・振興局ユーザの場合、宛先が市町自治体か否か
                        isCrossOrg = /^42[234]*/.test(item.id);
                    } else if (UserInfo.getUserType() === USER_TYPE.MUNICIPALITY){
                        // 配信元が市町ユーザの場合、宛先に「長崎県」の有無
                        isCrossOrg = (item.id === config.municInfo.prefMunicCd);
                    } else if( UserInfo.getUserType() === USER_TYPE.OTHER_ORGAN){
                        // 配信元がその他ユーザの場合、情報共有・要請の宛先は県のみとなるため、組織またぎ確定
                        isCrossOrg = true;
                    }
                }, this);
            }
            if (isDummy && sendData.chronologyType === ChronologyType.DISASTER_HLD.id && !isCrossOrg) {
                // 要請時、宛先に自治体が設定されていて、組織またぎでない場合はエラー
                // var infoDialogMsg = '宛先は自治体全体でなく要請を行う組織を選択してください。';
                var infoDialogMsg = '宛先には要請を行う個別の自治体または組織を選択してください。';
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : infoDialogMsg
                });
                this.infoDialog.show();
                return false;
            }
            // 共有先情報を設定する。
            var chronologyShares = [];

            // 送信者の市町村or振興局コード。
            var senderMuniOrgObj;
            df.forIn(this.senderName.tree._checkMap, function(item) {
                // 必ず一つのみが設定されている
                if(!senderMuniOrgObj && !/^35000[2-9]/.test(item.id)){ // 未設定かつ振興局コード以外の場合に先頭のdeptCdを設定
                    senderMuniOrgObj = item;
                }
            }, this);

            // 先頭に発信元を追加
            chronologyShareOrgs.unshift(senderMuniOrgObj);
            // 各種設定のフラグを取得する
            var typeSetting = ChronologyUtil.getSettingFromCode(sendData.chronologyType);
            sendData.municipalityAuthorizeFlg = typeSetting.municipalityAuthorizeFlg;
            sendData.organizationAuthorizeFlg = typeSetting.organizationAuthorizeFlg;

            // 項目ごとに処理
            all(
                array.map(chronologyShareOrgs, function(org){
                    // 親の自治体（municipality）が辿れない場合、ログインユーザ組織の自治体を取得
                    // 関係機関ユーザの場合、宛先は県のみで自組織のツリー情報が画面側にないためログイン情報で補う
                    if(!this.shareMunicipalityCd.getRegionMunicipalityInfo(org)){
                        return this.getRegionMunicipalityInfo(UserInfo.getMunicipalityCd());
                    }
                    // 各アイテムの自治体要素を取得する
                    return this.getRegionMunicipalityInfo(this.shareMunicipalityCd.getRegionMunicipalityInfo(org).id);
                }, this)
            ).then(lang.hitch(this, function(municInfos){
                // 共有先組織の数だけループする
                array.forEach(municInfos, function(infos, index){
                    // 仮想ルートと全体を宛先から除く
                    if(infos && infos.id !== '$ROOT$' && infos.id !== '0'){
                        var share = {};
                        var shareOrg = {};
                        // 発信元の場合
                        // 組織内情報場合は、これが唯一の共有先になる。
                        if(index === 0){
                            // 最初の配列は必ず発信元
                            share.senderFlg = '1';
                            // NOTE: 長崎県では送信者氏名はクロノロジ共有に持つ
                            share.senderPersonalName = sendData.senderPersonalName;
                            delete sendData.senderPersonalName;
                            // NOTE: 長崎県では発信日時はクロノロジ共有に持つ
                            share.registerTimestamp = sendData.registerTimestamp;

                            // 組織内情報の場合は宛先としても登録
                            // NOTE:長崎県では
                            share.destinationFlg = (sendData.chronologyType === ChronologyType.WHITE_BOARD.id) ?
                                                        '1' : '0';
                            shareOrg = this.setOrganizationFromTreeObject(chronologyShareOrgs[index]);
                            // 組織が設定されていれば市町村 + 組織名、市町村のみであれば市町村名
                            shareOrg.name = shareOrg.deptCd && shareOrg.deptCd !== null ?
                                infos.name + shareOrg.name : shareOrg.name;
                        } else {
                            share.senderFlg = '0';
                            share.destinationFlg = '1';
                            // 市町村全体共有モードでなければ、組織情報をセット
                            shareOrg =
                                this.setOrganizationFromTreeObject(chronologyShareOrgs[index]);
                            // 組織が設定されていれば市町村 + 組織名、市町村のみであれば市町村名
                            shareOrg.name = shareOrg.deptCd && shareOrg.deptCd !== null ?
                                infos.name + shareOrg.name : shareOrg.name;
                        }
                        // 自治体単位処理フラグが立っていない場合、組織を設定する
                        if(!typeSetting.isMunicipalityUnit){
                            share.deptCd = shareOrg.deptCd;
                            share.sectCd = shareOrg.sectCd;
                            share.unitCd = shareOrg.unitCd;
                            share.organizationCd = shareOrg.unitCd ? shareOrg.unitCd :
                                shareOrg.sectCd ? shareOrg.sectCd : shareOrg.deptCd;
                        }
                        // 市町村コード(振興局はnull)
                        share.municipalityCd = infos.id.substring(0,3) === config.municInfo.prefRegCdGudge &&
                            infos.id !== config.municInfo.prefMunicCd ? null : infos.id;
                        // 振興局コード(振興局以外はnull)
                        share.regionCd = infos.id.substring(0,3) === config.municInfo.prefRegCdGudge &&
                            infos.id !== config.municInfo.prefMunicCd ? infos.id : null;
                        // 自治体名 + 組織名
                        share.shareName = typeSetting.isShareNameOrganization ?
                            shareOrg.name : infos.name;
                        // 発信グループ番号
                        share.sendingSeqNum = 1;
                        chronologyShares.push(share);
                    }
                }, this);

                // 要請で、宛先組織がない場合
                // NOTE: 長崎県案件では組織と自治体を同じセレクタで管理するため起こりえないためコメントアウト
                // if((!chronologyShareOrgs || chronologyShareOrgs.length === 0) &&
                //         sendData.chronologyType === ChronologyType.DISASTER_HLD.id){

                //     // 組織情報は抜きで、市町村の情報のみをセットする
                //     var dest = {};
                //     dest.senderFlg = '0';
                //     dest.destinationFlg = '1';

                //     dest.municipalityCd = sendData.shareMunicipalityCd;
                //     dest.regionCd = municInfos.destMunic.regionCd ?
                //                     municInfos.destMunic.regionCd : null ;
                //     dest.shareName = municInfos.destMunic.name;

                //     dest.deptCd = null;
                //     dest.sectCd = null;
                //     dest.unitCd = null;
                //     dest.organizationCd = null;
                //     chronologyShares.push(dest);
                // }

                //  共有先情報をセットする
                sendData.chronologyShares = chronologyShares;

                // 送信情報の補完
                sendData.sourceAddress02 = '';

                if(sendData.sourceAddress01) {
                    sendData.sourceLatitude = this._sourceAddressLat;
                    sendData.sourceLongitude = this._sourceAddressLng;
                } else {
                    sendData.sourceLatitude = '';
                    sendData.sourceLongitude = '';
                }
                sendData.chronologyType = String(this.chronologyType.value);
                sendData.disasterId = DisasterInfo.getDisasterId();

                //添付ファイルIDをセット
                if(this.attachFileList) {
                    var attachFileIds = [];
                    for(var i=0; i<this.attachFileList.length; i++) {
                        attachFileIds.push(this.attachFileList[i].chronologyAtcFileId);
                    }
                    sendData.attachFileIds = attachFileIds.join(',');
                } else {
                    sendData.attachFileIds = '';
                }

                // マスコミ公開可否
                // NOTE: 長崎県案件では使用しないが互換性のために設定
                sendData.allowPublishingFlg = (sendData.allowPublishing[0] === 'on') ? '1' : '0';

                // 続報番号
                // NOTE: 長崎県独自要件
                // 新規登録時は1固定
                if (isActual) {
                    sendData.seqNum = 1;
                }

                delete sendData.allowPublishing;
                delete sendData.attachFile;
                delete sendData.organizationName;
                delete sendData.shareMunicipalityCd;
                // 情報区分が要請以外の場合は情報種別を削除
                if (sendData.chronologyType !== ChronologyType.DISASTER_HLD.id ){
                    delete sendData.chronologyCategory;
                }

                var jsonStr = null;

                //登録確認ダイアログを表示
                var message = null;
                // 災害シミュレーションのイベントとして登録する場合
                if(this._simulateFlg){
                    delete sendData.chronologyPostForm;
                    var subSendData = {};
                    subSendData.chronologyTypeName = this.chronologyType.domNode.innerText;
                    subSendData.hldStatusName = this.hldStatus.domNode.innerText;
                    subSendData.chronologyCategoryName = this.chronologyCategory.domNode.innerText;
                    subSendData.urgencyTypeName = this.urgencyType.domNode.innerText;
                    jsonStr = json.stringify({
                        chronologyPostForm: sendData,
                        chronologyConversionForm: subSendData,
                        scenarioId: this._scenarioId,
                        eventType: this._eventType,
                        content: this._content,
                        timing: this._timing});
                    message = isActual ? '【本登録】としてイベント登録します。' :
                                        '【仮登録】としてイベント登録します。';
                    this.chain.confirm(message, function(chain) {
                        var promise = Requester.post('/api/simulations/event/', {
                            data: jsonStr,
                            headers: {'Content-Type': 'application/json; charset=utf-8'},
                            handleAs: 'json',
                            preventCache : true
                        }).then(function() {
                            chain.infoComplete(function() {
                                chain.hide();
                                Router.moveTo('simulation/event', {scenarioId: this._scenarioId});
                            });
                        }, function(error) {
                            chain.infoError(error);
                        });
                        //ローダーの表示
                        Loader.wait(promise);
                    });
                } else {
                    jsonStr = json.stringify(sendData);
                    message = isActual ? '【本登録】として登録します。<br>登録後、他のユーザも登録情報が閲覧可能となります。' :
                                    '【仮登録】として登録します。<br>他のユーザには見えない状態で保存されます。' +
                                    '<br>登録ユーザは、再編集及び本登録することが可能です。';
                    this.chain.confirm(message, function(chain) {
                        var promise = Requester.post('/api/chronologies', {
                            data: jsonStr,
                            headers: {'Content-Type': 'application/json; charset=utf-8'},
                            handleAs: 'json',
                            preventCache : true
                        }).then(function() {
                            chain.infoComplete(function() {
                                chain.hide();
                                // 一覧画面に移動
                                Router.moveTo('chronology');
                            });
                        }, function(error) {
                            chain.infoError(error);
                        });
                        //ローダーの表示
                        Loader.wait(promise);
                    });
                }
            }), lang.hitch(this, function(error){
                this.chain.infoError(error);
            }));
        },

        onChronologyTypeChange: function(){
            var type = this.chronologyType.get('value');
            if(type === ChronologyType.DISASTER_HLD.id){
                // 要請
                this._isDisasterHld = true;
                domStyle.set(this.chronologyCategoryArea, 'display', '');
                // domStyle.set(this.chronologySharesArea, 'display', '');
                domStyle.set(this.chronologyMunicSharesArea, 'display', '');
                if (UserInfo.getUserType() === USER_TYPE.PREFECTURE || UserInfo.getUserType() === USER_TYPE.OTHER_ORGAN){
                    domStyle.set(this.collectiveButtonArea, 'display', '');
                } else {
                    domStyle.set(this.collectiveButtonArea, 'display', 'none');
                }
                domStyle.set(this.chronologyMunicInnerSharesArea, 'display', 'none');
            } else if (type === ChronologyType.NOTIFICATION.id) {
                // 情報提供
                this._isDisasterHld = false;
                domStyle.set(this.chronologyCategoryArea, 'display', 'none');
                // domStyle.set(this.chronologySharesArea, 'display', '');
                domStyle.set(this.chronologyMunicSharesArea, 'display', '');
                if (UserInfo.getUserType() === USER_TYPE.PREFECTURE || UserInfo.getUserType() === USER_TYPE.OTHER_ORGAN){
                    domStyle.set(this.collectiveButtonArea, 'display', '');
                } else {
                    domStyle.set(this.collectiveButtonArea, 'display', 'none');
                }
                domStyle.set(this.chronologyMunicInnerSharesArea, 'display', 'none');
            } else {
                // 組織内情報共有
                this._isDisasterHld = false;
                domStyle.set(this.chronologyCategoryArea, 'display', 'none');
                // domStyle.set(this.chronologySharesArea, 'display', 'none');
                domStyle.set(this.chronologyMunicSharesArea, 'display', 'none');
                domStyle.set(this.collectiveButtonArea, 'display', 'none');
                domStyle.set(this.chronologyMunicInnerSharesArea, 'display', '');
            }
        },

        /**
         * クロノロジ一覧画面へ遷移する。
         */
        onChronologyLinkClick:function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('chronology');
        }
    });
});
