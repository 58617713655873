/**
* 体制状況・報告概況画面用モジュール。
* @module app/disasterprevention/view/DisasterPreventionPrefListPage
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/json',
    'dojo/text!./templates/DisasterPreventionPrefListPage.html',
    'idis/model/UserInfo',
    'idis/control/Router',
    'idis/consts/ACL',
    'idis/store/IdisRest',
    './_DisasterPreventionPageBase',
    // 以下、変数として受け取らないモジュール
    'dijit/layout/BorderContainer',
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/TextBox',
    'idis/view/form/DateInput',
    'idis/view/form/DateTimeInput',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'app/view/form/DisasterSelector',
    'app/view/form/LayerDirectorySelector',
    'app/view/form/OrganizationSelector',
    'app/view/form/UserSelector',
    'app/provide/ProvidePreventionRegisterDialog',
    './DisasterPreventionStatusGrid',
    './DisasterPreventionRegisterPage',
    './DisasterPreventionPrefListGrid',
    './DisasterPreventionFormDialog'
], function(module, declare, lang, json, template, UserInfo, Router, ACL, IdisRest, _PageBase) {

    /**
     * 検索条件保管store
     */
    var disasterPreventionPrefListFilterStore = {};

    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/disasterprevention/view/DisasterPreventionPrefListPage~DisasterPreventionPrefListPage */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--disasterprevention',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'areaCd',
                target: '/api/disasterPrevention/prefList'
            });
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
            this.initForm();
            this.initGrid();
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // 検索を実行する
            this.updateGridQuery();

            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.grid.on('detailButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                this.moveDetailPage(evt.item);
            }));
        },

        /**
         * グリッドの検索条件を指定された値で更新する。
         * @param {Object} value name属性と値のマッピング
         */
        updateGridQuery: function() {
            var value = this.form.get('value');
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            filter = filter.eq('disasterId', this._disasterId);
            // 報告時点
            if (value.reportDateTime) {
                filter = filter.eq('reportDateTime', value.reportDateTime.getTime());
            }
            // すべての市町村を表示が選択されていない場合、体制をとっている市町村のみを検索
            if (value.allCityFlg.length){
                filter = filter.eq('statusFlg', 0);
            }else{
                filter = filter.eq('statusFlg', 1);
            }
            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.grid.set('collection', collection);
        },

        /**
         * 検索ボタンが押されたときに呼び出される。
         * テンプレートHTML内のFormウィジェットに対するdata-dojo-attach-eventでこのイベントを紐付けている。
         * また、同Formウィジェットに対しdata-dojo-attach-pointを指定し、this.formからアクセス出来るようにしている。
         */
        onSubmit: function() {
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                    // 検索条件を保管
                    this.setFilterToStore();
                }
            } catch (e) {
                console.error(e);
            } finally {
                return false;
            }
        },

        /**
         * 一覧画面を表示する。
         * @param {Object} item 参照する行のデータ
         */
        moveDetailPage: function(item) {
            // ダイアログの最初の子要素が詳細画面
            var areaCd = '';
            if (item.regionCd) {
                areaCd = item.regionCd;
            } else {
                areaCd = item.municipalityCd;
            }
            Router.moveTo('disasterprevention/status/pref', {areaCd: areaCd});
        },

        /**
        * 県と市町村の概況一覧を切り替える。
        */
        moveToListPage: function(evt) {
            evt.preventDefault();
            Router.moveTo('disasterprevention');
        },
        
        /**
         * 体制マスタ管理画面へ遷移
         */
        moveToDisasterPreventionStatus: function() {
            Router.moveTo('disasterpreventionstatus', {'from': 'disasterprevention/pref'});
        },

        /**
         * 検索条件が保管されている場合、storeから取得して検索条件を設定する。
         * 検索条件が保管されていない項目は初期条件を設定する。
         */
        initForm: function() {
            // 報告時点
            if (disasterPreventionPrefListFilterStore.reportDateTime) {
                this.reportDateTime.set('value', disasterPreventionPrefListFilterStore.reportDateTime);
            }
            // 全市町村検索フラグ
            if (disasterPreventionPrefListFilterStore.allCityFlg && 
                    disasterPreventionPrefListFilterStore.allCityFlg.length) {
                this.allCityFlg.set('value', disasterPreventionPrefListFilterStore.allCityFlg);
            }
        },

        /**
         * 検索条件をstoreに保管する。
         */
        setFilterToStore: function() {
            // 変数の初期化
            disasterPreventionPrefListFilterStore = {};
            // 現在の検索条件
            var value = this.form.get('value');

            // 報告日時
            if (value.reportDateTime) {
                disasterPreventionPrefListFilterStore.reportDateTime = value.reportDateTime;
            }
            // 全市町村フラグ
            if (value.allCityFlg.length){
                disasterPreventionPrefListFilterStore.allCityFlg = value.allCityFlg;
            }
        }
    });
});
