/**
 * 配信内容欄用のtextarea用ウィジェット。
 * @module app/provide/form/ProvideContentTextarea
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'idis/view/form/WordCountTextarea'
], function(module, array, declare, WordCountTextarea) {
    /**
     * 禁止されたパターンの一覧
     * （正規表現は app/provide/ProvideUrgentMailRegisterDialog より借用）
     * @type {Object[]}
     * @private
     */
    var _BAD_PATTERN_LIST = [{
        name: 'URL',
        pattern: /(https?|ftp)(:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#]+)/
    }, {
        name: 'メールアドレス',
        pattern: /([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+/
    }, {
        name: '電話番号',
        pattern: /(\d{1,5}-\d{1,4}-\d{4})|(0\d-\d{4}-\d{4})|((050|070|080|090)-\d{4}-\d{4})|(0120-\d{3}-\d{3})/
    }];

    /**
     * 指定された文字列が禁止パターンを含むかどうかを返す。
     * @function _containsBad
     * @param {string} str 検証対象文字列
     * @returns {boolean} 禁止パターンを含む場合はtrue、それ以外の場合はfalse
     * @private
     */
    function _containsBad(str) {
        // 改行を詰めた状態で検証する
        str = str.replace(/[\r\n]/g, '');
        return array.some(_BAD_PATTERN_LIST, function(pat) {
            return pat.pattern.test(str);
        });
    }

    return declare(module.id.replace(/\//g, '.'), WordCountTextarea, {
        validator: function(value) {
            // 親の条件に加え、禁止された文字列パターンを含まないこと
            return this.inherited(arguments) && !_containsBad(value);
        },

        // 入力中に呼ばれる
        _isValidSubset: function() {
            return this.inherited(arguments) && !_containsBad(this.textbox.value);
        },

        // エラー・メッセージを返す
        getErrorMessage: function() {
            var superMessage = this.inherited(arguments);
            // 親メソッドの結果が不正メッセージ（＝未入力でない）の場合
            if (superMessage === this.invalidMessage) {
                // 改行を詰めた状態で検証する
                var value = this.textbox.value.replace(/[\r\n]/g, '');
                // いずれかの禁止パターンを含む場合は名称と共に返す
                for (var i = 0; i < _BAD_PATTERN_LIST.length; i++) {
                    if (_BAD_PATTERN_LIST[i].pattern.test(value)) {
                        return _BAD_PATTERN_LIST[i].name + 'を含めることは出来ません';
                    }
                }
            }
            // 未入力 or いずれの禁止パターンも含まないならそのまま返す
            return superMessage;
        }
    });
});

