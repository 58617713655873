/**
 * 気象エリア選択用入力パーツ
 * @module app/view/form/KisyoAreaSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/form/TreeSelector',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest'
], function(module, declare, TreeSelector, CacheStoreModel, CacheTreeRest) {
    /**
     * 県/振興局/市町村選択用入力パーツ
     * @class FacilityTypeSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */

    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:idis/view/form/KisyoAreaSelector~KisyoAreaSelector# */ {
        // 選択ダイアログのタイトル
        title: '気象エリア',

        // ツリー・モデル
        model: null,

        constructor : function() {
            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                    target: '/api/KisyoAreas/KisyoAreas4Tree'
                })
            });
        }
    });
});
