/**
 * 職員招集用ツリー・チェック入力要素を定義する。
 * @module adis/view/form/ConvoTreeChecker
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/promise/all',
    'dojo/when',
    'dojox/lang/functional/array',
    'idis/error/InvalidArgumentException',
    'idis/view/tree/CheckTree',
    './ConvoTreeSelector'
], function(module, array, declare, lang, all, when, df, InvalidArgumentException, CheckTree, TreeSelector) {
    /**
     * ツリー・チェック入力要素。
     * @class TreeChecker
     * @extends module:adis/view/form/ConvoTreeSelector~ConvoTreeSelector
     * @param {Object} kwArgs
     * @param {module:dijit/tree/model} [kwArgs.title] 選択ダイアログのタイトル
     * @param {module:dijit/tree/model} kwArgs.model ツリー・モデル
     */
    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:adis/view/form/ConvoTreeChecker~ConvoTreeChecker# */ {

        // ツリーとして使うコンストラクター関数
        treeClass: CheckTree,

        constructor: function() {
            this.value = [];
        },

        // value値を設定する
        _setValueAttr: function(value) {
            if (!lang.isArray(value)) {
                var message = '#_setValueAttr: 配列を指定してください: value=' + value;
                throw new InvalidArgumentException(module.id + message);
            }
            // value値を設定
            this._set('value', value);
            // 選択状態を更新
            this._initTree().then(lang.hitch(this, function() {
                // 指定されなかったvalue値に対応する要素のチェックを解除
                return all(df.map(this.tree._checkMap, function(item, id) {
                    if (array.indexOf(value, id) === -1) {
                        return this.tree.setChecked(item, false);
                    }
                }, this));
            })).then(lang.hitch(this, function() {
                return all(array.map(value, function(v) {
                    // 各値を識別子とする要素を取得
                    return when(this.tree.model.store.get(v), lang.hitch(this, function(item) {
                        // チェックされたものをツリーに反映
                        return this.tree.setChecked(item, true);
                    }));
                }, this));
            }));
        },

        /**
         * ウィジェットの値をリセットする。
         */
        reset: function() {
            this.set('value', []);
        },

        /**
         * ツリー選択されている要素をこのウィジェットのvalue値として設定する。
         * 要素が選択されていない場合は何もしない。
         */
        applySelectedItem: function() {
            this.set('value', array.map(this.tree.getCheckedLeafs(), function(item) {
                return this.tree.model.store.getIdentity(item);
            }, this));
        }
    });
});

