/**
* 組織を扱うモデル。
* @module app/model/PubStatus
*/
define(['app/consts/PUB_STATUS'], function(PUB_STATUS) {
  // 公開範囲選択肢の配列を返す
  return  [
    {'id':1, 'value':null, 'label':'&nbsp;'},
    {'id':2, 'value':PUB_STATUS.SELF,	'label' : '一時保存'},
    {'id':3, 'value':PUB_STATUS.ORGANIZATION,	'label' : '組織内共有'},
    {'id':4, 'value':PUB_STATUS.MUNICIPALITY,	'label' : '市町村内管理'},
    {'id':5, 'value':PUB_STATUS.PREFECTURE,	'label' : '県報告'},
    {'id':6, 'value':PUB_STATUS.ALL,	'label' : '公開（全体）'}
  ];
});