/**
 * 配信情報関連のUtility
 * @module app/provide/ProvideUtil
 */
define([
    'module'
], function(module) {

    /**
     * 緊急速報メールのバリデートをする
     * @param {string} value
     * @returns {boolean}
     */
    function _urgentMailValidate(text) {
        console.debug(module);

        //URL正規表現パターン
        var chkURL = /(https?|ftp)(:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#]+)/;
        // メールアドレス正規表現パターン({1}@{2} のような形式)
        var chkMail = /([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+/;
        // 電話番号正規表現パターン1(最初１−５桁)
        var chkTel1 = /\d{1,5}-\d{1,4}-\d{4}/;
        // 電話番号正規表現パターン2(最初２桁)
        var chkTel2 = /0\d-\d{4}-\d{4}/;
        // 電話番号正規表現パターン3(携帯電話)
        var chkTel3 = /(050|070|080|090)-\d{4}-\d{4}/;
        // 電話番号正規表現パターン4(フリーダイヤル)
        var chkTel4 = /0120-\d{3}-\d{3}/;

        // URLが含まれているかチェック
        if (text.match(chkURL)) {
            console.debug('配信内容にURLが含まれています');
            return false;
        }
        // メールアドレスが含まれているかチェック
        else if (text.match(chkMail)) {
            console.debug('配信内容にメールアドレスが含まれています');
            return false;
        }
        // 電話番号が含まれているかチェック(4種類)
        else if (text.match(chkTel1) ||
            text.match(chkTel2) ||
            text.match(chkTel3) ||
            text.match(chkTel4)) {
            console.debug('配信内容に電話番号が含まれています');
            return false;
        } else {
            return true;
        }
    }

    return {
        urgentMailValidate: _urgentMailValidate
    };
});
