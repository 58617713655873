/**
 * 透過率を選択するためのウィジェット
*/
define([
    'module',
	'dojo/_base/declare',
	'dojo/on',
	'dojo/query',
	'dijit/form/HorizontalSlider',
	'dojo/text!./templates/OpacitySlider.html',
    'dijit/_WidgetBase',
    'dijit/_TemplatedMixin',
    'dijit/_WidgetsInTemplateMixin'
], function(module, declare, on, query, HorizontalSlider, template,
      _WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin){

	/**
	 * 透過率を選択するためのウィジェット
	 * @class OpacitySlider
	 * @param {Object} arguments
	 * 	displayOpacity 透過率（%）の表示有無 true or false
	 *  defaultOpacity 透過率（%）
	 */
	return declare(module.id.replace(/\//g, '.'), [_WidgetBase, _TemplatedMixin, _WidgetsInTemplateMixin], {

		/**
		 * このウィジェットのトップレベルに付与されるCSSクラス
		 * @type {String}
		 */
		baseClass: 'dimsOpacitySlider',

		/**
		 * テンプレート文字列
		 * @type {String}
		 */
		templateString: template,

		/**
		 * デフォルト透過率(%)
		 */
		_defaultOpacity: 0,

		/**
		 * 選択されている透過率（%）
		 */
		_selectedOpacity: 0,

		/**
		 * 透過率表示有無
		 */
		_displayOpacity: true,

		/**
		 * @override
		 */
		constructor: function(options){
			this.inherited(arguments);

			if(typeof options.dispalyOpacity !== 'undefined' && !options.displayOpacity){
				this._displayOpacity = false;
			}else if(typeof options.dispalyOpacity !== 'undefined' && options.displayOpacity){
				this._displayOpacity = true;
			}else{
				this._displayOpacity = true;
			}

		},

		/**
		 * @override
		 */
		postCreate: function(){
			this.inherited(arguments);

			// 透過率を非表示
			if(!this._displayOpacity){
				query('.dimsOpacityDisplayNode').style('display', 'none');
			}
		},

		/**
		 * 透過率を更新
		 * @public
		 */
		updateOpacity: function(value){

			// 透過率を表示
			this.opacity.innerHTML = value + '&nbsp;%';

			// 選択された透過率を保持
			this._selectedOpacity = value;
		},

		/**
		 * 選択されている透過率を返す
		 * @public
		 */
		getOpacity: function(){
			return this._selectedOpacity;
		},

		/**
		 * Leafletへの入力値を返す
		 * 0 : 100 （透過率スライダー） = 1 : 0 （Leaflet指定）
		 * @public
		 * @return {Number} 透過率（小数）
		 */
		getMapOpacity: function(){
			return (100 - this._selectedOpacity) / 100;
		},

		/**
		 * 指定された透過率を設定する
		 * @public
		 * @param {Number} 透過率（%）
		 */
		setOpacity: function(value){

			this._defaultOpacity = (typeof value !== 'undefined') ? Number(value) : 0;
			this._selectedOpacity = this._defaultOpacity;

			// スライダーに反映
			this.slider.set('value', this._selectedOpacity);

			// 透過率表示に反映
			this.opacity.innerHTML = this._selectedOpacity + '&nbsp;%';

		}

	});

});
