/**
 * 日時指定用入力欄のモジュール。
 * @module idis/view/form/DateTimeInput
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/topic',
    'dijit/_WidgetBase',
    'dijit/_TemplatedMixin',
    'dijit/form/Button',
    './_NamedMixin',
    './DateInput',
    './TimeInput'
], function(module, declare, lang, topic, _WidgetBase, _TemplatedMixin, Button, _NamedMixin, DateInput, TimeInput) {
    /**
     * 日時指定入力欄ウィジェット
     * @class DateTimeInput
     * @extends module:dijit/_WidgetBase~_WidgetBase
     * @extends module:idis/view/form/_NamedMixin~_NamedMixin
     * @param {Object} kwArgs
     * @param {boolean} [kwArgs.now=false] 現在時刻設定ボタンを表示するかどうか
     */
    return declare(module.id.replace(/\//g, '.'), [_WidgetBase, _TemplatedMixin, _NamedMixin],
        /** @lends module:idis/view/form/DateTimeInput~DateTimeInput# */ {
        // ルート要素に付与されるCSSクラス
        baseClass: 'idis-DateTimeInput',

        templateString: '<div data-dojo-attach-point="containerNode"></div>',

        /**
         * 無効状態かどうか
         * @type {boolean}
         */
        disabled: false,

        /**
         * 編集不可状態かどうか
         * @type {boolean}
         */
        readOnly: false,

        /**
         * このプロパティーが定義されているとき、
         * {@link module:dijit/form/Form~Form}のget('value')による取得対象となる。
         * @type {Date}
         */
        value: null,

        /**
         * 現在時刻設定ボタンを表示するかどうか
         * @type {boolean}
         */
        now: false,

        /**
         * 現在時刻設定ボタンへの参照
         * @type {module:dijit/form/Button~Button}
         * @private
         */
        _nowButton: null,

        /**
         * 日付を格納するためのウィジェット
         * @type {module:idis/view/form/DateInput~DateInput}
         * @private
         */
        _date: null,

        /**
         * 時刻を格納するためのウィジェット
         * @type {module:idis/view/form/TimeInput~TimeInput}
         * @private
         */
        _time: null,

        /**
         * 終了日時用の入力欄かどうか
         * @type {boolean}
         */
        isTo: false,

        // DOM構築処理
        buildRendering: function() {
            this.inherited(arguments);
            // 日付・時刻用ウィジェットを初期化
            this._date = new DateInput({noName: true, isTo: this.isTo,
                onChange: lang.hitch(this, function() {
                    topic.publish(module.id + '::currentDate', '');
                })
            });
            this._time = new TimeInput({noName: true, isTo: this.isTo,
                onChange: lang.hitch(this, function() {
                    topic.publish(module.id + '::currentTime', '');
                })
            });
            // DOM要素を追加する
            this.domNode.appendChild(this._date.domNode);
            this.domNode.appendChild(this._time.domNode);
            // 現在時刻設定ボタンを追加する
            if (this.now) {
                this._nowButton = new Button({
                    label: '現在',
                    onClick: lang.hitch(this, function() {
                        var now = new Date();
                        this._date.set('value', now);
                        this._time.set('value', now);
                        topic.publish(module.id + '::currentDateTime', '');
                    })
                });
                this.domNode.appendChild(this._nowButton.domNode);
            }
        },

        // ウィジェットの無効化状態を設定する
        _setDisabledAttr: function(disabled) {
            this._set('disabled', disabled);
            this._date.set('disabled', disabled);
            this._time.set('disabled', disabled);
        },

        // ウィジェットの編集不可状態を設定する
        _setReadOnlyAttr: function(readOnly) {
            this._set('readOnly', readOnly);
            this._date.set('readOnly', readOnly);
            this._time.set('readOnly', readOnly);
        },

        // 必須入力欄かどうかを設定する
        _setRequiredAttr: function(value) {
            this._date.set('required', value);
        },

        _getValueAttr: function() {
            var dateValue = this._date.get('value');
            var timeValue = this._time.get('value');
            // 日付・時刻共にnullならnullを返す
            if (dateValue === null && timeValue === null) {
                return null;
            }
            // 日付を設定
            var value = new Date();
            value.setTime(dateValue ? dateValue.getTime() : 0);
            // 時刻を設定
            if (timeValue) {
                // 時・分は入力値
                value.setHours(timeValue.getHours());
                value.setMinutes(timeValue.getMinutes());
                // 秒・ミリ秒はisToの値により決定
                value.setSeconds(timeValue.getSeconds());
                value.setMilliseconds(timeValue.getMilliseconds());
            }
            return value;
        },

        // value値を設定する
        _setValueAttr: function(value) {
            this._date.set('value', value);
            this._time.set('value', value);
        },

        // 入力欄にフォーカスする
        focus: function() {
            // 通常は日付欄、日付が正かつ時刻が不正の場合のみ時刻欄へフォーカス
            (this._date.isValid() && !this._time.isValid() ? this._time : this._date).focus();
        },

        // 入力要素をクリアする
        reset: function() {
            this._date.reset();
            this._time.reset();
        },

        // 入力値を検証する
        validate: function() {
            var d = this._date.validate();
            var t = this._time.validate();
            return d && t;
        },

        // 入力値を検証する
        isValid: function() {
            var d = this._date.isValid();
            var t = this._time.isValid();
            return d && t;
        }
    });
});
