define({
    riverCd: {
        '01':'石狩川',
        '02':'豊平川',
        '12':'新川',
        '03':'厚別川',
        '04':'野津幌川',
        '05':'月寒川',
        '06':'望月寒川',
        '07':'精進川',
        '08':'中の川',
        '09':'琴似発寒川',
        '10':'琴似川',
        '11':'星置川',
        '99':'共通'
    }
});
