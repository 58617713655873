/**
 * テンプレート一覧グリッド(送信ダイアログ用)。
 * @module app/convocation/view/mail/ConvoTempInsertGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid,
        /** @lends module:idis/view/page/ConvoTempInsertGrid~ConvoTempInsertGrid# */ {

        /**
         * 各列の定義
         * @type {Object[]}
         */
        collection: this.store,
        columns: [
            helper.buttonColumn('insert', '挿入'),
            helper.column('distributionTitle', '件名'),
            helper.column('distributionBody', '本文')
        ]
    });
});
