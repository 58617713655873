/**
 * URL情報取得用モジュール
 * @module idis/model/LinkUrl
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/service/Requester',
    'idis/model/UserInfo'
], function (module, declare, lang, Requester, UserInfo) {

    /**
     * ユーザ情報を管理するオブジェクト
     */
    return new declare(null, {

        /**
         *  URL情報
         * @type {Object}
         */
        _linkUrlInfo: {},

        /**
         *  ベースのホスト名
         * @type {String}
         */
        // 実災害モード
        _baseHostName: null,
        // 訓練モード
        _baseHostNameTrn: null,

        /**
         * constructor
         */
        constructor: function() {
            Requester.get('/data/master/config.json').then(lang.hitch(this, function (info) {
                this._linkUrlInfo = info;

                // 起動モードに応じたベースのホスト名をセット
                this._baseHostName = info.baseHostNameIntra;
                this._baseHostNameTrn = info.baseHostNameIntraTrn;
                // LGWANの場合（カレントホスト名で判定）
                if (info.baseHostNameLgwan.indexOf(location.hostname) > -1 || info.baseHostNameLgwanTrn.indexOf(location.hostname) > -1) {
                    this._baseHostName = info.baseHostNameLgwan;
                    this._baseHostNameTrn = info.baseHostNameLgwanTrn;
                }
            }), function(error) {
                console.error('URL取得に失敗しました。', error);
                var nowBaseHostName = location.protocol + '//' + location.hostname + ':' + location.port;
                this._baseHostName = nowBaseHostName;
                this._baseHostNameTrn = nowBaseHostName;
            });
        },

        /**
         * URL（総合防災システム用）を返す。
         * @param {Boolean} true: 訓練モード
         */
        getLinkurl: function (isTraining) {
            return !isTraining ?
                (this._baseHostName + this._linkUrlInfo.linkurl) :
                (this._baseHostNameTrn + this._linkUrlInfo.linkurlTrn);
        },
        // 訓練モード
        getLinkurlTrn: function () {
            return this.getLinkurl(true);
        },

        /**
         * URL（初心者モード用）を返す。
         * @param {Boolean} true: 訓練モード
         */
        getLinkurlBeginner: function (isTraining) {
            return !isTraining ?
                (this._baseHostName + this._linkUrlInfo.linkurlBeginner) :
                (this._baseHostNameTrn +  this._linkUrlInfo.linkurlBeginnerTrn);
        },
        // 訓練モード
        getLinkurlBeginnerTrn: function () {
            return  this.getLinkurlBeginner(true);
        },

        /**
         * URL（モバイル用）を返す。
         * @param {Boolean} true: 訓練モード
         */
        getLinkurlMobile: function (isTraining) {
            return !isTraining ?
                (this._baseHostName + this._linkUrlInfo.linkurlMobile) :
                (this._baseHostNameTrn +  this._linkUrlInfo.linkurlMobileTrn);
        },
        // 訓練モード
        getLinkurlMobileTrn: function () {
            return  this.getLinkurlMobile(true);
        },

        /**
         * URL（職員招集用）を返す。
         */
        getLinkurlConvo: function () {
            return this._linkUrlInfo.linkurlConvo;
        },


        /**
         * ベースホスト名（実災害モード）を返す。
         */
        getBaseHostName: function () {
            return this._baseHostName;
        },

        /**
         * ベースホスト名（訓練モード）を返す。
         */
        getBaseHostName4Trn: function () {
            return this._baseHostNameTrn;
        }
    })();
});
