/**
 * 日付関連のユーティリティー。
 * @module app/timeline/TimelineRiverConverter
 */
define([
    'app/timeline/TimelineRiver.json'
], function(TimelineRiver) {

    /**
     * 指定された値をDateオブジェクトに変換して返す。
     * @function parse
     * @param {number|string|Date} 変換対象の値
     * @returns {Date} 指定された値に対応するDateオブジェクト
     */
    function _convert(targetRivers) {
        var rivers = [];
        targetRivers.forEach( function(targetRiver){
            TimelineRiver.items.forEach(function(item) {
                if(targetRiver === item.id) {
                    rivers.push(item.name);
                }
            });
        });
        return rivers.join();
    }

    return {
        convert: _convert
    };
});

