define([
    // ここに書く
    'app/disasterprevention/view/DisasterPreventionListPage',
    'app/disasterprevention/view/DisasterPreventionPrefListPage',
    'app/disasterprevention/view/DisasterPreventionPrefStatusPage',
    'app/disasterprevention/view/DisasterPreventionStatusPage',
    'app/disasterpreventionstatus/view/DisasterPreventionStatusListPage',
    'app/disasterInfoShare/DisasterInfoShareListPage',
    'app/disasterInfoShare/DisasterInfoShareDetailPage',
    // ここまで
], function () { });