define([
    'leaflet',
    'dojo/_base/lang',
    'dojo/dom-construct'
], function(leaflet, lang) {
    var VectorTileLayer = leaflet.Class.extend({
        
        includes: leaflet.Mixin.Events,
        
        initialize: function(map, layer, options) {
            leaflet.setOptions(this, options);
            this._map = map;
            this._layer = layer;
        },
        
        load: function() {
            this.fire('loaded');
        },
        
        draw: function(texture) {
            /*
            if (this._layer instanceof GSI.VectorTileLayer) {
                //var canvasList = $(this._layer._container).find('canvas');
                //var canvasList = domConst.toDom(this._layer._container).find('canvas');
                for (var key in this._layer._tiles) {
                    if (this._layer._tiles.hasOwnProperty(key)) {
                        var geoJSON = this._layer._tiles[key].geoJSON;
                        if (!geoJSON ) {
                            continue;   
                        }
                        for (var i = 0; i < geoJSON.length; i++) {
                            this._drawLayer(
                                texture, 
                                geoJSON[i], 
                                this._layer._tiles[key]._tilePoint, 
                                this._layer.options);
                        }   
                    }
                }
            }
            */
            
            var drawLayer = this._layer.geojsonLayer ? 
                this._layer.geojsonLayer : (this._layer.layer ? this._layer.layer: this._layer);
            this._drawLayer(texture, drawLayer);
        },
        
        _drawLayer: function(texture, layer, tilePoint, layerOptions) {
            if (!layer) {
                return;
            }
            // 作図中オブジェクトの場合はFeatureGroupになっていないので、一階層低く、
            // 引数layerがそのまま書き出し対象
            if (layer.getLayers) {
                var layers = layer.getLayers();
                    
                for (var i = 0; i < layers.length; i++) {
                    this._drawLayer(texture, layers[i], tilePoint, layerOptions);
                }
            } else {
                this._drawPath(texture, layer, tilePoint, layerOptions);    
            }
        },
        
        _updateStyle: function(texture, layer) {
            if (!layer._parts && (!layer._radius || !layer._point)) {
                return;   
            }
            var options = layer.options;
            if (options.stroke) {
                // FIXME: なぜかweightが文字列だが、textureにセットすると数値変換される
                texture.lineWidth = options.weight;
                texture.strokeStyle = options.color;
                    
                if (options.lineCap && 
                    (options.lineCap === 'butt' || options.lineCap === 'round' || options.lineCap === 'square')) {
                            texture.lineCap = options.lineCap;
                } else {
                    texture.lineCap = 'butt';
                }
                            
                texture.lineJoin = 'round';
            }
            if (options.fill) {
                texture.fillStyle = options.fillColor || options.color;
            }
        },
        
        _drawPath: function(texture, layer, tilePoint, layerOptions) {
            if (layer.options.visible === false) {
                return;   
            }
            
            var origin = this._map.getPixelOrigin();
            var pixelBounds = this.options.pixelBounds ? this.options.pixelBounds : this._map.getPixelBounds();
            var options = layer.options;
            var dashArray = null;
            var offset = {x : 0, y : 0};
            var scale = 1;
            
            if (tilePoint) {
                var zoom = this._map.getZoom();
                var dz = zoom - layerOptions.maxNativeZoom;
                var tileSize = layerOptions.tileSize;
                var tz = zoom;
                if (dz > 0) {
                    tileSize = tileSize*Math.pow(2, dz);
                    tz = tz -dz;
                }
                
                offset.x = tilePoint.x*tileSize- pixelBounds.min.x - layerOptions.canvasDx*scale;
                offset.y = tilePoint.y*tileSize- pixelBounds.min.y - layerOptions.canvasDy*scale;
            } else {
                offset.x = (origin.x - pixelBounds.min.x);
                offset.y = (origin.y - pixelBounds.min.y);
            }
            
            if (options.dashArray) {
                if (options.dashArray instanceof Array) {
                    dashArray = lang.extend([], options.dashArray);
                } else {
                    var dashParts = options.dashArray.split(',');
                    dashArray = [];
                    for (var i = 0; i < dashParts.length; i++) {
                        dashArray.push(parseInt(dashParts[i], 10));
                    }
                }
                if (dashArray.length < 2) {
                    dashArray = null;
                }
            }
            
            if (!layer._parts) {
                // 円の場合
                if (layer._radius && layer._point) {
                    var p = layer._point;
                    texture.beginPath();
                    texture.arc(
                        (scale * p.x) + offset.x, 
                        (scale * p.y) + offset.y, 
                        layer._radius, 0, 
                        Math.PI * 2, 
                        false);
                    texture.closePath();
                    texture.save();
                            
                    this._updateStyle(texture, layer);
                    var opacity = (this.options.opacity ? this.options.opacity : 1);
                    
                    if (layer.options.fill) {
                        var fillOpacity = 
                            layer.options.fillOpacity || layer.options.fillOpacity === 0 ? 
                                layer.options.fillOpacity : 0;
                        texture.globalAlpha = fillOpacity * opacity;
                        texture.fill();
                    }
                    if (layer.options.stroke) {
                        texture.globalAlpha = 
                            (layer.options.opacity || layer.options.opacity === 0 ? layer.options.opacity: 1);
                        texture.stroke();
                    }
                    texture.restore();
                }
                // アイコン、付箋の場合はMapToImageの_markerPaneToCanvas()でCanvasに投影される
            } else {
                // ライン、ポリゴンの場合
                var n, j, len2, point;
                //var vp = this._map._pathViewport;
                var isPolygon = (layer instanceof leaflet.Polygon || layer instanceof leaflet.Circle);
                
                var parts = (layer._rings ? layer._rings : layer._parts);
                var len = parts.length;
                var buffTexture = null;
                var canvas = null;
                if (len > 1) {
                    buffTexture = texture;
                    canvas = document.createElement('canvas');
                    canvas.width = texture.canvas.width;
                    canvas.height = texture.canvas.height;
                    texture = canvas.getContext('2d');
                }
                
                for (n = 0; n < len; n++) {                    
                    var fromPoint = null;
                    var firstPoint = null;
                    var lastPoint = null;
                    
                    if (parts[n].length > 2 && 
                        (parts[n][0].x !== parts[n][parts[n].length-1].x || 
                            parts[n][0].y !== parts[n][parts[n].length-1].y)) {
                        lastPoint = parts[n][0];
                    }
                    
                    texture.beginPath();
                    
                    len2 = parts[n].length;
                    for (j = 0; j < len2; j++) {
                        point = parts[n][j];
                        var toPoint = {
                            x : ( scale * point.x ) + offset.x,
                            y : ( scale * point.y ) + offset.y
                        };
                        
                        if (j === 0) {
                            firstPoint = toPoint;
                            texture.moveTo(toPoint.x, toPoint.y);
                        } else {
                            if (dashArray && !isPolygon) {
                                // FIXME:
                                /*
                                GSI.Utils.dotLineTo(texture, fromPoint.x, fromPoint.y,
                                    toPoint.x, toPoint.y, dashArray);
                                */
                                console.debug('');
                            } else {
                                if (texture.setLineDash !== undefined) {
                                    texture.setLineDash([]);
                                } else if (texture.mozDash !== undefined) {
                                    texture.mozDash = [];
                                }
                                texture.lineTo(toPoint.x, toPoint.y);
                            }
                        }
                        
                        fromPoint = toPoint;
                    }
                            
                    if (lastPoint && isPolygon) {
                                    
                        var toPoint2 = {
                            x : ( scale * lastPoint.x ) + offset.x,
                            y : ( scale * lastPoint.y ) + offset.y
                        };
                        
                        if (texture.setLineDash !== undefined) {
                            texture.setLineDash([]);
                        } else if (texture.mozDash !== undefined) {
                            texture.mozDash = [];
                        }
                        texture.lineTo(toPoint2.x, toPoint2.y);
                    }
                    
                    if (isPolygon) {
                        texture.closePath();
                    }
                    
                    texture.save();
                            
                    if (isPolygon && n > 0) {
                        texture.globalCompositeOperation  = 'destination-out';
                        texture.globalAlpha =1;
                        texture.fill();
                        texture.globalCompositeOperation = 'source-over';
                    }
                            
                    this._updateStyle(texture, layer);
                    var opacity2 = (this.options.opacity ? this.options.opacity: 1);
                            
                    if (n === 0  && layer.options.fill) {
                        var fillOpacity2 = 
                            layer.options.fillOpacity || layer.options.fillOpacity === 0 ? 
                                layer.options.fillOpacity : 0;
                        texture.globalAlpha = fillOpacity2 * opacity2;
                        texture.fill();
                    }
                    if (layer.options.stroke) {
                        texture.globalAlpha = 
                            layer.options.opacity || layer.options.opacity === 0 ? layer.options.opacity: 1;
                        texture.stroke();
                    }
                    texture.restore();

                }
                
                if (buffTexture) {
                    buffTexture.drawImage(canvas, 0, 0, canvas.width, canvas.height);
                    
                    texture = buffTexture;
                }
            }
            
        }
        
    });

    /*
    leaflet.mapToImage.vectorTileLayer = function(map, layer, options){
        return new leaflet.mapToImage.VectorTileLayer(map, layer, options);
    };
    */

    return VectorTileLayer;
});

