define([
    'leaflet',
    'leaflet-plugins/layer/vector/KML'
], function(leaflet){
    // KMLからLayerへ
    var kmltoLayer = leaflet.CustomKML = leaflet.KML.extend({
        _addKML: function (xml) {
            var features = leaflet.KML.parseKML(xml);
            if (!features || !features.length) { return; }
            var that = this;
            var setLayer = function(aLayer){
                that.fire('addlayer', {layer: aLayer});
                that.addLayer(aLayer);
            };
            features.forEach(function(layers){
                // multi features
                if (!!layers._layers) {
                    layers.eachLayer(function(layer){
                        setLayer(layer);
                    });
                } else {
                    setLayer(layers);
                }
            });
            this.latLngs = leaflet.KML.getLatLngs(xml);
            this.fire('loaded');
        }
    });

    var placemarkToLayer = leaflet.Util.extend(leaflet.KML,{
        parsePlacemark: function (place, xml, style, options) {
            var h, i, j, k, el, il, opts = options || {};

            el = place.getElementsByTagName('styleUrl');
            for (i = 0; i < el.length; i++) {
                var url = el[i].childNodes[0].nodeValue;
                for (var a in style[url]) {
                    if(!style[url].hasOwnProperty(a)){continue;}
                    opts[a] = style[url][a];
                }
            }

            il = place.getElementsByTagName('Style')[0];
            if (il) {
                var inlineStyle = this.parseStyle(place);
                if (inlineStyle) {
                    for (k in inlineStyle) {
                        if(!inlineStyle.hasOwnProperty(k)){continue;}
                        opts[k] = inlineStyle[k];
                    }
                }
            }

            var multi = ['MultiGeometry', 'MultiTrack', 'gx:MultiTrack'];
            for (h in multi) {
                if(!multi.hasOwnProperty(h)){continue;}
                el = place.getElementsByTagName(multi[h]);
                for (i = 0; i < el.length; i++) {
                    return this.parsePlacemark(el[i], xml, style, opts);
                }
            }

            var layers = [];

            var parse = ['LineString', 'Polygon', 'Point', 'Track', 'gx:Track'];
            for (j in parse) {
                if(!parse.hasOwnProperty(j)){continue;}
                var tag = parse[j];
                el = place.getElementsByTagName(tag);
                for (i = 0; i < el.length; i++) {
                    var l = this['parse' + tag.replace(/gx:/, '')](el[i], xml, opts);
                    if (l) { layers.push(l); }
                }
            }

            if (!layers.length) {
                return;
            }
            var layer = layers[0];
            if (layers.length > 1) {
                layer = new leaflet.FeatureGroup(layers);
            }

            var name, descr = '';
            el = place.getElementsByTagName('name');
            if (el.length && el[0].childNodes.length) {
                name   = el[0].childNodes[0].nodeValue;
                layer.options.title = name;
            }
            el = place.getElementsByTagName('description');
            if (el.length && el[0].childNodes.length) {
                descr = el[0].childNodes[0].textContent;
                layer.options.description = descr;
            }

            if (name) {
                layer.on('add', function () {
                    if (name !== '' || name !== 'undefined') {
                        var content = '<h2 style=\"color:red;\">' + name + '</h2>';
                        if (!descr || descr !== '' || descr !== 'undefined') {
                            content += descr;
                        }
                        layer.bindPopup(content);
                    }
                });
            }

            // set drawType to its options
            // icon
            if (layer.options.icon !== undefined) {
                var upperOptions = layer.options;
                var lowerOptions = layer.options.icon.options;
                //icon needs to have the same options at Marker options
                upperOptions.iconUrl = lowerOptions.iconUrl;
                var iconSize = this.getIconSize(upperOptions.iconUrl);
                if (lowerOptions.scale) {
                    var calcIconSize = [];
                    calcIconSize[0] = iconSize[0] * lowerOptions.scale;
                    calcIconSize[1] = iconSize[1] * lowerOptions.scale;
                    layer.options.iconSize = layer.options.icon.options.iconSize = calcIconSize;
                }
                upperOptions.drawType = lowerOptions.drawType = 'Icon';

            // polyline and arrow
            } else if (!layer.options.fill) {
                // there is no difference between polyline and freehand_polyline
                layer.options.drawType = 'polyline';
            } else {
                // polygon has types like 'circle', 'rectangle' but we can't tell from...
                // polygon and freehand_polygon
                layer.options.drawType = 'polygon';
            }

            return layer;
        },

        parseStyle: function (xml) {
            var style = {};
            var poptions = {};
            var ioptions = {};
            var el;
            var id;

            var attributes = {
                color   : true,
                width   : true,
                scale   : true,
                Icon    : true,
                href    : true,
                hotSpot : true
            };

            function _parse (xml) {
                var options = {};
                for (var i = 0; i < xml.childNodes.length; i++) {
                    var e = xml.childNodes[i];
                    var key = e.tagName;
                    if (!attributes[key]) { continue; }
                    if (key === 'hotSpot') {
                        for (var j = 0; j < e.attributes.length; j++) {
                            options[e.attributes[j].name] = e.attributes[j].nodeValue;
                        }
                    } else {
                        var value = e.childNodes[0].nodeValue;
                        if (key === 'color') {
                            options.opacity = parseInt(value.substring(0, 2), 16) / 255.0;
                            options.color = '#' + value.substring(6, 8) + value.substring(4, 6) + value.substring(2, 4);
                        } else if (key === 'width') {
                            options.weight = value;
                        } else if (key === 'scale') {
                            options.scale = value;
                        } else if (key === 'Icon') {
                            ioptions = _parse(e);
                            if (ioptions.href) { options.href = ioptions.href; }
                        } else if (key === 'href') {
                            options.href = value;
                        }
                    }
                }
                return options;
            }

            el = xml.getElementsByTagName('LineStyle');
            if (el && el[0]) { style = _parse(el[0]); }
            el = xml.getElementsByTagName('PolyStyle');
            if (el && el[0]) { poptions = _parse(el[0]); }
            if (poptions.color) { style.fillColor = poptions.color; }
            if (poptions.opacity) { style.fillOpacity = poptions.opacity; }
            el = xml.getElementsByTagName('IconStyle');
            if (el && el[0]) { ioptions = _parse(el[0]); }
            if (ioptions.href) {
                style.icon = new leaflet.KMLIcon({
                    iconUrl   : ioptions.href,
                    shadowUrl : null,
                    scale     : ioptions.scale,
                    anchorRef : {x: ioptions.x, y: ioptions.y},
                    anchorType:    {x: ioptions.xunits, y: ioptions.yunits}
                });
            }

            id = xml.getAttribute('id');
            if (id && style) {
                style.id = id;
            }

            return style;
        },

        getIconSize: function(iconUrl) {
            var image = new Image();
            image.src = iconUrl;
            var iconSize = [image.naturalWidth, image.naturalHeight];
            if (iconSize[0] === 0) {iconSize = [20, 20];}
            return iconSize;
        }
    });
    return [kmltoLayer, placemarkToLayer];
});
