define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/DrawDialogFileImportPage.html',
    'idis/view/_IdisWidgetBase',
    'dijit/Dialog',
    'dojo/_base/lang',
    'dojo/on',
    'dojo/topic',
    'idis/service/Requester',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    'idis/view/draw/_LoadShapeFile',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dojox/form/Uploader',
    'idis/view/form/RadioGroup',
    'idis/view/form/Button',
    '../view/form/DrawLayerSelector',
    '../view/form/PubStatusSelector'
], function(module, declare, template, _IdisWidgetBase, Dialog, lang, on, topic,
    Requester, InfoDialog, Loader, LoadShapeFile) {

    var content = declare('_DrawDialogFileImportPageContent', _IdisWidgetBase, {
        templateString : template,
        baseClass: 'drawDialog-Container',
        widgetsInTemplate : true
    });

    var container = declare(module.id.replace(/\//g, '.'), [Dialog], {

        // DrawPanelへのpub
        IMPORT_FILE : '/app/draw/DrawPanel::drawLoadFromImportDialog',
        RM_IMPT_EVT : '/app/draw/DrawPanel::removeImportEvent',

        //RadioButtonのハンドリング
        _firstClick: true,
        // 自分の状態を管理
        _ActiveMode: 'local',
        //イベント破棄用
        _events: null,

        constructor : function(options){
            lang.mixin(this, options);
            this.title = 'ファイルの読込';
            this.inherited(arguments);
            this.inner = new content();
            this._events = [];
        },

        postCreate : function(){
            this.inherited(arguments);
            this.set('content', this.inner);
        },

        show: function(){
            this.inherited(arguments);
            this._init();
            //イベント定義
            this._setEvents();
        },

        _setEvents: function(){
            var importSwitchEvt = on(this.inner.importSwitch, 'click', lang.hitch(this, function(e){
                // Dialogの表示制御
                if (!this._firstClick) {
                    this._firstClick = true;
                    return;
                } else {
                    var target = e.target.value === undefined ?
                                 e.target.children[0].children[0].value :
                                 e.target.value;
                    // Local
                    if (target === '0') {
                        this.inner.fromLocal.style.display = '';
                        this.inner.fromServer.style.display = 'none';
                        this._ActiveMode = 'local';
                     //server
                    } else if (target === '1') {
                        this.inner.fromLocal.style.display = 'none';
                        this.inner.fromServer.style.display = '';
                        this._ActiveMode = 'server';
                    }
                    this._firstClick = false;
                }
            }));

            var commitEvt = on(this.inner.importDialogCommit, 'click', lang.hitch(this, function(){
                if(this.validate()){
                    this.importCommit();
                }
            }));

            // cancel
            var cancelEvt = on(this.inner.importDialogCancel, 'click', lang.hitch(this, function(){
                this.hide();
            }));

            // ダイアログの「×」ボタン
            var cancelEvent02 = on(this.closeButtonNode, 'click', lang.hitch(this, function(){
                this._removeEvents();
            }));

            // ダイアログがクローズされたら当該作図オブジェクトに対するイベントを破棄
            var hideEvt = on(this, 'hide', lang.hitch(this, function(){
                this._events.push(hideEvt);
                this._removeEvents();
            }));

            this._events.push(
                importSwitchEvt, commitEvt,
                cancelEvt, cancelEvent02, hideEvt
            );

            // DrawPanelから呼び出されるイベント削除
            var removeEvt = topic.subscribe(this.RM_IMPT_EVT, lang.hitch(this, function(){
                this._removeEvents();
                removeEvt.remove();
            }));
        },

        importCommit: function(){
            var pubURL = this.IMPORT_FILE;
            if (this._ActiveMode === 'local') {
                var reader = new FileReader();
                var file = this.inner.importFile._files[0];
                var tmpFileName = file.name;
                var fileName = tmpFileName.slice(0, tmpFileName.indexOf('.'));
                var extension = this.inner.fileExtension.value;
                var filePath = window.URL.createObjectURL(file);
                // shapefileなら
                if (extension === 'zip') {
                    // http://dit-aomori-gis01:6080/arcgis/services/ShapeZipToGeoJson/GPServer
                    var urlList = {
                        baseUrl : '/arcgis/rest/services/ShapeZipToGeoJson/GPServer',
                        upload  : '/uploads/upload',
                        execute : '/Shape2JSON/execute'
                    };
                    var callback = function(json){
                        topic.publish(pubURL, {
                            'fileName' : fileName,
                            'extension': extension,
                            'json'     : json
                        });
                    };
                    LoadShapeFile.execute(urlList, file, callback).then(function(res){
                        if (res) {
                            InfoDialog.show('失敗', res.details[0]);
                        }
                    });

                // KML, GeoJSONは同じ処理
                } else {
                    reader.onload = function(e){
                        topic.publish(pubURL, {
                            'fileName' : fileName,
                            'extension': extension,
                            'json'     : e.target.result,
                            'filePath' : filePath
                        });
                    };
                    reader.readAsText(file);
                }
                this.hide();
            } else {
                var pLayerId = this.inner.drawLayer.value;
                var tmpName  = this.inner.drawLayer.status.textContent;
                var layerName = tmpName.slice(tmpName.lastIndexOf('/')+2, tmpName.length);
                // 作図jsonの読み込み
                var param = '?layerId=' + pLayerId + '&layerName=' + layerName;

                var serverPromise = Requester.get('/api/draw/getJson' + param).then(function(data) {
                    topic.publish(pubURL, {
                        'fileName' : layerName,
                        'extension': 'GeoJSON',
                        'json'     : data.items[0]
                    });
                }, function(err) {
                  console.log(err);
                  InfoDialog.show('失敗', '情報の取得に失敗しました。');
                });
                Loader.wait(serverPromise);
                this.hide();
            }
        },

        _init: function(){
            // 開かれたときにCheckを入れておく。
            this.inner.importSwitch.getChildren()[0].checked = true;
            this.inner.importSwitch.getChildren()[1].checked = false;
            this.inner.fromLocal.style.display = '';
            this.inner.fromServer.style.display = 'none';
            this._ActiveMode = 'local';
            this.inner.fileExtension.set('value', '');
        },

        validate: function(){

            if (this._ActiveMode === 'server' && !this.inner.drawLayer.value) {
                InfoDialog.show('入力エラー', 'ファイルを選択してください。');
                return false;
            }
            if (this._ActiveMode === 'local') {
                if (this.inner.importFile.getFileList().length === 0) {
                    InfoDialog.show('入力エラー', 'ファイルを選択してください。');
                    return false;
                }
                if (!this.inner.fileExtension.value) {
                    InfoDialog.show('入力エラー', 'ファイル形式を入力してください。');
                    return false;
                }
                var fileName = this.inner.importFile.getFileList()[0].name;
                var extension = this.inner.fileExtension.value;
                if (!this.checkExtension(fileName)) {
                    InfoDialog.show('入力エラー', 'ファイルはgeojson/KML/shapefileを選択してください。');
                    return false;
                }
                if (fileName.toUpperCase().indexOf('.'+extension.toUpperCase()) === -1) {
                    InfoDialog.show('入力エラー', 'ファイル形式を正しく指定してください。');
                    return false;
                }

            }
            return true;
        },

        checkExtension: function(fileName) {
            var validExtension = ['.GEOJSON','.KML','.ZIP'];
            var validFile = false;
            validExtension.some(function(ex){
                if(!validFile){
                    if (fileName.toUpperCase().indexOf(ex) !== -1 ) {
                        validFile = true;
                    }
                }
            });
            return validFile;
        },

        _removeEvents: function(){
            this._events.forEach(lang.hitch(this, function(event){
                event.remove();
            }));
        }
    });

    return container;

});
