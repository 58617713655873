/**
 * 表示情報選択ダイアログ用モジュール。
 * @module app/view/page/StatisticsDataDialog
 */
define([
    'module', // モジュールのパスを返す
    'dijit/form/Select',
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/json',
    'dojo/dom-construct',
    'dojo/dom-style',
    'dojo/text!./templates/StatisticsDataDialog.html', // テンプレート文字列
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase' ,// 共通基底クラス
    'idis/view/Loader',
    'app/observationstation/model/DataKind',
    // 以下、変数で受けないモジュール
    'dijit/layout/ContentPane',
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/RadioButton',
    // 'dijit/form/Select',
    'idis/view/form/Button',
    'idis/view/form/DateInput'
], function(module, Select, declare, lang, Deferred, JSON, domCon, domStyle, template,
    InfoDialog, _PageBase, Loader, DataKind) {

    /**
     * 統計情報のダウンロード可能開始年月日
     * @private
     */
    // TODO 正確な可能開始日をセット
    var _START_DATE = '2012/04/01';

    /**
     * <クラスの説明>
     * @class StatisticsDataDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/StatisticsDataDialog~StatisticsDataDialog# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素のCSSクラス
        baseClass: 'observation-StatisticsDataDialog',

        /**
         * 選択中のデータ種別
         */
        dataKind: null,

        /**
         * ダウンロードファイル名
         */
        _fileMappin: [{
        // Excel
            'dataKind' : DataKind.RAINFALL,
            'reqid' : 'RAIN_MON_EX',
            'fileName' : 'RainMon.xlsx',
            'fileNameJp' : '雨量観測情報一覧（月報）.xlsx',
            'formType' : 'MONTHLY',
            'fileType' : 'excel'
        },{
            'dataKind' : DataKind.RAINFALL,
            'reqid' : 'RAIN_DAY_EX',
            'fileName' : 'RainDay.xlsx',
            'fileNameJp' : '雨量観測情報一覧（日報）.xlsx',
            'formType' : 'DAYLY',
            'fileType' : 'excel'
        },{
            'dataKind' : DataKind.RIVER_LEVEL,
            'reqid' : 'WATER_MON_EX',
            'fileName' : 'WaterMon.xlsx',
            'fileNameJp' : '河川水位観測情報一覧（月報）.xlsx',
            'formType' : 'MONTHLY',
            'fileType' : 'excel'
        },{
            'dataKind' : DataKind.RIVER_LEVEL,
            'reqid' : 'WATER_DAY_EX',
            'fileName' : 'WaterDay.xlsx',
            'fileNameJp' : '河川水位観測情報一覧（日報）.xlsx',
            'formType' : 'DAYLY',
            'fileType' : 'excel'
        },{
            'dataKind' : DataKind.TIDE_LEVEL,
            'reqid' : 'TIDE_MON_EX',
            'fileName' : 'TideMon.xlsx',
            'fileNameJp' : '潮位観測情報一覧（月報）.xlsx',
            'formType' : 'MONTHLY',
            'fileType' : 'excel'
        },{
            'dataKind' : DataKind.TIDE_LEVEL,
            'reqid' : 'TIDE_DAY_EX',
            'fileName' : 'TideDay.xlsx',
            'fileNameJp' : '潮位観測情報一覧（日報）.xlsx',
            'formType' : 'DAYLY',
            'fileType' : 'excel'
        },{
            'dataKind' : DataKind.RIVER_BASIN,
            'reqid' : 'RIVER_MON_EX',
            'fileName' : 'RiverMon.xlsx',
            'fileNameJp' : '流域平均雨量情報一覧（月報）.xlsx',
            'formType' : 'MONTHLY',
            'fileType' : 'excel'
        },{
            'dataKind' : DataKind.RIVER_BASIN,
            'reqid' : 'RIVER_DAY_EX',
            'fileName' : 'RiverDay.xlsx',
            'fileNameJp' : '流域平均雨量情報一覧（日報）.xlsx',
            'formType' : 'DAYLY',
            'fileType' : 'excel'
        },{
            'dataKind' : DataKind.DAM,
            'reqid' : 'DAM_MON_EX',
            'fileName' : 'DamMon.xlsx',
            'fileNameJp' : 'ダム諸量情報一覧（月報）.xlsx',
            'formType' : 'MONTHLY',
            'fileType' : 'excel'
        },{
            'dataKind' : DataKind.DAM,
            'reqid' : 'DAM_DAY_EX',
            'fileName' : 'DamDay.xlsx',
            'fileNameJp' : 'ダム諸量情報一覧（日報）.xlsx',
            'formType' : 'DAYLY',
            'fileType' : 'excel'
        },

        // PDF
          {
            'dataKind' : DataKind.RAINFALL,
            'reqid' : 'RAIN_MON_EX',
            'fileName' : 'RainMon.pdf',
            'fileNameJp' : '雨量観測情報一覧（月報）.pdf',
            'formType' : 'MONTHLY',
            'fileType' : 'pdf'
        },{
            'dataKind' : DataKind.RAINFALL,
            'reqid' : 'RAIN_DAY_EX',
            'fileName' : 'RainDay.pdf',
            'fileNameJp' : '雨量観測情報一覧（日報）.pdf',
            'formType' : 'DAYLY',
            'fileType' : 'pdf'
        },{
            'dataKind' : DataKind.RIVER_LEVEL,
            'reqid' : 'WATER_MON_EX',
            'fileName' : 'WaterMon.pdf',
            'fileNameJp' : '河川水位観測情報一覧（月報）.pdf',
            'formType' : 'MONTHLY',
            'fileType' : 'pdf'
        },{
            'dataKind' : DataKind.RIVER_LEVEL,
            'reqid' : 'WATER_DAY_EX',
            'fileName' : 'WaterDay.pdf',
            'fileNameJp' : '河川水位観測情報一覧（日報）.pdf',
            'formType' : 'DAYLY',
            'fileType' : 'pdf'
        }],

        constructor: function() {
            // 現在日時
            this.currentDate = new Date();
            // ダウンロード可能開始日
            this.startDate = new Date(_START_DATE);
        },

        initDialog: function(){
            this.form.reset();
            // 「日付」のセレクトボックスを設定する
            this.setDateSelect();
            // 「年」のセレクトボックスを設定する
            this.setYearSelect();
            // 「月」のセレクトボックスを設定する
            this._updateMonthSelect();
            // 表示項目を設定する
            this.onChangeFormType();
        },

        // 帳票出力を行う前に入力チェックを行う
        onOutputExcelButtonClick : function() {
            // フォームのバリデーションを実施
            if (!this.form.validate()) {
                return false;
            }
            // // 固有のバリデーションを実施
            // if (!this.validate()) {
            //     return false;
            // }
            this.getParent().hide();
            // ダウンロード
            var fileType = 'excel';
            this.downloadFile(fileType);
        },

        // 帳票出力を行う前に入力チェックを行う
        onOutputPdfButtonClick : function() {
            // フォームのバリデーションを実施
            if (!this.form.validate()) {
                return false;
            }
            // 固有のバリデーションを実施
            if (!this.validate()) {
                return false;
            }
            this.getParent().hide();
            // ダウンロード
            var fileType = 'pdf';
            this.downloadFile(fileType);
        },

        //帳票出力
        downloadFile : function(fileType) {
            var value = this.form.get('value');
            // 検索条件をセット
            var paramList = [];
            switch (value.formType) {
                case 'DAYLY':
                    // 日報-観測日付開始、終了
                    paramList = [
                        //YYYYMMDD形式
                        // {key: 'fromrepdate', value: this.dateSelectFrom.displayedValue.replace(/\//g,'')},
                        // {key: 'torepdate', value: this.dateSelectTo.displayedValue.replace(/\//g,'')}
                        {key: 'repdate', value: this.dateSelectFrom.displayedValue.replace(/\//g,'')}
                    ];
                    break;
                case 'MONTHLY':
                    // 月報-観測年月
                    paramList = [
                        //YYYYMM形式
                        {key: 'repMonth', value: [value.year, value.month].join('')}
                    ];
                    break;
            }
            // 水位のみ地域もセット
            // if (this.dataKind === DataKind.RIVER_LEVEL) {
            //     paramList.push({key: 'regionCd', value: this.region.get('value')});
            // }

            // 連携ファイル名とリクエストIDを取得
            var fileInf = this._fileMappin.filter(lang.hitch(this, function(item){
                if(item.formType===value.formType&&
                    item.dataKind===this.dataKind&&
                    item.fileType===fileType) { return true; }
                }))[0];
            
            // パラメータをセット
            var data4xoblosDownload = {
                fileName: fileInf.fileName,
                reqid: fileInf.reqid,
                paramList: paramList,
                fileType: fileType
            };

            var promise = this.download(data4xoblosDownload).then(lang.hitch(this, function() {
                    this.infoDialog = new InfoDialog({
                        title : 'ダウンロード完了',
                        content : 'ダウンロードが完了しました。'
                    });
                    this.infoDialog.show();

                }), lang.hitch(this, function(error) {
                    console.error(error);
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : 'エラーが発生しました。管理者にお問い合わせください。'
                    });
                    this.infoDialog.show();
            }));

            Loader.wait(promise);
        },

        // 帳票のダウンロード
        download: function(data) {
            var deferred = new Deferred();

            var xhr = new XMLHttpRequest();
            xhr.open('POST', '/api/xoblos/download', true);
            xhr.responseType = 'arraybuffer';
            xhr.setRequestHeader( 'Content-Type', 'application/json' );
            var self = this;
            xhr.onload = function() {

                // エラー時は処理を止める
                if(xhr.status !== 200){
                    deferred.reject('status error:'+ xhr.status);
                    return;
                }
                // ファイル名をレスポンスヘッダーから取り出す
                var contentDisposition = this.getResponseHeader('content-disposition');
                var inputFileName = contentDisposition.replace(/^.*"(.*)"$/, '$1');

                var arrayBuffer = this.response;
                var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});
                // ファイル名を取得
                var baseFileName = self._fileMappin.filter(function(item){
                    if(item.fileName===inputFileName) { return true; }
                })[0].fileNameJp;
                var fileName = baseFileName.replace(/^.*'(.*)'$/, '$1');

                // IE10+
                if(window.navigator.msSaveOrOpenBlob){
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    // 擬似的にAタグを作成
                    var link = document.createElement('a');
                    link.style = 'display: none';
                    document.body.appendChild(link);

                    // AタグのURLにバイナリデータをセット
                    var url = window.URL.createObjectURL(blob);
                    link.href = url;

                    // ファイル名をセット
                    link.download = fileName;

                    // 擬似的にリンクをクリック
                    link.click();
                    // 参照を解放
                    window.URL.revokeObjectURL(url);
                    link.remove();
                }

                deferred.resolve();

            };
            xhr.send(JSON.stringify(data));

            return deferred.promise;
        },

        /**
         * 出力帳票の種類を変更したときに入力項目を変更する
         * value
         * 0:日報 1:月報
         */
        onChangeFormType: function() {
            switch (this.form.get('value').formType) {
                case 'DAYLY':
                    domStyle.set(this.inputArea4dayly, 'display', '');
                    domStyle.set(this.inputArea4monthly, 'display', 'none');
                    break;
                case 'MONTHLY':
                    domStyle.set(this.inputArea4dayly, 'display', 'none');
                    domStyle.set(this.inputArea4monthly, 'display', '');
                    break;
            }
            // データ種別セレクタを初期化
            this.initDataKindSelect();
        },

        /**
         * 画面から呼ばれる。画面によって初期選択されている種別を設定する。
         */
        setInitKind: function(kind) {
            this.dataKind = kind;
            // ダイヤログ初期化
            this.initDialog();
        },

        /**
         * 「年」セレクトボックスが変更されたときの処理
         */
        onChangeYear: function() {
            // 選択年に合わせて月のセレクトボックスの表示を更新する
            this._updateMonthSelect();
        },

        /**
         * 月や日付を2桁にゼロpaddingする
         */
        _zeroPadding: function(month) {
            return ('00' + month).slice(-2);
        },

        /**
         * 「月」のセレクト・ボックスを表示を「年」の選択に応じて更新する
         * 現在年が選択されている場合は、現在月までの選択を有効化、それ以降は無効化する
         */
        _updateMonthSelect: function() {
            // 現在年
            var currentYear = this.currentDate.getFullYear();
            var selectedYear = this.yearSelect.get('value');

            if (currentYear === Number(selectedYear)) {
                // 現在年が選択されている場合、現在月以降の選択を無効化する
                // 現在月
                var end = this.currentDate.getMonth() + 1;

                // 1月から12月までをループして設定
                for(var i = 1; i < 13; i++) {
                    if (end >= i) {
                        this.monthSelect.getOptions(this._zeroPadding(i)).disabled = false;
                    } else {
                        this.monthSelect.getOptions(this._zeroPadding(i)).disabled = true;
                    }
                }
                // 現在月をデフォルト値にする
                this.monthSelect.set('value', this._zeroPadding(end));
            } else {
                // 全ての月を有効化する
                for(var j = 1; j < 13; j++) {
                    this.monthSelect.getOptions(this._zeroPadding(j)).disabled = false;
                }
            }

            this.monthSelect.startup();
        },

        /**
         * 「データ種別」のセレクトボックスを初期化する
         */
        initDataKindSelect: function() {
            // データ種別選択値セット
            var options = [
                { label: '雨量', value: DataKind.RAINFALL },
                { label: '河川水位', value: DataKind.RIVER_LEVEL }
                // { label: '流域平均雨量', value: DataKind.RIVER_BASIN },
                //{ label: '潮位', value: DataKind.TIDE_LEVEL },
                //{ label: 'ダム', value: DataKind.DAM }
            ];
            this.dataKindSelect = new Select({name: 'reportStatus',
                style:'width: 7em;',
                options: options,
                value : this.dataKind
            });
            this.dataKindWrapper.innerHTML = '';
            // データ種別によって、地域セレクタの表示切替
            // this.setRegionSelect();

            domCon.place(this.dataKindSelect.domNode, this.dataKindWrapper);
            // データ種別を変更したら値を取得
            this.dataKindSelect.on('change', lang.hitch(this, function(dataKind){
                this.dataKind = dataKind;
                // this.setRegionSelect();
            }));
        },

        /**
         * 「日付」のセレクトボックスを設定する
         */
        setDateSelect: function() {
            // 開始年月～今日まで
            this.dateSelectFrom.set('constraints', {min:this.startDate, max:this.currentDate});
            // デフォルトは今日
            this.dateSelectFrom.set('value', this.currentDate);
            // 開始年月～今日まで
            this.dateSelectTo.set('constraints', {min:this.startDate, max:this.currentDate});
            // デフォルトは今日
            this.dateSelectTo.set('value', this.currentDate);
        },

        /**
         * 「年」のセレクトボックスを設定する
         */
        setYearSelect: function() {

            // 現在の年
            var end = this.currentDate.getFullYear();
            // 開始年
            var start = this.startDate.getFullYear();

            // 現在年から開始年までループで回し、セレクトボックスのoptionをセットする
            var current = end;
            var yearOptions = [];
            while (start <= current) {
                // labelは文字列にしておく必要がある
                yearOptions.push({ value: current + '', label: current + '' });
                current--;
            }

            // セレクトボックスを設定
            this.yearSelect.set('options', yearOptions);
            // デフォルトは現在年
            this.yearSelect.set('value', end + '');
            this.yearSelect.startup();
        },

        /**
         * 「地域」セレクタを設定する
         */
        // setRegionSelect: function () {
        //     // 水位のときだけ、地域を表示
        //     domStyle.set(this.inputArea4region, 'display', 'none');
        //     if (this.dataKind === DataKind.RIVER_LEVEL) {
        //         domStyle.set(this.inputArea4region, 'display', '');
        //     }
        // },

        validate: function() {
            var value = this.form.get('value');
            var errMsg = [];
            // 日報の場合
            if(value.formType==='DAYLY'&&
                ((!value.dateSelectFrom||!value.dateSelectTo)||
                value.dateSelectTo-value.dateSelectFrom>366*24*60*60*1000||
                value.dateSelectTo-value.dateSelectFrom<0)){
                // 日報の観測期間指定1年間
                errMsg.push('対象期間を一週間以内で指定してください。');
            }
            // ダイヤログ表示
            if (errMsg.length>0) {
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : errMsg.join('<br>')
                });
                this.infoDialog.show();
                return false;
            }
            return true;
        }
    });
});
