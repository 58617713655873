define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--sending',

        /**
         * 各列の定義
         * @type {Object[]}
         */

        columns: [
          // ボタン
          helper.buttonColumn('detail', '選択'),
          helper.column('templateSendTypeName', 'テンプレート配信種別', {sortable: false}),
          helper.column('municipalityName', '市町', {sortable: false}),
          helper.column('name', 'テンプレート名', {sortable: false}),
          helper.column('subject', '件名', {sortable: false}),
          helper.column('bodyText', '本文', {sortable: false}),
          helper.column('lalertCategory', 'Lアラート情報種別', {sortable: false,
            formatter:function(value) {return value ? Object.keys(value).map(function(e){return value[e];}) : 0;}
          }),
          helper.column('lalertUrgency', 'Lアラート情報識別区分', {sortable: false,
            formatter:function(value) {return value ? Object.keys(value).map(function(e){return value[e];}) : 0;}
          })
        ]
    }
    );
});
