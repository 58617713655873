/**
 * 入力文字数をチェックするtextarea用ウィジェット。
 */
define([
    'module',
    'dojo/_base/declare',
    'dijit/form/SimpleTextarea',
    './WordCountTextBox'
], function(module, declare, SimpleTextarea, WordCountTextBox) {
    return declare(module.id.replace(/\//g, '.'), [WordCountTextBox, SimpleTextarea]);
});
