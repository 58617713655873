/**
 * 指示共有グループ登録用ダイアログモジュール。
 * @module app/chat/ChatShareDetailDialog
 */
define([
    'module',
    'leaflet',
    'dojo/on',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/dom-style',
    'dojo/dom-construct',
    'dojo/text!./templates/ChatShareDetailDialog.html',
    './_ChatGroupDialogBase',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    'idis/service/iframe',
    'idis/service/Requester',
    'exif-js/exif',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog',
    // 以下、変数で受けないモジュール
    'app/view/form/DisasterPreventionMunicOrgSelector',
    'idis/view/form/WordCountTextarea'
], function(module, leaflet, on, declare, lang, domClass, domStyle, domConstruct, template,
    _ChatGroupDialogBase, InfoDialog, Loader, iframe, Requester, exif, Menu, MenuItem,
    popup, TooltipDialog) {

    return declare(module.id.replace(/\//g, '.'), _ChatGroupDialogBase,
    {
        // テンプレート文字列
        templateString: template,

        constructor: function() {
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * ダイアログを初期化する。
         */
        initDialog: function(data) {
            this._userId = data.user.userId;
            this._chatGroupId = data.chatGroupId;
            // 表示情報をセット
            this.contentType.innerHTML = data.dpDistributionHistId ? '緊急連絡' :
                data.chatId ? '通常' : '気象情報';
            this.userName.innerHTML = data.user.userName ? data.user.userName : '-';
            if (data.dpDistributionHistId || !data.chatId) {
                // 「動員指示・気象情報」
                domStyle.set(this.officialPositionNameArea, 'display', 'none');
                domStyle.set(this.attachFileArea, 'display', 'none');
            } else {
                // チャット
                this.officialPositionName.innerHTML = 
                    data.user.officialPositionName ? data.user.officialPositionName : '-';
                domStyle.set(this.officialPositionNameArea, 'display', '');
                domStyle.set(this.attachFileArea, 'display', '');
                // プレビューを初期化
                this.clearPreview();
                // 添付ファイルを設定
                if(data.attachFileName && data.attachFilePath) {
                    this.showPreview(data, false);
                } else {
                    this.preview.innerHTML = '-';
                }
            }
            this.sendTimestamp.innerHTML = data.sendTimestamp ? data.sendTimestamp : '-';
            this.body.set('value', data.body);
        },

        /**
         * 添付ファイルのプレビューを表示する。
         */
        showPreview: function(data, exifFlg) {
            var dataUri = data.attachFilePath.replace('out/', 'data/');
            var fileName = data.attachFileName;
            var self = this;

            // 画像ファイルの場合
            if(fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
            fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
            fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
            fileName.indexOf('.gif') !== -1 || fileName.indexOf('.bmp') !== -1){
                var image = new Image();

                //JPEGファイルの場合、EXIFデータの取得を実行する
                if(fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
                fileName.indexOf('.JPG') !== -1 ||fileName.indexOf('.JPEG') !== -1) {
                    var img = null;
                    this.own(on(image, 'load', lang.hitch(this, function(e) {
                        img = e.target;

                        if(exifFlg) {
                            this.getExifData(img, this);
                        }
                    })));
                }
                image.src = dataUri;
                domClass.add(image, 'is-showPreview');
                domConstruct.place(image, this.preview);
                //メニューの作成
                this.createMenu(image, dataUri, fileName, self, false);

            } else if (fileName.indexOf('.xls') !== -1 || fileName.indexOf('.xlsx') !== -1) {
                var excel = new Image();
                excel.src = 'images/excelicon.png';
                domClass.add(excel, 'is-showPreview');
                domConstruct.place(excel, this.preview);
                //メニューの作成
                this.createMenu(excel, dataUri, fileName, self, false);
            } else if (fileName.indexOf('.pdf') !== -1) {
                var pdf = new Image();
                pdf.src = 'images/pdficon.png';
                domClass.add(pdf, 'is-showPreview');
                domConstruct.place(pdf, this.preview);
                //メニューの作成
                this.createMenu(pdf, dataUri, fileName, self, false);
            } else if (fileName.indexOf('.doc') !== -1 || fileName.indexOf('.docx') !== -1) {
                var word = new Image();
                word.src = 'images/wordicon.png';
                domClass.add(word, 'is-showPreview');
                domConstruct.place(word, this.preview);
                //メニューの作成
                this.createMenu(word, dataUri, fileName, self, false);
            } else if (fileName.indexOf('.ppt') !== -1 || fileName.indexOf('.pptx') !== -1) {
                var ppt = new Image();
                ppt.src = 'images/ppticon.png';
                domClass.add(ppt, 'is-showPreview');
                domConstruct.place(ppt, this.preview);
                //メニューの作成
                this.createMenu(ppt, dataUri, fileName, self, false);
            } else if (fileName.indexOf('.zip') !== -1) {
                var zip = new Image();
                zip.src = 'images/zipicon.png';
                domClass.add(zip, 'is-showPreview');
                domConstruct.place(zip, this.preview);
                //メニューの作成
                this.createMenu(zip, dataUri, fileName, self, false);
            } else {
                var other = new Image();
                other.src = 'images/othericon.png';
                domClass.add(other, 'is-showPreview');
                domConstruct.place(other, this.preview);
                //メニューの作成
                this.createMenu(other, dataUri, fileName, self, false);
            }
        },

        /**
        * JPEGファイルの位置情報を取得する
        */
        getExifData: function(img, self) {
            console.log('getting exif data start');
            exif.getData(img, function(){

                var latitude = exif.getTag(this, 'GPSLatitude');
                var longitude = exif.getTag(this, 'GPSLongitude');

                if(typeof latitude === 'undefined' || typeof longitude === 'undefined'){
                    console.log('GPS data is unavailable.');
                }else{
                    console.log('GPS data is available.');
                    var f = function(number){
                        return number[0].numerator + number[1].numerator /
                        (60 * number[1].denominator) + number[2].numerator / (3600 * number[2].denominator);
                    };
                    self.chain.confirm('この画像の位置情報を使用しますか？', function(chain) {
                        // 位置情報を設定する
                        self.pointLat = f(latitude);
                        self.pointLng = f(longitude);
                        // 地図にマークして中心に移動する
                        self.addMark(self.pointLat, self.pointLng, self);
                        self.map.setView([self.pointLat, self.pointLng], 14);
                        //ダイアログを閉じる
                        chain.hide();
                    });
                }
            });
        },

        /**
        * 添付ファイルのサムネイル上にメニューを作る
        */
        createMenu: function(newNode, uri, fileName, self, pic) {
            var menu = new Menu({
                targetNodeId: newNode
            });
            menu.set('style', {'border': 'none', 'box-shadow': 'none'});

            //ダウンロード操作用
            var download = null;
            var userAgent = window.navigator.userAgent.toLowerCase();
            if(userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)){
                var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + uri;
                // IEの場合、download属性が効かないため、右クリック保存などaタグ機能を無効化.
                // ダウンロード関数により元ファイル名でのダウンロードを可能にする.
                download = domConstruct.create('a', {href: '#'});
                //クリックでファイル取得処理に入る
                download.onclick = function() {
                    self.downloadFile(url, fileName);
                };
            }else{
                // FF, Chromeの場合、download属性でファイルダウンロード
                download = domConstruct.create('a', {
                    href: uri,
                    download: fileName
                });
            }

            // ファイル名とメニューとの境界線をセット
            var contentNode = domConstruct.create('div');
            contentNode.innerHTML = fileName;
            domConstruct.place('<hr color=#b0c4de>', contentNode);
            if (pic){
                var previewUri = self.getPreviewUri(uri);
                domConstruct.place('<img src="'+ previewUri + '" width="110">', contentNode);
            }

            //メニューをセット
            domConstruct.place(menu.domNode, contentNode);
            var tooltip = new TooltipDialog({
                content: contentNode
            });
            //
            tooltip.containerNode.onmouseleave = function() {
                popup.close(tooltip);
            };

            // 画像ファイルの場合のみ'開く'をメニューに追加する
            if(fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
            fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
            fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
            fileName.indexOf('.gif') !== -1 || fileName.indexOf('.bmp') !== -1){
                menu.addChild(new MenuItem({
                    label: '開く',
                    iconClass: 'dijitEditorIcon dijitEditorIconInsertImage',
                    onClick: function() {
                        console.log('file open');
                        window.open(uri);
                    }
                }));
            }

            menu.addChild(new MenuItem({
                label: 'ダウンロード',
                iconClass: 'dijitIconSave',
                onClick: function(e) {
                    console.log('file download');
                    console.log(e);
                    //IE対策
                    if(userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)){
                        download.onclick();
                    } else {
                        download.click();
                    }
                }
            }));

            menu.startup();
            //メニュー表示処理
            this.own(on(newNode, 'mouseover', lang.hitch(this, function() {
                popup.open({
                    popup: tooltip,
                    around: newNode,
                    orient: ['above-centered']
                });
            })));
            //画面破棄時に一緒に破棄する
            this.own(tooltip);
        },

        /**
        * 添付ファイルをダウンロードする。
        */
        downloadFile: function(url, name) {
            // Dojoのrequestor(dojo/request/xhr)のresponseType（handleAsプロパティ）で、
            // バイナリ（arraybuffer, blob）が利用できなかったためネイティブJSを利用している.
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'arraybuffer';
            xhr.onload = function() {

                var arrayBuffer = this.response;

                var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});

                // IE10+
                if(window.navigator.msSaveOrOpenBlob){
                    window.navigator.msSaveOrOpenBlob(blob, name);
                }

            };
            xhr.send();

            return false;

        },

        /**
        * プレビューをクリアする
        **/
        clearPreview: function() {

            var length = this.preview.childNodes.length;
            for(var i = 0; i < length; i++) {
                domConstruct.destroy(this.preview.childNodes[0]);
            }

            if(this._attachFileList) {
                this._attachFileList.splice(0, this._attachFileList.length);
            }
        },

        /**
        * プレビュー用の画像ファイルのURIを返す
        **/
        getPreviewUri : function(uri) {
            var previewUri = uri;
            // 画像ファイルの場合
            if(uri.indexOf('.jpg') !== -1 ||uri.indexOf('.jpeg') !== -1 ||
            uri.indexOf('.png') !== -1 || uri.indexOf('.JPG') !== -1 ||
            uri.indexOf('.JPEG') !== -1 || uri.indexOf('.PNG') !== -1 ||
            uri.indexOf('.gif') !== -1 || uri.indexOf('.bmp') !== -1){
                previewUri = uri;
            } else if (uri.indexOf('.xls') !== -1 || uri.indexOf('.xlsx') !== -1) {
                previewUri = 'images/excelicon.png';
            } else if (uri.indexOf('.pdf') !== -1) {
                previewUri = 'images/pdficon.png';
            } else if (uri.indexOf('.doc') !== -1 || uri.indexOf('.docx') !== -1) {
                previewUri = 'images/docicon.png';
            } else if (uri.indexOf('.ppt') !== -1 || uri.indexOf('.pptx') !== -1) {
                previewUri = 'images/ppticon.png';
            } else if (uri.indexOf('.zip') !== -1) {
                previewUri = 'images/zipicon.png';
            } else {
                previewUri = 'images/othericon.png';
            }
            return previewUri;
        }
    });
});
