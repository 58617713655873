define([
    'dojo/_base/Color',
    'dojo/_base/lang',
    'dojo/topic',
    'dojo/dom-construct',
    'dojox/layout/ResizeHandle',
    './HistoricalMap',
    './_DrawUtil'
], function(Color, lang, topic, domCon, ResizeHandle, HistoricalMap, Util) {
    return {
        //HEXのColorをrgbに変換する。DivIconはHEXを使用できないため。
        colorFormatConverter: function(color, opacity){
            var targetColor = new Color(color);
            //from hex to rgb
            if (color.indexOf('#') !== -1) {
                var rgbColor = targetColor.toRgb();
                return (opacity !== undefined) ?
                    'rgba('+rgbColor[0]+', '+rgbColor[1]+', '+rgbColor[2]+', '+opacity+')' :
                        'rgb(' +rgbColor[0]+', '+rgbColor[1]+', '+rgbColor[2]+')';
                        //from rgb to hex
            } else {
                //rgba
                return [
                    targetColor.toHex(),
                    color.indexOf('rgba') !== -1 ?
                        color.slice(color.lastIndexOf(',')+2, color.lastIndexOf(')')) : 1
                ];
            }
        },

        //付箋のcommentAreaの生成
        createCommentArea: function(domNode, options){
            var target = domNode.style;
            var insideDiv = document.createElement('div');
            insideDiv.style.height = '100%';

            new ResizeHandle({
                minWidth: 20,
                minHeight: 20,
                activeResize: false,
                animateSizing: false,
                animateMethod: 'chain',
                targetContainer: insideDiv,
                onResize: lang.hitch(this, function(e){
                    var eStyle = e.target.style;
                    var width  = eStyle.width === '' ? '200px' :
                                 parseInt(eStyle.width ,10) < 10 ? '20px': eStyle.width;
                    var height = eStyle.height === '' ? '50px' :
                                 parseInt(eStyle.height,10) < 10 ? '20px' : eStyle.height;
                    target.width = width;
                    target.height = height;
                    insideDiv.style.width = width;
                    insideDiv.style.height = height;
                    this.options.width = width;
                    this.options.height = height;

                    if (this.options.icon) {
                        this.options.icon.options.width  = width;
                        this.options.icon.options.height = height;
                    }

                    topic.publish('/app/draw/DrawPanel::drawAccessToHistoricalMap');
                })
            }).placeAt(insideDiv);

            for (var i = 0; i < options.comment.split('\n').length; i++) {
                var commnetDiv = domCon.create('div');
                commnetDiv.textContent = options.comment.split('\n')[i];
                domCon.place(commnetDiv, insideDiv);
            }
            domCon.place(insideDiv, domNode);
        },

        // 添付ファイルを地図上に展開する
        setThumnails: function(domNode, options){
            if (options) {
                options.forEach(function(file){
                    var thumnail = Util._createDownLoadImage(file);
                    domCon.place(thumnail, domNode.firstChild, 'last');
                });
            }
        }
    };
});
