/**
* 気象情報一覧画面用モジュール。
* @module app/weatherinfo/view/WeatherInfoPage
*/
define([
	'module',
	'dojo/_base/declare',
	'idis/view/grid/IdisGrid',
	'idis/view/grid/helper',
    './WeatherInfoColumn',
	// 以下、変数として受け取らないモジュール
	'dojox/lang/functional/array'
], function(module, declare, IdisGrid, helper, WeatherInfoColumn) {

    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

	/**
	 * 各列の定義
	 * @type {Object[]}
	 */
	columns: [
        helper.column('areaName', '地域',{sortable: false}),
		helper.column('cityName', '市町', {
			sortable: false,
			formatter: function(v,item) {
				var path = ['?areaCode=',item.areaCode,'&p=weatherinfo%2Fhistory'].join('');
				return ['<a href="', path, '">', item.cityName, '</a>'].join('');
			}
		}),
        WeatherInfoColumn.spWarnHeader,
		WeatherInfoColumn.sedimentHeader,
        WeatherInfoColumn.warnHeader,
        WeatherInfoColumn.advisoryHeader
	]});
});
