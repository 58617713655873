/**
 * ユーザー選択用入力パーツ
 * @module idis/view/form/UserSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/form/TreeSelector',
    '../../model/User'
], function(module, declare, TreeSelector, User) {
    /**
     * ユーザー選択用パーツ。
     * @class UserSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:idis/view/tree/UserSelector~UserSelector# */ {
        // 選択ダイアログのタイトル
        title: 'ユーザー選択',

        // ツリー・モデル
        model: User
    });
});

