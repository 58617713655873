define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/on',
    'idis/view/grid/IdisGrid',
    // 'idis/view/grid/helper',
    'idis/view/form/Button'
], function(module, declare, lang, on, IdisGrid/*, helper*/, Button) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--scheduledreport',

        // IdisGrid拡張：行自体にCSSクラスを追加する場合に指定する
        rowClassName: function(item) {
            if (!item.read) {
                //未読の場合は赤背景色
                return item && 'red-row';
            } else {
                //未報告の場合は黄色背景色
                return item && (item.reportedFlg === '0') ? 'yellow-row' : '';
            }
        },

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [

            {
                field: 'detail',
                label: '詳細',
                sortable: false,
                renderCell: function(item) {
                    var gridNode = this.grid.domNode;
                    var button = new Button({
                        label: (item.reportedFlg === '1') ? '詳細' : '報告',
                        onClick: function() {
                            on.emit(gridNode, 'detailButtonClick', {item: item});
                        }
                    });
                    // HTMLとしてウィジェットに紐付くDOMノードを返す
                    return button.domNode;
                }
            },
            {field: 'municipalityName', label: '市町'},
            {field: 'admNum', label: '報告番号'},
            { field: 'aggrTimestamp', label: '集計時点' },
            {field: 'reportTimestamp', label: '報告日時', className: 'reportTimestamp',
                formatter: lang.hitch(this, function(reportTimestamp, item){
                    if(item.reportedFlg === '1') {
                        return reportTimestamp;
                    } else {
                        return '(未報告)';
                    }
                })
            },
            {label: '報告者所属',field: 'organizationName', sortable: false},
            {label: '報告者名',field: 'reportAuthorName'},
            {label: '人的被害',field: 'human', sortable: false,
            children: [
                {field: 'deadNum', label: '死者', sortable: true},
                {field: 'unknownNum', label: '行方不明者', sortable: true},
                {field: 'seriousNum', label: '重傷者', sortable: true},
                {field: 'mildNum', label: '軽傷者', sortable: true},
                {field: 'unknownSympNum', label: '調査中', sortable: true}
            ]
            },
            {label: '住家被害',field: 'property', sortable: false,
                children: [
                {field: 'fullDestlHm', label: '全壊', sortable: true},
                {field: 'halfDestlHm', label: '半壊', sortable: true},
                {field: 'partDestlHm', label: '一部損壊', sortable: true},
                {field: 'fldAbvHm', label: '床上浸水', sortable: true},
                {field: 'fldBlwHm', label: '床下浸水', sortable: true}
            ]
            }
        ]
    });
});
