/**
* 災害情報画面用モジュール。
* @module app/view/page/DisasterAdminPage
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
	'dojox/lang/functional/object',
    'idis/util/DateUtils',
	'idis/view/grid/IdisGrid',
	'idis/view/grid/helper',
    './TimelineRiverConfig',
    './CategoryConfig',
	'dgrid/Editor',
	// 'dijit/form/TextBox',
    // 'idis/view/form/DateTimeInput',
    './RegisterCell',
    './AlertCell',
	// 以下、変数として受け取らないモジュール
	'dojox/lang/functional/array'
], function(module, declare, lang, array, df, DateUtils, IdisGrid, helper,
        TimelineRiverConfig, CategoryConfig, Editor, RegisterCell, AlertCell) {
	return declare(module.id.replace(/\//g, '.'), [ IdisGrid, Editor ], {

        // IdisGrid拡張：行自体にCSSクラスを追加する場合に指定する
        rowClassName: function(item) {
            var classNames = [];
            if(item && item.pubStatus === 1){
                classNames.push('is-public');
            }
            if(item && item.disabled) {
                classNames.push('timeline-Grid-Disable');
            }
            if(item && item.noActionFlg === '1') {
                classNames.push('timeline-Grid-NoActionTarget');
            } else if(item && item.alert && item.completeTimestamp === null) {
                classNames.push('timeline-Grid-AlertTarget');
            }
            return classNames.join(' ');
        },

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            helper.column('elapsedTime', '経過時間', {sortable: false}),
            {field: 'timestamp', label: '予定時刻', sortable: true,
                formatter: lang.hitch(this, function(item){
                    if (!item) {
                        return '-';
                    }else{
                        return DateUtils.format(item, {time: 'HH:mm'});
                    }
                })
            },
            helper.column('targetRivers', '対象河川', {
                formatter: lang.hitch(this, function(value){
                    var result = [];
                    if (!value) {
                        return '-';
                    }else{
                        array.forEach(value, function(riverCd){
                            result.push(TimelineRiverConfig.riverCd[riverCd]);
                        }, this);
                    }
                    return result.join('・');
                }
            )}),
            helper.column('organization', '対応課', {
                sortable: false
            }),
            helper.column('category', 'カテゴリー', {
                formatter: lang.hitch(this, function(value){
                    if (!value) {
                        return '-';
                    }else{
                        return CategoryConfig.categoryCd[value];
                    }
                }
            )}),
            helper.column('content', '実施項目',{
                sortable: false
            }),
            {field: 'registerCell', label: '完了日時',
                renderCell: function(item) {
                    var node = new RegisterCell({
                        item: item,
                        store: this.grid.collection
                    });
                    return node.domNode;

                    // ウィジットをnewする、引数はitemとstore(this.grid.collection)
                    // buildRenderingの中で、inheritした後に、条件分岐でdiv要素を描画orテキストボックス等を描画
                    // 登録したボタンを押した時に、storeに対してputする処理を実施
                    // →グリッドがputされた内容に従って更新される
                }
            },
            {field: 'alertCell', label: '対象外',
                renderCell: function(item) {
                    var node = new AlertCell({
                        item: item,
                        store: this.grid.collection
                    });
                    return node.domNode;

                    // ウィジットをnewする、引数はitemとstore(this.grid.collection)
                    // buildRenderingの中で、inheritした後に、条件分岐でdiv要素を描画orテキストボックス等を描画
                    // 登録したボタンを押した時に、storeに対してputする処理を実施
                    // →グリッドがputされた内容に従って更新される
                }
            }
        ]
     });
 });
