/**
 * 定数として定義
 */
define({
    // 気象警報モード
    WEATHER_LAYER_ID: [37, 38, 39, 51],
    // 土砂モード
    SEDIMENT_LAYER_ID: [52, 53],
    // 洪水モード
    FLOOD_LAYER_ID: [62],
    // 降水情報モード
    RAIN_LAYER_ID: []
});
