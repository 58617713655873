define({
    categoryCd: {
        '00': '',
        '01': '情報収集',
        '02': '意思決定',
        '03': '情報伝達',
        '04': '河川監視',
        '05': '施設管理',
        '06': '組織',
        '07': 'その他'
    }
});