/**
 * 配信の更新種別
 * @module app/provide/model/DistributionType
 */
define([
    'module',
    'dojo/store/Memory'
    // 以下、変数で受けないモジュール
], function(module, Memory) {
    // シングルトンを返す
    return new Memory({
        idProperty: 'code',
        data: [
            {code: '01', label: '新規'},
            {code: '02', label: '更新・訂正'},
            {code: '03', label: '取消'}
        ]
    });
});

