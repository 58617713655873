/**
 * dijit/tree/model 準拠のツリー・モデル。
 *「ユーザのツリー構造に対する操作」と「実際のツリー情報を扱うモデル」の間に立ち、
 * 操作の取り消し・やり直しが可能となるようにする。
 * @module idis/store/BridgeModel
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/json',
    'dojo/promise/all',
    'dojox/lang/functional/object',
    '../util/ArrayUtils'
], function(module, array, declare, lang, Deferred, JSON, all, df, ArrayUtils) {
    /**
     * 新規要素作成時に付与する仮IDの接頭辞。
     *
     * @type {string}
     */
    var NEW_ID_PREFIX = '_NEW_';

    /**
     * ツリー構造操作の取り消し・やり直しを可能とするため、
     * 実際のツリー情報を扱うModelをラップし、ユーザのツリー構造に対する操作を取り扱う。
     * @class BridgeModel
     */
    return declare(null, {
        /**
         * ブリッジ先のモデル
         * @type {dijit/tree/model~model}
         */
        model: null,

        /**
         * IDとキャッシュ要素の対応
         * @type {Object<identifier, Object>}
         */
        _cache: null,

        /**
         * IDと子要素一覧の対応
         * @type {Object<identifier, Object[]>}
         */
        _childrenCache: null,

        /**
         * ルート要素のキャッシュ
         * @type {Object}
         */
        _rootCache: null,

        /**
         * 変更履歴
         */
        _commandList: null,

        /**
         * 新規要素作成時に付与する識別子（作成時にインクリメント）
         * @type {number}
         */
        _newItemId: 0,

        constructor: function(kwArgs) {
            lang.mixin(this, kwArgs);
            // 要素を初期化
            this._cache = {};
            this._childrenCache = {};
            this._commandList = [];
        },

        // 各プロパティーが設定された後に呼ばれる
        postMixInProperties: function() {
            this.inherited(arguments);
            if (!this.model) {
                throw new Error(module.id + '#postMixInProperties: modelの指定は必須です。');
            }
        },

        /**
         * 要素一覧から指定した要素の位置を返す。
         * @param {Object[]} itemList 要素一覧
         * @param {Object} item 探す要素
         * @returns {number} 見つかればその位置、無ければ-1
         */
        getItemIndex: function(itemList, item) {
            var itemId = this.getIdentity(item);
            for (var i = 0; i < itemList.length; ++i) {
                if (itemId === this.getIdentity(itemList[i])) {
                    return i;
                }
            }
            return -1;
        },

        /**
         * 変更履歴を追加する。
         * @param {Object} 変更内容
         */
        pushCommand: function(command) {
            this._commandList.push(command);
        },

        /**
         * 変更されたかどうか。
         * @returns {boolean} 変更されたかどうか
         */
        isChanged: function() {
            // 変更履歴があれば変更されたと見なす
            return !!this._commandList.length;
        },

        /**
         * レイヤー変更情報。
         * @typedef {Object} BridgeModel~LayerChange
         * @property {string} id 要素の識別子（新規要素の場合は専用接頭辞付きの擬似識別子）
         * @property {boolean} [dispSeqNo] 移動後の位置
         * @property {string} [name] 新規作成または名前変更時に設定
         * @property {number} [parentId] 親要素の識別子（親要素が新規要素の場合は擬似識別子）
         * @property {number} [disasterId] 災害ID
         * @property {boolean} [delFlg] 削除時に設定
         */

        /**
         * 操作内容を変更内容に反映する。
         * @param {Object<identifier, BridgeModel~LayerChange>} 変更内容
         * @param {Object} command 適用する操作内容
         */
        _updateChangeMap: function(changeMap, command) {
            switch(command.type) {
                case 'create':
                    // フォルダ作成命令
                    changeMap[command.id] = {
                        id: command.id,
                        name: command.name,
                        parentId: command.parentId,
                        disasterId: command.disasterId
                    };
                    // 作成先の兄弟要素の順番を更新
                    array.forEach(command.siblingIdList, function(id, index) {
                        changeMap[id] = changeMap[id] || {id: id};
                        changeMap[id].dispSeqNo = index;
                    });
                    break;
                case 'rename':
                    // 名前変更命令
                    // 変更一覧に無ければ識別子で初期化
                    changeMap[command.id] = changeMap[command.id] || {id: command.id};
                    // 最後に出現したものが最終的な名前
                    changeMap[command.id].name = command.name;
                    break;
                case 'move':
                    // 移動命令
                    // 変更一覧に無ければ識別子で初期化
                    changeMap[command.id] = changeMap[command.id] || {id: command.id};
                    // 移動した要素の親を更新
                    if (command.newParentId || command.newParentId === 0) {
                        changeMap[command.id].parentId = command.newParentId;
                    }
                    // 移動先の兄弟要素の順番を更新（移動元は単に欠番になるだけなので更新を省略）
                    array.forEach(command.newSiblingIdList, function(id, index) {
                        changeMap[id] = changeMap[id] || {id: id};
                        changeMap[id].dispSeqNo = index;
                    });
                    break;
                case 'delete':
                    // 削除命令
                    // 各要素に削除フラグを設定
                    array.forEach(command.idList, function(id) {
                        if (lang.isString(id) && id.indexOf(NEW_ID_PREFIX) === 0) {
                            // 新規要素の場合は存在自体を無かったことにする
                            delete changeMap[id];
                        } else {
                            // 既存要素の場合
                            changeMap[id] = changeMap[id] || {id: id};
                            changeMap[id].delFlg = true;
                            // 親や順序は更新しない
                            // （新規要素が作られずに終わった場合など、判定が複雑なため）
                            delete changeMap[id].parentId;
                            delete changeMap[id].dispSeqNo;
                        }
                    });
                    break;
                default:
                    console.log('unknown command: ' + JSON.stringify(command));
            }
        },

        /**
         * 変更情報一覧を返す。
         * @returns {BridgeModel~LayerChange[]}
         */
        getChangeList: function() {
            // 識別子と変更内容のマップ
            var changeMap = {};
            array.forEach(this._commandList, function(command) {
                this._updateChangeMap(changeMap, command);
            }, this);
            // 配列化して返す
            return df.values(changeMap);
        },

        /**
         * 最後に出現した変更情報を文字列にして返す。
         * @returns {string} 最後に出現した変更情報を表す文字列
         */
        _stringifyLastCommand: function() {
            return JSON.stringify(this._commandList[this._commandList.length - 1]);
        },

        /**
         * 指定された名前でフォルダ要素を作成し、ツリーに反映する。
         * @param {string} name 作成するフォルダの名前
         */
        createFolder: function(name, parentId, disasterId) {
            // 変更履歴を記録（取り消し・確定用）
            var id = NEW_ID_PREFIX + this._newItemId;
            ++this._newItemId;
            var item = this.newItem({
                id: id,
                parentId: parentId,
                name: name,
                infoCategoryCd: 'T001' ,// ツリーの表示判定用
                disasterId: disasterId
            }, this.getCache(parentId));
            this.pushCommand({
                type: 'create',
                id: id,
                parentId: parentId,
                name: name,
                disasterId: disasterId,
                siblingIdList: array.map(this.getSiblings(item), this.getIdentity, this)
            });
            // デバッグ用ログ出力
            console.debug(module.id + '#createItem: ' + this._stringifyLastCommand());
        },

        // 要素を作成する
        newItem: function(item, parent) {
            // 新規キャッシュ情報として追加
            var id = this.getIdentity(item);
            this._cache[id] = item;
            this._childrenCache[id] = [];
            // 親要素の子要素として追加
            var parentId = this.getIdentity(parent);
            var parentChildren = this._childrenCache[parentId];
            if (!parentChildren) {
                // 新規要素やmayHaveChildrenを満たさない場合は空なので初期化
                parentChildren = this._childrenCache[parentId] = [];
            }
            parentChildren.unshift(item);
            this.onChildrenChange(parent, parentChildren);
            return item;
        },

        /**
         * 要素とその子孫要素を削除する。
         * @param {Object} item 削除対象の要素
         * @returns {Promise} 削除完了時に解決するPromise
         */
        deleteItem: function(item) {
            // ツリー表示上の要素を削除
            this.onDelete(item);
            // 全子孫要素を取得する
            return this.getIdentityRecursive(item).then(lang.hitch(this, function(idList) {
                // 変更履歴を記録（取り消し・確定用）
                this.pushCommand({
                    type: 'delete',
                    idList: idList
                });
                // デバッグ用ログ出力
                console.debug(module.id + '#deleteItem: ' + this._stringifyLastCommand());
            }));
        },

        /**
         * 要素の名前を変更し、ツリーに反映する。
         * @param {Object} item 変更対象の要素
         * @param {string} name 変更後の名前
         */
        renameItem: function(item, name) {
            var oldName = item.name;
            // キャッシュを書き換える
            item.name = name;
            // ツリーに反映
            this.onChange(item);
            // 変更履歴を記録（取り消し・確定用）
            this.pushCommand({
                type: 'rename',
                id: this.getIdentity(item),
                oldName: oldName,
                name: name
            });
            // デバッグ用ログ出力
            console.debug(module.id + '#renameItem: ' + this._stringifyLastCommand());
        },

        /**
         * 要素を指定位置へ移動する。
         * @param {Object} item D&D対象要素
         * @param {Object} oldParent 元の親要素
         * @param {Object} newParent 新たな親要素
         * @param {number} insertIndex 挿入位置
         * @returns None
         */
        moveItem: function(item, oldParent, newParent, insertIndex) {
            // 元の兄弟要素一覧を取得
            var oldPromise = this.getChildrenPromise(oldParent);
            // 移動前後で親が変わる場合は
            var isSameParent = this.getIdentity(oldParent) === this.getIdentity(newParent);
            var newPromise = isSameParent ? oldPromise : this.getChildrenPromise(newParent);
            all([oldPromise, newPromise]).then(lang.hitch(this, function(results) {
                // 新旧の兄弟要素（親が一致している場合は同一インスタンス）
                var oldSiblings = results[0];
                var newSiblings = results[1];
                // 元の親要素から対象要素を除く
                var oldIndex = this.getItemIndex(oldSiblings, item);
                console.log(oldIndex);
                var removed = oldSiblings.splice(oldIndex, 1)[0];
                // 挿入位置が指定されていない場合は先頭に追加
                newSiblings.splice(insertIndex || 0, 0, removed);
                // ツリーに反映
                this.setChildren(oldParent, oldSiblings);
                // 移動前後で親要素が変わっている場合は移動先もツリーに反映
                if (!isSameParent) {
                    this.setChildren(newParent, newSiblings);
                }
                // 変更履歴を記録（取り消し・確定用）
                var command = {
                    type: 'move',
                    id: this.getIdentity(item),
                    // 移動後の兄弟要素の順序（移動元は単に欠番になるだけなので更新を省略）
                    newSiblingIdList: array.map(newSiblings, this.getIdentity, this)
                };
                if (!isSameParent) {
                    // 親要素が変わった場合だけ新たな親IDを操作履歴に含める
                    command.newParentId = this.getIdentity(newParent);
                }
                this.pushCommand(command);
                // デバッグ用ログ出力
                console.debug(module.id + '#moveItem: ' + this._stringifyLastCommand());
            }));
        },

        /**
         * 指定された要素のキャッシュを作成して返す。
         * @param {Object} item キャッシュ元要素
         * @returns {Object} 要素のキャッシュ
         */
        _createCache: function(item) {
            return (this._cache[this.getIdentity(item)] = lang.mixin(null, item));
        },

        /**
         * 指定されたIDのキャッシュを返す。
         * @type {identifier} id 要素の識別子
         * @returns {Object} 識別子に対応するキャッシュ
         */
        getCache: function(id) {
            return this._cache[id] || null;
        },

        // 子要素一覧の取得
        getChildren: function(parentItem, onComplete, onError) {
            var parentId = this.getIdentity(parentItem);
            // キャッシュがあればコピーを返す
            if (this._childrenCache[parentId]) {
                onComplete(ArrayUtils.shallowCopy(this._childrenCache[parentId]));
                return;
            }
            // ブリッジ先の子要素一覧を取得
            this.model.getChildren(parentItem, lang.hitch(this, function(children) {
                // 子要素のコピーをキャッシュとして保存
                this._childrenCache[parentId] = array.map(children, this._createCache, this);
                // 呼び出し元にキャッシュのコピーを返す
                onComplete(ArrayUtils.shallowCopy(this._childrenCache[parentId]));
            }), onError);
        },

        /**
         * getChildrenをPromise形式で実施する。
         * @param {Object} parentItem 親要素
         * @returns {Promise<Object[]>} 成功したら子要素一覧、失敗したらエラーを返すPromise
         */
        getChildrenPromise: function(parentItem) {
            var dfd = new Deferred();
            this.getChildren(parentItem, dfd.resolve, dfd.reject);
            return dfd.promise;
        },

        /**
         * 指定された要素とその子孫要素の識別子を再帰的に取得する。
         * @param {Object} item 開始位置となる要素
         * @returns {identifier[]} 指定された要素とその子孫要素の識別子
         */
        getIdentityRecursive: function(item) {
            return this.getChildrenPromise(item).then(lang.hitch(this, function(children) {
                // 子要素に対し再帰的に実行
                var childrenResult = all(array.map(children, this.getIdentityRecursive, this));
                return childrenResult.then(lang.hitch(this, function(childResults) {
                    // 子要素の全Promise解決時
                    // 自分のIDを先頭に追加
                    var result = [this.getIdentity(item)];
                    // 子要素のID一覧を追加
                    array.forEach(childResults, function(idList) {
                        result = result.concat(idList);
                    });
                    // このメソッドの結果として返す
                    return result;
                }));
            }));
        },

        /**
         * キャッシュ取得済みであることを前提とし、要素の親要素を同期的に取得する。
         * @param {Object} item 要素
         * @param {Object} 親要素
         */
        getParent: function(item) {
            var parentId = this.model.store.getParentIdentity(item);
            return this.getCache(parentId) || null;
        },

        /**
         * キャッシュ取得済みであることを前提とし、要素の兄弟要素一覧を同期的に取得する。
         * @param {Object} item 要素
         * @param {Object[]} 兄弟要素一覧
         */
        getSiblings: function(item) {
            var parentId = this.model.store.getParentIdentity(item);
            return ArrayUtils.shallowCopy(this._childrenCache[parentId]);
        },

        /**
         * 子要素一覧を設定する。
         * @param {Object} item 子要素一覧の親要素
         * @param {Object[]} children 子要素一覧
         */
        setChildren: function(item, children) {
            // キャッシュを更新
            this._childrenCache[this.getIdentity(item)] = children;
            // ツリーに通知
            this.onChildrenChange(item, children);
        },

        // 指定された要素がこのツリーの要素かどうか
        isItem: function(item) {
            return item && this.getCache(this.getIdentity(item));
        },

        /**
         * 要素がD&Dでペーストされたときに呼ばれる。
         * @param {Object} item D&D対象要素
         * @param {Object} oldParentItem 元の親要素
         * @param {Object} newParentItem 新たな親要素
         * @param {Object} copy コピーかどうか
         * @param {number} [insertIndex] 設置位置（完全に重ねた場合はundefined）
         * @param {Object} [before] 設置位置直後の要素
         * @returns None
         */
        pasteItem: function(item, oldParentItem, newParentItem, copy, insertIndex, before) {
            this.moveItem(item, oldParentItem, newParentItem, insertIndex, before);
        },

        getIdentity: function() {
            // ブリッジ先に任せる
            return this.model.getIdentity.apply(this.model, arguments);
        },

        getLabel: function() {
            // ブリッジ先に任せる
            return this.model.getLabel.apply(this.model, arguments);
        },

        // ルート要素の取得
        getRoot: function(onItem, onError) {
            // キャッシュがあればそれを返す
            if (this._rootCache) {
                onItem(this._rootCache);
                return;
            }
            // ブリッジ先のルート要素を取得
            this.model.getRoot(lang.hitch(this, function(item) {
                // 結果のコピーをキャッシュ
                this._rootCache = this._createCache(item);
                // 呼び出し元にキャッシュを返す
                onItem(this._rootCache);
            }), onError);
        },

        mayHaveChildren: function() {
            // ブリッジ先に任せる
            return this.model.mayHaveChildren.apply(this.model, arguments);
        }
    });
});
