
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'idis/view/LazyNode',
    'app/model/LayerDirectory'
], function(module, declare, lang, IdisGrid, helper, LazyNode, LayerDirectory) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            helper.buttonColumn('detail', '詳細'),
            helper.column('crtTimestamp', '登録日時'),
            {field: 'parentLayerId', label: '格納先',
                renderCell: function(item, value) {
                    var lazyNode = new LazyNode({
                        promise: LayerDirectory.getFullName(value || 0)
                    });
                    return lazyNode.domNode;
                }
            },
            helper.column('layerName', 'レイヤ名'),
            helper.column('status', '処理状況', {
                formatMap: {
                    '1' : 'レイヤ情報登録済み',
                    '2' : 'レイヤ情報登録エラー',
                    '3' : '位置情報取得中',
                    '4' : '位置情報取得エラー',
                    '5' : '地図表示用ファイル作成中',
                    '6' : '地図表示用ファイル作成エラー',
                    '7' : '処理完了'
                }
            }),
            helper.column('description', '情報説明文'),
            helper.buttonColumn('output', 'CSV出力')
        ]
    });
});
