/**
    避難情報の発令内容の一覧
 *  @module app/evacorder/EvacOrderType
 */
define([
    'dojo/store/Memory'
], function(Memory) {
    return new Memory({
        data: [
            {
                // 改正災害対策基本法対応
                id: '11',
                labelWithLevel: '【警戒レベル3】高齢者等避難',
                name: '高齢者等避難',
                simpleLabel: '高齢者等避難',
                label4Lalert: '高齢者等避難',
                evacAction: '高齢者等は避難'
            },
            {
                // 改正災害対策基本法対応
                id: '13',
                labelWithLevel: '【警戒レベル4】避難指示',
                name: '避難指示',
                simpleLabel: '避難指示',
                label4Lalert: '避難指示',
                evacAction: '全員避難'
            },
            {
                // 改正災害対策基本法対応
                id: '14',
                labelWithLevel: '【警戒レベル5】緊急安全確保',
                name: '緊急安全確保',
                simpleLabel: '緊急安全確保',
                label4Lalert: '緊急安全確保',
                evacAction: '命を守る最善の行動'
            },
            {
                // 改正災害対策基本法対応
                id: '19',
                labelWithLevel: '警戒区域',
                name: '警戒区域',
                simpleLabel: '警戒区域',
                label4Lalert: '警戒区域',
                evacAction: '立ち入り禁止'
            }, 
            {
                // 画面用：DB登録コードではない
                // 改正災害対策基本法対応
                id: '80',
                labelWithLevel: '解除',
                name: '解除',
                simpleLabel: '解除',
                label4Lalert: '解除',
                evacAction: ''
            },
            {
                // 改正災害対策基本法対応
                id: '81',
                labelWithLevel: '高齢者等避難 解除',
                name: '解除(高齢者等避難)',
                simpleLabel: '解除',
                label4Lalert: '解除',
                issue: '高齢者等避難',
                issueId: '11',
                evacAction: ''
            },
            {
                // 改正災害対策基本法対応
                id: '83',
                labelWithLevel: '避難指示 解除',
                name: '解除(避難指示)',
                simpleLabel: '解除',
                label4Lalert: '解除',
                issue: '避難指示',
                issueId: '13',
                evacAction: ''
            },
            {
                // 改正災害対策基本法対応
                id: '84',
                labelWithLevel: '緊急安全確保 解除',
                name: '解除(緊急安全確保)',
                simpleLabel: '解除',
                label4Lalert: '解除',
                issue: '緊急安全確保',
                issueId: '14',
                evacAction: ''
            }, {
                // 改正災害対策基本法対応
                id: '89',
                labelWithLevel: '警戒区域 解除',
                name: '解除(警戒区域)',
                simpleLabel: '解除',
                label4Lalert: '解除',
                issue: '警戒区域',
                evacAction: ''
            }
        ]
    });
});
