/**
 * 通知先ユーザ選択用パーツ。
 * @module app/view/form/BroadnotifyUserMultiSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/string',
    'dijit/form/TextBox',
    'dojo/when',
    'dojo/dom-style',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'idis/view/form/TreeSelector'
], function(module, declare, lang, dStr, TextBox, when, domStyle, CacheStoreModel, CacheTreeRest, TreeSelector) {
    /**
     * 招集グループ用パーツ。
     * @class UsersSelector
     * @extends module:idis/view/form/TreeChecker~TreeChecker
     */
    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:idis/view/tree/BroadnotifyUserMultiSelector~BroadnotifyUserMultiSelector# */ {
        // 選択ダイアログのタイトル
        title: 'ユーザ選択',
        // ツリー・モデル
        model: null,

        // ページ初期表示にmodelがnullだとエラーが出るためmodelを生成
        constructor: function() {
            this._initModel();
        },
        // DOMを構築する
        buildRendering: function () {
            this.inherited(arguments);
            // 検索用入力要素を設置
            this.filterInput = new TextBox({
                placeHolder: this.placeHolder,
                maxLength: 128
            });
            this.filterInput.placeAt(this.treeControlNode);
            this.own(this.filterInput.on('keyup', lang.hitch(this, 'onFilterInputChange')));
        },
        
        _initModel: function(){
            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                target: '/api/broadnotify/terminal/user4Tree'
                })
            });
        },
        
        // ダイアログを表示する度にmodelを呼び出すことで施設種別テーブルの変更点をツリーに反映
        showDialog: function() {
            if(this.treeParentNode && this.tree){
                // this.treeNode = this.tree.domNode;
                this.tree.destroyRecursive();
                this.treeParentNode.appendChild(this.treeNode);
                delete this.tree;
                delete this.model;
                this._initModel();
            } else {
                // 2回目の表示以降、treeを削除する際にtreeNodeが削除されてしまうので
                // 初期表示の段階で、ツリー表示時のtreeNode.parentNodeを記憶しておく
                this.treeParentNode = this.treeNode.parentNode;
            }
            return this.inherited(arguments);
        },
        
        /**
         * 選択した値の設定
         */
        _setValueAttr: function(value) {
            if (value || value === 0) {
                this._set('value', value);
                this.emit('change', {value: value});
                this._initTree().then(lang.hitch(this, function() {
                    var model = this.tree.model;
                    var label;
                    if (this.fullName) {
                        label = model.getFullName(value);
                    } else {
                        label = when(model.store.get(value), function(item) {
                            return model.getLabel(item);
                        });
                    }
                    when(label, lang.hitch(this, function(name) {
                        this.status.innerHTML = name;
                    }));
                }));
            } else {
                this._set('value', '');
                this.emit('change', {value: ''});
                this.status.innerHTML = this.emptyValueLabel;
            }
            // 要素の選択状態をリセットする
            this._initTree().then(lang.hitch(this, function() {
                this.tree.set('selectedItem', null);
            }));
            // TreeChecker本来の処理
            // this.inherited(arguments);
            // 文字列省略をオーバーライド
            domStyle.set(this.status, {
                'max-width': '20em',
                'white-space': 'nowrap',
                'overflow': 'hidden',
                'text-overflow': 'ellipsis'
            });
        },

        /**
         * 検索用入力要素入力時。
         */
         onFilterInputChange: function () {
            // 入力値を取得
            var value = dStr.trim(this.filterInput.get('value'));
            if (value) {
                // ラベル名が入力値を含むものでフィルターし、ツリーを展開する
                this.model.setFilter(function (item, isLeaf, model) {
                    return isLeaf && (model.getLabel(item) || '').indexOf(value) !== -1;
                }).then(lang.hitch(this, function () {
                    this.tree.expandAll();
                }));
            } else {
                // 入力が空の場合は全要素を表示してツリーを閉じる
                this.model.setFilter().then(lang.hitch(this, function () {
                    this.tree.collapseAll();
                }));
            }
        }
    });
});

