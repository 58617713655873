/**
 * チャットユーザ一括編集画面用モジュール。
 * @module app/chat/ChatUserBulkPage
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/ChatUserBulkPage.html',
    'idis/view/page/_PageBase',
    'dojox/lang/functional/object',
    'dojo/dom-style',
    'dijit/Dialog',
    'idis/consts/ACL',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/store/IdisRest',
    'idis/view/Loader',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/DialogChain',
    'app/config',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'app/view/form/CustomizableMunicipalitySelector',
    'app/view/form/UserOrganizationSelector',
    './ChatUserBulkGrid'
], function(module, array, declare, lang, template, _PageBase, df, domStyle, Dialog, ACL, Locator, Router,
	UserInfo, Requester, IdisRest, Loader, InfoDialog, DialogChain, config) {
    /**
     * チャットユーザ一括編集画面
     * @class ChatUserBulkPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/chat/ChatUserBulkPage~ChatUserBulkPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--chat',

        /**
         * ストア
         */
        store: null,

        // お知らせ用Dialog
        infoDialog: null,

        // チャットグループID
        _chatGroupId: null,

        /**
         * constructor
         */
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'userId',
                target: '/api/chat/user/bulk',
                sortParam: 'sort',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);

            this.organizationSelector.restoreRefresh();
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);

            // チャットユーザ画面から渡されたチャットグループIDをセットする。
            this._chatGroupId = Locator.getQuery().chatGroupId ? Locator.getQuery().chatGroupId : '';

            this.initGrid();
            this.initPage();
            this.setChatGroupName();
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            this.chatUserBulkGrid.reset();
            this.updateGridQuery();
        },

        /**
         * チャット管理画面を初期化する。
         */
        initPage: function() {
            this.setMunicipality();
        },

        // 市町村セレクタの値が変更するときの動作
        onMunicipalityCdChanged: function(municipalityCd) {
	        this.organizationSelector.restoreRefresh();
            this.organizationSelector.setMunicipalityCd(municipalityCd.value);
        },

        // ログインユーザの市/行政区をセットする
        setMunicipality: function () {
			var role = UserInfo.getRoleCd();
            var munic = UserInfo.getMunicipalityCd();
                // システム管理者・全体管理者・市本部管理者以外は市区選択セレクタを非表示
            if (!(role === ACL.ADMIN_USER || role === ACL.SYSMGR_USER || role === ACL.SHMGR_USER)) {
                this.municipalityCd.set('value', munic );
			    domStyle.set(this.municipalityCd.selectButton.domNode, 'display', 'none');
                // 所属管理者・所属本部ユーザ（人事室および総務局以外）・一般職員は自組織のみ閲覧可能
                if (role === ACL.XXMGR_USER ||
                    (role === ACL.XXHONBU_USER &&
                        (UserInfo.getOrganization().deptCd !== config.dept.humanResourceDeptCd ||
                            UserInfo.getOrganization().deptCd !== config.dept.soumuDeptCd )) ||
                            role === ACL.IPPAN_USER) {
                    // this.organizationSelector.restoreRefresh();
                    // 所属組織をセレクタにセット
                    this.organizationSelector.set('value', 'D' + UserInfo.getOrganization().deptCd);
                    domStyle.set(this.organizationSelector.selectButton.domNode, 'display', 'none');
                }
			}
	    },

        /**
         * チャットグループ名を表示する。
         */
         setChatGroupName: function(){
            var promise = Requester.get('/api/chat/group/' + this._chatGroupId, {
                preventCache: false
            }).then(lang.hitch(this,function(obj) {
                this.chatGroupName.innerHTML = obj.chatGroupName;
            }), lang.hitch(this, function(err){
                console.error(err);
            }));

            Loader.wait(promise);
        },

        /**
         * ユーザを検索する。
         */
        onSubmit: function() {
            console.debug('[検索]ボタンがクリックされました。');
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * ユーザを一括更新する。
         */
        updateUserButtonClick: function() {
            console.debug('[投稿可否更新]ボタンがクリックされました。');

            var items = [];
            df.forIn(this.chatUserBulkGrid.radioButtons, function(button, userId) {
                items.push({
                    chatGroupId: this._chatGroupId,
                    userId: userId,
                    sendRoleType: button.value
                });
            }, this);
            var data = {'items': items};

            if (items.length === 0) {
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '更新対象のユーザが存在していません。'
                    });
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return false;
                }
            }

            var message = '投稿可否更新を行います。\nよろしいですか？';
            this.chain.confirm(message, lang.hitch(this, function(chain) {
                var promise =  Requester.put('/api/chat/user/bulkUpdate', {
                    data: data
                }).then(lang.hitch(this, function(result) {

                    console.log(result);
                    chain.info('投稿可否更新が完了しました。', '更新', lang.hitch(this, function(){
                        Router.moveTo('chatUser', {
                            chatGroupId: this._chatGroupId
                        });
                    }));

                }), function(error) {
                    // 失敗時
                    console.error(error);
                    chain.infoError(error);
                });
                Loader.wait(promise);
            }));

        },

        /**
         * ユーザを削除する。
         */
        deleteUserButtonClick: function() {
            console.debug('[削除]ボタンがクリックされました。');

            var changedRowList = this.chatUserBulkGrid.getSelectedIdList();
            console.debug(changedRowList);

            if (changedRowList.length === 0 || changedRowList === null) {
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : 'ユーザを選択して下さい。'
                    });
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return false;
                }
            }

            var data = {
                items: array.map(changedRowList, function(userId) {
                    return {
                        chatGroupId: this._chatGroupId,
                        userId: userId
                    };
                }, this)
            };
            var message = '削除を行います。\nよろしいですか？';
            this.chain.confirm(message, lang.hitch(this, function(chain) {
                var promise =  Requester.put('/api/chat/user/bulkDelete', {
                    data: data
                }).then(lang.hitch(this, function(result) {

                    console.log(result);
                    chain.info('削除が完了しました。', '削除', lang.hitch(this, function(){
                        Router.moveTo('chatUser', {
                            chatGroupId: this._chatGroupId
                        });
                    }));

                }), function(error) {
                    // 失敗時
                    console.error(error);
                    chain.infoError(error);
                });
                Loader.wait(promise);
            }));

        },

        /**
         * チャットグループ画面へ遷移する。
         */
        onReturnChatGroupLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // チャットグループ画面へ遷移
            Router.moveTo('chatGroup');
        },

        /**
         * チャットユーザ画面へ遷移する。
         */
        onReturnChatUserLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // チャットユーザ画面へ遷移
            Router.moveTo('chatUser', {
                chatGroupId: this._chatGroupId
            });
        },

        /**
         * グリットを更新する。
         */
        updateGridQuery: function() {
            var filter = new this.store.Filter();
            var value = this.form.get('value');
            console.log(value);

            // グループIDによる検索
            filter = filter.eq('chatGroupId', this._chatGroupId);

            // ユーザIDによる検索
            if (value.userId && value.userId !== '') {
                filter = filter.eq('userId', value.userId);
            }

            // ユーザ名による検索
            if (value.userName && value.userName !== '') {
                filter = filter.eq('userName', value.userName);
            }

            // 所属による検索
            if (value.municipalityCd && value.municipalityCd !== '') {
                filter = filter.eq('municipalityCd', value.municipalityCd);
            }

            // 組織による検索
            if (value.organization && value.organization !== '') {
                filter = filter.eq('organization', value.organization);
            }

            // 投稿可否による検索
            if (value.sendRoleType && value.sendRoleType !== '') {
                filter = filter.eq('sendRoleType', value.sendRoleType);
            }

            // 自動異動による検索
            if (value.autoUpdateFlg && value.autoUpdateFlg !== '') {
                filter = filter.eq('autoUpdateFlg', value.autoUpdateFlg);
            }

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);

            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.chatUserBulkGrid.set('collection', collection);
        }
    });
});
