define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/topic',
    'idis/consts/QUERY',
    'idis/control/Locator',
    'idis/map/IdisMap',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, lang, topic, QUERY, Locator,
    IdisMap, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'map-DetailGrid',

        noDataMessage: '',

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            helper.column('name', '名称', {
                formatter: lang.hitch(this, function(value) {
                    return value ? value : '-';
                })
            }),
            helper.column('address', '住所（所在地）', {
                formatter: lang.hitch(this, function(value) {
                    return value ? value : '-';
                })
            }),
            helper.column('comment', '備考', {
                formatter: lang.hitch(this, function(value) {
                    return value ? value : '-';
                })
            })
        ],

        // グリッド行がクリックされた場合に呼ばれる
        onRowClick: function(item) {
            if (!item.lat || !item.lng) {
                return;
            }
            // 地図を要素の指定する座標へ移動
            topic.publish(IdisMap.TOPIC.MOVE, {
                lat: item.lat,
                lng: item.lng,
                zoom: Locator.getQuery()[QUERY.ZOOM]
            });
        }
    });

});
