/**
 * 気象注意報・警報選択用入力パーツ
 * @module app/view/form/WeatherWarnSelector
 */
define([
    'module',
    'dojo/_base/declare',
    '../../model/WeatherWarn',
    'idis/view/form/TreeChecker'
], function(module, declare, WeatherWarn, TreeChecker) {
    /**
     * 気象注意報・警報選択用パーツ。
     * @class WeatherWarnSelector
     * @extends module:idis/view/form/TreeChecker~TreeChecker
     */
    return declare(module.id.replace(/\//g, '.'), TreeChecker,
        /** @lends module:idis/view/tree/WeatherWarnSelector~WeatherWarnSelector# */ {
        // 選択ダイアログのタイトル
        title: '気象注意報・警報選択',
        // ツリー・モデル
        model: WeatherWarn
    });
});
