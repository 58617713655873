/**
 * 画面に通知を出す
 * @module app/common/notifier/Notifier
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/fx',
    'dojo/dom-construct',
    'dojo/dom-style',
    'dojo/text!./templates/Notify.html',
    'idis/control/Router',
    'idis/view/_IdisWidgetBase'
], function (module, declare, lang, fx, domConstruct, domStyle, template, Router, _IdisWidgetBase) {
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase, {
        // テンプレート文字列
        templateString: template,

        notifyType : null, // 通知の種類
        notifyId : null, // 通知の詳細ID

        title: '通知',
        sender: null, // 要請元
        message: null,
        level: 'low',
        page: null, // システム内ページへの遷移
        detailId: null, // 詳細画面に遷移するときのID
        detailIdNum: null, // 詳細画面に遷移するときのIDの値
        url: null,  // システム外ページへの遷移

        _container: null, // お知らせを配置するDOM
        _timer: null,
        _mouseOverFlg: false,

        SHOW_DURATION_AFTERMOUSEOUT: 3, //マウスアウト後に通知を表示させておく時間

        constructor: function (obj) {
            this.inherited(arguments);
            lang.mixin(this, obj);
        },

        postCreate: function () {
            this.inherited(arguments);
            domStyle.set(this.domNode, 'opacity', 0);
            domConstruct.place(this.domNode, this._container);
            // 詳細リンク
            if (this.page) {
                var url = '?p='+ this.page;
                if (this.detailId && this.detailIdNum) {
                    url += '&' + this.detailId + '=' + this.detailIdNum;
                }
                this.detailLink.innerHTML = '<a href="'+ url + '">&gt; 詳細</a>';
                domStyle.set(this.detailLink, 'display', '');
            }
            // 要請元
            if (this.sender) {
                domStyle.set(this.notifySender, 'display', '');
            }
            fx.anim(this.domNode, {
                opacity: 1
            }, 250);
            this.setTimeout();
        },

        onMouseOver: function () {
            // this._mouseOverFlg = true;
            // clearInterval(this._timer);
        },

        onMouseOut: function () {
            // if (this._mouseOverFlg) {
            //     // マウスアウトしてから(SHOW_DURATION_AFTERMOUSEOUT)秒後に、ポップアップを消す
            //     this._timer = setTimeout(lang.hitch(this, 'close'), this.SHOW_DURATION_AFTERMOUSEOUT * 1000);
            // }
        },

        onClick: function () {
            // page/urlが指定されていたら画面遷移
            // pageが優先
            // if (this.page) {
            //     Router.moveTo(this.page, {deptId: this.deptId});
            //     return;
            // }

            // urlの場合は別タブで開きつつ通知を消す
            // if (this.url) {
            //     window.open(this.url);
            // }

            // 指定されてなかったら通知をフェードアウトさせる
            // this.close();
        },

        onDismiss: function (e) {
            console.log('hede notify');
            this.close();
            e.stopPropagation();
        },

        close: function () {
            fx.anim(this.domNode, {
                opacity: 0
            }, 500, null, lang.hitch(this, 'remove'));
        },

        remove: function () {
            // 自分自身を消して, 空いたところを詰める
            fx.anim(this.domNode, {
                height: 0,
                margin: 0
            }, 250, null, lang.partial(domConstruct.destroy, this.domNode));
        },

        setTimeout: function () {
            if (this.timeout > 0) {
                this._timer = setTimeout(lang.hitch(this, 'close'), this.timeout);
            }
        }
    });
});
