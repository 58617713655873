define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function (module, declare, lang, locale, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--sending',

        /**
         * 各列の定義
         * @type {Object[]}
         */

        columns: [
            // ボタン
            helper.buttonColumn('detail', '詳細'),
            helper.column('infoType', '情報種別', {
                formatMap: {
                    '1': "災害登録情報",
                    '2': "本部設置状況",
                    '3': "避難所情報",
                    '4': "避難情報",
                    '5': "被害情報",
                    '6': "被害情報（詳細報）",
                    '7': "クロノロジー",
                    '8': "府民向けお知らせ情報",
                    '9': "お知らせ情報",
                    '10': "Lアラート送信結果",
                }
            }),
            helper.column('subject', '件名'),
            helper.column('bodyText', '本文'),

            helper.column('deliveredFlg', '配信状況', {
                formatter: function (value) {

                    if (value === '0') {
                        return '未配信';
                    }
                    if (value === '1') {
                        return '配信済';
                    }
                    return '-';
                }, sortable: false
            }),
            helper.column('sendOrganizationName', '配信課'),
            {
                field: 'sendTimestamp', label: '配信日時', sortable: true,
                formatter: lang.hitch(this, function (item) {
                    if (item) {
                        return locale.format(new Date(item));
                    }
                    return '';
                })
            },
            helper.column('senderName', '登録者'),
            {
                field: 'crtTimestamp', label: '登録日時', sortable: true,
                formatter: lang.hitch(this, function (item) {
                    if (item) {
                        return locale.format(new Date(item));
                    }
                    return '';
                })
            }
        ]
    }
    );
});
