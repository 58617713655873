/**
 * 配信確認ダイアログ画面用モジュール。
 * @module app/sending/SendingConfirmDialog
 */
define([
    'module',
    'dojo',
    'dojo/_base/lang',
    'dojo/_base/declare',
    'dojo/_base/array',
    'dojo/Deferred',
    'dojo/dom-style',
    'dojo/date/locale',
    'dojo/text!./templates/SendingConfirmDialog.html',
    'idis/view/page/_PageBase',
    'idis/model/UserInfo',
    'app/model/Municipality',
    'app/config',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button'
], function(module, dojo, lang, declare, array, Deferred, domStyle,
        locale, template, WidgetBase, UserInfo, Municipality, config) {
    /**
     * 配信確認ダイアログ
     * @class SendingConfirmDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), WidgetBase,
            /** @lends app/view/Header~Header# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--sending',

        sendTimestamp: null,

        constructor: function() {
            this.inherited(arguments);
        },

        buildRendering: function() {
            this.inherited(arguments);
        },

        startup: function() {
            this.inherited(arguments);
            this.container.resize();
        },

        /*
         * 県のコードを変換する。
         *
         * ManageRegionMunicipalitySelectorは県のコードとしてAreaCode.propertiesの
         * PREF_FULL_CODEの値を使用しているが、配信情報が登録される
         * T_SENDING_HEADERテーブルのMUNICIPALITY_CD列はM_MUNICIPALITYテーブルの
         * MUNICIPALITY_CD列との間に参照整合性制約があるため、県は市町コードでなければ登録できない。
         */
        _convertPrefectureCd: function(municipalityCd) {
            if (municipalityCd === config.municInfo.prefCd) {
                return config.municInfo.prefMunicCd;
            }
            return municipalityCd;
        },

        /**
         * 画面に内容をセット
         */
        refresh: function(registerPageObj) {
            var tweet = registerPageObj.twitterOuterFrame;
            var value = registerPageObj.form.value;

            // 未来かどうかを判定
            if (new Date() < new Date(value.sendTimestamp)) {
                // タイマー設定の場合は15分刻みにする
                domStyle.set(this.timer, 'display', 'block');
                this.sendTimestamp = this.setTimer(value.sendTimestamp);
            } else {
                //そのまま
                this.sendTimestamp = value.sendTimestamp;
            }

            // 共通部分
            this.sendOrganizationNamePreview.innerHTML = value.sendOrganizationName;
            this.sendTimestampPreview.innerHTML = locale.format(this.sendTimestamp);
            if (registerPageObj.prefWholeArea.get('checked')) { // 県
                var municipalityCd = this._convertPrefectureCd(config.municInfo.prefMunicCd);
                Municipality.store.get(municipalityCd).then(
                    lang.hitch(this, function(item) {
                        this.municipalityName.innerHTML = item ? '県全域' : '取得失敗';
                    })
                );
            } else { // 市町別
                Municipality.store.get(value.municipalityCd).then(
                    lang.hitch(this, function(item) {
                        this.municipalityName.innerHTML = item ? item.name : '取得失敗';
                    })
                );
            }
            this.senderNamePreview.innerHTML = value.senderName;
            this.subjectPreview.innerHTML = value.subject;
            this.bodyTextPreview.innerHTML = value.bodyText;

            // チャンネル別
            // 緊急速報メール
            if (registerPageObj._isEmergency && registerPageObj.emergencyMail.checked) {
                domStyle.set(this.dispEmergencyMail, 'display', 'block');
                var emergencyMailMunicipalityCd = this._convertPrefectureCd(value.emergencyMailMunicipalityCd);
                Municipality.store.get(emergencyMailMunicipalityCd).then(
                    lang.hitch(this, function(item) {
                        var _emergencyMailMunicipalityName = item ? item.name : '取得失敗';
                        this.emergencyMailMunicipalityPreview.innerHTML = _emergencyMailMunicipalityName;
                    })
                );
                this.emergencyMailSubjectPreview.innerHTML = value.emergencyMailSubject;
                this.emergencyMailBodyPreview.innerHTML = value.emergencyMailBody;
            } else {
                domStyle.set(this.dispEmergencyMail, 'display', 'none');
            }

            // Lアラート
            if (registerPageObj.lalert.checked) {
                domStyle.set(this.dispLalert, 'display', 'block');
                this.lalertPreview.innerHTML = value.bodyText;
            } else {
                domStyle.set(this.dispLalert, 'display', 'none');
            }

            // Twitter
            if (registerPageObj.twitter.checked) {
                domStyle.set(this.dispTwitter, 'display', 'block');
                // Twitter
                // tweetを全て削除
                var element = this.twitterPreview;
                while (element.firstChild) {
                    element.removeChild(element.firstChild);
                }
                var twitterID = registerPageObj.getTwitterIdStr();
                var elements = tweet.getElementsByTagName('textarea');
                var div = dojo.create('div');
                array.forEach (elements, function(d) {
                    var oneTweet = dojo.create('div');
                    oneTweet.innerHTML = twitterID + '<br>' + d.value;
                    oneTweet.style='padding: 10px; margin-bottom: 10px;' + ' border: 1px dotted #333333; width:80%;';
                    oneTweet.align='left';
                    div.appendChild(oneTweet);
                });
                this.twitterPreview.appendChild(div);
            } else {
                domStyle.set(this.dispTwitter, 'display', 'none');
            }

            // Yahoo
            if (registerPageObj.yahoo.checked) {
                domStyle.set(this.dispYahoo, 'display', 'block');
                this.yahooPreview.innerHTML = registerPageObj.yahooBody.displayedValue;
            } else {
                domStyle.set(this.dispYahoo, 'display', 'none');
            }

            this.container.resize();
        },

        /**
         * 市町コードリストに対応する市町名リスト（カンマ区切り）を返す
         * @param {*} municipalityCds 市町コードリスト
         */
        createMunicList: function(municipalityCds){
            var deferred = new Deferred();
            var _municipalityName = '';
            var lastIndex = municipalityCds.length - 1;
            array.forEach(municipalityCds, function(code, i) {
                var municipalityCd = this._convertPrefectureCd(code);
                Municipality.store.get(municipalityCd).then(
                    lang.hitch(this, function(item) {
                        if (i === 0){
                            _municipalityName = item ? item.name : '取得失敗';
                        } else {
                            _municipalityName += item ? ', '+item.name : '取得失敗';
                        }
                        if (lastIndex === i) {
                            deferred.resolve(_municipalityName);
                        }
                    })
                );
            }, this);
            return deferred.promise;
        },

        /**
         * タイマーを設定
         */
        setTimer: function(timestamp) {
            var dt = new Date(timestamp);
            var date = timestamp.getDate();
            var hours = timestamp.getHours();
            var minutes = 0;
            var timestampMinutes = timestamp.getMinutes();

            var timerPoints = [15, 30, 45, 0];
            if (timestampMinutes % 15 === 0) {
                minutes = timestampMinutes;
            } else {
                minutes = timerPoints[Math.floor(timestampMinutes / 15)];
                if (minutes === 0) {
                    if (hours === 23) {
                        date++;
                    } else {
                        hours++;
                    }
                }
            }

            dt.setMinutes(minutes);
            dt.setHours(hours);
            dt.setDate(date);
            return dt;
        },

        /**
         * 配信
         */
        onSubmit: function() {
            this.emit('register', {sendTimestamp:this.sendTimestamp});
        },

        /**
         * キャンセルボタン
         */
        onCancelButton: function() {
            this.emit('cancel');
            this.leave();
        }
    });
});
