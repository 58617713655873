/**
 * 見た目上、テキスト文字列として振る舞う入力要素。
 * @module idis/view/form/ReadOnlyInput
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/dom-class',
    'dijit/form/MappedTextBox'
], function(module, declare, domClass, MappedTextBox) {
    return declare(module.id.replace(/\//g, '.'), MappedTextBox, {
        // 読み取り専用
        readOnly: true,

        /**
         * 値と表示の対応付け
         * @type {dojo/store/Memory}
         */
        store: null,

        /**
         * storeを使用する場合に
         * store内要素で表示文字列として扱うプロパティー
         * @type {string}
         */
        labelAttr: 'label',

        // DOMノードを構築する
        buildRendering: function() {
            this.inherited(arguments);
            // CSS指定で通常のテキスト文字列のように見せる
            domClass.add(this.domNode, 'idis-ReadOnlyInput');
        },

        // 値を表示文字列に変換
        format: function(value) {
            // storeが無ければ何もしない
            if (!this.store) {
                return this.inherited(arguments);
            }
            // 値をIDとする要素のラベルを返す
            var item = this.store.get(value);
            return item ? item[this.labelAttr] : '';
        },

        // 表示文字列を値に変換
        parse: function(displayedValue) {
            // storeが無ければ何もしない
            if (!this.store) {
                return this.inherited(arguments);
            }
            // ラベルの一致する最初の要素のIDを返す
            var q = {};
            q[this.labelAttr] = displayedValue;
            var itemList = this.store.query(q);
            return itemList.length ? this.store.getIdentity(itemList[0]) : '';
        }
    });
});

