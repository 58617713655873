/**
 * 情報配信テンプレート一覧グリッド(新規登録ダイアログ用)。
 * @module app/provide/ProvideTempInsertGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'    
], function(module, declare, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid,
        /** @lends module:idis/view/page/ProvideTemplatePage~ProvideTemplatePage# */ {

        /**
         * 各列の定義
         * @type {Object[]}
         */
        collection: this.store,
        columns: [
            helper.buttonColumn('insert', '挿入'),
            helper.column('tmpTitle', 'タイトル'),
            helper.column('category', '種別'),
            helper.column('level', '緊急度'),
            helper.column('content', '本文')
        ]        
    });
});

