/**
 * 配信の一般情報カテゴリー
 * @module app/provide/model/GeneralInfoCategory
 */
define([
    'module',
    'dojo/store/Memory'
    // 以下、変数で受けないモジュール
], function(module, Memory) {
    // シングルトンを返す
    return new Memory({
        idProperty: 'code',
        data: [
            {code: '11', label: '鉄道'},
            {code: '12', label: 'バス'},
            {code: '13', label: '航空'},
            {code: '14', label: '船舶'},
            {code: '15', label: '道路'},
            {code: '16', label: 'その他'},
            {code: '21', label: '電気'},
            {code: '22', label: 'ガス'},
            {code: '23', label: '水道'},
            {code: '24', label: '給水'},
            {code: '25', label: '通信'},
            {code: '26', label: '放送'},
            {code: '27', label: 'その他'},
            {code: '31', label: '行政手続き'},
            {code: '32', label: '被災者支援'},
            {code: '33', label: '福祉・教育・保育'},
            {code: '34', label: '環境'},
            {code: '35', label: '防犯'},
            {code: '36', label: '保健衛生'},
            {code: '37', label: '医療'},
            {code: '38', label: 'その他'},
            {code: '41', label: '広報'},
            {code: '51', label: '観光・文化'},
            {code: '52', label: 'その他'},
            {code: '61', label: 'その他'}
        ]
    });
});

