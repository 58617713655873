/**
 * 複数のチェックボックスを管理する。
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dijit/form/CheckBox',
    './_OptionGroupMixin'
], function(module, declare, lang, CheckBox, _OptionGroupMixin) {
    return declare(module.id.replace(/\//g, '.'), _OptionGroupMixin, {
        /**
         * 「すべて」を表す選択肢を無くすかどうか
         * @type {boolean}
         */
        noAll: false,

        /**
         * 「すべて」を表すCheckBoxへの参照
         * @type {CheckBox}
         */
        _checkAllWidget: null,

        /**
         * 各オプションを生成するためのコンストラクター関数
         * @type {function}
         */
        _optionWidgetClass: CheckBox,

        /**
         * @override
         */
        _createOptionGroup: function() {
            if (!this.noAll) {
                // 「すべて」を先頭に追加
                this._checkAllWidget = this.createOption({label: 'すべて'});
                this._checkAllWidget.on('change', lang.hitch(this, this.onCheckAllChanged));
            }
            this.inherited(arguments);
        },

        /**
         * 現在のチェック状態を元にこのウィジェットのvalueプロパティーを更新
         * @override
         */
        _updateValue: function() {
            this.inherited(arguments);
            if (!this.noAll) {
                // 「すべて」は自身以外の全選択肢がチェックされている場合だけチェックされる
                this._checkAllWidget.set('checked', this.value.length === this._optionWidgets.length, false);
            }
        },

        /**
         * check属性以外は「すべて」にも他の選択肢と同様の設定値を適用する。
         * @param {string} attrName 属性名
         * @param {*} value 新たな値
         * @override
         */
        _setAllOptions: function(attrName, value) {
            if (attrName !== 'checked') {
                this._checkAllWidget.set(attrName, value);
            }
            this.inherited(arguments);
        },

        /**
         * 「すべて」クリック時
         */
        onCheckAllChanged: function(checked) {
            this._setAllOptions('checked', checked);
        }
     });
});
