define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'idis/util/DateUtils',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'app/view/grid/CommonColumn',
    './EvacOrderColumn'
], function (module, declare, lang, domClass, DateUtils, IdisGrid, helper, CommonColumn, EvacOrderColumn) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            // ボタン
            helper.buttonColumn('detail', '詳細'),
            CommonColumn.municipalityCd,
            helper.column('districtFreeName', '地区', { sortable: false }),
            EvacOrderColumn.alertLevel,
            EvacOrderColumn.evacOrderType,
            EvacOrderColumn.evacOrderTimestamp,
            EvacOrderColumn.issueReasonType,
            helper.column('evacNum', '対象', {
                children: [
                    helper.column('evacHouseholdDistNum', '世帯数', { sortable: false }),
                    helper.column('evaqueeDistNum', '人数', { sortable: false })
                ],
                sortable: false
            }),
            // 訂正・取消はそれぞれのフラグからサーバー側で判断して返しているため、
            // dstoreのGridでは単純にリクエストできないためソート対象から外す。
            helper.column('correctCancelStatus', '訂正・取消', { sortable: false }),
            // 更新日時
            EvacOrderColumn.updTimestamp,
            // 公開日時
            EvacOrderColumn.releaseTimestamp
        ],

        // DOMを構築する
        buildRendering: function () {
            this.inherited(arguments);
            domClass.add(this.domNode, 'evacorder-EvacOrderGrid');
        }
    });
});
