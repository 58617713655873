define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/on',
    'dojo/text!./templates/SampleGridMapPage.html',
    'dojo/topic',
    'dojo/window',
    'idis/control/Locator',
    'idis/view/page/_PageBase',
    'leaflet',
    '../../config',
    'dijit/form/Form',
    'dijit/form/TextBox',
    'idis/view/form/Button'
], function(module, array, declare, lang, domStyle, on, template, topic, winUtils,
    Locator, _PageBase, leaflet, config) {
    return declare(module.id.replace(/\//g, '.'), _PageBase, {
        // テンプレート文字列
        templateString: template,

        /**
         * マップ用DOMノードをリサイズ中かどうか
         * （リサイズ処理が大量に呼ばれるのを防ぐ）
         * @type {boolean}
         */
        resizing: false,

        /**
         * 地図の現在のモード
         * @type {string}
         */
        _mode: null,

        /**
         * 地図に紐づけられたイベントリスナーの一覧
         * @type {Object[]}
         */
        _handles: null,

        constructor: function() {
            this._handles = [];
        },

        /**
         * マップ幅を調整する。
         */
        resizeMapNode: function() {
            // 破棄後に呼び出された場合は反応しない
            if (!this.mapNode) {
                return;
            }
            // 画面全体のサイズ
            var fullBox = winUtils.getBox(this.ownerDocument);

            // 幅調整
            // 全体から左側を抜いた幅（ある程度余裕を持たせる）
            var otherWidth = fullBox.w - 400;
            // 400 <= width <= 1200 の範囲に収める
            var mapWidth = Math.max(400, Math.min(1200, otherWidth));

            // 高さ調整
            // 全体から下部を抜いた高さ（ある程度余裕を持たせる）
            var otherHeight = fullBox.h - 200;
            // 400 <= height <= 800 の範囲に収める
            var mapHeight = Math.max(300, Math.min(800, otherHeight));

            // マップ領域にサイズを設定
            domStyle.set(this.mapNode, {
                width: mapWidth + 'px',
                height: mapHeight + 'px'
            });
            // Leafletにサイズ更新を反映させる
            if (this.map) {
                this.map.invalidateSize();
            }
            // リサイズ完了
            this.resizing = false;
        },

        /**
         * マップを初期化する。
         */
        initMap: function() {
            // mapの生成
            var latlng = [config.map.latitude, config.map.longitude];
            this.map = leaflet.map(this.mapNode).setView(latlng, 11);
            // destroy時にmapを破棄するよう設定
            this.own(this.map);
            // ウィンドウresize時に反応させる
            var win = winUtils.get(this.ownerDocument);
            this.own(on(win, 'resize', lang.hitch(this, function() {
                // 既にリサイズ中なら何もしない
                if (this.resizing) {
                    return;
                }
                this.resizing = true;
                // 30fpsで処理を実施
                setTimeout(lang.hitch(this, 'resizeMapNode'), 1000 / 30);
            })));
        },

        /**
         * 緯度経度モードに入るとき呼ばれる。
         */
        enterLonlatMode: function() {
            // ポップアップをインスタンス化
            this._popup = leaflet.popup();
            // クリック時のイベント登録
            var handle = this.map.on('click', lang.hitch(this, function(e) {
                this._popup.setLatLng(e.latlng)
                .setContent('緯度: ' + e.latlng.lat + '<br>経度：' + e.latlng.lng)
                .openOn(this.map);
            }));
            // モード変更時の片付け対象としてイベントを登録
            this._handles.push(handle);
        },

        /**
         * 緯度経度モードを抜けるとき呼ばれる。
         */
        leaveLonlatMode: function() {
            if (this._popup) {
                this._popup.remove();
            }
        },

        /**
         * 円モードに入るとき呼ばれる。
         */
        enterCircleMode: function() {
            // クリック時に円を追加
            var handle = this.map.on('click', lang.hitch(this, function(e) {
                leaflet.circle(e.latlng, {
                    color: 'blue',
                    fillColor: '#00F',
                    fillOpacity: 0.5,
                    radius: 500
                }).addTo(this.map);
            }));
            // モード変更時の片付け対象としてイベントを登録
            this._handles.push(handle);
        },

        /**
         * 変更イベントを受信したときに呼ばれる
         * @param {Object} payload
         * @param {string} payload.mode 新しいモード
         */
        onModeChange: function(payload) {
            // 現在のモードと比較
            if (this._mode === payload.mode) {
                return; // モード変更が無ければそのまま抜ける
            }
            console.log('leave ' + this._mode + ' mode');
            // 接続済みのイベントリスナーを解除
            array.forEach(this._handles, function(handle) {
                handle.off();
            });
            this._handles = [];
            // モードに応じた片付けを実施
            switch(this._mode) {
                case 'lonlat':
                    this.leaveLonlatMode();
                    break;
            }
            // 現在のモードを更新
            this._mode = payload.mode;
            console.log('enter ' + this._mode + ' mode');
            // モードに応じた処理を実施
            switch(this._mode) {
                case 'lonlat':
                    this.enterLonlatMode();
                    break;
                case 'circle':
                    this.enterCircleMode();
                    break;
                default:
                    console.error('mode not supported: ' + this._mode);
            }
        },

        /**
         * 緯度経度ボタンが押されたとき呼ばれる。
         */
        onLonlatButtonClick: function() {
            topic.publish(module.id + '::changeMode', {mode: 'lonlat'});
        },

        /**
         * ポリゴン描画ボタンが押されたとき呼ばれる。
         */
        onCircleButtonClick: function() {
            topic.publish(module.id + '::changeMode', {mode: 'circle'});
        },

        // HTML上にウィジェットが設置されてから呼ばれる
        startup: function() {
            this.inherited(arguments);
            // 表示領域のリサイズ
            this.resizeMapNode();
            // マップを初期化
            this.initMap();
            // 地図レイヤーの追加
            leaflet.tileLayer(config.map.url, {
                maxZoom: 18
            }).addTo(this.map);

            // 表示モード切替に反応する
            topic.subscribe(module.id + '::changeMode', lang.hitch(this, this.onModeChange));
        }
    });
});

