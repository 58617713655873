/**
 * 空ディレクトリーや非公開含め表示情報を表示。
 * @module app/model/FullLayer
 */
define([
    'idis/model/UserInfo',
    'idis/store/JsonLayerModel',
    './LayerStore'
], function(UserInfo, JsonLayerModel, LayerStore) {
    /**
     * シングルトンを返す。
     */
    return new JsonLayerModel({
        rootId: 0,
        store: LayerStore
    });
});

