/**
 * REST APIを利用する共通storeモジュール
 * @module idis/store/IdisRest
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/request',
    'dojox/lang/functional/object',
    'dstore/Rest',
    'dstore/Trackable',
    '../error/InvalidArgumentException',
    '../util/FormUtils'
], function(module, declare, lang, request, df, Rest, Trackable, InvalidArgumentException, FormUtils) {
    /**
     * REST APIを利用する共通storeクラス
     * @class IdisRest
     * @extends module:dstore/Rest~Rest
     * @param {Object} kwArgs キーワード引数
     * @param {string} kwArgs.target 利用するAPIエンドポイント
     * @param {string} [kwArgs.idProperty=id] 各要素がidとして持つプロパティー
     */
    return declare([Rest, Trackable], /** @lends module:idis/store/IdisRest~IdisRest# */ {
        /**
         * 範囲取得時、開始点指定クエリーのキー名。
         * dstore/Requestで利用。
         * @type {string}
         */
        rangeStartParam: 'start',

        /**
         * 範囲取得時、取得数指定クエリーのキー名。
         * dstore/Requestで利用。
         * @type {string}
         */
        rangeCountParam: 'count',

        /**
         * ソート条件が格納されるクエリーのキー名。
         * dstore/Requestで利用。
         * @type {string}
         */
        sortParam: 'sort',

        /**
         * 追加する際のデフォルト位置を先頭にするかどうか
         * @type {boolean}
         */
        defaultNewToStart: true,

        constructor: function(kwArgs) {
            kwArgs = kwArgs || {};
            // 引数チェック
            if (!kwArgs.target) {
                throw new InvalidArgumentException(module.id + '::constructor: Property "target" must be specified.');
            }
        },

        // キャッシュ利用しないようにdstore/Requestのメソッド内容を変更
        // （IEでブラウザーにキャッシュされてしまう問題の回避）
        _request: function (kwArgs) {
            // 基本はdstore/Requestより引用
            kwArgs = kwArgs || {};

            // perform the actual query
            var headers = lang.delegate(this.headers, { Accept: this.accepts });

            if ('headers' in kwArgs) {
                lang.mixin(headers, kwArgs.headers);
            }

            var requestUrl = this._renderUrl(kwArgs.queryParams);

            var response = request(requestUrl, {
                preventCache: true, // ここのみ変更
                method: 'GET',
                headers: headers
            });
            var collection = this;
            var parsedResponse = response.then(function (response) {
                return collection.parse(response);
            });
            return {
                data: parsedResponse.then(function (data) {
                    // support items in the results
                    var results = data.items || data;
                    for (var i = 0, l = results.length; i < l; i++) {
                        results[i] = collection._restore(results[i], true);
                    }
                    return results;
                }),
                total: parsedResponse.then(function (data) {
                    // check for a total property
                    var total = data.total;
                    if (total > -1) {
                        // if we have a valid positive number from the data,
                        // we can use that
                        return total;
                    }
                    // else use headers
                    return response.response.then(function (response) {
                        var range = response.getHeader('Content-Range');
                        return range && (range = range.match(/\/(.*)/)) && +range[1];
                    });
                }),
                response: response.response
            };
        },

        // キャッシュ利用しないようにdstore/Restのメソッド内容を変更
        // （IEでブラウザーにキャッシュされてしまう問題の回避）
        get: function (id, options) {
            // 基本はdstore/Restより引用
            options = options || {};
            var headers = lang.mixin({ Accept: this.accepts }, this.headers, options.headers || options);
            var store = this;
            return request(this._getTarget(id), {
                preventCache: true, // ここのみ変更
                headers: headers
            }).then(function (response) {
                return store._restore(store.parse(response), true);
            });
        },

        /**
         * 指定された条件でフィルターを生成して実行し、結果となるCollectionを返す。
         * queryの各キーと値の組み合わせを条件として追加する。
         * ただし、値がDateオブジェクトの場合に限りgetTimeで取得した値を利用する。
         * 取得した値が偽値の場合は未入力と考えられるため、フィルター条件に追加しない。
         * @param {Object} query クエリー条件名と値の組み合わせ
         * @returns {dstore/Store.Collection} フィルター結果
         */
        filterBy: function(query) {
            var filter = new this.Filter();
            // 各入力値を確認
            df.forIn(FormUtils.serialize(query), function(value, key) {
                // 有効な値であれば検索条件に追加
                // （数値の0やfalseは明示的な検索値と考えられるため残す）
                if (value || value === 0 || value === false) {
                    filter = filter.eq(key, value);
                }
            });
            return this.filter(filter);
        }
    });
});

