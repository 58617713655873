/**
 * 自動更新ダイアログの中身。
 * @module app/map/AutoUpdatePane
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/AutoUpdatePane.html',
    'idis/view/_IdisWidgetBase',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/NumberSpinner'
], function(module, declare, template, _IdisWidgetBase) {
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase, {
        // テンプレート文字列
        templateString: template,

        // ウィジェットのルート要素に付与されるCSSクラス
        baseClass: 'map-AutoUpdatePane'
    });
});
