/**
 * グリッド画面のサンプル実装用モジュール。
 * @module app/folder/view/_CommitMixin
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/service/Requester',
    'idis/view/Loader',
    'app/model/LayerStore'
    // 以下、変数として受け取らないモジュール
], function(module, declare, lang, Requester, Loader, LayerStore) {
    /**
     * グリッド画面のサンプル実装。
     * @class _CommitMixin
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(null, /** @lends module:app/view/folder/view/_CommitMixin~_CommitMixin# */ {
        // ツリーの状態が変わった際に呼ばれる
        onTreeChange: function() {
            this.inherited(arguments);
            // 確定ボタンは変更履歴があるときだけ有効
            this.commitButton.set('disabled', !this.leftTree.model.isChanged());
        },

        /**
         * 確定ボタンがクリックされた際に呼ばれる。
         * @param {MouseEvent} evt クリック・イベント
         */
        onCommitButtonClick: function(/* evt */) {
            this.chain.confirm('変更を確定します。よろしいですか？', function(chain) {
                // ローディング表示＆リクエスト
                Loader.wait(Requester.post('/api/folders/change', {
                    data: {changeList: this.leftTree.model.getChangeList()}
                }).then(function() {
                    // 成功時はLayerStoreを全て更新（ローディング表示はこれも待つ）
                    return LayerStore.refreshAll().otherwise(function(err) {
                        // 404に限り失敗を許容
                        if (!err || !err.response || err.response.status !== 404) {
                            throw err;
                        }
                    });
                }).then(lang.hitch(this, function() {
                    // リクエストとLayerStore更新の両方に成功時
                    // この画面のツリーを再描画
                    this.resetTree();
                    chain.infoComplete();
                }), function(err) {
                    // いずれかに失敗時
                    chain.infoError(err);
                }));
            });
        }
    });
});

