define([
    'leaflet'
], function(leaflet) {
    var OverlayLayer = leaflet.Class.extend({
        
        includes: leaflet.Mixin.Events,
        
        initialize: function(map, layer, options) {
            leaflet.setOptions(this, options);
            this._map = map;
            this._layer = layer;
        },
        
        load: function() {
            this.fire('loaded');
        },
        
        draw: function(texture) {
            var image = this._layer.getImage();
            var bounds = this._layer.getBounds();
            var pixelBounds = (this.options.pixelBounds ? this.options.pixelBounds : this._map.getPixelBounds());
            var zoom = this._map.getZoom();
            var imageTopLeft = this._map.project( bounds.getNorthWest() , zoom);
            var imageBottomRight = this._map.project( bounds.getSouthEast() , zoom);
            var topLeft = pixelBounds.getTopLeft();
            var opacity = (this.options.opacity ? this.options.opacity : 1);
            texture.globalAlpha = opacity;
            texture.drawImage(
                image, 
                0, 0, 
                image.width, image.height,
                imageTopLeft.x - topLeft.x, imageTopLeft.y - topLeft.y,
                imageBottomRight.x - imageTopLeft.x,imageBottomRight.y - imageTopLeft.y );
        }
        
    });

    /*
    leaflet.mapToImage.overlayLayer = function(map, layer, options){
        return new leaflet.mapToImage.OverlayLayer(map, layer, options);
    };
    */

    return OverlayLayer;
});

