/**
 * 配信情報Lアラート内容確認ダイアログ（体制）
 * @module app/sending/LalertDisasterPreventionDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/LalertDisasterPreventionDialog.html',
    'idis/view/dialog/DialogChain',
    'dojo/date/locale',
    'dojo/dom-style',
    'idis/view/page/_PageBase',
    // 以下、変数から参照されないモジュール
    'app/provide/form/DistributionTypeInput',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button'
], function(module, declare, template, DialogChain, locale, domStyle, _PageBase) {
    /**
     * 配信情報Lアラート内容確認ダイアログ（体制）
     * @class LalertDisasterPreventionDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/sending/LalertDisasterPreventionDialog~LalertDisasterPreventionDialog# */ {
        // テンプレート文字列
        templateString: template,

        constructor: function() {
            this.chain = DialogChain.get(this);
        },

        initDialog: function(obj){
            // 更新種別
            this.distributionType.set('value', obj.distributionType);

            // 発行元
            this.municipalityName.innerHTML = obj.municipalityName;

            // 災害対策本部名
            this.headquarterName.innerHTML = obj.headquarterName;

            // 設置状況
            this.headquarterStatus.innerHTML = obj.headquarterStatus;

            // 設置・解散日時
            this.headquarterDatetime.innerHTML = locale.format(new Date(obj.headquarterDatetime));

            // 補足情報
            this.complementaryInfo.innerHTML = obj.complementaryInfo;

            // 訂正・取消理由
            if (obj.errataDescription) {
                domStyle.set(this.errataDescriptionRow, 'display', '');
                this.errataDescription.innerHTML = obj.errataDescription;
            }
        },

        onOKButton: function() {
            this.getParent().hide();
        }
    });
});
