/**
 * フォルダ管理画面の右移動処理を実装するMixin。
 * @module app/folder/view/_MoveRightMixin
 */
define([
    'module',
    'dojo/_base/declare'
    // 以下、変数として受け取らないモジュール
], function(module, declare) {
    /**
     * フォルダ管理画面の並び替え処理を実装するMixin。
     * @class _MoveRightMixin
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(null, /** @lends module:app/view/folder/view/_MoveRightMixin~_MoveRightMixin# */ {
        /**
         * 選択中の要素に従ってボタンの状態を最新化する。
         */
        _updateMoveRightButton: function() {
            var leftItem = this.leftTree.get('lastFocusedItem');
            var rightItem = this.rightTree.get('lastFocusedItem');
            if (this.moveRightButton) {
                var isFolder, isDescendant = false;
                if (leftItem && rightItem) {
                    // 右側要素がフォルダかどうか
                    isFolder = rightItem.infoCategoryCd && rightItem.infoCategoryCd.indexOf('T') === 0;
                    // 右側要素が左側要素自身やその子孫要素でないか
                    var leftItemId = this.model.getIdentity(leftItem);
                    var parent = rightItem;
                    while (parent) {
                        if (leftItemId === this.model.getIdentity(parent)) {
                            // 親を辿ったときに左側要素と一致した場合
                            isDescendant = true;
                            break;
                        }
                        // 親を辿る
                        parent = this.model.getParent(parent);
                    }
                }
                // いずれかを選択していない or 右側がフォルダでない or 移動要素の子孫ならdisabled
                this.moveRightButton.set('disabled', !leftItem || !rightItem || !isFolder || isDescendant);
            }
        },

        /**
         * 左ツリーの選択要素が変わったときに呼ばれる。
         */
        onLeftTreeFocus: function(item) {
            this.inherited(arguments);
            this._updateMoveRightButton(item);
        },

        /**
         * 右ツリーの選択要素が変わったときに呼ばれる。
         */
        onRightTreeFocus: function(item) {
            this.inherited(arguments);
            this._updateMoveRightButton(item);
        },

        /**
         * ツリーの状態が変わったときに呼ばれる。
         */
        onTreeChange: function() {
            this.inherited(arguments);
            this._updateMoveRightButton();
        },

        /**
         * 「右へ移動」ボタンがクリックされた際に呼ばれる。
         * @param {MouseEvent} evt クリック・イベント
         */
        onMoveRightButtonClick: function(/* evt */) {
            // 移動する要素
            var item = this.leftTree.get('lastFocusedItem');
            // 送り元
            var oldParent = this.model.getParent(item);
            // 送り先
            var newParent = this.rightTree.get('lastFocusedItem');

            // 確認ダイアログを表示
            this.chain.confirm([
                '「', item.name, '」を<br>「', newParent.name, '」の中に移動します。<br>よろしいですか？'
            ].join(''), function(chain) {
                // 移動先は常に先頭
                this.model.moveItem(item, oldParent, newParent, 0);
                chain.info('移動を完了しました。<br>（確定ボタンを押すまで実際のデータには反映されません）');
            });
        }
    });
});

