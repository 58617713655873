/**
 * Lアラート詳細画面用モジュール。
 * @module app/view/page/LalertDetailPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, lang, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        columns: [
            lang.mixin(helper.column('reportDatetime', '発令時間'), {sortable: false}),
            lang.mixin(helper.column('infokind', '種別'), {sortable: false}),
            // lang.mixin(helper.column('areasName', '地域・市町村'), {sortable: false}),
            lang.mixin(helper.column('areasName', '地域・市/行政区'), {sortable: false}),
            lang.mixin(helper.column('kindName', '発令中の情報'), {sortable: false})
        ]
    });
});
