/**
 * 観測情報パネル
 * @module app/monitor/ObservationPanel
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/json',
    'dojo/text!./templates/ObservationPanel.html',
    'dojo/topic',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/view/page/_PageBase',
    '../config',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane'
], function(module, array, declare, lang, locale, JSON, template, topic, Router, UserInfo, Requester,
    DialogChain, Loader, _PageBase, config) {
    /**
     * 観測情報パネル
     * @class ObservationPanel
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/monitor/ObservationPanel~ObservationPanel# */ {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page ' +
        	'idis-Page--rainfallobs ' +
            'idis-Page--riverlevelobs ' +
            'idis-Page--waterlevelobs ' +
            'idis-Page--tidelevelobs ' +
            'idis-Page--sedimentriskobs',

        // テンプレート文字列
        templateString: template,

        /**
         * 市町村コード
         */
        _municipalityCd: null,

        // _maxSediRiskLevel: null,

        // _maxSediRiskLevelNum: null,

        // _maxSediRiskLevelClass: null,

        /**
         * constructor
         */
        constructor: function() {
            this.chain = DialogChain.get(this);
            this._municipalityCd = UserInfo.getSelectedMunicipalityCd();
            console.debug('観測情報表示対象の市町村コード：' + this._municipalityCd);
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
            this.initTable();

            // 市町村切替時に観測情報を更新
            this.own(topic.subscribe('idis/view/form/MunicipalitySelectForm::selected',
                lang.hitch(this, function(payload) {
                    this._municipalityCd = payload.municipalityCd;
                    this.initTable();
            })));
        },

        /**
         * テーブルを初期化します
         */
        initTable: function() {
            // 土砂災害危険度情報を設定
            // this.setSedimentDisasterInfo();

            // 雨量情報を設定
            this.setRainInfo();

            // 水位情報を設定
            this.setRiverInfo();

            // 潮位情報を設定
            // this.setTideInfo();
        },

        /**
         * 土砂災害危険度情報を設定する
         */
        // setSedimentDisasterInfo: function() {
        //     var self = this;

        //     var promise = Requester.get('/data/sediment/summary/' + this._municipalityCd + '.json', {
        //         headers: {'Content-Type': 'application/json; charset=utf-8'},
        //         handleAs: 'json',
        //         preventCache : true
        //     }).then(function(item) {
        //         console.debug('土砂災害危険度情報（' + self._municipalityCd + '）：' +
        //             JSON.stringify(item));

        //         self.getMaxSediRiscLevel(item);

        //         self.setSediRisk();

        //     }, function(error) {
        //         console.log(error);
        //         if (error.response.status === 404) {
        //             var item = {
        //                 'sediRiskHeavyRainCautionAreaNum' : 0,
        //                 'sediRiskHeavyRainAlarmAreaNum' : 0,
        //                 'sediRiskSedimentLimitOver3hAreaNum' : 0,
        //                 'sediRiskSedimentLimitOver2hAreaNum' : 0,
        //                 'sediRiskSedimentLimitOver1hAreaNum' : 0,
        //                 'sediRiskSedimentLimitOverNowAreaNum' : 0
        //             };
        //             self.getMaxSediRiscLevel(item);
        //             self.setSediRisk();
        //         } else {
        //             self.chain.info('情報の取得に失敗しました。', 'エラー');
        //         }
        //     });
        //     //ローダーの表示
        //     Loader.wait(promise);
        // },

        // getMaxSediRiscLevel: function(item){

        //     if(item.sediRiskSedimentLimitOverNowAreaNum){
        //         this._maxSediRiskLevel = '実況で基準値超過';
        //         this._maxSediRiskLevelNum = item.sediRiskSedimentLimitOverNowAreaNum;
        //         this._maxSediRiskLevelClass = 'is-sedimentLimitOverNow';
        //         return;
        //     }
        //     if(item.sediRiskSedimentLimitOver1hAreaNum){
        //         this._maxSediRiskLevel = '1時間後に基準値超過';
        //         this._maxSediRiskLevelNum = item.sediRiskSedimentLimitOver1hAreaNum;
        //         this._maxSediRiskLevelClass = 'is-heavyRainCaution';
        //         return;
        //     }
        //     if(item.sediRiskSedimentLimitOver2hAreaNum){
        //         this._maxSediRiskLevel = '2時間後に基準値超過';
        //         this._maxSediRiskLevelNum = item.sediRiskSedimentLimitOver2hAreaNum;
        //         this._maxSediRiskLevelClass = 'is-sedimentLimitOver2h';
        //         return;
        //     }
        //     if(item.sediRiskSedimentLimitOver3hAreaNum){
        //         this._maxSediRiskLevel = '3時間後に基準値超過';
        //         this._maxSediRiskLevelNum = item.sediRiskSedimentLimitOver3hAreaNum;
        //         this._maxSediRiskLevelClass = 'is-sedimentLimitOver3h';
        //         return;
        //     }
        //     if(item.sediRiskHeavyRainAlarmAreaNum){
        //         this._maxSediRiskLevel = '大雨警報(土砂災害)基準超過';
        //         this._maxSediRiskLevelNum = item.sediRiskHeavyRainAlarmAreaNum;
        //         this._maxSediRiskLevelClass = 'is-heavyRainAlarm';
        //         return;
        //     }
        //     if(item.sediRiskHeavyRainCautionAreaNum){
        //         this._maxSediRiskLevel = '大雨注意報基準超過';
        //         this._maxSediRiskLevelNum = item.sediRiskHeavyRainCautionAreaNum;
        //         this._maxSediRiskLevelClass = 'is-heavyRainCaution';
        //         return;
        //     }
        //     this._maxSediRiskLevel = 'なし';
        //     this._maxSediRiskLevelNum = 0;
        //     this._maxSediRiskLevelClass =  '';
        //     return;
        // },

        // setSediRisk: function(){
        //     // 画面遷移していない場合のみ注入
        //     var html = ['<span>'];
        //     if(this._maxSediRiskLevelNum > 0) {
        //         this.sediRiscCurrentMaxLevel.setAttribute('class', this._maxSediRiskLevelClass);
        //         html.push('発表（' + this._maxSediRiskLevelNum + '箇所）');
        //     } else {
        //         this.sediRiscCurrentMaxLevel.setAttribute('class', null);
        //         html.push('-');
        //     }
        //     html.push('</span>');
        //     this.sediRiscCurrentMaxLevelName.innerHTML = this._maxSediRiskLevel;
        //     this.sediRiscCurrentMaxLevel.innerHTML = html.join('');
        // },

        /**
         * 土砂災害危険度情報を設定する
         */
        // sediRiskAreaNum: function(element, areaNum, className) {
        //     if(element) {
        //         // 画面遷移していない場合のみ注入
        //         var html = ['<span>'];
        //         if(areaNum > 0) {
        //             element.setAttribute('class', className);
        //             html.push('発表（' + areaNum + '箇所）');
        //         } else {
        //             element.setAttribute('class', null);
        //             html.push('なし');
        //         }
        //         html.push('</span>');
        //         element.innerHTML = html.join('');
        //     }
        // },

        /**
         * 雨量情報を設定する
         */
        setRainInfo: function() {
            var self = this;

            var promise = Requester.get('/data/rainfall/data.json', {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(function(item) {
                var latestDateTimestamp = new Date(item.latestDateTimestamp.replace(/-/g, '/'));
                var jsonFilename = self.formatDateTimeFilename(latestDateTimestamp.getTime()) + '.json';
                var promise = Requester.get('/data/rainfall/list/' + jsonFilename, {
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(function(item) {
                    // 雨量を設定
                    self.setRain(self, item);
                }, function(error) {
                    console.log('雨量情報が見つかりません。初期値をセットします。' + (error.message ? ('('+error.message+')') : ''));
                    var item = {
                        // 'rainfall10Min30mmObsNum' : 0,
                        // 'rainfall10Min20mmObsNum' : 0,
                        // 'rainfall10Min10mmObsNum' : 0,
                        'rainfallHourly50mmObsNum' : 0,
                        'rainfallHourly30mmObsNum' : 0,
                        'rainfallHourly15mmObsNum' : 0,
                        'rainfallCumulative250mmObsNum' : 0,
                        'rainfallCumulative150mmObsNum' : 0,
                        'rainfallCumulative90mmObsNum' : 0
                    };
                    self.setRain(self, item);
                    if (error.response.status !== 404) {
                        self.chain.info('情報の取得に失敗しました。', 'エラー');
                    }
                });
                //ローダーの表示
                Loader.wait(promise);
            }, function(error) {
                console.log('雨量最新日時情報が見つかりません。初期値をセットします。' + (error.message ? ('('+error.message+')') : ''));
                var item = {
                    // 'rainfall10Min30mmObsNum' : 0,
                    // 'rainfall10Min20mmObsNum' : 0,
                    // 'rainfall10Min10mmObsNum' : 0,
                    'rainfallHourly50mmObsNum' : 0,
                    'rainfallHourly30mmObsNum' : 0,
                    'rainfallHourly15mmObsNum' : 0,
                    'rainfallCumulative250mmObsNum' : 0,
                    'rainfallCumulative150mmObsNum' : 0,
                    'rainfallCumulative90mmObsNum' : 0
                };
                self.setRain(self, item);
                    if (error.response.status !== 404) {
                    self.chain.info('情報の取得に失敗しました。', 'エラー');
                }
        });
            //ローダーの表示
            Loader.wait(promise);
        },

        /**
         * 雨量を設定する
         */
        setRain: function(self, data) {
            var rainFall = {
                // 'rainfall10Min30mmObsNum' : 0,
                // 'rainfall10Min20mmObsNum' : 0,
                // 'rainfall10Min10mmObsNum' : 0,
                'rainfallHourly50mmObsNum' : 0,
                'rainfallHourly30mmObsNum' : 0,
                'rainfallHourly15mmObsNum' : 0,
                'rainfallCumulative250mmObsNum' : 0,
                'rainfallCumulative150mmObsNum' : 0,
                'rainfallCumulative90mmObsNum' : 0
            };
            array.forEach(data.items, function(item){
                // 選択された市町村コードが県でない場合は絞り込みを行う
                if (self._municipalityCd !== config.municInfo.prefMunicCd &&
                    self._municipalityCd !== item.municipalityCd) {
                    return;
                }

                // // 10分雨量
                // if (item.rainfall10MinFlg === '0') {
                //     if (item.rainfall10Min >= 30) {
                //         rainFall.rainfall10Min30mmObsNum += 1;
                //     } else if (item.rainfall10Min >= 20) {
                //         rainFall.rainfall10Min20mmObsNum += 1;
                //     } else if (item.rainfall10Min >= 10) {
                //         rainFall.rainfall10Min10mmObsNum += 1;
                //     }
                // }
                // 時間雨量
                if (item.rainfall60MinFlg === '0') {
                    if (item.rainfall60Min >= 50) {
                        rainFall.rainfallHourly50mmObsNum += 1;
                    } else if (item.rainfall60Min >= 30) {
                        rainFall.rainfallHourly30mmObsNum += 1;
                    } else if (item.rainfall60Min >= 15) {
                        rainFall.rainfallHourly15mmObsNum += 1;
                    }
                }
                // 累計雨量
                if (item.rainfallCumulativeFlg === '0') {
                    if (item.rainfallCumulative >= 250) {
                        rainFall.rainfallCumulative250mmObsNum += 1;
                    } else if (item.rainfallCumulative >= 150) {
                        rainFall.rainfallCumulative150mmObsNum += 1;
                    } else if (item.rainfallCumulative >= 90) {
                        rainFall.rainfallCumulative90mmObsNum += 1;
                    }
                }
            });
            // self.setRainfallObsNum(self.rainfall10Min30mmObs,
            //     rainFall.rainfall10Min30mmObsNum, 'is-overLimitLevel-03');
            // self.setRainfallObsNum(self.rainfall10Min20mmObs,
            //     rainFall.rainfall10Min20mmObsNum, 'is-overLimitLevel-02');
            // self.setRainfallObsNum(self.rainfall10Min10mmObs,
            //     rainFall.rainfall10Min10mmObsNum, 'is-overLimitLevel-01');
            self.setRainfallObsNum(self.rainfallHourly50mmObs,
                rainFall.rainfallHourly50mmObsNum, 'is-rainfallHourly50mmObs');
            self.setRainfallObsNum(self.rainfallHourly30mmObs,
                rainFall.rainfallHourly30mmObsNum, 'is-rainfallHourly30mmObs');
            self.setRainfallObsNum(self.rainfallHourly15mmObs,
                rainFall.rainfallHourly15mmObsNum, 'is-rainfallHourly15mmObs');
            self.setRainfallObsNum(self.rainfallCumulative250mmObs,
                rainFall.rainfallCumulative250mmObsNum, 'is-rainfallCumulative250mmObs');
            self.setRainfallObsNum(self.rainfallCumulative150mmObs,
                rainFall.rainfallCumulative150mmObsNum, 'is-rainfallCumulative150mmObs');
            self.setRainfallObsNum(self.rainfallCumulative90mmObs,
                rainFall.rainfallCumulative90mmObsNum, 'is-rainfallCumulative90mmObs');
        },

        /**
         * 雨量情報を設定する
         */
        setRainfallObsNum: function(element, obsNumm, className) {
            if(element) {
                // 画面遷移していない場合のみ注入
                var html = ['<span>'];
                if(obsNumm > 0) {
                    element.setAttribute('class', className);
                    html.push('発表（' + obsNumm + '箇所）');
                } else {
                    element.setAttribute('class', null);
                    html.push('なし');
                }
                html.push('</span>');
                element.innerHTML = html.join('');
            }
        },

        /**
         * 水位情報を設定する
         */
        setRiverInfo: function() {
            var self = this;

            var promise = Requester.get('/data/river/data.json', {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(function(item) {
                var latestDateTimestamp = new Date(item.latestDateTimestamp.replace(/-/g, '/'));
                var jsonFilename = self.formatDateTimeFilename(latestDateTimestamp.getTime()) + '.json';
                var promise = Requester.get('/data/river/list/' + jsonFilename, {
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(function(item) {
                    console.debug('水位情報（' + self._municipalityCd + '）：' +
                        JSON.stringify(item));
                    // 水位情報を設定
                    self.setRiver(self, item);
                }, function(error) {
                    console.log('水位情報が見つかりません。初期値をセットします。' + (error.message ? ('('+error.message+')') : ''));
                    var item = {
                        'riverLevelDangerObsNum' : 0,
                        'riverLevelEvacuateObsNum' : 0,
                        'riverLevelCautionObsNum' : 0,
                        'riverLevelStandbyObsNum' : 0
                    };
                    self.setRiver(self, item);
                            if (error.response.status !== 404) {
                        self.chain.info('情報の取得に失敗しました。', 'エラー');
                    }
                });
                //ローダーの表示
                Loader.wait(promise);
            }, function(error) {
                console.log('水位最新日時情報が見つかりません。初期値をセットします。' + (error.message ? ('('+error.message+')') : ''));
                var item = {
                    'riverLevelDangerObsNum' : 0,
                    'riverLevelEvacuateObsNum' : 0,
                    'riverLevelCautionObsNum' : 0,
                    'riverLevelStandbyObsNum' : 0
                };
                self.setRiver(self, item);
                    if (error.response.status !== 404) {
                    self.chain.info('情報の取得に失敗しました。', 'エラー');
                }
            });
            //ローダーの表示
            Loader.wait(promise);
        },

        /**
         * 水位を設定する
         */
        setRiver: function(self, data) {
            var river = {
                'riverLevelDangerObsNum' : 0,
                'riverLevelEvacuateObsNum' : 0,
                'riverLevelCautionObsNum' : 0,
                'riverLevelStandbyObsNum' : 0
            };
            array.forEach(data.items, function(item){
                // 選択された市町村コードが県でない場合は絞り込みを行う
                if (self._municipalityCd !== config.municInfo.prefMunicCd &&
                    self._municipalityCd !== item.municipalityCd) {
                    return;
                }

                // 水位
                if (item.riverLevelFlg === '0') {
                    if (item.riverLevelDanger !== null && item.riverLevelDanger !== 0 &&
                        item.riverLevel >= item.riverLevelDanger) {
                        river.riverLevelDangerObsNum += 1;
                    } else if (item.riverLevelEvacuate !== null && item.riverLevelEvacuate !== 0 &&
                        item.riverLevel >= item.riverLevelEvacuate) {
                        river.riverLevelEvacuateObsNum += 1;
                    } else if (item.riverLevelCaution !== null && item.riverLevelCaution !== 0 &&
                        item.riverLevel >= item.riverLevelCaution) {
                        river.riverLevelCautionObsNum += 1;
                    } else if (item.riverLevelStandby !== null && item.riverLevelStandby !== 0 &&
                        item.riverLevel >= item.riverLevelStandby) {
                        river.riverLevelStandbyObsNum += 1;
                    }
                }
            });
            self.setRiverObsNum(self.riverLevelDangerObs,
                river.riverLevelDangerObsNum, 'is-crisis');
            self.setRiverObsNum(self.riverLevelEvacuateObs,
                river.riverLevelEvacuateObsNum, 'is-judgment');
            self.setRiverObsNum(self.riverLevelCautionObs,
                river.riverLevelCautionObsNum, 'is-caution');
            self.setRiverObsNum(self.riverLevelStandbyObs,
                river.riverLevelStandbyObsNum, 'is-wait');
        },

        /**
         * 水位情報を設定する
         */
        setRiverObsNum: function(element, obsNumm, className) {
            if(element) {
                // 画面遷移していない場合のみ注入
                var html = ['<span>'];
                if(obsNumm > 0) {
                    element.setAttribute('class', className);
                    html.push('発表（' + obsNumm + '箇所）');
                } else {
                    element.setAttribute('class', null);
                    html.push('なし');
                }
                html.push('</span>');
                element.innerHTML = html.join('');
            }
        },

        /**
         * 潮位情報を設定する
         */
        setTideInfo: function() {
            var self = this;

            var promise = Requester.get('/data/tide/data.json', {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(function(item) {
                var latestDateTimestamp = new Date(item.latestDateTimestamp.replace(/-/g, '/'));
                var jsonFilename = self.formatDateTimeFilename(latestDateTimestamp.getTime()) + '.json';
                var promise = Requester.get('/data/tide/list/' + jsonFilename, {
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(function(item) {
                    console.debug('潮位情報（' + self._municipalityCd + '）：' +
                        JSON.stringify(item));
                    // 潮位情報を設定
                    self.setTide(self, item);
                }, function(error) {
                    console.log('潮位情報が見つかりません。初期値をセットします。' + (error.message ? ('('+error.message+')') : ''));
                    var item = {
                        'tideLevelReportObsNum' : 0,
                        'tideLevelAlartObsNum' : 0
                    };
                    self.setTide(self, item);
                    if (error.response.status !== 404) {
                        self.chain.info('情報の取得に失敗しました。', 'エラー');
                    }
                });
                //ローダーの表示
                Loader.wait(promise);
            }, function(error) {
                console.log('潮位最新日時情報が見つかりません。初期値をセットします。' + (error.message ? ('('+error.message+')') : ''));
                var item = {
                    'tideLevelReportObsNum' : 0,
                    'tideLevelAlartObsNum' : 0
                };
                self.setTide(self, item);
                    if (error.response.status !== 404) {
                    self.chain.info('情報の取得に失敗しました。', 'エラー');
                }
            });
            //ローダーの表示
            Loader.wait(promise);
        },

        /**
         * 潮位を設定する
         */
        setTide: function(self, data) {
            var tide = {
                'tideLevelReportObsNum' : 0,
                'tideLevelAlartObsNum' : 0
            };
            array.forEach(data.items, function(item){
                // 選択された市町村コードが県でない場合は絞り込みを行う
                if (self._municipalityCd !== config.municInfo.prefMunicCd &&
                    self._municipalityCd !== item.municipalityCd) {
                    return;
                }
                // 潮位
                if (item.tideLevelFlg === '0') {
                    if (item.tideLevelAlert !== null && item.tideLevelAlert !== 0 &&
                        item.tideLevel > item.tideLevelAlert) {
                        tide.tideLevelAlartObsNum += 1;
                    } else if (item.tideLevelReport !== null && item.tideLevelReport !== 0 &&
                        item.tideLevel > item.tideLevelReport) {
                        tide.tideLevelReportObsNum += 1;
                    }
                }
            });
            self.setTideObsNum(self.tideLevelReportObs,
                tide.tideLevelReportObsNum, 'is-overLimitLevel-01');
            self.setTideObsNum(self.tideLevelAlartObs,
                tide.tideLevelAlartObsNum, 'is-overLimitLevel-02');
        },

        /**
         * 潮位情報を設定する
         */
        setTideObsNum: function(element, obsNumm, className) {
            if(element) {
                // 画面遷移していない場合のみ注入
                var html = ['<span>'];
                if(obsNumm > 0) {
                    element.setAttribute('class', className);
                    html.push('発表（' + obsNumm + '箇所）');
                } else {
                    element.setAttribute('class', null);
                    html.push('なし');
                }
                html.push('</span>');
                element.innerHTML = html.join('');
            }
        },

        // onSedimentRiskObservationLinkClick: function(evt) {
        //     // ブラウザーの遷移処理をキャンセル
        //     evt.preventDefault();
        //     // 土砂災害危険度情報一覧画面へ遷移
        //     Router.moveTo('sedimentriskobservation');
        // },

        onRainfallObservationLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // 雨量情報一覧画面へ遷移
            Router.moveTo('observation/rainfall');
        },

        onWaterLevelObservationLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // 河川水位情報一覧画面へ遷移
            Router.moveTo('observation/river');
        },

        //onTideLevelObservationLinkClick: function(evt) {
        //    // ブラウザーの遷移処理をキャンセル
        //    evt.preventDefault();
        //    // 潮位実況情報一覧画面へ遷移
        //    Router.moveTo('observation/tide');
        //},

        // toggleLegendDialog: function(evt){
        //     // ブラウザーの遷移処理をキャンセル
        //     evt.preventDefault();
        //     // 凡例を表示
        //     topic.publish(module.id + '::showGridLegendLayer');
        // },

        /**
         * 'yyyy-MM-dd-HH-mm' 形式に変換
         */
        formatDateTimeFilename: function(val) {
            var timestamp = new Date(val);
            var dateLabel = locale.format(timestamp, {
                selector: 'date',
                datePattern: 'yyyy-MM-dd'
            });
            var timeLabel = locale.format(timestamp, {
                selector: 'time',
                timePattern: 'HH-mm'
            });
            return dateLabel + '-' + timeLabel;
        }
    });
});
