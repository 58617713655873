define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojox/lang/functional/object',
    'idis/view/form/RadioGroup',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/IdisSelector'
], function(module, declare, lang, locale, df, RadioGroup, IdisGrid, IdisSelector) {
    return declare(module.id.replace(/\//g, '.'), [IdisGrid, IdisSelector], {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--chat',

        allowSelectAll: false,

        radioButtons: {},

        constructor: function () {
            /**
             * 各列の定義
             * @type {Object[]}
             */
            this.columns = [
                {
                    field: 'checkbox',
                    label: '削除',
                    selector: 'checkbox'
                },
                { field: 'userId', label: 'ユーザID', sortable: true },
                { field: 'municipalityName', label: '所属', sortable: true },
                { field: 'organizationName', label: '組織', sortable: true },
                { field: 'userName', label: 'ユーザ名', sortable: true },
                { field: 'emailAddress', label: 'メールアドレス', sortable: true },
                {
                    field: 'sendRoleType', label: '投稿可否', sortable: false,
                    renderCell: lang.hitch(this, function (item, value) {
                        this.radioButtons[item.userId] = new RadioGroup({
                            name:'sendRoleType_' + item.userId,
                            options:[{label:'不可', value:'0'}, {label:'可', value:'1'}]
                        });
                        this.radioButtons[item.userId].set('value', value);
                        // HTMLとしてウィジェットに紐付くDOMノードを返す
                        return this.radioButtons[item.userId].domNode;
                    })
                },
                { field: 'autoUpdateFlg', label: '自動異動', sortable: true,
                    formatter: lang.hitch(this, function(item){
                        switch (item) {
                            case '0':
                                return '対象外';
                            case '1':
                                return '対象';
                            default:
                                return '';
                        }
                    })
                }
            ];

            this.inherited(arguments);
        },

        reset: function () {
            this.inherited(arguments);

            this.radioButtons = {};
        },

        /**
         * 選択したユーザのID一覧を返す。
         * @returns {string[]} 選択したユーザのID一覧
         */
        getSelectedIdList: function() {
            // 値がtrueのものに絞り込む（一度選択したものはfalseが入る）
            var selected = df.filterIn(this.selection, function(v) {return v;});
            // 残ったキー一覧を返す
            return df.keys(selected);
        }

    });
});
