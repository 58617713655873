/**
 * 避難レコメンドステータスを定数として定義
 * @module app/evacrecommend/consts/EVAC_RECOMMEND_STATUS
 */
define({
    /** 0: アクティブ */
    ACTIVE: '0',
    /** 1: 発令済 */
    ISSUED: '1',
    /** 2: 撤回 */
    SETTLED: '2',
    /** 3: 発令不要 */
    UNNECESSARY: '3',
    /** 9: 自動判定対象外 */
    MANUAL: '9'
});
