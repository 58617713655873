/**
 * クロノロジ詳細画面用モジュール。
 * @module app/chronology/ChronologyDetailPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/json',
    'dojo/_base/array',
    'dojo/topic',
    'dojo/text!./templates/ChronologyDetailPage.html',
    'dojo/promise/all',
    'dstore/Memory',
    'dstore/Trackable',
    'dojox/lang/functional/array',
    'idis/view/page/_PageBase',
    '../config',
    'idis/model/UserInfo',
    'idis/view/dialog/InfoDialog',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/consts/USER_TYPE',
    'idis/service/Requester',
    'idis/view/Loader',
    './_ChronologyPageBase',
    './ChronologyLinks',
    './ChronologyAction',
    './ChronologyUtil',
    'idis/util/DateUtils',
    './consts/ChronologyType',
    './consts/ChronologyCategories',
    // 以下、変数で受けないモジュール
    'dijit/layout/TabContainer',
    'dijit/TitlePane',
    'idis/view/form/WordCountTextarea',
    'idis/view/form/AclButton',
    'app/view/form/ShareOrganizationMultiSelector',
    'app/view/form/CustomizableMunicipalitySelector',
    'app/view/form/MunicipalitySelector',
    'app/view/form/ShareOrgsMultiSelector4Chronology',
    './ChronologyActionGrid',
    './ChronologyHistoryGrid',
    './ChronologyActionForm',
    './ChronologyAction',
    './ChronologyHistory'
], function(module, declare, lang, domStyle, json, array, topic, template, all,
    Memory, Trackable, df, _PageBase, config,
    UserInfo, InfoDialog, Locator, Router, USER_TYPE,
    Requester, Loader, _ChronologyPageBase, ChronologyLinks, ChronologyAction,
    ChronologyUtil, DateUtils, ChronologyType, ChronologyCategory) {
    /**
     * ロノロジ詳細画面
     * @class ChronologyDetailPage
     * @extends module:app/chronology/_ChronologyPageBase~_ChronologyPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _ChronologyPageBase,
    /** @lends module:app/chronology/ChronologyDetailPage~ChronologyDetailPage# */ {


        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--chronology',

        // テンプレート文字列
        templateString: template,

        /**
         * クロノロジID
         * @private
         */
        _chronologyId: null,

        /**
         * クロノロジ種別
         * @private
         */
        _chronologyType: null,

        // 対応情報のストア
        actionStore: null,

        // 過去の内容のストア
        histStore: null,

        _actualFlg: null, //本登録フラグ

        EXTERNAL_TYPE_LIST: ['01', '02', '03'],

        /**
         * 取得データ
         */
        _sourceData: null,

        /**
         * 管理対象宛先
         */
        _targetShares: [],

        /**
         * constructor
         */
        constructor: function() {
            // URLからクロノロジIDを取得
            this._chronologyId = Locator.getQuery().chronologyId;

            // URLから災害シミュレーション用のパラメータを取得
            this._simulateFlg = Locator.getQuery().simulateFlg === 'true';
            this._scenarioId = Locator.getQuery().scenarioId;
            this._eventId = Locator.getQuery().eventId;
        },

        buildRendering: function(){
            this.inherited(arguments);
            this.own(this.linkInsertDialog);
            // 対応履歴の新規登録
            this.own(topic.subscribe('app/chronology/ChronologyActionForm::initActionGrid',
                lang.hitch(this, function(payload){
                    var action = this.updateActionGrid(payload);
                    this.actionStore.add(action);
                    //グリッド更新後、位置を最下段にうつす
                    this.grid.scrollTo({ y: this.grid.bodyNode.scrollHeight });
                    // 本体に対応状況を反映
                    this.hldStatus.set('value', payload.hldStatus);
            })));
            // 対応履歴の更新
            this.own(topic.subscribe('app/chronology/ChronologyActionForm::update',
                lang.hitch(this, function(payload){
                    var action = this.updateActionGrid(payload);
                    this.actionStore.put(action);
                    this.actionForm.clearActionForm();
            })));
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);

            // 宛先ラベルにhintをつける
            this.setShareTips();

            // クロノロジ詳細画面を初期化
            this.initDetailPage();

            if(this._simulateFlg){
                // 災害シミュレーションモードの場合、「対応履歴」タブは閲覧不可
                this.tabContainer.removeChild(this.tab2);
            } else {
                this.initChronologyActionGrid();
                //対応履歴フォームのイベントリスナーをセット
                this.initChronologyActionForm();
            }
            // 過去の内容グリッドの初期化
            this.initChronologyHistoryGrid();
        },

        /**
         * postCreate
         */
        postCreate: function() {
            this.inherited(arguments);
        },

        updateActionGrid: function(item) {
            var action = new ChronologyAction({
                chronologyActionId: item.chronologyActionId,
                content: item.content,
                hldStatus: item.hldStatus,
                crtUserId: item.crtUserId,
                //報告日時はひとまずレコードの更新日で設定
                crtTimestamp: DateUtils.format(item.crtTimestamp),
                updTimestamp: DateUtils.format(item.updTimestamp),
                organizationName: item.organizationName,
                name: item.name,
                attachFiles: item.attachFiles
            });
            return action;
        },

        /**
         * クロノロジ詳細画面を初期化する
         */
        initDetailPage: function() {
            this.actionStore = Trackable.create(new Memory({
                idProperty: 'chronologyActionId'
            }));
            this.histStore = Trackable.create(new Memory({
                idProperty: 'chronologyHistId'
            }));
            var promise = null;
            if(this._simulateFlg){
                // 災害シミュレーションモードの場合はファイルから情報を取得
                promise = Requester.get('/data/simulation/' + this._eventId + '.json')
                    .then(lang.hitch(this, function(data) {
                        this.initValue(data.chronologyPostForm);
                }), lang.hitch(this, function(error) {
                    this.chain.info('イベント情報の取得に失敗しました。', 'エラー');
                }));
            } else {
                // 通常時はサーバにリクエストを発行
                promise = Requester.get('/api/chronologies/' + this._chronologyId, {
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                }).then(lang.hitch(this, function(data) {
                    // データに閲覧権限がない場合は空のデータが返る
                    if (data === null) {
                        this.infoDialog = new InfoDialog({
                            title : 'エラー',
                            content : 'リクエストされた情報は権限がないため閲覧できません',
                            onOK : lang.hitch(this, function() {
                                this.infoDialog.hide();
                                Router.moveTo('chronology');
                            })
                        });
                        this.infoDialog.show();
                        return false;
                    } else {
                        this.initValue(data);
                    }
                }), lang.hitch(this, function(error) {
                    this.chain.info('情報の取得に失敗しました。', 'エラー', function() {
                        Router.moveTo('chronology');
                    });
                }));
            }
            //ローダーの表示
            Loader.wait(promise);
        },

        /**
         * クロノロジ詳細画面に値を設置し、
         * 情報区分に応じて表示を切替える
         */
        initValue: function(data){
            //初期化
            this.pointLat = '';
            this.pointLng = '';
            this.marker = '';
            this.attachFileList = [];
            var chronologyShares = data.chronologyShares;
            // var municipalities = [];
            var seqNum = data.seqNum ||  '1';

            delete data.chronologyShares; //this.chronologySharesと反応するためここで取り除く

            // 値を設定する

            // 配信元情報の取得
            var sender = array.filter(chronologyShares, function(share){
                // 対象発信グループ番号が設定されていない、又は対象発信グループ番号が一致、
                // 且つ、配信元フラグが設定されている
                return (!data.targetSendingSeqNum || data.targetSendingSeqNum === share.sendingSeqNum) &&
                        (share.senderFlg === '1');
            })[0];
            if (sender) {
                // 配信元を取得できた場合に処理（外部システムは配信元がない）
                // 配信元の設定
                this._senderName = sender.shareName;
                this.senderName.innerHTML = this._senderName;
                this.senderNameReadOnly.innerHTML = this._senderName;

                // 配信元氏名の設定
                // NOTE:長崎県ではクロノロジ共有に保持
                this.senderPersonalName.set('value', sender.senderPersonalName);
                this.senderPersonalNameReadOnly.innerHTML = sender.senderPersonalName;
            }

            // 発信日時の設定
            var registerTimestamp = sender && sender.registerTimestamp ? sender.registerTimestamp :
                                data.registerTimestamp;
            this.registerTimestampSender.set('value', registerTimestamp);
            this.registerTimestampReadOnly.innerHTML = DateUtils.format(registerTimestamp);

            // 宛先の設定
            var shareCds = [];
            df.forIn(chronologyShares, function(share) {
                // 宛先フラグがON且つ、
                // 対象発信グループ番号が設定されていない、又は対象発信グループ番号が一致する項目を取得する
                if (share.destinationFlg === '1' &&
                    (!data.targetSendingSeqNum || data.targetSendingSeqNum === share.sendingSeqNum)) {
                    var shareCd = share.organizationCd && share.organizationCd !== null ?
                        share.organizationCd :
                        share.municipalityCd && share.municipalityCd !== null ?
                            share.municipalityCd : share.regionCd;
                    shareCds.push(shareCd);
                    this._targetShares.push(share);
                }
            }, this);
            this.shareMunicipalityCd._setValueAttr(shareCds);
            this.shareMunicipalityCdReadOnly._setValueAttr(shareCds);
            // 組織内情報用メッセージ
            this.shareOrganInnerLabel.set('value', shareCds);

            // 登録ラベルの設定
            this._actualFlg = (data.actualFlg && data.actualFlg === '1') ? '1' : '0';
            if(this._simulateFlg){
                // 災害シミュレーションモード
                domStyle.set(this.registerButton.domNode, 'display', 'none');
                domStyle.set(this.deleteButton.domNode, 'display', 'none');
                this.registeringStatus.innerHTML = this._actualFlg === '1' ? '本登録' : '本登録待ち';
            } else {
                // 通常モード
                if(this._actualFlg === '1'){
                    domStyle.set(this.registerButton.domNode, 'display', 'none');
                    this.registeringStatus.innerHTML = '第' + seqNum + '報';
                } else {
                    domStyle.set(this.registerButton.domNode, 'display', '');
                    this.registeringStatus.innerHTML = '仮登録';
                }
            }

            // 添付ファイルを設定
            if(data.attachFiles) {
                for(var i=0; i<data.attachFiles.length; i++) {
                    this.attachFileList.push(data.attachFiles[i]);
                    this.showPreview(data.attachFiles[i], false);
                }
            }

            // クロノロジ種別ごとの制御
            this._chronologyType = data.chronologyType;
            if(this.EXTERNAL_TYPE_LIST.indexOf(data.chronologyType) !== -1){
                // 外部システムから登録された場合
                this.initValueAsReadOnly(data);
                // 配信元の共有情報がないため、該当箇所に固定値を設定
                this._senderName = '外部システム';
                this.senderNameReadOnly.innerHTML = '外部システム';
                this.senderPersonalNameReadOnly.innerHTML = '外部システム';

            } else if (data.chronologyType === ChronologyType.DISASTER_HLD.id ||
                    data.chronologyType === ChronologyType.WHITE_BOARD.id ||
                    data.chronologyType === ChronologyType.NOTIFICATION.id) {
                // クロノロジ画面から登録された場合（組織内情報、要請、情報提供）

                // 表示項目の制御
                var category;
                this._reSendingShare = false;
                if (data.chronologyType === ChronologyType.DISASTER_HLD.id){
                    // 要請
                    category = 'DISASTER_HLD';
                    // 配信元・宛先に応じた場合分け
                    if (data.shareType.substring(0, 1) === '1') {
                        // 配信元の場合は
                        // 全ての入力エリアを表示
                        // 全ての読み取り専用エリアを非表示
                        // 再配信エリアは非表示
                        domStyle.set(this.inputBasicArea, 'display', '');
                        domStyle.set(this.readOnlyBasicArea, 'display', 'none');
                        domStyle.set(this.reSendingBasicArea, 'display', 'none');
                        domStyle.set(this.inputPublishArea, 'display', '');
                        domStyle.set(this.readOnlyPublishArea, 'display', 'none');

                        // 宛先更新可否フラグをtrue
                        this._editableShare = true;
                    } else {
                        // 配信元でない宛先
                        // 基本事項は読み取り専用エリアを表示
                        // 発信内容は入力エリアを表示
                        // 再発信エリアを表示
                        domStyle.set(this.inputBasicArea, 'display', 'none');
                        domStyle.set(this.readOnlyBasicArea, 'display', '');
                        domStyle.set(this.reSendingBasicArea, 'display', '');
                        domStyle.set(this.inputPublishArea, 'display', '');
                        domStyle.set(this.readOnlyPublishArea, 'display', 'none');

                        // 宛先更新可否フラグをfalse
                        this._editableShare = false;

                        // 再配信フラグを設定する
                        this._reSendingShare = true;

                        // 読み取り専用エリアに値を設定する
                        this.initReadOnlyValues(data);

                        // 再発信の発信元に情報を設定する
                        var userOrganizationCd = UserInfo.getLowestOrganizationCd();
                        this.reSendSenderName.set('value', [userOrganizationCd]);
                    }
                    // 以下は配信元・宛先によらずに表示設定
                    // 組織内情報用メッセージを非表示
                    domStyle.set(this.chronologyMunicInnerSharesArea, 'display', 'none');

                    // 要請フラグ
                    this._isDisasterHld = true;

                } else if (data.chronologyType === ChronologyType.WHITE_BOARD.id){
                    // 組織内情報
                    category = 'WHITE_BOARD';
                    // 全ての入力エリアを表示
                    // 全ての読み取り専用エリアを非表示
                    // 再配信エリアは非表示
                    domStyle.set(this.inputBasicArea, 'display', '');
                    domStyle.set(this.readOnlyBasicArea, 'display', 'none');
                    domStyle.set(this.reSendingBasicArea, 'display', 'none');
                    domStyle.set(this.inputPublishArea, 'display', '');
                    domStyle.set(this.readOnlyPublishArea, 'display', 'none');
                    domStyle.set(this.chronologyMunicInnerSharesArea, 'display', '');
                    // 宛先は非表示
                    domStyle.set(this.shareMunicipalityArea, 'display', 'none');
                    // 情報種別を非表示
                    domStyle.set(this.chronologyCategoryArea, 'display', 'none');
                    domStyle.set(this.chronologyCategoryAreaReadOnly, 'display', 'none');

                    // 組織内情報の場合は、宛先更新可否フラグをfalse
                    this._editableShare = false;

                } else if (data.chronologyType === ChronologyType.NOTIFICATION.id){
                    // 情報提供
                    category = 'NOTIFICATION';
                    // 配信元・宛先に応じた場合分け
                    if (data.shareType.substring(0, 1) === '1') {
                        // 配信元の場合は
                        // 全ての入力エリアを表示
                        // 全ての読み取り専用エリアを非表示
                        // 再配信エリアは非表示
                        domStyle.set(this.inputBasicArea, 'display', '');
                        domStyle.set(this.readOnlyBasicArea, 'display', 'none');
                        domStyle.set(this.reSendingBasicArea, 'display', 'none');
                        domStyle.set(this.inputPublishArea, 'display', '');
                        domStyle.set(this.readOnlyPublishArea, 'display', 'none');

                        // 情報提供で配信元の場合は宛先更新可否フラグをtrue
                        this._editableShare = true;
                    } else {
                        // 配信元でない宛先
                        // 基本事項は読み取り専用エリアを表示
                        // 発信内容は入力エリアを表示
                        // 再配信エリアは非表示
                        domStyle.set(this.inputBasicArea, 'display', 'none');
                        domStyle.set(this.readOnlyBasicArea, 'display', '');
                        domStyle.set(this.reSendingBasicArea, 'display', 'none');
                        domStyle.set(this.inputPublishArea, 'display', '');
                        domStyle.set(this.readOnlyPublishArea, 'display', 'none');

                        // 情報提供で宛先の場合は宛先更新可否フラグをfalse
                        this._editableShare = false;

                        // 読み取り専用エリアに値を設定する
                        this.initReadOnlyValues(data);
                    }
                    // 以下は配信元・宛先によらずに表示設定
                    // 組織内情報用メッセージを非表示
                    domStyle.set(this.chronologyMunicInnerSharesArea, 'display', 'none');
                    // 情報種別を非表示
                    domStyle.set(this.chronologyCategoryArea, 'display', 'none');
                    domStyle.set(this.chronologyCategoryAreaReadOnly, 'display', 'none');
                }

                // 仮登録判定
                if (this._actualFlg === '0') {
                    // 仮登録の場合は一律入力可能エリアのみ表示する
                    domStyle.set(this.inputBasicArea, 'display', '');
                    domStyle.set(this.readOnlyBasicArea, 'display', 'none');
                    domStyle.set(this.reSendingBasicArea, 'display', 'none');
                    domStyle.set(this.inputPublishArea, 'display', '');
                    domStyle.set(this.readOnlyPublishArea, 'display', 'none');
                }

                // 添付ファイルボタンを表示
                domStyle.set(this.attachFileItemArea, 'display', '');

                // 取得データの設定
                // 入力エリア・読み取り専用エリア両方に設定する

                // 情報区分の設定
                this.chronologyTypeLabel.innerHTML = ChronologyType[category].name;
                this.chronologyTypeLabelReadOnly.innerHTML = ChronologyType[category].name;

                // 取得したデータを保存
                this._chronologyShares = chronologyShares;
                this._municipalityAuthorizeFlg = data.municipalityAuthorizeFlg;
                this._organizationAuthorizeFlg = data.organizationAuthorizeFlg;
                this._sourceData = data;

            }else{
                // 他のページで登録された場合
                this.initValueAsReadOnly(data);
            }
            // 自動登録されるクロノロジは対応状況ステータスを持たないので「-:指定なし」で固定
            if (!(data.chronologyType === ChronologyType.DISASTER_HLD.id ||
                data.chronologyType === ChronologyType.WHITE_BOARD.id ||
                data.chronologyType === ChronologyType.NOTIFICATION.id)) {
                // 対応履歴の対応状況を「-:指定なし」固定とする
                this.actionForm.hldStatus.set('value','9');
                this.actionForm.hldStatus.set('disabled', true);
            }

            // 対応状況をcrtTimestampでソートしてからメモリーストアにセットする
            if(data.chronologyActions){
                data.chronologyActions.sort(function(a, b) {
                    if (a.crtTimestamp < b.crtTimestamp) {
                        return -1;
                    }
                    if (a.crtTimestamp > b.crtTimestamp) {
                        return 1;
                    }
                    return 0;
                }).forEach(function(object) {
                    // 更新の場合は削除されているものも送る
                    this.actionStore.add(object);
                }, this);
            }

            // 内容履歴をregisterTimestampでソートしてからメモリーストアにセットする
            if(data.chronologyHists){
                data.chronologyHists.sort(function(a, b) {
                    if (a.registerTimestamp < b.registerTimestamp) {
                        return -1;
                    }
                    if (a.registerTimestamp > b.registerTimestamp) {
                        return 1;
                    }
                    return 0;
                }).forEach(function(object) {
                    // 更新の場合は削除されているものも送る
                    this.histStore.add(object);
                }, this);
            }

            // 地図を初期化
            // クロノロジの緯度経度情報 > ユーザの所属市町座標 > 県の座標、の順に評価する
            if(data.sourceLatitude && data.sourceLongitude) {

                this.initMap(data.sourceLatitude, data.sourceLongitude);

                this.pointLat = data.sourceLatitude;
                this.pointLng = data.sourceLongitude;
                this._sourceAddressLat = data.sourceLatitude;
                this._sourceAddressLng = data.sourceLongitude;
                this.addMark(data.sourceLatitude, data.sourceLongitude);
                this.map.setView([data.sourceLatitude, data.sourceLongitude], 14);
            } else {
                if(UserInfo.getMunicipalityCd()) {
                    Requester.get('/api/municipalities/' + UserInfo.getMunicipalityCd(), {
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                    }).then(lang.hitch(this, function(item) {
                        var zoom = 11;
                        if (UserInfo.getMunicipalityCd() === config.municInfo.prefMunicCd){
                            // 長崎県全体を表示する場合、ズーム値は7
                            zoom = 7;
                        }
                        // 地図を初期化
                        this.initMap(item.latitude, item.longitude, zoom);
                    }), lang.hitch(this, function(error) {
                        this.initMap(config.map.latitude, config.map.longitude);
                        this.chain.info('情報の取得に失敗しました。', 'エラー');
                    }));
                } else if(UserInfo.getRegionCd()) {
                    Requester.get('/api/regions/' + UserInfo.getRegionCd(), {
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                    }).then(lang.hitch(this, function(item) {
                        // 地図を初期化
                        // 振興局を表示する場合、ズーム値は9
                        this.initMap(item.latitude, item.longitude, 9);
                    }), lang.hitch(this, function(error) {
                        this.initMap(config.map.latitude, config.map.longitude);
                        this.chain.info('情報の取得に失敗しました。', 'エラー');
                    }));
                } else {
                    // 地図を初期化
                    this.initMap(config.map.latitude, config.map.longitude);
                }
            }
            this._disasterId = data.disasterId;
            this._chronologyType = data.chronologyType;

            // hldOrganizationをform.setの対象に含めるとエラーが出るため、このタイミングで取り除く
            delete data.chronologyShares;

            // その他の値を設定
            this.form.set('value', data);

            //外部連携時は情報種別を非表示にする
            if (!this.chronologyCategoryArea.style.display){
                this.hideChronologyCategoryArea(data);
            }
        },

        /**
         * 対応状況グリッドを初期化する。
         * startup時、続報セレクタ選択時、対応状況の登録更新削除時
         */
        initChronologyActionGrid: function() {
            // 削除フラグがたっているものはグリッドに表示しない
            var filter = new this.actionStore.Filter();
            var delFlgFilter = filter.ne('delFlg', '1');
            this.grid.set('collection', this.actionStore.filter(delFlgFilter));

            this.grid.on('updateActionButtonClick', lang.hitch(this, function(evt) {
                //[編集中]メッセージの付け替え
                if(this.actionForm.selectedAction && this.actionForm.selectedAction.editingMsg){
                    domStyle.set(this.actionForm.selectedAction.editingMsg, 'display', 'none');
                }
                this.actionForm.selectedAction = evt.selectedAction;
                domStyle.set(this.actionForm.selectedAction.editingMsg, 'display', '');
                this.actionForm.showUpdateActionForm(evt.item);
            }));
        },

        /**
         * 過去の内容グリッドを初期化する。
         * startup時
         */
        initChronologyHistoryGrid: function() {
            // 削除フラグがたっているものはグリッドに表示しない
            var filter = new this.histStore.Filter();
            var delFlgFilter = filter.ne('delFlg', '1');
            this.historyGrid.set('collection', this.histStore.filter(delFlgFilter));
        },

        /**
         * 対応履歴編集フォームを初期化する
         */
        initChronologyActionForm: function() {
            // 編集フォームのregisterイベントを受け取る
            var self = this;
            this.actionForm.on('register', lang.hitch(this, function(evt) {
                // idが空文字の場合はフォームから削除する
                if (evt.value.id === '') {
                    delete evt.value.id;
                }
                // chronologyActionIdが空文字の場合はフォームから削除する
                if (evt.value.chronologyActionId === '') {
                    delete evt.value.chronologyActionId;
                }
                evt.value.updTimestamp = new Date().getTime();

                // ストアにオブジェクトを追加
                this.actionStore.add(evt.value).then(function() {
                    self.actionForm.clearActionForm();
                });
                this.initChronologyActionGrid();
            }));

            // 編集フォームのupdateイベントを受け取る
            this.actionForm.on('update', lang.hitch(this, function(evt) {
                // chronologyActionIdが空文字の場合はフォームから削除する
                if (evt.value.chronologyActionId === '') {
                    delete evt.value.chronologyActionId;
                }
                // ストアのオブジェクトを更新
                this.actionStore.put(evt.value).then(function() {
                    self.actionForm.clearActionForm();
                });
                evt.value.updTimestamp = new Date().getTime();

                this.initChronologyActionGrid();
            }));

            // 編集フォームのdeleteイベントを受け取る
            this.actionForm.on('delete', lang.hitch(this, function(evt) {
                // chronologyActionIdが空文字ではないとき（サーバーに登録されている対応状況の削除）
                if (evt.value.chronologyActionId !== '') {
                    // 削除フラグをたてる
                    evt.value.delFlg = '1';
                    evt.value.updTimestamp = new Date().getTime();

                    // ストアのオブジェクトを更新
                    this.actionStore.put(evt.value).then(function() {
                        self.actionForm.clearActionForm();
                    });
                    var y1 = this.grid.bodyNode.scrollTop;
                    this.initChronologyActionGrid();
                    this.grid.scrollTo({ y: y1 });
                // chronologyActionIdが空文字なとき（まだサーバーに登録していない対応状況の削除）
                } else {
                    // ストアのオブジェクトを削除
                    this.actionStore.remove(evt.value.id).then(function() {
                        self.actionForm.clearActionForm();
                    });
                    var y2 = this.grid.bodyNode.scrollTop;
                    this.initChronologyActionGrid();
                    this.grid.scrollTo({ y: y2 });
                }
            }));

        },

        /**
         * 読み取り専用エリアに値を設定する
         */
        initReadOnlyValues: function(data) {
            this.chronologyIdReadOnly.innerHTML = data.chronologyId;
            // 発信日時は共通でセット
            // 発信元は共通でセット
            var category = ChronologyCategory.filter(function(item) {
                return item.id === data.chronologyCategory;
            })[0];
            this.chronologyCategoryReadOnly.innerHTML = category ? category.value : '-';
        },

        /**
         * 外部システム及び他機能からのクロノロジの場合は
         * 読み取り専用の項目のみを表示する。
         */
        initValueAsReadOnly: function(data) {
            // 入力エリアを非表示
            domStyle.set(this.inputBasicArea, 'display', 'none');
            domStyle.set(this.inputPublishArea, 'display', 'none');
            // 読み取り専用エリアを表示
            domStyle.set(this.readOnlyBasicArea, 'display', '');
            domStyle.set(this.readOnlyPublishArea, 'display', '');
            // 再発信エリアを非表示
            domStyle.set(this.reSendingBasicArea, 'display', 'none');
            // ボタンを非表示
            domStyle.set(this.buttonArea, 'display', 'none');
            // 添付ファイルボタンを非表示
            domStyle.set(this.attachFileItemArea, 'display', '');
            domStyle.set(this.attachFile.domNode, 'display', 'none');
            // 過去の内容タブを非表示
            this.tabContainer.removeChild(this.tab3);

            // 不要な個別項目を非表示
            // 情報種別
            domStyle.set(this.chronologyCategoryAreaReadOnly, 'display', 'none');

            // 外部機能独自の値を設定する
            // 情報区分
            Object.keys(ChronologyType).forEach(lang.hitch(this, function(category){
                if(data.chronologyType === ChronologyType[category].id){
                    this.chronologyTypeLabelReadOnly.innerHTML = ChronologyType[category].name;
                }
            }));

            // URL
            var link = '';
            array.forEach(ChronologyLinks, function(type){
                if (type.id === data.chronologyType){
                    array.forEach(type.name, function(name, i){
                        var url = (type.url[i] === 'sourceUrl') ? data.sourceUrl : type.url[i];
                        link += '<a href="' + url + '" target="_blank">' + name + '</a>　';
                    });
                }
            });
            if (link !== '') {
                // URLが設定されていれば表示する
                this.fixedUrl.innerHTML = link || '&nbsp';
                domStyle.set(this.urlArea, 'display', 'block');
            }

            // 内容
            this.contentReadOnly.set('value', data.content);

            // 緊急度
            // 緊急度マップ
            var urgencyMap = {
                '0' : '-',
                '1' : '低',
                '2' : '中',
                '3' : '高'
            };
            this.urgencyTypeReadOnly.innerHTML = urgencyMap[data.urgencyType];

            // 住所
            this.sourceAddressReadOnly.innerHTML =
                data.sourceAddress01 && data.sourceAddress01 !== null && data.sourceAddress01 !== '' ?
                data.sourceAddress01 : '-';

            // 対応状況
            var hldStatusMap = {
                '9' : '-',
                '0' : '確認中',
                '1' : '対応待ち',
                '2' : '対応中',
                '3' : '対応完了'
            };
            this.hldStatusReadOnly.innerHTML = hldStatusMap[data.hldStatus];

            // 備考
            this.noteReadOnly.set('value', data.note);

            // 読み取り専用エリアの項目に値を設定する
            this.initReadOnlyValues(data);
        },

        showTab1: function(){
            // 地図が見切れてしまうので、リサイズする
            this.tab1.resize();
            setTimeout(lang.hitch(this, function(){this.map.invalidateSize();}), 200);
        },

        /**
         * クロノロジ本登録を実行する
         */
        registerActualChronology: function(){ // 本登録
            var sendData = this.form.get('value');
            sendData.actualFlg = '1';
            this.update(sendData, true);
        },

        /**
         * クロノロジを更新する。
         */
        updateChronology: function() {
            var sendData = this.form.get('value');
            sendData.actualFlg = this._actualFlg;
            this.update(sendData, false);
        },

        /**
         * クロノロジ更新処理
         * @param {*} sendData
         * @param {*} isActualRegistering
         */
        update: function(sendData, isActualRegistering){
            // var chronologyShares = this._chronologyShares;

            var infoDialogMsg = null;
            // 要請・情報提供の場合、宛先の入力チェック
            if (this._chronologyType === ChronologyType.DISASTER_HLD.id ||
                this._chronologyType === ChronologyType.NOTIFICATION.id) {
                if (!sendData.shareMunicipalityCd || sendData.shareMunicipalityCd.length < 1) {
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title : 'エラー',
                            content : '宛先が選択されていません'
                        });
                    }
                    this.infoDialog.show();
                    this.infoDialog=null;
                    return false;
                }
            }

            // 内容のサイズチェック
            // 内容のみリンク追加での制御があるため登録時にもチェックする
            var propLength = this.content.params.maxLength;
            if (this.content.get('value').length > propLength) {
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '内容は' + propLength + '字以内で入力してください。'
                    });
                }
                this.infoDialog.show();
                this.infoDialog=null;
                return false;
            }

            // 要請の宛先の場合、再配信先が入力されている場合のみ入力チェック
            var reSendingFlg = false;
            if (this._reSendingShare) {
                // 発信日時、氏名、宛先のいずれかが入力されている場合に、
                // 必須項目の発信日時、宛先が入力されていない場合にエラー
                if (sendData.reSendRegisterTimestamp !== null || sendData.reSendSenderPersonalName.length > 0 ||
                    sendData.reSendShareMunicipalityCd.length > 0) {
                    if (sendData.reSendRegisterTimestamp === null || !this.reSendRegisterTimestamp.validate() ||
                        !this.reSendRegisterTimestamp._date.displayedValue ||
                        !this.reSendRegisterTimestamp._time.displayedValue ||
                        !sendData.reSendShareMunicipalityCd || sendData.reSendShareMunicipalityCd.length < 1) {
                        if (!this.infoDialog) {
                            this.infoDialog = new InfoDialog({
                                title : 'エラー',
                                content : '再発信項目が入力されていません'
                            });
                        }
                        this.infoDialog.show();
                        this.infoDialog=null;
                        return false;
                    }
                    // チェック通過時は再発信フラグをONにする
                    reSendingFlg = true;
                }
            }

            // フォームのバリデーションを行う（共通部品）
            if(!this.form.validate()) {
                return false;
            }

            // 共有情報が定義されていたら削除
            delete sendData.chronologyShares;

            // 続報番号の設定
            if (isActualRegistering) {
                // 仮登録から本登録の実施時は初期値
                sendData.seqNum = 1;
            } else if (this._actualFlg === '1') {
                // 本登録の更新時はカウントアップ
                sendData.seqNum = this._sourceData.seqNum + 1;
            }

            // 取得データから引き継ぐ値を設定
            // 災害ID
            sendData.disasterId = this._disasterId;
            // クロノロジID
            sendData.chronologyId = this._chronologyId;
            // クロノロジ情報区分
            sendData.chronologyType = this._chronologyType;
            // 住所2は未使用
            sendData.sourceAddress02 = '';
            // 住所が設定されている場合に経度緯度情報を設定
            if(sendData.sourceAddress01) {
                sendData.sourceLatitude = this._sourceAddressLat;
                sendData.sourceLongitude = this._sourceAddressLng;
            } else {
                sendData.sourceLatitude = '';
                sendData.sourceLongitude = '';
            }

            // 添付ファイルIDをセット
            if(this.attachFileList.length > 0) {
                var attachFileIds = [];
                for(var i=0; i<this.attachFileList.length; i++) {
                    attachFileIds.push(this.attachFileList[i].chronologyAtcFileId);
                }
                sendData.attachFileIds = attachFileIds.join(',');
            } else {
                sendData.attachFileIds = '';
            }

            // NOTE:長崎県案件では使用しないが互換性のために設定
            sendData.allowPublishingFlg = (sendData.allowPublishing[0] === 'on') ? '1' : '0';

            // 不要項目を削除
            delete sendData.shareMunicipalityCd;
            delete sendData.allowPublishing;
            // delete sendData.deptCd;
            delete sendData.attachFile;
            delete sendData.organizationName;
            this._registerTimestamp = sendData.registerTimestampSender;
            delete sendData.registerTimestampSender;
            // 再配信用の項目
            this._senderPersonalNameReSending = sendData.reSendSenderPersonalName;
            delete sendData.reSendSenderPersonalName;
            delete sendData.reSendShareMunicipalityCd;
            this._reSendRegisterTimestamp = sendData.reSendRegisterTimestamp;
            delete sendData.reSendRegisterTimestamp;

            // NOTE: 長崎県では送信者氏名と発信日時をクロノロジ共有に持つ（画面の値を再設定）
            sendData.registerTimestamp = this._registerTimestamp;
            df.forIn(this._chronologyShares, function(targetShare){
                // 配信元フラグが立っている共有先に送信者氏名、発信日時を設定
                if (targetShare.senderFlg === '1') {
                    targetShare.senderPersonalName = sendData.senderPersonalName;
                    targetShare.registerTimestamp = this._registerTimestamp;
                }
            }, this);

            // 宛先設定有無に応じて処理を変更
            if(this._editableShare || (this._reSendingShare && reSendingFlg)){
                // 宛先変更可否フラグがtrue、または再発信フラグがtrueかつ再発信宛先が設定

                // 定義から取得する
                var typeSetting = ChronologyUtil.getSettingFromCode(sendData.chronologyType);
                sendData.municipalityAuthorizeFlg = typeSetting.municipalityAuthorizeFlg;
                sendData.organizationAuthorizeFlg = typeSetting.organizationAuthorizeFlg;

                // NOTE:長崎県では、宛先の多段階管理を行う。
                // 取得する宛先マップが2種類存在するため、配信元の場合と宛先の場合で
                // 取得するマップとツリーを変更する。
                var chronologyShareOrgs = []; // 今回修正対象宛先格納リスト
                var shareSelectorCd = reSendingFlg ? // 取得する宛先情報を分岐する
                        this.reSendShareMunicipalityCd : this.shareMunicipalityCd;
                var shareMap = shareSelectorCd.tree._checkMap;
                var excludeMap = {};
                var tree = shareSelectorCd.tree;

                var isMunicipality = false;
                var isDummy = false; // 自治体指定か否か
                var isCrossOrg = false; // 組織またぎか否か
                var isToMunicOrg = false; // 市町の防災担当組織宛てか否か
                // セレクタから値を取得する
                // チェックされたすべての値を取得してしまうため、
                // 子が全てチェックされたものは親のみを取得するため、
                // 除外リストに登録する。
                df.forIn(shareMap, function(item) {
                    if(tree.isEveryChildChecked(item)){
                        // 配信元が県・振興局ユーザの場合、宛先が市町自治体か否か
                        // （上記の宛先が市町の場合、最下層の防災担当組織1件を宛先組織とするため、ツリーの上位組織ではなく最下層組織を取得）
                        if(UserInfo.getUserType() === USER_TYPE.PREFECTURE || UserInfo.getUserType() === USER_TYPE.REGION){
                            isToMunicOrg = /^42[234]*/.test(item.municipalityCd);
                        }
                        // 子が全てチェックされている項目

                        // NOTE: 長崎県では市町と組織を同じセレクタで管理する
                        // idが4桁未満のものはアイテムをまとめるダミーなので除外し、
                        // 4桁以上のもののみ処理を行う
                        // 振興局・自治体コードも除外（振興局・自治体もそれぞれの組織コードで扱う）
                        // if (tree.model.store.getIdentity(item).length >= 4) {
                        if (tree.model.store.getIdentity(item).length >= 4 &&
                            !/^42[0-9]{4}/.test(tree.model.store.getIdentity(item))) {
                            tree.getItemChildren(item).then(lang.hitch(this, function(items) {
                                if(!items){
                                    return false;
                                }
                                if(isToMunicOrg){
                                    if(items.length !== 0){
                                        // 県・振興局ユーザが市町の防災担当組織宛てとする場合は、子を登録する
                                        // 子を登録して親を登録対象外とする場合
                                        // 対象に子が存在する場合、親は登録対象外とする
                                        excludeMap[tree.model.store.getIdentity(item)] = true;
                                        return false;
                                    }
                                } else {
                                    // 子を全て除外リストに登録する
                                    // 親を登録して子を登録対象外とする場合
                                    // 対象に紐づく子はすべて登録対象外とする
                                    array.forEach(items, function(item){
                                        excludeMap[tree.model.store.getIdentity(item)] = true;
                                    }, this);
                                }
                            }));
                        } else {
                            // ダミー項目も除外リストに登録する
                            excludeMap[tree.model.store.getIdentity(item)] = true;
                        }
                    }
                }, this);
                // 再度セレクタの値に対してチェックし、
                // 除外リストに登録されていない項目を配列に格納していく
                df.forIn(shareMap, function(item) {
                    var cd = tree.model.store.getIdentity(item);
                    if(!excludeMap[cd]){
                        chronologyShareOrgs.push(item);
                    }
                    if (cd.substring(0, 1) !== 'D') {
                        // 市町村が設定されている
                        isMunicipality = true;
                    }
                    if (cd.length < 4){
                        isDummy = true;
                    }
                    // 組織またぎか否か
                    if(UserInfo.getUserType() === USER_TYPE.PREFECTURE || UserInfo.getUserType() === USER_TYPE.REGION){
                        // 配信元が県・振興局ユーザの場合、宛先が市町自治体か否か
                        isCrossOrg = /^42[234]*/.test(item.id);
                    } else if (UserInfo.getUserType() === USER_TYPE.MUNICIPALITY){
                        // 配信元が市町ユーザの場合、宛先に「長崎県」の有無
                        isCrossOrg = (item.id === config.municInfo.prefMunicCd);
                    } else if( UserInfo.getUserType() === USER_TYPE.OTHER_ORGAN){
                        // 配信元がその他ユーザの場合、情報共有・要請の宛先は県のみとなるため、組織またぎ確定
                        isCrossOrg = true;
                    }
                }, this);

                if (isDummy && sendData.chronologyType === ChronologyType.DISASTER_HLD.id && !isCrossOrg) {
                    // 要請時、宛先に自治体が設定されていて、組織またぎでない場合はエラー
                    infoDialogMsg = '宛先には要請を行う個別の自治体または組織を選択してください。';
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : infoDialogMsg
                    });
                    this.infoDialog.show();
                    return false;
                }
                // NOTE: 長崎県では要請の場合宛先の多段階管理を行うため、
                // 宛先の場合は、今回登録した宛先が他の発信グループ番号の宛先に登録されていないかをチェックする
                if (sendData.chronologyType === ChronologyType.DISASTER_HLD.id) {
                    var conflictShare = false;
                    var conflictShareName = null;
                    df.forIn(chronologyShareOrgs, function(share){
                        df.forIn(this._chronologyShares, function(postShare){
                            // 今回更新した宛先と取得データの宛先(DEPT)が一致し、
                            // 且つ、
                            // 今回が再発信の場合、又は、対象発信グループ番号と取得データの宛先の発信グループ番号が異なる場合
                            // 宛先は登録済としてエラーとする。
                            if ((share.id === postShare.deptCd) &&
                                (this._reSendingShare ||
                                    this._sourceData.targetSendingSeqNum !== postShare.sendingSeqNum)) {
                                conflictShare = true;
                                conflictShareName = postShare.shareName;
                            }
                        }, this);
                    }, this);
                    if (conflictShare) {
                        // 宛先が競合した場合はエラー
                        infoDialogMsg = '登録した宛先は既に共有先として登録されているため登録できません。[' +
                            conflictShareName + ']';
                        this.infoDialog = new InfoDialog({
                            title : 'エラー',
                            content : infoDialogMsg
                        });
                        this.infoDialog.show();
                        return false;
                    }
                }

                // NOTE: 長崎県では要請の宛先の多段階管理を行うため
                // 削除された宛先が既に他の発信グループ番号で配信元となっている場合はエラーとする
                // 再発信の場合は削除は起こりえないのでチェック対象外
                if (sendData.chronologyType === ChronologyType.DISASTER_HLD.id &&
                    !this._reSendingShare) {
                    // 要請、且つ、配信元の場合
                    var deletedShares = [];
                    df.forIn(this._targetShares, function(share){
                        // 変更前の同じ発信グループ番号の宛先を総ざらいし、DEPTを比較し
                        // 今回の宛先に含まれていないものを取得する
                        var found = false;
                        df.forIn(chronologyShareOrgs, function(targetShare){
                            if (share.deptCd === targetShare.deptCd && targetShare.destinationFlg === '1') {
                                found = true;
                            }
                        }, this);
                        if (!found) {
                            deletedShares.push(share);
                        }
                    }, this);
                    var deleteSender = false;
                    var deletedShareName = null;
                    df.forIn(deletedShares, function(deletedShare){
                        df.forIn(this._chronologyShares, function(targetShare){
                            // 削除された宛先が全体の共有先に含まれているかを確認し、
                            // 配信元として含まれている場合はエラーとする
                            if (deletedShare.deptCd === targetShare.deptCd &&
                                targetShare.senderFlg === '1') {
                                deleteSender = true;
                                deletedShareName = targetShare.shareName;
                            }
                        }, this);
                    }, this);
                    if (deleteSender) {
                        // 再配信済の宛先を削除した場合はエラー
                        infoDialogMsg = '削除した宛先は既に他の自治体宛に再発信を実施しているため削除できません。[' +
                            deletedShareName + ']';
                        this.infoDialog = new InfoDialog({
                            title : 'エラー',
                            content : infoDialogMsg
                        });
                        this.infoDialog.show();
                        return false;
                    }
                }

                // 宛先ごとに共有情報オブジェクトを設定する
                all(
                    array.map(chronologyShareOrgs, function(org){
                        // 各アイテムの自治体要素を取得する
                        return this.getRegionMunicipalityInfo(shareSelectorCd.getRegionMunicipalityInfo(org).id);
                    }, this)
                ).then(lang.hitch(this, function(municInfos){

                    sendData = this.setChronolgyShares(sendData, typeSetting, municInfos, chronologyShareOrgs);

                    this.sendRequest(sendData, isActualRegistering);
                }), lang.hitch(this, function(error){
                    this.chain.infoError(error);
                }));

            }else{
                // 共有先を更新対象外の場合
                // 取得データの共有先をそのまま設定して更新
                sendData.chronologyShares = this._chronologyShares;
                sendData.municipalityAuthorizeFlg = this._municipalityAuthorizeFlg;
                sendData.organizationAuthorizeFlg = this._organizationAuthorizeFlg;
                this.sendRequest(sendData, isActualRegistering);
            }
        },

        /**
         * リクエストの送信処理を行う
         * @param {*} sendData
         * @param {*} isActualRegistering
         */
        sendRequest: function(sendData, isActualRegistering){
            var jsonStr = null;
            var message = '';

            //更新確認ダイアログを表示
            if(this._simulateFlg){
                delete sendData.version;
                var subSendData = {};
                subSendData.chronologyTypeName = this.chronologyTypeLabel.innerHTML;
                subSendData.hldStatusName = this.hldStatus.domNode.innerText;
                subSendData.chronologyCategoryName = this.chronologyCategory.domNode.innerText;
                subSendData.urgencyTypeName = this.urgencyType.domNode.innerText;
                var data = {chronologyPostForm: sendData,
                            chronologyConversionForm: subSendData,
                            scenarioId: this._scenarioId,
                            eventId: this._eventId};
                jsonStr = json.stringify(data);
                message = 'イベントを訂正します。<br>よろしいですか。';
                this.chain.confirm(message, function(chain) {
                var promise = Requester.put('/api/simulations/event/'+ this._eventId, {
                    data: jsonStr,
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(function() {
                    chain.infoComplete(function() {
                        Router.moveTo('simulation/event', {scenarioId: this._scenarioId});
                    });
                }, function(error) {
                    chain.infoError(error);
                });
                //ローダーの表示
                Loader.wait(promise);
                });
            } else {
                jsonStr = json.stringify(sendData);
                if(isActualRegistering){
                    message = '仮登録から、本登録に切り替えます。';
                } else if (this._actualFlg === '0'){
                    message = '仮登録のまま訂正します。本登録は行いません。';
                } else {
                    message = '続報登録します。';
                }
                message += 'よろしいですか。';
                this.chain.confirm(message, function(chain) {
                    var promise = Requester.put('/api/chronologies/' + this._chronologyId, {
                        data: jsonStr,
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                    }).then(function() {
                        chain.infoComplete(function() {
                            // 一覧画面に移動
                            Router.moveTo('chronology');
                        });
                    }, function(error) {
                        chain.infoError(error);
                    });
                    //ローダーの表示
                    Loader.wait(promise);
                });
            }
        },

        /**
         * クロノロジを削除する。
         */
        deleteChronology: function() {
            var version = this.form.get('value').version;
            //削除確認ダイアログの表示
            this.chain.confirmDel(function(chain) {
                var promise = Requester.del('/api/chronologies/' + this._chronologyId,{
                    query:{
                        version: version
                    }
                })
                .then(function() {
                    chain.infoComplete(function() {
                        // 一覧画面に移動
                        Router.moveTo('chronology');
                        // location.reload();
                    });
                }, function(error) {
                    chain.infoError(error);
                });
                //ローダーの表示
                Loader.wait(promise);
            });
        },

        /**
         * クロノロジ共有を設定する。
         * @param {*} sendData
         * @param {*} typeSetting
         * @param {*} municInfos
         * @param {*} chronologyShareOrgs
         */
        setChronolgyShares: function(sendData, typeSetting, municInfos, chronologyShareOrgs){

            // 共有先情報を設定する。
            var chronologyShares = [];

            // 情報区分、配信元・宛先に応じて処理を変更する
            var sendingSeqNum = 1;
            if (sendData.chronologyType === ChronologyType.NOTIFICATION.id) {
                // 情報提供の場合、更新後の宛先で全て置き換える。
                // 発信元のみ固定のため、変更前の情報を取得する。
                chronologyShares = array.filter(this._chronologyShares, function(share){
                    return share.senderFlg === '1';
                });
            } else if (this._reSendingShare) {
                // 要請の場合で、且つ宛先フラグの場合、変更前の共有先に追加となるため、
                // 変更前の共有先を全て更新後の共有先にも設定する。
                // 同時に発信グループ番号の最大値を取得する
                var postSendingSeqNum = 1;
                df.forIn(this._chronologyShares, function(share){
                    // 全てそのまま格納する
                    chronologyShares.push(share);
                    if (share.sendingSeqNum > postSendingSeqNum) {
                        postSendingSeqNum = share.sendingSeqNum;
                    }
                }, this);
                // 発信グループ番号をカウントアップする
                sendingSeqNum = postSendingSeqNum + 1;

                // その上で、自身を配信元として登録する
                var sender = {};
                sender.senderFlg = '1';
                sender.senderPersonalName = this._senderPersonalNameReSending;
                sender.destinationFlg = '0';
                sender.registerTimestamp = this._reSendRegisterTimestamp;
                // 組織情報を取得する
                var senderMuniOrgObj;
                df.forIn(this.reSendSenderName.tree._checkMap, function(item) {
                    // 必ず一つのみが設定されている
                    if(!senderMuniOrgObj && !/^35000[2-9]/.test(item.id)){ // 未設定かつ振興局コード以外の場合に先頭のdeptCdを設定
                        senderMuniOrgObj = item;
                    }
                }, this);
                var shareOrg =
                    this.setOrganizationFromTreeObject(senderMuniOrgObj);

                // 自治体単位処理フラグが立っていない場合、組織を設定する
                if(!typeSetting.isMunicipalityUnit){
                    sender.deptCd = shareOrg.deptCd;
                    sender.sectCd = shareOrg.sectCd;
                    sender.unitCd = shareOrg.unitCd;
                    sender.organizationCd = shareOrg.unitCd ? shareOrg.unitCd :
                        shareOrg.sectCd ? shareOrg.sectCd : shareOrg.deptCd;
                }
                var infos = this.reSendShareMunicipalityCd.getRegionMunicipalityInfo(senderMuniOrgObj);
                shareOrg.name = shareOrg.name ? infos.name + shareOrg.name : infos.name;
                // 市町村コード(振興局はnull)
                sender.municipalityCd = infos.id.substring(0,3) === config.municInfo.prefRegCdGudge &&
                    infos.id !== config.municInfo.prefMunicCd ? null : infos.id;
                // 振興局コード(振興局以外はnull)
                sender.regionCd = infos.id.substring(0,3) === config.municInfo.prefRegCdGudge &&
                    infos.id !== config.municInfo.prefMunicCd ? infos.id : null;
                // 自治体名 + 組織名
                sender.shareName = typeSetting.isShareNameOrganization ?
                    shareOrg.name : infos.name;
                // 発信グループ番号
                sender.sendingSeqNum = sendingSeqNum;
                // 発信元を格納する
                chronologyShares.push(sender);
            } else {
                // 要請の場合で、且つ配信元フラグの場合、
                // 変更前の共有先から処理対象発信グループ番号の共有先を除外し、
                // 変更分を追加する
                df.forIn(this._chronologyShares, function(share){
                    // 発信グループ番号が一致しないもののみ格納
                    if (this._sourceData.targetSendingSeqNum !== share.sendingSeqNum) {
                        chronologyShares.push(share);
                    } else if (share.senderFlg === '1') {
                        // または、発信グループ番号が一致し、センダーフラグが立っているものを格納
                        chronologyShares.push(share);
                    }
                }, this);
                // 発信グループ番号は処理対象グループ番号を引き継ぐ
                sendingSeqNum = this._sourceData.targetSendingSeqNum;
            }

            // 宛先
            // 宛先組織の数だけループする
            array.forEach(municInfos, function(infos, index){
                // 仮想ルートと全体を宛先から除く
                if(infos && infos.id !== '$ROOT$' && infos.id !== '0'){
                    var dest = {};
                    dest.senderFlg = '0';
                    dest.destinationFlg = '1';
                    var destOrg = this.setOrganizationFromTreeObject(chronologyShareOrgs[index]);
                    dest.deptCd = destOrg.deptCd;
                    dest.sectCd = destOrg.sectCd;
                    dest.unitCd = destOrg.unitCd;
                    dest.organizationCd = destOrg.unitCd ? destOrg.unitCd :
                                            destOrg.sectCd ? destOrg.sectCd : destOrg.deptCd;
                    destOrg.name = destOrg.deptCd && destOrg.deptCd !== null ?
                                            infos.name + destOrg.name : destOrg.name;
                    // 市町・県の場合のみ設定
                    dest.municipalityCd = infos.id.substring(0,3) === config.municInfo.prefRegCdGudge &&
                        infos.id !== config.municInfo.prefMunicCd ? null : infos.id;

                    // 市町村もnullを設定
                    dest.regionCd = infos.id.substring(0,3) === config.municInfo.prefRegCdGudge &&
                        infos.id !== config.municInfo.prefMunicCd ? infos.id : null;
                    // 自治体名 + 組織名
                    dest.shareName = typeSetting.isShareNameOrganization ?
                        destOrg.name : infos.name;

                    // 発信グループ番号
                    dest.sendingSeqNum = sendingSeqNum;
                    chronologyShares.push(dest);
                }
            }, this);

            // 宛先組織がない場合（組織内情報含む）
            // NOTE: 長崎県では自治体と組織を同一セレクタで制御するため起こりえない
            // if(!chronologyShareOrgs || chronologyShareOrgs.length === 0){
            //     // 組織情報は抜きで、市町村の情報のみをセットする
            //     var dest = {};
            //     dest.senderFlg = '0';
            //     dest.destinationFlg = '1';
            //     dest.municipalityCd = sendData.shareMunicipality;
            //     dest.regionCd = municInfos.regionCd ?
            //                     municInfos.regionCd : null ;
            //     dest.shareName = municInfos.name;
            //     dest.deptCd = null;
            //     dest.sectCd = null;
            //     dest.unitCd = null;
            //     dest.organizationCd = null;
            //     chronologyShares.push(dest);
            // }

            // 変更前と同一の共有先はIDの引継ぎを行う
            chronologyShares = this.addChronologyShareId(chronologyShares);

            //  共有先情報をセットする
            sendData.chronologyShares = chronologyShares;
            return sendData;
        },

        /**
         * 再作成したクロノロジ共有の中に変更前と同じ共有先があればIDを引き継ぐ。
         * @param {*} chronologyShares
         */
        addChronologyShareId: function(chronologyShares){
            array.map(chronologyShares, function(share){
                array.forEach(this._chronologyShares, function(protoShare){
                    // 組織コード、自治体コード、振興局コード、配信元フラグ、
                    // 宛先フラグ、発信グループ番号が一致すれば同一IDとする
                    if(share.organizationCd === protoShare.organizationCd &&
                        share.municipalityCd === protoShare.municipalityCd &&
                        share.regionCd === protoShare.regionCd &&
                        share.senderFlg === protoShare.senderFlg &&
                        share.destinationFlg === protoShare.destinationFlg &&
                        share.sendingSeqNum === protoShare.sendingSeqNum){
                        // 各種条件が一致した場合に、元のshare情報にあるshareIdを付与する
                        share.chronologyShareId = protoShare.chronologyShareId;
                    }
                }, this);
                return share;
            }, this);
            return chronologyShares;
        },

        /**
         * 外部連携時は情報種別欄を非表示にする。
         */
        hideChronologyCategoryArea: function(data){
            if (data.chronologyType === ChronologyType.WEATHER_WARNING.id ||
                data.chronologyType === ChronologyType.CIVIL_PROTECTION.id ||
                data.chronologyType === ChronologyType.OBSERVATORY.id ){
                domStyle.set(this.chronologyCategoryArea, 'display', 'none');
            }
        },

        /**
         * クロノロジ一覧画面へ遷移する。
         */
        onChronologyLinkClick:function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('chronology');
        },

        /**
         * 自治体・組織コードが設定された場合、入力チェックを行い、
         * 地図を選択した自治体の位置に変更する
         * NOTE: 要請の宛先を多段階管理するための長崎県独自要件
         */
        onMunicipalityChangeReSending: function(){

            // セレクタからフルチェックされた自治体コードを取得する
            var shareMap = this.reSendShareMunicipalityCd.tree._checkMap;
            var tree = this.reSendShareMunicipalityCd.tree;
            var isMunicipality = false;
            var isDummy = false; // 自治体指定か否か
            var isCrossOrg = false; // 組織またぎか否か

            // チェックされている項目に部以外のものがないかを判定する
            df.forIn(shareMap, function(item) {
                var cd = tree.model.store.getIdentity(item);
                // NOTE: 長崎県では市町と組織を同じセレクタで管理する
                // idが4桁未満のものをアイテムをまとめるダミーとして判定
                if (cd.substring(0,1) !== 'D') {
                    // 市町村または振興局または県
                    isMunicipality = true;
                }
                if (cd.length < 4){
                    isDummy = true;
                }
                // 組織またぎか否か
                if(UserInfo.getUserType() === USER_TYPE.PREFECTURE || UserInfo.getUserType() === USER_TYPE.REGION){
                    // 配信元が県・振興局ユーザの場合、宛先が市町自治体か否か
                    isCrossOrg = /^42[234]*/.test(item.id);
                } else if (UserInfo.getUserType() === USER_TYPE.MUNICIPALITY){
                    // 配信元が市町ユーザの場合、宛先に「長崎県」の有無
                    isCrossOrg = (item.id === config.municInfo.prefMunicCd);
                } else if( UserInfo.getUserType() === USER_TYPE.OTHER_ORGAN){
                    // 配信元がその他ユーザの場合、要請の宛先は県のみとなるため、組織またぎ確定
                    isCrossOrg = true;
                }
            }, this);

            // if (isMunicipality) {
            if (isDummy && !isCrossOrg) {
                // 自治体が選択されていた場合、エラーメッセージを出力する
                var infoDialogMsg = '宛先には要請を行う個別の自治体または組織を選択してください。';
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : infoDialogMsg
                });
                this.infoDialog.show();
                return false;
            }

            // 最下層組織が取得されるため市町村コードへの変換が必要
            var selectedMunicItem = this.reSendShareMunicipalityCd.getMunicipalityCdsIncludesHalf()[0];
            // 未選択の場合は処理せず終了
            if(!selectedMunicItem){
                return false;
            }
            var selectedMunicCd = selectedMunicItem.id;
            //地図の位置を変更
            if(this.map){
                Requester.get('/api/municipalities/' + selectedMunicCd, {
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(lang.hitch(this, function(municipality) {
                    // マップを初期化
                    if(municipality.latitude && municipality.longitude){
                        this.map.setView([municipality.latitude,municipality.longitude]);
                    }
                }), lang.hitch(this, function() {
                    this.chain.info('情報の取得に失敗しました。', 'エラー');
                }));
            }
        }
    });
});
