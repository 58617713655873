/**
 * 配信確認ダイアログ画面用モジュール。
 * @module app/sendingHistory/SendingHistoryConfirmDialog
 */
define([
    'module',
    'dojo',
    'dojo/_base/lang',
    'dojo/_base/declare',
    'dojo/_base/array',
    'dojo/dom-style',
    'dojo/date/locale',
    'dojo/text!./templates/SendingHistoryConfirmDialog.html',
    'idis/view/page/_PageBase',
    'app/model/Municipality',
    'app/config',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button'
], function(module, dojo, lang, declare, array, domStyle,
        locale, template, WidgetBase, Municipality, config) {
    /**
     * 配信確認ダイアログ
     * @class SendingHistoryConfirmDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), WidgetBase,
            /** @lends app/view/Header~Header# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--sending',

        sendTimestamp: null,

        constructor: function() {
            this.inherited(arguments);
        },

        buildRendering: function() {
            this.inherited(arguments);
        },

        startup: function() {
            this.inherited(arguments);
            this.container.resize();
        },

        /*
         * 県のコードを変換する。
         *
         * ManageRegionMunicipalitySelectorは県のコードとしてAreaCode.propertiesの
         * PREF_FULL_CODEの値を使用しているが、配信情報が登録される
         * T_SENDING_HEADERテーブルのMUNICIPALITY_CD列はM_MUNICIPALITYテーブルの
         * MUNICIPALITY_CD列との間に参照整合性制約があるため、県は市町村コードでなければ登録できない。
         */
        _convertPrefectureCd: function(municipalityCd) {
            if (municipalityCd === config.municInfo.prefCd) {
                return config.municInfo.prefMunicCd;
            }
            return municipalityCd;
        },

        /**
         * 画面に内容をセット
         */
        refresh: function(registerPageObj) {
            var tweet = registerPageObj.twitterOuterFrame;
            var value = registerPageObj.form.value;

            // 未来かどうかを判定
            if (new Date() < new Date(value.sendTimestamp)) {
                // タイマー設定の場合は15分刻みにする
                domStyle.set(this.timer, 'display', 'block');
                this.sendTimestamp = this.setTimer(value.sendTimestamp);
            } else {
                //そのまま
                this.sendTimestamp = value.sendTimestamp;
            }

            // 共通部分
            this.sendOrganizationNamePreview.innerHTML = value.sendOrganizationName;
            this.sendTimestampPreview.innerHTML = locale.format(this.sendTimestamp);
            // var municipalityCd = this._convertPrefectureCd(value.municipalityCd);
            // Municipality.store.get(municipalityCd).then(
            //     lang.hitch(this, function(item) {
            //         var _municipalityName = item ? item.name : '取得失敗';
            //         this.municipalityName.innerHTML = _municipalityName;
            //     })
            // );
            this.senderNamePreview.innerHTML = value.senderName;
            this.subjectPreview.innerHTML = value.subject;
            this.bodyTextPreview.innerHTML = value.bodyText;

            // チャンネル別
            // Lアラート
            if (registerPageObj.lalert.checked) {
                domStyle.set(this.dispLalert, 'display', 'block');
                this.lalertPreview.innerHTML = value.lalertInputBody;
            } else {
                domStyle.set(this.dispLalert, 'display', 'none');
            }

            // Twitter
            if (registerPageObj.twitter.checked) {
                domStyle.set(this.dispTwitter, 'display', 'block');
                // Twitter
                // tweetを全て削除
                var element = this.twitterPreview;
                while (element.firstChild) {
                    element.removeChild(element.firstChild);
                }
                var twitterID = registerPageObj.getTwitterIdStr();
                var elements = tweet.getElementsByTagName('textarea');
                var div = dojo.create('div');
                array.forEach (elements, function(d) {
                    var oneTweet = dojo.create('div');
                    oneTweet.innerHTML = twitterID + '<br>' + d.value;
                    oneTweet.style='padding: 10px; margin-bottom: 10px;' + ' border: 1px dotted #333333; width:80%;';
                    oneTweet.align='left';
                    div.appendChild(oneTweet);
                });
                this.twitterPreview.appendChild(div);
            } else {
                domStyle.set(this.dispTwitter, 'display', 'none');
            }

            // 府民向けのお知らせ
            if (registerPageObj.newsForCitizens.checked) {
                domStyle.set(this.dispNewsForCitizens, 'display', 'block');
                this.newsForCitizensPublishDatetime.innerHTML = locale.format(value.newsForCitizensPublishDatetime);
                this.newsForCitizensCloseDatetime.innerHTML = locale.format(value.newsForCitizensCloseDatetime);
                this.newsForCitizensPreview.innerHTML = value.newsForCitizensBody;
            } else {
                domStyle.set(this.dispNewsForCitizens, 'display', 'none');
            }

            this.container.resize();
        },

        /**
         * タイマーを設定
         */
        setTimer: function(timestamp) {
            var dt = new Date(timestamp);
            var date = timestamp.getDate();
            var hours = timestamp.getHours();
            var minutes = 0;
            var timestampMinutes = timestamp.getMinutes();

            var timerPoints = [15, 30, 45, 0];
            if (timestampMinutes % 15 === 0) {
                minutes = timestampMinutes;
            } else {
                minutes = timerPoints[Math.floor(timestampMinutes / 15)];
                if (minutes === 0) {
                    if (hours === 23) {
                        date++;
                    } else {
                        hours++;
                    }
                }
            }

            dt.setMinutes(minutes);
            dt.setHours(hours);
            dt.setDate(date);
            return dt;
        },

        /**
         * 配信
         */
        onSubmit: function() {
            this.emit('register', {sendTimestamp:this.sendTimestamp});
        },

        /**
         * キャンセルボタン
         */
        onCancelButton: function() {
            this.emit('cancel');
            this.leave();
        }
    });
});
