/**
 * 市町内の組織選択用入力パーツ
 * @module app/view/form/UserOrganizationSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/string',
    'dijit/form/TextBox',
    'idis/view/form/TreeSelector',
    'idis/model/UserInfo',
    'idis/consts/ACL',
    'idis/consts/USER_TYPE',
    '../../config',
    'idis/view/tree/IdisTree',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest'
], function(module, declare, lang, dStr, TextBox, TreeSelector, UserInfo, ACL, UserType, config,
    IdisTree, CacheStoreModel, CacheTreeRest) {
    /**
     * 組織選択用パーツ。
     * @class OrganizationSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:app/view/tree/UserOrganizationSelector~UserOrganizationSelector# */ {
        // 選択ダイアログのタイトル
        title: '組織選択',

        // ツリー・モデル
        model: null,

        // ツリーの取得レベル（MUnit, MSect, MDeptのどちか）
        level: 'MUnit',
        // 市町村コード
        _municipalityCd: null,
        // 市町村コード
        _municipalityCdChanged: true,
        // 振興局コード
        _regionCd: null,
        // 振興局コード
        _regionCdChanged: true,
        // 組織カテゴリコード
        _deptCd: null,
        // 組織カテゴリコード
        _deptCdChanged: true,

        // リフレッシュができるかどうか
        _canRefresh: false,

        placeHolder: '組織名で絞り込み',

        constructor: function() {
            this.inherited(arguments);
            // 組織を0県受け取るようダミーの市町コードを設定
            var municipalityCd = '000000';
            var query = '?municipalityCd=' + municipalityCd + '&level=' + this.level;
            this.model =  new CacheStoreModel({
                store: new CacheTreeRest({
                    target: '/api/organizations/municTree' + query
                })
            });
        },
        // DOMを構築する
        buildRendering: function () {
            this.inherited(arguments);
            // 検索用入力要素を設置
            this.filterInput = new TextBox({
                placeHolder: this.placeHolder,
                maxLength: 128
            });
            this.filterInput.placeAt(this.treeControlNode);
            this.own(this.filterInput.on('keyup', lang.hitch(this, 'onFilterInputChange')));
        },
        // 現在がリフレッシュができるかどうかを取得する
        canRefresh: function() {
            return this._canRefresh;
        },

        // リフレッシュを停止する
        stopRefresh: function() {
            this._canRefresh = false;
        },

        // リフレッシュを再開する
        restoreRefresh: function() {
            this._canRefresh = true;
            this._refresh();
        },

        // 市町村コードを設定する
        setMunicipalityCd: function(municipalityCd) {
            if (this._municipalityCd !== municipalityCd) {
                this._municipalityCd = municipalityCd;
                this._municipalityCdChanged = true;
                if (this._canRefresh) {
                    this._refresh();
                }
            }
        },

        // 振興局コードを設定する
        setRegionCd: function(regionCd) {
            if (this._regionCd !== regionCd) {
                this._regionCd = regionCd;
                this._regionCdChanged = true;
                if (this._canRefresh) {
                    this._refresh();
                }
            }
        },

        // 組織カテゴリコードを設定する
        setDeptCd: function(deptCd) {
            if (this._deptCd !== deptCd) {
                this._deptCd = deptCd;
                this._deptCdChanged = true;
                if (this._canRefresh) {
                    this._refresh();
                }
            }
        },

        // 市町村セレクタと振興局セレクタが変更された際、市町村＋振興局内の組織を取得し直す
        _refresh: function() {
            if (!this.tree || this._municipalityCdChanged || this._regionCdChanged || this._deptCdChanged) {
                this._municipalityCdChanged = false;
                this._regionCdChanged = false;
                this._deptCdChanged = false;
                var value = this.get('value');
                // 一旦ツリーを削除し、再構築する
                if (this.tree) {
                    this.tree.destroyRecursive();
                }
				var query = '?level=' + this.level;
				//var role = UserInfo.getRoleCd();
				//if (role !== ACL.ADMIN_USER) {
	                if (this._municipalityCd && this._municipalityCd !== '') {
	                    query += '&municipalityCd=' + this._municipalityCd;
	                }
	                if (this._regionCd && this._regionCd !== '') {
	                    query += '&regionCd=' + this._regionCd;
	                }
	                if (this._deptCd && this._deptCd !== '') {
	                    query += '&deptCd=' + this._deptCd;
	                }
				//}
                // TODO: この部分で[Cannot read property 'setAttribute' of null] というエラーを吐く場合があるが、
                // 画面上の不具合はないため対応を保留中。
                this.model = new CacheStoreModel({
                    store: new CacheTreeRest({
                        target: '/api/organizations/municTree' + query
                    })
                });

                this.tree = new IdisTree({
                    model: this.model
                }, this.treeNode);

                // 再配置する
                this.tree.placeAt(this.parentTreeNode);
                this.tree.startup();

                var setValue = lang.hitch(this, function(value) {
                    this.set('value', value);
                });
                // いったん現在の値をクリアし、現在選択中の値が存在する場合、それを再設定する
                setValue('');
                var onError = lang.hitch(this, function() {});
                var onComplete = lang.hitch(this, function(items) {
                    for (var i=0; i<items.length; i++) {
                        var item = items[i];
                        var ids = this.model.getChildrenIds(item);
                        if (ids.length > 0) {
                            if (ids.indexOf(value) > -1) {
                                // 現在選択中の値が存在する場合、それを再設定する
                                setValue(value);
                            } else if (ids.length > 0) {
                                this.model.getChildren(item, onComplete, onError);
                            }
                        }
                    }
                });
                this.model.getRoot(lang.hitch(this, function(root) {
                    var ids = this.model.getChildrenIds(root);
                    if (ids.indexOf(value) > -1) {
                        setValue(value);
                    } else if (ids.length > 0) {
                        this.model.getChildren(root, onComplete, onError);
                    }
                }), onError);
            }
            return this.tree.onLoadDeferred;
        },
        /**
         * 検索用入力要素入力時。
         */
            onFilterInputChange: function () {
            // 入力値を取得
            var value = dStr.trim(this.filterInput.get('value'));
            if (value) {
                // ラベル名が入力値を含むものでフィルターし、ツリーを展開する
                this.model.setFilter(function (item, isLeaf, model) {
                    return isLeaf && (model.getLabel(item) || '').indexOf(value) !== -1;
                }).then(lang.hitch(this, function () {
                    this.tree.expandAll();
                }));
            } else {
                // 入力が空の場合は全要素を表示してツリーを閉じる
                this.model.setFilter().then(lang.hitch(this, function () {
                    this.tree.collapseAll();
                }));
            }
        }
    });
});
