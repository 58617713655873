/**
 * 被害画面のUIウィジェット
 * @module app/beginner/ReportDetail
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/ReportDetail.html',
    'idis/view/page/_PageBase',
    'dijit/_WidgetBase',
    'dijit/_TemplatedMixin',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'dojo/dom-style'
    // 以下、変数から参照されないモジュール
], function(module, declare, template, _PageBase, _WidgetBase, _TemplatedMixin, Router, UserInfo, UserType, domStyle) {

    /**
     * 被害情報画面のUIウィジェット。
     * @class ReportDetail
     * @extends module:idis/view/page/_PageBase~_PageBase
     */

    /**
     * メニュー用オブジェクト
     * @class Menu
     */

    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/beginner/ReportDetail~ReportDetail# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--beginner/reportdetail',

        /**
         * マニュアルファイルPATH
         */
        FAIL_PATH: '/data/manual/',

        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * DOM構築後に呼ばれる
         */
        postCreate: function() {
            this.inherited(arguments);
            this.initReportLink();
        },

        initReportLink: function(){
            // 権限毎にリンクを出し分ける
            console.debug(UserInfo.getUserType());

            domStyle.set(this.reportLink1, 'display', 'none');
            domStyle.set(this.reportLink2, 'display', 'none');
            domStyle.set(this.reportLink3, 'display', 'none');
            domStyle.set(this.reportLink4, 'display', 'none');

            switch (UserInfo.getUserType()) {
                // 市町ユーザーの場合
                case UserType.MUNICIPALITY:
                        domStyle.set(this.reportLink3, 'display', '');
                        domStyle.set(this.reportLink4, 'display', '');
                        break;
                // 振興局・県・その他機関ユーザーの場合
                case UserType.REGION:
                case UserType.PREFECTURE:
                case UserType.OTHER_ORGAN:
                        domStyle.set(this.reportLink1, 'display', '');
                        domStyle.set(this.reportLink2, 'display', '');
                        domStyle.set(this.reportLink3, 'display', '');
                        break;
                // 開発用
                default:
                        domStyle.set(this.reportLink1, 'display', '');
                        domStyle.set(this.reportLink2, 'display', '');
                        domStyle.set(this.reportLink3, 'display', '');
                        domStyle.set(this.reportLink4, 'display', '');
                        break;
            }

        },

        /**
         * 「マニュアルを確認する」がクリックされた時の挙動。
         */
        onReportManualDlLink: function() {
            var failname = 'report.pdf';
            // pdfを別タブで開く
            window.open(this.FAIL_PATH + failname, '被害情報マニュアル');
        },

        /**
         * 入力例を確認する」がクリックされた時の挙動。
         */
        onReportSampleDlLink: function() {
            var failname = 'report_sample.pdf';
            // pdfを別タブで開く
            window.open(this.FAIL_PATH + failname, '被害情報入力例');
        },


        onReportLink1Click: function(evt){
              // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            console.debug('[被害情報：被害情報を確認したい]がクリックされました');
            // 被害情報概況画面へ遷移
            Router.moveTo('report/admin');
        },

        onReportLink2Click: function(evt){
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            console.debug('[被害情報：各市町の被害状況を確認したい。]がクリックされました');
            if (UserInfo.getUserType() === UserType.REGION) {
                var municipalityCd = UserInfo.getMunicipalityCd();
            } else {
                var municipalityCd = null;
            }
            // 被害情報一覧画面へ遷移
            Router.moveTo('report',{
                municipalityCd : municipalityCd
            });
        },

        onReportLink3Click: function(evt){
              // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            console.debug('[被害情報：被害情報を登録したい。]がクリックされました');
            // 被害情報登録画面へ遷移
            Router.moveTo('report/register');
        },

        onReportLink4Click: function(evt){
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            console.debug('[被害情報：市町内の被害状況を確認したい。被害情報を続報登録、訂正、削除したい。]がクリックされました');
            // 被害情報一覧画面へ遷移
            Router.moveTo('report',{
            municipalityCd : UserInfo.getMunicipalityCd()
            });
        }
    });
});
