/**
 * 組織の一覧定義
 */
define({
    // 市町の防災組織一覧（掲示板）
    BBS_DP: ['D01001','D02001','S03007','S04002','S05007','U06002','S07004','U08001','S09004','S10001',
             'U11007','U12007','S13001','S14006','S15001','U16001','D17001','S18001','U19001','S20001',
             'S21001'],
    // 市町の防災組織一覧（クロノロジ）
    CHRONOLOGY_DP: ['D01001','D02001','D03002','S04002','S05007','U06002','S07004','U08001','S09004','S10001',
                  'U11007','U12007','S13001','S14006','S15001','U16001','D17001','S18001','U19001','S20001',
                  'S21001'],
    // 県の主幹課一覧（クロノロジ）
    CHRONOLOGY_MAIN: ['S22001','S22006','S22009','S22013','S22027','S22033','S22039','S22055','S22072','S22075',
                      'S22085','S22092','S22107','S22121','S22123','S22124','S22125','S22126','S22127','S22143']
});
