define([
    'dojo/_base/lang',
    'leaflet',
    '../drawLocalExtendExtend',
    './measureObj'
], function(lang, leaflet, drawLocalExtendExtend, measureObj) {
    // ツールチップ・テキスト
    var tooltipText = drawLocalExtendExtend.measure.handlers.distance.tooltip;

    var Measure = lang.getObject('Measure', true, leaflet);

    Measure.Distance = leaflet.Handler.extend({
        statics: {
            TYPE: 'measure-distance'
        },

        includes: leaflet.Mixin.Events,

        options: {
            drawError: {
                color: '#b00b00',
                timeout: 2500
            },
            icon: new leaflet.DivIcon({
                iconSize: new leaflet.Point(8, 8),
                className: 'leaflet-editing-icon'
                //className: 'leaflet-div-icon leaflet-editing-icon'
            }),
            touchIcon: new leaflet.DivIcon({
                iconSize: new leaflet.Point(20, 20),
                className: 'leaflet-editing-icon leaflet-touch-icon'
                //className: 'leaflet-div-icon leaflet-editing-icon leaflet-touch-icon'
            }),
            guidelineDistance: 20,
            maxGuideLineLength: 4000,
            shapeOptions: {
                stroke: true,
                color: '#f06eaa',
                weight: 5,
                opacity: 0.5,
                fill: false,
                clickable: true
            },
            metric: true, // Whether to use the metric meaurement system or imperial
            showLength: true, // Whether to display distance in the tooltip
            zIndexOffset: 2000 // This should be > than the highest z-index any map layers
        },

        initialize: function (map, options) {
            this._map = map;
            this._container = map._container;
            this._overlayPane = map._panes.overlayPane;

            if (leaflet.Browser.touch) {
                this.options.icon = this.options.touchIcon;
            }

            leaflet.Handler.prototype.initialize.call(this, map);

            leaflet.Util.setOptions(this, options);

            // Save the type so super can fire, need to do this as cannot do this.TYPE :(
            this.type = leaflet.Measure.Distance.TYPE;
            this.setObj(this);
        },

        setObj: function (obj) {
            measureObj[this.constructor.TYPE] = obj;
        },

        getObj: function (type) {
            return measureObj[type];
        },

        enable: function () {
            // var obj = this.getObj(this.constructor.TYPE);
            if (this.enabled()) {
                return;
            }
            this.fire('enabled', {handler: this.type});
            this._map.fire('measure:start', {handler: this.type});

            leaflet.Handler.prototype.enable.call(this);
            this._drawing = false;
            this._afterDrawing = false;
        },

        disable: function () {
            // var obj = this.getObj(this.constructor.TYPE);
            if (!this.enabled()) {
                return;
            }
            leaflet.Handler.prototype.disable.call(this);
            this._map.fire('measure:stop', {handler: this.type});
            this.fire('disabled', {handler: this.type});
            this._drawing = false;
            this._afterDrawing = false;
        },

        addHooks: function () {
            var map = this._map;

            if (!map) {
                return;
            }

            map.getContainer().focus();
            this._markers = [];

            this._positions = [];

            this._tooltip = new leaflet.Draw.Tooltip(this._map);
            this._tooltip.updateContent({text: tooltipText.start});
            // カーソル側のコメントを非表示に
            this._tooltip.dispose();

            this._lastMarker = null;

            this._markerGroup = new leaflet.LayerGroup();
            this._map.addLayer(this._markerGroup);

            this._poly = new leaflet.Polyline([], this.options.shapeOptions);

            // Make a transparent marker that will used to catch click events. These click
            // events will create the vertices. We need to do this so we can ensure that
            // we can create vertices over other map layers (markers, vector layers). We
            // also do not want to trigger any click handlers of objects we are clicking on
            // while drawing.
            if (!this._mouseMarker) {
                this._mouseMarker = leaflet.marker(this._map.getCenter(), {
                    icon: leaflet.divIcon({
                        className: 'leaflet-mouse-marker',
                        iconAnchor: [20, 20],
                        iconSize: [40, 40]
                    }),
                    opacity: 0,
                    zIndexOffset: this.options.zIndexOffset
                });
            }

            this._mouseMarker
            .on('mousedown', this._onMouseDown, this)
            .on('contextmenu', this._onContextMenu, this)
            .addTo(this._map);

            this._map
            .on('mousemove', this._onMouseMove, this)
            .on('mouseup', this._onMouseUp, this)
            .on('zoomlevelschange', this._onZoomEnd, this)
            .on('contextmenu', this._onContextMenu, this)
            .on('click', this._onTouch, this);
        },

        removeHooks: function () {
            if (!this._map) {
                return;
            }
            this._tooltip.dispose();
            this._tooltip = null;

            this._cleanUpShape();
            // remove markers from map
            this._map.removeLayer(this._markerGroup);
            delete this._markerGroup;
            delete this._lastMarker;
            delete this._markers;

            if (this._poly) {
                this._map.removeLayer(this._poly);
                delete this._poly;
            }

            this._mouseMarker
            .off('mousedown', this._onMouseDown, this)
            .off('contextmenu', this._onContextMenu, this);
            this._map.removeLayer(this._mouseMarker);
            delete this._mouseMarker;

            // clean up DOM
            this._clearGuides();

            this._map
            .off('mousemove', this._onMouseMove, this)
            .off('mouseup', this._onMouseUp, this)
            .off('zoomlevelschange', this._onZoomEnd, this)
            .off('click', this._onTouch, this)
            .off('contextmenu', this._onContextMenu, this);
        },

        revertLayers: function () {
        },

        deleteLastVertex: function () {
            if (this._markers.length <= 1) {
                return;
            }

            var lastMarker = this._markers.pop();
            var poly = this._poly;
            var latlng = this._poly.spliceLatLngs(poly.getLatLngs().length - 1, 1)[0];

            this._markerGroup.removeLayer(lastMarker);

            if (poly.getLatLngs().length < 2) {
                this._map.removeLayer(poly);
            }

            this._vertexChanged(latlng, false);
        },

        addVertex: function (latlng) {
            /*if (this._lastMarker === null) {
              this._lastMarker = this._createMarker(latlng);
              } else {
              this._lastMarker.setLatLng(latlng);
              }*/
            if (this._markers === null) {
                this._markers = [];
            }
            this._markers.push(this._createMarker(latlng));
            this._poly.addLatLng(latlng);
            this._positions.push(latlng);

            if (this._poly.getLatLngs().length === 2) {
                this._map.addLayer(this._poly);
            }

            this._vertexChanged(latlng, true);
        },

        _createMarker: function (latlng) {
            var marker = new leaflet.Marker(latlng, {
                icon: this.options.icon,
                zIndexOffset: this.options.zIndexOffset * 2
            });

            if (this._markerGroup === null) {
                this._markerGroup = new leaflet.LayerGroup();
            }
            this._markerGroup.addLayer(marker);
            marker.on('click', this._finishShape, this);

            return marker;
        },

        /**
         * 計測範囲指定完了時に呼ばれる。
         * @param {Event} e クリック・イベント
         */
        _finishShape: function (e) {
            var marker = e.target;
            var latlng = marker.getLatLng();
            var labelText;
            this._drawing = false;
            this._afterDrawing = true;
            labelText = this._updateTooltip(latlng);
            labelText.subtext = labelText.subtext || '';
            this.fire('finish', {result: this._getMeasurementResult()});
        },

        _onZoomEnd: function () {
            this._updateGuide();
        },

        _onMouseMove: function (e) {
            var newPos = e.layerPoint,
                latlng = e.latlng;

                // Save latlng
                // should this be moved to _updateGuide() ?
                this._currentLatLng = latlng;

                if (this._drawing) {
                    this._updateTooltip(latlng);
                    // Update the guide line
                    this._updateGuide(newPos);
                } else if (this._afterDrawing) {
                    this._updateTooltip();
                } else {
                    this._updateTooltip(latlng);
                }
                // Update the mouse marker position
                this._mouseMarker.setLatLng(latlng);

                leaflet.DomEvent.preventDefault(e.originalEvent);
        },

        _onMouseDown: function (e) {
            var originalEvent = e.originalEvent;
            if (originalEvent.button !== 2) {
                this._mouseDownOrigin = leaflet.point(originalEvent.clientX, originalEvent.clientY);
            }
        },

        _onMouseUp: function (e) {
            this._currentLatLng = e.latlng;
            if (!this._drawing) {
                this._afterDrawing = false;
                this._measurementRunningTotal = 0;
                this._positions = [];
                if (this._poly) {
                    this._map.removeLayer(this._poly);
                    delete this._poly;
                }
                this._poly = new leaflet.Polyline([], this.options.shapeOptions);
                this._drawing = true;
                this.addVertex(e.latlng);
            }
            if (this._mouseDownOrigin) {
                // We detect clicks within a certain tolerance, otherwise let it
                // be interpreted as a drag by the map
                var distance = leaflet.point(e.originalEvent.clientX, e.originalEvent.clientY)
                .distanceTo(this._mouseDownOrigin);
                if (Math.abs(distance) < 9 * (window.devicePixelRatio || 1)) {
                    this.addVertex(e.latlng);
                    this._updateTooltip(e.latlng);
                }
            }
            this._mouseDownOrigin = null;
        },

        _onTouch: function (e) {
            // #TODO: use touchstart and touchend vs using click(touch start & end).
            if (leaflet.Browser.touch) { // #TODO: get rid of this once leaflet fixes their click/touch.
                this._onMouseDown(e);
                this._onMouseUp(e);
            }
        },

        _onContextMenu: function (e) {
            leaflet.DomEvent.preventDefault(e);
            this.deleteLastVertex();
        },

        _vertexChanged: function (latlng, added) {
            //this._updateFinishHandler();

            this._updateRunningMeasure(latlng, added);

            this._clearGuides();

            //this._updateTooltip();
        },

        _updateFinishHandler: function () {
            var markerCount = this._markers.length;
            // The last marker should have a click handler to close the polyline
            if (markerCount > 1) {
                this._markers[markerCount - 1].on('click', this._finishShape, this);
            }

            // Remove the old marker click handler (as only the last point should close the polyline)
            if (markerCount > 2) {
                this._markers[markerCount - 2].off('click', this._finishShape, this);
            }
        },

        _updateTooltip: function (latLng) {
            var text = this._getTooltipText();

            if (latLng) {
                this._tooltip.updatePosition(latLng);
            }

            if (!this._errorShown) {
                this._tooltip.updateContent(text);
            }
            return text;
        },

        _getTooltipText: function () {
            var showLength = this.options.showLength,
                labelText, distanceStr;

                if (this._markers.length === 0) {
                    //if (this._positions.length === 0) {
                    labelText = {
                        text: tooltipText.start
                    };
                } else {
                    distanceStr = showLength ? this._getMeasurementString() : '';

                    //if (this._positions.length === 1) {
                    if (this._markers.length === 1) {
                        labelText = {
                            text: tooltipText.cont,
                            subtext: distanceStr
                        };
                    } else if (this._afterDrawing) {
                        labelText = {
                            text: tooltipText.finished,
                            subtext: distanceStr
                        };
                    } else {
                        labelText = {
                            text: tooltipText.end,
                            subtext: distanceStr
                        };
                    }
                }
                return labelText;
        },

        /**
         * 計測結果を数値として返す。
         * @returns {number} 計測結果
         */
        _getMeasurementResult: function() {
            var currentLatLng = this._currentLatLng;
            var previousLatLng = this._markers[this._markers.length - 1].getLatLng();

            // calculate the distance from the last fixed point to the mouse position
            if (this._afterDrawing) {
                return this._measurementRunningTotal;
            } else {
                return this._measurementRunningTotal + currentLatLng.distanceTo(previousLatLng);
            }
        },

        _getMeasurementString: function () {
            var currentLatLng = this._currentLatLng;
            //previousLatLng = this._positions[this._positions.length - 1],
            var previousLatLng = this._markers[this._markers.length - 1].getLatLng();
            var distance;

            // calculate the distance from the last fixed point to the mouse position
            if (this._afterDrawing) {
                distance = this._measurementRunningTotal;
            } else {
                distance = this._measurementRunningTotal + currentLatLng.distanceTo(previousLatLng);
            }

            return leaflet.GeometryUtil.readableDistance(distance, this.options.metric);
        },

        _updateRunningMeasure: function (latlng, added) {
            //var markersLength = this._positions.length,
            var markersLength = this._markers.length,
                previousMarkerIndex, distance;

                //if (this._positions.length === 1) {
                if (this._markers.length === 1) {
                    this._measurementRunningTotal = 0;
                } else {
                    previousMarkerIndex = markersLength - (added ? 2 : 1);
                    //distance = latlng.distanceTo(this._positions[previousMarkerIndex]);
                    distance = latlng.distanceTo(this._markers[previousMarkerIndex].getLatLng());

                    this._measurementRunningTotal += distance * (added ? 1 : -1);
                }
        },

        _updateGuide: function (newPos) {
            var markerCount;
            if(typeof this.getObj(this.constructor.TYPE)._markers === 'undefined') {
                markerCount = 0;
            } else {
                markerCount = this.getObj(this.constructor.TYPE)._markers.length;
            }

            if (markerCount > 0) {
                //if (this._lastMarker) {
                newPos = newPos || this._map.latLngToLayerPoint(this._currentLatLng);

                // draw the guide line
                this._clearGuides();
                this._drawGuide(
                    //this._map.latLngToLayerPoint(this._lastMarker.getLatLng()),
                    this._map.latLngToLayerPoint(this._markers[markerCount - 1].getLatLng()),
                    newPos
                );
            }
        },

        _drawGuide: function (pointA, pointB) {
            var length = Math.floor(Math.sqrt(Math.pow((pointB.x - pointA.x), 2) + Math.pow((pointB.y - pointA.y), 2)));
            var guidelineDistance = this.options.guidelineDistance;
            var maxGuideLineLength = this.options.maxGuideLineLength;
            // Only draw a guideline with a max length
            var i = length > maxGuideLineLength ? length - maxGuideLineLength : guidelineDistance;
            var fraction, dashPoint, dash;

            //create the guides container if we haven't yet
            if (!this._guidesContainer) {
                this._guidesContainer = leaflet.DomUtil.create('div', 'leaflet-draw-guides', this._overlayPane);
            }

            //draw a dash every GuildeLineDistance
            for (; i < length; i += this.options.guidelineDistance) {
                //work out fraction along line we are
                fraction = i / length;

                //calculate new x,y point
                dashPoint = {
                    x: Math.floor((pointA.x * (1 - fraction)) + (fraction * pointB.x)),
                    y: Math.floor((pointA.y * (1 - fraction)) + (fraction * pointB.y))
                };

                //add guide dash to guide container
                dash = leaflet.DomUtil.create('div', 'leaflet-draw-guide-dash', this._guidesContainer);
                dash.style.backgroundColor =
                    !this._errorShown ? this.options.shapeOptions.color : this.options.drawError.color;

                    leaflet.DomUtil.setPosition(dash, dashPoint);
            }
        },

        // removes all child elements (guide dashes) from the guides container
        _clearGuides: function () {
            if (this._guidesContainer) {
                while (this._guidesContainer.firstChild) {
                    this._guidesContainer.removeChild(this._guidesContainer.firstChild);
                }
            }
        },

        _cleanUpShape: function () {
            if (this._markers.length > 1) {
                this._markers[this._markers.length - 1].off('click', this._finishShape, this);
            }
        }
    });

    return Measure.Distance;
});

