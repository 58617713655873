define([
    'leaflet',
    'dojo/_base/lang',
    'dojo/dom-attr',
    'dojo/dom-construct',
    'dojo/dom-style',
    'dojo/on'
], function(leaflet, lang, domAttr, domConst, domStyle, on) {
    var TileLayer = leaflet.Class.extend({
        
        includes: leaflet.Mixin.Events,
        
        initialize: function(map, layer, options) {
            leaflet.setOptions(this, options);
            this._map = map;
            this._layer = layer;
        },
        
        _getTileSize: function() {
            var zoom = this._map.getZoom();
            var zoomN = this._layer.options.maxNativeZoom;
            var tileSize = this._layer.options.tileSize;
            if (zoomN && zoom > zoomN) {
                tileSize = Math.round(this._map.getZoomScale(zoom) / this._map.getZoomScale(zoomN) * tileSize);
            }
            
            return tileSize;  
        },
        
        refreshQueue: function() {
            var bounds = (this.options.pixelBounds ? this.options.pixelBounds : this._map.getPixelBounds());
            var zoom = this._map.getZoom();
            if (zoom > this._layer.options.maxZoom || zoom < this._layer.options.minZoom) {
                return;
            }
            
            var tileSize = this._getTileSize();
            
            var tileBounds = 
                leaflet.bounds(bounds.min.divideBy(tileSize)._floor(), bounds.max.divideBy(tileSize)._floor());
            this._queue = [];
            //center = tileBounds.getCenter();
            this._tiles = {};

            for (var j = tileBounds.min.y; j <= tileBounds.max.y; j++) {
                for (var i = tileBounds.min.x; i <= tileBounds.max.x; i++) {
                    var point = new leaflet.Point(i, j);
                    this._queue.push(point);
                    this._tiles[point.x + ':' + point.y] = point;
                }
            }
        },
        
        _getZoomForUrl: function() {
            var options = this._layer.options,
            zoom = this._map.getZoom();
            
            if (options.zoomReverse) {
                zoom = options.maxZoom - zoom;
            }
            
            zoom += options.zoomOffset;
    
            return options.maxNativeZoom ? Math.min(zoom, options.maxNativeZoom) : zoom;
        },
        
        _getWrapTileNum: function() {
            var crs = this._map.options.crs;
            var s = crs.scale(this._map.getZoom());
            var size = leaflet.point(s, s);
            
            return size.divideBy(this._getTileSize())._floor();
        },
        
        _adjustTilePoint: function(tilePoint) {
            var limit = this._getWrapTileNum();
            
            // wrap tile coordinates
            if (!this.options.continuousWorld && !this.options.noWrap) {
                tilePoint.x = ((tilePoint.x % limit.x) + limit.x) % limit.x;
            }

            tilePoint.z = this._getZoomForUrl();
        },
        
        load: function() {
            if (!this._queue) {
                return;
            }
            //var zoom = this._getZoomForUrl();
            var tileSize = this._getTileSize();
            var origin = this._map.getPixelOrigin();
            var pixelBounds = (this.options.pixelBounds ? this.options.pixelBounds : this._map.getPixelBounds());
            var loadCounter = 0;
            
            for (var i = 0; i<this._queue.length; i++) {
                var tilePoint =  this._queue[i];
                var tilePoint2 = lang.mixin({}, tilePoint);
                this._adjustTilePoint(tilePoint2);
                
                if (this._layer.options.bounds) {
                    var nwPoint = tilePoint.multiplyBy(tileSize);
                    var sePoint = nwPoint.add([tileSize, tileSize]);
                    var nw = this._map.unproject(nwPoint);
                    var se = this._map.unproject(sePoint);

                    if (!this._layer.options.continuousWorld && !this._layer.options.noWrap) {
                        nw = nw.wrap();
                        se = se.wrap();
                    }

                    if (!this._layer.options.bounds.intersects([nw, se])) {
                        tilePoint._noimage = true;
                        delete this._tiles[tilePoint.x + ':' + tilePoint.y];
                        continue;
                    }
                }
                    
                var tilePos = this._getTilePos(tilePoint2, tileSize);
                tilePos = tilePoint.multiplyBy(tileSize).subtract(origin);
                tilePos.x += (origin.x - pixelBounds.min.x);
                tilePos.y += (origin.y - pixelBounds.min.y);
                /*
                tilePoint.img = $( '<img>' ).css({
                        width : tileSize + 'px',
                        height : tileSize + 'px'
                }).attr( { 'crossOrigin' : 'anonymous' } );
                */
                tilePoint.img = domConst.toDom('<img>');
                domStyle.set(tilePoint.img, {
                    width : tileSize + 'px',
                    height : tileSize + 'px'
                });
                domAttr.set(tilePoint.img, 'crossOrigin', 'anonymous');
                tilePoint.size = tileSize;
                tilePoint.pos = tilePos;
                //tilePoint.img.on('load', leaflet.bind(this._onTileLoad, this, tilePoint));
                //tilePoint.img.on('error', leaflet.bind(this._onTileLoadError, this, tilePoint));
                on(tilePoint.img, 'load', leaflet.bind(this._onTileLoad, this, tilePoint));
                on(tilePoint.img, 'error', leaflet.bind(this._onTileLoadError, this, tilePoint));
                
                var url =  this.getTileUrl(tilePoint2);
                if (false && url.match(/^\//i )) {
                    url = 'https:' + url;
                }
                
                domAttr.set(tilePoint.img, 'src', url);
                
                loadCounter++;
            }
            
            if (loadCounter <= 0) {
                this._tileLoaded();
            }
        },
        
        _tileLoaded: function() {
            var length = 0;
            for (var key in this._tiles) {
                if (this._tiles.hasOwnProperty(key)) {
                    length++;    
                }
            }
            
            if (length <= 0) {
                this.fire('loaded');
            }
        },
        
        _onTileLoad: function(tilePoint) {
            delete this._tiles[tilePoint.x + ':' + tilePoint.y];
            this._tileLoaded();
        },
        
        _onTileLoadError: function(tilePoint) {
            tilePoint._noimage = true;
            delete this._tiles[tilePoint.x + ':' + tilePoint.y];
            this._tileLoaded();
        },
        
        _getSubdomain: function(tilePoint) {
            var index = Math.abs(tilePoint.x + tilePoint.y) % this._layer.options.subdomains.length;
            return this._layer.options.subdomains[index];
        },
        
        getTileUrl: function(tilePoint) {
            return leaflet.Util.template(this._layer._url.replace(/cyberjapandata.gsi.go.jp/, 'maps.gsi.go.jp'), 
                leaflet.extend({
                    s: this._getSubdomain(tilePoint),
                    z: tilePoint.z,
                    x: tilePoint.x,
                    y: tilePoint.y
                }, this.options));
        },
        
        _getTilePos: function(tilePoint, tileSize) {
            var origin = this._map.getPixelOrigin();

            return tilePoint.multiplyBy(tileSize).subtract(origin);
        },
        
        draw: function(texture) {
            var grayScaleCanvas = null;
            texture.globalAlpha = (this.options.opacity ? this.options.opacity : 1.0);
            for (var i = 0; i < this._queue.length; i++) {
                var tilePoint = this._queue[i];
                if (tilePoint._noimage) {
                    continue;
                }
                
                if (this.options.grayscale) {
                    if (!grayScaleCanvas) {
                        grayScaleCanvas = document.createElement('canvas');
                    }
                    if (grayScaleCanvas.getContext) {
                        grayScaleCanvas.width  = Math.ceil(tilePoint.size);
                        grayScaleCanvas.height = Math.ceil(tilePoint.size);

                        var ctx = grayScaleCanvas.getContext('2d');
                        ctx.drawImage(tilePoint.img[0], 0, 0, 256 ,256, 0, 0, tilePoint.size, tilePoint.size);
                        var imageData = ctx.getImageData(0, 0, tilePoint.size, tilePoint.size);
                        /*
                        pixelData = imageData.data;
                        for (var y = 0; y < grayScaleCanvas.height; y++) {
                            for(var x = 0; x < grayScaleCanvas.width; x++) {
                                // (x,y)ピクセルの明度
                                var j = (y * 4 * grayScaleCanvas.width) + (x * 4);

                                var R = pixelData[j    ];
                                var G = pixelData[j + 1];
                                var B = pixelData[j + 2];

                                // グレースケールに変換
                                var grayScale = (R * 0.3) + (G * 0.59) + (B * .11);
                                pixelData[j    ] = grayScale;
                                pixelData[j + 1] = grayScale;
                                pixelData[j + 2] = grayScale;
                                //pixelData[j + 3] = 32;
                            }
                        }
                        */
                        ctx.putImageData(imageData, 0, 0, 0, 0, imageData.width, imageData.height);
                                
                        texture.drawImage(
                            grayScaleCanvas, 0, 0, 256 ,256, 
                            tilePoint.pos.x, tilePoint.pos.y, 
                            tilePoint.size, tilePoint.size);    
                    }
                } else {
                    if (this.options.blend) {
                        texture.globalCompositeOperation = 'multiply';
                        texture.drawImage(
                            tilePoint.img, 0, 0, 256 ,256, 
                            tilePoint.pos.x, tilePoint.pos.y, 
                            tilePoint.size, tilePoint.size);
                        texture.globalCompositeOperation = 'source-over';
                    } else {
                        texture.drawImage(
                            tilePoint.img, 0, 0, 256 ,256, 
                            tilePoint.pos.x, tilePoint.pos.y, 
                            tilePoint.size, tilePoint.size);
                    
                    }
                }
            }
            texture.globalAlpha = 1.0;
        }
        
    });

    /*
    leaflet.mapToImage.tileLayer = function(map, layer, options){
        return new leaflet.mapToImage.TileLayer(map, layer, options);
    };
    */

    return TileLayer;
});

